<template>
    <div id="searchBar">
        <b-form-input id="input" type="text" placeholder="Search by name, location, troop number, or year" v-model="searchInput" @keyup.enter="submit"></b-form-input>
        <div id="buttonBar">
            <b-button id="submitBtn" class="button" variant="primary" name="Submit" type="submit" @click="submit">Search</b-button>
            <b-button class="button" variant="secondary" name="ViewAll" @click="viewAll">View All</b-button>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/eventBus/Bus'; 

export default {
    name: 'SearchBar',
    data(){
        return {
            searchInput: '',
            testInput: ''
        }
    },
    methods: {
        submit() {
            EventBus.$emit("update:searchInput", this.searchInput);
        },
        viewAll() {
            this.searchInput = '';
            //insert spinner here
            EventBus.$emit("update:searchInput", 'All');
        }
    }
}



</script>

<style>
    #searchBar {
        margin: 10px;
    }

    #input {
        width: 70%;
        /* margin: 5px; */
        display: inline-block;
    }
    

    #buttonBar {
        display: inline-block;

    }

    .button {
        display: inline-block;
        margin: 5.5px 10px 10px 10px;
        width: 6rem;
    }

    #submitBtn {
        background-color:#23365c !important;
    }

    @media screen and (max-width: 843px) {
        #buttonBar {
            margin: 1rem 0rem 0rem 11rem;
        }
        #input {
            width: 100%;
        }
        .button {
            width: 160px;
        }
    }

    @media screen and (max-width: 609px) {
        #buttonBar {
        margin: 1rem 0rem 0rem 3rem;
        }
        #input {
            width: 100%;
        }
        .button {
            width: 100px;
        }
    }

    @media screen and (min-width: 1200px) {
        #searchBar {
            margin: 10px 10px 10px 150px;
        }
    }
    
</style>
