<template>
  <div>
    <div class="row">
      <div class="col-xs-0 col-md-0 col-lg-0 col-xl-1"></div>
      <GoogleMaps
        :selectedPerps="filteredLocations"
        :centerCoordinates="centerCoordinates"
        id="map"
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-10"
      />
      <div class="col-xs-0 col-md-0 col-lg-0 col-xl-1"></div>
    </div>
    <div class="row">
      <div class="col-xs-0 col-md-0 col-lg-0 col-xl-1"></div>
      <PerpTable
        :selectedPerps="filteredLocations"
        v-if="showTable"
        id="table"
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mainElement"
      />
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mainElement">
          <h3>Contact Us</h3>
          <p>Jeff Anderson & Associates is recognized as the nation’s premier law firm to represent victims of childhood sexual abuse. We are smart, tough and relentless, but the virtue that ultimately sets us apart is our compassion. We are people who feel deeply and work tirelessly in response to an unjust world. We have a reputation for being the best at what we do, and that begins and ends with our ability to support, protect and guide survivors along their journey towards justice and healing.</p>
          <p>We understand how difficult it can be to take this step. Please be assured that we will be honest and caring in our discussion and will respect your privacy at all times.</p>
          <p><strong>Call us privately at 1-800-ITS-TIME or click the contact us button below.</strong></p>
          <a class="btn btn-primary" target="_blank" href="https://www.andersonadvocates.com/contact/">Contact Us</a>
      </div>
      <div class="col-xs-0 col-md-0 col-lg-0 col-xl-1"></div>
    </div>
  </div>
</template>

<script>
import PerpTable from "@/components/PerpTable";
import GoogleMaps from "@/components/GoogleMaps";
import PerpData from "@/data/PerpData";
import DataDictionary from "@/data/DataDictionary";
import Perp from "@/classes/Perp";
import { EventBus } from "@/eventBus/Bus";

export default {
  name: "MainContainerNoForm",
  components: {
    PerpTable,
    GoogleMaps
  },
  created() {},
  mounted() {
    var parsedPerps = PerpData;
    for (let index = 0; index < parsedPerps.length; index++) {
      var np = new Perp(parsedPerps[index]);
      this.perpObjects.push(np);
    }
    EventBus.$on("update:boundsChange", this.handleBoundsChange);
  },
  methods: {
    handleBoundsChange(bounds) {
      this.bounds = bounds;
    }
  },
  props: ["searchText"],
  computed: {
    filteredLocations() {
      if (this.searchText == "All" || this.searchText == "") {
        if (this.bounds !== undefined) {
          var perpsInBounds = [];
          this.perpObjects.forEach(perp => {
            if (this.bounds.contains(perp.Position)) {
              perpsInBounds.push(perp);
            }
          });
        }
        return perpsInBounds === undefined ? this.perpObjects : perpsInBounds;
      } else {
        let filteredPerps = [];
        let tokens = this.searchText.toLowerCase().split(",");

        tokens.forEach(token => {
          token = token.trim();
          let perpIndices = DataDictionary[token];
          if (perpIndices === undefined) {
            perpIndices = [];
            let partialMatchKeys = Object.keys(DataDictionary).filter(p =>
              p.includes(token)
            );
            partialMatchKeys.forEach(pm =>
              perpIndices.push(...DataDictionary[pm])
            );
          }
          perpIndices.forEach(index =>
            filteredPerps.push(this.perpObjects[index])
          );
        });
        return filteredPerps;
      }
    }
  },
  data() {
    return {
      perpObjects: [],
      showTable: true,
      centerCoordinates: { lat: 39.8283, lng: -98.5795 },
      bounds: undefined
    };
  }
};
</script>

<style>
#table {
  width: 100%;
}

.mainElement {
  margin-top: 2rem;
}
</style>
