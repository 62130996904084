export default class Perp {
    TroopNumber
    lng
    State
    NameOrID
    City
    Year
    lat
    DocumentUri
    Position

    constructor(data){
        Object.assign(this, data);
        this.Position = new Position(data.lat, data.lng);
        this.DocumentUri = data.Document;
    }
}

class Position {
    lat
    lng

    constructor(latitude, longtitude)
    {
        this.lat = Number(latitude);
        this.lng = Number(longtitude);
    }
}