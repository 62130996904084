<template>
    <div id="footer">
        <div class="row">
            <h3 id="copy" class="col-12">Jeff Anderson & Associates</h3>
        </div>
        <div class="row">
            <p class="col-xs-12 col-sm-12 col-md-4 col-lg-4"><br>11812 San Vicente Blvd<br>Los Angeles, California 90049</p>
            <p class="col-xs-12 col-sm-12 col-md-4 col-lg-4"><br>366 Jackson Street, Suite 100<br>St. Paul MN, 55101
                <br><a href="tel:8004878463">1-800-ITS-TIME</a></p>
            <p class="col-xs-12 col-sm-12 col-md-4 col-lg-4"><br>55 W 39th Street, 11th Floor<br>New York, New York 10018</p>
        </div>
    <div>
        <p class="col-12"><br>505 Thornall Street, Suite 405<br>Edison, NJ 08837</p>
        </div>
        <div class="row">
            <div class="col-12">
                <br>Copyright ©2020 Jeff Anderson & Associates | 
                <a target="_blank" href="https://www.andersonadvocates.com/Disclaimer">Disclaimer</a> | 
                <a target="_blank" href="https://www.andersonadvocates.com/Privacy">Privacy Statement</a> | ATTORNEY ADVERTISING
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterComponent"
}
</script>

<style>

    #footer {
        background-color:#23365c;
        margin-top: 5rem;
        text-align: center;
        color: white;
    }

    #copy {
        padding-top: 24px;
    }

</style>
