<template>
    <div id="header">
        <div class="row">
            <img src="images/logo.png" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 headerElement">
            <p class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 headerElement">
            Use the search bar below to locate the basic information and the IV file of a known perpetrator of sexual abuse who has volunteered as a Scout leader,
            Scoutmaster, or other role within the Boy Scouts of America. Search by name, location, year of service or troop number, or "view all perpetrators" using the
            View All button below. 
            </p>
        </div>
    </div>    
</template>

<script>
export default {
    name: "HeaderComponent",
    data() {
        return {
            
        }
    }
}

</script>

<style>
    #header {
        text-align: center;
        margin: 4rem;
    }
    .headerElement {
        margin: 2rem 0rem 0rem 0rem;
    }
</style>
