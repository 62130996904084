export default [
    {
        "TroopNumber": "Troop 22",
        "lng": "-83.373255",
        "State": "Georgia",
        "NameOrID": "Boland, Ernest P.",
        "City": "Athens",
        "Year": "1977",
        "lat": "33.958132",
        "Document": " docs/ernest-p-boland.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-83.373255",
        "State": "Georgia",
        "NameOrID": "Boland, Ernest P.",
        "City": "Athens",
        "Year": "1977",
        "lat": "33.958132",
        "Document": " docs/ernest-p-boland.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-83.373255",
        "State": "Georgia",
        "NameOrID": "Boland, Ernest P.",
        "City": "Athens",
        "Year": "1977",
        "lat": "33.958132",
        "Document": " docs/ernest-p-boland.pdf"
    },
    {
        "TroopNumber": "Troop 77",
        "lng": "-120.999589",
        "State": "California",
        "NameOrID": "Hanigan, Kenneth L.",
        "City": "Modesto",
        "Year": "1987",
        "lat": "37.6383",
        "Document": "docs/kenneth-l-hanigan.pdf"
    },
    {
        "TroopNumber": "Pack 56",
        "lng": "-120.999589",
        "State": "California",
        "NameOrID": "Hanigan, Kenneth L.",
        "City": "Modesto",
        "Year": "1987",
        "lat": "37.6383",
        "Document": "docs/kenneth-l-hanigan.pdf"
    },
    {
        "TroopNumber": "Pack 5",
        "lng": "-120.999589",
        "State": "California",
        "NameOrID": "Hanigan, Kenneth L.",
        "City": "Modesto",
        "Year": "1987",
        "lat": "37.6383",
        "Document": "docs/kenneth-l-hanigan.pdf"
    },
    {
        "TroopNumber": "Pack 7",
        "lng": "",
        "State": "",
        "NameOrID": "Michell, David H.",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/david-h-michell.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-110.963989",
        "State": "Wyoming",
        "NameOrID": "Later, Kip E.",
        "City": "Evanston",
        "Year": "1988",
        "lat": "41.26748",
        "Document": "docs/kip-e-later.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-71.513467",
        "State": "Rhode Island",
        "NameOrID": "Crepean, Ronald F.",
        "City": "Woonsocket",
        "Year": "1962",
        "lat": "42.003008",
        "Document": "docs/ronald-f-crepean.pdf"
    },
    {
        "TroopNumber": "Troop 8",
        "lng": "-71.513467",
        "State": "Rhode Island",
        "NameOrID": "Crepean, Ronald F.",
        "City": "Woonsocket",
        "Year": "1962",
        "lat": "42.003008",
        "Document": "docs/ronald-f-crepean.pdf"
    },
    {
        "TroopNumber": "Troop 9",
        "lng": "-71.513467",
        "State": "Rhode Island",
        "NameOrID": "Crepean, Ronald F.",
        "City": "Woonsocket",
        "Year": "1962",
        "lat": "42.003008",
        "Document": "docs/ronald-f-crepean.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-121.078869",
        "State": "California",
        "NameOrID": "Thomas, Raymond L.",
        "City": "Auburn",
        "Year": "1967",
        "lat": "38.896205",
        "Document": "docs/raymond-l-thomas.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-121.284588",
        "State": "California",
        "NameOrID": "Thomas, Raymond L.",
        "City": "Roseville",
        "Year": "1967",
        "lat": "38.748554",
        "Document": "docs/raymond-l-thomas.pdf"
    },
    {
        "TroopNumber": "Explorer 11",
        "lng": "-121.284588",
        "State": "California",
        "NameOrID": "Thomas, Raymond L.",
        "City": "Roseville",
        "Year": "1967",
        "lat": "38.748554",
        "Document": "docs/raymond-l-thomas.pdf"
    },
    {
        "TroopNumber": "Explorer 510",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Merritt, Randall B.",
        "City": "Indianapolis",
        "Year": "1982",
        "lat": "39.76691",
        "Document": "docs/randall-b-merritt.pdf"
    },
    {
        "TroopNumber": "Explorer 123",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Merritt, Randall B.",
        "City": "Indianapolis",
        "Year": "1982",
        "lat": "39.76691",
        "Document": "docs/randall-b-merritt.pdf"
    },
    {
        "TroopNumber": "Explorer 12",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Merritt, Randall B.",
        "City": "Indianapolis",
        "Year": "1982",
        "lat": "39.76691",
        "Document": "docs/randall-b-merritt.pdf"
    },
    {
        "TroopNumber": "Troop 5",
        "lng": "-82.445369",
        "State": "West Virginia",
        "NameOrID": "Liontakis, Luigi E.",
        "City": "Huntington",
        "Year": "1964",
        "lat": "38.419825",
        "Document": "docs/luigi-e-liontakis.pdf"
    },
    {
        "TroopNumber": "Troop 8",
        "lng": "-82.445369",
        "State": "West Virginia",
        "NameOrID": "Liontakis, Luigi E.",
        "City": "Huntington",
        "Year": "1964",
        "lat": "38.419825",
        "Document": "docs/luigi-e-liontakis.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-82.445369",
        "State": "West Virginia",
        "NameOrID": "Liontakis, Luigi E.",
        "City": "Huntington",
        "Year": "1964",
        "lat": "38.419825",
        "Document": "docs/luigi-e-liontakis.pdf"
    },
    {
        "TroopNumber": "Pack 1",
        "lng": "-73.608599",
        "State": "New York",
        "NameOrID": "Gonzalez, Guillermo",
        "City": "Baldwin",
        "Year": "1984",
        "lat": "40.6581",
        "Document": " docs/guillermo-gonzalez.pdf"
    },
    {
        "TroopNumber": "Pack 217",
        "lng": "-73.608599",
        "State": "New York",
        "NameOrID": "Gonzalez, Guillermo",
        "City": "Baldwin",
        "Year": "1984",
        "lat": "40.6581",
        "Document": " docs/guillermo-gonzalez.pdf"
    },
    {
        "TroopNumber": "Pack 18",
        "lng": "-73.587219",
        "State": "New York",
        "NameOrID": "Gonzalez, Guillermo",
        "City": "Freeport",
        "Year": "1984",
        "lat": "40.658455",
        "Document": " docs/guillermo-gonzalez.pdf"
    },
    {
        "TroopNumber": "Troop 138",
        "lng": "-73.587219",
        "State": "New York",
        "NameOrID": "Gonzalez, Guillermo",
        "City": "Freeport ",
        "Year": "1984",
        "lat": "40.658455",
        "Document": " docs/guillermo-gonzalez.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 50",
        "lng": "-87.713749",
        "State": "Wisconsin",
        "NameOrID": "Gort, Jack C.",
        "City": "Sheboygan",
        "Year": "1967",
        "lat": "43.74978",
        "Document": "docs/jack-c-gort.pdf"
    },
    {
        "TroopNumber": "Troop 4",
        "lng": "-87.713749",
        "State": "Wisconsin",
        "NameOrID": "Gort, Jack C.",
        "City": "Sheboygan",
        "Year": "1967",
        "lat": "43.74978",
        "Document": "docs/jack-c-gort.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-87.713749",
        "State": "Wisconsin",
        "NameOrID": "Gort, Jack C.",
        "City": "Sheboygan",
        "Year": "1967",
        "lat": "43.74978",
        "Document": "docs/jack-c-gort.pdf"
    },
    {
        "TroopNumber": "Troop 19",
        "lng": "-87.713749",
        "State": "Wisconsin",
        "NameOrID": "Gort, Jack C.",
        "City": "Sheboygan",
        "Year": "1967",
        "lat": "43.74978",
        "Document": "docs/jack-c-gort.pdf"
    },
    {
        "TroopNumber": "Troop 28",
        "lng": "-78.642669",
        "State": "North Carolina",
        "NameOrID": "Hardin, Terry",
        "City": "Raleigh",
        "Year": "1989",
        "lat": "35.78551",
        "Document": "docs/terry-hardin.pdf"
    },
    {
        "TroopNumber": "Troop 387",
        "lng": "-78.642669",
        "State": "North Carolina",
        "NameOrID": "Hardin, Terry",
        "City": "Raleigh",
        "Year": "1989",
        "lat": "35.78551",
        "Document": "docs/terry-hardin.pdf"
    },
    {
        "TroopNumber": "Troop 33",
        "lng": "-78.642669",
        "State": "North Carolina",
        "NameOrID": "Hardin, Terry",
        "City": "Raleigh",
        "Year": "1989",
        "lat": "35.78551",
        "Document": "docs/terry-hardin.pdf"
    },
    {
        "TroopNumber": "Troop 79",
        "lng": "-122.093064",
        "State": "Washington",
        "NameOrID": "Matlock, James R.",
        "City": "Snohomish",
        "Year": "1991",
        "lat": "47.9124",
        "Document": "docs/james-r-matlock.pdf"
    },
    {
        "TroopNumber": "Pack 45",
        "lng": "-122.093064",
        "State": "Washington",
        "NameOrID": "Matlock, James R.",
        "City": "Snohomish",
        "Year": "1991",
        "lat": "47.9124",
        "Document": "docs/james-r-matlock.pdf"
    },
    {
        "TroopNumber": "Troop 36",
        "lng": "-122.093064",
        "State": "Washington",
        "NameOrID": "Matlock, James R.",
        "City": "Snohomish",
        "Year": "1991",
        "lat": "47.9124",
        "Document": "docs/james-r-matlock.pdf"
    },
    {
        "TroopNumber": "Troop 16",
        "lng": "-93.052314",
        "State": "Arkansas",
        "NameOrID": "Otts, Sam C.",
        "City": "Hot Springs ",
        "Year": "1977",
        "lat": "34.509424",
        "Document": " docs/sam-c-otts.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-93.052314",
        "State": "Arkansas",
        "NameOrID": "Otts, Sam C.",
        "City": "Hot Springs",
        "Year": "1977",
        "lat": "34.509424",
        "Document": " docs/sam-c-otts.pdf"
    },
    {
        "TroopNumber": "68",
        "lng": "-81.778294",
        "State": "Ohio",
        "NameOrID": "Keyes, Brian Thomas",
        "City": "Lakewood ",
        "Year": "1985",
        "lat": "41.48449",
        "Document": "docs/brian-thomas-keyes.pdf"
    },
    {
        "TroopNumber": "727",
        "lng": "-81.778294",
        "State": "Ohio",
        "NameOrID": "Keyes, Brian Thomas",
        "City": "Lakewood",
        "Year": "1985",
        "lat": "41.48449",
        "Document": "docs/brian-thomas-keyes.pdf"
    },
    {
        "TroopNumber": "41",
        "lng": "-71.069771",
        "State": "Ohio",
        "NameOrID": "Keyes, Brian Thomas",
        "City": "Bayvillage",
        "Year": "1985",
        "lat": "42.350651",
        "Document": "docs/brian-thomas-keyes.pdf"
    },
    {
        "TroopNumber": "Troop 66",
        "lng": "-96.707739",
        "State": "Nebraska",
        "NameOrID": "Shockley, Douglas A.",
        "City": "Lincoln",
        "Year": "1987",
        "lat": "40.81362",
        "Document": "docs/douglas-a-shockley.pdf"
    },
    {
        "TroopNumber": "Troop 46",
        "lng": "-96.707739",
        "State": "Nebraska",
        "NameOrID": "Shockley, Douglas A.",
        "City": "Lincoln",
        "Year": "1987",
        "lat": "40.81362",
        "Document": "docs/douglas-a-shockley.pdf"
    },
    {
        "TroopNumber": "Troop 51",
        "lng": "-96.707739",
        "State": "Nebraska",
        "NameOrID": "Shockley, Douglas A.",
        "City": "Lincoln",
        "Year": "1987",
        "lat": "40.81362",
        "Document": "docs/douglas-a-shockley.pdf"
    },
    {
        "TroopNumber": "Troop 555",
        "lng": "",
        "State": "",
        "NameOrID": "Willis, Abrom",
        "City": "",
        "Year": "1965",
        "lat": "",
        "Document": "docs/abrom-willis.pdf"
    },
    {
        "TroopNumber": "Explorer 58",
        "lng": "-83.606874",
        "State": "Georgia",
        "NameOrID": "Willis, Abrom",
        "City": "Warner Robins",
        "Year": "1965",
        "lat": "32.620835",
        "Document": "docs/abrom-willis.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-91.638594",
        "State": "Minnesota",
        "NameOrID": "Hopkins, Timothy R.",
        "City": "Winona",
        "Year": "1989",
        "lat": "44.051295",
        "Document": "docs/timothy-r-hopkins.pdf"
    },
    {
        "TroopNumber": "Pack 59",
        "lng": "-90.847372",
        "State": "Wisconsin",
        "NameOrID": "Hopkins, Timothy R.",
        "City": "Black River Falls",
        "Year": "1989",
        "lat": "44.295586",
        "Document": "docs/timothy-r-hopkins.pdf"
    },
    {
        "TroopNumber": "Troop 59",
        "lng": "-90.847372",
        "State": "Wisconsin",
        "NameOrID": "Hopkins, Timothy R.",
        "City": "Black River Falls",
        "Year": "1989",
        "lat": "44.295586",
        "Document": "docs/timothy-r-hopkins.pdf"
    },
    {
        "TroopNumber": "Troop 118",
        "lng": "-69.619623",
        "State": "Maine",
        "NameOrID": "Baker, Charles S.",
        "City": "Shirley mills",
        "Year": "1990",
        "lat": "45.365973",
        "Document": "docs/charles-s-baker.pdf"
    },
    {
        "TroopNumber": "Troop 120",
        "lng": "-69.590377",
        "State": "Maine",
        "NameOrID": "Baker, Charles S.",
        "City": "Greenville",
        "Year": "1990",
        "lat": "45.460843",
        "Document": "docs/charles-s-baker.pdf"
    },
    {
        "TroopNumber": "Troop 62",
        "lng": "-69.5951",
        "State": "Maine",
        "NameOrID": "Baker, Charles S.",
        "City": "Wellington",
        "Year": "1990",
        "lat": "45.032773",
        "Document": "docs/charles-s-baker.pdf"
    },
    {
        "TroopNumber": "Troop 117",
        "lng": "-69.012493",
        "State": "Maine",
        "NameOrID": "Baker, Charles S.",
        "City": "Guildford",
        "Year": "1990",
        "lat": "45.262321",
        "Document": "docs/charles-s-baker.pdf"
    },
    {
        "TroopNumber": "Troop 40",
        "lng": "-88.042284",
        "State": "Illinois",
        "NameOrID": "Morris, Frank H.",
        "City": "St. Joseph",
        "Year": "1986",
        "lat": "40.110478",
        "Document": "docs/frank-h-morris.pdf"
    },
    {
        "TroopNumber": "Troop 76",
        "lng": "-75.004769",
        "State": "Pennsylvania",
        "NameOrID": "Morris, Frank H.",
        "City": "Feasterville",
        "Year": "1986",
        "lat": "40.145135",
        "Document": "docs/frank-h-morris.pdf"
    },
    {
        "TroopNumber": "Explorer 697",
        "lng": "-84.391109",
        "State": "Georgia",
        "NameOrID": "Kennedy, Daniel W.",
        "City": "Atlanta",
        "Year": "1991",
        "lat": "33.748315",
        "Document": "docs/daniel-w-kennedy.pdf"
    },
    {
        "TroopNumber": "Troop 70",
        "lng": "-84.391109",
        "State": "Georgia",
        "NameOrID": "Kennedy, Daniel W.",
        "City": "Atlanta",
        "Year": "1991",
        "lat": "33.748315",
        "Document": "docs/daniel-w-kennedy.pdf"
    },
    {
        "TroopNumber": "Troop 77",
        "lng": "-84.391109",
        "State": "Georgia",
        "NameOrID": "Kennedy, Daniel W.",
        "City": "Atlanta",
        "Year": "1991",
        "lat": "33.748315",
        "Document": "docs/daniel-w-kennedy.pdf"
    },
    {
        "TroopNumber": "Troop 550",
        "lng": "-84.391109",
        "State": "Georgia",
        "NameOrID": "Kennedy, Daniel W.",
        "City": "Atlanta",
        "Year": "1991",
        "lat": "33.748315",
        "Document": "docs/daniel-w-kennedy.pdf"
    },
    {
        "TroopNumber": "Troop 372",
        "lng": "-83.986778",
        "State": "Michigan",
        "NameOrID": "Martin, Michael James",
        "City": "Durand",
        "Year": "1987",
        "lat": "42.91234",
        "Document": "docs/michael-james-martin.pdf"
    },
    {
        "TroopNumber": "Troop 84",
        "lng": "-83.986778",
        "State": "Michigan",
        "NameOrID": "Martin, Michael James",
        "City": "Durand",
        "Year": "1987",
        "lat": "42.91234",
        "Document": "docs/michael-james-martin.pdf"
    },
    {
        "TroopNumber": "Pack 84",
        "lng": "-83.986778",
        "State": "Michigan",
        "NameOrID": "Martin, Michael James",
        "City": "Durand",
        "Year": "1987",
        "lat": "42.91234",
        "Document": "docs/michael-james-martin.pdf"
    },
    {
        "TroopNumber": "Pack 88",
        "lng": "-79.347538",
        "State": "North Carolina",
        "NameOrID": "Cameron, Talmond Morris",
        "City": "McCain",
        "Year": "1966",
        "lat": "35.055874",
        "Document": "docs/talmond-morris-cameron.pdf"
    },
    {
        "TroopNumber": "Troop 85",
        "lng": "-79.347538",
        "State": "North Carolina",
        "NameOrID": "Cameron, Talmond Morris",
        "City": "McCain",
        "Year": "1966",
        "lat": "35.055874",
        "Document": "docs/talmond-morris-cameron.pdf"
    },
    {
        "TroopNumber": "Troop 88",
        "lng": "-79.347538",
        "State": "North Carolina",
        "NameOrID": "Cameron, Talmond Morris",
        "City": "McCain",
        "Year": "1966",
        "lat": "35.055874",
        "Document": "docs/talmond-morris-cameron.pdf"
    },
    {
        "TroopNumber": "Troop 686",
        "lng": "-79.347538",
        "State": "North Carolina",
        "NameOrID": "Cameron, Talmond Morris",
        "City": "McCain",
        "Year": "1966",
        "lat": "35.055874",
        "Document": "docs/talmond-morris-cameron.pdf"
    },
    {
        "TroopNumber": "Pack 1088",
        "lng": "-87.632949",
        "State": "Wisconsin",
        "NameOrID": "Longway, Brian Paul",
        "City": "Marinette",
        "Year": "1973",
        "lat": "45.099771",
        "Document": "docs/brian-paul-longway.pdf"
    },
    {
        "TroopNumber": "Troop 135",
        "lng": "-75.918044",
        "State": "Pennsylvania",
        "NameOrID": "Longway, Brian Paul",
        "City": "Parkesburg",
        "Year": "1973",
        "lat": "39.959105",
        "Document": "docs/brian-paul-longway.pdf"
    },
    {
        "TroopNumber": "Troop 90",
        "lng": "-77.008339",
        "State": "Pennsylvania",
        "NameOrID": "Longway, Brian Paul",
        "City": "Mechanicsburg",
        "Year": "1973",
        "lat": "40.2132",
        "Document": "docs/brian-paul-longway.pdf"
    },
    {
        "TroopNumber": "Troop 356",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Harper, Arthur",
        "City": "Rochester ",
        "Year": "1972",
        "lat": "43.1555",
        "Document": " docs/arthur-harper.pdf"
    },
    {
        "TroopNumber": "Troop 98",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Harper, Arthur",
        "City": "Rochester",
        "Year": "1972",
        "lat": "43.1555",
        "Document": " docs/arthur-harper.pdf"
    },
    {
        "TroopNumber": "Troop 100",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Harper, Arthur",
        "City": "Rochester",
        "Year": "1972",
        "lat": "43.1555",
        "Document": " docs/arthur-harper.pdf"
    },
    {
        "TroopNumber": "Troop 4",
        "lng": "-78.763334",
        "State": "Maryland",
        "NameOrID": "Margulies, Arthur D.",
        "City": "Cumberland",
        "Year": "1980",
        "lat": "39.651775",
        "Document": " docs/arthur-d-margulies.pdf"
    },
    {
        "TroopNumber": "Troop 256",
        "lng": "-77.050999",
        "State": "Maryland",
        "NameOrID": "Margulies, Arthur D.",
        "City": "Wheaton",
        "Year": "1980",
        "lat": "39.043035",
        "Document": " docs/arthur-d-margulies.pdf"
    },
    {
        "TroopNumber": "Troop 101",
        "lng": "",
        "State": "West Virginia",
        "NameOrID": "Margulies, Arthur D.",
        "City": "Sutton",
        "Year": "1980",
        "lat": "",
        "Document": " docs/arthur-d-margulies.pdf"
    },
    {
        "TroopNumber": "Troop 8",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "Gates, Richard D.",
        "City": "Worcester",
        "Year": "1961",
        "lat": "42.263415",
        "Document": "docs/richard-d-gates.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "Gates, Richard D.",
        "City": "Worcester",
        "Year": "1961",
        "lat": "42.263415",
        "Document": "docs/richard-d-gates.pdf"
    },
    {
        "TroopNumber": "Troop 103",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "Gates, Richard D.",
        "City": "Worcester",
        "Year": "1961",
        "lat": "42.263415",
        "Document": "docs/richard-d-gates.pdf"
    },
    {
        "TroopNumber": "Troop 55",
        "lng": "-74.932334",
        "State": "New Jersey",
        "NameOrID": "Hollender, John F.",
        "City": "Berlin",
        "Year": "1977",
        "lat": "39.795923",
        "Document": " docs/john-f-hollender.pdf"
    },
    {
        "TroopNumber": "Troop 102",
        "lng": "-75.095409",
        "State": "New Jersey",
        "NameOrID": "Hollender, John F.",
        "City": "Woodlyn",
        "Year": "1977",
        "lat": "39.916556",
        "Document": " docs/john-f-hollender.pdf"
    },
    {
        "TroopNumber": "Troop 105",
        "lng": "-75.070209",
        "State": "New Jersey",
        "NameOrID": "Hollender, John F.",
        "City": "Collingswood",
        "Year": "1977",
        "lat": "39.91664",
        "Document": " docs/john-f-hollender.pdf"
    },
    {
        "TroopNumber": "Troop 393",
        "lng": "-71.463089",
        "State": "New Hampshire",
        "NameOrID": "Cote, Alfred Francis",
        "City": "Manchester",
        "Year": "1984",
        "lat": "42.991175",
        "Document": "docs/alfred-francis-cote.pdf"
    },
    {
        "TroopNumber": "Troop 412",
        "lng": "-71.326764",
        "State": "New Hampshire",
        "NameOrID": "Cote, Alfred Francis",
        "City": "Derry",
        "Year": "1984",
        "lat": "42.880575",
        "Document": "docs/alfred-francis-cote.pdf"
    },
    {
        "TroopNumber": "Troop 107",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Cote, Alfred Francis",
        "City": "Chicago",
        "Year": "1984",
        "lat": "41.88415",
        "Document": "docs/alfred-francis-cote.pdf"
    },
    {
        "TroopNumber": "Troop 34",
        "lng": "-73.114939",
        "State": "Massachusetts",
        "NameOrID": "Bulshey, Mark T.",
        "City": "North Adams",
        "Year": "1976",
        "lat": "42.699857",
        "Document": " docs/mark-t-bulshey.pdf"
    },
    {
        "TroopNumber": "Troop 34",
        "lng": "-73.114939",
        "State": "Massachusetts",
        "NameOrID": "Bulshey, Mark T.",
        "City": "North Adams",
        "Year": "1976",
        "lat": "42.699857",
        "Document": " docs/mark-t-bulshey.pdf"
    },
    {
        "TroopNumber": "Troop 108",
        "lng": "-73.114939",
        "State": "Massachusetts",
        "NameOrID": "Bulshey, Mark T.",
        "City": "North Adams",
        "Year": "1976",
        "lat": "42.699857",
        "Document": " docs/mark-t-bulshey.pdf"
    },
    {
        "TroopNumber": "Troop 125",
        "lng": "-73.114939",
        "State": "Massachusetts",
        "NameOrID": "Bulshey, Mark T.",
        "City": "North Adams",
        "Year": "1976",
        "lat": "42.699857",
        "Document": " docs/mark-t-bulshey.pdf"
    },
    {
        "TroopNumber": "Troop 66",
        "lng": "-79.997459",
        "State": "Pennsylvania",
        "NameOrID": "Gallardi, Robert",
        "City": "Pittsburgh ",
        "Year": "1989",
        "lat": "40.438335",
        "Document": "docs/robert-gallardi.pdf"
    },
    {
        "TroopNumber": "Troop 212",
        "lng": "-97.609704",
        "State": "Kansas",
        "NameOrID": "Capra, John R.",
        "City": "Salina ",
        "Year": "1984",
        "lat": "38.840545",
        "Document": " docs/john-r-capra.pdf"
    },
    {
        "TroopNumber": "Troop 116",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Capra, John R.",
        "City": "Kansas City",
        "Year": "1984",
        "lat": "39.10296",
        "Document": " docs/john-r-capra.pdf"
    },
    {
        "TroopNumber": "Pack 116",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Capra, John R.",
        "City": "Kansas City",
        "Year": "1984",
        "lat": "39.10296",
        "Document": " docs/john-r-capra.pdf"
    },
    {
        "TroopNumber": "Troop 256",
        "lng": "-94.671684",
        "State": "Kansas",
        "NameOrID": "Capra, John R.",
        "City": "Overland Park",
        "Year": "1984",
        "lat": "38.984895",
        "Document": " docs/john-r-capra.pdf"
    },
    {
        "TroopNumber": "Troop 919",
        "lng": "-77.163609",
        "State": "Maryland",
        "NameOrID": "Franklin, Claudie Hershel",
        "City": "Indian Head",
        "Year": "1965",
        "lat": "38.599885",
        "Document": "docs/claudie-hershel-franklin.pdf"
    },
    {
        "TroopNumber": "Troop 220",
        "lng": "-83.606874",
        "State": "Georgia",
        "NameOrID": "Franklin, Claudie Hershel",
        "City": "Warner Robins",
        "Year": "1965",
        "lat": "32.620835",
        "Document": "docs/claudie-hershel-franklin.pdf"
    },
    {
        "TroopNumber": "Troop 120",
        "lng": "-83.606874",
        "State": "Georgia",
        "NameOrID": "Franklin, Claudie Hershel",
        "City": "Warner Robins",
        "Year": "1965",
        "lat": "32.620835",
        "Document": "docs/claudie-hershel-franklin.pdf"
    },
    {
        "TroopNumber": "Troop 60",
        "lng": "-82.976419",
        "State": "Ohio",
        "NameOrID": "Mathisen, Paul Gerhard",
        "City": "Bucyrus",
        "Year": "1988",
        "lat": "40.80865",
        "Document": "docs/paul-gerhard-mathisen.pdf"
    },
    {
        "TroopNumber": "Pack ",
        "lng": "-82.522196",
        "State": "Ohio",
        "NameOrID": "Mathisen, Paul Gerhard",
        "City": "Rockbridge",
        "Year": "1988",
        "lat": "39.578182",
        "Document": "docs/paul-gerhard-mathisen.pdf"
    },
    {
        "TroopNumber": "Troop 138",
        "lng": "-83.170804",
        "State": "Ohio",
        "NameOrID": "Mathisen, Paul Gerhard",
        "City": "Sycamore",
        "Year": "1988",
        "lat": "40.94973",
        "Document": "docs/paul-gerhard-mathisen.pdf"
    },
    {
        "TroopNumber": "Pack 118",
        "lng": "-83.170804",
        "State": "Ohio",
        "NameOrID": "Mathisen, Paul Gerhard",
        "City": "Sycamore",
        "Year": "1988",
        "lat": "40.94973",
        "Document": "docs/paul-gerhard-mathisen.pdf"
    },
    {
        "TroopNumber": "Troop 39",
        "lng": "-120.999589",
        "State": "California",
        "NameOrID": "Pugh, Henry",
        "City": "Modesto",
        "Year": "1983",
        "lat": "37.6383",
        "Document": " docs/henry-pugh.pdf"
    },
    {
        "TroopNumber": "Pack 39",
        "lng": "-120.999589",
        "State": "California",
        "NameOrID": "Pugh, Henry",
        "City": "Modesto",
        "Year": "1983",
        "lat": "37.6383",
        "Document": " docs/henry-pugh.pdf"
    },
    {
        "TroopNumber": "Pack 139",
        "lng": "-120.999589",
        "State": "California",
        "NameOrID": "Pugh, Henry",
        "City": "Modesto",
        "Year": "1983",
        "lat": "37.6383",
        "Document": " docs/henry-pugh.pdf"
    },
    {
        "TroopNumber": "Troop 80",
        "lng": "-83.035881",
        "State": "Michigan",
        "NameOrID": "Clark, David Earl",
        "City": "Utica",
        "Year": "1965",
        "lat": "42.625997",
        "Document": "docs/david-earl-clark.pdf"
    },
    {
        "TroopNumber": "Explorer 80",
        "lng": "-83.035881",
        "State": "Michigan",
        "NameOrID": "Clark, David Earl",
        "City": "Utica",
        "Year": "1965",
        "lat": "42.625997",
        "Document": "docs/david-earl-clark.pdf"
    },
    {
        "TroopNumber": "Troop 141",
        "lng": "-83.035881",
        "State": "Michigan",
        "NameOrID": "Clark, David Earl",
        "City": "Utica",
        "Year": "1965",
        "lat": "42.625997",
        "Document": "docs/david-earl-clark.pdf"
    },
    {
        "TroopNumber": "Troop 164",
        "lng": "-72.744429",
        "State": "Connecticut",
        "NameOrID": "Dyber, James",
        "City": "West Hartford",
        "Year": "1989",
        "lat": "41.75877",
        "Document": "docs/james-dyber.pdf"
    },
    {
        "TroopNumber": "Troop 44",
        "lng": "-72.744429",
        "State": "Connecticut",
        "NameOrID": "Dyber, James",
        "City": "West Hartford",
        "Year": "1989",
        "lat": "41.75877",
        "Document": "docs/james-dyber.pdf"
    },
    {
        "TroopNumber": "Troop 146",
        "lng": "-72.744429",
        "State": "Connecticut",
        "NameOrID": "Dyber, James",
        "City": "West Hartford",
        "Year": "1989",
        "lat": "41.75877",
        "Document": "docs/james-dyber.pdf"
    },
    {
        "TroopNumber": "Pack 169",
        "lng": "-94.415629",
        "State": "Missouri",
        "NameOrID": "Woods, Rolland L.",
        "City": "Independence",
        "Year": "1974",
        "lat": "39.093245",
        "Document": " docs/rolland-l-woods.pdf"
    },
    {
        "TroopNumber": "Troop 206",
        "lng": "-94.415629",
        "State": "Missouri",
        "NameOrID": "Woods, Rolland L.",
        "City": "Independence",
        "Year": "1974",
        "lat": "39.093245",
        "Document": " docs/rolland-l-woods.pdf"
    },
    {
        "TroopNumber": "Troop 169",
        "lng": "-94.415629",
        "State": "Missouri",
        "NameOrID": "Woods, Rolland L.",
        "City": "Independence",
        "Year": "1974",
        "lat": "39.093245",
        "Document": " docs/rolland-l-woods.pdf"
    },
    {
        "TroopNumber": "Troop 186",
        "lng": "-123.263534",
        "State": "Oregon",
        "NameOrID": "Tobiassen, William E.",
        "City": "Corvallis ",
        "Year": "1984",
        "lat": "44.56504",
        "Document": "docs/william-e-tobiassen.pdf"
    },
    {
        "TroopNumber": "Troop 191",
        "lng": "-87.906844",
        "State": "Wisconsin",
        "NameOrID": "Kunde, Cliffe W.",
        "City": "Milwaukee",
        "Year": "1977",
        "lat": "43.04181",
        "Document": " docs/cliffe-w-kunde.pdf"
    },
    {
        "TroopNumber": "Troop 61",
        "lng": "-77.572544",
        "State": "Maryland",
        "NameOrID": "Horowitz, Alan J.",
        "City": "Smithsburg",
        "Year": "1983",
        "lat": "39.654345",
        "Document": " docs/alan-j-horowitz.pdf"
    },
    {
        "TroopNumber": "Pack 64",
        "lng": "-81.975309",
        "State": "Georgia",
        "NameOrID": "Horowitz, Alan J.",
        "City": "Augusta",
        "Year": "1983",
        "lat": "33.47909",
        "Document": " docs/alan-j-horowitz.pdf"
    },
    {
        "TroopNumber": "Troop 202",
        "lng": "-91.526534",
        "State": "Iowa",
        "NameOrID": "Horowitz, Alan J.",
        "City": "Iowa City",
        "Year": "1983",
        "lat": "41.657825",
        "Document": " docs/alan-j-horowitz.pdf"
    },
    {
        "TroopNumber": "Pack 158",
        "lng": "-74.007124",
        "State": "New York",
        "NameOrID": "Jacoby, Robert J.",
        "City": "New York",
        "Year": "1976",
        "lat": "40.71455",
        "Document": " docs/robert-j-jacoby.pdf"
    },
    {
        "TroopNumber": "Troop 158",
        "lng": "-74.007124",
        "State": "New York",
        "NameOrID": "Jacoby, Robert J.",
        "City": "New York City",
        "Year": "1976",
        "lat": "40.71455",
        "Document": " docs/robert-j-jacoby.pdf"
    },
    {
        "TroopNumber": "Troop 205",
        "lng": "-74.019444",
        "State": "New Jersey",
        "NameOrID": "Jacoby, Robert J.",
        "City": "Teaneck",
        "Year": "1976",
        "lat": "40.886875",
        "Document": " docs/robert-j-jacoby.pdf"
    },
    {
        "TroopNumber": "Troop 208",
        "lng": "",
        "State": "Georgia",
        "NameOrID": "Calabro, Larry",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/larry-calabro.pdf"
    },
    {
        "TroopNumber": "Troop 565",
        "lng": "-98.494619",
        "State": "Texas",
        "NameOrID": "Diaz, Felix",
        "City": "San Antonia",
        "Year": "1985",
        "lat": "29.42449",
        "Document": "docs/felix-diaz.pdf"
    },
    {
        "TroopNumber": "Pack 565",
        "lng": "-98.494619",
        "State": "Texas",
        "NameOrID": "Diaz, Felix",
        "City": "San Antonio ",
        "Year": "1985",
        "lat": "29.42449",
        "Document": "docs/felix-diaz.pdf"
    },
    {
        "TroopNumber": "Troop 216",
        "lng": "-98.494619",
        "State": "Texas",
        "NameOrID": "Diaz, Felix",
        "City": "San Antonio",
        "Year": "1985",
        "lat": "29.42449",
        "Document": "docs/felix-diaz.pdf"
    },
    {
        "TroopNumber": "12",
        "lng": "-98.494619",
        "State": "Texas",
        "NameOrID": "Diaz, Felix",
        "City": "San Antonio",
        "Year": "1985",
        "lat": "29.42449",
        "Document": "docs/felix-diaz.pdf"
    },
    {
        "TroopNumber": "Troop 132",
        "lng": "-75.830129",
        "State": "Maryland",
        "NameOrID": "Culver, David A.",
        "City": "Elkton",
        "Year": "1979",
        "lat": "39.608565",
        "Document": "docs/david-allen-culver.pdf"
    },
    {
        "TroopNumber": "Troop 366",
        "lng": "-76.072193",
        "State": "Maryland",
        "NameOrID": "Culver, David A.",
        "City": "Perryville",
        "Year": "1979",
        "lat": "39.559749",
        "Document": "docs/david-allen-culver.pdf"
    },
    {
        "TroopNumber": "Troop 238",
        "lng": "-75.830129",
        "State": "Maryland",
        "NameOrID": "Culver, David A.",
        "City": "Elkton",
        "Year": "1979",
        "lat": "39.608565",
        "Document": "docs/david-allen-culver.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-75.830129",
        "State": "Maryland",
        "NameOrID": "Culver, David A.",
        "City": "Elkton",
        "Year": "1979",
        "lat": "39.608565",
        "Document": "docs/david-allen-culver.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-72.676773",
        "State": "Virginia",
        "NameOrID": "Metzger, Arthur Robert",
        "City": "Suffolk County",
        "Year": "1967",
        "lat": "40.95108",
        "Document": "docs/arthur-robert-metzger.pdf"
    },
    {
        "TroopNumber": "Troop 15",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "Metzger, Arthur Robert",
        "City": "San Diego",
        "Year": "1967",
        "lat": "32.715695",
        "Document": "docs/arthur-robert-metzger.pdf"
    },
    {
        "TroopNumber": "Troop 244",
        "lng": "-122.462479",
        "State": "California",
        "NameOrID": "Metzger, Arthur Robert",
        "City": "Miramar",
        "Year": "1967",
        "lat": "37.49556",
        "Document": "docs/arthur-robert-metzger.pdf"
    },
    {
        "TroopNumber": "Pack 9",
        "lng": "-79.997459",
        "State": "Pennsylvania",
        "NameOrID": "Nugent, Samuel J.",
        "City": "Pittsburgh",
        "Year": "1989",
        "lat": "40.438335",
        "Document": "docs/samuel-j-nugent.pdf"
    },
    {
        "TroopNumber": "Pack 274",
        "lng": "-79.997459",
        "State": "Pennsylvania",
        "NameOrID": "Nugent, Samuel J.",
        "City": "Pittsburgh",
        "Year": "1989",
        "lat": "40.438335",
        "Document": "docs/samuel-j-nugent.pdf"
    },
    {
        "TroopNumber": "Troop 274",
        "lng": "-79.997459",
        "State": "Pennsylvania",
        "NameOrID": "Nugent, Samuel J.",
        "City": "Pittsburgh",
        "Year": "1989",
        "lat": "40.438335",
        "Document": "docs/samuel-j-nugent.pdf"
    },
    {
        "TroopNumber": "Troop 112",
        "lng": "-77.430164",
        "State": "New York",
        "NameOrID": "King, David W.",
        "City": "Webster",
        "Year": "1969",
        "lat": "43.217305",
        "Document": "docs/david-w-king.pdf"
    },
    {
        "TroopNumber": "Troop 262",
        "lng": "-77.430164",
        "State": "New York",
        "NameOrID": "King, David W.",
        "City": "Webster",
        "Year": "1969",
        "lat": "43.217305",
        "Document": "docs/david-w-king.pdf"
    },
    {
        "TroopNumber": "Troop 314",
        "lng": "-77.430164",
        "State": "New York",
        "NameOrID": "King, David W.",
        "City": "Webster",
        "Year": "1969",
        "lat": "43.217305",
        "Document": "docs/david-w-king.pdf"
    },
    {
        "TroopNumber": "Troop 712",
        "lng": "-74.006099",
        "State": "New York",
        "NameOrID": "Hungate, James M.",
        "City": "Manhattan",
        "Year": "1960",
        "lat": "40.71443",
        "Document": "docs/james-m-hungate.pdf"
    },
    {
        "TroopNumber": "Troop 226",
        "lng": "-73.990364",
        "State": "New York",
        "NameOrID": "Hungate, James M.",
        "City": "Brooklyn",
        "Year": "1960",
        "lat": "40.692455",
        "Document": "docs/james-m-hungate.pdf"
    },
    {
        "TroopNumber": "Troop 333",
        "lng": "-73.990364",
        "State": "New York",
        "NameOrID": "Hungate, James M.",
        "City": "Brooklyn",
        "Year": "1960",
        "lat": "40.692455",
        "Document": "docs/james-m-hungate.pdf"
    },
    {
        "TroopNumber": "Explorer 384",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Wantuck, Robert L.",
        "City": "St. Louis",
        "Year": "1963",
        "lat": "38.62774",
        "Document": "docs/robert-l-wantuck.pdf"
    },
    {
        "TroopNumber": "Explorer 765",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Wantuck, Robert L.",
        "City": "St. Louis",
        "Year": "1963",
        "lat": "38.62774",
        "Document": "docs/robert-l-wantuck.pdf"
    },
    {
        "TroopNumber": "Troop 384",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Wantuck, Robert L.",
        "City": "St. Louis",
        "Year": "1963",
        "lat": "38.62774",
        "Document": "docs/robert-l-wantuck.pdf"
    },
    {
        "TroopNumber": "Pack 179",
        "lng": "-93.264929",
        "State": "Minnesota",
        "NameOrID": "Froehlig, James William",
        "City": "Minneapolis",
        "Year": "1982",
        "lat": "44.979035",
        "Document": " docs/james-william-froehlig.pdf"
    },
    {
        "TroopNumber": "Troop 179",
        "lng": "-93.264929",
        "State": "Minnesota",
        "NameOrID": "Froehlig, James William",
        "City": "Minneapolis",
        "Year": "1982",
        "lat": "44.979035",
        "Document": " docs/james-william-froehlig.pdf"
    },
    {
        "TroopNumber": "Troop 299",
        "lng": "-80.254146",
        "State": "Florida",
        "NameOrID": "Chandler, Anthony S.",
        "City": "Coconut Grove",
        "Year": "1968",
        "lat": "25.732333",
        "Document": "docs/anthony-s-chandler.pdf"
    },
    {
        "TroopNumber": "Troop 21",
        "lng": "-80.254146",
        "State": "Florida",
        "NameOrID": "Chandler, Anthony S.",
        "City": "Coconut Grove",
        "Year": "1968",
        "lat": "25.732333",
        "Document": "docs/anthony-s-chandler.pdf"
    },
    {
        "TroopNumber": "Troop 419",
        "lng": "-80.237419",
        "State": "Florida",
        "NameOrID": "Chandler, Anthony S.",
        "City": "Miami",
        "Year": "1968",
        "lat": "25.728985",
        "Document": "docs/anthony-s-chandler.pdf"
    },
    {
        "TroopNumber": "Troop 255",
        "lng": "-111.831459",
        "State": "Arizona",
        "NameOrID": "Judd, Larry Wright",
        "City": "Mesa",
        "Year": "1986",
        "lat": "33.417045",
        "Document": "docs/larry-wright-judd.pdf"
    },
    {
        "TroopNumber": "Troop 152",
        "lng": "-111.831459",
        "State": "Arizona",
        "NameOrID": "Judd, Larry Wright",
        "City": "Mesa",
        "Year": "1986",
        "lat": "33.417045",
        "Document": "docs/larry-wright-judd.pdf"
    },
    {
        "TroopNumber": "Troop 430",
        "lng": "-111.831459",
        "State": "Arizona",
        "NameOrID": "Judd, Larry Wright",
        "City": "Mesa",
        "Year": "1986",
        "lat": "33.417045",
        "Document": "docs/larry-wright-judd.pdf"
    },
    {
        "TroopNumber": "Troop 455",
        "lng": "-89.703429",
        "State": "Wisconsin",
        "NameOrID": "Syring, David L.",
        "City": "Mosinee",
        "Year": "1977",
        "lat": "44.79323",
        "Document": " docs/david-l-syring.pdf"
    },
    {
        "TroopNumber": "Explorer 455",
        "lng": "-89.703429",
        "State": "Wisconsin",
        "NameOrID": "Syring, David L.",
        "City": "Mosinee",
        "Year": "1977",
        "lat": "44.79323",
        "Document": " docs/david-l-syring.pdf"
    },
    {
        "TroopNumber": "Troop 438",
        "lng": "-89.612847",
        "State": "Wisconsin",
        "NameOrID": "Syring, David L.",
        "City": "Schofield",
        "Year": "1977",
        "lat": "44.92061",
        "Document": " docs/david-l-syring.pdf"
    },
    {
        "TroopNumber": "Troop 338",
        "lng": "",
        "State": "",
        "NameOrID": "Kinney, Vernon C.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/vernon-c-kinney.pdf"
    },
    {
        "TroopNumber": "Troop 76",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Tannehill, Thomas M.",
        "City": "Indianapolis",
        "Year": "1990",
        "lat": "39.76691",
        "Document": "docs/thomas-m-tannehill.pdf"
    },
    {
        "TroopNumber": "Troop 804",
        "lng": "-87.906844",
        "State": "Wisconsin",
        "NameOrID": "Tannehill, Thomas M.",
        "City": "Milwaukee",
        "Year": "1990",
        "lat": "43.04181",
        "Document": "docs/thomas-m-tannehill.pdf"
    },
    {
        "TroopNumber": "Troop 472",
        "lng": "-87.906844",
        "State": "Wisconsin",
        "NameOrID": "Tannehill, Thomas M.",
        "City": "Milwaukee",
        "Year": "1990",
        "lat": "43.04181",
        "Document": "docs/thomas-m-tannehill.pdf"
    },
    {
        "TroopNumber": "Troop 317",
        "lng": "-87.906844",
        "State": "Wisconsin",
        "NameOrID": "Tannehill, Thomas M.",
        "City": "Milwaukee",
        "Year": "1990",
        "lat": "43.04181",
        "Document": "docs/thomas-m-tannehill.pdf"
    },
    {
        "TroopNumber": "Troop 560",
        "lng": "-101.845009",
        "State": "Texas",
        "NameOrID": "Beaudoin, Charles",
        "City": "Lubbock",
        "Year": "1987",
        "lat": "33.58451",
        "Document": "docs/charles-beaudoin.pdf"
    },
    {
        "TroopNumber": "Troop 59",
        "lng": "-84.835249",
        "State": "Michigan",
        "NameOrID": "Workman, James Hardy",
        "City": "Charlotte",
        "Year": "1984",
        "lat": "42.563976",
        "Document": " docs/james-hardy-workman.pdf"
    },
    {
        "TroopNumber": "Troop 606",
        "lng": "-84.835249",
        "State": "Michigan",
        "NameOrID": "Workman, James Hardy",
        "City": "Charlotte",
        "Year": "1984",
        "lat": "42.563976",
        "Document": " docs/james-hardy-workman.pdf"
    },
    {
        "TroopNumber": "Pack 606",
        "lng": "-84.835249",
        "State": "Michigan",
        "NameOrID": "Workman, James Hardy",
        "City": "Charlotte",
        "Year": "1984",
        "lat": "42.563976",
        "Document": " docs/james-hardy-workman.pdf"
    },
    {
        "TroopNumber": "Troop 324",
        "lng": "",
        "State": "",
        "NameOrID": "Macemore, Claude E.",
        "City": "",
        "Year": "1974",
        "lat": "",
        "Document": " docs/claude-e-macemore.pdf"
    },
    {
        "TroopNumber": "Troop 410",
        "lng": "-77.433654",
        "State": "Virginia",
        "NameOrID": "Hampton, Thomas B.",
        "City": "Richmond",
        "Year": "1972",
        "lat": "37.5407",
        "Document": " docs/thomas-b-hampton.pdf"
    },
    {
        "TroopNumber": "Troop 703",
        "lng": "-77.433654",
        "State": "Virginia",
        "NameOrID": "Hampton, Thomas B.",
        "City": "Richmond",
        "Year": "1972",
        "lat": "37.5407",
        "Document": " docs/thomas-b-hampton.pdf"
    },
    {
        "TroopNumber": "Troop 701",
        "lng": "-77.433654",
        "State": "Virginia",
        "NameOrID": "Hampton, Thomas B.",
        "City": "Richmond",
        "Year": "1972",
        "lat": "37.5407",
        "Document": " docs/thomas-b-hampton.pdf"
    },
    {
        "TroopNumber": "Pack 75",
        "lng": "-71.136494",
        "State": "Massachusetts",
        "NameOrID": "Darby, Ernest M.",
        "City": "Andover",
        "Year": "1988",
        "lat": "42.653565",
        "Document": "docs/ernest-m-darby.pdf"
    },
    {
        "TroopNumber": "Troop 79",
        "lng": "-71.136494",
        "State": "Massachusetts",
        "NameOrID": "Darby, Ernest M.",
        "City": "Andover",
        "Year": "1988",
        "lat": "42.653565",
        "Document": "docs/ernest-m-darby.pdf"
    },
    {
        "TroopNumber": "Explorer 829",
        "lng": "-76.127694",
        "State": "New York",
        "NameOrID": "Filicia, Salvatore",
        "City": "Pulaski",
        "Year": "1985",
        "lat": "43.566588",
        "Document": "docs/salvatore-filicia.pdf"
    },
    {
        "TroopNumber": "Troop 829",
        "lng": "-76.127694",
        "State": "New York",
        "NameOrID": "Filicia, Salvatore",
        "City": "Pulaski",
        "Year": "1985",
        "lat": "43.566588",
        "Document": "docs/salvatore-filicia.pdf"
    },
    {
        "TroopNumber": "Troop 829",
        "lng": "-76.127694",
        "State": "New York",
        "NameOrID": "Filicia, Salvatore",
        "City": "Pulaski",
        "Year": "1985",
        "lat": "43.566588",
        "Document": "docs/salvatore-filicia.pdf"
    },
    {
        "TroopNumber": "Pack 854",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Draper, John T.",
        "City": "Houston",
        "Year": "1990",
        "lat": "29.76045",
        "Document": "docs/john-t-draper.pdf"
    },
    {
        "TroopNumber": "Troop 854",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Draper, John T.",
        "City": "Houston",
        "Year": "1990",
        "lat": "29.76045",
        "Document": "docs/john-t-draper.pdf"
    },
    {
        "TroopNumber": "Explorer 854",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Draper, John T.",
        "City": "Houston",
        "Year": "1990",
        "lat": "29.76045",
        "Document": "docs/john-t-draper.pdf"
    },
    {
        "TroopNumber": "Troop 9",
        "lng": "-72.452326",
        "State": "Massachusetts",
        "NameOrID": "Galbraith, Bruce",
        "City": "Northfield",
        "Year": "1988",
        "lat": "42.702909",
        "Document": "docs/bruce-galbraith.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-72.551089",
        "State": "Massachusetts",
        "NameOrID": "Galbraith, Bruce",
        "City": "Turner's Falls",
        "Year": "1988",
        "lat": "42.59858",
        "Document": "docs/bruce-galbraith.pdf"
    },
    {
        "TroopNumber": "Troop 901",
        "lng": "-72.598119",
        "State": "Massachusetts",
        "NameOrID": "Galbraith, Bruce",
        "City": "Greenfield",
        "Year": "1988",
        "lat": "42.587535",
        "Document": "docs/bruce-galbraith.pdf"
    },
    {
        "TroopNumber": "Troop 40",
        "lng": "-82.459269",
        "State": "Florida",
        "NameOrID": "Baldwin, William Mark",
        "City": "Tampa",
        "Year": "1983",
        "lat": "27.94653",
        "Document": " docs/william-mark-baldwin.pdf"
    },
    {
        "TroopNumber": "Pack 40",
        "lng": "-82.459269",
        "State": "Florida",
        "NameOrID": "Baldwin, William Mark",
        "City": "Tampa",
        "Year": "1983",
        "lat": "27.94653",
        "Document": " docs/william-mark-baldwin.pdf"
    },
    {
        "TroopNumber": "Explorer 911",
        "lng": "-82.459269",
        "State": "Florida",
        "NameOrID": "Baldwin, William Mark",
        "City": "Tampa",
        "Year": "1983",
        "lat": "27.94653",
        "Document": " docs/william-mark-baldwin.pdf"
    },
    {
        "TroopNumber": "Troop 213",
        "lng": "-119.749194",
        "State": "Nevada",
        "NameOrID": "Doran, Bernard P.",
        "City": "Sparks",
        "Year": "1980",
        "lat": "39.541",
        "Document": " docs/bernard-p-doran.pdf"
    },
    {
        "TroopNumber": "Explorer 113",
        "lng": "-119.749194",
        "State": "Nevada",
        "NameOrID": "Doran, Bernard P.",
        "City": "Sparks",
        "Year": "1980",
        "lat": "39.541",
        "Document": " docs/bernard-p-doran.pdf"
    },
    {
        "TroopNumber": "Explorer 913",
        "lng": "-119.749194",
        "State": "Nevada",
        "NameOrID": "Doran, Bernard P.",
        "City": "Sparks",
        "Year": "1980",
        "lat": "39.541",
        "Document": " docs/bernard-p-doran.pdf"
    },
    {
        "TroopNumber": "Troop 213",
        "lng": "-119.749194",
        "State": "Nevada",
        "NameOrID": "Doran, Bernard P.",
        "City": "Sparks",
        "Year": "1980",
        "lat": "39.541",
        "Document": " docs/bernard-p-doran.pdf"
    },
    {
        "TroopNumber": "Pack 3200",
        "lng": "",
        "State": "",
        "NameOrID": "Hale, James A.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/james-a-hale.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-157.96479",
        "State": "Hawaii",
        "NameOrID": "Hale, James A.",
        "City": "Oahu",
        "Year": "1988",
        "lat": "21.483595",
        "Document": "docs/james-a-hale.pdf"
    },
    {
        "TroopNumber": "Troop 221",
        "lng": "-94.415629",
        "State": "Missouri",
        "NameOrID": "Tezon, Peter E.",
        "City": "Independence",
        "Year": "1987",
        "lat": "39.093245",
        "Document": "docs/peter-e-tezon.pdf"
    },
    {
        "TroopNumber": "Pack 165",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Tezon, Peter E.",
        "City": "Kansas City",
        "Year": "1987",
        "lat": "39.10296",
        "Document": "docs/peter-e-tezon.pdf"
    },
    {
        "TroopNumber": "Explorer 2147",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Tezon, Peter E.",
        "City": "Kansas City",
        "Year": "1987",
        "lat": "39.10296",
        "Document": "docs/peter-e-tezon.pdf"
    },
    {
        "TroopNumber": "Troop 314",
        "lng": "-82.178149",
        "State": "Ohio",
        "NameOrID": "Sherrard, David",
        "City": "Lorain",
        "Year": "1985",
        "lat": "41.4682",
        "Document": " docs/david-sherrard.pdf"
    },
    {
        "TroopNumber": "Pack 3591",
        "lng": "",
        "State": "",
        "NameOrID": "Kriner, Kevin George",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/kevin-george-kriner.pdf"
    },
    {
        "TroopNumber": "Troop 863",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Stensland, Donald",
        "City": "Chicago",
        "Year": "1990",
        "lat": "41.88415",
        "Document": "docs/donald-stensland.pdf"
    },
    {
        "TroopNumber": "Explorer 2863",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Stensland, Donald",
        "City": "Chicago",
        "Year": "1990",
        "lat": "41.88415",
        "Document": "docs/donald-stensland.pdf"
    },
    {
        "TroopNumber": "Pack 3863",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Stensland, Donald",
        "City": "Chicago",
        "Year": "1990",
        "lat": "41.88415",
        "Document": "docs/donald-stensland.pdf"
    },
    {
        "TroopNumber": "Explorer 9763",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Stensland, Donald",
        "City": "Chicago",
        "Year": "1990",
        "lat": "41.88415",
        "Document": "docs/donald-stensland.pdf"
    },
    {
        "TroopNumber": "Troop 396",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Buzzek, David J.",
        "City": "Chicago",
        "Year": "1977",
        "lat": "41.88415",
        "Document": " docs/david-j-buzzek.pdf"
    },
    {
        "TroopNumber": "District 904",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Buzzek, David J.",
        "City": "Chicago",
        "Year": "1977",
        "lat": "41.88415",
        "Document": " docs/david-j-buzzek.pdf"
    },
    {
        "TroopNumber": "District 3904",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Buzzek, David J.",
        "City": "Chicago",
        "Year": "1977",
        "lat": "41.88415",
        "Document": " docs/david-j-buzzek.pdf"
    },
    {
        "TroopNumber": "District 2904",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Buzzek, David J.",
        "City": "Chicago",
        "Year": "1977",
        "lat": "41.88415",
        "Document": " docs/david-j-buzzek.pdf"
    },
    {
        "TroopNumber": "Pack 3883",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Wishnie, Joseph B.",
        "City": "Chicago",
        "Year": "1965",
        "lat": "41.88415",
        "Document": "docs/joseph-b-wishnie.pdf"
    },
    {
        "TroopNumber": "Pack 3981",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Wishnie, Joseph B.",
        "City": "Chicago",
        "Year": "1965",
        "lat": "41.88415",
        "Document": "docs/joseph-b-wishnie.pdf"
    },
    {
        "TroopNumber": "Pack 3956",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Wishnie, Joseph B.",
        "City": "Chicago",
        "Year": "1965",
        "lat": "41.88415",
        "Document": "docs/joseph-b-wishnie.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 34",
        "lng": "",
        "State": "",
        "NameOrID": "Herring, David Thomas",
        "City": "",
        "Year": "1962",
        "lat": "",
        "Document": "docs/david-thomas-herring.pdf"
    },
    {
        "TroopNumber": "Troop 124",
        "lng": "-81.722139",
        "State": "South Carolina",
        "NameOrID": "Burgess, Mitchell P.",
        "City": "Aiken ",
        "Year": "1985",
        "lat": "33.561645",
        "Document": "docs/mitchell-p-burgess.pdf"
    },
    {
        "TroopNumber": "Troop 404",
        "lng": "-81.722139",
        "State": "South Carolina",
        "NameOrID": "Burgess, Mitchell P.",
        "City": "Aiken",
        "Year": "1985",
        "lat": "33.561645",
        "Document": "docs/mitchell-p-burgess.pdf"
    },
    {
        "TroopNumber": "Troop 8604",
        "lng": "-81.722139",
        "State": "South Carolina",
        "NameOrID": "Burgess, Mitchell P.",
        "City": "Aiken",
        "Year": "1985",
        "lat": "33.561645",
        "Document": "docs/mitchell-p-burgess.pdf"
    },
    {
        "TroopNumber": "Troop 146",
        "lng": "-81.722139",
        "State": "South Carolina",
        "NameOrID": "Burgess, Mitchell P.",
        "City": "Aiken",
        "Year": "1985",
        "lat": "33.561645",
        "Document": "docs/mitchell-p-burgess.pdf"
    },
    {
        "TroopNumber": "Troop 147",
        "lng": "-74.158979",
        "State": "New Jersey",
        "NameOrID": "Abidiwan, Michael J.",
        "City": "Nutley",
        "Year": "1984",
        "lat": "40.819955",
        "Document": " docs/michael-j-abidiwan.pdf"
    },
    {
        "TroopNumber": "Troop 331",
        "lng": "-90.811633",
        "State": "Louisiana",
        "NameOrID": "Adams, Steve Allen",
        "City": "Schriever",
        "Year": "1987",
        "lat": "29.743906",
        "Document": "docs/steve-allen-adams.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-89.434937",
        "State": "Mississippi",
        "NameOrID": "Adams, Steve Allen",
        "City": "Kiln",
        "Year": "1987",
        "lat": "30.41044",
        "Document": "docs/steve-allen-adams.pdf"
    },
    {
        "TroopNumber": "Troop 4",
        "lng": "",
        "State": "",
        "NameOrID": "Adams, Julian",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/julian-adams.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-82.445369",
        "State": "West Virginia",
        "NameOrID": "Adkins, Royce Lee",
        "City": "Huntington",
        "Year": "1971",
        "lat": "38.419825",
        "Document": " docs/royce-lee-adkins.pdf"
    },
    {
        "TroopNumber": "Pack 36",
        "lng": "-94.209494",
        "State": "Arkansas",
        "NameOrID": "Agarand, Joseph A.",
        "City": "Bentonville",
        "Year": "1990",
        "lat": "36.372335",
        "Document": "docs/joseph-a-agarand.pdf"
    },
    {
        "TroopNumber": "Troop 225",
        "lng": "-97.79567",
        "State": "Texas",
        "NameOrID": "Ahern, Stephen Edward",
        "City": "Ft. Hood",
        "Year": "1989",
        "lat": "31.12258",
        "Document": "docs/stephen-edward-ahern.pdf"
    },
    {
        "TroopNumber": "Troop 21",
        "lng": "-80.237419",
        "State": "Florida",
        "NameOrID": "Aiken, David",
        "City": "Miami",
        "Year": "1984",
        "lat": "25.728985",
        "Document": " docs/david-aiken.pdf"
    },
    {
        "TroopNumber": "Explorer 724",
        "lng": "-73.941026",
        "State": "New York",
        "NameOrID": "Alberga, Karl A.",
        "City": "Schenectady",
        "Year": "1989",
        "lat": "42.81226",
        "Document": "docs/karl-a-alberga.pdf"
    },
    {
        "TroopNumber": "Troop 357",
        "lng": "-73.941026",
        "State": "New York",
        "NameOrID": "Alberga, Karl A.",
        "City": "Schenectady",
        "Year": "1989",
        "lat": "42.81226",
        "Document": "docs/karl-a-alberga.pdf"
    },
    {
        "TroopNumber": "Pack 535",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Albright, Charles F.",
        "City": "Dallas",
        "Year": "1984",
        "lat": "32.778155",
        "Document": " docs/charles-f-albright.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Alexander, Arthur W.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/arthur-w-alexander.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Alexander, Ray",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/ray-alexander.pdf"
    },
    {
        "TroopNumber": "Troop 57",
        "lng": "-102.375189",
        "State": "Texas",
        "NameOrID": "Alford, Kenneth Lyle",
        "City": "Odessa",
        "Year": "1987",
        "lat": "31.84915",
        "Document": "docs/kenneth-lyle-alford.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "",
        "State": "",
        "NameOrID": "Allan, Robert",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/robert-allan.pdf"
    },
    {
        "TroopNumber": "Troop 612",
        "lng": "-122.353259",
        "State": "Washington",
        "NameOrID": "Allen, Ronald William",
        "City": "Washougal",
        "Year": "1988",
        "lat": "45.579225",
        "Document": "docs/ronald-william-allen.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-82.716164",
        "State": "Florida",
        "NameOrID": "Allen, Girard H.",
        "City": "New Port Richey",
        "Year": "1972",
        "lat": "28.250195",
        "Document": " docs/girard-h-allen.pdf"
    },
    {
        "TroopNumber": "Pack 407",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "Allen, Jeffrey",
        "City": "Worcester",
        "Year": "1990",
        "lat": "42.263415",
        "Document": "docs/jeffrey-allen.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Allen, Lawrence N.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/lawrence-n-allen.pdf"
    },
    {
        "TroopNumber": "Troop 8",
        "lng": "-72.924979",
        "State": "Connecticut",
        "NameOrID": "Alpren, Larry K.",
        "City": "New Haven",
        "Year": "1987",
        "lat": "41.30713",
        "Document": "docs/larry-k-alpren.pdf"
    },
    {
        "TroopNumber": "Troop 25",
        "lng": "-85.444799",
        "State": "Indiana",
        "NameOrID": "Alter, Michael R.",
        "City": "Rushville",
        "Year": "1988",
        "lat": "39.60876",
        "Document": "docs/michael-r-alter.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-84.504552",
        "State": "Ohio",
        "NameOrID": "Altomere, Joseph F.",
        "City": "Cincinnati",
        "Year": "1989",
        "lat": "39.106614",
        "Document": "docs/joseph-f-altomere.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-89.643604",
        "State": "Illinois",
        "NameOrID": "Altomere, Joseph F.",
        "City": "Springfield",
        "Year": "1989",
        "lat": "39.801055",
        "Document": "docs/joseph-f-altomere.pdf"
    },
    {
        "TroopNumber": "14",
        "lng": "-82.445369",
        "State": "West Virginia",
        "NameOrID": "Aluise, Richard Augustus",
        "City": "Huntington",
        "Year": "1985",
        "lat": "38.419825",
        "Document": "docs/richard-augustus-aluise.pdf"
    },
    {
        "TroopNumber": "99",
        "lng": "-82.445369",
        "State": "West Virginia",
        "NameOrID": "Aluise, Richard Augustus",
        "City": "Huntington",
        "Year": "1985",
        "lat": "38.419825",
        "Document": "docs/richard-augustus-aluise.pdf"
    },
    {
        "TroopNumber": "Pack 1022",
        "lng": "-83.047924",
        "State": "Michigan",
        "NameOrID": "Anderson, William J.",
        "City": "Detroit",
        "Year": "1990",
        "lat": "42.331685",
        "Document": "docs/william-j-anderson.pdf"
    },
    {
        "TroopNumber": "Troop 126",
        "lng": "-115.139974",
        "State": "Nevada",
        "NameOrID": "Anderson, Charles O.",
        "City": "Las Vegas",
        "Year": "1984",
        "lat": "36.171915",
        "Document": " docs/charles-o-anderson.pdf"
    },
    {
        "TroopNumber": "Pack 132",
        "lng": "-98.682759",
        "State": "Oklahoma",
        "NameOrID": "Anderson, Frank R.",
        "City": "Grandfield",
        "Year": "1990",
        "lat": "34.23188",
        "Document": "docs/frank-r-anderson.pdf"
    },
    {
        "TroopNumber": "Troop 73",
        "lng": "-75.513949",
        "State": "Pennsylvania",
        "NameOrID": "Appler, Russell C.",
        "City": "Phoenixville",
        "Year": "1989",
        "lat": "40.13313",
        "Document": "docs/russell-c-appler.pdf"
    },
    {
        "TroopNumber": "Pack 24",
        "lng": "-76.301269",
        "State": "Pennsylvania",
        "NameOrID": "Arce, Jesus Nathaniel",
        "City": "Lancaster",
        "Year": "1986",
        "lat": "40.038045",
        "Document": "docs/jesus-nathaniel-arce.pdf"
    },
    {
        "TroopNumber": "Pack 43",
        "lng": "-76.301269",
        "State": "Pennsylvania",
        "NameOrID": "Arce, Jesus Nathaniel",
        "City": "Lancaster",
        "Year": "1986",
        "lat": "40.038045",
        "Document": "docs/jesus-nathaniel-arce.pdf"
    },
    {
        "TroopNumber": "Troop 171",
        "lng": "-117.373879",
        "State": "California",
        "NameOrID": "Ardrey, Richard H.",
        "City": "Riverside",
        "Year": "1983",
        "lat": "33.98163",
        "Document": " docs/richard-h-ardrey.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-122.441654",
        "State": "Washington",
        "NameOrID": "Arevalo, Marcos Adrian",
        "City": "Tacoma",
        "Year": "1991",
        "lat": "47.255135",
        "Document": "docs/marcos-adrian-arevalo.pdf"
    },
    {
        "TroopNumber": "Troop 421",
        "lng": "-97.742984",
        "State": "Texas",
        "NameOrID": "Argyris, Alexander Dalton",
        "City": "Austin",
        "Year": "1989",
        "lat": "30.267605",
        "Document": "docs/alexander-dalton-argyris.pdf"
    },
    {
        "TroopNumber": "Troop 557",
        "lng": "-93.747469",
        "State": "Iowa",
        "NameOrID": "Armstrong, Vaughn A.",
        "City": "Leon",
        "Year": "1979",
        "lat": "40.739515",
        "Document": " docs/vaughn-a-armstrong.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Armstrong, Isaiah",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/isaiah-armstrong.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-116.193409",
        "State": "Idaho",
        "NameOrID": "Arnold, Larren Bybee",
        "City": "Boise",
        "Year": "1991",
        "lat": "43.60698",
        "Document": "docs/larren-bybee-arnold.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-111.888229",
        "State": "Utah",
        "NameOrID": "Arnold, Larren Bybee",
        "City": "Salt Lake City",
        "Year": "1991",
        "lat": "40.759505",
        "Document": "docs/larren-bybee-arnold.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-98.44575",
        "State": "Texas",
        "NameOrID": "Artusy, Willard Henry",
        "City": "Kingsland",
        "Year": "1987",
        "lat": "30.660876",
        "Document": "docs/willard-henry-artusy.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-82.879594",
        "State": "Ohio",
        "NameOrID": "Ashbrook, Lester A.",
        "City": "Galena",
        "Year": "1983",
        "lat": "40.215145",
        "Document": " docs/lester-a-ashbrook.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-89.386694",
        "State": "Wisconsin",
        "NameOrID": "Ashmore, Travis Asbjorn",
        "City": "Madison",
        "Year": "1989",
        "lat": "43.07295",
        "Document": "docs/travis-asbjorn-ashmore.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Astleford, Dale Lawrence",
        "City": "",
        "Year": "1984",
        "lat": "",
        "Document": " docs/dale-lawrence-astleford.pdf"
    },
    {
        "TroopNumber": "Troop 105",
        "lng": "-98.389919",
        "State": "Oklahoma",
        "NameOrID": "Atteberry, Edsel Ray",
        "City": "Lawton",
        "Year": "1971",
        "lat": "34.605185",
        "Document": " docs/edsel-ray-atteberry.pdf"
    },
    {
        "TroopNumber": "Explorer 336",
        "lng": "-117.850399",
        "State": "California",
        "NameOrID": "Atwood, John",
        "City": "Orange",
        "Year": "1991",
        "lat": "33.787865",
        "Document": "docs/john-atwood.pdf"
    },
    {
        "TroopNumber": "Troop 210",
        "lng": "-119.180439",
        "State": "California",
        "NameOrID": "Atwood, John",
        "City": "Oxnard",
        "Year": "1991",
        "lat": "34.20034",
        "Document": "docs/john-atwood.pdf"
    },
    {
        "TroopNumber": "Troop 87",
        "lng": "-71.025665",
        "State": "Massachusetts",
        "NameOrID": "Auclair, James Andrew",
        "City": "Groveland",
        "Year": "1988",
        "lat": "42.767048",
        "Document": "docs/james-andrew-auclair.pdf"
    },
    {
        "TroopNumber": "264",
        "lng": "-122.491114",
        "State": "California",
        "NameOrID": "Auleb, Robert Kenneth",
        "City": "Pacifica",
        "Year": "1988",
        "lat": "37.635265",
        "Document": "docs/robert-kenneth-auleb.pdf"
    },
    {
        "TroopNumber": "Pack 441",
        "lng": "-76.059204",
        "State": "Virginia",
        "NameOrID": "Avant, Eric Patrick",
        "City": "Virginia Beach",
        "Year": "1988",
        "lat": "36.75502",
        "Document": "docs/eric-patrick-avant.pdf"
    },
    {
        "TroopNumber": "Pack 196",
        "lng": "",
        "State": "New York",
        "NameOrID": "Aviles, Daniel",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/daniel-aviles.pdf"
    },
    {
        "TroopNumber": "Pack 3329",
        "lng": "-87.669354",
        "State": "Michigan",
        "NameOrID": "Ayotte, Wilfred J.",
        "City": "Ishpeming",
        "Year": "1989",
        "lat": "46.48863",
        "Document": "docs/wilfred-j-ayotte.pdf"
    },
    {
        "TroopNumber": "Troop 51",
        "lng": "-75.198234",
        "State": "New Jersey",
        "NameOrID": "Azzalina, Tom",
        "City": "Phillipsburg",
        "Year": "1989",
        "lat": "40.694569",
        "Document": "docs/tom-azzalina.pdf"
    },
    {
        "TroopNumber": "Troop 10",
        "lng": "-73.629619",
        "State": "Connecticut",
        "NameOrID": "Babyak, Donald R.",
        "City": "Greenwich",
        "Year": "1989",
        "lat": "41.02525",
        "Document": "docs/donald-r-babyak.pdf"
    },
    {
        "TroopNumber": "Explorer 5",
        "lng": "-122.875614",
        "State": "Oregon",
        "NameOrID": "Backes, Richard Allen",
        "City": "Medford",
        "Year": "1984",
        "lat": "42.32356",
        "Document": " docs/richard-allen-backes.pdf"
    },
    {
        "TroopNumber": "Pack 3049",
        "lng": "-85.014842",
        "State": "Michigan",
        "NameOrID": "Backoff, John A.",
        "City": "Boyne City",
        "Year": "1991",
        "lat": "45.218422",
        "Document": "docs/john-a-backoff.pdf"
    },
    {
        "TroopNumber": "Troop 361",
        "lng": "-77.860704",
        "State": "Pennsylvania",
        "NameOrID": "Bagshaw, Timothy J.",
        "City": "State College ",
        "Year": "1984",
        "lat": "40.79373",
        "Document": " docs/timothy-j-bagshaw.pdf"
    },
    {
        "TroopNumber": "Troop 133",
        "lng": "-95.993334",
        "State": "Oklahoma",
        "NameOrID": "Bahaus, Michael John",
        "City": "Tulsa",
        "Year": "1991",
        "lat": "36.149745",
        "Document": "docs/michael-john-bahaus.pdf"
    },
    {
        "TroopNumber": "Troop 82",
        "lng": "-68.795751",
        "State": "Maine",
        "NameOrID": "Bailey, Harold E.",
        "City": "Bucksport",
        "Year": "1978",
        "lat": "44.573136",
        "Document": "docs/harold-e-bailey.pdf"
    },
    {
        "TroopNumber": "Troop 44",
        "lng": "-123.043814",
        "State": "Oregon",
        "NameOrID": "Bailey, Thomas Lee",
        "City": "Salem",
        "Year": "1987",
        "lat": "44.93326",
        "Document": "docs/thomas-lee-bailey.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Bailey, William N.",
        "City": "Philadelphia",
        "Year": "1989",
        "lat": "39.95228",
        "Document": "docs/william-n-bailey.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Bailey, Michael Edward",
        "City": "",
        "Year": "1984",
        "lat": "",
        "Document": " docs/michael-edward-bailey.pdf"
    },
    {
        "TroopNumber": "Troop 204",
        "lng": "-84.156009",
        "State": "Georgia",
        "NameOrID": "Baird, Rupert M.",
        "City": "Albany",
        "Year": "1972",
        "lat": "31.58076",
        "Document": " docs/rupert-m-baird.pdf"
    },
    {
        "TroopNumber": "Pack 3099",
        "lng": "-83.536259",
        "State": "Ohio",
        "NameOrID": "Baker, Alan Douglas",
        "City": "Toledo",
        "Year": "1982",
        "lat": "41.65381",
        "Document": " docs/alan-douglas-baker.pdf"
    },
    {
        "TroopNumber": "Pack 3211",
        "lng": "-83.536259",
        "State": "Ohio",
        "NameOrID": "Baker, Alan Douglas",
        "City": "Toledo",
        "Year": "1982",
        "lat": "41.65381",
        "Document": " docs/alan-douglas-baker.pdf"
    },
    {
        "TroopNumber": "Pack 3110",
        "lng": "-84.022131",
        "State": "Ohio",
        "NameOrID": "Baker, John A.",
        "City": "Fairborn",
        "Year": "1986",
        "lat": "39.82715",
        "Document": "docs/john-a-baker.pdf"
    },
    {
        "TroopNumber": "Troop 547",
        "lng": "-78.824734",
        "State": "New York",
        "NameOrID": "Baker, William D.",
        "City": "Lackawanna",
        "Year": "1988",
        "lat": "42.8258",
        "Document": "docs/william-d-baker.pdf"
    },
    {
        "TroopNumber": "Troop 320",
        "lng": "-86.300629",
        "State": "Alabama",
        "NameOrID": "Baker, Claiborne B.",
        "City": "Montgomery",
        "Year": "1989",
        "lat": "32.38012",
        "Document": "docs/claiborne-b-baker.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-79.331214",
        "State": "New York",
        "NameOrID": "Balles, James Richard",
        "City": "Fredonia",
        "Year": "1988",
        "lat": "42.440135",
        "Document": "docs/james-richard-balles.pdf"
    },
    {
        "TroopNumber": "476",
        "lng": "-112.064609",
        "State": "Utah",
        "NameOrID": "Barber, Norman Carl",
        "City": "Syracuse",
        "Year": "1988",
        "lat": "41.088815",
        "Document": "docs/norman-carl-barber.pdf"
    },
    {
        "TroopNumber": "Troop 960",
        "lng": "-87.814579",
        "State": "Illinois",
        "NameOrID": "Bardy, Michael J.",
        "City": "Norridge",
        "Year": "1987",
        "lat": "41.953505",
        "Document": "docs/michael-j-bardy.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Barner, Bryan A.",
        "City": "",
        "Year": "1984",
        "lat": "",
        "Document": " docs/bryan-a-barner.pdf"
    },
    {
        "TroopNumber": "Troop 77",
        "lng": "",
        "State": "",
        "NameOrID": "Barnes, Matthew",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/matthew-barnes.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Barnes, Walter C.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/walter-c-barnes.pdf"
    },
    {
        "TroopNumber": "273",
        "lng": "-122.025924",
        "State": "California",
        "NameOrID": "Barney, William",
        "City": "Union City",
        "Year": "1987",
        "lat": "37.588395",
        "Document": "docs/william-barney.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-80.812804",
        "State": "Ohio",
        "NameOrID": "Barnhart, Thomas",
        "City": "Warren",
        "Year": "1984",
        "lat": "41.2362",
        "Document": " docs/thomas-barnhart.pdf"
    },
    {
        "TroopNumber": "50",
        "lng": "-74.188929",
        "State": "New York",
        "NameOrID": "Barone, Richard V.",
        "City": "Amsterdam",
        "Year": "1985",
        "lat": "42.938969",
        "Document": "docs/richard-v-barone.pdf"
    },
    {
        "TroopNumber": "Troop 732",
        "lng": "-84.516434",
        "State": "Georgia",
        "NameOrID": "Barrett, Mike L.",
        "City": "Smyrna",
        "Year": "1973",
        "lat": "33.88486",
        "Document": " docs/mike-l-barrett.pdf"
    },
    {
        "TroopNumber": "Troop 51",
        "lng": "-82.578074",
        "State": "Florida",
        "NameOrID": "Bartel, Benjamin Reese",
        "City": "Bradenton",
        "Year": "1982",
        "lat": "27.4955",
        "Document": " docs/benjamin-reese-bartel.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-82.578074",
        "State": "Florida",
        "NameOrID": "Bartel, Benjamin Reese",
        "City": "Bradenton",
        "Year": "1982",
        "lat": "27.4955",
        "Document": " docs/benjamin-reese-bartel.pdf"
    },
    {
        "TroopNumber": "Explorer 580",
        "lng": "-89.018214",
        "State": "Wisconsin",
        "NameOrID": "Bartelt, LaVern J.",
        "City": "Janesville",
        "Year": "1990",
        "lat": "42.687892",
        "Document": "docs/lavern-j-bartelt.pdf"
    },
    {
        "TroopNumber": "254",
        "lng": "-119.180439",
        "State": "California",
        "NameOrID": "Bartholomew, Edward Bruce",
        "City": "Oxnard",
        "Year": "1985",
        "lat": "34.20034",
        "Document": "docs/edward-bruce-bartholomew.pdf"
    },
    {
        "TroopNumber": "Troop 62",
        "lng": "-74.174184",
        "State": "New Jersey",
        "NameOrID": "Bartiromo, Pasquale J.",
        "City": "Newark",
        "Year": "1986",
        "lat": "40.73197",
        "Document": "docs/pasquale-j-bartiromo.pdf"
    },
    {
        "TroopNumber": "Troop 49",
        "lng": "-71.411994",
        "State": "Rhode Island",
        "NameOrID": "Bartlett, Jeffrey A.",
        "City": "Providence",
        "Year": "1988",
        "lat": "41.823875",
        "Document": "docs/jeffrey-a-bartlett.pdf"
    },
    {
        "TroopNumber": "229",
        "lng": "-80.250729",
        "State": "North Carolina",
        "NameOrID": "Barton, James D.",
        "City": "Lexington",
        "Year": "1983",
        "lat": "35.826245",
        "Document": " docs/james-d-barton.pdf"
    },
    {
        "TroopNumber": "Pack 3408",
        "lng": "-76.075237",
        "State": "Pennsylvania",
        "NameOrID": "Bath, Edward George",
        "City": "Glen Lyon",
        "Year": "1991",
        "lat": "41.173945",
        "Document": "docs/edward-george-bath.pdf"
    },
    {
        "TroopNumber": "Troop 150",
        "lng": "-119.094559",
        "State": "Washington",
        "NameOrID": "Baugh, Denis",
        "City": "Pasco",
        "Year": "1987",
        "lat": "46.23518",
        "Document": "docs/denis-baugh.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-76.074749",
        "State": "Maryland",
        "NameOrID": "Bauman, Robert E.",
        "City": "Easton",
        "Year": "1980",
        "lat": "38.773905",
        "Document": " docs/robert-e-bauman.pdf"
    },
    {
        "TroopNumber": "Troop 310",
        "lng": "121.834675",
        "State": "Philippines",
        "NameOrID": "Baxley, James Allen",
        "City": "Clark AB",
        "Year": "1988",
        "lat": "12.85113",
        "Document": "docs/james-allen-baxley.pdf"
    },
    {
        "TroopNumber": "Troop 729",
        "lng": "-110.969754",
        "State": "Arizona",
        "NameOrID": "Beahm, Harley David",
        "City": "Tucson",
        "Year": "1989",
        "lat": "32.221553",
        "Document": "docs/harley-david-beahm.pdf"
    },
    {
        "TroopNumber": "Troop 993",
        "lng": "-77.043414",
        "State": "Virginia",
        "NameOrID": "Beard, Ronald",
        "City": "Alexandria",
        "Year": "1988",
        "lat": "38.804605",
        "Document": "docs/ronald-beard.pdf"
    },
    {
        "TroopNumber": "Troop 66",
        "lng": "-93.093326",
        "State": "Minnesota",
        "NameOrID": "Beardsley, David Jerome",
        "City": "St. Paul",
        "Year": "1972",
        "lat": "44.943829",
        "Document": " docs/david-jerome-beardsley.pdf"
    },
    {
        "TroopNumber": "Troop 358",
        "lng": "-86.394339",
        "State": "Tennessee",
        "NameOrID": "Beasley, Steven D.",
        "City": "Murfreesboro",
        "Year": "1977",
        "lat": "35.8444",
        "Document": " docs/steven-d-beasley.pdf"
    },
    {
        "TroopNumber": "Troop 112",
        "lng": "-85.536341",
        "State": "Tennessee",
        "NameOrID": "Beasley, Steven D.",
        "City": "Guild",
        "Year": "1977",
        "lat": "35.028522",
        "Document": " docs/steven-d-beasley.pdf"
    },
    {
        "TroopNumber": "Troop 146",
        "lng": "",
        "State": "West Virginia",
        "NameOrID": "Beasley, Charles E.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/charles-e-beasley.pdf"
    },
    {
        "TroopNumber": "Troop 222",
        "lng": "-77.131399",
        "State": "Pennsylvania",
        "NameOrID": "Beatty, Rodger L.",
        "City": "Newport",
        "Year": "1976",
        "lat": "40.47732",
        "Document": " docs/rodger-l-beatty.pdf"
    },
    {
        "TroopNumber": "Troop 82",
        "lng": "-82.323184",
        "State": "Florida",
        "NameOrID": "Beauregard, Albert W.",
        "City": "Gainesville",
        "Year": "1990",
        "lat": "29.65195",
        "Document": "docs/albert-w-beauregard.pdf"
    },
    {
        "TroopNumber": "Troop 93",
        "lng": "-83.065795",
        "State": "Ohio",
        "NameOrID": "Beck, Charles O.",
        "City": "Delaware",
        "Year": "1977",
        "lat": "40.300745",
        "Document": " docs/charles-o-beck.pdf"
    },
    {
        "TroopNumber": "Troop 1",
        "lng": "-73.979394",
        "State": "New Jersey",
        "NameOrID": "Becker, Ronald A.",
        "City": "Monmouth Beach",
        "Year": "1989",
        "lat": "40.331385",
        "Document": "docs/ronald-a-becker.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Becker, William N.",
        "City": "",
        "Year": "1972",
        "lat": "",
        "Document": " docs/william-n-becker.pdf"
    },
    {
        "TroopNumber": "Pack 3721",
        "lng": "-118.601564",
        "State": "California",
        "NameOrID": "Becker, Samuel J.",
        "City": "Canoga Park",
        "Year": "1991",
        "lat": "34.201965",
        "Document": "docs/samuel-j-becker.pdf"
    },
    {
        "TroopNumber": "Explorer 487",
        "lng": "-80.326614",
        "State": "Florida",
        "NameOrID": "Becker, Donald Charles",
        "City": "Ft. Pierce",
        "Year": "1988",
        "lat": "27.44865",
        "Document": "docs/donald-charles-becker.pdf"
    },
    {
        "TroopNumber": "Pack 7",
        "lng": "-117.373879",
        "State": "California",
        "NameOrID": "Beers, Chris J.",
        "City": "Riverside",
        "Year": "1990",
        "lat": "33.98163",
        "Document": "docs/chris-j-beers.pdf"
    },
    {
        "TroopNumber": "Troop 17",
        "lng": "",
        "State": "",
        "NameOrID": "Belford, Stephen P.",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": "docs/stephen-p-belford.pdf"
    },
    {
        "TroopNumber": "Troop 150",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Belke, Earl",
        "City": "Kansas City",
        "Year": "1980",
        "lat": "39.10296",
        "Document": " docs/earl-belke.pdf"
    },
    {
        "TroopNumber": "Troop 118",
        "lng": "-82.001389",
        "State": "Ohio",
        "NameOrID": "Bell, Harry E.",
        "City": "Zanesville",
        "Year": "1990",
        "lat": "39.940549",
        "Document": "docs/harry-e-bell.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-81.855272",
        "State": "Florida",
        "NameOrID": "Bell, Ronald Edwin",
        "City": "Groveland",
        "Year": "1989",
        "lat": "28.559287",
        "Document": "docs/ronald-edwin-bell.pdf"
    },
    {
        "TroopNumber": "Troop 311",
        "lng": "-80.143564",
        "State": "Florida",
        "NameOrID": "Bellavance, Donn T.",
        "City": "Ft. Lauderdale",
        "Year": "1990",
        "lat": "26.12367",
        "Document": "docs/donn-t-bellavance.pdf"
    },
    {
        "TroopNumber": "District 355",
        "lng": "-116.936529",
        "State": "Idaho",
        "NameOrID": "Belmer, Donald D.",
        "City": "Payette",
        "Year": "1988",
        "lat": "44.07429",
        "Document": "docs/donald-d-belmer.pdf"
    },
    {
        "TroopNumber": "Pack ",
        "lng": "",
        "State": "",
        "NameOrID": "Bendler, Clifford",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/clifford-bendler.pdf"
    },
    {
        "TroopNumber": "Troop 87",
        "lng": "126.985691",
        "State": "Korea",
        "NameOrID": "Bennett, David William",
        "City": "Yongsan",
        "Year": "1991",
        "lat": "37.525949",
        "Document": "docs/david-william-bennett.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 602",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Benson, Gregory A.",
        "City": "Portland",
        "Year": "1979",
        "lat": "45.511795",
        "Document": " docs/gregory-a-benson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Berger, Byron",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/byron-berger.pdf"
    },
    {
        "TroopNumber": "Troop 184",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Bergeron, Wilfred H.",
        "City": "Rochester",
        "Year": "1988",
        "lat": "43.1555",
        "Document": "docs/wilfred-h-bergeron.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Bernstein, Robert Bobby",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": "docs/robert-bobby-bernstein.pdf"
    },
    {
        "TroopNumber": "Troop 252",
        "lng": "-82.560419",
        "State": "Tennessee",
        "NameOrID": "Bethany, Jacob H.",
        "City": "Kingsport",
        "Year": "1977",
        "lat": "36.5478",
        "Document": " docs/jacob-h-bethany.pdf"
    },
    {
        "TroopNumber": "Pack 139",
        "lng": "-84.553414",
        "State": "Ohio",
        "NameOrID": "Betz, Donald L.",
        "City": "Sherwood",
        "Year": "1987",
        "lat": "41.286635",
        "Document": "docs/donald-l-betz.pdf"
    },
    {
        "TroopNumber": "Pack 139",
        "lng": "-84.553414",
        "State": "Ohio",
        "NameOrID": "Betz, Donald L.",
        "City": "Sherwood",
        "Year": "1987",
        "lat": "41.286635",
        "Document": "docs/donald-l-betz.pdf"
    },
    {
        "TroopNumber": "Troop 15",
        "lng": "",
        "State": "",
        "NameOrID": "Bibeau, David A.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/david-a-bibeau.pdf"
    },
    {
        "TroopNumber": "Troop 377",
        "lng": "-105.279239",
        "State": "Colorado",
        "NameOrID": "Bieber, Ray L.",
        "City": "Boulder",
        "Year": "1978",
        "lat": "40.01574",
        "Document": "docs/ray-l-bieber.pdf"
    },
    {
        "TroopNumber": "Troop 559",
        "lng": "-122.671559",
        "State": "Washington",
        "NameOrID": "Bilger, Mark William",
        "City": "Vancouver",
        "Year": "1989",
        "lat": "45.632215",
        "Document": "docs/mark-william-bilger.pdf"
    },
    {
        "TroopNumber": "Troop 120",
        "lng": "-92.566299",
        "State": "Missouri",
        "NameOrID": "Bird, Aubrey Eugene",
        "City": "California",
        "Year": "1989",
        "lat": "38.63579",
        "Document": "docs/aubrey-eugene-bird.pdf"
    },
    {
        "TroopNumber": "Explorer 120",
        "lng": "-92.566299",
        "State": "Missouri",
        "NameOrID": "Bird, Aubrey Eugene",
        "City": "California",
        "Year": "1989",
        "lat": "38.63579",
        "Document": "docs/aubrey-eugene-bird.pdf"
    },
    {
        "TroopNumber": "Troop 180",
        "lng": "-73.995589",
        "State": "New Jersey",
        "NameOrID": "Bischoff, Albert",
        "City": "Bergenfield",
        "Year": "1982",
        "lat": "40.93478",
        "Document": " docs/albert-bischoff.pdf"
    },
    {
        "TroopNumber": "Troop 31",
        "lng": "-72.878003",
        "State": "Connecticut",
        "NameOrID": "Bishop, David A.",
        "City": "Southington",
        "Year": "1988",
        "lat": "41.599451",
        "Document": "docs/david-a-bishop.pdf"
    },
    {
        "TroopNumber": "Pack 7",
        "lng": "-71.464209",
        "State": "New Hampshire",
        "NameOrID": "Bishop, Robert Frederick",
        "City": "Nashua",
        "Year": "1973",
        "lat": "42.758365",
        "Document": " docs/robert-frederick-bishop.pdf"
    },
    {
        "TroopNumber": "Troop 33",
        "lng": "-71.281471",
        "State": "Massachusetts",
        "NameOrID": "Bishop, Edward",
        "City": "Bedford",
        "Year": "1989",
        "lat": "42.491891",
        "Document": "docs/edward-bishop.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Bittenbender, Carlton L.",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": "docs/carlton-l-bittenbender.pdf"
    },
    {
        "TroopNumber": "1970",
        "lng": "-77.354571",
        "State": "Virginia",
        "NameOrID": "Bittenbender, Carlton L.",
        "City": "Reston",
        "Year": "1985",
        "lat": "38.959374",
        "Document": "docs/carlton-l-bittenbender.pdf"
    },
    {
        "TroopNumber": "Troop 127",
        "lng": "-92.119049",
        "State": "Louisiana",
        "NameOrID": "Bivins, Lewis T.",
        "City": "Monroe",
        "Year": "1989",
        "lat": "32.50965",
        "Document": "docs/lewis-t-bivins.pdf"
    },
    {
        "TroopNumber": "Pack 3241",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Bjorklund, Buford E.",
        "City": "Houston",
        "Year": "1988",
        "lat": "29.76045",
        "Document": "docs/buford-e-bjorklund.pdf"
    },
    {
        "TroopNumber": "Troop 610",
        "lng": "-80.053093",
        "State": "Virginia",
        "NameOrID": "Blackiston, John William",
        "City": "Salem",
        "Year": "1991",
        "lat": "37.293668",
        "Document": "docs/john-william-blackiston.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Blacklock, Russell",
        "City": "",
        "Year": "1983",
        "lat": "",
        "Document": " docs/russell-blacklock.pdf"
    },
    {
        "TroopNumber": "Pack 493",
        "lng": "-121.302544",
        "State": "California",
        "NameOrID": "Blackwell, Terry",
        "City": "Lathrop",
        "Year": "1990",
        "lat": "37.804395",
        "Document": "docs/terry-blackwell.pdf"
    },
    {
        "TroopNumber": "Troop 30",
        "lng": "-81.090719",
        "State": "Georgia",
        "NameOrID": "Blaine, B. Christy",
        "City": "Savannah",
        "Year": "1987",
        "lat": "32.08078",
        "Document": "docs/b-christy-blaine.pdf"
    },
    {
        "TroopNumber": "Troop 202",
        "lng": "-81.495564",
        "State": "Georgia",
        "NameOrID": "Blaine, B. Christy",
        "City": "Brunswick",
        "Year": "1987",
        "lat": "31.149765",
        "Document": "docs/b-christy-blaine.pdf"
    },
    {
        "TroopNumber": "Troop 355",
        "lng": "-98.707034",
        "State": "Oklahoma",
        "NameOrID": "Blaskovic, William E.",
        "City": "Weatherford",
        "Year": "1987",
        "lat": "35.52608",
        "Document": "docs/william-e-blaskovic.pdf"
    },
    {
        "TroopNumber": "Troop 44",
        "lng": "-94.626824",
        "State": "Kansas",
        "NameOrID": "Blauvelt, Henry H.",
        "City": "Kansas City",
        "Year": "1991",
        "lat": "39.11338",
        "Document": "docs/henry-h-blauvelt.pdf"
    },
    {
        "TroopNumber": "Troop 72",
        "lng": "-74.831324",
        "State": "New Jersey",
        "NameOrID": "Blazer, William Henry",
        "City": "Wildwood Crest",
        "Year": "1971",
        "lat": "38.97667",
        "Document": " docs/william-henry-blazer.pdf"
    },
    {
        "TroopNumber": "Troop 429",
        "lng": "-86.254044",
        "State": "Indiana",
        "NameOrID": "Bliley, John Joseph",
        "City": "South Bend",
        "Year": "1990",
        "lat": "41.679058",
        "Document": "docs/john-joseph-bliley.pdf"
    },
    {
        "TroopNumber": "Troop 92",
        "lng": "-84.031804",
        "State": "Michigan",
        "NameOrID": "Blondin, Guy P.",
        "City": "Vernon",
        "Year": "1986",
        "lat": "42.939204",
        "Document": "docs/guy-p-blondin.pdf"
    },
    {
        "TroopNumber": "Troop 73",
        "lng": "-81.858569",
        "State": "Ohio",
        "NameOrID": "Blough, Elmer L.",
        "City": "Smithville",
        "Year": "1988",
        "lat": "40.864005",
        "Document": "docs/elmer-l-blough.pdf"
    },
    {
        "TroopNumber": "Troop 607",
        "lng": "-73.226054",
        "State": "Vermont",
        "NameOrID": "Blow, Harvey Louis",
        "City": "Shelburne",
        "Year": "1988",
        "lat": "44.376075",
        "Document": "docs/harvey-louis-blow.pdf"
    },
    {
        "TroopNumber": "Troop 206",
        "lng": "-112.075774",
        "State": "Arizona",
        "NameOrID": "Blum, Jon Michael",
        "City": "Phoenix",
        "Year": "1971",
        "lat": "33.44826",
        "Document": " docs/jon-michael-blum.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-84.194439",
        "State": "Ohio",
        "NameOrID": "Blythe, Winston",
        "City": "Dayton",
        "Year": "1987",
        "lat": "39.75911",
        "Document": "docs/winston-blythe.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-89.018214",
        "State": "Wisconsin",
        "NameOrID": "Boardman, Michael L.",
        "City": "Janesville",
        "Year": "1981",
        "lat": "42.687892",
        "Document": " docs/michael-l-boardman.pdf"
    },
    {
        "TroopNumber": "Explorer 343",
        "lng": "-88.646884",
        "State": "Wisconsin",
        "NameOrID": "Boardman, Michael L.",
        "City": "Delavan",
        "Year": "1981",
        "lat": "42.6321",
        "Document": " docs/michael-l-boardman.pdf"
    },
    {
        "TroopNumber": "Troop 217",
        "lng": "-97.727109",
        "State": "Texas",
        "NameOrID": "Boardman, James E.",
        "City": "Killeen",
        "Year": "1972",
        "lat": "31.116835",
        "Document": " docs/james-e-boardman.pdf"
    },
    {
        "TroopNumber": "Troop 136",
        "lng": "-75.100077",
        "State": "Pennsylvania",
        "NameOrID": "Boblitt, Allen L.",
        "City": "Warminster",
        "Year": "1982",
        "lat": "40.205459",
        "Document": " docs/allen-l-boblitt.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-102.075694",
        "State": "Texas",
        "NameOrID": "Bobo, Gene Paul",
        "City": "Midland",
        "Year": "1987",
        "lat": "32.000105",
        "Document": "docs/gene-paul-bobo.pdf"
    },
    {
        "TroopNumber": "494",
        "lng": "-94.072949",
        "State": "Minnesota",
        "NameOrID": "Bobrowske, Kenneth John",
        "City": "Howard Lake",
        "Year": "1987",
        "lat": "45.060743",
        "Document": "docs/kenneth-john-bobrowske.pdf"
    },
    {
        "TroopNumber": "Troop 371",
        "lng": "-82.178149",
        "State": "Ohio",
        "NameOrID": "Bodak, James A.",
        "City": "Lorain",
        "Year": "1972",
        "lat": "41.4682",
        "Document": " docs/james-a-bodak.pdf"
    },
    {
        "TroopNumber": "Troop 396",
        "lng": "-73.593544",
        "State": "New York",
        "NameOrID": "Boder, Donald M.",
        "City": "Uniondale",
        "Year": "1989",
        "lat": "40.717935",
        "Document": "docs/donald-m-boder.pdf"
    },
    {
        "TroopNumber": "Troop 923",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Bogart, Bradley",
        "City": "Chicago",
        "Year": "1972",
        "lat": "41.88415",
        "Document": " docs/bradley-bogart.pdf"
    },
    {
        "TroopNumber": "Explorer 9007",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Bogart, Bradley",
        "City": "Chicago",
        "Year": "1972",
        "lat": "41.88415",
        "Document": " docs/bradley-bogart.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Bohrer, Paul J.",
        "City": "",
        "Year": "1974",
        "lat": "",
        "Document": " docs/paul-j-bohrer.pdf"
    },
    {
        "TroopNumber": "Explorer 453",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Bolog, Richard V.",
        "City": "Houston",
        "Year": "1989",
        "lat": "29.76045",
        "Document": "docs/richard-v-bolog.pdf"
    },
    {
        "TroopNumber": "104",
        "lng": "-104.859959",
        "State": "Colorado",
        "NameOrID": "Boone, David Lee",
        "City": "Castle Rock",
        "Year": "1987",
        "lat": "39.375915",
        "Document": "docs/david-lee-boone.pdf"
    },
    {
        "TroopNumber": "45",
        "lng": "-121.654819",
        "State": "California",
        "NameOrID": "Booth, Wayne Spowyer",
        "City": "Salinas",
        "Year": "1987",
        "lat": "36.67088",
        "Document": "docs/wayne-spowyer-booth.pdf"
    },
    {
        "TroopNumber": "Troop 803",
        "lng": "-87.988357",
        "State": "Wisconsin",
        "NameOrID": "Borchardt, Jerry A.",
        "City": "Cedarburg",
        "Year": "1983",
        "lat": "43.29862",
        "Document": " docs/jerry-a-borchardt.pdf"
    },
    {
        "TroopNumber": "Pack 184",
        "lng": "-75.610664",
        "State": "Maryland",
        "NameOrID": "Borelli, John Keith",
        "City": "Fruitland",
        "Year": "1990",
        "lat": "38.32072",
        "Document": "docs/john-keith-borelli.pdf"
    },
    {
        "TroopNumber": "Troop 435",
        "lng": "-110.274679",
        "State": "Arizona",
        "NameOrID": "Borg, David James",
        "City": "Sierra Vista",
        "Year": "1989",
        "lat": "31.56945",
        "Document": "docs/david-james-borg.pdf"
    },
    {
        "TroopNumber": "Troop 15",
        "lng": "-85.210274",
        "State": "Michigan",
        "NameOrID": "Bornschein, Joseph John",
        "City": "Bellaire",
        "Year": "1982",
        "lat": "44.98312",
        "Document": " docs/joseph-john-bornschein.pdf"
    },
    {
        "TroopNumber": "Pack 56",
        "lng": "-87.906844",
        "State": "Wisconsin",
        "NameOrID": "Bottesi, Dennis R.",
        "City": "Milwaukee",
        "Year": "1971",
        "lat": "43.04181",
        "Document": " docs/dennis-r-bottesi.pdf"
    },
    {
        "TroopNumber": "Troop 327",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Bouchard, Stephen C.",
        "City": "Rochester",
        "Year": "1989",
        "lat": "43.1555",
        "Document": "docs/stephen-c-bouchard.pdf"
    },
    {
        "TroopNumber": "Troop 4",
        "lng": "-86.778365",
        "State": "Tennessee",
        "NameOrID": "Bowden, Robert Crawford",
        "City": "Nashville",
        "Year": "1985",
        "lat": "36.167783",
        "Document": "docs/robert-crawford-bowden.pdf"
    },
    {
        "TroopNumber": "Troop 210",
        "lng": "-86.778365",
        "State": "Tennessee",
        "NameOrID": "Bowden, Robert Crawford",
        "City": "Nashville",
        "Year": "1985",
        "lat": "36.167783",
        "Document": "docs/robert-crawford-bowden.pdf"
    },
    {
        "TroopNumber": "Troop 81",
        "lng": "",
        "State": "",
        "NameOrID": "Bowen, Arvin R.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/arvin-r-bowen.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Bowen, Thomas Neal",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/thomas-neal-bowen.pdf"
    },
    {
        "TroopNumber": "Troop 82",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Boyer, Ronald Dean",
        "City": "indianapolis",
        "Year": "1983",
        "lat": "39.76691",
        "Document": " docs/ronald-dean-boyer.pdf"
    },
    {
        "TroopNumber": "Troop 105",
        "lng": "-83.503391",
        "State": "Michigan",
        "NameOrID": "Bradley, Michael T.",
        "City": "Goodrich",
        "Year": "1988",
        "lat": "42.916935",
        "Document": "docs/michael-t-bradley.pdf"
    },
    {
        "TroopNumber": "Troop 226",
        "lng": "-83.047924",
        "State": "Michigan",
        "NameOrID": "Bradley, Michael T.",
        "City": "Detroit",
        "Year": "1988",
        "lat": "42.331685",
        "Document": "docs/michael-t-bradley.pdf"
    },
    {
        "TroopNumber": "Pack 3047",
        "lng": "-122.471219",
        "State": "Washington",
        "NameOrID": "Bradley, Carl Polk",
        "City": "Bellingham",
        "Year": "1989",
        "lat": "48.75235",
        "Document": "docs/carl-polk-bradley.pdf"
    },
    {
        "TroopNumber": "Troop 70",
        "lng": "-123.351819",
        "State": "California",
        "NameOrID": "Branscomb, James O.",
        "City": "Willits",
        "Year": "1989",
        "lat": "39.404145",
        "Document": "docs/james-o-branscomb.pdf"
    },
    {
        "TroopNumber": "Troop 137",
        "lng": "",
        "State": "West Virginia",
        "NameOrID": "Brant, Dale Thomas",
        "City": "New Manchester",
        "Year": "1987",
        "lat": "",
        "Document": "docs/dale-thomas-brant.pdf"
    },
    {
        "TroopNumber": "Troop 191",
        "lng": "-83.536259",
        "State": "Ohio",
        "NameOrID": "Branyan, Larry Woodroe",
        "City": "Toledo",
        "Year": "1986",
        "lat": "41.65381",
        "Document": "docs/larry-woodroe-branyan.pdf"
    },
    {
        "TroopNumber": "Pack 3035",
        "lng": "-83.536259",
        "State": "Ohio",
        "NameOrID": "Branyan, Larry Woodroe",
        "City": "Toledo",
        "Year": "1986",
        "lat": "41.65381",
        "Document": "docs/larry-woodroe-branyan.pdf"
    },
    {
        "TroopNumber": "Pack 121",
        "lng": "-70.950219",
        "State": "Massachusetts",
        "NameOrID": "Breen, Robert E.",
        "City": "Lynn",
        "Year": "1984",
        "lat": "42.4637",
        "Document": " docs/robert-e-breen.pdf"
    },
    {
        "TroopNumber": "Explorer 192",
        "lng": "-74.075283",
        "State": "New York",
        "NameOrID": "Breglio, Robert J.",
        "City": "Staten Island",
        "Year": "1978",
        "lat": "40.64244",
        "Document": "docs/robert-j-breglio.pdf"
    },
    {
        "TroopNumber": "703",
        "lng": "-71.072399",
        "State": "Massachusetts",
        "NameOrID": "Brehaut, Lawrence O.",
        "City": "Wakefield",
        "Year": "1985",
        "lat": "42.5064",
        "Document": "docs/lawrence-o-brehaut.pdf"
    },
    {
        "TroopNumber": "Explorer 189",
        "lng": "-74.169044",
        "State": "New Jersey",
        "NameOrID": "Brewer, John",
        "City": "Wyckoff",
        "Year": "1976",
        "lat": "41.013764",
        "Document": " docs/john-brewer.pdf"
    },
    {
        "TroopNumber": "Troop 16",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Brier, Steven Vincent",
        "City": "Jacksonville",
        "Year": "1990",
        "lat": "30.33147",
        "Document": "docs/steven-vincent-brier.pdf"
    },
    {
        "TroopNumber": "Troop 62",
        "lng": "-92.411384",
        "State": "Louisiana",
        "NameOrID": "Briggs, Walter E.",
        "City": "Downsville",
        "Year": "1991",
        "lat": "32.630825",
        "Document": "docs/walter-e-briggs.pdf"
    },
    {
        "TroopNumber": "Troop 359",
        "lng": "-117.412279",
        "State": "Washington",
        "NameOrID": "Briley, Donald M.",
        "City": "Spokane",
        "Year": "1989",
        "lat": "47.65726",
        "Document": "docs/donald-m-briley.pdf"
    },
    {
        "TroopNumber": "Pack 40",
        "lng": "-81.514059",
        "State": "Ohio",
        "NameOrID": "Bristol, Charles W.",
        "City": "Akron",
        "Year": "1989",
        "lat": "41.084195",
        "Document": "docs/charles-w-bristol.pdf"
    },
    {
        "TroopNumber": "Pack 204",
        "lng": "-81.514059",
        "State": "Ohio",
        "NameOrID": "Bristol, Charles W.",
        "City": "Akron",
        "Year": "1989",
        "lat": "41.084195",
        "Document": "docs/charles-w-bristol.pdf"
    },
    {
        "TroopNumber": "Pack 3204",
        "lng": "-81.514059",
        "State": "Ohio",
        "NameOrID": "Bristol, Charles W.",
        "City": "Akron",
        "Year": "1989",
        "lat": "41.084195",
        "Document": "docs/charles-w-bristol.pdf"
    },
    {
        "TroopNumber": "Troop 460",
        "lng": "-117.181494",
        "State": "Washington",
        "NameOrID": "Brock, Donald C.",
        "City": "Pullman",
        "Year": "1986",
        "lat": "46.730155",
        "Document": "docs/donald-c-brock.pdf"
    },
    {
        "TroopNumber": "Troop 90",
        "lng": "-87.498929",
        "State": "Kentucky",
        "NameOrID": "Broddle, Robert O.",
        "City": "Madisonville",
        "Year": "1988",
        "lat": "37.3279",
        "Document": "docs/robert-o-broddle.pdf"
    },
    {
        "TroopNumber": "Pack 241",
        "lng": "-87.279114",
        "State": "Indiana",
        "NameOrID": "Brooks, Danny Edward",
        "City": "Petersburg",
        "Year": "1985",
        "lat": "38.492415",
        "Document": "docs/danny-edward-brooks.pdf"
    },
    {
        "TroopNumber": "Troop 121",
        "lng": "-88.178889",
        "State": "Alabama",
        "NameOrID": "Brooks, Don",
        "City": "Theodore",
        "Year": "1983",
        "lat": "30.55433",
        "Document": " docs/don-brooks.pdf"
    },
    {
        "TroopNumber": "Troop 710",
        "lng": "-77.885272",
        "State": "Virginia",
        "NameOrID": "Brooks, Allan L.",
        "City": "Goochland",
        "Year": "1989",
        "lat": "37.683039",
        "Document": "docs/allan-l-brooks.pdf"
    },
    {
        "TroopNumber": "Troop 700",
        "lng": "-77.479343",
        "State": "Virginia",
        "NameOrID": "Brooks, Allan L.",
        "City": "Ashland",
        "Year": "1989",
        "lat": "37.758852",
        "Document": "docs/allan-l-brooks.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Brotherton, Daniel Ray",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/daniel-ray-brotherton.pdf"
    },
    {
        "TroopNumber": "Troop 724",
        "lng": "-75.959814",
        "State": "New York",
        "NameOrID": "Brown, Michael Raymond",
        "City": "Constantia",
        "Year": "1984",
        "lat": "43.25119",
        "Document": "docs/michael-raymond-brown.pdf"
    },
    {
        "TroopNumber": "Troop 444",
        "lng": "-80.14168",
        "State": "North Carolina",
        "NameOrID": "Brown, James Randolph",
        "City": "Walnut Cove",
        "Year": "1989",
        "lat": "36.296185",
        "Document": "docs/james-randolph-brown.pdf"
    },
    {
        "TroopNumber": "Troop 316",
        "lng": "-87.723089",
        "State": "Illinois",
        "NameOrID": "Brown, Gerald L.",
        "City": "St. Anne",
        "Year": "1989",
        "lat": "41.02123",
        "Document": "docs/gerald-l-brown.pdf"
    },
    {
        "TroopNumber": "Troop 81",
        "lng": "-70.338627",
        "State": "Maine",
        "NameOrID": "Brown, William B.",
        "City": "Westbrook",
        "Year": "1977",
        "lat": "43.679004",
        "Document": " docs/william-b-brown.pdf"
    },
    {
        "TroopNumber": "Troop 52",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "Brown, Edward C.",
        "City": "Worcester",
        "Year": "1984",
        "lat": "42.263415",
        "Document": " docs/edward-c-brown.pdf"
    },
    {
        "TroopNumber": "Troop 42",
        "lng": "-75.150929",
        "State": "New York",
        "NameOrID": "Brown, John Henry",
        "City": "Russell",
        "Year": "1984",
        "lat": "44.42914",
        "Document": " docs/john-henry-brown.pdf"
    },
    {
        "TroopNumber": "Troop 36",
        "lng": "-84.220895",
        "State": "Ohio",
        "NameOrID": "Brown, John D.",
        "City": "Maineville",
        "Year": "1987",
        "lat": "39.31677",
        "Document": "docs/john-d-brown.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Brown, Ronald Gene",
        "City": "",
        "Year": "1984",
        "lat": "",
        "Document": " docs/ronald-gene-brown.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-81.947864",
        "State": "Florida",
        "NameOrID": "Brown, James H.",
        "City": "Lakeland",
        "Year": "1972",
        "lat": "28.04419",
        "Document": "docs/james-h-brown.pdf"
    },
    {
        "TroopNumber": "Troop 61",
        "lng": "-84.516434",
        "State": "Georgia",
        "NameOrID": "Brown, Robert K.",
        "City": "Smyrna",
        "Year": "1989",
        "lat": "33.88486",
        "Document": "docs/robert-k-brown.pdf"
    },
    {
        "TroopNumber": "Explorer 221",
        "lng": "-76.942074",
        "State": "Maryland",
        "NameOrID": "Brownscombe, Tom",
        "City": "Hyattsville",
        "Year": "1984",
        "lat": "38.95259",
        "Document": " docs/tom-brownscombe.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Brunette, David J.",
        "City": "",
        "Year": "1983",
        "lat": "",
        "Document": " docs/david-j-brunette.pdf"
    },
    {
        "TroopNumber": "Troop 101",
        "lng": "-81.377389",
        "State": "Florida",
        "NameOrID": "Brus, Antonie",
        "City": "Orlando",
        "Year": "1977",
        "lat": "28.538235",
        "Document": " docs/antonie-brus.pdf"
    },
    {
        "TroopNumber": "Troop 78",
        "lng": "-81.377389",
        "State": "Florida",
        "NameOrID": "Brus, Antonie",
        "City": "Orlando",
        "Year": "1977",
        "lat": "28.538235",
        "Document": " docs/antonie-brus.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-81.377389",
        "State": "Florida",
        "NameOrID": "Brus, Antonie",
        "City": "Orlando ",
        "Year": "1977",
        "lat": "28.538235",
        "Document": " docs/antonie-brus.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-92.274494",
        "State": "Arkansas",
        "NameOrID": "Bryant, Richard Dale",
        "City": "Little Rock",
        "Year": "1991",
        "lat": "34.748655",
        "Document": "docs/richard-dale-bryant.pdf"
    },
    {
        "TroopNumber": "Troop 268",
        "lng": "-119.785734",
        "State": "California",
        "NameOrID": "Bryant, Charles",
        "City": "Fresno",
        "Year": "1989",
        "lat": "36.740685",
        "Document": "docs/charles-bryant.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Buckingham, Darryl L.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/darryl-l-buckingham.pdf"
    },
    {
        "TroopNumber": "Explorer 3",
        "lng": "-84.334334",
        "State": "Canal Zone",
        "NameOrID": "Bucko, Robert",
        "City": "Ft. Clayton",
        "Year": "1974",
        "lat": "41.62259",
        "Document": "docs/robert-bucko.pdf"
    },
    {
        "TroopNumber": "Troop 262",
        "lng": "-81.540094",
        "State": "North Carolina",
        "NameOrID": "Bumgarner, Mark F.",
        "City": "Lenoir",
        "Year": "1979",
        "lat": "35.91533",
        "Document": "docs/mark-f-bumgarner.pdf"
    },
    {
        "TroopNumber": "Explorer 1145",
        "lng": "-77.308132",
        "State": "Virginia",
        "NameOrID": "Bumgarner, Mark F.",
        "City": "Fairfax",
        "Year": "1979",
        "lat": "38.841574",
        "Document": "docs/mark-f-bumgarner.pdf"
    },
    {
        "TroopNumber": "Pack 422",
        "lng": "-95.457203",
        "State": "Texas",
        "NameOrID": "Bump, Jeffery Lloyd",
        "City": "Crockett",
        "Year": "1989",
        "lat": "31.317733",
        "Document": "docs/jeffery-lloyd-bump.pdf"
    },
    {
        "TroopNumber": "District 4",
        "lng": "-122.329439",
        "State": "Washington",
        "NameOrID": "Burgett, Howard Eugene Sanderson",
        "City": "Seattle",
        "Year": "1984",
        "lat": "47.60356",
        "Document": " docs/howard-eugene-sanderson-burgett.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Burgie, Gerald W.",
        "City": "",
        "Year": "1981",
        "lat": "",
        "Document": " docs/gerald-w-burgie.pdf"
    },
    {
        "TroopNumber": "Troop 444",
        "lng": "-87.941064",
        "State": "Illinois",
        "NameOrID": "Burke, Tom M.",
        "City": "Bensenville",
        "Year": "1975",
        "lat": "41.95605",
        "Document": " docs/tom-m-burke.pdf"
    },
    {
        "TroopNumber": "Troop 39",
        "lng": "-84.95088",
        "State": "Georgia",
        "NameOrID": "Burke, James A.",
        "City": "Calhoun",
        "Year": "1986",
        "lat": "34.505243",
        "Document": "docs/james-a-burke.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Burke, David",
        "City": "",
        "Year": "1981",
        "lat": "",
        "Document": "docs/david-burke.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Burkhardt, Richard",
        "City": "",
        "Year": "1983",
        "lat": "",
        "Document": " docs/richard-burkhardt.pdf"
    },
    {
        "TroopNumber": "Pack 402",
        "lng": "-100.958584",
        "State": "Texas",
        "NameOrID": "Burnett, Jimmy D.",
        "City": "Pampa",
        "Year": "1990",
        "lat": "35.5316",
        "Document": "docs/jimmy-d-burnett.pdf"
    },
    {
        "TroopNumber": "Pack 3009",
        "lng": "-147.101759",
        "State": "Alaska",
        "NameOrID": "Burns, Kenneth A.",
        "City": "Edelson AFB",
        "Year": "1983",
        "lat": "64.690531",
        "Document": " docs/kenneth-a-burns.pdf"
    },
    {
        "TroopNumber": "Troop 304",
        "lng": "-70.532574",
        "State": "Maine",
        "NameOrID": "Burr, Peter H.",
        "City": "Kennebunk",
        "Year": "1988",
        "lat": "43.392413",
        "Document": "docs/peter-h-burr.pdf"
    },
    {
        "TroopNumber": "Pack 50",
        "lng": "-80.195659",
        "State": "North Carolina",
        "NameOrID": "Burr, William J.",
        "City": "Albermarle",
        "Year": "1977",
        "lat": "35.35023",
        "Document": " docs/william-j-burr.pdf"
    },
    {
        "TroopNumber": "Troop 503",
        "lng": "-97.335579",
        "State": "Kansas",
        "NameOrID": "Burris, William Alva",
        "City": "Wichita",
        "Year": "1985",
        "lat": "37.68698",
        "Document": "docs/william-alva-burris.pdf"
    },
    {
        "TroopNumber": "Troop 526",
        "lng": "-97.335579",
        "State": "Kansas",
        "NameOrID": "Burris, William Alva",
        "City": "Wichita",
        "Year": "1985",
        "lat": "37.68698",
        "Document": "docs/william-alva-burris.pdf"
    },
    {
        "TroopNumber": "Troop 729",
        "lng": "",
        "State": "",
        "NameOrID": "Burt, Robert W.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/robert-w-burt.pdf"
    },
    {
        "TroopNumber": "Troop 59",
        "lng": "-91.201824",
        "State": "Iowa",
        "NameOrID": "Bush, Leavitt L.",
        "City": "Colesburg",
        "Year": "1978",
        "lat": "42.64001",
        "Document": "docs/leavitt-l-bush.pdf"
    },
    {
        "TroopNumber": "60",
        "lng": "-84.700769",
        "State": "Michigan",
        "NameOrID": "Bushard, Donald E.",
        "City": "Wacousta",
        "Year": "1985",
        "lat": "42.84958",
        "Document": "docs/donald-e-bushard.pdf"
    },
    {
        "TroopNumber": "Troop 17",
        "lng": "-98.500994",
        "State": "Texas",
        "NameOrID": "Bussey, Ronald Eugene",
        "City": "Wichita Falls",
        "Year": "1991",
        "lat": "33.90831",
        "Document": "docs/ronald-eugene-bussey.pdf"
    },
    {
        "TroopNumber": "Troop 26",
        "lng": "-73.093892",
        "State": "Massachusetts",
        "NameOrID": "Butler, Frank J.",
        "City": "Becket",
        "Year": "1985",
        "lat": "42.314509",
        "Document": "docs/frank-j-butler.pdf"
    },
    {
        "TroopNumber": "Troop 295",
        "lng": "-121.885794",
        "State": "California",
        "NameOrID": "Butler, George",
        "City": "San Jose",
        "Year": "1989",
        "lat": "37.338475",
        "Document": "docs/george-butler.pdf"
    },
    {
        "TroopNumber": "Troop 49",
        "lng": "-74.534307",
        "State": "New Jersey",
        "NameOrID": "Buttel, Curtis",
        "City": "Milton",
        "Year": "1990",
        "lat": "41.021104",
        "Document": " docs/curtis-buttel.pdf"
    },
    {
        "TroopNumber": "Troop 341",
        "lng": "-117.039187",
        "State": "California",
        "NameOrID": "Buxton, James J.",
        "City": "Wonder Valley",
        "Year": "1988",
        "lat": "33.22227",
        "Document": "docs/james-j-buxton.pdf"
    },
    {
        "TroopNumber": "Troop 503",
        "lng": "-75.550844",
        "State": "Delaware",
        "NameOrID": "Bye, Robert N.",
        "City": "Wilmington",
        "Year": "1985",
        "lat": "39.740235",
        "Document": "docs/robert-n-bye.pdf"
    },
    {
        "TroopNumber": "Troop 527",
        "lng": "-75.550844",
        "State": "Delaware",
        "NameOrID": "Bye, Robert N.",
        "City": "Wilmington",
        "Year": "1985",
        "lat": "39.740235",
        "Document": "docs/robert-n-bye.pdf"
    },
    {
        "TroopNumber": "Explorer 911",
        "lng": "-83.920914",
        "State": "Tennessee",
        "NameOrID": "Cadle, Wade S.",
        "City": "Knoxville",
        "Year": "1990",
        "lat": "35.960495",
        "Document": "docs/wade-s-cadle.pdf"
    },
    {
        "TroopNumber": "Pack 797",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Cagle, Robert J.",
        "City": "Dallas",
        "Year": "1990",
        "lat": "32.778155",
        "Document": "docs/robert-j-cagle.pdf"
    },
    {
        "TroopNumber": "District 8660",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Cagle, Robert J.",
        "City": "Dallas",
        "Year": "1990",
        "lat": "32.778155",
        "Document": "docs/robert-j-cagle.pdf"
    },
    {
        "TroopNumber": "Troop 604",
        "lng": "-92.274494",
        "State": "Arkansas",
        "NameOrID": "Calamia, Waverly T.",
        "City": "Little Rock",
        "Year": "1988",
        "lat": "34.748655",
        "Document": "docs/waverly-t-calamia.pdf"
    },
    {
        "TroopNumber": "Troop 120",
        "lng": "",
        "State": "",
        "NameOrID": "Callahan, Bruce K.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/bruce-k-callahan.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Calvert, Patrick C.",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": " docs/patrick-c-calvert.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-94.197519",
        "State": "Minnesota",
        "NameOrID": "Campina, Arthur Ronald",
        "City": "St. Cloud",
        "Year": "1989",
        "lat": "45.56586",
        "Document": "docs/arthur-ronald-campina.pdf"
    },
    {
        "TroopNumber": "Explorer 209",
        "lng": "-84.587694",
        "State": "Georgia",
        "NameOrID": "Cannon, Steven J.",
        "City": "Peachtree City",
        "Year": "1989",
        "lat": "33.398935",
        "Document": "docs/steven-j-cannon.pdf"
    },
    {
        "TroopNumber": "27",
        "lng": "-71.076767",
        "State": "Massachusetts",
        "NameOrID": "Capasso, Conrad T.",
        "City": "Haverhill",
        "Year": "1980",
        "lat": "42.777554",
        "Document": " docs/conrad-t-capasso.pdf"
    },
    {
        "TroopNumber": "Pack 3486",
        "lng": "-91.007879",
        "State": "Illinois",
        "NameOrID": "Cardemas, Mario Joseph",
        "City": "West Beverly",
        "Year": "1988",
        "lat": "39.793915",
        "Document": "docs/mario-joseph-cardemas.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-87.511799",
        "State": "Kentucky",
        "NameOrID": "Carl, Coley T.",
        "City": "Earlington",
        "Year": "1979",
        "lat": "37.27358",
        "Document": "docs/coley-t-carl.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Carlisle, Randolph",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/randolph-carlisle.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Redacted, Redacted",
        "City": "",
        "Year": "1981",
        "lat": "",
        "Document": "docs/david-j-carlo.pdf"
    },
    {
        "TroopNumber": "Troop 301",
        "lng": "-82.13247",
        "State": "Ohio",
        "NameOrID": "Carloni, Louis D.",
        "City": "The Plains",
        "Year": "1989",
        "lat": "39.369725",
        "Document": "docs/louis-d-carloni.pdf"
    },
    {
        "TroopNumber": "Troop 113",
        "lng": "-92.779629",
        "State": "Minnesota",
        "NameOrID": "Carlson, Richard Butch",
        "City": "Bayport",
        "Year": "1988",
        "lat": "45.018055",
        "Document": "docs/richard-butch-carlson.pdf"
    },
    {
        "TroopNumber": "Pack 3205",
        "lng": "-97.609704",
        "State": "Kansas",
        "NameOrID": "Carlson, Melvin Douglas",
        "City": "Salina",
        "Year": "1989",
        "lat": "38.840545",
        "Document": "docs/melvin-douglas-carlson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Carmean, Arthur C.",
        "City": "",
        "Year": "1974",
        "lat": "",
        "Document": " docs/arthur-c-carmean.pdf"
    },
    {
        "TroopNumber": "District 8633",
        "lng": "-121.897259",
        "State": "California",
        "NameOrID": "Carpontier, Donald K.",
        "City": "Milpitas ",
        "Year": "1990",
        "lat": "37.432805",
        "Document": "docs/donald-k-carpontier.pdf"
    },
    {
        "TroopNumber": "Explorer 951",
        "lng": "-76.069682",
        "State": "New York",
        "NameOrID": "Carr, John J.",
        "City": "Jamesville",
        "Year": "1987",
        "lat": "42.991418",
        "Document": "docs/john-j-carr.pdf"
    },
    {
        "TroopNumber": "Troop 466",
        "lng": "-80.182709",
        "State": "Florida",
        "NameOrID": "Carroll, Joseph",
        "City": "North Miami",
        "Year": "1987",
        "lat": "25.890425",
        "Document": "docs/joseph-carroll.pdf"
    },
    {
        "TroopNumber": "Troop 280",
        "lng": "-72.644119",
        "State": "Connecticut",
        "NameOrID": "Carroll, Edgar A.",
        "City": "East Hartford",
        "Year": "1984",
        "lat": "41.768805",
        "Document": " docs/edgar-a-carroll.pdf"
    },
    {
        "TroopNumber": "Troop 33",
        "lng": "-122.114149",
        "State": "California",
        "NameOrID": "Carson-Hull, Gary A.",
        "City": "Los Altos",
        "Year": "1990",
        "lat": "37.383075",
        "Document": "docs/gary-a-carson-hull.pdf"
    },
    {
        "TroopNumber": "Troop 95",
        "lng": "-94.540189",
        "State": "Iowa",
        "NameOrID": "Carter, John F.",
        "City": "Manson",
        "Year": "1984",
        "lat": "42.52898",
        "Document": " docs/john-f-carter.pdf"
    },
    {
        "TroopNumber": "Pack 95",
        "lng": "-94.540189",
        "State": "Iowa",
        "NameOrID": "Carter, John F.",
        "City": "Manson",
        "Year": "1984",
        "lat": "42.52898",
        "Document": " docs/john-f-carter.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Carter, Clemens George",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": "docs/clemens-george-carter.pdf"
    },
    {
        "TroopNumber": "Explorer 601",
        "lng": "-82.955682",
        "State": "South Carolina",
        "NameOrID": "Carver, Timothy G.",
        "City": "Seneca",
        "Year": "1985",
        "lat": "34.685483",
        "Document": "docs/timothy-g-carver.pdf"
    },
    {
        "TroopNumber": "Troop 27",
        "lng": "-82.692575",
        "State": "South Carolina",
        "NameOrID": "Carver, Timothy G.",
        "City": "Liberty",
        "Year": "1985",
        "lat": "34.787221",
        "Document": "docs/timothy-g-carver.pdf"
    },
    {
        "TroopNumber": "Troop 66",
        "lng": "-80.237419",
        "State": "Florida",
        "NameOrID": "Casanova, Nelson Enriquez",
        "City": "Miami",
        "Year": "1972",
        "lat": "25.728985",
        "Document": " docs/nelson-enriquez-casanova.pdf"
    },
    {
        "TroopNumber": "Troop 103",
        "lng": "-79.896973",
        "State": "North Carolina",
        "NameOrID": "Case, Roy Lindsay",
        "City": "Summerfield",
        "Year": "1972",
        "lat": "36.199806",
        "Document": " docs/roy-lindsay-case.pdf"
    },
    {
        "TroopNumber": "Troop 132",
        "lng": "-74.928239",
        "State": "Pennsylvania",
        "NameOrID": "Casey, Joseph G.",
        "City": "Bensalem",
        "Year": "1989",
        "lat": "40.109511",
        "Document": "docs/joseph-g-casey.pdf"
    },
    {
        "TroopNumber": "Troop 576",
        "lng": "-121.491014",
        "State": "California",
        "NameOrID": "Cates, David E.",
        "City": "Sacramento",
        "Year": "1973",
        "lat": "38.579065",
        "Document": " docs/david-e-cates.pdf"
    },
    {
        "TroopNumber": "Troop 250",
        "lng": "-75.030549",
        "State": "New Jersey",
        "NameOrID": "Cathcart, Dieter",
        "City": "Cherry Hill",
        "Year": "1988",
        "lat": "39.9275",
        "Document": "docs/dieter-cathcart.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-75.030549",
        "State": "New Jersey",
        "NameOrID": "Cathcart, Dieter",
        "City": "Cherry Hill",
        "Year": "1988",
        "lat": "39.9275",
        "Document": "docs/dieter-cathcart.pdf"
    },
    {
        "TroopNumber": "Pack 2047",
        "lng": "-147.722059",
        "State": "Alaska",
        "NameOrID": "Cavanaugh, Robert W.",
        "City": "Fairbanks",
        "Year": "1985",
        "lat": "64.845085",
        "Document": "docs/robert-w-cavanaugh.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Ceballes, Charles R.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/charles-r-ceballes.pdf"
    },
    {
        "TroopNumber": "Troop 155",
        "lng": "-74.938574",
        "State": "New Jersey",
        "NameOrID": "Centrella, Arthur",
        "City": "West Berlin",
        "Year": "1991",
        "lat": "39.81001",
        "Document": "docs/arthur-centrella.pdf"
    },
    {
        "TroopNumber": "Troop 522",
        "lng": "-112.075774",
        "State": "Arizona",
        "NameOrID": "Challberg, William J.",
        "City": "Phoenix",
        "Year": "1986",
        "lat": "33.44826",
        "Document": "docs/william-j-challberg.pdf"
    },
    {
        "TroopNumber": "Pack 109",
        "lng": "-82.045344",
        "State": "Florida",
        "NameOrID": "Chamblee, John Thomas",
        "City": "Melrose",
        "Year": "1987",
        "lat": "29.709668",
        "Document": "docs/john-thomas-chamblee.pdf"
    },
    {
        "TroopNumber": "Pack 304",
        "lng": "-100.442424",
        "State": "Texas",
        "NameOrID": "Chandler, Oliver Carl",
        "City": "San Angelo",
        "Year": "1971",
        "lat": "31.4615",
        "Document": " docs/oliver-carl-chandler.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-100.442424",
        "State": "Texas",
        "NameOrID": "Chandler, Oliver Carl",
        "City": "San Angelo",
        "Year": "1971",
        "lat": "31.4615",
        "Document": " docs/oliver-carl-chandler.pdf"
    },
    {
        "TroopNumber": "Troop 172",
        "lng": "-82.692575",
        "State": "South Carolina",
        "NameOrID": "Chapman, John F.",
        "City": "Liberty",
        "Year": "1989",
        "lat": "34.787221",
        "Document": "docs/john-f-chapman.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Chapman-Anderson, Leonard H.",
        "City": "Jacksonville",
        "Year": "1986",
        "lat": "30.33147",
        "Document": "docs/leonard-h-chapman-anderson.pdf"
    },
    {
        "TroopNumber": "Troop 17",
        "lng": "-71.017249",
        "State": "Massachusetts",
        "NameOrID": "Chappell, Maurice S.",
        "City": "Brockton",
        "Year": "1982",
        "lat": "42.08317",
        "Document": " docs/maurice-s-chappell.pdf"
    },
    {
        "TroopNumber": "Troop 19",
        "lng": "-71.017249",
        "State": "Massachusetts",
        "NameOrID": "Chappell, Maurice S.",
        "City": "Brockton",
        "Year": "1982",
        "lat": "42.08317",
        "Document": " docs/maurice-s-chappell.pdf"
    },
    {
        "TroopNumber": "Explorer 335",
        "lng": "-86.778365",
        "State": "Tennessee",
        "NameOrID": "Chappell, Everett A.",
        "City": "Nashville",
        "Year": "1989",
        "lat": "36.167783",
        "Document": "docs/everett-a-chappell.pdf"
    },
    {
        "TroopNumber": "Troop 435",
        "lng": "-84.552249",
        "State": "Michigan",
        "NameOrID": "Chase, Herbert W.",
        "City": "Lansing",
        "Year": "1986",
        "lat": "42.73194",
        "Document": "docs/herbert-w-chase.pdf"
    },
    {
        "TroopNumber": "Troop 656",
        "lng": "-75.16652",
        "State": "Pennsylvania",
        "NameOrID": "Cherry, Robert L.",
        "City": "Dresher",
        "Year": "1988",
        "lat": "40.140732",
        "Document": "docs/robert-l-cherry.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Cherry, Kyle J.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/kyle-j-cherry.pdf"
    },
    {
        "TroopNumber": "Troop 73",
        "lng": "-74.64528",
        "State": "New Jersey",
        "NameOrID": "Chiappini, William A.",
        "City": "Egg Harbor",
        "Year": "1984",
        "lat": "39.53078",
        "Document": " docs/william-a-chiappini.pdf"
    },
    {
        "TroopNumber": "Troop 46",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "Chiappone, Alan M.",
        "City": "Syracuse",
        "Year": "1983",
        "lat": "43.04999",
        "Document": " docs/alan-m-chiappone.pdf"
    },
    {
        "TroopNumber": "56",
        "lng": "-103.230889",
        "State": "South Dakota",
        "NameOrID": "Childs, George",
        "City": "Rapid City",
        "Year": "1976",
        "lat": "44.081165",
        "Document": " docs/george-childs.pdf"
    },
    {
        "TroopNumber": "Troop 56",
        "lng": "-97.107719",
        "State": "Texas",
        "NameOrID": "Chrystie, Bruce W.",
        "City": "Arlington",
        "Year": "1990",
        "lat": "32.7356",
        "Document": "docs/bruce-w-chrystie.pdf"
    },
    {
        "TroopNumber": "Troop 58",
        "lng": "-77.964564",
        "State": "West Virginia",
        "NameOrID": "Church, Bruce E.",
        "City": "Martinsburg",
        "Year": "1989",
        "lat": "39.45637",
        "Document": "docs/bruce-e-church.pdf"
    },
    {
        "TroopNumber": "Troop 77",
        "lng": "-80.005085",
        "State": "North Carolina",
        "NameOrID": "Clapp, Michael Stephen",
        "City": "High Point",
        "Year": "1984",
        "lat": "35.954689",
        "Document": " docs/michael-stephen-clapp.pdf"
    },
    {
        "TroopNumber": "Pack 278",
        "lng": "-121.885794",
        "State": "California",
        "NameOrID": "Clark, Nathan Albert",
        "City": "San Jose",
        "Year": "1987",
        "lat": "37.338475",
        "Document": "docs/nathan-albert-clark.pdf"
    },
    {
        "TroopNumber": "Troop 259",
        "lng": "-80.253005",
        "State": "Florida",
        "NameOrID": "Clark, Gregory M.",
        "City": "Opa Locka",
        "Year": "1984",
        "lat": "25.904557",
        "Document": " docs/gregory-m-clark.pdf"
    },
    {
        "TroopNumber": "Troop 320",
        "lng": "-98.263068",
        "State": "Texas",
        "NameOrID": "Clark, Kenneth",
        "City": "Canyon Lake",
        "Year": "1989",
        "lat": "29.875041",
        "Document": "docs/kenneth-clark.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Clark, Jesse Steven",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/jesse-steven-clark.pdf"
    },
    {
        "TroopNumber": "Pack 437",
        "lng": "-81.690434",
        "State": "Ohio",
        "NameOrID": "Clayborne, Stewart",
        "City": "Cleveland ",
        "Year": "1975",
        "lat": "41.50438",
        "Document": " docs/stewart-clayborne.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Cleveland, Blanchard L.",
        "City": "",
        "Year": "1974",
        "lat": "",
        "Document": " docs/blanchard-l-cleveland.pdf"
    },
    {
        "TroopNumber": "Troop 45",
        "lng": "",
        "State": "Georgia",
        "NameOrID": "Cline, Joseph W.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/joseph-w-cline.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Clutter, Elwin Dean",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/elwin-dean-clutter.pdf"
    },
    {
        "TroopNumber": "Troop 337",
        "lng": "-74.024452",
        "State": "New Jersey",
        "NameOrID": "Coakley, Robert J",
        "City": "Emerson",
        "Year": "1980",
        "lat": "40.975205",
        "Document": " docs/robert-j-coakley.pdf"
    },
    {
        "TroopNumber": "Explorer 337",
        "lng": "-74.024452",
        "State": "New Jersey",
        "NameOrID": "Coakley, Robert J",
        "City": "Emerson",
        "Year": "1980",
        "lat": "40.975205",
        "Document": " docs/robert-j-coakley.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "New York",
        "NameOrID": "Coakley, Robert J",
        "City": "Forrestport",
        "Year": "1980",
        "lat": "",
        "Document": " docs/robert-j-coakley.pdf"
    },
    {
        "TroopNumber": "Troop 209",
        "lng": "-94.350119",
        "State": "Texas",
        "NameOrID": "Cochnauer, Michael D.",
        "City": "Marshall",
        "Year": "1990",
        "lat": "32.545965",
        "Document": "docs/michael-d-cochnauer.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Cochran, Robert",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/robert-cochran.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-74.229229",
        "State": "New Jersey",
        "NameOrID": "Codispoti, Arnold Edward",
        "City": "Cedar Grove",
        "Year": "1985",
        "lat": "40.850775",
        "Document": "docs/arnold-edward-codispoti.pdf"
    },
    {
        "TroopNumber": "Explorer 654",
        "lng": "-74.174184",
        "State": "New Jersey",
        "NameOrID": "Codispoti, Arnold Edward",
        "City": "Newark ",
        "Year": "1985",
        "lat": "40.73197",
        "Document": "docs/arnold-edward-codispoti.pdf"
    },
    {
        "TroopNumber": "Explorer 730",
        "lng": "-72.501474",
        "State": "Vermont",
        "NameOrID": "Codling, Donald R.",
        "City": "Barre",
        "Year": "1991",
        "lat": "44.19689",
        "Document": "docs/donald-r-codling.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-84.280649",
        "State": "Florida",
        "NameOrID": "Coe, Roger A.",
        "City": "Tallahassee",
        "Year": "1977",
        "lat": "30.439775",
        "Document": " docs/roger-a-coe.pdf"
    },
    {
        "TroopNumber": "Explorer 173",
        "lng": "-78.164049",
        "State": "Virginia",
        "NameOrID": "Coffey, Bradley Martin",
        "City": "Winchester",
        "Year": "1988",
        "lat": "39.184005",
        "Document": "docs/bradley-martin-coffey.pdf"
    },
    {
        "TroopNumber": "Pack 89",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Coffey, Carleton T.",
        "City": "Portland",
        "Year": "1986",
        "lat": "45.511795",
        "Document": "docs/carleton-t-coffey.pdf"
    },
    {
        "TroopNumber": "Pack 8602",
        "lng": "-87.413684",
        "State": "Michigan",
        "NameOrID": "Coffman, Douglas J.",
        "City": "Marquette",
        "Year": "1991",
        "lat": "46.54584",
        "Document": "docs/douglas-j-coffman.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Cohoon, Leo",
        "City": "",
        "Year": "1986",
        "lat": "",
        "Document": "docs/leo-cohoon.pdf"
    },
    {
        "TroopNumber": "Troop 9",
        "lng": "-118.192399",
        "State": "California",
        "NameOrID": "Colbert, Joseph A.",
        "City": "Long Beach",
        "Year": "1989",
        "lat": "33.766725",
        "Document": "docs/joseph-a-colbert.pdf"
    },
    {
        "TroopNumber": "Explorer 553",
        "lng": "-84.016664",
        "State": "Georgia",
        "NameOrID": "Collar, Richard E.",
        "City": "Snellville",
        "Year": "1990",
        "lat": "33.85675",
        "Document": "docs/richard-e-collar.pdf"
    },
    {
        "TroopNumber": "Pack 323",
        "lng": "-117.412279",
        "State": "Washington",
        "NameOrID": "Collins, Dennis David",
        "City": "Spokane",
        "Year": "1972",
        "lat": "47.65726",
        "Document": " docs/dennis-david-collins.pdf"
    },
    {
        "TroopNumber": "Troop 56",
        "lng": "-68.539329",
        "State": "Maine",
        "NameOrID": "Comer, Martin J.",
        "City": "Medway",
        "Year": "1990",
        "lat": "45.613255",
        "Document": "docs/martin-j-comer.pdf"
    },
    {
        "TroopNumber": "Troop 105",
        "lng": "-87.424569",
        "State": "Indiana",
        "NameOrID": "Cometto, Nick I.",
        "City": "Cedar Lake",
        "Year": "1991",
        "lat": "41.36745",
        "Document": "docs/nick-i-cometto.pdf"
    },
    {
        "TroopNumber": "Troop 38",
        "lng": "-75.202054",
        "State": "New York",
        "NameOrID": "Condlin, Richard Allen",
        "City": "Waddington",
        "Year": "1984",
        "lat": "44.863365",
        "Document": " docs/richard-allen-condlin.pdf"
    },
    {
        "TroopNumber": "Pack 38",
        "lng": "-75.202054",
        "State": "New York",
        "NameOrID": "Condlin, Richard Allen",
        "City": "Waddington",
        "Year": "1984",
        "lat": "44.863365",
        "Document": " docs/richard-allen-condlin.pdf"
    },
    {
        "TroopNumber": "Troop 37",
        "lng": "",
        "State": "",
        "NameOrID": "Conekin, Albert M.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/albert-m-conekin.pdf"
    },
    {
        "TroopNumber": "Troop 50",
        "lng": "-80.237419",
        "State": "Florida",
        "NameOrID": "Conkey, Guy E.",
        "City": "Miami",
        "Year": "1973",
        "lat": "25.728985",
        "Document": " docs/guy-e-conkey.pdf"
    },
    {
        "TroopNumber": "Pack 321",
        "lng": "-85.180224",
        "State": "Michigan",
        "NameOrID": "Conklin, Harry E.",
        "City": "Battle Creek",
        "Year": "1988",
        "lat": "42.31617",
        "Document": "docs/harry-e-conklin.pdf"
    },
    {
        "TroopNumber": "Troop 1",
        "lng": "-71.659889",
        "State": "Rhode Island",
        "NameOrID": "Connell, John D.",
        "City": "Harrisville",
        "Year": "1988",
        "lat": "41.969745",
        "Document": "docs/john-d-connell.pdf"
    },
    {
        "TroopNumber": "Troop 647",
        "lng": "-69.790417",
        "State": "Maine",
        "NameOrID": "Conrad, Alfred J.",
        "City": "Hallowell",
        "Year": "1984",
        "lat": "44.286946",
        "Document": " docs/alfred-j-conrad.pdf"
    },
    {
        "TroopNumber": "Troop 103",
        "lng": "-71.19376",
        "State": "Massachusetts",
        "NameOrID": "Conti, Steven A.",
        "City": "Burlington",
        "Year": "1988",
        "lat": "42.505832",
        "Document": "docs/steven-a-conti.pdf"
    },
    {
        "TroopNumber": "858",
        "lng": "",
        "State": "",
        "NameOrID": "Cook, Steven L.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/steven-l-cook.pdf"
    },
    {
        "TroopNumber": "13",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Cook, Robert A.",
        "City": "Jacksonville",
        "Year": "1990",
        "lat": "30.33147",
        "Document": "docs/robert-a-cook.pdf"
    },
    {
        "TroopNumber": "Pack 880",
        "lng": "-84.504552",
        "State": "Ohio",
        "NameOrID": "Cooke, Robert W.",
        "City": "Cincinnati",
        "Year": "1979",
        "lat": "39.106614",
        "Document": "docs/robert-w-cooke.pdf"
    },
    {
        "TroopNumber": "Troop 100",
        "lng": "-71.079521",
        "State": "Massachusetts",
        "NameOrID": "Cooney, James J.",
        "City": "Westport",
        "Year": "1990",
        "lat": "41.658074",
        "Document": "docs/james-j-cooney.pdf"
    },
    {
        "TroopNumber": "Troop 238",
        "lng": "-90.049259",
        "State": "Tennessee",
        "NameOrID": "Cooper, William E.",
        "City": "Memphis",
        "Year": "1987",
        "lat": "35.149766",
        "Document": "docs/william-e-cooper.pdf"
    },
    {
        "TroopNumber": "Pack 8611",
        "lng": "-90.049259",
        "State": "Tennessee",
        "NameOrID": "Cooper, William E.",
        "City": "Memphis",
        "Year": "1987",
        "lat": "35.149766",
        "Document": "docs/william-e-cooper.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-91.486956",
        "State": "Arkansas",
        "NameOrID": "Cooper, William E.",
        "City": "Hardy",
        "Year": "1987",
        "lat": "36.316303",
        "Document": "docs/william-e-cooper.pdf"
    },
    {
        "TroopNumber": "Troop 668",
        "lng": "-83.047924",
        "State": "Michigan",
        "NameOrID": "Cooper, Samuel Lee",
        "City": "Detroit",
        "Year": "1991",
        "lat": "42.331685",
        "Document": "docs/samuel-lee-cooper.pdf"
    },
    {
        "TroopNumber": "Troop 350",
        "lng": "-74.060294",
        "State": "New Jersey",
        "NameOrID": "Cooper, James E.",
        "City": "Paramus",
        "Year": "1982",
        "lat": "40.92673",
        "Document": " docs/james-e-cooper.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Cooper, Brian R.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/brian-r-cooper.pdf"
    },
    {
        "TroopNumber": "Troop 70",
        "lng": "-121.918261",
        "State": "California",
        "NameOrID": "Cope, William Burke",
        "City": "Dorris",
        "Year": "1986",
        "lat": "41.966843",
        "Document": "docs/william-burke-cope.pdf"
    },
    {
        "TroopNumber": "Troop 147",
        "lng": "-80.253005",
        "State": "Florida",
        "NameOrID": "Copps, William J.",
        "City": "Opa Locka",
        "Year": "1975",
        "lat": "25.904557",
        "Document": " docs/william-j-copps.pdf"
    },
    {
        "TroopNumber": "Explorer 2972",
        "lng": "-94.576804",
        "State": "Missouri",
        "NameOrID": "Corbley, Robert Kevin",
        "City": "Gladstone",
        "Year": "1986",
        "lat": "39.224724",
        "Document": "docs/robert-kevin-corbley.pdf"
    },
    {
        "TroopNumber": "Not Applicable ",
        "lng": "",
        "State": "",
        "NameOrID": "Corcoran, Vincent James",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/vincent-james-corcoran.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Corringham, Brian",
        "City": "",
        "Year": "1975",
        "lat": "",
        "Document": " docs/brian-corringham.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-76.724289",
        "State": "Pennsylvania",
        "NameOrID": "Corssan, Robert F.",
        "City": "York",
        "Year": "1977",
        "lat": "39.964535",
        "Document": " docs/robert-f-corssan.pdf"
    },
    {
        "TroopNumber": "Troop 331",
        "lng": "-91.425149",
        "State": "Louisiana",
        "NameOrID": "Cortez, Stephen Joseph",
        "City": "Vidalia",
        "Year": "1973",
        "lat": "31.564052",
        "Document": " docs/stephen-joseph-cortez.pdf"
    },
    {
        "TroopNumber": "Troop 52",
        "lng": "-70.683928",
        "State": "Massachusetts",
        "NameOrID": "Costa, George A.",
        "City": "Duxbury",
        "Year": "1989",
        "lat": "42.025865",
        "Document": "docs/george-a-costa.pdf"
    },
    {
        "TroopNumber": "Pack 185",
        "lng": "-78.869194",
        "State": "New York",
        "NameOrID": "Cote, John L.",
        "City": "North Tonawanda",
        "Year": "1988",
        "lat": "43.028804",
        "Document": " docs/john-l-cote.pdf"
    },
    {
        "TroopNumber": "Troop 82",
        "lng": "-149.858384",
        "State": "Alaska",
        "NameOrID": "Cottrell, Paul V.",
        "City": "Anchorage",
        "Year": "1989",
        "lat": "61.21755",
        "Document": "docs/paul-v-cottrell.pdf"
    },
    {
        "TroopNumber": "Explorer 11",
        "lng": "",
        "State": "Wisconsin",
        "NameOrID": "Coulter, Mark A.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/mark-a-coulter.pdf"
    },
    {
        "TroopNumber": "Troop 513",
        "lng": "-73.688094",
        "State": "New York",
        "NameOrID": "Cousins, Joseph A.",
        "City": "Troy",
        "Year": "1987",
        "lat": "42.734495",
        "Document": "docs/joseph-a-cousins.pdf"
    },
    {
        "TroopNumber": "Pack 563",
        "lng": "-73.688094",
        "State": "New York",
        "NameOrID": "Cousins, Joseph A.",
        "City": "Troy",
        "Year": "1987",
        "lat": "42.734495",
        "Document": "docs/joseph-a-cousins.pdf"
    },
    {
        "TroopNumber": "79",
        "lng": "-105.090519",
        "State": "Colorado",
        "NameOrID": "Cowan, Charles D.",
        "City": "Lafayette",
        "Year": "1990",
        "lat": "39.98828",
        "Document": "docs/charles-d-cowan.pdf"
    },
    {
        "TroopNumber": "Troop 999",
        "lng": "-121.757829",
        "State": "California",
        "NameOrID": "Cox, Robert Edward",
        "City": "Livermore",
        "Year": "1986",
        "lat": "37.675245",
        "Document": "docs/robert-edward-cox.pdf"
    },
    {
        "TroopNumber": "Troop 500",
        "lng": "-81.526673",
        "State": "Florida",
        "NameOrID": "Cox, Harvey A.",
        "City": "Babson Park",
        "Year": "1985",
        "lat": "27.833383",
        "Document": "docs/harvey-a-cox.pdf"
    },
    {
        "TroopNumber": "Troop 86",
        "lng": "-83.973404",
        "State": "Tennessee",
        "NameOrID": "Cox, Thomas R.",
        "City": "Maryville",
        "Year": "1988",
        "lat": "35.753795",
        "Document": "docs/thomas-r-cox.pdf"
    },
    {
        "TroopNumber": "Pack 61",
        "lng": "",
        "State": "Germany",
        "NameOrID": "Cox, Earl W.",
        "City": "",
        "Year": "1984",
        "lat": "",
        "Document": " docs/earl-w-cox.pdf"
    },
    {
        "TroopNumber": "Troop 37",
        "lng": "-83.61211",
        "State": "Ohio",
        "NameOrID": "Cox, Marcus",
        "City": "Hillsboro",
        "Year": "1980",
        "lat": "39.202283",
        "Document": " docs/marcus-cox.pdf"
    },
    {
        "TroopNumber": "Troop 108",
        "lng": "-70.522383",
        "State": "Maine",
        "NameOrID": "Cram, Fred A.",
        "City": "Casco",
        "Year": "1984",
        "lat": "44.004542",
        "Document": " docs/fred-a-cram.pdf"
    },
    {
        "TroopNumber": "District 8604",
        "lng": "-74.496414",
        "State": "New Jersey",
        "NameOrID": "Cramer, William N.",
        "City": "Denville",
        "Year": "1988",
        "lat": "40.86189",
        "Document": "docs/william-n-cramer.pdf"
    },
    {
        "TroopNumber": "Troop 137",
        "lng": "-90.077749",
        "State": "Louisiana",
        "NameOrID": "Cramer, Harry",
        "City": "New Orleans",
        "Year": "1976",
        "lat": "29.9537",
        "Document": " docs/harry-cramer.pdf"
    },
    {
        "TroopNumber": "Troop 1903",
        "lng": "-113.996234",
        "State": "Montana",
        "NameOrID": "Crist, Stephen D.",
        "City": "Missoula",
        "Year": "1990",
        "lat": "46.87278",
        "Document": "docs/stephen-d-crist.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Cronenwett, William Herbert",
        "City": "",
        "Year": "1971",
        "lat": "",
        "Document": " docs/william-herbert-cronenwett.pdf"
    },
    {
        "TroopNumber": "156",
        "lng": "-82.892529",
        "State": "Michigan",
        "NameOrID": "Crooks, Dennis C.",
        "City": "Grosse Pointe Farms",
        "Year": "1978",
        "lat": "42.40636",
        "Document": "docs/dennis-c-crooks.pdf"
    },
    {
        "TroopNumber": "Explorer 158",
        "lng": "-97.82189",
        "State": "Texas",
        "NameOrID": "Crowder, David V.",
        "City": "Cedar Park",
        "Year": "1990",
        "lat": "30.506609",
        "Document": "docs/david-v-crowder.pdf"
    },
    {
        "TroopNumber": "Pack 314",
        "lng": "-79.846894",
        "State": "Pennsylvania",
        "NameOrID": "Cseripko, Michael J.",
        "City": "Duquesne",
        "Year": "1987",
        "lat": "40.37295",
        "Document": "docs/michael-j-cseripko.pdf"
    },
    {
        "TroopNumber": "Pack 109",
        "lng": "-73.055289",
        "State": "Connecticut",
        "NameOrID": "Cseripko, Michael J.",
        "City": "Nagatuck",
        "Year": "1987",
        "lat": "41.490695",
        "Document": "docs/michael-j-cseripko.pdf"
    },
    {
        "TroopNumber": "District 324",
        "lng": "",
        "State": "",
        "NameOrID": "Culhane, Bonnie J.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/bonnie-j-culhane.pdf"
    },
    {
        "TroopNumber": "Troop 729",
        "lng": "-110.969754",
        "State": "Arizona",
        "NameOrID": "Cummings, William S.",
        "City": "Tucson",
        "Year": "1984",
        "lat": "32.221553",
        "Document": " docs/william-s-cummings.pdf"
    },
    {
        "TroopNumber": "279",
        "lng": "-89.046659",
        "State": "Illinois",
        "NameOrID": "Cunningham, Howard",
        "City": "Walnut Hill",
        "Year": "1991",
        "lat": "38.477865",
        "Document": "docs/howard-cunningham.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Cunyus, David Wayne",
        "City": "",
        "Year": "1984",
        "lat": "",
        "Document": " docs/david-wayne-cunyus.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 1",
        "lng": "-81.868194",
        "State": "Florida",
        "NameOrID": "Curls, William C.",
        "City": "Ft. Myers ",
        "Year": "1978",
        "lat": "26.640845",
        "Document": "docs/william-c-curls.pdf"
    },
    {
        "TroopNumber": "Pack 47",
        "lng": "-82.810859",
        "State": "Ohio",
        "NameOrID": "Curren, Dale",
        "City": "Marengo",
        "Year": "1987",
        "lat": "40.40159",
        "Document": "docs/dale-curren.pdf"
    },
    {
        "TroopNumber": "Troop 25",
        "lng": "-93.093326",
        "State": "Minnesota",
        "NameOrID": "Currie, Richard Eugene",
        "City": "St. Paul",
        "Year": "1987",
        "lat": "44.943829",
        "Document": "docs/richard-eugene-currie.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-88.669682",
        "State": "Wisconsin",
        "NameOrID": "Currie, Richard Eugene",
        "City": "Long Lake",
        "Year": "1987",
        "lat": "45.84383",
        "Document": "docs/richard-eugene-currie.pdf"
    },
    {
        "TroopNumber": "Explorer 240",
        "lng": "-70.980365",
        "State": "New Hampshire",
        "NameOrID": "Currier, Hazen James",
        "City": "Rochester",
        "Year": "1981",
        "lat": "43.305537",
        "Document": " docs/hazen-james-currier.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-89.29071",
        "State": "Mississippi",
        "NameOrID": "Curry, William Neil",
        "City": "Hattiesburg",
        "Year": "1988",
        "lat": "31.327351",
        "Document": "docs/william-neil-curry.pdf"
    },
    {
        "TroopNumber": "Troop 699",
        "lng": "-97.270084",
        "State": "Kansas",
        "NameOrID": "Curtiss, Charles P.",
        "City": "Derby",
        "Year": "1986",
        "lat": "37.544885",
        "Document": "docs/charles-p-curtiss.pdf"
    },
    {
        "TroopNumber": "Troop 620",
        "lng": "-97.335579",
        "State": "Kansas",
        "NameOrID": "Curtiss, Charles P.",
        "City": "Wichita",
        "Year": "1986",
        "lat": "37.68698",
        "Document": "docs/charles-p-curtiss.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 699",
        "lng": "-97.270084",
        "State": "Kansas",
        "NameOrID": "Curtiss, Charles P.",
        "City": "Derby",
        "Year": "1986",
        "lat": "37.544885",
        "Document": "docs/charles-p-curtiss.pdf"
    },
    {
        "TroopNumber": "Troop 453",
        "lng": "-117.938024",
        "State": "California",
        "NameOrID": "Cusimano, Ross",
        "City": "West Covina",
        "Year": "1991",
        "lat": "34.072149",
        "Document": "docs/ross-cusimano.pdf"
    },
    {
        "TroopNumber": "Troop 565",
        "lng": "-117.277584",
        "State": "Oregon",
        "NameOrID": "Dahlgren, Gary G.",
        "City": "Enterprise",
        "Year": "1987",
        "lat": "45.42596",
        "Document": "docs/gary-g-dahlgren.pdf"
    },
    {
        "TroopNumber": "Troop 284",
        "lng": "",
        "State": "New York",
        "NameOrID": "D'Amore, Robert F.",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": " docs/robert-f-damore.pdf"
    },
    {
        "TroopNumber": "Explorer 2070",
        "lng": "-105.992344",
        "State": "Colorado",
        "NameOrID": "Danhauer, Gary D.",
        "City": "Salida",
        "Year": "1991",
        "lat": "38.53662",
        "Document": "docs/gary-d-danhauer.pdf"
    },
    {
        "TroopNumber": "5033",
        "lng": "-88.942674",
        "State": "Illinois",
        "NameOrID": "Daniels, Vernon R.",
        "City": "Cherry Valley",
        "Year": "1985",
        "lat": "42.236825",
        "Document": "docs/vernon-r-daniels.pdf"
    },
    {
        "TroopNumber": "Troop 490",
        "lng": "-72.594194",
        "State": "Connecticut",
        "NameOrID": "Davenport, David Dirk",
        "City": "Madison",
        "Year": "1985",
        "lat": "41.27969",
        "Document": "docs/david-dirk-davenport.pdf"
    },
    {
        "TroopNumber": "Pack 706",
        "lng": "-83.560633",
        "State": "Georgia",
        "NameOrID": "David, Larry James",
        "City": "Maysville",
        "Year": "1990",
        "lat": "34.253502",
        "Document": "docs/larry-james-david.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Davidson, Eugene",
        "City": "St. Louis",
        "Year": "1979",
        "lat": "38.62774",
        "Document": "docs/eugene-davidson.pdf"
    },
    {
        "TroopNumber": "Troop 867",
        "lng": "-80.241094",
        "State": "North Carolina",
        "NameOrID": "Davis, James T.",
        "City": "Winston-Salem",
        "Year": "1987",
        "lat": "36.099625",
        "Document": "docs/james-t-davis.pdf"
    },
    {
        "TroopNumber": "Troop 773",
        "lng": "-96.955506",
        "State": "Texas",
        "NameOrID": "Davis, Gerald L.",
        "City": "Irving",
        "Year": "1975",
        "lat": "32.813516",
        "Document": " docs/gerald-l-davis.pdf"
    },
    {
        "TroopNumber": "Troop 113",
        "lng": "-77.271574",
        "State": "New York",
        "NameOrID": "Davis, Francis Eugene",
        "City": "Walworth",
        "Year": "1986",
        "lat": "43.139205",
        "Document": "docs/francis-eugene-davis.pdf"
    },
    {
        "TroopNumber": "Troop 98",
        "lng": "-89.69882",
        "State": "Illinois",
        "NameOrID": "Davis, Harold E.",
        "City": "Sterling ",
        "Year": "1991",
        "lat": "41.794044",
        "Document": "docs/harold-e-davis.pdf"
    },
    {
        "TroopNumber": "Troop 43",
        "lng": "-72.465748",
        "State": "Vermont",
        "NameOrID": "Davis, Mark W.",
        "City": "Westminster",
        "Year": "1990",
        "lat": "43.07212",
        "Document": "docs/mark-w-davis.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Davis, Donald L.",
        "City": "",
        "Year": "1975",
        "lat": "",
        "Document": " docs/donald-l-davis.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Davis, Samuel D.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/samuel-d-davis.pdf"
    },
    {
        "TroopNumber": "Pack 3072",
        "lng": "-118.063704",
        "State": "California",
        "NameOrID": "Day, Howard A.",
        "City": "Cerritos ",
        "Year": "1988",
        "lat": "33.868545",
        "Document": "docs/howard-a-day.pdf"
    },
    {
        "TroopNumber": "Troop 72",
        "lng": "-118.063704",
        "State": "California",
        "NameOrID": "Day, Howard A.",
        "City": "Cerritos",
        "Year": "1988",
        "lat": "33.868545",
        "Document": "docs/howard-a-day.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-81.090719",
        "State": "Georgia",
        "NameOrID": "Deal, Robert D.",
        "City": "Savannah",
        "Year": "1982",
        "lat": "32.08078",
        "Document": " docs/robert-d-deal.pdf"
    },
    {
        "TroopNumber": "Troop 130",
        "lng": "-77.189499",
        "State": "New York",
        "NameOrID": "Dean, Alan Miles",
        "City": "Marion",
        "Year": "1985",
        "lat": "43.142605",
        "Document": "docs/alan-miles-dean.pdf"
    },
    {
        "TroopNumber": "Explorer 137",
        "lng": "-77.189499",
        "State": "New York",
        "NameOrID": "Dean, Alan Miles",
        "City": "Marion",
        "Year": "1985",
        "lat": "43.142605",
        "Document": "docs/alan-miles-dean.pdf"
    },
    {
        "TroopNumber": "45",
        "lng": "-94.626824",
        "State": "Kansas",
        "NameOrID": "Deane, Jay W.",
        "City": "Kansas City",
        "Year": "1988",
        "lat": "39.11338",
        "Document": "docs/jay-w-deane.pdf"
    },
    {
        "TroopNumber": "Pack 133",
        "lng": "-92.639789",
        "State": "Louisiana",
        "NameOrID": "Debose, Michael Ray",
        "City": "Ruston",
        "Year": "1987",
        "lat": "32.530501",
        "Document": "docs/michael-ray-debose.pdf"
    },
    {
        "TroopNumber": "Troop 133",
        "lng": "-92.639789",
        "State": "Louisiana",
        "NameOrID": "Debose, Michael Ray",
        "City": "Ruston",
        "Year": "1987",
        "lat": "32.530501",
        "Document": "docs/michael-ray-debose.pdf"
    },
    {
        "TroopNumber": "Explorer 245",
        "lng": "-87.979104",
        "State": "Illinois",
        "NameOrID": "DeCesare, Robert F.",
        "City": "Villa Park",
        "Year": "1977",
        "lat": "41.88929",
        "Document": " docs/robert-f-decesare.pdf"
    },
    {
        "TroopNumber": "Troop 17",
        "lng": "-111.306074",
        "State": "Montana",
        "NameOrID": "Deck, James H.",
        "City": "Great Falls",
        "Year": "1988",
        "lat": "47.507145",
        "Document": "docs/james-h-deck.pdf"
    },
    {
        "TroopNumber": "Troop 273",
        "lng": "-81.440379",
        "State": "Ohio",
        "NameOrID": "Decker, David G.",
        "City": "Stow",
        "Year": "1989",
        "lat": "41.168025",
        "Document": "docs/david-g-decker.pdf"
    },
    {
        "TroopNumber": "Troop 93",
        "lng": "8.2723",
        "State": "Germany",
        "NameOrID": "Defoor, Rae C.",
        "City": "Mainz",
        "Year": "1984",
        "lat": "50.000605",
        "Document": " docs/rae-c-defoor.pdf"
    },
    {
        "TroopNumber": "Pack 245",
        "lng": "-96.623583",
        "State": "Texas",
        "NameOrID": "DeJean, Prescott Louis",
        "City": "Port Lavaca",
        "Year": "1991",
        "lat": "28.61598",
        "Document": "docs/prescott-louis-dejean.pdf"
    },
    {
        "TroopNumber": "Pack 116",
        "lng": "-96.405069",
        "State": "Texas",
        "NameOrID": "DeJean, Prescott Louis",
        "City": "Port O'Connor",
        "Year": "1991",
        "lat": "28.44713",
        "Document": "docs/prescott-louis-dejean.pdf"
    },
    {
        "TroopNumber": "Pack 22",
        "lng": "-104.52275",
        "State": "New Mexico",
        "NameOrID": "Delgado, Ernie",
        "City": "Roswell",
        "Year": "1989",
        "lat": "33.39482",
        "Document": "docs/ernie-delgado.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-104.52275",
        "State": "New Mexico",
        "NameOrID": "Delgado, Ernie",
        "City": "Roswell",
        "Year": "1989",
        "lat": "33.39482",
        "Document": "docs/ernie-delgado.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-74.426524",
        "State": "New Jersey",
        "NameOrID": "Dellomo, Angelo",
        "City": "Atlantic City",
        "Year": "1987",
        "lat": "39.362815",
        "Document": "docs/angelo-dellomo.pdf"
    },
    {
        "TroopNumber": "Troop 460",
        "lng": "-94.343264",
        "State": "Mississippi",
        "NameOrID": "DeLoach, G. Lake",
        "City": "Greenwood",
        "Year": "1987",
        "lat": "38.85145",
        "Document": "docs/g-lake-deloach.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-94.591954",
        "State": "Mississippi",
        "NameOrID": "DeLoach, G. Lake",
        "City": "Cleveland",
        "Year": "1987",
        "lat": "38.67915",
        "Document": "docs/g-lake-deloach.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-89.725319",
        "State": "Mississippi",
        "NameOrID": "DeLoach, G. Lake",
        "City": "Hazelhurst",
        "Year": "1987",
        "lat": "45.809319",
        "Document": "docs/g-lake-deloach.pdf"
    },
    {
        "TroopNumber": "Troop 282",
        "lng": "-83.946673",
        "State": "Michigan",
        "NameOrID": "DeLong, Robert W.",
        "City": "Saginaw",
        "Year": "1972",
        "lat": "43.419181",
        "Document": " docs/robert-w-delong.pdf"
    },
    {
        "TroopNumber": "Pack 194",
        "lng": "-80.296104",
        "State": "North Carolina",
        "NameOrID": "DelReal, Robert",
        "City": "Rural Hall",
        "Year": "1991",
        "lat": "36.24074",
        "Document": "docs/robert-delreal.pdf"
    },
    {
        "TroopNumber": "Troop 962",
        "lng": "-80.241094",
        "State": "North Carolina",
        "NameOrID": "DelReal, Robert",
        "City": "Winston-Salem",
        "Year": "1991",
        "lat": "36.099625",
        "Document": "docs/robert-delreal.pdf"
    },
    {
        "TroopNumber": "Pack 38",
        "lng": "-71.547991",
        "State": "Massachusetts",
        "NameOrID": "DeMelia, James",
        "City": "Marlboro",
        "Year": "1973",
        "lat": "42.346806",
        "Document": " docs/james-demelia.pdf"
    },
    {
        "TroopNumber": "Troop 10",
        "lng": "-71.547991",
        "State": "Massachusetts",
        "NameOrID": "DeMelia, James",
        "City": "Marlboro",
        "Year": "1973",
        "lat": "42.346806",
        "Document": " docs/james-demelia.pdf"
    },
    {
        "TroopNumber": "Troop 347",
        "lng": "-72.281499",
        "State": "New Hampshire",
        "NameOrID": "Demers, David C.",
        "City": "Keene",
        "Year": "1986",
        "lat": "42.933205",
        "Document": "docs/david-c-demers.pdf"
    },
    {
        "TroopNumber": "Troop 27",
        "lng": "-74.128902",
        "State": "New Jersey",
        "NameOrID": "Deneke, John",
        "City": "Glen Rock",
        "Year": "1980",
        "lat": "40.961376",
        "Document": "docs/john-deneke.pdf"
    },
    {
        "TroopNumber": "Troop 503",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Denison, Joe Dan",
        "City": "Dallas",
        "Year": "1981",
        "lat": "32.778155",
        "Document": " docs/joe-dan-denison.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-91.251919",
        "State": "Wisconsin",
        "NameOrID": "Dennis, Mark A.",
        "City": "La Crosse",
        "Year": "1981",
        "lat": "43.812625",
        "Document": " docs/mark-a-dennis.pdf"
    },
    {
        "TroopNumber": "Explorer 2116",
        "lng": "-104.821814",
        "State": "Colorado",
        "NameOrID": "DeRosa, Thomas A.",
        "City": "Colorado Springs",
        "Year": "1991",
        "lat": "38.83345",
        "Document": "docs/thomas-a-derosa.pdf"
    },
    {
        "TroopNumber": "Troop 123",
        "lng": "-74.887204",
        "State": "Pennsylvania",
        "NameOrID": "Devine, James",
        "City": "Levittown",
        "Year": "1989",
        "lat": "40.160665",
        "Document": "docs/james-devine.pdf"
    },
    {
        "TroopNumber": "Pack 123",
        "lng": "-74.887204",
        "State": "Pennsylvania",
        "NameOrID": "Devine, James",
        "City": "Levittown",
        "Year": "1989",
        "lat": "40.160665",
        "Document": "docs/james-devine.pdf"
    },
    {
        "TroopNumber": "Troop 316",
        "lng": "-85.790544",
        "State": "Michigan",
        "NameOrID": "Devries, David A.",
        "City": "Casnovia",
        "Year": "1983",
        "lat": "43.234625",
        "Document": " docs/david-a-devries.pdf"
    },
    {
        "TroopNumber": "Troop 41",
        "lng": "-73.400227",
        "State": "New York",
        "NameOrID": "Dewey, Robert L.",
        "City": "Whitehall",
        "Year": "1973",
        "lat": "43.550026",
        "Document": " docs/robert-l-dewey.pdf"
    },
    {
        "TroopNumber": "Pack 106",
        "lng": "-72.97828",
        "State": "Vermont",
        "NameOrID": "Dewey, Robert L.",
        "City": "Rutland",
        "Year": "1973",
        "lat": "43.605098",
        "Document": " docs/robert-l-dewey.pdf"
    },
    {
        "TroopNumber": "Pack ",
        "lng": "-81.406744",
        "State": "Florida",
        "NameOrID": "Dewey, Robert L.",
        "City": "Kissimmee",
        "Year": "1973",
        "lat": "28.29443",
        "Document": " docs/robert-l-dewey.pdf"
    },
    {
        "TroopNumber": "136",
        "lng": "-117.01666",
        "State": "Idaho",
        "NameOrID": "Deyarmin, Daniel A.",
        "City": "Lewiston ",
        "Year": "1988",
        "lat": "46.418657",
        "Document": "docs/daniel-a-deyarmin.pdf"
    },
    {
        "TroopNumber": "164",
        "lng": "",
        "State": "",
        "NameOrID": "Dhalluin, James T.",
        "City": "",
        "Year": "1974",
        "lat": "",
        "Document": " docs/james-t-dhalluin.pdf"
    },
    {
        "TroopNumber": "Troop 37",
        "lng": "-74.075283",
        "State": "New York",
        "NameOrID": "Diaz, Christopher",
        "City": "Staten Island",
        "Year": "1987",
        "lat": "40.64244",
        "Document": "docs/christopher-diaz.pdf"
    },
    {
        "TroopNumber": "Troop 52",
        "lng": "-74.075283",
        "State": "New York",
        "NameOrID": "Diaz, Christopher",
        "City": "Staten Island",
        "Year": "1987",
        "lat": "40.64244",
        "Document": "docs/christopher-diaz.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-74.075283",
        "State": "New York",
        "NameOrID": "Diaz, Christopher",
        "City": "Staten Island",
        "Year": "1987",
        "lat": "40.64244",
        "Document": "docs/christopher-diaz.pdf"
    },
    {
        "TroopNumber": "3371",
        "lng": "-96.370424",
        "State": "Texas",
        "NameOrID": "Dickens, David Kimbel",
        "City": "Bryan",
        "Year": "1988",
        "lat": "30.670985",
        "Document": "docs/david-kimbel-dickens.pdf"
    },
    {
        "TroopNumber": "Not Applicable ",
        "lng": "",
        "State": "",
        "NameOrID": "Dickenson, George Henri",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/george-henri-dickenson.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-76.209649",
        "State": "New York",
        "NameOrID": "Dickinson, Richard L.",
        "City": "Liverpool",
        "Year": "1988",
        "lat": "43.10424",
        "Document": "docs/richard-l-dickinson.pdf"
    },
    {
        "TroopNumber": "Troop 73",
        "lng": "-74.64528",
        "State": "New Jersey",
        "NameOrID": "Diegelman, George J.",
        "City": "Egg Harbor",
        "Year": "1984",
        "lat": "39.53078",
        "Document": " docs/george-j-diegelman.pdf"
    },
    {
        "TroopNumber": "Troop 315",
        "lng": "-76.829198",
        "State": "Maryland",
        "NameOrID": "Digiacomo, Michael A.",
        "City": "Reisterstown",
        "Year": "1987",
        "lat": "39.464791",
        "Document": "docs/michael-a-digiacomo.pdf"
    },
    {
        "TroopNumber": "Troop 347",
        "lng": "23.73718",
        "State": "Greece",
        "NameOrID": "Dilley, Richard L.",
        "City": "Athens",
        "Year": "1990",
        "lat": "37.97605",
        "Document": "docs/richard-l-dilley.pdf"
    },
    {
        "TroopNumber": "Troop 85",
        "lng": "-118.526514",
        "State": "California",
        "NameOrID": "Dillingham, Edward",
        "City": "Pacific Palisades",
        "Year": "1987",
        "lat": "34.047045",
        "Document": "docs/edward-dillingham.pdf"
    },
    {
        "TroopNumber": "76",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "DiMinuco, Frank G.",
        "City": "Syracuse",
        "Year": "1987",
        "lat": "43.04999",
        "Document": "docs/frank-g-diminuco.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Dimsha, Charles",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/charles-dimsha.pdf"
    },
    {
        "TroopNumber": "Troop 35",
        "lng": "-90.098244",
        "State": "Illinois",
        "NameOrID": "Dine, Daniel Russell",
        "City": "Wood River",
        "Year": "1990",
        "lat": "38.86231",
        "Document": "docs/daniel-russell-dine.pdf"
    },
    {
        "TroopNumber": "Troop 48",
        "lng": "-78.193764",
        "State": "New York",
        "NameOrID": "Dingman, Kenneth Allan",
        "City": "Albion",
        "Year": "1971",
        "lat": "43.24572",
        "Document": " docs/kenneth-allan-dingman.pdf"
    },
    {
        "TroopNumber": "Pack 3825",
        "lng": "-76.285069",
        "State": "Virginia",
        "NameOrID": "Disbennett, Terry D.",
        "City": "Norfolk",
        "Year": "1990",
        "lat": "36.846825",
        "Document": "docs/terry-d-disbennett.pdf"
    },
    {
        "TroopNumber": "Troop 236",
        "lng": "-84.194439",
        "State": "Ohio",
        "NameOrID": "Dittmar, Robert A.",
        "City": "Dayton ",
        "Year": "1990",
        "lat": "39.75911",
        "Document": "docs/robert-a-dittmar.pdf"
    },
    {
        "TroopNumber": "Pack 3395",
        "lng": "-82.178149",
        "State": "Ohio",
        "NameOrID": "Ditzler, Kenneth S.",
        "City": "Lorain",
        "Year": "1990",
        "lat": "41.4682",
        "Document": "docs/kenneth-s-ditzler.pdf"
    },
    {
        "TroopNumber": "Troop 101",
        "lng": "-90.198729",
        "State": "Illinois",
        "NameOrID": "Dole, Edward J.",
        "City": "Cambridge",
        "Year": "1986",
        "lat": "41.30493",
        "Document": "docs/edward-j-dole.pdf"
    },
    {
        "TroopNumber": "Troop 17",
        "lng": "-72.589287",
        "State": "Massachusetts",
        "NameOrID": "Doley, Kenneth P.",
        "City": "Springfiled",
        "Year": "1986",
        "lat": "42.10125",
        "Document": "docs/kenneth-p-doley.pdf"
    },
    {
        "TroopNumber": "Troop 49",
        "lng": "-147.352564",
        "State": "Alaska",
        "NameOrID": "Dollar, Don H.",
        "City": "North Pole",
        "Year": "1984",
        "lat": "64.74878",
        "Document": " docs/don-h-dollar.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "D'Ombrain, Nicolas Paul",
        "City": "",
        "Year": "1986",
        "lat": "",
        "Document": "docs/nicolas-paul-dombrain.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Donaghe, Sam W.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/sam-w-donaghe.pdf"
    },
    {
        "TroopNumber": "Troop 259",
        "lng": "-71.464209",
        "State": "New Hampshire",
        "NameOrID": "Donahue, Daniel T.",
        "City": "Nahshua",
        "Year": "1985",
        "lat": "42.758365",
        "Document": "docs/daniel-t-donahue.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-73.178994",
        "State": "Vermont",
        "NameOrID": "Dow, Carl",
        "City": "South Burlington",
        "Year": "1982",
        "lat": "44.454685",
        "Document": " docs/carl-dow.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Dowdle, J. Reed",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/j-reed-dowdle.pdf"
    },
    {
        "TroopNumber": "Troop 25",
        "lng": "-122.471219",
        "State": "Washington",
        "NameOrID": "Downing, Arthur H.",
        "City": "Bellingham",
        "Year": "1975",
        "lat": "48.75235",
        "Document": " docs/arthur-h-downing.pdf"
    },
    {
        "TroopNumber": "Troop 411",
        "lng": "-80.237419",
        "State": "Florida",
        "NameOrID": "Draher, William",
        "City": "Miami",
        "Year": "1990",
        "lat": "25.728985",
        "Document": "docs/william-draher.pdf"
    },
    {
        "TroopNumber": "Pack 22",
        "lng": "-123.324639",
        "State": "Oregon",
        "NameOrID": "Drake, Benjamin E.",
        "City": "Grants Pass",
        "Year": "1988",
        "lat": "42.44321",
        "Document": "docs/benjamin-e-drake.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Drake, Robert Fredrick",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/robert-fredrick-drake.pdf"
    },
    {
        "TroopNumber": "Explorer 111",
        "lng": "-122.441654",
        "State": "Washington",
        "NameOrID": "Dreher, Marty V.",
        "City": "Tacoma",
        "Year": "1988",
        "lat": "47.255135",
        "Document": "docs/marty-v-dreher.pdf"
    },
    {
        "TroopNumber": "Troop 336",
        "lng": "-77.604546",
        "State": "Pennsylvania",
        "NameOrID": "Dreisbach, Edward A.",
        "City": "",
        "Year": "1989",
        "lat": "40.994705",
        "Document": "docs/edward-a-dreisbach.pdf"
    },
    {
        "TroopNumber": "105",
        "lng": "-94.874839",
        "State": "Kansas",
        "NameOrID": "Drummond, L.Z.",
        "City": "Paola",
        "Year": "1985",
        "lat": "38.57861",
        "Document": "docs/lz-drummond.pdf"
    },
    {
        "TroopNumber": "107",
        "lng": "-94.874839",
        "State": "Kansas",
        "NameOrID": "Drummond, L.Z.",
        "City": "Paola",
        "Year": "1985",
        "lat": "38.57861",
        "Document": "docs/lz-drummond.pdf"
    },
    {
        "TroopNumber": "Troop 28",
        "lng": "-79.997459",
        "State": "Pennsylvania",
        "NameOrID": "Drummond, Brian E.",
        "City": "Pittsburgh ",
        "Year": "1989",
        "lat": "40.438335",
        "Document": "docs/brian-e-drummond.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-72.980016",
        "State": "Connecticut",
        "NameOrID": "Drummond, Mark Gavin",
        "City": "New Hartford",
        "Year": "1989",
        "lat": "41.879141",
        "Document": "docs/mark-gavin-drummond.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-122.161659",
        "State": "Oregon",
        "NameOrID": "Drury, Kenneth James",
        "City": "McKenzie Bridge",
        "Year": "1986",
        "lat": "44.174141",
        "Document": "docs/kenneth-james-drury.pdf"
    },
    {
        "TroopNumber": "Troop 965",
        "lng": "-118.062116",
        "State": "Washington",
        "NameOrID": "Druxman, Ed",
        "City": "Kettle Falls",
        "Year": "1989",
        "lat": "48.61053",
        "Document": "docs/ed-druxman.pdf"
    },
    {
        "TroopNumber": "Troop 999",
        "lng": "-117.904754",
        "State": "Washington",
        "NameOrID": "Druxman, Ed",
        "City": "Colville",
        "Year": "1989",
        "lat": "48.54657",
        "Document": "docs/ed-druxman.pdf"
    },
    {
        "TroopNumber": "Troop 311",
        "lng": "-79.87912",
        "State": "Pennsylvania",
        "NameOrID": "Drzewinski, Edward R.",
        "City": "Fox Chapel",
        "Year": "1989",
        "lat": "40.515756",
        "Document": "docs/edward-r-drzewinski.pdf"
    },
    {
        "TroopNumber": "Troop 31",
        "lng": "-72.206332",
        "State": "Connecticut",
        "NameOrID": "Dubeau, Paul A.",
        "City": "Willamantic",
        "Year": "1988",
        "lat": "41.710542",
        "Document": "docs/paul-a-dubeau.pdf"
    },
    {
        "TroopNumber": "Troop 117",
        "lng": "-71.463089",
        "State": "New Hampshire",
        "NameOrID": "Dubois, Richard A.",
        "City": "Manchester",
        "Year": "1982",
        "lat": "42.991175",
        "Document": " docs/richard-a-dubois.pdf"
    },
    {
        "TroopNumber": "5",
        "lng": "-81.090719",
        "State": "Georgia",
        "NameOrID": "DuBois, Samuel Max",
        "City": "Savannah",
        "Year": "1972",
        "lat": "32.08078",
        "Document": " docs/samuel-max-dubois.pdf"
    },
    {
        "TroopNumber": "Troop 263",
        "lng": "-83.536259",
        "State": "Ohio",
        "NameOrID": "Duckworth, John Edwin",
        "City": "Toledo",
        "Year": "1988",
        "lat": "41.65381",
        "Document": "docs/john-edwin-duckworth.pdf"
    },
    {
        "TroopNumber": "Explorer 263",
        "lng": "-83.536259",
        "State": "Ohio",
        "NameOrID": "Duckworth, John Edwin",
        "City": "Toledo",
        "Year": "1988",
        "lat": "41.65381",
        "Document": "docs/john-edwin-duckworth.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-81.440379",
        "State": "Ohio",
        "NameOrID": "Duckworth, John Edwin",
        "City": "Stow",
        "Year": "1988",
        "lat": "41.168025",
        "Document": "docs/john-edwin-duckworth.pdf"
    },
    {
        "TroopNumber": "Explorer 177",
        "lng": "-81.440379",
        "State": "Ohio",
        "NameOrID": "Duckworth, John Edwin",
        "City": "Stow",
        "Year": "1988",
        "lat": "41.168025",
        "Document": "docs/john-edwin-duckworth.pdf"
    },
    {
        "TroopNumber": "Troop 884",
        "lng": "-84.367438",
        "State": "Kentucky",
        "NameOrID": "Dudenhoeffer, Kirk",
        "City": "Butler",
        "Year": "1973",
        "lat": "38.78974",
        "Document": " docs/kirk-dudenhoeffer.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-76.209649",
        "State": "New York",
        "NameOrID": "Duerr, Donald",
        "City": "Liverpool",
        "Year": "1985",
        "lat": "43.10424",
        "Document": "docs/donald-duerr.pdf"
    },
    {
        "TroopNumber": "Explorer 45",
        "lng": "-76.707754",
        "State": "Pennsylvania",
        "NameOrID": "Dufour, Peter J.",
        "City": "Valley Forge",
        "Year": "1985",
        "lat": "39.98459",
        "Document": "docs/peter-j-dufour.pdf"
    },
    {
        "TroopNumber": "14",
        "lng": "-97.742984",
        "State": "Texas",
        "NameOrID": "Dugan, Mike J.",
        "City": "Austin",
        "Year": "1988",
        "lat": "30.267605",
        "Document": "docs/mike-j-dugan.pdf"
    },
    {
        "TroopNumber": "Pack 3338",
        "lng": "",
        "State": "Indiana",
        "NameOrID": "Duncan, Donald G.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/donald-g-duncan.pdf"
    },
    {
        "TroopNumber": "Pack 181",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Duncan, Frank Edward",
        "City": "Rochester ",
        "Year": "1971",
        "lat": "43.1555",
        "Document": " docs/frank-edward-duncan.pdf"
    },
    {
        "TroopNumber": "Pack 321",
        "lng": "-158.028275",
        "State": "Hawaii",
        "NameOrID": "Dunham, Edward P.",
        "City": "Wahiawa/Wheeler AFB",
        "Year": "1982",
        "lat": "21.48666",
        "Document": " docs/edward-p-dunham.pdf"
    },
    {
        "TroopNumber": "Troop 176",
        "lng": "-158.025549",
        "State": "Hawaii",
        "NameOrID": "Dunham, Edward P.",
        "City": "Wahiawa",
        "Year": "1982",
        "lat": "21.49765",
        "Document": " docs/edward-p-dunham.pdf"
    },
    {
        "TroopNumber": "Troop 70",
        "lng": "-121.981509",
        "State": "Washington",
        "NameOrID": "Dunkin, Mark L.E.",
        "City": "Monroe",
        "Year": "1987",
        "lat": "47.852405",
        "Document": "docs/mark-le-dunkin.pdf"
    },
    {
        "TroopNumber": "Pack 98",
        "lng": "-119.785734",
        "State": "California",
        "NameOrID": "Dunlap, Alan Craig",
        "City": "Fresno",
        "Year": "1973",
        "lat": "36.740685",
        "Document": "docs/alan-craig-dunlap.pdf"
    },
    {
        "TroopNumber": "Troop 98",
        "lng": "-119.785734",
        "State": "California",
        "NameOrID": "Dunlap, Alan Craig",
        "City": "Fresno",
        "Year": "1973",
        "lat": "36.740685",
        "Document": "docs/alan-craig-dunlap.pdf"
    },
    {
        "TroopNumber": "Troop 378",
        "lng": "-98.032204",
        "State": "Texas",
        "NameOrID": "Dunn, Rayford T.",
        "City": "Hico",
        "Year": "1978",
        "lat": "31.982615",
        "Document": "docs/rayford-t-dunn.pdf"
    },
    {
        "TroopNumber": "Troop 326",
        "lng": "-118.472154",
        "State": "California",
        "NameOrID": "Dunn, Alan K.",
        "City": "Venice",
        "Year": "1978",
        "lat": "33.987545",
        "Document": "docs/alan-k-dunn.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Dunn, Robert Arnold",
        "City": "",
        "Year": "1973",
        "lat": "",
        "Document": " docs/robert-arnold-dunn.pdf"
    },
    {
        "TroopNumber": "20",
        "lng": "-74.493857",
        "State": "New York",
        "NameOrID": "Dunnigan, Daniel L.",
        "City": "Grand Gorge",
        "Year": "1988",
        "lat": "42.36277",
        "Document": "docs/daniel-l-dunnigan.pdf"
    },
    {
        "TroopNumber": "Pack 29",
        "lng": "-73.941026",
        "State": "New York",
        "NameOrID": "DuPont, Lawrence J.",
        "City": "Schenectady",
        "Year": "1986",
        "lat": "42.81226",
        "Document": "docs/lawrence-j-dupont.pdf"
    },
    {
        "TroopNumber": "Troop 99",
        "lng": "-82.122752",
        "State": "Georgia",
        "NameOrID": "Dupuy, William Arley",
        "City": "Ft. Gordon",
        "Year": "1976",
        "lat": "33.398926",
        "Document": " docs/william-arley-dupuy.pdf"
    },
    {
        "TroopNumber": "Pack 3173",
        "lng": "-94.596593",
        "State": "Missouri",
        "NameOrID": "Dwyer, Joe T.",
        "City": "Gower",
        "Year": "1988",
        "lat": "39.613133",
        "Document": "docs/joe-t-dwyer.pdf"
    },
    {
        "TroopNumber": "719",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Dykes, Timur V.",
        "City": "Portland",
        "Year": "1987",
        "lat": "45.511795",
        "Document": "docs/timur-v-dykes.pdf"
    },
    {
        "TroopNumber": "Troop 945",
        "lng": "-111.887559",
        "State": "Utah",
        "NameOrID": "Eames, Oliver D.",
        "City": "Farmington",
        "Year": "1988",
        "lat": "40.98311",
        "Document": "docs/oliver-d-eames.pdf"
    },
    {
        "TroopNumber": "Troop 239",
        "lng": "-82.400114",
        "State": "South Carolina",
        "NameOrID": "Easley, James J.",
        "City": "Greenville",
        "Year": "1975",
        "lat": "34.84827",
        "Document": " docs/james-j-easley.pdf"
    },
    {
        "TroopNumber": "Troop 733",
        "lng": "-82.400114",
        "State": "South Carolina",
        "NameOrID": "Easley, James J.",
        "City": "Greenville",
        "Year": "1975",
        "lat": "34.84827",
        "Document": " docs/james-j-easley.pdf"
    },
    {
        "TroopNumber": "Pack 118",
        "lng": "-84.169982",
        "State": "Georgia",
        "NameOrID": "Eaton, Leon E.",
        "City": "Stone Mountain",
        "Year": "1987",
        "lat": "33.803855",
        "Document": "docs/leon-e-eaton.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Eaton, Wayne Martin",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/wayne-martin-eaton.pdf"
    },
    {
        "TroopNumber": "Troop 117",
        "lng": "-77.930099",
        "State": "Maryland",
        "NameOrID": "Ebberts, Fred Thomas",
        "City": "Clear Spring",
        "Year": "1989",
        "lat": "39.65639",
        "Document": "docs/fred-thomas-ebberts.pdf"
    },
    {
        "TroopNumber": "Explorer 8608",
        "lng": "-77.433654",
        "State": "Virginia",
        "NameOrID": "Eck, Michael E.",
        "City": "Richmond",
        "Year": "1991",
        "lat": "37.5407",
        "Document": "docs/michael-e-eck.pdf"
    },
    {
        "TroopNumber": "Troop 891",
        "lng": "-77.433654",
        "State": "Virginia",
        "NameOrID": "Eck, Michael E.",
        "City": "Richmond",
        "Year": "1991",
        "lat": "37.5407",
        "Document": "docs/michael-e-eck.pdf"
    },
    {
        "TroopNumber": "Explorer 2161",
        "lng": "-76.247847",
        "State": "Virginia",
        "NameOrID": "Edmundson, Sean A.",
        "City": "Chesapeake",
        "Year": "1990",
        "lat": "36.714295",
        "Document": "docs/sean-a-edmundson.pdf"
    },
    {
        "TroopNumber": "Troop 330",
        "lng": "-112.322914",
        "State": "Arizona",
        "NameOrID": "Edwards, William Reese",
        "City": "Prescott Valley",
        "Year": "1988",
        "lat": "34.586002",
        "Document": "docs/william-reese-edwards.pdf"
    },
    {
        "TroopNumber": "Troop 160",
        "lng": "-75.296474",
        "State": "Pennsylvania",
        "NameOrID": "Eisenhart, Gregory L.",
        "City": "Drexel hill",
        "Year": "1975",
        "lat": "39.949",
        "Document": " docs/gregory-l-eisenhart.pdf"
    },
    {
        "TroopNumber": "23",
        "lng": "144.751877",
        "State": "Guam",
        "NameOrID": "Ellington, David Joseph",
        "City": "Agana",
        "Year": "1972",
        "lat": "13.47777",
        "Document": " docs/david-joseph-ellington.pdf"
    },
    {
        "TroopNumber": "Troop 226",
        "lng": "-112.075774",
        "State": "Arizona",
        "NameOrID": "Ellington, David Joseph",
        "City": "Phoenix",
        "Year": "1972",
        "lat": "33.44826",
        "Document": " docs/david-joseph-ellington.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-116.193409",
        "State": "Idaho",
        "NameOrID": "Elliott, Robert Francis",
        "City": "Boise",
        "Year": "1971",
        "lat": "43.60698",
        "Document": " docs/robert-francis-elliott.pdf"
    },
    {
        "TroopNumber": "Troop 185",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Ellis, David L.",
        "City": "Philadelphia",
        "Year": "1989",
        "lat": "39.95228",
        "Document": "docs/david-l-ellis.pdf"
    },
    {
        "TroopNumber": "Pack 60",
        "lng": "-87.259629",
        "State": "Indiana",
        "NameOrID": "Elmquist, Edward S.",
        "City": "Dugger",
        "Year": "1974",
        "lat": "39.068205",
        "Document": " docs/edward-s-elmquist.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-88.110629",
        "State": "Illinois",
        "NameOrID": "Emanuelson, Arthur R.",
        "City": "Wheaton",
        "Year": "1989",
        "lat": "41.86606",
        "Document": "docs/arthur-r-emanuelson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-89.571699",
        "State": "Wisconsin",
        "NameOrID": "Emanuelson, Arthur R.",
        "City": "Oxford ",
        "Year": "1989",
        "lat": "43.781517",
        "Document": "docs/arthur-r-emanuelson.pdf"
    },
    {
        "TroopNumber": "Troop 62",
        "lng": "-76.664164",
        "State": "Pennsylvania",
        "NameOrID": "Embly, Kristofer J.",
        "City": "Bainbridge",
        "Year": "1983",
        "lat": "40.093115",
        "Document": " docs/kristofer-j-embly.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-88.952459",
        "State": "Illinois",
        "NameOrID": "Emmons, Paul R.",
        "City": "Decatur",
        "Year": "1977",
        "lat": "39.845845",
        "Document": " docs/paul-r-emmons.pdf"
    },
    {
        "TroopNumber": "Troop 345",
        "lng": "-78.642669",
        "State": "North Carolina",
        "NameOrID": "Emory, Ernest C.",
        "City": "Raleigh",
        "Year": "1976",
        "lat": "35.78551",
        "Document": " docs/ernest-c-emory.pdf"
    },
    {
        "TroopNumber": "Troop 354",
        "lng": "-78.642669",
        "State": "North Carolina",
        "NameOrID": "Emory, Ernest C.",
        "City": "Raleigh",
        "Year": "1976",
        "lat": "35.78551",
        "Document": " docs/ernest-c-emory.pdf"
    },
    {
        "TroopNumber": "District 5",
        "lng": "-111.668509",
        "State": "Utah",
        "NameOrID": "Empey, Dennis J.",
        "City": "Provo",
        "Year": "1988",
        "lat": "40.233775",
        "Document": "docs/dennis-j-empey.pdf"
    },
    {
        "TroopNumber": "Troop 250",
        "lng": "-89.862449",
        "State": "Missouri",
        "NameOrID": "Enderle, Jay J.A.",
        "City": "Perryville",
        "Year": "1973",
        "lat": "37.725325",
        "Document": " docs/jay-ja-enderle.pdf"
    },
    {
        "TroopNumber": "Explorer 887",
        "lng": "-89.660689",
        "State": "Missouri",
        "NameOrID": "Enderle, Jay J.A.",
        "City": "Chaffee ",
        "Year": "1973",
        "lat": "37.181865",
        "Document": " docs/jay-ja-enderle.pdf"
    },
    {
        "TroopNumber": "Troop 320",
        "lng": "-82.974829",
        "State": "Ohio",
        "NameOrID": "Engle, William B.",
        "City": "Clyde",
        "Year": "1987",
        "lat": "41.309345",
        "Document": "docs/william-b-engle.pdf"
    },
    {
        "TroopNumber": "Pack 3168",
        "lng": "-119.749194",
        "State": "Nevada",
        "NameOrID": "English, Donald Lee",
        "City": "Sparks",
        "Year": "1991",
        "lat": "39.541",
        "Document": "docs/donald-lee-english.pdf"
    },
    {
        "TroopNumber": "Troop 51",
        "lng": "-75.198234",
        "State": "New Jersey",
        "NameOrID": "Enz, Paul J.",
        "City": "Phillipsburg",
        "Year": "1989",
        "lat": "40.694569",
        "Document": "docs/paul-j-enz.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Erickson, Keith Duane",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/keith-duane-erickson.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-71.088764",
        "State": "Massachusetts",
        "NameOrID": "Erwin, Michael J.",
        "City": "Taunton",
        "Year": "1988",
        "lat": "41.900925",
        "Document": "docs/michael-j-erwin.pdf"
    },
    {
        "TroopNumber": "Troop 154",
        "lng": "-76.307357",
        "State": "Pennsylvania",
        "NameOrID": "Eshelman, Kenneth L.",
        "City": "Littitz",
        "Year": "1974",
        "lat": "40.1575",
        "Document": " docs/kenneth-l-eshelman.pdf"
    },
    {
        "TroopNumber": "Troop 223",
        "lng": "-101.833894",
        "State": "Texas",
        "NameOrID": "Estes, Melvin Christopher",
        "City": "Amarillo",
        "Year": "1989",
        "lat": "35.207255",
        "Document": "docs/melvin-christopher-estes.pdf"
    },
    {
        "TroopNumber": "Troop 44",
        "lng": "-71.041399",
        "State": "Massachusetts",
        "NameOrID": "Estey, Scott Cecil",
        "City": "Randolph",
        "Year": "1991",
        "lat": "42.16268",
        "Document": "docs/scott-cecil-estey.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-116.714711",
        "State": "California",
        "NameOrID": "Evans, Arthur C.",
        "City": "Idyllwild",
        "Year": "1989",
        "lat": "33.746083",
        "Document": "docs/arthur-c-evans.pdf"
    },
    {
        "TroopNumber": "Pack 8601",
        "lng": "-117.373879",
        "State": "California",
        "NameOrID": "Evans, Arthur C.",
        "City": "Riverside",
        "Year": "1989",
        "lat": "33.98163",
        "Document": "docs/arthur-c-evans.pdf"
    },
    {
        "TroopNumber": "Troop 506",
        "lng": "-87.508934",
        "State": "Indiana",
        "NameOrID": "Evans, Richard Allen",
        "City": "Hammond",
        "Year": "1987",
        "lat": "41.60508",
        "Document": " docs/richard-allen-evans.pdf"
    },
    {
        "TroopNumber": "Pack 70",
        "lng": "-88.952459",
        "State": "Illinois",
        "NameOrID": "Evans, Richard Allen",
        "City": "Decatur",
        "Year": "1987",
        "lat": "39.845845",
        "Document": " docs/richard-allen-evans.pdf"
    },
    {
        "TroopNumber": "Troop 169",
        "lng": "-119.116984",
        "State": "Washington",
        "NameOrID": "Evans, Jonathan W.",
        "City": "Kennewick",
        "Year": "1991",
        "lat": "46.20804",
        "Document": "docs/jonathan-w-evans.pdf"
    },
    {
        "TroopNumber": "Troop 303",
        "lng": "-90.355924",
        "State": "Missouri",
        "NameOrID": "Every, Steve B.",
        "City": "Webster Groves",
        "Year": "1980",
        "lat": "38.59265",
        "Document": " docs/steve-b-every.pdf"
    },
    {
        "TroopNumber": "Pack 777",
        "lng": "-124.107394",
        "State": "Oregon",
        "NameOrID": "Ewing, Robert Joseph",
        "City": "Florence",
        "Year": "1991",
        "lat": "43.96887",
        "Document": "docs/robert-joseph-ewing.pdf"
    },
    {
        "TroopNumber": "721",
        "lng": "-112.075774",
        "State": "Arizona",
        "NameOrID": "Falk, Herbert Henry",
        "City": "Phoenix",
        "Year": "1988",
        "lat": "33.44826",
        "Document": " docs/herbert-henry-falk.pdf"
    },
    {
        "TroopNumber": "Troop 148",
        "lng": "-121.290754",
        "State": "California",
        "NameOrID": "Falk, Herbert Henry",
        "City": "Stockton",
        "Year": "1988",
        "lat": "37.95368",
        "Document": " docs/herbert-henry-falk.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-87.830661",
        "State": "Illinois",
        "NameOrID": "Fanning, John Wood",
        "City": "Park Ridge",
        "Year": "1987",
        "lat": "42.008369",
        "Document": "docs/john-wood-fanning.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-93.220111",
        "State": "Louisiana",
        "NameOrID": "Fanning, John Wood",
        "City": "Lake Charles",
        "Year": "1987",
        "lat": "30.224033",
        "Document": "docs/john-wood-fanning.pdf"
    },
    {
        "TroopNumber": "Troop 5",
        "lng": "-71.049484",
        "State": "Massachusetts",
        "NameOrID": "Fannon, Walter",
        "City": "South Boston",
        "Year": "1988",
        "lat": "42.337955",
        "Document": "docs/walter-fannon.pdf"
    },
    {
        "TroopNumber": "Troop 688",
        "lng": "-82.323184",
        "State": "Florida",
        "NameOrID": "Farar, Boyd",
        "City": "Gainesville",
        "Year": "1990",
        "lat": "29.65195",
        "Document": "docs/boyd-farar.pdf"
    },
    {
        "TroopNumber": "Troop 111",
        "lng": "-74.486077",
        "State": "New Jersey",
        "NameOrID": "Farley, Joel Michael",
        "City": "Oak Ridge",
        "Year": "1973",
        "lat": "41.045611",
        "Document": " docs/joel-michael-farley.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-71.586394",
        "State": "Massachusetts",
        "NameOrID": "Farrar, Alden R.",
        "City": "Ayer",
        "Year": "1978",
        "lat": "42.559445",
        "Document": "docs/alden-r-farrar.pdf"
    },
    {
        "TroopNumber": "Troop 54",
        "lng": "-89.504093",
        "State": "Illinois",
        "NameOrID": "Farrell, Frank A.",
        "City": "Capertersville",
        "Year": "1981",
        "lat": "39.739256",
        "Document": " docs/frank-a-farrell.pdf"
    },
    {
        "TroopNumber": "Explorer 195",
        "lng": "-149.858384",
        "State": "Alaska",
        "NameOrID": "Feichtinger, Eric Frank",
        "City": "Anchorage ",
        "Year": "1989",
        "lat": "61.21755",
        "Document": "docs/eric-frank-feichtinger.pdf"
    },
    {
        "TroopNumber": "Explorer 2",
        "lng": "-149.858384",
        "State": "Alaska",
        "NameOrID": "Feichtinger, Eric Frank",
        "City": "Anchorage",
        "Year": "1989",
        "lat": "61.21755",
        "Document": "docs/eric-frank-feichtinger.pdf"
    },
    {
        "TroopNumber": "Troop 514",
        "lng": "-66.147379",
        "State": "Puerto Rico",
        "NameOrID": "Feliciano, Eladio J.",
        "City": "Bayamon",
        "Year": "1988",
        "lat": "18.394095",
        "Document": "docs/eladio-j-feliciano.pdf"
    },
    {
        "TroopNumber": "Explorer 75",
        "lng": "-76.798814",
        "State": "New York",
        "NameOrID": "Felker, Richard H.",
        "City": "Elmira",
        "Year": "1987",
        "lat": "42.09041",
        "Document": "docs/richard-h-felker.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 75",
        "lng": "-76.826069",
        "State": "New York",
        "NameOrID": "Felker, Richard H.",
        "City": "Horseheads",
        "Year": "1987",
        "lat": "42.172655",
        "Document": "docs/richard-h-felker.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-71.463089",
        "State": "New Hampshire",
        "NameOrID": "Fellows, Richard Carol",
        "City": "Manchester ",
        "Year": "1987",
        "lat": "42.991175",
        "Document": "docs/richard-carol-fellows.pdf"
    },
    {
        "TroopNumber": "Troop 193",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Ferguson, Samuel",
        "City": "philadelphia",
        "Year": "1973",
        "lat": "39.95228",
        "Document": " docs/samuel-ferguson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Ferris, Elwood J.",
        "City": "",
        "Year": "1973",
        "lat": "",
        "Document": " docs/elwood-j-ferris.pdf"
    },
    {
        "TroopNumber": "Troop 339",
        "lng": "-75.377784",
        "State": "Pennsylvania",
        "NameOrID": "Fertal, Robert E.",
        "City": "Bethlehem",
        "Year": "1991",
        "lat": "40.630985",
        "Document": "docs/robert-e-fertal.pdf"
    },
    {
        "TroopNumber": "Troop 73",
        "lng": "-95.940469",
        "State": "Nebraska",
        "NameOrID": "Fiehn, Larry O.",
        "City": "Omaha",
        "Year": "1991",
        "lat": "41.260675",
        "Document": "docs/larry-o-fiehn.pdf"
    },
    {
        "TroopNumber": "Troop 411",
        "lng": "-118.913314",
        "State": "California",
        "NameOrID": "Field, Stephen D.",
        "City": "Fillmore",
        "Year": "1988",
        "lat": "34.39851",
        "Document": "docs/stephen-d-field.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-118.492289",
        "State": "California",
        "NameOrID": "Field, Stephen D.",
        "City": "Santa Monica",
        "Year": "1988",
        "lat": "34.011565",
        "Document": "docs/stephen-d-field.pdf"
    },
    {
        "TroopNumber": "Troop 4412",
        "lng": "-94.581609",
        "State": "Missouri",
        "NameOrID": "Fields, Virgil Lynn",
        "City": "Smithville",
        "Year": "1988",
        "lat": "39.387125",
        "Document": "docs/virgil-lynn-fields.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-117.818824",
        "State": "California",
        "NameOrID": "Fields, Allen L.",
        "City": "Tustin",
        "Year": "1982",
        "lat": "33.743325",
        "Document": " docs/allen-l-fields.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Finch, John E.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/john-e-finch.pdf"
    },
    {
        "TroopNumber": "Venturing 6001",
        "lng": "-88.120559",
        "State": "Illinois",
        "NameOrID": "Findlay, Gary L.",
        "City": "Carol Stream",
        "Year": "1987",
        "lat": "41.916055",
        "Document": "docs/gary-l-findlay.pdf"
    },
    {
        "TroopNumber": "District 8601",
        "lng": "-85.601114",
        "State": "Michigan",
        "NameOrID": "Firestone, Thomas Leo",
        "City": "Kalamazoo",
        "Year": "1991",
        "lat": "42.292435",
        "Document": "docs/thomas-leo-firestone.pdf"
    },
    {
        "TroopNumber": "Troop 172",
        "lng": "-85.846554",
        "State": "Michigan",
        "NameOrID": "Firestone, Thomas Leo",
        "City": "Lawton",
        "Year": "1991",
        "lat": "42.167964",
        "Document": "docs/thomas-leo-firestone.pdf"
    },
    {
        "TroopNumber": "Troop 5",
        "lng": "-96.707739",
        "State": "Nebraska",
        "NameOrID": "Fischbach, John E.",
        "City": "Lincoln",
        "Year": "1989",
        "lat": "40.81362",
        "Document": "docs/john-e-fischbach.pdf"
    },
    {
        "TroopNumber": "517",
        "lng": "-122.410224",
        "State": "Washington",
        "NameOrID": "Fischer, Michael James",
        "City": "Spanaway",
        "Year": "1990",
        "lat": "47.0649",
        "Document": "docs/michael-james-fischer.pdf"
    },
    {
        "TroopNumber": "Troop 749",
        "lng": "-79.664239",
        "State": "North Carolina",
        "NameOrID": "Fisher, William H.",
        "City": "Reidsville",
        "Year": "1981",
        "lat": "36.359555",
        "Document": " docs/william-h-fisher.pdf"
    },
    {
        "TroopNumber": "Troop 121",
        "lng": "-122.419644",
        "State": "California",
        "NameOrID": "Fisher, Walter Lee",
        "City": "San Francisco",
        "Year": "1971",
        "lat": "37.777125",
        "Document": "docs/walter-lee-fisher.pdf"
    },
    {
        "TroopNumber": "Troop 31",
        "lng": "-122.314834",
        "State": "California",
        "NameOrID": "Fisher, Gail D.",
        "City": "San Mateo",
        "Year": "1990",
        "lat": "37.54703",
        "Document": "docs/gail-d-fisher.pdf"
    },
    {
        "TroopNumber": "Troop 26",
        "lng": "-74.726715",
        "State": "New Jersey",
        "NameOrID": "Fitzgerald, John T.",
        "City": "Barneat",
        "Year": "1988",
        "lat": "40.14323",
        "Document": "docs/john-t-fitzgerald.pdf"
    },
    {
        "TroopNumber": "Troop 746",
        "lng": "-81.947864",
        "State": "Florida",
        "NameOrID": "Flatt, Rodney G.",
        "City": "Lakeland",
        "Year": "1988",
        "lat": "28.04419",
        "Document": "docs/rodney-g-flatt.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Flavin, Michael L.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/michael-l-flavin.pdf"
    },
    {
        "TroopNumber": "Troop 1052",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Fleer, Earl",
        "City": "Kansas City",
        "Year": "1990",
        "lat": "39.10296",
        "Document": "docs/earl-fleer.pdf"
    },
    {
        "TroopNumber": "4052",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Fleer, Earl",
        "City": "Kansas City",
        "Year": "1990",
        "lat": "39.10296",
        "Document": "docs/earl-fleer.pdf"
    },
    {
        "TroopNumber": "Troop 9018",
        "lng": "-72.0790717",
        "State": "Connecticut",
        "NameOrID": "Fleming, Henry W.",
        "City": "Groton",
        "Year": "1988",
        "lat": "41.3497456",
        "Document": "docs/henry-w-fleming.pdf"
    },
    {
        "TroopNumber": "Troop 364",
        "lng": "-85.766404",
        "State": "Kentucky",
        "NameOrID": "Fleming, Timothy L.",
        "City": "Louisville",
        "Year": "1978",
        "lat": "38.25486",
        "Document": "docs/timothy-l-fleming.pdf"
    },
    {
        "TroopNumber": "Pack 67",
        "lng": "-123.088544",
        "State": "Oregon",
        "NameOrID": "Flemming, Jerrold S.",
        "City": "Eugene",
        "Year": "1981",
        "lat": "44.04992",
        "Document": " docs/jerrold-s-flemming.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-74.229229",
        "State": "New Jersey",
        "NameOrID": "Flemming, Gregory",
        "City": "Cedar Grove",
        "Year": "1986",
        "lat": "40.850775",
        "Document": "docs/gregory-flemming.pdf"
    },
    {
        "TroopNumber": "Explorer 2079",
        "lng": "-88.093589",
        "State": "Illinois",
        "NameOrID": "Flosi, Gregory J.",
        "City": "Round Lake",
        "Year": "1988",
        "lat": "42.356456",
        "Document": "docs/gregory-j-flosi.pdf"
    },
    {
        "TroopNumber": "Troop 83",
        "lng": "-88.026854",
        "State": "Illinois",
        "NameOrID": "Flosi, Gregory J.",
        "City": "Lindenhurst",
        "Year": "1988",
        "lat": "42.407901",
        "Document": "docs/gregory-j-flosi.pdf"
    },
    {
        "TroopNumber": "Troop 371",
        "lng": "-70.444494",
        "State": "Maine",
        "NameOrID": "Fogarty, Bernard N.",
        "City": "Saco",
        "Year": "1986",
        "lat": "43.49925",
        "Document": "docs/bernard-n-fogarty.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Folley, Ernest John",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/ernest-john-folley.pdf"
    },
    {
        "TroopNumber": "District 8690",
        "lng": "-75.743434",
        "State": "Pennsylvania",
        "NameOrID": "Fonzo, Gerald A.",
        "City": "Avoca",
        "Year": "1991",
        "lat": "41.335476",
        "Document": "docs/gerald-a-fonzo.pdf"
    },
    {
        "TroopNumber": "Troop 303",
        "lng": "-75.818403",
        "State": "Pennsylvania",
        "NameOrID": "Fonzo, Gerald A.",
        "City": "Exeter",
        "Year": "1991",
        "lat": "41.320785",
        "Document": "docs/gerald-a-fonzo.pdf"
    },
    {
        "TroopNumber": "Troop 592",
        "lng": "-97.007199",
        "State": "Texas",
        "NameOrID": "Ford, William H.",
        "City": "Grand Prairie",
        "Year": "1989",
        "lat": "32.7477",
        "Document": "docs/william-h-ford.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Forsythe, James A.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/james-a-forsythe.pdf"
    },
    {
        "TroopNumber": "Troop 59",
        "lng": "-95.993334",
        "State": "Oklahoma",
        "NameOrID": "Foster, Roland Daniel",
        "City": "Tulsa",
        "Year": "1986",
        "lat": "36.149745",
        "Document": "docs/roland-daniel-foster.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "New Mexico",
        "NameOrID": "Foster, James B.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/james-b-foster.pdf"
    },
    {
        "TroopNumber": "Troop 57",
        "lng": "-99.739771",
        "State": "Texas",
        "NameOrID": "Fox, Edgar L.",
        "City": "Quanah",
        "Year": "1990",
        "lat": "34.297215",
        "Document": "docs/edgar-l-fox.pdf"
    },
    {
        "TroopNumber": "Troop 99",
        "lng": "-124.139034",
        "State": "Oregon",
        "NameOrID": "Francies, David John",
        "City": "Myrtle Point",
        "Year": "1983",
        "lat": "43.06435",
        "Document": " docs/david-john-francies.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-83.047924",
        "State": "Michigan",
        "NameOrID": "Francis, Jimmie Leroy",
        "City": "Detroit",
        "Year": "1988",
        "lat": "42.331685",
        "Document": "docs/jimmie-leroy-francis.pdf"
    },
    {
        "TroopNumber": "Troop 48",
        "lng": "-88.920984",
        "State": "Illinois",
        "NameOrID": "Frankenfield, Robert C.",
        "City": "West Frankfort",
        "Year": "1989",
        "lat": "37.897995",
        "Document": "docs/robert-c-frankenfield.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-89.504093",
        "State": "Illinois",
        "NameOrID": "Frankenfield, Robert C.",
        "City": "Little Grassy Lake",
        "Year": "1989",
        "lat": "39.739256",
        "Document": "docs/robert-c-frankenfield.pdf"
    },
    {
        "TroopNumber": "Troop 70",
        "lng": "-74.304002",
        "State": "New Jersey",
        "NameOrID": "Franklin, Roy A.",
        "City": "Lincoln Park",
        "Year": "1991",
        "lat": "40.920946",
        "Document": "docs/roy-a-franklin.pdf"
    },
    {
        "TroopNumber": "Troop 10",
        "lng": "-98.678497",
        "State": "Texas",
        "NameOrID": "Frantz, Gene",
        "City": "Ranger",
        "Year": "1990",
        "lat": "32.470696",
        "Document": "docs/gene-frantz.pdf"
    },
    {
        "TroopNumber": "Pack 301",
        "lng": "",
        "State": "",
        "NameOrID": "Frazier, Jerry B.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/jerry-b-frazier.pdf"
    },
    {
        "TroopNumber": "Pack 6",
        "lng": "-105.080499",
        "State": "Colorado",
        "NameOrID": "Freeman, Steve",
        "City": "Wheat Ridge",
        "Year": "1979",
        "lat": "39.75845",
        "Document": "docs/steve-freeman.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Freitas, John J.",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": " docs/john-j-freitas.pdf"
    },
    {
        "TroopNumber": "Troop 161",
        "lng": "-77.964564",
        "State": "West Virginia",
        "NameOrID": "French, John Eric",
        "City": "Martinsburg",
        "Year": "1985",
        "lat": "39.45637",
        "Document": "docs/john-eric-french.pdf"
    },
    {
        "TroopNumber": "Troop 58",
        "lng": "-77.964564",
        "State": "West Virginia",
        "NameOrID": "French, John Eric",
        "City": "Martinsburg",
        "Year": "1985",
        "lat": "39.45637",
        "Document": "docs/john-eric-french.pdf"
    },
    {
        "TroopNumber": "District 8644",
        "lng": "-73.829959",
        "State": "New York",
        "NameOrID": "Fried, Samuel L.",
        "City": "Queens",
        "Year": "1988",
        "lat": "40.713985",
        "Document": "docs/samuel-l-fried.pdf"
    },
    {
        "TroopNumber": "Troop 216",
        "lng": "-73.715464",
        "State": "New York",
        "NameOrID": "Fried, Samuel L.",
        "City": "Bellerose",
        "Year": "1988",
        "lat": "40.723025",
        "Document": "docs/samuel-l-fried.pdf"
    },
    {
        "TroopNumber": "Pack 355",
        "lng": "-116.1193327",
        "State": "Idaho",
        "NameOrID": "Frisbie, Vincent Paul",
        "City": "Kellogg",
        "Year": "1987",
        "lat": "47.5382565",
        "Document": "docs/vincent-paul-frisbie.pdf"
    },
    {
        "TroopNumber": "Troop 21",
        "lng": "",
        "State": "Idaho",
        "NameOrID": "Frisbie, Vincent Paul",
        "City": "Kellogg",
        "Year": "1987",
        "lat": "",
        "Document": "docs/vincent-paul-frisbie.pdf"
    },
    {
        "TroopNumber": "Troop 16",
        "lng": "-73.911669",
        "State": "New York",
        "NameOrID": "Fross, James A.",
        "City": "Tivoli",
        "Year": "1980",
        "lat": "42.059175",
        "Document": " docs/james-a-fross.pdf"
    },
    {
        "TroopNumber": "Troop 58",
        "lng": "-73.876688",
        "State": "New York",
        "NameOrID": "Fross, James A.",
        "City": "Red Hook",
        "Year": "1980",
        "lat": "41.993895",
        "Document": " docs/james-a-fross.pdf"
    },
    {
        "TroopNumber": "Troop 135",
        "lng": "-124.072789",
        "State": "Oregon",
        "NameOrID": "Frost, Ronald R.",
        "City": "Powers",
        "Year": "1972",
        "lat": "42.8805",
        "Document": " docs/ronald-r-frost.pdf"
    },
    {
        "TroopNumber": "Pack 135",
        "lng": "-124.072789",
        "State": "Oregon",
        "NameOrID": "Frost, Ronald R.",
        "City": "Powers",
        "Year": "1972",
        "lat": "42.8805",
        "Document": " docs/ronald-r-frost.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 241",
        "lng": "-82.459269",
        "State": "Florida",
        "NameOrID": "Fuentes, Roland R.",
        "City": "Tampa",
        "Year": "1989",
        "lat": "27.94653",
        "Document": "docs/roland-r-fuentes.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 210",
        "lng": "-82.459269",
        "State": "Florida",
        "NameOrID": "Fuentes, Roland R.",
        "City": "Tampa",
        "Year": "1989",
        "lat": "27.94653",
        "Document": "docs/roland-r-fuentes.pdf"
    },
    {
        "TroopNumber": "Troop 294",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Fugate, Charles F.",
        "City": "Chicago",
        "Year": "1977",
        "lat": "41.88415",
        "Document": " docs/charles-f-fugate.pdf"
    },
    {
        "TroopNumber": "Troop 206",
        "lng": "-79.794704",
        "State": "North Carolina",
        "NameOrID": "Fultz, David M.",
        "City": "Greensboro",
        "Year": "1987",
        "lat": "36.068994",
        "Document": "docs/david-m-fultz.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-79.710989",
        "State": "North Carolina",
        "NameOrID": "Fultz, David M.",
        "City": "Browns Summit",
        "Year": "1987",
        "lat": "36.212917",
        "Document": "docs/david-m-fultz.pdf"
    },
    {
        "TroopNumber": "Troop 432",
        "lng": "-120.577169",
        "State": "California",
        "NameOrID": "Furness, Jayne A.",
        "City": "Arroyo Grande",
        "Year": "1976",
        "lat": "35.124195",
        "Document": " docs/jayne-a-furness.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-74.174184",
        "State": "New Jersey",
        "NameOrID": "Galdon, Richard M.",
        "City": "Newark",
        "Year": "1987",
        "lat": "40.73197",
        "Document": "docs/richard-m-galdon.pdf"
    },
    {
        "TroopNumber": "Troop 35",
        "lng": "-74.174184",
        "State": "New Jersey",
        "NameOrID": "Galdon, Richard M.",
        "City": "Newark",
        "Year": "1987",
        "lat": "40.73197",
        "Document": "docs/richard-m-galdon.pdf"
    },
    {
        "TroopNumber": "Troop 126",
        "lng": "-102.075694",
        "State": "Texas",
        "NameOrID": "Gallagher, Joseph J.",
        "City": "Midland",
        "Year": "1985",
        "lat": "32.000105",
        "Document": "docs/joseph-j-gallagher.pdf"
    },
    {
        "TroopNumber": "Troop 94",
        "lng": "-102.075694",
        "State": "Texas",
        "NameOrID": "Gallagher, Joseph J.",
        "City": "Midland",
        "Year": "1985",
        "lat": "32.000105",
        "Document": "docs/joseph-j-gallagher.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-79.415435",
        "State": "North Carolina",
        "NameOrID": "Gallick, Mark",
        "City": "Carthage",
        "Year": "1989",
        "lat": "35.344684",
        "Document": "docs/mark-gallick.pdf"
    },
    {
        "TroopNumber": "Troop 437",
        "lng": "-76.059204",
        "State": "Virginia",
        "NameOrID": "Gardner, Keith M.",
        "City": "Virginia Beach",
        "Year": "1984",
        "lat": "36.75502",
        "Document": "docs/keith-m-gardner.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-70.938819",
        "State": "Massachusetts",
        "NameOrID": "Gardner, Richard E.",
        "City": "Weymouth",
        "Year": "1988",
        "lat": "42.22217",
        "Document": "docs/richard-e-gardner.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Gardner, Mark Richard",
        "City": "",
        "Year": "1984",
        "lat": "",
        "Document": " docs/mark-richard-gardner.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-78.263631",
        "State": "Virginia",
        "NameOrID": "Gardner, Edward M.",
        "City": "Palmyra",
        "Year": "1987",
        "lat": "37.861215",
        "Document": "docs/edward-m-gardner.pdf"
    },
    {
        "TroopNumber": "Explorer ",
        "lng": "-70.762629",
        "State": "Massachusetts",
        "NameOrID": "Gardner, Edward M.",
        "City": "Marion",
        "Year": "1987",
        "lat": "41.70309",
        "Document": "docs/edward-m-gardner.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Garon, Peter P.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/peter-p-garon.pdf"
    },
    {
        "TroopNumber": "Troop 211",
        "lng": "-90.985464",
        "State": "Louisiana",
        "NameOrID": "Garrison, Dennis",
        "City": "Donaldsville",
        "Year": "1986",
        "lat": "30.1056",
        "Document": "docs/dennis-garrison.pdf"
    },
    {
        "TroopNumber": "Pack 3207",
        "lng": "-119.180439",
        "State": "California",
        "NameOrID": "Garver, Allen James",
        "City": "Oxnard",
        "Year": "1984",
        "lat": "34.20034",
        "Document": " docs/allen-james-garver.pdf"
    },
    {
        "TroopNumber": "Troop 239",
        "lng": "-119.180439",
        "State": "California",
        "NameOrID": "Garver, Allen James",
        "City": "Oxnard",
        "Year": "1984",
        "lat": "34.20034",
        "Document": " docs/allen-james-garver.pdf"
    },
    {
        "TroopNumber": "Troop 526",
        "lng": "-100.678913",
        "State": "North Dakota",
        "NameOrID": "Garwood, Bert R.",
        "City": "Souris",
        "Year": "1973",
        "lat": "48.907776",
        "Document": " docs/bert-r-garwood.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-81.493799",
        "State": "Florida",
        "NameOrID": "Gates, James",
        "City": "Paisley",
        "Year": "1989",
        "lat": "29.01702",
        "Document": "docs/james-gates.pdf"
    },
    {
        "TroopNumber": "Troop 369",
        "lng": "-80.685989",
        "State": "Florida",
        "NameOrID": "Gates, James",
        "City": "Merritt Island",
        "Year": "1989",
        "lat": "28.357095",
        "Document": "docs/james-gates.pdf"
    },
    {
        "TroopNumber": "Troop 664",
        "lng": "-122.435309",
        "State": "Oregon",
        "NameOrID": "Gates, Kenneth",
        "City": "Gresham",
        "Year": "1980",
        "lat": "45.50599",
        "Document": " docs/kenneth-gates.pdf"
    },
    {
        "TroopNumber": "Troop 125",
        "lng": "-118.038854",
        "State": "California",
        "NameOrID": "Gates, Roger",
        "City": "Arcadia",
        "Year": "1979",
        "lat": "34.136165",
        "Document": "docs/roger-gates.pdf"
    },
    {
        "TroopNumber": "Troop 408",
        "lng": "-104.992259",
        "State": "Colorado",
        "NameOrID": "Gauthe, Richard James",
        "City": "Westminster",
        "Year": "1970",
        "lat": "39.74001",
        "Document": " docs/richard-james-gauthe.pdf"
    },
    {
        "TroopNumber": "Troop 112",
        "lng": "-93.317499",
        "State": "Colorado",
        "NameOrID": "Gauthe, Richard James",
        "City": "Denver",
        "Year": "1970",
        "lat": "42.022525",
        "Document": " docs/richard-james-gauthe.pdf"
    },
    {
        "TroopNumber": "155",
        "lng": "-85.859124",
        "State": "Kentucky",
        "NameOrID": "Gehly, Theodore E.",
        "City": "Elizabethtown",
        "Year": "1991",
        "lat": "37.693675",
        "Document": "docs/theodore-e-gehly.pdf"
    },
    {
        "TroopNumber": "Troop 25",
        "lng": "-74.64528",
        "State": "New Jersey",
        "NameOrID": "Geiger, Charles Paul",
        "City": "Egg Harbor",
        "Year": "1987",
        "lat": "39.53078",
        "Document": "docs/charles-paul-geiger.pdf"
    },
    {
        "TroopNumber": "Troop 25",
        "lng": "-76.301269",
        "State": "Pennsylvania",
        "NameOrID": "Geiter, Dennis C.",
        "City": "Lancaster",
        "Year": "1986",
        "lat": "40.038045",
        "Document": "docs/dennis-c-geiter.pdf"
    },
    {
        "TroopNumber": "Pack 16",
        "lng": "-76.301269",
        "State": "Pennsylvania",
        "NameOrID": "Geiter, Dennis C.",
        "City": "Lancaster",
        "Year": "1986",
        "lat": "40.038045",
        "Document": "docs/dennis-c-geiter.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "Florida",
        "NameOrID": "Geller, Stewart Ian",
        "City": "",
        "Year": "1983",
        "lat": "",
        "Document": " docs/stewart-ian-geller.pdf"
    },
    {
        "TroopNumber": "Troop 171",
        "lng": "-77.001414",
        "State": "Pennsylvania",
        "NameOrID": "George, Owen Ronald",
        "City": "Williamsport",
        "Year": "1974",
        "lat": "41.240945",
        "Document": " docs/owen-ronald-george.pdf"
    },
    {
        "TroopNumber": "Explorer 59",
        "lng": "-78.0647387",
        "State": "New York",
        "NameOrID": "Germeo, Gary R.",
        "City": "Clarendon",
        "Year": "1985",
        "lat": "43.193393",
        "Document": "docs/gary-r-germeo.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-83.748479",
        "State": "Michigan",
        "NameOrID": "Gesell, Robert William",
        "City": "Ann Arbor",
        "Year": "1971",
        "lat": "42.281875",
        "Document": " docs/robert-william-gesell.pdf"
    },
    {
        "TroopNumber": "Troop 74",
        "lng": "-75.961913",
        "State": "New York",
        "NameOrID": "Getman, Douglas G.",
        "City": "Lefargeville",
        "Year": "1990",
        "lat": "44.19682",
        "Document": "docs/douglas-g-getman.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "Pennsylvania",
        "NameOrID": "Giammarino, Frank Michael",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/frank-michael-giammarino.pdf"
    },
    {
        "TroopNumber": "Pack 3928",
        "lng": "-81.522849",
        "State": "Ohio",
        "NameOrID": "Gibbens, Joseph E.",
        "City": "Navarre",
        "Year": "1987",
        "lat": "40.7244",
        "Document": "docs/joseph-e-gibbens.pdf"
    },
    {
        "TroopNumber": "Troop 410",
        "lng": "-82.799754",
        "State": "Florida",
        "NameOrID": "Gibson, Joe N.",
        "City": "Clearwater",
        "Year": "1981",
        "lat": "27.965585",
        "Document": " docs/joe-n-gibson.pdf"
    },
    {
        "TroopNumber": "Pack 410",
        "lng": "-82.799754",
        "State": "Florida",
        "NameOrID": "Gibson, Joe N.",
        "City": "Clearwater",
        "Year": "1981",
        "lat": "27.965585",
        "Document": " docs/joe-n-gibson.pdf"
    },
    {
        "TroopNumber": "Troop 385",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Gibson, Gerald W.",
        "City": "Philadelphia",
        "Year": "1973",
        "lat": "39.95228",
        "Document": " docs/gerald-w-gibson.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-78.398904",
        "State": "Pennsylvania",
        "NameOrID": "Gibson, Donald Eugene",
        "City": "Altoona",
        "Year": "1982",
        "lat": "40.50719",
        "Document": " docs/donald-eugene-gibson.pdf"
    },
    {
        "TroopNumber": "Pack 20",
        "lng": "-86.079315",
        "State": "Alabama",
        "NameOrID": "Giddens, Charles",
        "City": "Scottsboro",
        "Year": "1990",
        "lat": "34.681391",
        "Document": "docs/charles-giddens.pdf"
    },
    {
        "TroopNumber": "Pack 263",
        "lng": "-86.079315",
        "State": "Alabama",
        "NameOrID": "Giddens, Charles",
        "City": "Scottsboro",
        "Year": "1990",
        "lat": "34.681391",
        "Document": "docs/charles-giddens.pdf"
    },
    {
        "TroopNumber": "Troop 593",
        "lng": "-93.093326",
        "State": "Minnesota",
        "NameOrID": "Gilbert, Robert H.",
        "City": "St. Paul",
        "Year": "1984",
        "lat": "44.943829",
        "Document": " docs/robert-h-gilbert.pdf"
    },
    {
        "TroopNumber": "Troop 1231",
        "lng": "-71.628593",
        "State": "Massachusetts",
        "NameOrID": "Gill, Robert W.",
        "City": "Berlin",
        "Year": "1984",
        "lat": "42.361112",
        "Document": " docs/robert-w-gill.pdf"
    },
    {
        "TroopNumber": "Troop 240",
        "lng": "-89.587764",
        "State": "Illinois",
        "NameOrID": "Gilman, David L.",
        "City": "Peoria",
        "Year": "1974",
        "lat": "40.69214",
        "Document": " docs/david-l-gilman.pdf"
    },
    {
        "TroopNumber": "Troop 138",
        "lng": "-89.587764",
        "State": "Illinois",
        "NameOrID": "Gilman, David L.",
        "City": "Peoria",
        "Year": "1974",
        "lat": "40.69214",
        "Document": " docs/david-l-gilman.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Gilpin, Joel",
        "City": "",
        "Year": "1978",
        "lat": "",
        "Document": "docs/joel-gilpin.pdf"
    },
    {
        "TroopNumber": "Pack 68",
        "lng": "-74.803974",
        "State": "New Jersey",
        "NameOrID": "Glazewski, Vincent W.",
        "City": "Hammonton",
        "Year": "1986",
        "lat": "39.63564",
        "Document": "docs/vincent-w-glazewski.pdf"
    },
    {
        "TroopNumber": "Troop 354",
        "lng": "-85.785669",
        "State": "Michigan",
        "NameOrID": "Gleason, Robert Walter",
        "City": "Jenison",
        "Year": "1991",
        "lat": "42.90444",
        "Document": "docs/robert-walter-gleason.pdf"
    },
    {
        "TroopNumber": "Pack 4151",
        "lng": "-86.363908",
        "State": "Michigan",
        "NameOrID": "Glover, Ernest",
        "City": "Shelby",
        "Year": "1984",
        "lat": "43.608521",
        "Document": " docs/ernest-glover.pdf"
    },
    {
        "TroopNumber": "Troop 159",
        "lng": "-82.954969",
        "State": "Ohio",
        "NameOrID": "Gloyd, Ralph R.",
        "City": "Ashville",
        "Year": "1990",
        "lat": "39.713064",
        "Document": "docs/ralph-r-gloyd.pdf"
    },
    {
        "TroopNumber": "Troop 170",
        "lng": "-81.377389",
        "State": "Florida",
        "NameOrID": "Godin, Walter Lee",
        "City": "Orlando",
        "Year": "1987",
        "lat": "28.538235",
        "Document": "docs/walter-lee-godin.pdf"
    },
    {
        "TroopNumber": "Troop 352",
        "lng": "-85.693289",
        "State": "Michigan",
        "NameOrID": "Goei, Howard",
        "City": "Wyoming",
        "Year": "1991",
        "lat": "42.9134",
        "Document": "docs/howard-goei.pdf"
    },
    {
        "TroopNumber": "Troop 253",
        "lng": "-66.581348",
        "State": "Puerto Rico",
        "NameOrID": "Goldstraw, John Edward",
        "City": "Raney AFB",
        "Year": "1972",
        "lat": "18.2016",
        "Document": " docs/john-edward-goldstraw.pdf"
    },
    {
        "TroopNumber": "Troop 99",
        "lng": "-74.592003",
        "State": "New Jersey",
        "NameOrID": "Goldstraw, John Edward",
        "City": "McGuire AFB",
        "Year": "1972",
        "lat": "40.0168",
        "Document": " docs/john-edward-goldstraw.pdf"
    },
    {
        "TroopNumber": "3260",
        "lng": "-84.700769",
        "State": "Michigan",
        "NameOrID": "Gowing, Donald A.",
        "City": "Wacousta",
        "Year": "1985",
        "lat": "42.84958",
        "Document": "docs/donald-a-gowing.pdf"
    },
    {
        "TroopNumber": "Troop 350",
        "lng": "-120.662519",
        "State": "California",
        "NameOrID": "Gomez, Emilio A.",
        "City": "San Luis Obispo",
        "Year": "1974",
        "lat": "35.28552",
        "Document": " docs/emilio-a-gomez.pdf"
    },
    {
        "TroopNumber": "60",
        "lng": "-84.700769",
        "State": "Michigan",
        "NameOrID": "Gowing, Donald A.",
        "City": "Wacousta",
        "Year": "1985",
        "lat": "42.84958",
        "Document": "docs/donald-a-gowing.pdf"
    },
    {
        "TroopNumber": "Troop 90",
        "lng": "-82.073995",
        "State": "Georgia",
        "NameOrID": "Good, Kenneth R.",
        "City": "Martinez",
        "Year": "1991",
        "lat": "33.516391",
        "Document": "docs/kenneth-r-good.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Gowing, Delmar B.",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": "docs/delmar-b-gowing.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-74.209909",
        "State": "New Jersey",
        "NameOrID": "Gooding, Alexander John",
        "City": "East Orange",
        "Year": "1972",
        "lat": "40.761",
        "Document": " docs/alexander-john-gooding.pdf"
    },
    {
        "TroopNumber": "Troop 567",
        "lng": "-78.686394",
        "State": "New York",
        "NameOrID": "Grace, John E.",
        "City": "Depew",
        "Year": "1989",
        "lat": "42.904405",
        "Document": "docs/john-e-grace.pdf"
    },
    {
        "TroopNumber": "Troop 37",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "Grady, Daniel T.",
        "City": "Worcester",
        "Year": "1988",
        "lat": "42.263415",
        "Document": "docs/daniel-t-grady.pdf"
    },
    {
        "TroopNumber": "Explorer 401",
        "lng": "-93.736684",
        "State": "Texas",
        "NameOrID": "Goodman, James E.",
        "City": "Orange",
        "Year": "1989",
        "lat": "30.0931",
        "Document": "docs/james-e-goodman.pdf"
    },
    {
        "TroopNumber": "Troop 60",
        "lng": "-117.025827",
        "State": "California",
        "NameOrID": "Graham, John",
        "City": "Barstow",
        "Year": "1986",
        "lat": "34.892965",
        "Document": "docs/john-graham.pdf"
    },
    {
        "TroopNumber": "Troop 55",
        "lng": "-74.167859",
        "State": "New York",
        "NameOrID": "Graham, Charles N.",
        "City": "Northville",
        "Year": "1975",
        "lat": "43.226925",
        "Document": " docs/charles-n-graham.pdf"
    },
    {
        "TroopNumber": "Troop 323",
        "lng": "-90.150674",
        "State": "Illinois",
        "NameOrID": "Goodman, Lester L.",
        "City": "Waterloo",
        "Year": "1979",
        "lat": "38.333915",
        "Document": "docs/lester-l-goodman-sr.pdf"
    },
    {
        "TroopNumber": "Pack 921",
        "lng": "-119.749194",
        "State": "Nevada",
        "NameOrID": "Grassini, Randee E.",
        "City": "Sparks",
        "Year": "1977",
        "lat": "39.541",
        "Document": " docs/randee-e-grassini.pdf"
    },
    {
        "TroopNumber": "Troop 161",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Goodwin, James",
        "City": "Indianapolis",
        "Year": "1989",
        "lat": "39.76691",
        "Document": "docs/james-goodwin.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Graybill, Wayne",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/wayne-graybill.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Gregoire, James F.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/james-f-gregoire.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-86.534244",
        "State": "Indiana",
        "NameOrID": "Goodwin, James",
        "City": "Bloomington",
        "Year": "1989",
        "lat": "39.16659",
        "Document": "docs/james-goodwin.pdf"
    },
    {
        "TroopNumber": "Troop 43",
        "lng": "-122.064171",
        "State": "Washington",
        "NameOrID": "Grewe, Charles",
        "City": "Lake Stevens",
        "Year": "1988",
        "lat": "48.014386",
        "Document": "docs/charles-grewe.pdf"
    },
    {
        "TroopNumber": "Troop 60",
        "lng": "-96.602149",
        "State": "Texas",
        "NameOrID": "Griffeth, Richard J.",
        "City": "Mesquite",
        "Year": "1991",
        "lat": "32.783455",
        "Document": "docs/richard-j-griffeth.pdf"
    },
    {
        "TroopNumber": "Troop 436",
        "lng": "-81.020636",
        "State": "Florida",
        "NameOrID": "Gorski, Roy D.",
        "City": "Port Orange",
        "Year": "1988",
        "lat": "29.126141",
        "Document": "docs/roy-d-gorski.pdf"
    },
    {
        "TroopNumber": "Pack 855",
        "lng": "-96.602149",
        "State": "Texas",
        "NameOrID": "Griffeth, Richard J.",
        "City": "Mesquite",
        "Year": "1991",
        "lat": "32.783455",
        "Document": "docs/richard-j-griffeth.pdf"
    },
    {
        "TroopNumber": "Troop 549",
        "lng": "-101.845009",
        "State": "Texas",
        "NameOrID": "Goulet, Dana Edward",
        "City": "Lubbock",
        "Year": "1988",
        "lat": "33.58451",
        "Document": "docs/dana-edward-goulet.pdf"
    },
    {
        "TroopNumber": "Troop 727",
        "lng": "-76.506338",
        "State": "Maryland",
        "NameOrID": "Griffin, Eric",
        "City": "Arnold",
        "Year": "1974",
        "lat": "39.031878",
        "Document": " docs/eric-griffin.pdf"
    },
    {
        "TroopNumber": "Troop 82",
        "lng": "-80.753412",
        "State": "West Virginia",
        "NameOrID": "Gower, John F.",
        "City": "Glendale",
        "Year": "1972",
        "lat": "39.947872",
        "Document": " docs/john-f-gower.pdf"
    },
    {
        "TroopNumber": "Troop 158",
        "lng": "-91.599904",
        "State": "Iowa",
        "NameOrID": "Griffith, Robert A.",
        "City": "Marion",
        "Year": "1991",
        "lat": "42.03329",
        "Document": "docs/robert-a-griffith.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-78.962643",
        "State": "Panama",
        "NameOrID": "Grimsley, Ralph John",
        "City": "Balboa",
        "Year": "1972",
        "lat": "8.4324",
        "Document": " docs/ralph-john-grimsley.pdf"
    },
    {
        "TroopNumber": "3260",
        "lng": "-84.700769",
        "State": "Michigan",
        "NameOrID": "Gowing, Jeffrey Donald",
        "City": "Wacousta",
        "Year": "1985",
        "lat": "42.84958",
        "Document": "docs/jeffrey-donald-gowing.pdf"
    },
    {
        "TroopNumber": "Pack 137",
        "lng": "-74.184672",
        "State": "New Jersey",
        "NameOrID": "Gromack, Gary",
        "City": "Hazlet",
        "Year": "1989",
        "lat": "40.421982",
        "Document": "docs/gary-gromack.pdf"
    },
    {
        "TroopNumber": "60",
        "lng": "-84.700769",
        "State": "Michigan",
        "NameOrID": "Gowing, Jeffrey Donald",
        "City": "Wacousta",
        "Year": "1985",
        "lat": "42.84958",
        "Document": "docs/jeffrey-donald-gowing.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Grooms, Morris W.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/morris-w-grooms.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-89.372169",
        "State": "Illinois",
        "NameOrID": "Grooms, Morris W.",
        "City": "Carlyle",
        "Year": "1987",
        "lat": "38.610165",
        "Document": "docs/morris-w-grooms.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-83.396899",
        "State": "Georgia",
        "NameOrID": "Gross, Peter A.",
        "City": "Clayton",
        "Year": "1988",
        "lat": "34.87862",
        "Document": "docs/peter-a-gross.pdf"
    },
    {
        "TroopNumber": "Troop 465",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Haber, Robert M.",
        "City": "Rockford",
        "Year": "1983",
        "lat": "42.2669",
        "Document": " docs/robert-m-haber.pdf"
    },
    {
        "TroopNumber": "Troop 22",
        "lng": "-118.492289",
        "State": "California",
        "NameOrID": "Grossman, Alan H.",
        "City": "Santa Monica",
        "Year": "1977",
        "lat": "34.011565",
        "Document": " docs/alan-h-grossman.pdf"
    },
    {
        "TroopNumber": "Troop 222",
        "lng": "-118.492289",
        "State": "California",
        "NameOrID": "Grossman, Alan H.",
        "City": "Santa Monica",
        "Year": "1977",
        "lat": "34.011565",
        "Document": " docs/alan-h-grossman.pdf"
    },
    {
        "TroopNumber": "Pack 465",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Haber, Robert M.",
        "City": "Rockford",
        "Year": "1983",
        "lat": "42.2669",
        "Document": " docs/robert-m-haber.pdf"
    },
    {
        "TroopNumber": "Troop 362",
        "lng": "-84.964249",
        "State": "Michigan",
        "NameOrID": "Groth, Peter J.",
        "City": "Marshall",
        "Year": "1990",
        "lat": "42.27492",
        "Document": "docs/peter-j-groth.pdf"
    },
    {
        "TroopNumber": "Troop 408",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Grounds, David P.",
        "City": "Dallas",
        "Year": "1988",
        "lat": "32.778155",
        "Document": "docs/david-p-grounds.pdf"
    },
    {
        "TroopNumber": "Troop 999",
        "lng": "-111.695419",
        "State": "Utah",
        "NameOrID": "Hacking, Eric",
        "City": "Orem",
        "Year": "1984",
        "lat": "40.29806",
        "Document": " docs/eric-hacking.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Grubbs, Robert Monroe",
        "City": "Portland",
        "Year": "1987",
        "lat": "45.511795",
        "Document": "docs/robert-monroe-grubbs.pdf"
    },
    {
        "TroopNumber": "Troop 39",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Grubbs, Robert Monroe",
        "City": "Portland",
        "Year": "1987",
        "lat": "45.511795",
        "Document": "docs/robert-monroe-grubbs.pdf"
    },
    {
        "TroopNumber": "Explorer 118",
        "lng": "-116.569624",
        "State": "Idaho",
        "NameOrID": "Hagen, Bill J.",
        "City": "Nampa",
        "Year": "1976",
        "lat": "43.582635",
        "Document": " docs/bill-j-hagen.pdf"
    },
    {
        "TroopNumber": "Troop 30",
        "lng": "-86.811504",
        "State": "Alabama",
        "NameOrID": "Guerra, Vincent M.",
        "City": "Birmingham",
        "Year": "1989",
        "lat": "33.520295",
        "Document": "docs/vincent-m-guerra.pdf"
    },
    {
        "TroopNumber": "Troop 112",
        "lng": "-122.717029",
        "State": "Oregon",
        "NameOrID": "Gunter, Gerald Wayne",
        "City": "Ashland",
        "Year": "1985",
        "lat": "42.19967",
        "Document": "docs/gerald-wayne-gunter.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 5011",
        "lng": "-88.416564",
        "State": "Wisconsin",
        "NameOrID": "Hahn, Larry R.",
        "City": "Appleton",
        "Year": "1988",
        "lat": "44.26178",
        "Document": "docs/larry-r-hahn.pdf"
    },
    {
        "TroopNumber": "Troop 97",
        "lng": "-90.049259",
        "State": "Tennessee",
        "NameOrID": "Gunter, Riley Wayne",
        "City": "Memphis",
        "Year": "1983",
        "lat": "35.149766",
        "Document": " docs/riley-wayne-gunter.pdf"
    },
    {
        "TroopNumber": "Troop 141",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Hale, Gwenn",
        "City": "Chicago",
        "Year": "1984",
        "lat": "41.88415",
        "Document": " docs/gwenn-hale.pdf"
    },
    {
        "TroopNumber": "Troop 750",
        "lng": "-72.757313",
        "State": "Vermont",
        "NameOrID": "Guptil, Mark T.",
        "City": "Waterbury",
        "Year": "1986",
        "lat": "44.338718",
        "Document": "docs/mark-t-guptil.pdf"
    },
    {
        "TroopNumber": "Troop 714",
        "lng": "-122.204986",
        "State": "Washington",
        "NameOrID": "Guzzone, Byron John",
        "City": "Renton",
        "Year": "1986",
        "lat": "47.475237",
        "Document": "docs/byron-john-guzzone.pdf"
    },
    {
        "TroopNumber": "Troop 380",
        "lng": "-76.992559",
        "State": "Maryland",
        "NameOrID": "Hall, Kenneth E.",
        "City": "Westminster",
        "Year": "1976",
        "lat": "39.574485",
        "Document": " docs/kenneth-e-hall.pdf"
    },
    {
        "TroopNumber": "Troop 381",
        "lng": "-76.992559",
        "State": "Maryland",
        "NameOrID": "Hall, Kenneth E.",
        "City": "Westminster",
        "Year": "1976",
        "lat": "39.574485",
        "Document": " docs/kenneth-e-hall.pdf"
    },
    {
        "TroopNumber": "Troop 137",
        "lng": "-90.077749",
        "State": "Louisiana",
        "NameOrID": "Halvorsen, Richard Stanley",
        "City": "New Orleans",
        "Year": "1976",
        "lat": "29.9537",
        "Document": " docs/richard-stanley-halvorsen.pdf"
    },
    {
        "TroopNumber": "Pack 441",
        "lng": "-111.937479",
        "State": "Utah",
        "NameOrID": "Hamblin, Leroy B.",
        "City": "Kaysville",
        "Year": "1990",
        "lat": "41.034855",
        "Document": "docs/leroy-b-hamblin.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hampton, Raymond A.",
        "City": "",
        "Year": "1973",
        "lat": "",
        "Document": " docs/raymond-a-hampton.pdf"
    },
    {
        "TroopNumber": "Troop 403",
        "lng": "-77.433654",
        "State": "Virginia",
        "NameOrID": "Hall, William Henry",
        "City": "Richmond",
        "Year": "1987",
        "lat": "37.5407",
        "Document": "docs/william-henry-hall.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-122.285219",
        "State": "California",
        "NameOrID": "Hankin, Harvey S.",
        "City": "Napa",
        "Year": "1986",
        "lat": "38.29886",
        "Document": "docs/harvey-s-hankin.pdf"
    },
    {
        "TroopNumber": "Explorer 221",
        "lng": "-83.002984",
        "State": "Ohio",
        "NameOrID": "Hanrahan, Michael",
        "City": "Columbus",
        "Year": "1986",
        "lat": "39.96196",
        "Document": "docs/michael-hanrahan.pdf"
    },
    {
        "TroopNumber": "Troop 376",
        "lng": "-104.992259",
        "State": "Colorado",
        "NameOrID": "Hall, Gerald J.",
        "City": "Denver",
        "Year": "1983",
        "lat": "39.74001",
        "Document": " docs/gerald-j-hall.pdf"
    },
    {
        "TroopNumber": "519",
        "lng": "-86.270889",
        "State": "Indiana",
        "NameOrID": "Hanson, James A.",
        "City": "Russiaville",
        "Year": "1990",
        "lat": "40.417486",
        "Document": "docs/james-a-hanson.pdf"
    },
    {
        "TroopNumber": "Troop 251",
        "lng": "-87.790412",
        "State": "Wisconsin",
        "NameOrID": "Hall, Lloyd M.",
        "City": "Racine",
        "Year": "1989",
        "lat": "42.725216",
        "Document": "docs/lloyd-m-hall.pdf"
    },
    {
        "TroopNumber": "Troop 831",
        "lng": "-97.058654",
        "State": "Oklahoma",
        "NameOrID": "Hardesty, Mike D.",
        "City": "Stillwater",
        "Year": "1987",
        "lat": "36.11625",
        "Document": "docs/mike-d-hardesty.pdf"
    },
    {
        "TroopNumber": "Pack 368",
        "lng": "-106.648639",
        "State": "New Mexico",
        "NameOrID": "Harkins, Michael J.",
        "City": "Albuquerque",
        "Year": "1983",
        "lat": "35.08418",
        "Document": " docs/michael-j-harkins.pdf"
    },
    {
        "TroopNumber": "Troop 80",
        "lng": "-85.65538",
        "State": "Indiana",
        "NameOrID": "Hall, Daniel Albert",
        "City": "Charleston",
        "Year": "1988",
        "lat": "38.444156",
        "Document": "docs/daniel-albert-hall.pdf"
    },
    {
        "TroopNumber": "Troop 34",
        "lng": "-87.995949",
        "State": "Illinois",
        "NameOrID": "Harkness, Ned M.",
        "City": "Arlington Heights",
        "Year": "1975",
        "lat": "42.138015",
        "Document": " docs/ned-m-harkness.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-71.547991",
        "State": "Massachusetts",
        "NameOrID": "Hallisey, David A.",
        "City": "Marlborough",
        "Year": "1990",
        "lat": "42.346806",
        "Document": "docs/david-a-hallisey.pdf"
    },
    {
        "TroopNumber": "Pack 412",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Hallquist, Gene",
        "City": "Rockford",
        "Year": "1972",
        "lat": "42.2669",
        "Document": " docs/gene-hallquist.pdf"
    },
    {
        "TroopNumber": "Troop 45",
        "lng": "-118.245319",
        "State": "California",
        "NameOrID": "Harper, Kim Morgan",
        "City": "Los Angeles",
        "Year": "1991",
        "lat": "34.05349",
        "Document": " docs/kim-morgan-harper.pdf"
    },
    {
        "TroopNumber": "Troop 471",
        "lng": "-117.998044",
        "State": "California",
        "NameOrID": "Harper, Kim Morgan",
        "City": "Buena Park",
        "Year": "1991",
        "lat": "33.86441",
        "Document": " docs/kim-morgan-harper.pdf"
    },
    {
        "TroopNumber": "Troop 384",
        "lng": "-71.620919",
        "State": "New Hampshire",
        "NameOrID": "Harper, Ralph R.",
        "City": "Boscawen",
        "Year": "1973",
        "lat": "43.315535",
        "Document": " docs/ralph-r-harper.pdf"
    },
    {
        "TroopNumber": "Troop 1373",
        "lng": "-94.419419",
        "State": "Missouri",
        "NameOrID": "Harper, Christopher",
        "City": "Liberty",
        "Year": "1988",
        "lat": "39.245415",
        "Document": "docs/christopher-harper.pdf"
    },
    {
        "TroopNumber": "Troop 116",
        "lng": "-83.686029",
        "State": "Michigan",
        "NameOrID": "Hatcher, Robert Luther",
        "City": "Flint ",
        "Year": "1977",
        "lat": "43.01075",
        "Document": " docs/robert-luther-hatcher.pdf"
    },
    {
        "TroopNumber": "Troop 452",
        "lng": "-82.669468",
        "State": "Ohio",
        "NameOrID": "Haugh, Fred",
        "City": "Cinti",
        "Year": "1991",
        "lat": "40.190331",
        "Document": "docs/fred-haugh.pdf"
    },
    {
        "TroopNumber": "Explorer 153",
        "lng": "-86.534244",
        "State": "Indiana",
        "NameOrID": "Harper, Michael L.",
        "City": "Bloomington",
        "Year": "1988",
        "lat": "39.16659",
        "Document": "docs/michael-l-harper.pdf"
    },
    {
        "TroopNumber": "Explorer 2061",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Hawkins, Richard L.",
        "City": "Houston",
        "Year": "1988",
        "lat": "29.76045",
        "Document": "docs/richard-l-hawkins.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Harper, Wallace George",
        "City": "",
        "Year": "1971",
        "lat": "",
        "Document": " docs/wallace-george-harper.pdf"
    },
    {
        "TroopNumber": "Explorer 5061",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Hawkins, Richard L.",
        "City": "Houston",
        "Year": "1988",
        "lat": "29.76045",
        "Document": "docs/richard-l-hawkins.pdf"
    },
    {
        "TroopNumber": "Troop 69",
        "lng": "-94.356509",
        "State": "Missouri",
        "NameOrID": "Hawks, Vernon J.",
        "City": "Osborn",
        "Year": "1987",
        "lat": "39.74897",
        "Document": "docs/vernon-j-hawks.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-94.856074",
        "State": "Missouri",
        "NameOrID": "Hawks, Vernon J.",
        "City": "St. Joseph",
        "Year": "1987",
        "lat": "39.7666",
        "Document": "docs/vernon-j-hawks.pdf"
    },
    {
        "TroopNumber": "Troop 112",
        "lng": "-80.837764",
        "State": "North Carolina",
        "NameOrID": "Haytock, Richard K.",
        "City": "Charlotte",
        "Year": "1990",
        "lat": "35.22269",
        "Document": "docs/richard-k-haytock.pdf"
    },
    {
        "TroopNumber": "Troop 164",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Heagy, Steven Miles",
        "City": "Portland",
        "Year": "1991",
        "lat": "45.511795",
        "Document": "docs/steven-miles-heagy.pdf"
    },
    {
        "TroopNumber": "Pack 40",
        "lng": "-75.911271",
        "State": "New York",
        "NameOrID": "Heath, James L.",
        "City": "Binghamton",
        "Year": "1972",
        "lat": "42.098687",
        "Document": " docs/james-l-heath.pdf"
    },
    {
        "TroopNumber": "Troop 25",
        "lng": "-88.952459",
        "State": "Illinois",
        "NameOrID": "Harrison, Richard",
        "City": "Decatur",
        "Year": "1989",
        "lat": "39.845845",
        "Document": "docs/richard-harrison.pdf"
    },
    {
        "TroopNumber": "Troop 87",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "Hebert, Donald J.",
        "City": "Syracuse",
        "Year": "1990",
        "lat": "43.04999",
        "Document": "docs/donald-j-hebert.pdf"
    },
    {
        "TroopNumber": "Troop 852",
        "lng": "",
        "State": "",
        "NameOrID": "Hart, Joseph C.",
        "City": "",
        "Year": "1971",
        "lat": "",
        "Document": " docs/joseph-c-hart.pdf"
    },
    {
        "TroopNumber": "Troop 99",
        "lng": "-88.347949",
        "State": "Wisconsin",
        "NameOrID": "Heindl, Bernard F.",
        "City": "Hartland",
        "Year": "1989",
        "lat": "43.10333",
        "Document": "docs/bernard-f-heindl.pdf"
    },
    {
        "TroopNumber": "Troop 306",
        "lng": "",
        "State": "",
        "NameOrID": "Harter, Dave",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/dave-harter.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-85.601114",
        "State": "Michigan",
        "NameOrID": "Harter, Dave",
        "City": "Kalamazoo",
        "Year": "1988",
        "lat": "42.292435",
        "Document": "docs/dave-harter.pdf"
    },
    {
        "TroopNumber": "Pack 21",
        "lng": "-88.295619",
        "State": "Illinois",
        "NameOrID": "Helms, Charles David",
        "City": "South Elgin",
        "Year": "1990",
        "lat": "41.99464",
        "Document": "docs/charles-david-helms.pdf"
    },
    {
        "TroopNumber": "3",
        "lng": "-88.084594",
        "State": "Illinois",
        "NameOrID": "Hemley, Daniel R.",
        "City": "Joliet",
        "Year": "1988",
        "lat": "41.524935",
        "Document": "docs/daniel-r-hemley.pdf"
    },
    {
        "TroopNumber": "30",
        "lng": "-122.524549",
        "State": "California",
        "NameOrID": "Hartley, Craig D.",
        "City": "Lod Altos",
        "Year": "1980",
        "lat": "37.904617",
        "Document": " docs/craig-d-hartley.pdf"
    },
    {
        "TroopNumber": "Troop 91",
        "lng": "-90.489594",
        "State": "Mississippi",
        "NameOrID": "Henley, Dale Smallwood",
        "City": "Belzoni",
        "Year": "1985",
        "lat": "33.1847",
        "Document": "docs/dale-smallwood-henley.pdf"
    },
    {
        "TroopNumber": "36",
        "lng": "-122.114149",
        "State": "California",
        "NameOrID": "Hartley, Craig D.",
        "City": "Los Altos",
        "Year": "1980",
        "lat": "37.383075",
        "Document": " docs/craig-d-hartley.pdf"
    },
    {
        "TroopNumber": "Troop 67",
        "lng": "-86.457484",
        "State": "Michigan",
        "NameOrID": "Henner, Robert Ray",
        "City": "Benton Harbor",
        "Year": "1971",
        "lat": "42.115535",
        "Document": " docs/robert-ray-henner.pdf"
    },
    {
        "TroopNumber": "Troop 382",
        "lng": "-94.415629",
        "State": "Missouri",
        "NameOrID": "Harvey, Edward L.",
        "City": "Independence",
        "Year": "1988",
        "lat": "39.093245",
        "Document": "docs/edward-l-harvey.pdf"
    },
    {
        "TroopNumber": "Pack 350",
        "lng": "-80.00852",
        "State": "Pennsylvania",
        "NameOrID": "Henrikson, Robert C.",
        "City": "Castle Shannon",
        "Year": "1990",
        "lat": "40.367586",
        "Document": "docs/robert-c-henrikson.pdf"
    },
    {
        "TroopNumber": "Troop 652",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Haskett, Charles R.",
        "City": "Houston",
        "Year": "1989",
        "lat": "29.76045",
        "Document": "docs/charles-r-haskett.pdf"
    },
    {
        "TroopNumber": "Pack 59",
        "lng": "-116.510729",
        "State": "California",
        "NameOrID": "Henson, Jerry",
        "City": "Desert Hot Springs",
        "Year": "1988",
        "lat": "33.9614",
        "Document": "docs/jerry-henson.pdf"
    },
    {
        "TroopNumber": "Troop 295",
        "lng": "-81.523938",
        "State": "North Carolina",
        "NameOrID": "Haskett, Robbie C.",
        "City": "Whitnel",
        "Year": "1991",
        "lat": "35.887279",
        "Document": "docs/robbie-c-haskett.pdf"
    },
    {
        "TroopNumber": "Pack 203",
        "lng": "-117.874307",
        "State": "California",
        "NameOrID": "Hernandez, Paul F.",
        "City": "Santa Ana",
        "Year": "1988",
        "lat": "33.749588",
        "Document": "docs/paul-f-hernandez.pdf"
    },
    {
        "TroopNumber": "Troop 28",
        "lng": "-117.874307",
        "State": "California",
        "NameOrID": "Hernandez, Paul F.",
        "City": "Santa Ana",
        "Year": "1988",
        "lat": "33.749588",
        "Document": "docs/paul-f-hernandez.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Herring, Charles Francis",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/charles-francis-herring.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-95.993334",
        "State": "Oklahoma",
        "NameOrID": "Herring, Charles Francis",
        "City": "Tulsa ",
        "Year": "1988",
        "lat": "36.149745",
        "Document": "docs/charles-francis-herring.pdf"
    },
    {
        "TroopNumber": "Troop 212",
        "lng": "-71.049484",
        "State": "Massachusetts",
        "NameOrID": "Hightower, Paul A.",
        "City": "South Boston",
        "Year": "1971",
        "lat": "42.337955",
        "Document": " docs/paul-a-hightower.pdf"
    },
    {
        "TroopNumber": "Troop 62",
        "lng": "-81.637029",
        "State": "Florida",
        "NameOrID": "Hersey, Jake Gary",
        "City": "Palatka",
        "Year": "1989",
        "lat": "29.648535",
        "Document": "docs/jake-gary-hersey.pdf"
    },
    {
        "TroopNumber": "Troop 211",
        "lng": "-71.049484",
        "State": "Massachusetts",
        "NameOrID": "Hightower, Paul A.",
        "City": "South Boston",
        "Year": "1971",
        "lat": "42.337955",
        "Document": " docs/paul-a-hightower.pdf"
    },
    {
        "TroopNumber": "Pack 36",
        "lng": "-91.673759",
        "State": "Iowa",
        "NameOrID": "Hess, Terry",
        "City": "Cedar Rapids",
        "Year": "1988",
        "lat": "41.976345",
        "Document": "docs/terry-hess.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Higley, Douglas Lee",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/douglas-lee-higley.pdf"
    },
    {
        "TroopNumber": "154",
        "lng": "-80.837764",
        "State": "North Carolina",
        "NameOrID": "Hester, Luther Wayne",
        "City": "Charlotte",
        "Year": "1989",
        "lat": "35.22269",
        "Document": "docs/luther-wayne-hester.pdf"
    },
    {
        "TroopNumber": "Troop 560",
        "lng": "-83.606874",
        "State": "Georgia",
        "NameOrID": "Hill, Paul B.",
        "City": "Warner Robins",
        "Year": "1974",
        "lat": "32.620835",
        "Document": " docs/paul-b-hill.pdf"
    },
    {
        "TroopNumber": "Troop 250",
        "lng": "-88.896378",
        "State": "Mississippi",
        "NameOrID": "Hibbard, James D.",
        "City": "Biloxi",
        "Year": "1989",
        "lat": "30.394381",
        "Document": "docs/james-d-hibbard.pdf"
    },
    {
        "TroopNumber": "Pack 219",
        "lng": "-88.896378",
        "State": "Mississippi",
        "NameOrID": "Hibbard, James D.",
        "City": "Biloxi",
        "Year": "1989",
        "lat": "30.394381",
        "Document": "docs/james-d-hibbard.pdf"
    },
    {
        "TroopNumber": "Explorer 2131",
        "lng": "-120.507049",
        "State": "Washington",
        "NameOrID": "Hicks, Michael L.",
        "City": "Yakima",
        "Year": "1990",
        "lat": "46.604135",
        "Document": "docs/michael-l-hicks.pdf"
    },
    {
        "TroopNumber": "Troop 418",
        "lng": "-81.701154",
        "State": "Florida",
        "NameOrID": "Hillebrenner, Eric",
        "City": "Orange Park",
        "Year": "1987",
        "lat": "30.165065",
        "Document": "docs/eric-hillebrenner.pdf"
    },
    {
        "TroopNumber": "Troop 412",
        "lng": "-81.701154",
        "State": "Florida",
        "NameOrID": "Hicks, Patrick H.",
        "City": "Orange Park",
        "Year": "1987",
        "lat": "30.165065",
        "Document": "docs/patrick-h-hicks.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Hicks, Patrick H.",
        "City": "Jacksonville",
        "Year": "1987",
        "lat": "30.33147",
        "Document": "docs/patrick-h-hicks.pdf"
    },
    {
        "TroopNumber": "Explorer 418",
        "lng": "-81.701154",
        "State": "Florida",
        "NameOrID": "Hillebrenner, Eric",
        "City": "Orange Park",
        "Year": "1987",
        "lat": "30.165065",
        "Document": "docs/eric-hillebrenner.pdf"
    },
    {
        "TroopNumber": "Troop 261",
        "lng": "-83.002984",
        "State": "Ohio",
        "NameOrID": "Highman, Michael Shriver",
        "City": "Columbus",
        "Year": "1984",
        "lat": "39.96196",
        "Document": " docs/michael-shriver-highman.pdf"
    },
    {
        "TroopNumber": "Pack 111",
        "lng": "-73.191269",
        "State": "Connecticut",
        "NameOrID": "Hiller, John",
        "City": "Bridgeport",
        "Year": "1989",
        "lat": "41.18188",
        "Document": "docs/john-hiller.pdf"
    },
    {
        "TroopNumber": "Pack 406",
        "lng": "",
        "State": "",
        "NameOrID": "Hogan, James F.",
        "City": "",
        "Year": "1974",
        "lat": "",
        "Document": " docs/james-f-hogan.pdf"
    },
    {
        "TroopNumber": "Troop 211",
        "lng": "-73.191269",
        "State": "Connecticut",
        "NameOrID": "Hiller, John",
        "City": "Bridgeport",
        "Year": "1989",
        "lat": "41.18188",
        "Document": "docs/john-hiller.pdf"
    },
    {
        "TroopNumber": "Explorer 812",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Hogan, James F.",
        "City": "Portland",
        "Year": "1974",
        "lat": "45.511795",
        "Document": " docs/james-f-hogan.pdf"
    },
    {
        "TroopNumber": "Troop 79",
        "lng": "-76.906905",
        "State": "New York",
        "NameOrID": "Hills, Mark E.",
        "City": "Moreland",
        "Year": "1988",
        "lat": "42.3078519",
        "Document": "docs/mark-e-hills.pdf"
    },
    {
        "TroopNumber": "Troop 210",
        "lng": "-92.459849",
        "State": "Minnesota",
        "NameOrID": "Hokanson, Richard",
        "City": "Rochester ",
        "Year": "1983",
        "lat": "44.01932",
        "Document": " docs/richard-hokanson.pdf"
    },
    {
        "TroopNumber": "Troop 82",
        "lng": "-116.054879",
        "State": "California",
        "NameOrID": "Holdsworth, William",
        "City": "Twentynine Palms",
        "Year": "1980",
        "lat": "34.13561",
        "Document": " docs/william-holdsworth.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-76.890399",
        "State": "New York",
        "NameOrID": "Hills, Mark E.",
        "City": "Dix",
        "Year": "1988",
        "lat": "42.329026",
        "Document": "docs/mark-e-hills.pdf"
    },
    {
        "TroopNumber": "Explorer 2027",
        "lng": "-87.022234",
        "State": "Alabama",
        "NameOrID": "Holland, Ricky A.",
        "City": "Selma",
        "Year": "1989",
        "lat": "32.41179",
        "Document": "docs/ricky-a-holland.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "Hollenback, Kenneth",
        "City": "Syracuse",
        "Year": "1988",
        "lat": "43.04999",
        "Document": "docs/kenneth-hollenback.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hinton, Darryl Keith",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": "docs/darryl-keith-hinton.pdf"
    },
    {
        "TroopNumber": "Troop 40",
        "lng": "-74.024339",
        "State": "New Jersey",
        "NameOrID": "Holzschuh, William Howard",
        "City": "Belmar",
        "Year": "1989",
        "lat": "40.18289",
        "Document": "docs/william-howard-holzschuh.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-93.891169",
        "State": "Mississippi",
        "NameOrID": "Hoolihan, Steve",
        "City": "Columbus",
        "Year": "1986",
        "lat": "38.85564",
        "Document": "docs/steve-hoolihan.pdf"
    },
    {
        "TroopNumber": "Troop 479",
        "lng": "-76.059204",
        "State": "Virginia",
        "NameOrID": "Hobson, Bernard C.",
        "City": "Virginia Beach",
        "Year": "1971",
        "lat": "36.75502",
        "Document": " docs/bernard-c-hobson.pdf"
    },
    {
        "TroopNumber": "348",
        "lng": "-120.527099",
        "State": "California",
        "NameOrID": "Hooper, George Richard",
        "City": "West Point",
        "Year": "1987",
        "lat": "38.399162",
        "Document": "docs/george-richard-hooper.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Hoffelder, Douglas K.",
        "City": "St. Louis",
        "Year": "1971",
        "lat": "38.62774",
        "Document": " docs/douglas-k-hoffelder.pdf"
    },
    {
        "TroopNumber": "Pack 348",
        "lng": "-120.527099",
        "State": "California",
        "NameOrID": "Hooper, George Richard",
        "City": "West Point",
        "Year": "1987",
        "lat": "38.399162",
        "Document": "docs/george-richard-hooper.pdf"
    },
    {
        "TroopNumber": "Troop 234",
        "lng": "-76.045669",
        "State": "New York",
        "NameOrID": "Hooper, Jack",
        "City": "Endicott",
        "Year": "1990",
        "lat": "42.09973",
        "Document": "docs/jack-hooper.pdf"
    },
    {
        "TroopNumber": "Troop 145",
        "lng": "-82.877409",
        "State": "Michigan",
        "NameOrID": "Hopgood, Daniel",
        "City": "Mt. Clemens",
        "Year": "1988",
        "lat": "42.596605",
        "Document": "docs/daniel-hopgood.pdf"
    },
    {
        "TroopNumber": "Troop 87",
        "lng": "-75.524756",
        "State": "New York",
        "NameOrID": "Hopkins, Robert James",
        "City": "Lee Center",
        "Year": "1972",
        "lat": "43.303868",
        "Document": " docs/robert-james-hopkins.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-88.350756",
        "State": "Kentucky",
        "NameOrID": "Horrall, Steve R.",
        "City": "Benton",
        "Year": "1989",
        "lat": "36.85753",
        "Document": "docs/steve-r-horrall.pdf"
    },
    {
        "TroopNumber": "Explorer 257",
        "lng": "-97.903769",
        "State": "Texas",
        "NameOrID": "Horn, Billie Lorenz",
        "City": "Copperas Cove",
        "Year": "1986",
        "lat": "31.12387",
        "Document": "docs/billie-lorenz-horn.pdf"
    },
    {
        "TroopNumber": "Troop 203",
        "lng": "-87.309844",
        "State": "Indiana",
        "NameOrID": "Horrall, Steve R.",
        "City": "Wheatland",
        "Year": "1989",
        "lat": "38.662625",
        "Document": "docs/steve-r-horrall.pdf"
    },
    {
        "TroopNumber": "Pack 921",
        "lng": "-80.241094",
        "State": "North Carolina",
        "NameOrID": "Horton, John Edward",
        "City": "Winston-Salem",
        "Year": "1988",
        "lat": "36.099625",
        "Document": "docs/john-edward-horton.pdf"
    },
    {
        "TroopNumber": "Troop 31",
        "lng": "-101.833894",
        "State": "Texas",
        "NameOrID": "Hughes, George Wayne",
        "City": "Amarillo",
        "Year": "1990",
        "lat": "35.207255",
        "Document": "docs/george-wayne-hughes.pdf"
    },
    {
        "TroopNumber": "Troop 186",
        "lng": "-106.046059",
        "State": "Colorado",
        "NameOrID": "Horton, Eldon",
        "City": "Dillon",
        "Year": "1989",
        "lat": "39.630263",
        "Document": "docs/eldon-horton.pdf"
    },
    {
        "TroopNumber": "Troop 25",
        "lng": "-74.211467",
        "State": "New Jersey",
        "NameOrID": "Hughes, Brian",
        "City": "Elizabeth",
        "Year": "1980",
        "lat": "40.663517",
        "Document": " docs/brian-hughes.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-83.536259",
        "State": "Ohio",
        "NameOrID": "Horton, Gary R.",
        "City": "Toledo",
        "Year": "1986",
        "lat": "41.65381",
        "Document": "docs/gary-r-horton.pdf"
    },
    {
        "TroopNumber": "Troop 811",
        "lng": "-117.084279",
        "State": "California",
        "NameOrID": "Humphrey, Richard E.",
        "City": "Chula Vista",
        "Year": "1975",
        "lat": "32.6409",
        "Document": " docs/richard-e-humphrey.pdf"
    },
    {
        "TroopNumber": "Troop 472",
        "lng": "-76.247847",
        "State": "Virginia",
        "NameOrID": "Humphries, Arthur W.",
        "City": "Chesapeake",
        "Year": "1984",
        "lat": "36.714295",
        "Document": "docs/arthur-w-humphries.pdf"
    },
    {
        "TroopNumber": "Troop 138",
        "lng": "",
        "State": "",
        "NameOrID": "Hosch, Michael James",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/michael-james-hosch.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hunnicutt, William Ray",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/william-ray-hunnicutt.pdf"
    },
    {
        "TroopNumber": "Troop 856",
        "lng": "-96.602149",
        "State": "Texas",
        "NameOrID": "Hoseck, Don Eugene",
        "City": "Mesquite",
        "Year": "1989",
        "lat": "32.783455",
        "Document": "docs/don-eugene-hoseck.pdf"
    },
    {
        "TroopNumber": "Troop 650",
        "lng": "-87.833889",
        "State": "Illinois",
        "NameOrID": "Hunter, John K.",
        "City": "Waukegan",
        "Year": "1990",
        "lat": "42.36164",
        "Document": "docs/john-k-hunter.pdf"
    },
    {
        "TroopNumber": "Troop 108",
        "lng": "-89.904739",
        "State": "Arkansas",
        "NameOrID": "Huntzicker, Eugene",
        "City": "Blytheville",
        "Year": "1981",
        "lat": "35.92848",
        "Document": " docs/eugene-huntzicker.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hotchkiss, Harold",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/harold-hotchkiss.pdf"
    },
    {
        "TroopNumber": "Troop 635",
        "lng": "",
        "State": "",
        "NameOrID": "Hurst, David M.",
        "City": "",
        "Year": "1976",
        "lat": "",
        "Document": " docs/david-m-hurst.pdf"
    },
    {
        "TroopNumber": "Troop 504",
        "lng": "-97.742984",
        "State": "Texas",
        "NameOrID": "Howard, George James",
        "City": "Austin",
        "Year": "1971",
        "lat": "30.267605",
        "Document": " docs/george-james-howard.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hutton, George",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/george-hutton.pdf"
    },
    {
        "TroopNumber": "Troop 223",
        "lng": "-75.927843",
        "State": "Pennsylvania",
        "NameOrID": "Huyett, Thomas W.",
        "City": "Reading ",
        "Year": "1988",
        "lat": "40.336585",
        "Document": "docs/thomas-w-huyett.pdf"
    },
    {
        "TroopNumber": "Troop 523",
        "lng": "-81.186399",
        "State": "North Carolina",
        "NameOrID": "Jacobs, Herbert Melton",
        "City": "Gastonia",
        "Year": "1990",
        "lat": "35.2622",
        "Document": "docs/herbert-melton-jacobs.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hyslop, James",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": " docs/james-hyslop.pdf"
    },
    {
        "TroopNumber": "571",
        "lng": "-118.072524",
        "State": "California",
        "NameOrID": "Idle, Donnie Allen",
        "City": "Norwalk",
        "Year": "1985",
        "lat": "33.91674",
        "Document": "docs/donnie-allen-idle.pdf"
    },
    {
        "TroopNumber": "Pack 64",
        "lng": "-105.100504",
        "State": "Colorado",
        "NameOrID": "Jacobson, Jack D.",
        "City": "Longmont",
        "Year": "1988",
        "lat": "40.16394",
        "Document": "docs/jack-d-jacobson.pdf"
    },
    {
        "TroopNumber": "Troop 201",
        "lng": "",
        "State": "California",
        "NameOrID": "Immel, Kirk O.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/kirk-o-immel.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Jaggers, Mark Aaron",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/mark-aaron-jaggers.pdf"
    },
    {
        "TroopNumber": "Troop 46",
        "lng": "-73.491724",
        "State": "New York",
        "NameOrID": "Ingraham, Robert L.",
        "City": "Fort Ann",
        "Year": "1987",
        "lat": "43.41455",
        "Document": "docs/robert-l-ingraham.pdf"
    },
    {
        "TroopNumber": "6535",
        "lng": "-78.457896",
        "State": "Virginia",
        "NameOrID": "Jamerson, Wilbur J.",
        "City": "Dillwyn",
        "Year": "1985",
        "lat": "37.542683",
        "Document": "docs/wilbur-j-jamerson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-77.433654",
        "State": "Virginia",
        "NameOrID": "Jamerson, Wilbur J.",
        "City": "Richmond",
        "Year": "1985",
        "lat": "37.5407",
        "Document": "docs/wilbur-j-jamerson.pdf"
    },
    {
        "TroopNumber": "Troop 361",
        "lng": "-75.377784",
        "State": "Pennsylvania",
        "NameOrID": "Jeffries, Walter J.",
        "City": "Bethlehem",
        "Year": "1981",
        "lat": "40.630985",
        "Document": " docs/walter-j-jeffries.pdf"
    },
    {
        "TroopNumber": "512",
        "lng": "-114.981094",
        "State": "Nevada",
        "NameOrID": "Jellum, Paul M.",
        "City": "Henderson",
        "Year": "1991",
        "lat": "36.03082",
        "Document": "docs/paul-m-jellum.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Janiak, Thomas A.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/thomas-a-janiak.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-96.707739",
        "State": "Nebraska",
        "NameOrID": "Jellum, Patrick K.",
        "City": "Lincoln",
        "Year": "1991",
        "lat": "40.81362",
        "Document": "docs/patrick-k-jellum.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Jarman, Gerald Roger",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/gerald-roger-jarman.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Jennette, Herman",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/herman-jennette.pdf"
    },
    {
        "TroopNumber": "Pack 371",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Jarman, Gerald Roger",
        "City": "Rockford",
        "Year": "1987",
        "lat": "42.2669",
        "Document": "docs/gerald-roger-jarman.pdf"
    },
    {
        "TroopNumber": "Troop 521",
        "lng": "-89.018214",
        "State": "Wisconsin",
        "NameOrID": "Jensen, Eugene H.",
        "City": "Janesville",
        "Year": "1989",
        "lat": "42.687892",
        "Document": "docs/eugene-h-jensen.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-94.177624",
        "State": "Iowa",
        "NameOrID": "Jasper, James Lewis",
        "City": "Ft. Dodge",
        "Year": "1985",
        "lat": "42.50157",
        "Document": "docs/james-lewis-jasper.pdf"
    },
    {
        "TroopNumber": "Troop 287",
        "lng": "-123.199129",
        "State": "Oregon",
        "NameOrID": "Jensen, Dick A.",
        "City": "McMinnville",
        "Year": "1990",
        "lat": "45.20894",
        "Document": "docs/dick-a-jensen.pdf"
    },
    {
        "TroopNumber": "Troop 731",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Jeanotte, Charles E.",
        "City": "St. Louis",
        "Year": "1981",
        "lat": "38.62774",
        "Document": " docs/charles-e-jeanotte.pdf"
    },
    {
        "TroopNumber": "Troop 123",
        "lng": "-117.874307",
        "State": "California",
        "NameOrID": "Jefferson, Donald",
        "City": "Santa Ana",
        "Year": "1972",
        "lat": "33.749588",
        "Document": " docs/donald-jefferson.pdf"
    },
    {
        "TroopNumber": "3334",
        "lng": "-82.638764",
        "State": "Florida",
        "NameOrID": "Jessop, Richard J.",
        "City": "St. Petersburg",
        "Year": "1991",
        "lat": "27.77119",
        "Document": "docs/richard-j-jessop.pdf"
    },
    {
        "TroopNumber": "Pack 10",
        "lng": "-71.390754",
        "State": "Rhode Island",
        "NameOrID": "Jette, Regis",
        "City": "Cumberland ",
        "Year": "1988",
        "lat": "41.900345",
        "Document": "docs/regis-jette.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-97.403564",
        "State": "Texas",
        "NameOrID": "Johnson, Frank Aubry",
        "City": "Corpus Christi",
        "Year": "1988",
        "lat": "27.79641",
        "Document": "docs/frank-aubry-johnson.pdf"
    },
    {
        "TroopNumber": "Troop 682",
        "lng": "-89.292044",
        "State": "Illinois",
        "NameOrID": "Johnson, Warren Leslie",
        "City": "Taylorville",
        "Year": "1986",
        "lat": "39.55226",
        "Document": "docs/warren-leslie-johnson.pdf"
    },
    {
        "TroopNumber": "Troop 9",
        "lng": "-92.445649",
        "State": "Louisiana",
        "NameOrID": "Johnson, Leonard C.",
        "City": "Alexandria",
        "Year": "1988",
        "lat": "31.312685",
        "Document": "docs/leonard-c-johnson.pdf"
    },
    {
        "TroopNumber": "Troop 133",
        "lng": "-89.292044",
        "State": "Illinois",
        "NameOrID": "Johnson, Warren Leslie",
        "City": "Taylorville",
        "Year": "1986",
        "lat": "39.55226",
        "Document": "docs/warren-leslie-johnson.pdf"
    },
    {
        "TroopNumber": "Troop 126",
        "lng": "-119.116984",
        "State": "Washington",
        "NameOrID": "Johnson, Richard",
        "City": "Kennewick",
        "Year": "1990",
        "lat": "46.20804",
        "Document": " docs/richard-johnson.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-92.445649",
        "State": "Louisiana",
        "NameOrID": "Johnson, Leonard C.",
        "City": "Alexandria",
        "Year": "1988",
        "lat": "31.312685",
        "Document": "docs/leonard-c-johnson.pdf"
    },
    {
        "TroopNumber": "Troop 55",
        "lng": "-87.798569",
        "State": "Illinois",
        "NameOrID": "Johnson, Daniel W.",
        "City": "Glenview",
        "Year": "1988",
        "lat": "42.077455",
        "Document": "docs/daniel-w-johnson.pdf"
    },
    {
        "TroopNumber": "Troop 5",
        "lng": "-88.952459",
        "State": "Illinois",
        "NameOrID": "Johnson, Harry L.",
        "City": "Decatur",
        "Year": "1990",
        "lat": "39.845845",
        "Document": "docs/harry-l-johnson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-103.621039",
        "State": "Colorado",
        "NameOrID": "Johnson, William H.",
        "City": "Brush",
        "Year": "1990",
        "lat": "40.255366",
        "Document": "docs/william-h-johnson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Johnson, R. Martin",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/r-martin-johnson.pdf"
    },
    {
        "TroopNumber": "Troop 386",
        "lng": "-121.328394",
        "State": "California",
        "NameOrID": "Jones, Lawrence Stephen",
        "City": "Carmichael",
        "Year": "1985",
        "lat": "38.625205",
        "Document": "docs/lawrence-stephen-jones.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Johnson, John T.",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": " docs/john-t-johnson.pdf"
    },
    {
        "TroopNumber": "379",
        "lng": "-118.072524",
        "State": "California",
        "NameOrID": "Jones, Charles M.",
        "City": "Norwalk",
        "Year": "1990",
        "lat": "33.91674",
        "Document": "docs/charles-m-jones.pdf"
    },
    {
        "TroopNumber": "Troop 124",
        "lng": "-92.445649",
        "State": "Louisiana",
        "NameOrID": "Jones, Theron A.",
        "City": "Alexandria",
        "Year": "1989",
        "lat": "31.312685",
        "Document": "docs/theron-a-jones.pdf"
    },
    {
        "TroopNumber": "Troop 67",
        "lng": "-81.314255",
        "State": "Florida",
        "NameOrID": "Johnston, Charles D.",
        "City": "St. Augustine",
        "Year": "1988",
        "lat": "29.892128",
        "Document": "docs/charles-d-johnston.pdf"
    },
    {
        "TroopNumber": "Pack 38",
        "lng": "-121.491014",
        "State": "California",
        "NameOrID": "Jones, Hank A.",
        "City": "Sacramento",
        "Year": "1986",
        "lat": "38.579065",
        "Document": "docs/hank-a-jones.pdf"
    },
    {
        "TroopNumber": "Explorer 32",
        "lng": "-72.206332",
        "State": "Connecticut",
        "NameOrID": "Johnston, Gary",
        "City": "Willimantie ",
        "Year": "1985",
        "lat": "41.710542",
        "Document": "docs/gary-johnston.pdf"
    },
    {
        "TroopNumber": "Explorer 10",
        "lng": "-78.878464",
        "State": "New York",
        "NameOrID": "Jones, Timothy H.",
        "City": "Buffalo",
        "Year": "1989",
        "lat": "42.88544",
        "Document": "docs/timothy-h-jones.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-84.19453",
        "State": "Ohio",
        "NameOrID": "Johnston, John R.",
        "City": "Wapakoneta",
        "Year": "1974",
        "lat": "40.567881",
        "Document": " docs/john-r-johnston.pdf"
    },
    {
        "TroopNumber": "Troop 120",
        "lng": "-122.329439",
        "State": "Washington",
        "NameOrID": "Jonasson, Richard C.",
        "City": "Seattle",
        "Year": "1988",
        "lat": "47.60356",
        "Document": "docs/richard-c-jonasson.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-82.086569",
        "State": "Florida",
        "NameOrID": "Jones, Thomas",
        "City": "Hawthorne",
        "Year": "1989",
        "lat": "29.592845",
        "Document": "docs/thomas-jones.pdf"
    },
    {
        "TroopNumber": "Pack 120",
        "lng": "-122.329439",
        "State": "Washington",
        "NameOrID": "Jonasson, Richard C.",
        "City": "Seattle",
        "Year": "1988",
        "lat": "47.60356",
        "Document": "docs/richard-c-jonasson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-118.245319",
        "State": "California",
        "NameOrID": "Jones, Samuel L.",
        "City": "Los Angeles",
        "Year": "1984",
        "lat": "34.05349",
        "Document": " docs/samuel-l-jones.pdf"
    },
    {
        "TroopNumber": "22",
        "lng": "-88.325879",
        "State": "Tennessee",
        "NameOrID": "Jones, Carl Arthur",
        "City": "Paris",
        "Year": "1989",
        "lat": "36.301885",
        "Document": "docs/carl-arthur-jones.pdf"
    },
    {
        "TroopNumber": "Troop 215",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Jones, Sam C.",
        "City": "Kansas City",
        "Year": "1989",
        "lat": "39.10296",
        "Document": "docs/sam-c-jones.pdf"
    },
    {
        "TroopNumber": "Troop 176",
        "lng": "-82.539629",
        "State": "Florida",
        "NameOrID": "Jordan, Ronald Allen",
        "City": "Sarasota",
        "Year": "1988",
        "lat": "27.33888",
        "Document": "docs/ronald-allen-jordan.pdf"
    },
    {
        "TroopNumber": "Explorer 2388",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Jones, Sam C.",
        "City": "Kansas City",
        "Year": "1989",
        "lat": "39.10296",
        "Document": "docs/sam-c-jones.pdf"
    },
    {
        "TroopNumber": "Explorer 25",
        "lng": "-82.539629",
        "State": "Florida",
        "NameOrID": "Jordan, Ronald Allen",
        "City": "Sarasota",
        "Year": "1988",
        "lat": "27.33888",
        "Document": "docs/ronald-allen-jordan.pdf"
    },
    {
        "TroopNumber": "769",
        "lng": "-76.695054",
        "State": "Maryland",
        "NameOrID": "Jones, Alfred M.",
        "City": "Odenton",
        "Year": "1974",
        "lat": "39.081405",
        "Document": " docs/alfred-m-jones.pdf"
    },
    {
        "TroopNumber": "616",
        "lng": "-97.335579",
        "State": "Kansas",
        "NameOrID": "Joy, Michael R.",
        "City": "Wichita",
        "Year": "1991",
        "lat": "37.68698",
        "Document": "docs/michael-r-joy.pdf"
    },
    {
        "TroopNumber": "Troop 4",
        "lng": "-71.308294",
        "State": "Rhode Island",
        "NameOrID": "Joynes, Ronald",
        "City": "Barrington",
        "Year": "1989",
        "lat": "41.740155",
        "Document": "docs/ronald-joynes.pdf"
    },
    {
        "TroopNumber": "7",
        "lng": "-74.983156",
        "State": "New York",
        "NameOrID": "Jungers, Albert Kenneth",
        "City": "Fly Creek",
        "Year": "1991",
        "lat": "42.719266",
        "Document": "docs/albert-kenneth-jungers.pdf"
    },
    {
        "TroopNumber": "Troop 365",
        "lng": "-97.003574",
        "State": "Texas",
        "NameOrID": "Judd, Mack Arthur",
        "City": "Victoria",
        "Year": "1984",
        "lat": "28.805965",
        "Document": " docs/mack-arthur-judd.pdf"
    },
    {
        "TroopNumber": "Explorer 851",
        "lng": "-81.202827",
        "State": "Florida",
        "NameOrID": "Jurek, Allen G.",
        "City": "Oviedo",
        "Year": "1991",
        "lat": "28.652516",
        "Document": "docs/allen-g-jurek.pdf"
    },
    {
        "TroopNumber": "Troop 29",
        "lng": "-92.822129",
        "State": "Louisiana",
        "NameOrID": "Judd, Bobby",
        "City": "Welsh",
        "Year": "1983",
        "lat": "30.232182",
        "Document": " docs/bobby-judd.pdf"
    },
    {
        "TroopNumber": "Troop 61",
        "lng": "-87.906844",
        "State": "Wisconsin",
        "NameOrID": "July, John Paul",
        "City": "Milwaukee",
        "Year": "1981",
        "lat": "43.04181",
        "Document": " docs/john-paul-july.pdf"
    },
    {
        "TroopNumber": "Explorer 689",
        "lng": "-77.412034",
        "State": "Maryland",
        "NameOrID": "Jury, Larry B.",
        "City": "Frederick",
        "Year": "1990",
        "lat": "39.415745",
        "Document": "docs/larry-b-jury.pdf"
    },
    {
        "TroopNumber": "Troop 706",
        "lng": "-80.726179",
        "State": "Florida",
        "NameOrID": "Kees, James S.",
        "City": "Cocoa",
        "Year": "1987",
        "lat": "28.35167",
        "Document": "docs/james-s-kees.pdf"
    },
    {
        "TroopNumber": "Troop 5",
        "lng": "8.582042",
        "State": "Germany",
        "NameOrID": "Kablach, John E.",
        "City": "Bremerhaven",
        "Year": "1991",
        "lat": "53.544301",
        "Document": "docs/john-e-kablach.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-81.493799",
        "State": "Florida",
        "NameOrID": "Kees, James S.",
        "City": "Paiseley",
        "Year": "1987",
        "lat": "29.01702",
        "Document": "docs/james-s-kees.pdf"
    },
    {
        "TroopNumber": "Troop 50",
        "lng": "-68.504569",
        "State": "Maine",
        "NameOrID": "Kanes, Douglas F.",
        "City": "Lincoln",
        "Year": "1990",
        "lat": "45.366475",
        "Document": "docs/douglas-f-kanes.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-116.193409",
        "State": "Idaho",
        "NameOrID": "Keheley, James E.",
        "City": "Boise",
        "Year": "1988",
        "lat": "43.60698",
        "Document": "docs/james-e-keheley.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-77.054624",
        "State": "New York",
        "NameOrID": "Kaopuiki, Bradford",
        "City": "Corning",
        "Year": "1984",
        "lat": "42.143077",
        "Document": " docs/bradford-kaopuiki.pdf"
    },
    {
        "TroopNumber": "Troop 110",
        "lng": "",
        "State": "",
        "NameOrID": "Kellar, Dennis E.",
        "City": "",
        "Year": "1983",
        "lat": "",
        "Document": " docs/dennis-e-kellar.pdf"
    },
    {
        "TroopNumber": "District 8699",
        "lng": "-75.362719",
        "State": "New Jersey",
        "NameOrID": "Karczewski, Julian A.",
        "City": "Alloway",
        "Year": "1988",
        "lat": "39.559127",
        "Document": "docs/julian-a-karczewski.pdf"
    },
    {
        "TroopNumber": "Explorer 1014",
        "lng": "-71.281089",
        "State": "Massachusetts",
        "NameOrID": "Kelleher, Dale Mathew",
        "City": "Attleboro",
        "Year": "1991",
        "lat": "41.945085",
        "Document": "docs/dale-mathew-kelleher.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-75.362719",
        "State": "New Jersey",
        "NameOrID": "Karczewski, Julian A.",
        "City": "Alloway",
        "Year": "1988",
        "lat": "39.559127",
        "Document": "docs/julian-a-karczewski.pdf"
    },
    {
        "TroopNumber": "Troop 178",
        "lng": "-121.896772",
        "State": "California",
        "NameOrID": "Kelleher, John L.",
        "City": "Monterey",
        "Year": "1991",
        "lat": "36.597591",
        "Document": "docs/john-l-kelleher.pdf"
    },
    {
        "TroopNumber": "Troop 89",
        "lng": "-80.763618",
        "State": "Ohio",
        "NameOrID": "Kasek, Ronald J.",
        "City": "Austintown",
        "Year": "1986",
        "lat": "41.0975",
        "Document": "docs/ronald-j-kasek.pdf"
    },
    {
        "TroopNumber": "Troop 337",
        "lng": "-90.319909",
        "State": "Missouri",
        "NameOrID": "Kelley, John J.",
        "City": "Richmond Hights",
        "Year": "1979",
        "lat": "38.628135",
        "Document": "docs/john-j-kelley.pdf"
    },
    {
        "TroopNumber": "Troop 865",
        "lng": "-83.401369",
        "State": "Michigan",
        "NameOrID": "Kassel, Tony R.",
        "City": "Westland",
        "Year": "1973",
        "lat": "42.3242",
        "Document": " docs/tony-r-kassel.pdf"
    },
    {
        "TroopNumber": "Troop 375",
        "lng": "-79.812126",
        "State": "Virginia",
        "NameOrID": "Kelly, Max C.W.",
        "City": "Burnt Chimney",
        "Year": "1984",
        "lat": "37.108138",
        "Document": " docs/max-cw-kelly.pdf"
    },
    {
        "TroopNumber": "Troop 533",
        "lng": "-83.308869",
        "State": "Michigan",
        "NameOrID": "Kastura, George Josh",
        "City": "Newport",
        "Year": "1990",
        "lat": "42.002515",
        "Document": "docs/george-josh-kastura.pdf"
    },
    {
        "TroopNumber": "Pack 456",
        "lng": "-79.889287",
        "State": "Virginia",
        "NameOrID": "Kelly, Max C.W.",
        "City": "Rocky Mount",
        "Year": "1984",
        "lat": "36.995042",
        "Document": " docs/max-cw-kelly.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-88.235513",
        "State": "Wisconsin",
        "NameOrID": "Keating, Jack W.",
        "City": "Waukesha",
        "Year": "1974",
        "lat": "43.014423",
        "Document": " docs/jack-w-keating.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "Kelly, Max C.W.",
        "City": "San Diego",
        "Year": "1984",
        "lat": "32.715695",
        "Document": " docs/max-cw-kelly.pdf"
    },
    {
        "TroopNumber": "Troop 115",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Keaton, Robert Jeff",
        "City": "Indianapolis",
        "Year": "1989",
        "lat": "39.76691",
        "Document": "docs/robert-jeff-keaton.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-85.615839",
        "State": "Michigan",
        "NameOrID": "Kelsey, Louis Charles",
        "City": "Traverse City",
        "Year": "1984",
        "lat": "44.759995",
        "Document": " docs/louis-charles-kelsey.pdf"
    },
    {
        "TroopNumber": "Pack 63",
        "lng": "-86.207249",
        "State": "Michigan",
        "NameOrID": "Kelsey, Louis Charles",
        "City": "Onekama",
        "Year": "1984",
        "lat": "44.363695",
        "Document": " docs/louis-charles-kelsey.pdf"
    },
    {
        "TroopNumber": "Troop 181",
        "lng": "-92.12783",
        "State": "Louisiana",
        "NameOrID": "Kee, Norman Hoyt",
        "City": "West Monroe",
        "Year": "1988",
        "lat": "32.504086",
        "Document": "docs/norman-hoyt-kee.pdf"
    },
    {
        "TroopNumber": "545",
        "lng": "-84.348039",
        "State": "Michigan",
        "NameOrID": "Kelto, Douglas Loren",
        "City": "Addison",
        "Year": "1985",
        "lat": "41.986645",
        "Document": "docs/douglas-loren-kelto.pdf"
    },
    {
        "TroopNumber": "Pack 3305",
        "lng": "-118.248364",
        "State": "California",
        "NameOrID": "Kennaday, Donald F.",
        "City": "La Crescenta",
        "Year": "1986",
        "lat": "34.22939",
        "Document": "docs/donald-f-kennaday.pdf"
    },
    {
        "TroopNumber": "Troop 86",
        "lng": "-71.411994",
        "State": "Rhode Island",
        "NameOrID": "Kempf, Jack J.",
        "City": "Providence",
        "Year": "1988",
        "lat": "41.823875",
        "Document": "docs/jack-j-kempf.pdf"
    },
    {
        "TroopNumber": "1150",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Kennedy, James A.",
        "City": "Kansas City",
        "Year": "1984",
        "lat": "39.10296",
        "Document": " docs/james-a-kennedy.pdf"
    },
    {
        "TroopNumber": "Troop 425",
        "lng": "-80.285129",
        "State": "Florida",
        "NameOrID": "Kikta, Joseph",
        "City": "Miami Springs",
        "Year": "1985",
        "lat": "25.821095",
        "Document": "docs/joseph-kikta.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-80.567888",
        "State": "Florida",
        "NameOrID": "Kerns, Robert Dale",
        "City": "Islamorada",
        "Year": "1987",
        "lat": "24.960295",
        "Document": "docs/robert-dale-kerns.pdf"
    },
    {
        "TroopNumber": "Pack 232",
        "lng": "-72.974934",
        "State": "Connecticut",
        "NameOrID": "Kimber, Donald J.",
        "City": "Wolcott",
        "Year": "1987",
        "lat": "41.601025",
        "Document": "docs/donald-j-kimber.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-98.248379",
        "State": "Texas",
        "NameOrID": "Kerns, Robert Dale",
        "City": "Graford",
        "Year": "1987",
        "lat": "32.938225",
        "Document": "docs/robert-dale-kerns.pdf"
    },
    {
        "TroopNumber": "Troop 79",
        "lng": "-81.9934581",
        "State": "West Virginia",
        "NameOrID": "Kincaid, Edker",
        "City": "Logan",
        "Year": "1988",
        "lat": "37.8487147",
        "Document": "docs/edker-kincaid.pdf"
    },
    {
        "TroopNumber": "Troop 507",
        "lng": "-88.153019",
        "State": "Illinois",
        "NameOrID": "Keyes, Alan G.",
        "City": "Naperville",
        "Year": "1989",
        "lat": "41.770535",
        "Document": "docs/alan-g-keyes.pdf"
    },
    {
        "TroopNumber": "Troop 309",
        "lng": "-119.059749",
        "State": "California",
        "NameOrID": "King, Willis Dale",
        "City": "Santa Paula",
        "Year": "1989",
        "lat": "34.35207",
        "Document": "docs/willis-dale-king.pdf"
    },
    {
        "TroopNumber": "Troop 119",
        "lng": "-119.292324",
        "State": "California",
        "NameOrID": "Kinsler, Norbert M.",
        "City": "Ventura ",
        "Year": "1989",
        "lat": "34.280825",
        "Document": "docs/norbert-m-kinsler.pdf"
    },
    {
        "TroopNumber": "Troop 400",
        "lng": "-121.885794",
        "State": "California",
        "NameOrID": "Keyes, David E.",
        "City": "San Jose",
        "Year": "1988",
        "lat": "37.338475",
        "Document": "docs/david-e-keyes.pdf"
    },
    {
        "TroopNumber": "Pack 8601",
        "lng": "-119.292324",
        "State": "California",
        "NameOrID": "Kinsler, Norbert M.",
        "City": "Ventura",
        "Year": "1989",
        "lat": "34.280825",
        "Document": "docs/norbert-m-kinsler.pdf"
    },
    {
        "TroopNumber": "Troop 216",
        "lng": "-93.735388",
        "State": "Louisiana",
        "NameOrID": "Keys, Michael Wayne",
        "City": "Bossier City",
        "Year": "1987",
        "lat": "32.515943",
        "Document": "docs/wayne-michael-keyes.pdf"
    },
    {
        "TroopNumber": "Troop 84",
        "lng": "",
        "State": "",
        "NameOrID": "Kisthart, Larry W.",
        "City": "",
        "Year": "1974",
        "lat": "",
        "Document": " docs/larry-w-kisthart.pdf"
    },
    {
        "TroopNumber": "Troop 46",
        "lng": "-93.735388",
        "State": "Louisiana",
        "NameOrID": "Keys, Michael Wayne",
        "City": "Bossier",
        "Year": "1987",
        "lat": "32.515943",
        "Document": "docs/wayne-michael-keyes.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-92.119049",
        "State": "Louisiana",
        "NameOrID": "Keys, Michael Wayne",
        "City": "Monroe",
        "Year": "1987",
        "lat": "32.50965",
        "Document": "docs/wayne-michael-keyes.pdf"
    },
    {
        "TroopNumber": "Troop 48",
        "lng": "139.595123",
        "State": "Japan",
        "NameOrID": "Keyser, Robert Norman",
        "City": "Yokohama",
        "Year": "1972",
        "lat": "35.452702",
        "Document": " docs/robert-norman-keyser.pdf"
    },
    {
        "TroopNumber": "Troop 55",
        "lng": "-84.662329",
        "State": "Kentucky",
        "NameOrID": "Kidd, Lawrence Paul",
        "City": "Wilmore",
        "Year": "1973",
        "lat": "37.86117",
        "Document": " docs/lawrence-paul-kidd.pdf"
    },
    {
        "TroopNumber": "Pack 529",
        "lng": "-74.174184",
        "State": "New Jersey",
        "NameOrID": "Kistner, Peter",
        "City": "Newark",
        "Year": "1991",
        "lat": "40.73197",
        "Document": "docs/peter-kistner.pdf"
    },
    {
        "TroopNumber": "Troop 893",
        "lng": "-97.354139",
        "State": "Kansas",
        "NameOrID": "Kieffer, Mark W.",
        "City": "Haysville",
        "Year": "1986",
        "lat": "37.564325",
        "Document": "docs/mark-w-kieffer.pdf"
    },
    {
        "TroopNumber": "Pack 270",
        "lng": "-83.369574",
        "State": "Michigan",
        "NameOrID": "Kittsteiner, Karl H.",
        "City": "Livonia",
        "Year": "1982",
        "lat": "42.39658",
        "Document": " docs/karl-h-markinson.pdf"
    },
    {
        "TroopNumber": "Pack 893",
        "lng": "-97.354139",
        "State": "Kansas",
        "NameOrID": "Kieffer, Mark W.",
        "City": "Haysville",
        "Year": "1986",
        "lat": "37.564325",
        "Document": "docs/mark-w-kieffer.pdf"
    },
    {
        "TroopNumber": "Troop 197",
        "lng": "7.86331",
        "State": "Germany",
        "NameOrID": "Klein, Steven C.",
        "City": "Bad Kreuznach",
        "Year": "1986",
        "lat": "49.843148",
        "Document": "docs/steven-c-klein.pdf"
    },
    {
        "TroopNumber": "Explorer 55",
        "lng": "-94.358679",
        "State": "Missouri",
        "NameOrID": "Kiger, Claude Orvel",
        "City": "Nevada",
        "Year": "1985",
        "lat": "37.837215",
        "Document": "docs/claude-orvel-kiger.pdf"
    },
    {
        "TroopNumber": "Troop 150",
        "lng": "-121.933029",
        "State": "California",
        "NameOrID": "Knarich, Curtis H.",
        "City": "Travis AFB",
        "Year": "1984",
        "lat": "38.271229",
        "Document": " docs/curtis-h-knarich.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-92.249385",
        "State": "Minnesota",
        "NameOrID": "Knoll, Kenneth J.",
        "City": "Elgin",
        "Year": "1991",
        "lat": "44.13024",
        "Document": "docs/kenneth-j-knoll.pdf"
    },
    {
        "TroopNumber": "Troop 107",
        "lng": "-93.220111",
        "State": "Louisiana",
        "NameOrID": "Knox, Bob",
        "City": "Lake Charles",
        "Year": "1983",
        "lat": "30.224033",
        "Document": " docs/bob-knox.pdf"
    },
    {
        "TroopNumber": "Troop 471",
        "lng": "-90.420439",
        "State": "Missouri",
        "NameOrID": "Koch, David F.",
        "City": "Farmington ",
        "Year": "1987",
        "lat": "37.780436",
        "Document": "docs/david-f-koch.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-90.420439",
        "State": "Missouri",
        "NameOrID": "Koch, David F.",
        "City": "Farmington",
        "Year": "1987",
        "lat": "37.780436",
        "Document": "docs/david-f-koch.pdf"
    },
    {
        "TroopNumber": "Troop 16",
        "lng": "-87.686589",
        "State": "Illinois",
        "NameOrID": "Koefoot, Paul Scott",
        "City": "Evanston",
        "Year": "1983",
        "lat": "42.056655",
        "Document": " docs/paul-scott-koefoot.pdf"
    },
    {
        "TroopNumber": "Troop 188",
        "lng": "-106.487494",
        "State": "Texas",
        "NameOrID": "Kral, Donald Lee",
        "City": "El Paso",
        "Year": "1991",
        "lat": "31.759165",
        "Document": "docs/donald-lee-kral.pdf"
    },
    {
        "TroopNumber": "Pack 3507",
        "lng": "-87.823654",
        "State": "Wisconsin",
        "NameOrID": "Koehler, Robert J.",
        "City": "Kenosha",
        "Year": "1989",
        "lat": "42.588075",
        "Document": "docs/robert-j-koehler.pdf"
    },
    {
        "TroopNumber": "Troop 59",
        "lng": "-106.487494",
        "State": "Texas",
        "NameOrID": "Kral, Donald Lee",
        "City": "El Paso",
        "Year": "1991",
        "lat": "31.759165",
        "Document": "docs/donald-lee-kral.pdf"
    },
    {
        "TroopNumber": "Explorer 2285",
        "lng": "-81.514059",
        "State": "Ohio",
        "NameOrID": "Kolp, Joseph P.",
        "City": "Akron",
        "Year": "1985",
        "lat": "41.084195",
        "Document": "docs/joseph-p-kolp.pdf"
    },
    {
        "TroopNumber": "Troop 193",
        "lng": "-76.907099",
        "State": "Pennsylvania",
        "NameOrID": "Kramer, George Edward",
        "City": "Lower Allen Township",
        "Year": "1972",
        "lat": "40.231085",
        "Document": " docs/george-edward-kramer.pdf"
    },
    {
        "TroopNumber": "Pack 184",
        "lng": "-82.459269",
        "State": "Florida",
        "NameOrID": "Krause, David A.",
        "City": "Tampa",
        "Year": "1988",
        "lat": "27.94653",
        "Document": "docs/david-a-krause.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "",
        "NameOrID": "Korecky, David R.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/david-r-korecky.pdf"
    },
    {
        "TroopNumber": "Troop 50",
        "lng": "-82.459269",
        "State": "Florida",
        "NameOrID": "Krause, David A.",
        "City": "Tampa",
        "Year": "1988",
        "lat": "27.94653",
        "Document": "docs/david-a-krause.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Korman, John J.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/john-j-korman.pdf"
    },
    {
        "TroopNumber": "Troop 414",
        "lng": "-84.552249",
        "State": "Michigan",
        "NameOrID": "Krauszowski, David M.",
        "City": "Lansing",
        "Year": "1987",
        "lat": "42.73194",
        "Document": "docs/david-m-krauszowski.pdf"
    },
    {
        "TroopNumber": "Troop 293",
        "lng": "-93.365864",
        "State": "Minnesota",
        "NameOrID": "Koski, Martin R.",
        "City": "Minnewasta",
        "Year": "1976",
        "lat": "46.442316",
        "Document": " docs/martin-r-koski.pdf"
    },
    {
        "TroopNumber": "Troop 831",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Krechel, Thomas D.",
        "City": "St. Louis",
        "Year": "1984",
        "lat": "38.62774",
        "Document": " docs/thomas-d-krechel.pdf"
    },
    {
        "TroopNumber": "Troop 21",
        "lng": "-76.201569",
        "State": "Pennsylvania",
        "NameOrID": "Kost, Charles A.",
        "City": "Shenandoah",
        "Year": "1982",
        "lat": "40.82045",
        "Document": " docs/charles-a-kost.pdf"
    },
    {
        "TroopNumber": "Explorer 24",
        "lng": "-117.825348",
        "State": "California",
        "NameOrID": "Kredel, Robert E.",
        "City": "Irvine",
        "Year": "1988",
        "lat": "33.686887",
        "Document": "docs/robert-e-kredel.pdf"
    },
    {
        "TroopNumber": "Explorer 16",
        "lng": "",
        "State": "",
        "NameOrID": "Kreil, William G.",
        "City": "",
        "Year": "1979",
        "lat": "",
        "Document": "docs/william-g-kreil.pdf"
    },
    {
        "TroopNumber": "Pack 888",
        "lng": "-80.255359",
        "State": "Florida",
        "NameOrID": "Kostiuk, Terry Lee",
        "City": "Stuart",
        "Year": "1991",
        "lat": "27.19999",
        "Document": "docs/terry-lee-kostiuk.pdf"
    },
    {
        "TroopNumber": "Troop 49",
        "lng": "-116.193409",
        "State": "Idaho",
        "NameOrID": "Krigbaum, Arthur N.",
        "City": "Boise",
        "Year": "1974",
        "lat": "43.60698",
        "Document": " docs/arthur-n-krigbaum.pdf"
    },
    {
        "TroopNumber": "Troop 733",
        "lng": "-82.125394",
        "State": "Florida",
        "NameOrID": "Kovatch, William J.",
        "City": "Plant City",
        "Year": "1971",
        "lat": "28.017315",
        "Document": " docs/william-j-kovatch.pdf"
    },
    {
        "TroopNumber": "Pack 3506",
        "lng": "-81.864019",
        "State": "Ohio",
        "NameOrID": "Kroyer, Douglas J.",
        "City": "Medina",
        "Year": "1990",
        "lat": "41.14032",
        "Document": "docs/douglas-j-kroyer.pdf"
    },
    {
        "TroopNumber": "Troop 505",
        "lng": "-87.995704",
        "State": "Wisconsin",
        "NameOrID": "Kowalski, Thomas H.",
        "City": "Greendale ",
        "Year": "1987",
        "lat": "42.9424",
        "Document": "docs/thomas-h-kowalski.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-71.594779",
        "State": "Massachusetts",
        "NameOrID": "Kruger, Donn W.",
        "City": "Pepperell",
        "Year": "1983",
        "lat": "42.667411",
        "Document": " docs/donn-w-kruger.pdf"
    },
    {
        "TroopNumber": "Pack 38",
        "lng": "-86.778365",
        "State": "Tennessee",
        "NameOrID": "Kozel, John Nelson",
        "City": "Nashville",
        "Year": "1989",
        "lat": "36.167783",
        "Document": "docs/john-nelson-kozel.pdf"
    },
    {
        "TroopNumber": "Troop 45",
        "lng": "-92.099849",
        "State": "Minnesota",
        "NameOrID": "Kruse, Wesley A.",
        "City": "Duluth",
        "Year": "1990",
        "lat": "46.787965",
        "Document": "docs/wesley-a-kruse.pdf"
    },
    {
        "TroopNumber": "Troop 237",
        "lng": "-103.543128",
        "State": "Colorado",
        "NameOrID": "Kracke, Nyle",
        "City": "La Junta",
        "Year": "1974",
        "lat": "37.987455",
        "Document": " docs/nyle-kracke.pdf"
    },
    {
        "TroopNumber": "Troop 117",
        "lng": "-75.820632",
        "State": "Pennsylvania",
        "NameOrID": "Kuhn, Kyle S.",
        "City": "Coatsville",
        "Year": "1989",
        "lat": "39.981942",
        "Document": "docs/kyle-s-kuhn.pdf"
    },
    {
        "TroopNumber": "Troop 432",
        "lng": "-74.043228",
        "State": "New York",
        "NameOrID": "Krajewski, Raymond",
        "City": "Jersey City",
        "Year": "1989",
        "lat": "40.7174",
        "Document": "docs/raymond-krajewski.pdf"
    },
    {
        "TroopNumber": "Troop 16",
        "lng": "-88.006644",
        "State": "Wisconsin",
        "NameOrID": "Kummer, Gerald William",
        "City": "West Allis",
        "Year": "1989",
        "lat": "43.016655",
        "Document": "docs/gerald-william-kummer.pdf"
    },
    {
        "TroopNumber": "Pack 311",
        "lng": "-82.603289",
        "State": "Ohio",
        "NameOrID": "Lambert, Brian K.",
        "City": "Lancaster",
        "Year": "1989",
        "lat": "39.713754",
        "Document": "docs/brian-k-lambert.pdf"
    },
    {
        "TroopNumber": "Troop 117",
        "lng": "-75.915919",
        "State": "Pennsylvania",
        "NameOrID": "Kunkel, James A.",
        "City": "Honey Brook",
        "Year": "1986",
        "lat": "40.093406",
        "Document": "docs/james-a-kunkel.pdf"
    },
    {
        "TroopNumber": "Troop 233",
        "lng": "-82.603289",
        "State": "Ohio",
        "NameOrID": "Lambert, Brian K.",
        "City": "Lancaster",
        "Year": "1989",
        "lat": "39.713754",
        "Document": "docs/brian-k-lambert.pdf"
    },
    {
        "TroopNumber": "Pack 100",
        "lng": "-117.261134",
        "State": "California",
        "NameOrID": "Kuper, Edward P.",
        "City": "Moreno Valley",
        "Year": "1989",
        "lat": "33.91658",
        "Document": "docs/edward-p-kuper.pdf"
    },
    {
        "TroopNumber": "Troop 34",
        "lng": "-118.472154",
        "State": "California",
        "NameOrID": "Kyle, Donald S.",
        "City": "Venice",
        "Year": "1988",
        "lat": "33.987545",
        "Document": "docs/donald-s-kyle.pdf"
    },
    {
        "TroopNumber": "Pack 4057",
        "lng": "-89.315468",
        "State": "Illinois",
        "NameOrID": "Lambert, Robert A.",
        "City": "DePue",
        "Year": "1980",
        "lat": "41.333428",
        "Document": " docs/robert-a-lambert.pdf"
    },
    {
        "TroopNumber": "Pack 226",
        "lng": "-118.001574",
        "State": "California",
        "NameOrID": "LaBadie, Al",
        "City": "Huntington Beach",
        "Year": "1987",
        "lat": "33.67889",
        "Document": "docs/al-labadie.pdf"
    },
    {
        "TroopNumber": "District 5",
        "lng": "-97.335579",
        "State": "Kansas",
        "NameOrID": "Lambert, Marvin L.",
        "City": "Wichita",
        "Year": "1972",
        "lat": "37.68698",
        "Document": " docs/marvin-l-lambert.pdf"
    },
    {
        "TroopNumber": "Troop 226",
        "lng": "-118.001574",
        "State": "California",
        "NameOrID": "LaBadie, Al",
        "City": "Huntington Beach",
        "Year": "1987",
        "lat": "33.67889",
        "Document": "docs/al-labadie.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-86.254044",
        "State": "Indiana",
        "NameOrID": "LaBeau, Gary M.",
        "City": "South Bend",
        "Year": "1971",
        "lat": "41.679058",
        "Document": " docs/gary-m-labeau.pdf"
    },
    {
        "TroopNumber": "66",
        "lng": "-78.397087",
        "State": "New York",
        "NameOrID": "Lamkin, LaVerne S.",
        "City": "Indian Falls",
        "Year": "1977",
        "lat": "43.028629",
        "Document": " docs/laverne-s-lamkin.pdf"
    },
    {
        "TroopNumber": "Troop 25",
        "lng": "",
        "State": "",
        "NameOrID": "Lamont, Charles A.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/charles-a-lamont.pdf"
    },
    {
        "TroopNumber": "Troop 354",
        "lng": "-111.324678",
        "State": "Arizona",
        "NameOrID": "LaBelle, Kenneth R.",
        "City": "Payson",
        "Year": "1990",
        "lat": "34.231321",
        "Document": "docs/kenneth-r-labelle.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Lane, Jerry W.",
        "City": "Dallas",
        "Year": "1977",
        "lat": "32.778155",
        "Document": " docs/jerry-w-lane.pdf"
    },
    {
        "TroopNumber": "Troop 19",
        "lng": "-72.075709",
        "State": "Connecticut",
        "NameOrID": "LaBrecque, John Paul",
        "City": "Norwich",
        "Year": "1972",
        "lat": "41.526564",
        "Document": " docs/john-paul-labrecque.pdf"
    },
    {
        "TroopNumber": "Pack 87",
        "lng": "-71.13124",
        "State": "Massachusetts",
        "NameOrID": "Lachapelle, Richard A.",
        "City": "North Andover",
        "Year": "1989",
        "lat": "42.703095",
        "Document": "docs/richard-a-lachapelle.pdf"
    },
    {
        "TroopNumber": "Troop 465",
        "lng": "-96.959819",
        "State": "Texas",
        "NameOrID": "Langford, Calvin R.",
        "City": "Pilot Point",
        "Year": "1974",
        "lat": "33.396665",
        "Document": " docs/calvin-r-langford.pdf"
    },
    {
        "TroopNumber": "Troop 87",
        "lng": "-71.13124",
        "State": "Massachusetts",
        "NameOrID": "Lachapelle, Richard A.",
        "City": "North Andover",
        "Year": "1989",
        "lat": "42.703095",
        "Document": "docs/richard-a-lachapelle.pdf"
    },
    {
        "TroopNumber": "Troop 311",
        "lng": "-75.507637",
        "State": "Virginia",
        "NameOrID": "Langley, William Lawrence",
        "City": "Atlantic",
        "Year": "1988",
        "lat": "37.900131",
        "Document": "docs/william-lawrence-langley.pdf"
    },
    {
        "TroopNumber": "Troop 304",
        "lng": "-89.587764",
        "State": "Illinois",
        "NameOrID": "Lankton, James R.",
        "City": "Peoria",
        "Year": "1981",
        "lat": "40.69214",
        "Document": " docs/james-r-lankton.pdf"
    },
    {
        "TroopNumber": "Troop 355",
        "lng": "-118.245319",
        "State": "California",
        "NameOrID": "Lachica, Joel",
        "City": "Los Angeles",
        "Year": "1985",
        "lat": "34.05349",
        "Document": "docs/joel-lachica.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Lantz, Kenneth Lee",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/kenneth-lee-lantz.pdf"
    },
    {
        "TroopNumber": "Troop 24",
        "lng": "-78.034551",
        "State": "New York",
        "NameOrID": "Ladison, Charles E.",
        "City": "Belmont",
        "Year": "1982",
        "lat": "42.223226",
        "Document": " docs/charles-e-ladison.pdf"
    },
    {
        "TroopNumber": "Pack 2",
        "lng": "",
        "State": "",
        "NameOrID": "LaRoche, Michael James",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": "docs/michael-james-laroche.pdf"
    },
    {
        "TroopNumber": "Troop 15",
        "lng": "-76.285069",
        "State": "Virginia",
        "NameOrID": "Ladison, Charles E.",
        "City": "Norfolk",
        "Year": "1982",
        "lat": "36.846825",
        "Document": " docs/charles-e-ladison.pdf"
    },
    {
        "TroopNumber": "Troop 115",
        "lng": "",
        "State": "Utah",
        "NameOrID": "Larsen, Michael L.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/michael-l-larsen.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-91.671024",
        "State": "Wisconsin",
        "NameOrID": "Laehn, Ronald J.",
        "City": "Mondovi",
        "Year": "1984",
        "lat": "44.56786",
        "Document": " docs/ronald-j-laehn.pdf"
    },
    {
        "TroopNumber": "Troop 91",
        "lng": "-88.197385",
        "State": "Wisconsin",
        "NameOrID": "Larsen, Allen Gorden",
        "City": "Oneida",
        "Year": "1972",
        "lat": "44.501683",
        "Document": " docs/allen-gorden-larsen.pdf"
    },
    {
        "TroopNumber": "Troop 875",
        "lng": "-96.636554",
        "State": "Texas",
        "NameOrID": "Lassman, Jeffrey M.",
        "City": "Garland",
        "Year": "1972",
        "lat": "32.913865",
        "Document": " docs/jeffrey-m-lassman.pdf"
    },
    {
        "TroopNumber": "Troop 87",
        "lng": "-76.174824",
        "State": "New York",
        "NameOrID": "Latham, Richard William",
        "City": "Cortland",
        "Year": "1971",
        "lat": "42.603495",
        "Document": " docs/richard-william-latham.pdf"
    },
    {
        "TroopNumber": "Troop 452",
        "lng": "-99.68095",
        "State": "Nebraska",
        "NameOrID": "Latham, Richard William",
        "City": "Offutt AFB",
        "Year": "1971",
        "lat": "41.500755",
        "Document": " docs/richard-william-latham.pdf"
    },
    {
        "TroopNumber": "Troop 88",
        "lng": "-80.237419",
        "State": "Florida",
        "NameOrID": "Laurita, Joseph A.",
        "City": "Miami",
        "Year": "1983",
        "lat": "25.728985",
        "Document": " docs/joseph-a-laurita.pdf"
    },
    {
        "TroopNumber": "Troop 20",
        "lng": "-122.270539",
        "State": "California",
        "NameOrID": "Leavitt, George William",
        "City": "Berkeley",
        "Year": "1973",
        "lat": "37.869885",
        "Document": " docs/george-william-leavitt.pdf"
    },
    {
        "TroopNumber": "Explorer 2743",
        "lng": "-72.644119",
        "State": "Connecticut",
        "NameOrID": "Lavoie, John P.",
        "City": "East Hartford",
        "Year": "1989",
        "lat": "41.768805",
        "Document": "docs/john-p-lavoie.pdf"
    },
    {
        "TroopNumber": "Troop 263",
        "lng": "-81.690434",
        "State": "Ohio",
        "NameOrID": "Leckler, Kurt W.",
        "City": "Cleveland",
        "Year": "1986",
        "lat": "41.50438",
        "Document": "docs/kurt-w-leckler.pdf"
    },
    {
        "TroopNumber": "Explorer 2740",
        "lng": "-74.011314",
        "State": "New Jersey",
        "NameOrID": "Lawlor, James",
        "City": "Ocean",
        "Year": "1989",
        "lat": "40.37799",
        "Document": "docs/james-lawlor.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-122.285219",
        "State": "California",
        "NameOrID": "Lawrence, Charles Edward",
        "City": "Napa",
        "Year": "1988",
        "lat": "38.29886",
        "Document": "docs/charles-edward-lawrence.pdf"
    },
    {
        "TroopNumber": "Troop 9",
        "lng": "-92.445649",
        "State": "Louisiana",
        "NameOrID": "Ledezma, Alberto J.",
        "City": "Alexandria",
        "Year": "1979",
        "lat": "31.312685",
        "Document": "docs/alberto-j-ledezma.pdf"
    },
    {
        "TroopNumber": "Troop 24",
        "lng": "-122.285219",
        "State": "California",
        "NameOrID": "Lawrence, Charles Edward",
        "City": "Napa",
        "Year": "1988",
        "lat": "38.29886",
        "Document": "docs/charles-edward-lawrence.pdf"
    },
    {
        "TroopNumber": "Troop 1346",
        "lng": "-77.272279",
        "State": "Virginia",
        "NameOrID": "Lawrence, Richard E.",
        "City": "Burke",
        "Year": "1986",
        "lat": "38.793234",
        "Document": "docs/richard-e-lawrence.pdf"
    },
    {
        "TroopNumber": "Troop 4",
        "lng": "-92.445649",
        "State": "Louisiana",
        "NameOrID": "Ledezma, Alberto J.",
        "City": "Alexandria",
        "Year": "1979",
        "lat": "31.312685",
        "Document": "docs/alberto-j-ledezma.pdf"
    },
    {
        "TroopNumber": "Troop 27",
        "lng": "-103.825069",
        "State": "South Dakota",
        "NameOrID": "Lawrence, Raymond G.W.",
        "City": "Edgemont",
        "Year": "1990",
        "lat": "43.301355",
        "Document": "docs/raymond-gw-lawrence.pdf"
    },
    {
        "TroopNumber": "Pack 41",
        "lng": "-88.176314",
        "State": "Illinois",
        "NameOrID": "Lee, Lester D.",
        "City": "Charleston",
        "Year": "1989",
        "lat": "39.495975",
        "Document": "docs/lester-d-lee.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Lawrence, Christopher",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/christopher-lawrence.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Lee, Richard D.",
        "City": "",
        "Year": "1973",
        "lat": "",
        "Document": " docs/richard-d-lee.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Laws, Don",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/don-laws.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Lee, Michael",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/michael-lee.pdf"
    },
    {
        "TroopNumber": "Troop 22",
        "lng": "-71.411994",
        "State": "Rhode Island",
        "NameOrID": "Lazzareschi, William",
        "City": "Providence",
        "Year": "1988",
        "lat": "41.823875",
        "Document": "docs/william-lazzareschi.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Leight, Cletus",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/cletus-leight.pdf"
    },
    {
        "TroopNumber": "Pack 10",
        "lng": "-71.498794",
        "State": "Rhode Island",
        "NameOrID": "Lazzareschi, William",
        "City": "Johnston",
        "Year": "1988",
        "lat": "41.82389",
        "Document": "docs/william-lazzareschi.pdf"
    },
    {
        "TroopNumber": "Explorer 131",
        "lng": "",
        "State": "",
        "NameOrID": "Leininger, William H.",
        "City": "",
        "Year": "1976",
        "lat": "",
        "Document": " docs/william-h-leininger.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-71.702424",
        "State": "Rhode Island",
        "NameOrID": "Leach, Henry",
        "City": "Pascoag",
        "Year": "1988",
        "lat": "41.955692",
        "Document": "docs/henry-leach.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "Pennsylvania",
        "NameOrID": "Leiter, Harold E.",
        "City": "",
        "Year": "1976",
        "lat": "",
        "Document": "docs/harold-e-leiter.pdf"
    },
    {
        "TroopNumber": "Troop 19",
        "lng": "",
        "State": "",
        "NameOrID": "Leland, Edward L.",
        "City": "",
        "Year": "1976",
        "lat": "",
        "Document": " docs/edward-l-leland.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-71.778702",
        "State": "Rhode Island",
        "NameOrID": "Leach, Henry",
        "City": "Hopkinton",
        "Year": "1988",
        "lat": "41.457465",
        "Document": "docs/henry-leach.pdf"
    },
    {
        "TroopNumber": "District 8603",
        "lng": "-117.412279",
        "State": "Washington",
        "NameOrID": "Lemley, Robert C.",
        "City": "Spokane",
        "Year": "1990",
        "lat": "47.65726",
        "Document": "docs/robert-c-lemley.pdf"
    },
    {
        "TroopNumber": "Troop 400",
        "lng": "-93.741149",
        "State": "Missouri",
        "NameOrID": "Leahy, Jonathan F.",
        "City": "Warrensburg",
        "Year": "1988",
        "lat": "38.771224",
        "Document": "docs/jonathan-f-leahy.pdf"
    },
    {
        "TroopNumber": "Pack 13",
        "lng": "-90.148129",
        "State": "Illinois",
        "NameOrID": "Lemp, James Michael",
        "City": "Granite City",
        "Year": "1987",
        "lat": "38.702155",
        "Document": "docs/james-michael-lemp.pdf"
    },
    {
        "TroopNumber": "Troop 525",
        "lng": "-122.671559",
        "State": "Washington",
        "NameOrID": "Leppard, Ronald David",
        "City": "Vancouver",
        "Year": "1986",
        "lat": "45.632215",
        "Document": "docs/ronald-david-leppard.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-90.148129",
        "State": "Illinois",
        "NameOrID": "Lemp, James Michael",
        "City": "Granite City",
        "Year": "1987",
        "lat": "38.702155",
        "Document": "docs/james-michael-lemp.pdf"
    },
    {
        "TroopNumber": "Troop 4",
        "lng": "-84.786769",
        "State": "Michigan",
        "NameOrID": "Lesh, David G.",
        "City": "Alanson",
        "Year": "1990",
        "lat": "45.443985",
        "Document": "docs/david-g-lesh.pdf"
    },
    {
        "TroopNumber": "Troop 317",
        "lng": "-123.087434",
        "State": "Washington",
        "NameOrID": "Letteer, Mark",
        "City": "Rochester",
        "Year": "1989",
        "lat": "46.82175",
        "Document": "docs/mark-letteer.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-87.413229",
        "State": "Indiana",
        "NameOrID": "Lenwell, John",
        "City": "Terre Haute",
        "Year": "1990",
        "lat": "39.466495",
        "Document": "docs/john-lenwell.pdf"
    },
    {
        "TroopNumber": "Troop 555",
        "lng": "-151.544229",
        "State": "Alaska",
        "NameOrID": "Lewis, Jerry Wayne",
        "City": "Homer",
        "Year": "1984",
        "lat": "59.64656",
        "Document": " docs/jerry-wayne-lewis.pdf"
    },
    {
        "TroopNumber": "Pack 555",
        "lng": "-151.544229",
        "State": "Alaska",
        "NameOrID": "Lewis, Jerry Wayne",
        "City": "Homer",
        "Year": "1984",
        "lat": "59.64656",
        "Document": " docs/jerry-wayne-lewis.pdf"
    },
    {
        "TroopNumber": "Troop 230",
        "lng": "-106.593682",
        "State": "Colorado",
        "NameOrID": "Lister, Joe A.",
        "City": "Edwards",
        "Year": "1980",
        "lat": "39.644372",
        "Document": " docs/joe-a-lister.pdf"
    },
    {
        "TroopNumber": "Pack 103",
        "lng": "-79.437914",
        "State": "North Carolina",
        "NameOrID": "Lewis, Thomas Benton",
        "City": "Burlington",
        "Year": "1982",
        "lat": "36.096865",
        "Document": " docs/thomas-benton-lewis.pdf"
    },
    {
        "TroopNumber": "Troop 103",
        "lng": "-79.437914",
        "State": "North Carolina",
        "NameOrID": "Lewis, Thomas Benton",
        "City": "Burlington",
        "Year": "1982",
        "lat": "36.096865",
        "Document": " docs/thomas-benton-lewis.pdf"
    },
    {
        "TroopNumber": "Troop 638",
        "lng": "-87.217239",
        "State": "Florida",
        "NameOrID": "Liter, David C.",
        "City": "Pensacola",
        "Year": "1989",
        "lat": "30.420845",
        "Document": "docs/david-c-liter.pdf"
    },
    {
        "TroopNumber": "Explorer 7",
        "lng": "-73.941026",
        "State": "New York",
        "NameOrID": "Lewis, James R.",
        "City": "Schenectady",
        "Year": "1988",
        "lat": "42.81226",
        "Document": "docs/james-r-lewis.pdf"
    },
    {
        "TroopNumber": "Pack 25",
        "lng": "-88.416564",
        "State": "Wisconsin",
        "NameOrID": "Litscher, Jerome C.",
        "City": "Appleton",
        "Year": "1988",
        "lat": "44.26178",
        "Document": "docs/jerome-c-litscher.pdf"
    },
    {
        "TroopNumber": "Explorer 1",
        "lng": "-73.941026",
        "State": "New York",
        "NameOrID": "Lewis, James R.",
        "City": "Schenectady",
        "Year": "1988",
        "lat": "42.81226",
        "Document": "docs/james-r-lewis.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-88.416564",
        "State": "Wisconsin",
        "NameOrID": "Litscher, Jerome C.",
        "City": "Appleton",
        "Year": "1988",
        "lat": "44.26178",
        "Document": "docs/jerome-c-litscher.pdf"
    },
    {
        "TroopNumber": "329",
        "lng": "-84.256541",
        "State": "Tennessee",
        "NameOrID": "Lewis, James B.",
        "City": "Oak Ridge",
        "Year": "1986",
        "lat": "36.014536",
        "Document": "docs/james-b-lewis.pdf"
    },
    {
        "TroopNumber": "Troop 243",
        "lng": "-71.408704",
        "State": "New Hampshire",
        "NameOrID": "Litteer, Robert",
        "City": "Gilford",
        "Year": "1985",
        "lat": "43.546453",
        "Document": "docs/robert-litteer.pdf"
    },
    {
        "TroopNumber": "Pack 309",
        "lng": "-94.226174",
        "State": "Missouri",
        "NameOrID": "Lewis, William",
        "City": "Excelsior Springs",
        "Year": "1984",
        "lat": "39.33921",
        "Document": " docs/william-lewis.pdf"
    },
    {
        "TroopNumber": "Pack 382",
        "lng": "-86.584979",
        "State": "Alabama",
        "NameOrID": "Little, Daniel Ray",
        "City": "Huntsville",
        "Year": "1987",
        "lat": "34.729135",
        "Document": "docs/daniel-ray-little.pdf"
    },
    {
        "TroopNumber": "Troop 30",
        "lng": "-85.713914",
        "State": "Alabama",
        "NameOrID": "Lewis, Ricky L.",
        "City": "Daleville",
        "Year": "1988",
        "lat": "31.30706",
        "Document": "docs/ricky-l-lewis.pdf"
    },
    {
        "TroopNumber": "Troop 203",
        "lng": "-80.055032",
        "State": "Florida",
        "NameOrID": "Loch, Michael J.",
        "City": "Lantana",
        "Year": "1989",
        "lat": "26.576871",
        "Document": "docs/michael-j-loch.pdf"
    },
    {
        "TroopNumber": "3169",
        "lng": "-115.139974",
        "State": "Nevada",
        "NameOrID": "Lieberman, Richard P.",
        "City": "Las Vegas",
        "Year": "1990",
        "lat": "36.171915",
        "Document": "docs/richard-p-lieberman.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-76.163034",
        "State": "Maryland",
        "NameOrID": "Lilley, Joseph K.",
        "City": "Aberdeen",
        "Year": "1973",
        "lat": "39.51046",
        "Document": " docs/joseph-k-lilley.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Lokken, Scott",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": "docs/scott-lokken.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Long, Lauri B.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/lauri-b-long.pdf"
    },
    {
        "TroopNumber": "Troop 444",
        "lng": "-97.329389",
        "State": "Texas",
        "NameOrID": "Lynch, Kenneth R.",
        "City": "Ft. Worth",
        "Year": "1976",
        "lat": "32.748855",
        "Document": " docs/kenneth-r-lynch.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "",
        "State": "",
        "NameOrID": "Longo, Robert John",
        "City": "",
        "Year": "1986",
        "lat": "",
        "Document": "docs/robert-john-longo.pdf"
    },
    {
        "TroopNumber": "Troop 59",
        "lng": "-94.405969",
        "State": "Missouri",
        "NameOrID": "Lyon, Paul K.",
        "City": "Goodman ",
        "Year": "1988",
        "lat": "36.739495",
        "Document": "docs/paul-k-lyon.pdf"
    },
    {
        "TroopNumber": "Troop 576",
        "lng": "-122.025234",
        "State": "Washington",
        "NameOrID": "Loomis, Harold Wayne",
        "City": "Buckley",
        "Year": "1988",
        "lat": "47.16299",
        "Document": "docs/harold-wayne-loomis.pdf"
    },
    {
        "TroopNumber": "Troop 101",
        "lng": "-71.718185",
        "State": "Massachusetts",
        "NameOrID": "MacDonald, Brian",
        "City": "Hansen",
        "Year": "1980",
        "lat": "42.062449",
        "Document": " docs/brian-macdonald.pdf"
    },
    {
        "TroopNumber": "Pack 9",
        "lng": "-154.565033",
        "State": "Alaska",
        "NameOrID": "Lopardo, Joseph R.",
        "City": "Eiselsen AFB",
        "Year": "1987",
        "lat": "61.325384",
        "Document": "docs/joseph-r-lopardo.pdf"
    },
    {
        "TroopNumber": "Troop 34",
        "lng": "-71.718185",
        "State": "Massachusetts",
        "NameOrID": "MacDonald, Brian",
        "City": "Hansen",
        "Year": "1980",
        "lat": "42.062449",
        "Document": " docs/brian-macdonald.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-111.930706",
        "State": "Arizona",
        "NameOrID": "Lopardo, Joseph R.",
        "City": "Yacca Council",
        "Year": "1987",
        "lat": "34.167874",
        "Document": "docs/joseph-r-lopardo.pdf"
    },
    {
        "TroopNumber": "Troop 229",
        "lng": "-104.992259",
        "State": "Colorado",
        "NameOrID": "Lopez, Gregory",
        "City": "Denver",
        "Year": "1990",
        "lat": "39.74001",
        "Document": "docs/gregory-lopez.pdf"
    },
    {
        "TroopNumber": "Pack 11",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "MacGregor, George W.",
        "City": "Worcester",
        "Year": "1978",
        "lat": "42.263415",
        "Document": "docs/george-w-macgregor.pdf"
    },
    {
        "TroopNumber": "Troop 177",
        "lng": "-98.494619",
        "State": "Texas",
        "NameOrID": "Lovell, Dennis",
        "City": "San Antonio",
        "Year": "1991",
        "lat": "29.42449",
        "Document": "docs/dennis-lovell.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "MacGregor, George W.",
        "City": "Worcester",
        "Year": "1978",
        "lat": "42.263415",
        "Document": "docs/george-w-macgregor.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Mackey, John Michael",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/john-michael-mackey.pdf"
    },
    {
        "TroopNumber": "Troop 148",
        "lng": "-97.794259",
        "State": "Texas",
        "NameOrID": "Lowry, David P.",
        "City": "Granbury",
        "Year": "1990",
        "lat": "32.44235",
        "Document": "docs/david-p-lowry.pdf"
    },
    {
        "TroopNumber": "548",
        "lng": "-69.967784",
        "State": "Maine",
        "NameOrID": "MacLeod, James A.",
        "City": "Brunswick",
        "Year": "1988",
        "lat": "43.913955",
        "Document": "docs/james-a-macleod.pdf"
    },
    {
        "TroopNumber": "Troop 226",
        "lng": "-121.491014",
        "State": "California",
        "NameOrID": "Maddock, Terrance J.",
        "City": "Sacramento ",
        "Year": "1985",
        "lat": "38.579065",
        "Document": "docs/terrance-j-maddock.pdf"
    },
    {
        "TroopNumber": "Troop 31",
        "lng": "-79.399709",
        "State": "Maryland",
        "NameOrID": "Lucas, Randy R.",
        "City": "Oakland",
        "Year": "1991",
        "lat": "39.404335",
        "Document": "docs/randy-r-lucas.pdf"
    },
    {
        "TroopNumber": "Troop 21",
        "lng": "-111.306074",
        "State": "Montana",
        "NameOrID": "Maddox, Roger S.",
        "City": "Great Falls",
        "Year": "1987",
        "lat": "47.507145",
        "Document": "docs/roger-s-maddox.pdf"
    },
    {
        "TroopNumber": "Explorer 9681",
        "lng": "-90.495629",
        "State": "Iowa",
        "NameOrID": "Lundell, David E.",
        "City": "Bettendorf",
        "Year": "1987",
        "lat": "41.5259",
        "Document": "docs/david-e-lundell.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Maddox, Bruce W.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/bruce-w-maddox.pdf"
    },
    {
        "TroopNumber": "Troop 456",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Lundy, Richard J.",
        "City": "Dallas",
        "Year": "1980",
        "lat": "32.778155",
        "Document": " docs/richard-j-lundy.pdf"
    },
    {
        "TroopNumber": "Troop 26",
        "lng": "",
        "State": "",
        "NameOrID": "Madigan, Glen A.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/glen-a-madigan.pdf"
    },
    {
        "TroopNumber": "Pack 124",
        "lng": "-97.411064",
        "State": "Nebraska",
        "NameOrID": "Lurz, Hank W.",
        "City": "Norfolk",
        "Year": "1989",
        "lat": "42.0327",
        "Document": "docs/hank-w-lurz.pdf"
    },
    {
        "TroopNumber": "Troop 221",
        "lng": "-84.200519",
        "State": "Ohio",
        "NameOrID": "Maegly, John E.",
        "City": "Kalida",
        "Year": "1988",
        "lat": "40.98249",
        "Document": "docs/john-e-maegly.pdf"
    },
    {
        "TroopNumber": "294",
        "lng": "-82.638764",
        "State": "Florida",
        "NameOrID": "Lutz, Roger E.",
        "City": "St. Petersburg",
        "Year": "1985",
        "lat": "27.77119",
        "Document": "docs/roger-e-lutz.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Magar, Dewey Eugene",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": "docs/dewey-eugene-magar.pdf"
    },
    {
        "TroopNumber": "Troop 51",
        "lng": "-71.312844",
        "State": "Massachusetts",
        "NameOrID": "Maitland, Frederick R.",
        "City": "Lowell",
        "Year": "1983",
        "lat": "42.64589",
        "Document": " docs/frederick-r-maitland.pdf"
    },
    {
        "TroopNumber": "Troop 449",
        "lng": "-95.582419",
        "State": "Oklahoma",
        "NameOrID": "Magreevy, William D.",
        "City": "Eufaula",
        "Year": "1979",
        "lat": "35.287855",
        "Document": "docs/william-d-magreevy.pdf"
    },
    {
        "TroopNumber": "Troop 115",
        "lng": "-80.138899",
        "State": "Florida",
        "NameOrID": "Maitre, William J.",
        "City": "Wilton Manors",
        "Year": "1983",
        "lat": "26.154675",
        "Document": " docs/william-j-maitre.pdf"
    },
    {
        "TroopNumber": "Troop 26",
        "lng": "-80.786736",
        "State": "Ohio",
        "NameOrID": "Magreevy, William D.",
        "City": "Ashtabula",
        "Year": "1979",
        "lat": "41.889273",
        "Document": "docs/william-d-magreevy.pdf"
    },
    {
        "TroopNumber": "Troop 119",
        "lng": "-73.622794",
        "State": "New York",
        "NameOrID": "Malone, Matthew",
        "City": "Hempstead",
        "Year": "1971",
        "lat": "40.70991",
        "Document": " docs/matthew-malone.pdf"
    },
    {
        "TroopNumber": "Pack 60",
        "lng": "-86.300629",
        "State": "Alabama",
        "NameOrID": "Marshall, John",
        "City": "Montgomery",
        "Year": "1989",
        "lat": "32.38012",
        "Document": "docs/john-marshall.pdf"
    },
    {
        "TroopNumber": "Troop 270",
        "lng": "-73.923249",
        "State": "New York",
        "NameOrID": "Mancuso, Patrick A.",
        "City": "Bronx",
        "Year": "1977",
        "lat": "40.825745",
        "Document": " docs/patrick-a-mancuso.pdf"
    },
    {
        "TroopNumber": "Pack ",
        "lng": "-73.348353",
        "State": "New York",
        "NameOrID": "Maniscalco, Leonard",
        "City": "West Babylon",
        "Year": "1988",
        "lat": "40.701239",
        "Document": "docs/leonard-maniscalco.pdf"
    },
    {
        "TroopNumber": "Troop 132",
        "lng": "-73.981594",
        "State": "New Jersey",
        "NameOrID": "Martginetti, Anthony J.",
        "City": "Old Tappan",
        "Year": "1988",
        "lat": "41.014055",
        "Document": "docs/anthony-j-martginetti.pdf"
    },
    {
        "TroopNumber": "Explorer 97",
        "lng": "-80.204074",
        "State": "Florida",
        "NameOrID": "Manley, Mark",
        "City": "Margate",
        "Year": "1984",
        "lat": "26.241835",
        "Document": " docs/mark-manley.pdf"
    },
    {
        "TroopNumber": "Troop 123",
        "lng": "-93.747274",
        "State": "Louisiana",
        "NameOrID": "Martin, Robert A.",
        "City": "Shreveport",
        "Year": "1989",
        "lat": "32.514615",
        "Document": " docs/robert-a-martin.pdf"
    },
    {
        "TroopNumber": "Pack 31",
        "lng": "-79.399709",
        "State": "Maryland",
        "NameOrID": "Manning, Roberta M.",
        "City": "Oakland",
        "Year": "1991",
        "lat": "39.404335",
        "Document": "docs/roberta-m-manning.pdf"
    },
    {
        "TroopNumber": "Troop 15",
        "lng": "-93.747274",
        "State": "Louisiana",
        "NameOrID": "Martin, Robert A.",
        "City": "Shreveport ",
        "Year": "1989",
        "lat": "32.514615",
        "Document": " docs/robert-a-martin.pdf"
    },
    {
        "TroopNumber": "Troop 31",
        "lng": "-79.399709",
        "State": "Maryland",
        "NameOrID": "Manning, Donald W.",
        "City": "Oakland",
        "Year": "1991",
        "lat": "39.404335",
        "Document": "docs/donald-w-manning.pdf"
    },
    {
        "TroopNumber": "Troop 410",
        "lng": "-111.888229",
        "State": "Utah",
        "NameOrID": "Martin, David W.",
        "City": "Salt Lake City",
        "Year": "1988",
        "lat": "40.759505",
        "Document": "docs/david-w-martin.pdf"
    },
    {
        "TroopNumber": "Troop 3020",
        "lng": "-147.722059",
        "State": "Alaska",
        "NameOrID": "Mapes, Jack C.",
        "City": "Fairbanks",
        "Year": "1986",
        "lat": "64.845085",
        "Document": "docs/jack-c-mapes.pdf"
    },
    {
        "TroopNumber": "Troop 280",
        "lng": "-72.644119",
        "State": "Connecticut",
        "NameOrID": "Martin, David C.",
        "City": "East Hartford",
        "Year": "1984",
        "lat": "41.768805",
        "Document": "docs/david-c-martin.pdf"
    },
    {
        "TroopNumber": "Pack 3",
        "lng": "-71.153276",
        "State": "Massachusetts",
        "NameOrID": "Mares, Manuel A.",
        "City": "Brighton",
        "Year": "1985",
        "lat": "42.349647",
        "Document": "docs/manuel-a-mares.pdf"
    },
    {
        "TroopNumber": "Troop 30",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Margrave, Gerald E.",
        "City": "Rockford",
        "Year": "1985",
        "lat": "42.2669",
        "Document": "docs/gerald-e-margrave.pdf"
    },
    {
        "TroopNumber": "Troop 4",
        "lng": "-76.301269",
        "State": "Pennsylvania",
        "NameOrID": "Martindale, William Ben",
        "City": "Lancaster",
        "Year": "1987",
        "lat": "40.038045",
        "Document": "docs/william-ben-martindale.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-122.532474",
        "State": "California",
        "NameOrID": "Martinelli, Roderick Patrick",
        "City": "San Rafael",
        "Year": "1990",
        "lat": "37.97457",
        "Document": "docs/roderick-patrick-martinelli.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Martinez, Joe A.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/joe-a-martinez.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-122.473349",
        "State": "California",
        "NameOrID": "Markinson, Craig",
        "City": "Daly City",
        "Year": "1982",
        "lat": "37.691085",
        "Document": " docs/craig-markinson.pdf"
    },
    {
        "TroopNumber": "Troop 1631",
        "lng": "-95.599454",
        "State": "Texas",
        "NameOrID": "Martinez, Joe A.",
        "City": "Sugar Land",
        "Year": "1987",
        "lat": "29.632965",
        "Document": "docs/joe-a-martinez.pdf"
    },
    {
        "TroopNumber": "Pack 101",
        "lng": "-96.961184",
        "State": "Nebraska",
        "NameOrID": "Marshall, David Alan",
        "City": "Wilber",
        "Year": "1990",
        "lat": "40.48135",
        "Document": "docs/david-alan-marshall.pdf"
    },
    {
        "TroopNumber": "Troop 40",
        "lng": "-108.055299",
        "State": "Wyoming",
        "NameOrID": "Martinez, Luciano Ernest",
        "City": "Greybull",
        "Year": "1971",
        "lat": "44.488555",
        "Document": " docs/luciano-ernest-martinez.pdf"
    },
    {
        "TroopNumber": "Pack 371",
        "lng": "-83.002984",
        "State": "Ohio",
        "NameOrID": "Mason, Richard",
        "City": "Columbus",
        "Year": "1990",
        "lat": "39.96196",
        "Document": "docs/richard-mason.pdf"
    },
    {
        "TroopNumber": "Troop 34",
        "lng": "-108.039709",
        "State": "Wyoming",
        "NameOrID": "Martinez, Luciano Ernest",
        "City": "Basin",
        "Year": "1971",
        "lat": "44.38111",
        "Document": " docs/luciano-ernest-martinez.pdf"
    },
    {
        "TroopNumber": "Troop 975",
        "lng": "-92.829579",
        "State": "Arkansas",
        "NameOrID": "Massey, Leonard R.",
        "City": "Camden",
        "Year": "1989",
        "lat": "33.58698",
        "Document": "docs/leonard-r-massey.pdf"
    },
    {
        "TroopNumber": "Pack 132",
        "lng": "",
        "State": "",
        "NameOrID": "Martinez, Antonio",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/antonio-martinez.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Massey, Carroll",
        "City": "",
        "Year": "1983",
        "lat": "",
        "Document": " docs/carroll-massey.pdf"
    },
    {
        "TroopNumber": "Troop 781",
        "lng": "-89.579164",
        "State": "Illinois",
        "NameOrID": "Martz, Keith A.",
        "City": "Forreston",
        "Year": "1991",
        "lat": "42.12556",
        "Document": "docs/keith-a-martz.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-87.869564",
        "State": "Illinois",
        "NameOrID": "Matheny, Donald W.",
        "City": "La Grange",
        "Year": "1989",
        "lat": "41.813255",
        "Document": "docs/donald-w-matheny.pdf"
    },
    {
        "TroopNumber": "Troop 77",
        "lng": "-71.136494",
        "State": "Massachusetts",
        "NameOrID": "Mathews, Robert",
        "City": "Andover",
        "Year": "1989",
        "lat": "42.653565",
        "Document": "docs/robert-mathews.pdf"
    },
    {
        "TroopNumber": "Troop 315",
        "lng": "-104.818169",
        "State": "Colorado",
        "NameOrID": "McArthur, Theodore",
        "City": "Brighton",
        "Year": "1990",
        "lat": "39.986685",
        "Document": "docs/theodore-mcarthur.pdf"
    },
    {
        "TroopNumber": "Troop 513",
        "lng": "-118.095941",
        "State": "Oregon",
        "NameOrID": "Mathias, Franklin Leon",
        "City": "La Grande",
        "Year": "1987",
        "lat": "45.329545",
        "Document": "docs/franklin-leon-mathias.pdf"
    },
    {
        "TroopNumber": "Troop 516",
        "lng": "-118.095941",
        "State": "Oregon",
        "NameOrID": "Mathias, Franklin Leon",
        "City": "La Grande",
        "Year": "1987",
        "lat": "45.329545",
        "Document": "docs/franklin-leon-mathias.pdf"
    },
    {
        "TroopNumber": "Troop 66",
        "lng": "-115.555174",
        "State": "Montana",
        "NameOrID": "McBride, John D.",
        "City": "Libby",
        "Year": "1977",
        "lat": "48.388105",
        "Document": " docs/john-d-mcbride.pdf"
    },
    {
        "TroopNumber": "Troop 3204",
        "lng": "-83.924534",
        "State": "Georgia",
        "NameOrID": "Mattice, Kenneth Edwin",
        "City": "Flowery Branch ",
        "Year": "1991",
        "lat": "34.184895",
        "Document": "docs/kenneth-edwin-mattice.pdf"
    },
    {
        "TroopNumber": "Troop 386",
        "lng": "-71.411994",
        "State": "Rhode Island",
        "NameOrID": "McCaffrey, Elmer E.",
        "City": "Providence",
        "Year": "1991",
        "lat": "41.823875",
        "Document": "docs/elmer-e-mccaffrey.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Mattice-Walters, Pattie Ann",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/pattie-ann-mattice-walters.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "McCallie, Ernest L.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/ernest-l-mccallie.pdf"
    },
    {
        "TroopNumber": "Troop 81",
        "lng": "-122.611734",
        "State": "Washington",
        "NameOrID": "Mauch, Michael S.",
        "City": "Anacortes",
        "Year": "1981",
        "lat": "48.517485",
        "Document": " docs/michael-s-mauch.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-72.745373",
        "State": "Connecticut",
        "NameOrID": "Maule, David P.",
        "City": "Berlin",
        "Year": "1987",
        "lat": "41.622506",
        "Document": "docs/david-p-maule.pdf"
    },
    {
        "TroopNumber": "Pack 2391",
        "lng": "-112.063794",
        "State": "Utah",
        "NameOrID": "Maxfield, Norman",
        "City": "Clinton",
        "Year": "1991",
        "lat": "41.13988",
        "Document": "docs/norman-maxfield.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Maxson, Bradley",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/bradley-maxson.pdf"
    },
    {
        "TroopNumber": "Troop 96",
        "lng": "-77.031959",
        "State": "District of Columbia",
        "NameOrID": "May, Michael",
        "City": "Washington",
        "Year": "1979",
        "lat": "38.89037",
        "Document": "docs/michael-may.pdf"
    },
    {
        "TroopNumber": "Explorer 265",
        "lng": "-116.075049",
        "State": "California",
        "NameOrID": "McCormick, James",
        "City": "Baker",
        "Year": "1990",
        "lat": "35.273925",
        "Document": "docs/james-mccormick.pdf"
    },
    {
        "TroopNumber": "Troop 463",
        "lng": "-122.038258",
        "State": "California",
        "NameOrID": "McCrery, Charles",
        "City": "Sunnyvale",
        "Year": "1971",
        "lat": "37.371612",
        "Document": " docs/charles-mccrery.pdf"
    },
    {
        "TroopNumber": "Troop 433",
        "lng": "-117.560987",
        "State": "California",
        "NameOrID": "McKinney, Melvin E.",
        "City": "Norco",
        "Year": "1975",
        "lat": "33.922929",
        "Document": " docs/melvin-e-mckinney.pdf"
    },
    {
        "TroopNumber": "Troop 10",
        "lng": "-71.236054",
        "State": "Massachusetts",
        "NameOrID": "McDonnell, David M.",
        "City": "Needham",
        "Year": "1982",
        "lat": "42.28107",
        "Document": " docs/david-m-mcdonnell.pdf"
    },
    {
        "TroopNumber": "Troop 33",
        "lng": "-117.560987",
        "State": "California",
        "NameOrID": "McKinney, Melvin E.",
        "City": "Norco",
        "Year": "1975",
        "lat": "33.922929",
        "Document": " docs/melvin-e-mckinney.pdf"
    },
    {
        "TroopNumber": "Explorer 98",
        "lng": "-94.048254",
        "State": "Texas",
        "NameOrID": "McDuffie, Russell B.",
        "City": "Texarkana",
        "Year": "1988",
        "lat": "33.42558",
        "Document": "docs/russell-b-mcduffie.pdf"
    },
    {
        "TroopNumber": "Troop 640",
        "lng": "-111.920694",
        "State": "Arizona",
        "NameOrID": "McKinney, Ralph A.",
        "City": "Scottsdale",
        "Year": "1987",
        "lat": "33.494",
        "Document": "docs/ralph-a-mckinney.pdf"
    },
    {
        "TroopNumber": "Troop 155",
        "lng": "-71.668585",
        "State": "Massachusetts",
        "NameOrID": "McElreath, Richard L.",
        "City": "Whitinsville",
        "Year": "1989",
        "lat": "42.115785",
        "Document": "docs/richard-l-mcelreath.pdf"
    },
    {
        "TroopNumber": "Troop 164",
        "lng": "-119.116984",
        "State": "Washington",
        "NameOrID": "McLallen, Jerald A.",
        "City": "Kennewick",
        "Year": "1991",
        "lat": "46.20804",
        "Document": "docs/jerald-a-mclallen.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "McFarland, Fred",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/fred-mcfarland.pdf"
    },
    {
        "TroopNumber": "Troop 30",
        "lng": "-118.777624",
        "State": "Nevada",
        "NameOrID": "McLaughlin, Kevin J.",
        "City": "Fallon",
        "Year": "1989",
        "lat": "39.474905",
        "Document": "docs/kevin-j-mclaughlin.pdf"
    },
    {
        "TroopNumber": "Explorer 555",
        "lng": "-82.1220569",
        "State": "Florida",
        "NameOrID": "McGahee, Rubin Curtis",
        "City": "Macclenny",
        "Year": "1991",
        "lat": "30.2821807",
        "Document": "docs/rubin-curtis-mcgahee.pdf"
    },
    {
        "TroopNumber": "Troop 160",
        "lng": "-82.808854",
        "State": "Ohio",
        "NameOrID": "McLaughlin, Thomas L.",
        "City": "New Albany",
        "Year": "1989",
        "lat": "40.08131",
        "Document": "docs/thomas-l-mclaughlin.pdf"
    },
    {
        "TroopNumber": "Explorer 2360",
        "lng": "-111.651304",
        "State": "Arizona",
        "NameOrID": "McMacken, Dennis King",
        "City": "Flagstaff",
        "Year": "1988",
        "lat": "35.1982",
        "Document": "docs/dennis-king-mcmacken.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-98.500994",
        "State": "Texas",
        "NameOrID": "McGilvray, David",
        "City": "Wichita Falls",
        "Year": "1990",
        "lat": "33.90831",
        "Document": "docs/david-mcgilvray.pdf"
    },
    {
        "TroopNumber": "Pack 3034",
        "lng": "-111.651304",
        "State": "Arizona",
        "NameOrID": "McMacken, Dennis King",
        "City": "Flagstaff",
        "Year": "1988",
        "lat": "35.1982",
        "Document": "docs/dennis-king-mcmacken.pdf"
    },
    {
        "TroopNumber": "Troop 152",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "McGrew, John E.",
        "City": "Dallas",
        "Year": "1987",
        "lat": "32.778155",
        "Document": "docs/john-e-mcgrew.pdf"
    },
    {
        "TroopNumber": "Troop 3034",
        "lng": "-111.651304",
        "State": "Arizona",
        "NameOrID": "McMacken, Dennis King",
        "City": "Flagstaff",
        "Year": "1988",
        "lat": "35.1982",
        "Document": "docs/dennis-king-mcmacken.pdf"
    },
    {
        "TroopNumber": "183",
        "lng": "-97.160054",
        "State": "Texas",
        "NameOrID": "McMillian, Billy Ray",
        "City": "Smithville",
        "Year": "1986",
        "lat": "30.00868",
        "Document": "docs/billy-ray-mcmillian.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Means, Clyde H.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/clyde-h-means.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "McMurry, Roger",
        "City": "",
        "Year": "1974",
        "lat": "",
        "Document": " docs/roger-mcmurry.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Mebane, John D.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/john-d-mebane.pdf"
    },
    {
        "TroopNumber": "District 8620",
        "lng": "",
        "State": "",
        "NameOrID": "McReynolds, Steve",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/steve-mcreynolds.pdf"
    },
    {
        "TroopNumber": "Troop 226",
        "lng": "-121.275999",
        "State": "California",
        "NameOrID": "Meeks, Dan W.",
        "City": "Lodi",
        "Year": "1975",
        "lat": "38.13016",
        "Document": " docs/dan-w-meeks.pdf"
    },
    {
        "TroopNumber": "Troop 81",
        "lng": "-71.13124",
        "State": "Massachusetts",
        "NameOrID": "McRobbie, Daniel R.",
        "City": "North Andover",
        "Year": "1987",
        "lat": "42.703095",
        "Document": "docs/daniel-r-mcrobbie.pdf"
    },
    {
        "TroopNumber": "Troop 114",
        "lng": "-108.275004",
        "State": "New Mexico",
        "NameOrID": "Meisner, Lawrence",
        "City": "Silver City ",
        "Year": "1991",
        "lat": "32.769975",
        "Document": "docs/lawrence-meisner.pdf"
    },
    {
        "TroopNumber": "Pack 278",
        "lng": "-92.851889",
        "State": "Minnesota",
        "NameOrID": "Melbourne, Patrick Joseph",
        "City": "Hastings",
        "Year": "1987",
        "lat": "44.74229",
        "Document": "docs/patrick-joseph-melbourne.pdf"
    },
    {
        "TroopNumber": "Troop 34",
        "lng": "",
        "State": "Connecticut",
        "NameOrID": "Mead, Alan D.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/alan-d-mead.pdf"
    },
    {
        "TroopNumber": "Troop 238",
        "lng": "-75.359539",
        "State": "Pennsylvania",
        "NameOrID": "Memmo, Paul",
        "City": "Broomall",
        "Year": "1983",
        "lat": "39.98062",
        "Document": " docs/paul-memmo.pdf"
    },
    {
        "TroopNumber": "Pack 3100",
        "lng": "-121.290754",
        "State": "California",
        "NameOrID": "Meadows, Daniel",
        "City": "Stockton",
        "Year": "1988",
        "lat": "37.95368",
        "Document": "docs/daniel-meadows.pdf"
    },
    {
        "TroopNumber": "Troop 786",
        "lng": "-78.877579",
        "State": "North Carolina",
        "NameOrID": "Menghi, Thomas J.",
        "City": "Fayetteville",
        "Year": "1974",
        "lat": "35.054495",
        "Document": " docs/thomas-j-menghi.pdf"
    },
    {
        "TroopNumber": "Troop 399",
        "lng": "-101.535384",
        "State": "Texas",
        "NameOrID": "Means, Robert Ted",
        "City": "Lorenzo",
        "Year": "1971",
        "lat": "33.667525",
        "Document": " docs/robert-ted-means.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Merila, Richard E.",
        "City": "Indianapolis",
        "Year": "1987",
        "lat": "39.76691",
        "Document": "docs/richard-e-merila.pdf"
    },
    {
        "TroopNumber": "Pack 13",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Merila, Richard E.",
        "City": "Indianapolis",
        "Year": "1987",
        "lat": "39.76691",
        "Document": "docs/richard-e-merila.pdf"
    },
    {
        "TroopNumber": "Troop 140",
        "lng": "-118.192399",
        "State": "California",
        "NameOrID": "Meyer, Keith Dale",
        "City": "Long Beach",
        "Year": "1986",
        "lat": "33.766725",
        "Document": "docs/keith-dale-meyer.pdf"
    },
    {
        "TroopNumber": "Troop 81",
        "lng": "-88.153019",
        "State": "Illinois",
        "NameOrID": "Merkel, Stephen J.",
        "City": "Naperville",
        "Year": "1990",
        "lat": "41.770535",
        "Document": "docs/stephen-j-merkel.pdf"
    },
    {
        "TroopNumber": "Troop 117",
        "lng": "-74.99658",
        "State": "New Jersey",
        "NameOrID": "Meyer, Herman G.",
        "City": "Blairstown",
        "Year": "1986",
        "lat": "40.982622",
        "Document": "docs/herman-g-meyer.pdf"
    },
    {
        "TroopNumber": "113",
        "lng": "-98.777443",
        "State": "Kansas",
        "NameOrID": "Merlau, Edson",
        "City": "Hoisington",
        "Year": "1972",
        "lat": "38.51534",
        "Document": " docs/edson-merlau.pdf"
    },
    {
        "TroopNumber": "Explorer 3",
        "lng": "-94.197519",
        "State": "Minnesota",
        "NameOrID": "Messersmith, Robert",
        "City": "St. Cloud",
        "Year": "1988",
        "lat": "45.56586",
        "Document": "docs/robert-messersmith.pdf"
    },
    {
        "TroopNumber": "Troop 113",
        "lng": "-90.231164",
        "State": "Illinois",
        "NameOrID": "Meyer, James M.",
        "City": "Jacksonville",
        "Year": "1984",
        "lat": "39.745201",
        "Document": " docs/james-m-meyer.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-71.411994",
        "State": "Rhode Island",
        "NameOrID": "Micarelli, Edmond C.",
        "City": "Providence",
        "Year": "1990",
        "lat": "41.823875",
        "Document": "docs/edmond-c-micarelli.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Michaels, Charles Alan",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/charles-alan-michaels.pdf"
    },
    {
        "TroopNumber": "Explorer 48",
        "lng": "-73.829959",
        "State": "New York",
        "NameOrID": "Methvin, Robert",
        "City": "Queens",
        "Year": "1972",
        "lat": "40.713985",
        "Document": " docs/robert-methvin.pdf"
    },
    {
        "TroopNumber": "Troop 115",
        "lng": "-80.138899",
        "State": "Florida",
        "NameOrID": "Metzger, Frank",
        "City": "Wilton Manors",
        "Year": "1984",
        "lat": "26.154675",
        "Document": " docs/frank-metzger.pdf"
    },
    {
        "TroopNumber": "Pack 3389",
        "lng": "-97.107719",
        "State": "Texas",
        "NameOrID": "Middleton, William",
        "City": "Arlington ",
        "Year": "1991",
        "lat": "32.7356",
        "Document": "docs/william-middleton.pdf"
    },
    {
        "TroopNumber": "Troop 35",
        "lng": "-118.192399",
        "State": "California",
        "NameOrID": "Meyer, Keith Dale",
        "City": "Long Beach",
        "Year": "1986",
        "lat": "33.766725",
        "Document": "docs/keith-dale-meyer.pdf"
    },
    {
        "TroopNumber": "Pack 141",
        "lng": "-88.839074",
        "State": "Illinois",
        "NameOrID": "Miller, George R.",
        "City": "Belvidere",
        "Year": "1986",
        "lat": "42.256695",
        "Document": "docs/george-r-miller.pdf"
    },
    {
        "TroopNumber": "Troop 141",
        "lng": "-88.839074",
        "State": "Illinois",
        "NameOrID": "Miller, George R.",
        "City": "Belvidere",
        "Year": "1986",
        "lat": "42.256695",
        "Document": "docs/george-r-miller.pdf"
    },
    {
        "TroopNumber": "Explorer 244",
        "lng": "-94.856074",
        "State": "Missouri",
        "NameOrID": "Miller, Dean O.",
        "City": "St. Joseph",
        "Year": "1988",
        "lat": "39.7666",
        "Document": "docs/dean-o-miller.pdf"
    },
    {
        "TroopNumber": "Troop 400",
        "lng": "-118.526514",
        "State": "California",
        "NameOrID": "Miller, Ken",
        "City": "Pacific Palisades",
        "Year": "1986",
        "lat": "34.047045",
        "Document": "docs/ken-miller.pdf"
    },
    {
        "TroopNumber": "Troop 420",
        "lng": "-99.771419",
        "State": "Texas",
        "NameOrID": "Mills, William R.",
        "City": "Junction",
        "Year": "1985",
        "lat": "30.489305",
        "Document": "docs/william-r-mills.pdf"
    },
    {
        "TroopNumber": "Troop 227",
        "lng": "-85.671179",
        "State": "Michigan",
        "NameOrID": "Miller, Phillip F.",
        "City": "Grand Rapids",
        "Year": "1990",
        "lat": "42.96641",
        "Document": "docs/phillip-f-miller.pdf"
    },
    {
        "TroopNumber": "Troop 188",
        "lng": "-76.285069",
        "State": "Virginia",
        "NameOrID": "Miller, Mark",
        "City": "Norfolk",
        "Year": "1989",
        "lat": "36.846825",
        "Document": "docs/mark-miller.pdf"
    },
    {
        "TroopNumber": "Troop 64",
        "lng": "-81.245244",
        "State": "Ohio",
        "NameOrID": "Miller, Dennis A.",
        "City": "Painesville",
        "Year": "1990",
        "lat": "41.724205",
        "Document": "docs/dennis-a-miller.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-71.778702",
        "State": "Rhode Island",
        "NameOrID": "Mink, Albert",
        "City": "Hopkinton",
        "Year": "1987",
        "lat": "41.457465",
        "Document": "docs/albert-mink.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-104.612374",
        "State": "Colorado",
        "NameOrID": "Miller, Robert E.",
        "City": "Pueblo",
        "Year": "1989",
        "lat": "38.26386",
        "Document": "docs/robert-e-miller.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Miller, Kenneth G.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/kenneth-g-miller.pdf"
    },
    {
        "TroopNumber": "Troop 6090",
        "lng": "-123.324639",
        "State": "Oregon",
        "NameOrID": "Mitchell, Jay Douglas",
        "City": "Grants Pass",
        "Year": "1986",
        "lat": "42.44321",
        "Document": "docs/jay-douglas-mitchell.pdf"
    },
    {
        "TroopNumber": "Troop 119",
        "lng": "-83.536259",
        "State": "Ohio",
        "NameOrID": "Mitchell, Frank E.",
        "City": "Toledo",
        "Year": "1986",
        "lat": "41.65381",
        "Document": "docs/frank-e-mitchell.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Mitchell, Albert T.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/albert-t-mitchell.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-87.978819",
        "State": "Illinois",
        "NameOrID": "Monroe, Gary L.",
        "City": "Wood Dale",
        "Year": "1989",
        "lat": "41.967265",
        "Document": "docs/gary-l-monroe.pdf"
    },
    {
        "TroopNumber": "Troop 24",
        "lng": "-81.934869",
        "State": "South Carolina",
        "NameOrID": "Mixon, Larry W.",
        "City": "Spartanburg",
        "Year": "1988",
        "lat": "34.948255",
        "Document": "docs/larry-w-mixon.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Monroe, Wes D.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/wes-d-monroe.pdf"
    },
    {
        "TroopNumber": "Troop 26",
        "lng": "-95.671184",
        "State": "Kansas",
        "NameOrID": "Montague, Jeff",
        "City": "Topeka",
        "Year": "1989",
        "lat": "39.049285",
        "Document": "docs/jeff-montague.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-84.504552",
        "State": "Ohio",
        "NameOrID": "Moberly, John Thomas",
        "City": "Cincinnati",
        "Year": "1983",
        "lat": "39.106614",
        "Document": " docs/john-thomas-moberly.pdf"
    },
    {
        "TroopNumber": "Pack 494",
        "lng": "-73.990364",
        "State": "New York",
        "NameOrID": "Modica, Freddie",
        "City": "Brooklyn",
        "Year": "1973",
        "lat": "40.692455",
        "Document": " docs/freddie-modica.pdf"
    },
    {
        "TroopNumber": "Troop 121",
        "lng": "-118.192399",
        "State": "California",
        "NameOrID": "Montoya, Daniel",
        "City": "Long Beach",
        "Year": "1986",
        "lat": "33.766725",
        "Document": "docs/daniel-montoya.pdf"
    },
    {
        "TroopNumber": "Troop 75",
        "lng": "-122.301254",
        "State": "California",
        "NameOrID": "Moeller, David William",
        "City": "Anderson",
        "Year": "1985",
        "lat": "40.450565",
        "Document": "docs/david-william-moeller.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Mooers, Howard T.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/howard-t-mooers.pdf"
    },
    {
        "TroopNumber": "Troop 49",
        "lng": "-73.037439",
        "State": "New York",
        "NameOrID": "Mooney, Michael",
        "City": "Blue Point",
        "Year": "1989",
        "lat": "40.754295",
        "Document": "docs/michael-mooney.pdf"
    },
    {
        "TroopNumber": "Troop 149",
        "lng": "-122.592784",
        "State": "Oregon",
        "NameOrID": "Moffitt, David W.",
        "City": "Oregon City",
        "Year": "1989",
        "lat": "45.336518",
        "Document": "docs/david-w-moffitt.pdf"
    },
    {
        "TroopNumber": "Pack 310",
        "lng": "-82.391515",
        "State": "Georgia",
        "NameOrID": "Moore, Kathlene G.",
        "City": "Dearing",
        "Year": "1984",
        "lat": "33.414921",
        "Document": " docs/kathlene-g-moore.pdf"
    },
    {
        "TroopNumber": "Troop 430",
        "lng": "-90.669803",
        "State": "Illinois",
        "NameOrID": "Mohr, Johnnie L.",
        "City": "Reynolds",
        "Year": "1985",
        "lat": "41.32965",
        "Document": " docs/johnnie-l-mohr.pdf"
    },
    {
        "TroopNumber": "Pack 310",
        "lng": "-82.391515",
        "State": "Georgia",
        "NameOrID": "Moore, Frederick Andrew",
        "City": "Dearing",
        "Year": "1984",
        "lat": "33.414921",
        "Document": " docs/frederick-andrew-moore.pdf"
    },
    {
        "TroopNumber": "Troop 103",
        "lng": "-71.740241",
        "State": "Massachusetts",
        "NameOrID": "Moore, Daniel J.",
        "City": "Boylston",
        "Year": "1988",
        "lat": "42.334405",
        "Document": "docs/daniel-j-moore.pdf"
    },
    {
        "TroopNumber": "Troop 501",
        "lng": "-81.864019",
        "State": "Ohio",
        "NameOrID": "Mollica, Anthony J.",
        "City": "Medina",
        "Year": "1986",
        "lat": "41.14032",
        "Document": "docs/anthony-j-mollica.pdf"
    },
    {
        "TroopNumber": "22",
        "lng": "-100.442424",
        "State": "Texas",
        "NameOrID": "Moos, Dale C.",
        "City": "San Angelo",
        "Year": "1974",
        "lat": "31.4615",
        "Document": " docs/dale-c-moos.pdf"
    },
    {
        "TroopNumber": "Troop 457",
        "lng": "-87.20854",
        "State": "Indiana",
        "NameOrID": "Mondary, Roy E.",
        "City": "Jasonville",
        "Year": "1990",
        "lat": "39.163287",
        "Document": "docs/roy-e-mondary.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-81.377389",
        "State": "Florida",
        "NameOrID": "Morgan, Andrew T.",
        "City": "Orlando",
        "Year": "1990",
        "lat": "28.538235",
        "Document": "docs/andrew-t-morgan.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-74.829991",
        "State": "New York",
        "NameOrID": "Morgan, Andrew T.",
        "City": "White Lake",
        "Year": "1990",
        "lat": "41.673761",
        "Document": "docs/andrew-t-morgan.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-118.192399",
        "State": "California",
        "NameOrID": "Morrison, Kevin Keen",
        "City": "Long Beach",
        "Year": "1983",
        "lat": "33.766725",
        "Document": " docs/kevin-keen-morrison.pdf"
    },
    {
        "TroopNumber": "Troop 326",
        "lng": "-71.153276",
        "State": "Massachusetts",
        "NameOrID": "Morley, Robert Nelson",
        "City": "Brighton",
        "Year": "1983",
        "lat": "42.349647",
        "Document": " docs/robert-nelson-morley.pdf"
    },
    {
        "TroopNumber": "Troop 61",
        "lng": "-118.192399",
        "State": "California",
        "NameOrID": "Morrison, Kevin Keen",
        "City": "Long Beach",
        "Year": "1983",
        "lat": "33.766725",
        "Document": " docs/kevin-keen-morrison.pdf"
    },
    {
        "TroopNumber": "Pack 4",
        "lng": "-81.956939",
        "State": "Florida",
        "NameOrID": "Morris, Alan Richard",
        "City": "Cape Coral",
        "Year": "1986",
        "lat": "26.62982",
        "Document": "docs/alan-richard-morris.pdf"
    },
    {
        "TroopNumber": "Pack 54",
        "lng": "-76.183789",
        "State": "Pennsylvania",
        "NameOrID": "Morrison, James Randolph",
        "City": "Leola",
        "Year": "1972",
        "lat": "40.088235",
        "Document": " docs/james-randolph-morrison.pdf"
    },
    {
        "TroopNumber": "Troop 86",
        "lng": "-81.956939",
        "State": "Florida",
        "NameOrID": "Morris, Alan Richard",
        "City": "Cape Coral",
        "Year": "1986",
        "lat": "26.62982",
        "Document": "docs/alan-richard-morris.pdf"
    },
    {
        "TroopNumber": "Pack 14",
        "lng": "-89.984234",
        "State": "Illinois",
        "NameOrID": "Morrison, Lenny M.",
        "City": "Belleville",
        "Year": "1991",
        "lat": "38.512135",
        "Document": "docs/lenny-m-morrison.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-89.984234",
        "State": "Illinois",
        "NameOrID": "Morrison, Lenny M.",
        "City": "Belleville",
        "Year": "1991",
        "lat": "38.512135",
        "Document": "docs/lenny-m-morrison.pdf"
    },
    {
        "TroopNumber": "Troop 136",
        "lng": "-83.536259",
        "State": "Ohio",
        "NameOrID": "Murray, Gerald F.",
        "City": "Toledo",
        "Year": "1982",
        "lat": "41.65381",
        "Document": " docs/gerald-f-murray.pdf"
    },
    {
        "TroopNumber": "Troop 15",
        "lng": "-72.589294",
        "State": "Massachusetts",
        "NameOrID": "Mosher, Reginald",
        "City": "Springfield",
        "Year": "1988",
        "lat": "42.10125",
        "Document": "docs/reginald-mosher.pdf"
    },
    {
        "TroopNumber": "Explorer 28",
        "lng": "-77.717579",
        "State": "Maryland",
        "NameOrID": "Murray, Harold J.",
        "City": "Hagerstown",
        "Year": "1988",
        "lat": "39.6425",
        "Document": "docs/harold-j-murray.pdf"
    },
    {
        "TroopNumber": "Explorer 139",
        "lng": "-77.717579",
        "State": "Maryland",
        "NameOrID": "Murray, Harold J.",
        "City": "Hagerstown",
        "Year": "1988",
        "lat": "39.6425",
        "Document": "docs/harold-j-murray.pdf"
    },
    {
        "TroopNumber": "Troop 80",
        "lng": "-74.692848",
        "State": "New York",
        "NameOrID": "Myhre, John E.",
        "City": "Oppenheim",
        "Year": "1986",
        "lat": "43.072018",
        "Document": "docs/john-e-myhre.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-76.174824",
        "State": "New York",
        "NameOrID": "Moulton, John Dudley",
        "City": "Cortland",
        "Year": "1972",
        "lat": "42.603495",
        "Document": " docs/john-dudley-moulton.pdf"
    },
    {
        "TroopNumber": "Troop 450",
        "lng": "-78.886371",
        "State": "New York",
        "NameOrID": "Naffky, Peter",
        "City": "Tonawanda",
        "Year": "1989",
        "lat": "43.018839",
        "Document": "docs/peter-naffky.pdf"
    },
    {
        "TroopNumber": "Troop 1058",
        "lng": "-117.32364",
        "State": "California",
        "NameOrID": "Nahodyl, James J.",
        "City": "Victorville",
        "Year": "1987",
        "lat": "34.510622",
        "Document": "docs/james-j-nahodyl.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-2.30546",
        "State": "England",
        "NameOrID": "Mulnix, Walter J.",
        "City": "Lakenheath Air Force Base",
        "Year": "1977",
        "lat": "52.84145",
        "Document": " docs/walter-j-mulnix.pdf"
    },
    {
        "TroopNumber": "Troop 565",
        "lng": "-78.686394",
        "State": "New York",
        "NameOrID": "Nail, Douglas W.",
        "City": "Depew",
        "Year": "1988",
        "lat": "42.904405",
        "Document": "docs/douglas-w-nail.pdf"
    },
    {
        "TroopNumber": "District 1",
        "lng": "-118.248364",
        "State": "California",
        "NameOrID": "Mumford, Jeffrey H.",
        "City": "Glendale",
        "Year": "1989",
        "lat": "34.14633",
        "Document": "docs/jeffrey-h-mumford.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Nakanishi, Stanley J.",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": " docs/stanley-j-nakanishi.pdf"
    },
    {
        "TroopNumber": "Troop 804",
        "lng": "",
        "State": "",
        "NameOrID": "Mundy, Merlin Edward",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/merlin-edward-mundy.pdf"
    },
    {
        "TroopNumber": "Troop 287",
        "lng": "-97.727109",
        "State": "Texas",
        "NameOrID": "Narmi, Mark E.",
        "City": "Killeen",
        "Year": "1991",
        "lat": "31.116835",
        "Document": "docs/mark-e-narmi.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "",
        "State": "",
        "NameOrID": "Murphree, Casey",
        "City": "",
        "Year": "1986",
        "lat": "",
        "Document": "docs/casey-murphree.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-82.331149",
        "State": "Florida",
        "NameOrID": "Needle, Howie",
        "City": "Inverness",
        "Year": "1979",
        "lat": "28.835464",
        "Document": "docs/howie-needle.pdf"
    },
    {
        "TroopNumber": "Troop 195",
        "lng": "-81.69957",
        "State": "West Virginia",
        "NameOrID": "Neff, James L.",
        "City": "South Charleston",
        "Year": "1989",
        "lat": "38.368429",
        "Document": "docs/james-l-neff.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Neftzger, Joe N.",
        "City": "",
        "Year": "1978",
        "lat": "",
        "Document": "docs/joe-n-neftzger.pdf"
    },
    {
        "TroopNumber": "Troop 705",
        "lng": "-88.48407",
        "State": "Wisconsin",
        "NameOrID": "Nehring, Clifford Gordon",
        "City": "North Fond du Lac",
        "Year": "1988",
        "lat": "43.80727",
        "Document": "docs/clifford-gordon-nehring.pdf"
    },
    {
        "TroopNumber": "Troop 274",
        "lng": "-118.001574",
        "State": "California",
        "NameOrID": "Negrette, David Robert",
        "City": "Huntington Beach",
        "Year": "1988",
        "lat": "33.67889",
        "Document": "docs/david-robert-negrette.pdf"
    },
    {
        "TroopNumber": "Troop 348",
        "lng": "-118.001574",
        "State": "California",
        "NameOrID": "Negrette, David Robert",
        "City": "Huntington Beach",
        "Year": "1988",
        "lat": "33.67889",
        "Document": "docs/david-robert-negrette.pdf"
    },
    {
        "TroopNumber": "16",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Nelson, David James",
        "City": "Kansas City",
        "Year": "1990",
        "lat": "39.10296",
        "Document": "docs/david-james-nelson.pdf"
    },
    {
        "TroopNumber": "Troop 123",
        "lng": "-117.874307",
        "State": "California",
        "NameOrID": "Negrette, David Robert",
        "City": "Santa Ana",
        "Year": "1988",
        "lat": "33.749588",
        "Document": "docs/david-robert-negrette.pdf"
    },
    {
        "TroopNumber": "Pack 487",
        "lng": "-105.073919",
        "State": "Colorado",
        "NameOrID": "Nelson, Richard W.",
        "City": "Loveland",
        "Year": "1989",
        "lat": "40.39276",
        "Document": "docs/richard-w-nelson.pdf"
    },
    {
        "TroopNumber": "Pack 395",
        "lng": "-97.452588",
        "State": "Texas",
        "NameOrID": "Nelson, States Lee",
        "City": "Benbrook",
        "Year": "1989",
        "lat": "32.677946",
        "Document": "docs/states-lee-nelson.pdf"
    },
    {
        "TroopNumber": "Troop 577",
        "lng": "-80.38363",
        "State": "Pennsylvania",
        "NameOrID": "Newman, Emanuel",
        "City": "Willowgrove",
        "Year": "1989",
        "lat": "40.947359",
        "Document": "docs/emanuel-newman.pdf"
    },
    {
        "TroopNumber": "Troop 45",
        "lng": "-122.593513",
        "State": "Washington",
        "NameOrID": "Newman, Arnold O.",
        "City": "Ferndale",
        "Year": "1976",
        "lat": "48.847563",
        "Document": " docs/arnold-o-newman.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Nelson, Keven B.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/keven-b-nelson.pdf"
    },
    {
        "TroopNumber": "Troop 26",
        "lng": "-95.993334",
        "State": "Oklahoma",
        "NameOrID": "Newman, George",
        "City": "Tulsa",
        "Year": "1990",
        "lat": "36.149745",
        "Document": "docs/george-newman.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Nelson, David L.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/david-l-nelson.pdf"
    },
    {
        "TroopNumber": "Explorer ",
        "lng": "-90.482079",
        "State": "Missouri",
        "NameOrID": "Newman, Edward Al",
        "City": "St. Charles",
        "Year": "1990",
        "lat": "38.782435",
        "Document": "docs/edward-al-newman.pdf"
    },
    {
        "TroopNumber": "Pack 605",
        "lng": "-118.492289",
        "State": "California",
        "NameOrID": "Nenno, Patrick William",
        "City": "Santa Monica",
        "Year": "1988",
        "lat": "34.011565",
        "Document": "docs/patrick-william-nenno.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-85.325985",
        "State": "Michigan",
        "NameOrID": "Newman, Edward Al",
        "City": "Osceola County",
        "Year": "1990",
        "lat": "43.989344",
        "Document": "docs/edward-al-newman.pdf"
    },
    {
        "TroopNumber": "Troop 70",
        "lng": "",
        "State": "",
        "NameOrID": "Nerau, Alan R.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/alan-r-nerau.pdf"
    },
    {
        "TroopNumber": "Pack 735",
        "lng": "-84.510209",
        "State": "Kentucky",
        "NameOrID": "Newman, Daniel Keith",
        "City": "Covington",
        "Year": "1988",
        "lat": "39.083775",
        "Document": "docs/daniel-keith-newman.pdf"
    },
    {
        "TroopNumber": "Troop 266",
        "lng": "",
        "State": "California",
        "NameOrID": "Ney, Robert Graham",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/robert-graham-ney.pdf"
    },
    {
        "TroopNumber": "Troop 1600",
        "lng": "-77.412034",
        "State": "Maryland",
        "NameOrID": "Neufeld, Harold A.",
        "City": "Frederick",
        "Year": "1988",
        "lat": "39.415745",
        "Document": "docs/harold-a-neufeld.pdf"
    },
    {
        "TroopNumber": "Pack 594",
        "lng": "-87.114804",
        "State": "Tennessee",
        "NameOrID": "Nichols, Gene E.",
        "City": "Kingston Springs",
        "Year": "1989",
        "lat": "36.10145",
        "Document": "docs/gene-e-nichols.pdf"
    },
    {
        "TroopNumber": "Troop 799",
        "lng": "-77.412034",
        "State": "Maryland",
        "NameOrID": "Neufeld, Harold A.",
        "City": "Frederick",
        "Year": "1988",
        "lat": "39.415745",
        "Document": "docs/harold-a-neufeld.pdf"
    },
    {
        "TroopNumber": "Explorer 373",
        "lng": "-97.221804",
        "State": "Oklahoma",
        "NameOrID": "Nichols, Robert F.",
        "City": "Pauls Valley",
        "Year": "1988",
        "lat": "34.740205",
        "Document": "docs/robert-f-nichols.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-122.990499",
        "State": "Oregon",
        "NameOrID": "Newby, Rand Darrell",
        "City": "Hillsboro",
        "Year": "1987",
        "lat": "45.522675",
        "Document": "docs/rand-darrell-newby.pdf"
    },
    {
        "TroopNumber": "Troop 179",
        "lng": "-92.445554",
        "State": "Iowa",
        "NameOrID": "Nicholson, Dennis D.",
        "City": "Cedar Falls",
        "Year": "1988",
        "lat": "42.528145",
        "Document": "docs/dennis-d-nicholson.pdf"
    },
    {
        "TroopNumber": "Pack 3641",
        "lng": "-73.213234",
        "State": "Vermont",
        "NameOrID": "Newcity, Randall",
        "City": "Burlington",
        "Year": "1985",
        "lat": "44.47592",
        "Document": " docs/randall-newcity.pdf"
    },
    {
        "TroopNumber": "Troop 528",
        "lng": "-70.190343",
        "State": "Maine",
        "NameOrID": "Nickerson, William H.",
        "City": "Livermore Falls",
        "Year": "1990",
        "lat": "44.474325",
        "Document": "docs/william-h-nickerson.pdf"
    },
    {
        "TroopNumber": "Troop 418",
        "lng": "-87.823654",
        "State": "Wisconsin",
        "NameOrID": "Nicoll, George T.",
        "City": "Kenosha",
        "Year": "1989",
        "lat": "42.588075",
        "Document": "docs/george-t-nicoll.pdf"
    },
    {
        "TroopNumber": "Troop 436",
        "lng": "-75.264424",
        "State": "Pennsylvania",
        "NameOrID": "Niedringhaus, William",
        "City": "Narberth",
        "Year": "1991",
        "lat": "40.005295",
        "Document": "docs/william-niedringhaus.pdf"
    },
    {
        "TroopNumber": "Troop 641",
        "lng": "-73.213234",
        "State": "Vermont",
        "NameOrID": "Newcity, Randall",
        "City": "Burlington",
        "Year": "1985",
        "lat": "44.47592",
        "Document": " docs/randall-newcity.pdf"
    },
    {
        "TroopNumber": "Troop 1044",
        "lng": "-72.812099",
        "State": "Connecticut",
        "NameOrID": "Nobert, Mark",
        "City": "South Meriden",
        "Year": "1986",
        "lat": "41.53678",
        "Document": " docs/mark-nobert.pdf"
    },
    {
        "TroopNumber": "Troop 614",
        "lng": "-94.741075",
        "State": "Texas",
        "NameOrID": "Newman, Charles R.",
        "City": "Longview",
        "Year": "1989",
        "lat": "32.50064",
        "Document": "docs/charles-r-newman.pdf"
    },
    {
        "TroopNumber": "Troop 1020",
        "lng": "-72.812099",
        "State": "Connecticut",
        "NameOrID": "Nobert, Mark",
        "City": "South Meriden",
        "Year": "1986",
        "lat": "41.53678",
        "Document": " docs/mark-nobert.pdf"
    },
    {
        "TroopNumber": "Explorer 801",
        "lng": "-94.741075",
        "State": "Texas",
        "NameOrID": "Newman, Charles R.",
        "City": "Longview",
        "Year": "1989",
        "lat": "32.50064",
        "Document": "docs/charles-r-newman.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "",
        "State": "",
        "NameOrID": "Noble, Martin Frederick",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/martin-frederick-noble.pdf"
    },
    {
        "TroopNumber": "Troop 118",
        "lng": "-80.234949",
        "State": "Florida",
        "NameOrID": "Nolan, Gary",
        "City": "Davie",
        "Year": "1985",
        "lat": "26.06567",
        "Document": " docs/gary-nolan.pdf"
    },
    {
        "TroopNumber": "Explorer 1119",
        "lng": "-84.340534",
        "State": "Georgia",
        "NameOrID": "O'Connor, Rory J.",
        "City": "Morrow",
        "Year": "1991",
        "lat": "33.58335",
        "Document": "docs/rory-j-oconnor.pdf"
    },
    {
        "TroopNumber": "Troop 836",
        "lng": "-80.204074",
        "State": "Florida",
        "NameOrID": "Nolan, Gary",
        "City": "Margate",
        "Year": "1985",
        "lat": "26.241835",
        "Document": " docs/gary-nolan.pdf"
    },
    {
        "TroopNumber": "Troop 196",
        "lng": "-81.265844",
        "State": "Florida",
        "NameOrID": "O'Conner, Robert Jake",
        "City": "Winter Springs",
        "Year": "1988",
        "lat": "28.70049",
        "Document": "docs/robert-jake-oconner.pdf"
    },
    {
        "TroopNumber": "Troop 249",
        "lng": "-99.963544",
        "State": "Texas",
        "NameOrID": "Nolte, Randy J.",
        "City": "Winters",
        "Year": "1990",
        "lat": "31.957681",
        "Document": "docs/randy-j-nolte.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Odell, Amos F.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/amos-f-odell.pdf"
    },
    {
        "TroopNumber": "Troop 176",
        "lng": "-80.13117",
        "State": "Pennsylvania",
        "NameOrID": "Nonclerg, Michael D.",
        "City": "Edinboro",
        "Year": "1972",
        "lat": "41.875708",
        "Document": " docs/michael-d-nonclerg.pdf"
    },
    {
        "TroopNumber": "Troop 214",
        "lng": "-94.478139",
        "State": "Missouri",
        "NameOrID": "O'Dell, Glen",
        "City": "Pleasant Valley",
        "Year": "1983",
        "lat": "39.216365",
        "Document": " docs/glen-odell.pdf"
    },
    {
        "TroopNumber": "20",
        "lng": "-75.242762",
        "State": "New York",
        "NameOrID": "Noreau, Lawrence F.",
        "City": "Utica",
        "Year": "1974",
        "lat": "43.095808",
        "Document": " docs/lawrence-f-noreau.pdf"
    },
    {
        "TroopNumber": "Troop 77",
        "lng": "-105.279239",
        "State": "Colorado",
        "NameOrID": "Odya, Robert E.",
        "City": "Boulder",
        "Year": "1988",
        "lat": "40.01574",
        "Document": "docs/robert-e-odya.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Norton, Roger B.",
        "City": "",
        "Year": "1973",
        "lat": "",
        "Document": " docs/roger-b-norton.pdf"
    },
    {
        "TroopNumber": "Troop 182",
        "lng": "-93.605264",
        "State": "Iowa",
        "NameOrID": "Novac, Robert J.",
        "City": "Ankeny",
        "Year": "1977",
        "lat": "41.73184",
        "Document": " docs/robert-j-novac.pdf"
    },
    {
        "TroopNumber": "Troop 120",
        "lng": "-93.553618",
        "State": "Missouri",
        "NameOrID": "Ogan, James E.",
        "City": "Chilicothe",
        "Year": "1983",
        "lat": "39.79022",
        "Document": " docs/james-e-ogan.pdf"
    },
    {
        "TroopNumber": "Troop 110",
        "lng": "-78.835734",
        "State": "Pennsylvania",
        "NameOrID": "Novak, David A.",
        "City": "Windber",
        "Year": "1988",
        "lat": "40.23902",
        "Document": "docs/david-a-novak.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Okerlund, Denes P.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/denes-p-okerlund.pdf"
    },
    {
        "TroopNumber": "Troop 64",
        "lng": "-93.093326",
        "State": "Minnesota",
        "NameOrID": "Novak, Roger",
        "City": "St. Paul",
        "Year": "1979",
        "lat": "44.943829",
        "Document": "docs/roger-novak.pdf"
    },
    {
        "TroopNumber": "Troop 515",
        "lng": "-74.006099",
        "State": "New York",
        "NameOrID": "Olaskowitz, Michael",
        "City": "Manhattan",
        "Year": "1986",
        "lat": "40.71443",
        "Document": "docs/michael-olaskowitz.pdf"
    },
    {
        "TroopNumber": "Troop 15",
        "lng": "-76.798814",
        "State": "New York",
        "NameOrID": "Noyes, Thomas E.",
        "City": "Elmira",
        "Year": "1987",
        "lat": "42.09041",
        "Document": "docs/thomas-e-noyes.pdf"
    },
    {
        "TroopNumber": "Pack 515",
        "lng": "-74.006099",
        "State": "New York",
        "NameOrID": "Olaskowitz, Michael",
        "City": "Manhattan",
        "Year": "1986",
        "lat": "40.71443",
        "Document": "docs/michael-olaskowitz.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Nye, Edward E.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/edward-e-nye.pdf"
    },
    {
        "TroopNumber": "Explorer 907",
        "lng": "-81.507604",
        "State": "Florida",
        "NameOrID": "Oldroyd, Robert J.",
        "City": "Apopka",
        "Year": "1989",
        "lat": "28.67312",
        "Document": "docs/robert-j-oldroyd.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "California",
        "NameOrID": "Nykamp, John Martin",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/john-martin-nykamp.pdf"
    },
    {
        "TroopNumber": "Troop 999",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Oliva, Rudy T.",
        "City": "Chicago",
        "Year": "1988",
        "lat": "41.88415",
        "Document": "docs/rudy-t-oliva.pdf"
    },
    {
        "TroopNumber": "Troop 36",
        "lng": "-81.610694",
        "State": "Ohio",
        "NameOrID": "Oborn, Edward A.",
        "City": "Barberton",
        "Year": "1989",
        "lat": "41.012035",
        "Document": "docs/edward-a-oborn.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "O'Boyle, James J.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/james-j-oboyle.pdf"
    },
    {
        "TroopNumber": "Troop 43",
        "lng": "-82.694769",
        "State": "Florida",
        "NameOrID": "Oliver, Donald Duane",
        "City": "Safety Harbor",
        "Year": "1985",
        "lat": "27.99081",
        "Document": " docs/donald-duane-oliver.pdf"
    },
    {
        "TroopNumber": "Troop 119",
        "lng": "-84.340534",
        "State": "Georgia",
        "NameOrID": "O'Connor, Rory J.",
        "City": "Morrow",
        "Year": "1991",
        "lat": "33.58335",
        "Document": "docs/rory-j-oconnor.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "",
        "State": "",
        "NameOrID": "Oliver, William",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/william-oliver.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "",
        "State": "",
        "NameOrID": "O'Neal, John C.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/john-c-oneal.pdf"
    },
    {
        "TroopNumber": "Pack 231",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Olivieri, Albert J.",
        "City": "Rochester ",
        "Year": "1972",
        "lat": "43.1555",
        "Document": " docs/albert-j-olivieri.pdf"
    },
    {
        "TroopNumber": "Troop 226",
        "lng": "-121.491014",
        "State": "California",
        "NameOrID": "Olson, John Norman",
        "City": "Sacramento",
        "Year": "1985",
        "lat": "38.579065",
        "Document": " docs/john-norman-olson.pdf"
    },
    {
        "TroopNumber": "Explorer 2012",
        "lng": "-93.093326",
        "State": "Minnesota",
        "NameOrID": "Opalinski, Leland",
        "City": "St. Paul",
        "Year": "1971",
        "lat": "44.943829",
        "Document": " docs/leland-opalinski.pdf"
    },
    {
        "TroopNumber": "Troop 338",
        "lng": "-84.105989",
        "State": "Georgia",
        "NameOrID": "Omahen, Alan Lee",
        "City": "Lithonia",
        "Year": "1986",
        "lat": "33.711975",
        "Document": " docs/alan-lee-omahen.pdf"
    },
    {
        "TroopNumber": "District 3",
        "lng": "-111.888229",
        "State": "Utah",
        "NameOrID": "Opfar, Thomas",
        "City": "Salt Lake City",
        "Year": "1987",
        "lat": "40.759505",
        "Document": "docs/thomas-opfar.pdf"
    },
    {
        "TroopNumber": "Pack 22",
        "lng": "-119.813439",
        "State": "Nevada",
        "NameOrID": "Opfer, Ray",
        "City": "Reno",
        "Year": "1989",
        "lat": "39.527385",
        "Document": "docs/ray-opfer.pdf"
    },
    {
        "TroopNumber": "Pack 242",
        "lng": "-103.219959",
        "State": "Colorado",
        "NameOrID": "Overholt, Dan S.",
        "City": "Las Animas",
        "Year": "1990",
        "lat": "38.06728",
        "Document": "docs/dan-s-overholt.pdf"
    },
    {
        "TroopNumber": "Troop 408",
        "lng": "-79.769059",
        "State": "South Carolina",
        "NameOrID": "Orman, Ernest Nestor",
        "City": "Florence",
        "Year": "1990",
        "lat": "34.19363",
        "Document": "docs/ernest-nestor-orman.pdf"
    },
    {
        "TroopNumber": "Troop 154",
        "lng": "-94.453098",
        "State": "Missouri",
        "NameOrID": "Overlin, Kim A.",
        "City": "Ft. Wood",
        "Year": "1976",
        "lat": "39.9517",
        "Document": " docs/kim-a-overlin.pdf"
    },
    {
        "TroopNumber": "Troop 316",
        "lng": "-87.564125",
        "State": "Indiana",
        "NameOrID": "Ormond, David Michael",
        "City": "Evansville",
        "Year": "1986",
        "lat": "37.976911",
        "Document": " docs/david-michael-ormond.pdf"
    },
    {
        "TroopNumber": "Troop 427",
        "lng": "-85.452084",
        "State": "Michigan",
        "NameOrID": "Overton, Michael J.",
        "City": "Mendon",
        "Year": "1990",
        "lat": "42.00682",
        "Document": "docs/michael-j-overton.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Osher, Richard E.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/richard-e-osher.pdf"
    },
    {
        "TroopNumber": "Troop 47",
        "lng": "-88.544434",
        "State": "Wisconsin",
        "NameOrID": "Owens, Richard",
        "City": "Oshkosh",
        "Year": "1973",
        "lat": "44.02491",
        "Document": " docs/richard-owens.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Ostopowich, Darrald Timmie",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/darrald-timmie-ostopowich.pdf"
    },
    {
        "TroopNumber": "30",
        "lng": "-75.761814",
        "State": "Delaware",
        "NameOrID": "Owens, Robert",
        "City": "Newark",
        "Year": "1991",
        "lat": "39.678015",
        "Document": "docs/robert-owens.pdf"
    },
    {
        "TroopNumber": "Troop 988",
        "lng": "-77.043414",
        "State": "Virginia",
        "NameOrID": "Ostrowski, Theodore J.",
        "City": "Alexandria",
        "Year": "1988",
        "lat": "38.804605",
        "Document": "docs/theodore-j-ostrowski.pdf"
    },
    {
        "TroopNumber": "Troop 202",
        "lng": "-71.181865",
        "State": "New Hampshire",
        "NameOrID": "Oxton, Alfred J.",
        "City": "Gorham",
        "Year": "1979",
        "lat": "44.390437",
        "Document": "docs/alfred-j-oxton.pdf"
    },
    {
        "TroopNumber": "Pack 30",
        "lng": "-79.895274",
        "State": "Pennsylvania",
        "NameOrID": "Otte, Albert William",
        "City": "Butler",
        "Year": "1991",
        "lat": "40.86015",
        "Document": "docs/albert-william-otte.pdf"
    },
    {
        "TroopNumber": "Pack 204",
        "lng": "-71.181865",
        "State": "New Hampshire",
        "NameOrID": "Oxton, Alfred J.",
        "City": "Gorham",
        "Year": "1979",
        "lat": "44.390437",
        "Document": "docs/alfred-j-oxton.pdf"
    },
    {
        "TroopNumber": "Pack 3",
        "lng": "-79.895274",
        "State": "Pennsylvania",
        "NameOrID": "Otte, Albert William",
        "City": "Butler",
        "Year": "1991",
        "lat": "40.86015",
        "Document": "docs/albert-william-otte.pdf"
    },
    {
        "TroopNumber": "Pack 153",
        "lng": "-118.192399",
        "State": "California",
        "NameOrID": "Pace, Norman James",
        "City": "Long Beach",
        "Year": "1971",
        "lat": "33.766725",
        "Document": " docs/norman-james-pace.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-91.220279",
        "State": "Louisiana",
        "NameOrID": "Oubre, Beatrice T.",
        "City": "Berwick",
        "Year": "1988",
        "lat": "29.69423",
        "Document": "docs/beatrice-t-oubre.pdf"
    },
    {
        "TroopNumber": "Explorer 89",
        "lng": "-82.799321",
        "State": "Florida",
        "NameOrID": "Pace, Roy K.",
        "City": "Seminole",
        "Year": "1980",
        "lat": "27.840289",
        "Document": " docs/roy-k-pace.pdf"
    },
    {
        "TroopNumber": "Pack 438",
        "lng": "-91.202921",
        "State": "Louisiana",
        "NameOrID": "Oubre, Beatrice T.",
        "City": "Morgan City",
        "Year": "1988",
        "lat": "29.701243",
        "Document": "docs/beatrice-t-oubre.pdf"
    },
    {
        "TroopNumber": "Troop 355",
        "lng": "-77.433654",
        "State": "Virginia",
        "NameOrID": "Overby, Archer R.",
        "City": "Richmond",
        "Year": "1976",
        "lat": "37.5407",
        "Document": " docs/archer-r-overby.pdf"
    },
    {
        "TroopNumber": "Troop 570",
        "lng": "-81.223354",
        "State": "Florida",
        "NameOrID": "Pace, Marc Steve",
        "City": "Deltona",
        "Year": "1988",
        "lat": "28.932215",
        "Document": "docs/marc-steve-pace.pdf"
    },
    {
        "TroopNumber": "Pack 3192",
        "lng": "-93.425116",
        "State": "Minnesota",
        "NameOrID": "Paech, Manfred Wolfgang",
        "City": "Ogilvie",
        "Year": "1987",
        "lat": "45.828861",
        "Document": "docs/manfred-wolfgang-paech.pdf"
    },
    {
        "TroopNumber": "Pack 902",
        "lng": "-84.354069",
        "State": "Georgia",
        "NameOrID": "Palmer, Thomas",
        "City": "Jonesboro",
        "Year": "1990",
        "lat": "33.52198",
        "Document": "docs/thomas-palmer.pdf"
    },
    {
        "TroopNumber": "Troop 458",
        "lng": "-101.134114",
        "State": "North Dakota",
        "NameOrID": "Page, Dennis R.",
        "City": "Surrey",
        "Year": "1973",
        "lat": "48.231575",
        "Document": " docs/dennis-r-page.pdf"
    },
    {
        "TroopNumber": "Troop 282",
        "lng": "-94.415629",
        "State": "Missouri",
        "NameOrID": "Palmer, Harry A.",
        "City": "Independence",
        "Year": "1971",
        "lat": "39.093245",
        "Document": " docs/harry-a-palmer.pdf"
    },
    {
        "TroopNumber": "Troop 119",
        "lng": "",
        "State": "",
        "NameOrID": "Paige, Gilbert L.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/gilbert-l-paige.pdf"
    },
    {
        "TroopNumber": "Troop 580",
        "lng": "-70.545724",
        "State": "Maine",
        "NameOrID": "Parent, Timothy D.",
        "City": "Rumford",
        "Year": "1987",
        "lat": "44.54547",
        "Document": "docs/timothy-d-parent.pdf"
    },
    {
        "TroopNumber": "Pack 919",
        "lng": "-87.130142",
        "State": "Indiana",
        "NameOrID": "Parker, Rolland Keith",
        "City": "Boone Grove",
        "Year": "1990",
        "lat": "41.354721",
        "Document": "docs/rolland-keith-parker.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Pajot, John Walter",
        "City": "",
        "Year": "1973",
        "lat": "",
        "Document": " docs/john-walter-pajot.pdf"
    },
    {
        "TroopNumber": "Troop 919",
        "lng": "-87.130142",
        "State": "Indiana",
        "NameOrID": "Parker, Rolland Keith",
        "City": "Boone Grove",
        "Year": "1990",
        "lat": "41.354721",
        "Document": "docs/rolland-keith-parker.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Palank, Darrell",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/darrell-palank.pdf"
    },
    {
        "TroopNumber": "2430",
        "lng": "-84.607579",
        "State": "Michigan",
        "NameOrID": "Parks, Harold D.",
        "City": "Ithaca",
        "Year": "1989",
        "lat": "43.293675",
        "Document": "docs/harold-d-parks.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Palese, Ronald E.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/ronald-e-palese.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Parrish, Jerry Lee",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/jerry-lee-parrish.pdf"
    },
    {
        "TroopNumber": "Pack 227",
        "lng": "-93.505931",
        "State": "Louisiana",
        "NameOrID": "Parsons, Karolyn",
        "City": "Haughton",
        "Year": "1988",
        "lat": "32.533246",
        "Document": "docs/karolyn-parsons.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-112.075774",
        "State": "Arizona",
        "NameOrID": "Peabody, David W.",
        "City": "Phoenix",
        "Year": "1989",
        "lat": "33.44826",
        "Document": "docs/david-w-peabody.pdf"
    },
    {
        "TroopNumber": "Troop 69",
        "lng": "-86.811504",
        "State": "Alabama",
        "NameOrID": "Partain, Ricky Carl",
        "City": "Birmingham",
        "Year": "1984",
        "lat": "33.520295",
        "Document": " docs/ricky-carl-partain.pdf"
    },
    {
        "TroopNumber": "Troop 228",
        "lng": "",
        "State": "",
        "NameOrID": "Pearson, Bobby Gene",
        "City": "",
        "Year": "1986",
        "lat": "",
        "Document": "docs/bobby-gene-pearson.pdf"
    },
    {
        "TroopNumber": "Troop 75",
        "lng": "-86.682354",
        "State": "Alabama",
        "NameOrID": "Partain, Ricky Carl",
        "City": "Pinson",
        "Year": "1984",
        "lat": "33.69121",
        "Document": " docs/ricky-carl-partain.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Pease, David B.",
        "City": "",
        "Year": "1973",
        "lat": "",
        "Document": " docs/david-b-pease.pdf"
    },
    {
        "TroopNumber": "Troop 48",
        "lng": "-122.804539",
        "State": "Oregon",
        "NameOrID": "Patterson, William Ronald Daniel",
        "City": "Eagle Point",
        "Year": "1988",
        "lat": "42.473505",
        "Document": "docs/william-ronald-daniel-patterson.pdf"
    },
    {
        "TroopNumber": "Troop 255",
        "lng": "-121.491014",
        "State": "California",
        "NameOrID": "Pease, David B.",
        "City": "Sacramento",
        "Year": "1973",
        "lat": "38.579065",
        "Document": " docs/david-b-pease.pdf"
    },
    {
        "TroopNumber": "Troop 337",
        "lng": "-110.969754",
        "State": "Arizona",
        "NameOrID": "Paul, Les",
        "City": "Tucson",
        "Year": "1989",
        "lat": "32.221553",
        "Document": "docs/les-paul.pdf"
    },
    {
        "TroopNumber": "Pack 3084",
        "lng": "-121.300814",
        "State": "California",
        "NameOrID": "Peat, David A.",
        "City": "Galt",
        "Year": "1988",
        "lat": "38.252745",
        "Document": "docs/david-a-peat.pdf"
    },
    {
        "TroopNumber": "Troop 602",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Pawlowski, Arnold Lee",
        "City": "Jacksonville",
        "Year": "1987",
        "lat": "30.33147",
        "Document": "docs/arnold-lee-pawlowski.pdf"
    },
    {
        "TroopNumber": "Troop 119",
        "lng": "-121.300814",
        "State": "California",
        "NameOrID": "Peat, David A.",
        "City": "Galt",
        "Year": "1988",
        "lat": "38.252745",
        "Document": "docs/david-a-peat.pdf"
    },
    {
        "TroopNumber": "Troop 355",
        "lng": "-98.707034",
        "State": "Oklahoma",
        "NameOrID": "Payne, Larry James",
        "City": "Weatherford",
        "Year": "1988",
        "lat": "35.52608",
        "Document": "docs/larry-james-payne.pdf"
    },
    {
        "TroopNumber": "Pack 763",
        "lng": "-122.796153",
        "State": "Oregon",
        "NameOrID": "Peetz, James Frederick",
        "City": "Beaverton",
        "Year": "1988",
        "lat": "45.484895",
        "Document": "docs/james-frederick-peetz.pdf"
    },
    {
        "TroopNumber": "Pack 3048",
        "lng": "-85.835699",
        "State": "Alabama",
        "NameOrID": "Payne, Steven E.",
        "City": "Oxford",
        "Year": "1988",
        "lat": "33.613075",
        "Document": "docs/steven-e-payne.pdf"
    },
    {
        "TroopNumber": "District 9",
        "lng": "-81.236188",
        "State": "South Carolina",
        "NameOrID": "Pelkey, Giles",
        "City": "Lexington ",
        "Year": "1978",
        "lat": "33.980619",
        "Document": "docs/giles-pelkey.pdf"
    },
    {
        "TroopNumber": "Troop 716",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Pence, Edward A.",
        "City": "Chicago",
        "Year": "1990",
        "lat": "41.88415",
        "Document": "docs/edward-a-pence.pdf"
    },
    {
        "TroopNumber": "Troop 187",
        "lng": "-111.695419",
        "State": "Utah",
        "NameOrID": "Peterson, Brian R.",
        "City": "Orem",
        "Year": "1989",
        "lat": "40.29806",
        "Document": "docs/brian-r-peterson.pdf"
    },
    {
        "TroopNumber": "Troop 43",
        "lng": "-88.309329",
        "State": "Illinois",
        "NameOrID": "Petitt, Alan J.",
        "City": "Batavia",
        "Year": "1989",
        "lat": "41.849995",
        "Document": "docs/alan-j-petitt.pdf"
    },
    {
        "TroopNumber": "Pack 112",
        "lng": "-88.309329",
        "State": "Illinois",
        "NameOrID": "Petitt, Alan J.",
        "City": "Batavia",
        "Year": "1989",
        "lat": "41.849995",
        "Document": "docs/alan-j-petitt.pdf"
    },
    {
        "TroopNumber": "Troop 471",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Petrauskas, Thomas J.",
        "City": "Chicago",
        "Year": "1989",
        "lat": "41.88415",
        "Document": "docs/thomas-j-petrauskas.pdf"
    },
    {
        "TroopNumber": "Pack 343",
        "lng": "-89.210729",
        "State": "Wisconsin",
        "NameOrID": "Peot, Frank R.",
        "City": "Sun Prairie",
        "Year": "1990",
        "lat": "43.18296",
        "Document": "docs/frank-r-peot.pdf"
    },
    {
        "TroopNumber": "Troop 100",
        "lng": "-122.329439",
        "State": "Washington",
        "NameOrID": "Petrie, John Phillip",
        "City": "Seattle",
        "Year": "1988",
        "lat": "47.60356",
        "Document": "docs/john-phillip-petrie.pdf"
    },
    {
        "TroopNumber": "District 13",
        "lng": "-98.494619",
        "State": "Texas",
        "NameOrID": "Perales, Alonso M.",
        "City": "San Antonio",
        "Year": "1980",
        "lat": "29.42449",
        "Document": " docs/alonso-m-perales.pdf"
    },
    {
        "TroopNumber": "Troop 51",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Phegley, Luvern W.",
        "City": "St. Louis",
        "Year": "1976",
        "lat": "38.62774",
        "Document": "docs/luvern-w-phegley.pdf"
    },
    {
        "TroopNumber": "Troop 340",
        "lng": "-82.799321",
        "State": "Florida",
        "NameOrID": "Perkins, Herman Myles",
        "City": "Seminole",
        "Year": "1972",
        "lat": "27.840289",
        "Document": " docs/herman-myles-perkins.pdf"
    },
    {
        "TroopNumber": "Explorer 733",
        "lng": "-117.824319",
        "State": "California",
        "NameOrID": "Phillips, David W.",
        "City": "Yorba Linda",
        "Year": "1977",
        "lat": "33.89117",
        "Document": " docs/david-w-phillips.pdf"
    },
    {
        "TroopNumber": "Pack 151",
        "lng": "-93.403654",
        "State": "Minnesota",
        "NameOrID": "Permar, Bernard J.",
        "City": "International  Falls",
        "Year": "1978",
        "lat": "48.602605",
        "Document": "docs/bernard-j-permar.pdf"
    },
    {
        "TroopNumber": "Troop 40",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Phillips, Jeffrey Patrick",
        "City": "Houston",
        "Year": "1990",
        "lat": "29.76045",
        "Document": "docs/jeffrey-patrick-phillips.pdf"
    },
    {
        "TroopNumber": "Troop 258",
        "lng": "-74.157778",
        "State": "New Jersey",
        "NameOrID": "Perrucci, Thomas J.",
        "City": "Mahwah",
        "Year": "1983",
        "lat": "41.085015",
        "Document": " docs/thomas-j-perrucci.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-122.329439",
        "State": "Washington",
        "NameOrID": "Phillips, Gary Brian",
        "City": "Seattle",
        "Year": "1990",
        "lat": "47.60356",
        "Document": "docs/gary-brian-phillips.pdf"
    },
    {
        "TroopNumber": "Not Applicable ",
        "lng": "",
        "State": "",
        "NameOrID": "Phillips, Bryan G.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/bryan-g-phillips.pdf"
    },
    {
        "TroopNumber": "Troop 295",
        "lng": "-93.757546",
        "State": "Iowa",
        "NameOrID": "Phipps, Walter J.",
        "City": "West Des Moines",
        "Year": "1987",
        "lat": "41.567657",
        "Document": "docs/walter-j-phipps.pdf"
    },
    {
        "TroopNumber": "Troop 215",
        "lng": "",
        "State": "California",
        "NameOrID": "Pilkington, Gregory Alan",
        "City": "",
        "Year": "1973",
        "lat": "",
        "Document": " docs/gregory-alan-pilkington.pdf"
    },
    {
        "TroopNumber": "Pack 422",
        "lng": "-94.415629",
        "State": "Missouri",
        "NameOrID": "Picon, Frank A.",
        "City": "Independence",
        "Year": "1982",
        "lat": "39.093245",
        "Document": " docs/frank-a-picon.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Piloco, Arthur",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/arthur-piloco.pdf"
    },
    {
        "TroopNumber": "Troop 1776",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Pierson, Barry N.",
        "City": "Houston",
        "Year": "1988",
        "lat": "29.76045",
        "Document": "docs/barry-n-pierson.pdf"
    },
    {
        "TroopNumber": "Troop 92",
        "lng": "-84.031804",
        "State": "Michigan",
        "NameOrID": "Pincomb, William T.",
        "City": "Vernon",
        "Year": "1991",
        "lat": "42.939204",
        "Document": "docs/william-t-pincomb.pdf"
    },
    {
        "TroopNumber": "499",
        "lng": "-93.217579",
        "State": "Minnesota",
        "NameOrID": "Pietz, Floyd Fenten",
        "City": "Apple Valley",
        "Year": "1983",
        "lat": "44.74275",
        "Document": " docs/floyd-fenten-pietz.pdf"
    },
    {
        "TroopNumber": "Pack 3027",
        "lng": "-90.294494",
        "State": "Missouri",
        "NameOrID": "Pinson, David D.",
        "City": "Fredericktown",
        "Year": "1986",
        "lat": "37.558605",
        "Document": "docs/david-d-pinson.pdf"
    },
    {
        "TroopNumber": "Pack 9",
        "lng": "-117.18258",
        "State": "California",
        "NameOrID": "Pike, Thomas J.",
        "City": "Redlands",
        "Year": "1990",
        "lat": "34.055282",
        "Document": "docs/thomas-j-pike.pdf"
    },
    {
        "TroopNumber": "Troop 133",
        "lng": "-75.209059",
        "State": "Pennsylvania",
        "NameOrID": "Pitman, Lester R.",
        "City": "Chalfont",
        "Year": "1989",
        "lat": "40.287433",
        "Document": "docs/lester-r-pitman.pdf"
    },
    {
        "TroopNumber": "Pack 3093",
        "lng": "-94.48357",
        "State": "Missouri",
        "NameOrID": "Potter, Darrell G.",
        "City": "Noel",
        "Year": "1989",
        "lat": "36.544846",
        "Document": "docs/darrell-g-potter.pdf"
    },
    {
        "TroopNumber": "Troop 333",
        "lng": "-76.285069",
        "State": "Virginia",
        "NameOrID": "Pitz, Michael T.",
        "City": "Norfolk",
        "Year": "1991",
        "lat": "36.846825",
        "Document": "docs/michael-t-pitz.pdf"
    },
    {
        "TroopNumber": "Troop 64",
        "lng": "-105.100504",
        "State": "Colorado",
        "NameOrID": "Potter, Raymond",
        "City": "Longmont",
        "Year": "1972",
        "lat": "40.16394",
        "Document": " docs/raymond-potter.pdf"
    },
    {
        "TroopNumber": "Troop 117",
        "lng": "-91.673759",
        "State": "Iowa",
        "NameOrID": "Poland, James A.",
        "City": "Cedar Rapids",
        "Year": "1975",
        "lat": "41.976345",
        "Document": " docs/james-a-poland.pdf"
    },
    {
        "TroopNumber": "Pack 17",
        "lng": "-91.673759",
        "State": "Iowa",
        "NameOrID": "Poland, James A.",
        "City": "Cedar Rapids",
        "Year": "1975",
        "lat": "41.976345",
        "Document": " docs/james-a-poland.pdf"
    },
    {
        "TroopNumber": "Troop 232",
        "lng": "-121.654819",
        "State": "California",
        "NameOrID": "Polinski, Albert A.",
        "City": "Salinas",
        "Year": "1984",
        "lat": "36.67088",
        "Document": " docs/albert-a-polinski.pdf"
    },
    {
        "TroopNumber": "Troop 1",
        "lng": "-116.392564",
        "State": "Idaho",
        "NameOrID": "Powell, James L.",
        "City": "Meridian ",
        "Year": "1980",
        "lat": "43.611025",
        "Document": " docs/james-l-powell.pdf"
    },
    {
        "TroopNumber": "Troop 42",
        "lng": "-91.673759",
        "State": "Iowa",
        "NameOrID": "Pollock, Richard Lee",
        "City": "Cedar Rapids",
        "Year": "1985",
        "lat": "41.976345",
        "Document": " docs/richard-lee-pollock.pdf"
    },
    {
        "TroopNumber": "Pack 160",
        "lng": "-87.906844",
        "State": "Wisconsin",
        "NameOrID": "Powers, John F.",
        "City": "Milwaukee",
        "Year": "1991",
        "lat": "43.04181",
        "Document": "docs/john-f-powers.pdf"
    },
    {
        "TroopNumber": "Explorer 697",
        "lng": "-81.377389",
        "State": "Florida",
        "NameOrID": "Prentiss, William C.",
        "City": "Orlando",
        "Year": "1990",
        "lat": "28.538235",
        "Document": "docs/william-c-prentiss.pdf"
    },
    {
        "TroopNumber": "Troop 254",
        "lng": "-97.091169",
        "State": "Texas",
        "NameOrID": "Pomeroy, Robert R.",
        "City": "Euless",
        "Year": "1987",
        "lat": "32.839179",
        "Document": "docs/robert-r-pomeroy.pdf"
    },
    {
        "TroopNumber": "Explorer 161",
        "lng": "-88.930049",
        "State": "Illinois",
        "NameOrID": "Presson, Rex D.",
        "City": "Marion",
        "Year": "1989",
        "lat": "37.73082",
        "Document": "docs/rex-d-presson.pdf"
    },
    {
        "TroopNumber": "Troop 10",
        "lng": "-95.114924",
        "State": "Minnesota",
        "NameOrID": "Poncin, Gary C.",
        "City": "Windom",
        "Year": "1989",
        "lat": "43.864825",
        "Document": "docs/gary-c-poncin.pdf"
    },
    {
        "TroopNumber": "Troop 101",
        "lng": "-88.930049",
        "State": "Illinois",
        "NameOrID": "Presson, Rex D.",
        "City": "Marion",
        "Year": "1989",
        "lat": "37.73082",
        "Document": "docs/rex-d-presson.pdf"
    },
    {
        "TroopNumber": "Troop 110",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Preze, Kipp",
        "City": "Kansas City",
        "Year": "1989",
        "lat": "39.10296",
        "Document": "docs/kipp-preze.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Porter, Malcolm W.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/malcolm-w-porter.pdf"
    },
    {
        "TroopNumber": "Camp 4120",
        "lng": "-93.953584",
        "State": "Missouri",
        "NameOrID": "Price, Vernon Lee",
        "City": "Odessa",
        "Year": "1991",
        "lat": "39.000175",
        "Document": "docs/vernon-lee-price.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Price, Byard J.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/byard-j-price.pdf"
    },
    {
        "TroopNumber": "Troop 582",
        "lng": "-90.077749",
        "State": "Louisiana",
        "NameOrID": "Primeaux, Whitney Joseph",
        "City": "New Orleans",
        "Year": "1972",
        "lat": "29.9537",
        "Document": " docs/whitney-joseph-primeaux.pdf"
    },
    {
        "TroopNumber": "Pack 3",
        "lng": "-93.220111",
        "State": "Louisiana",
        "NameOrID": "Primeaux, Whitney Joseph",
        "City": "Lake Charles",
        "Year": "1972",
        "lat": "30.224033",
        "Document": " docs/whitney-joseph-primeaux.pdf"
    },
    {
        "TroopNumber": "Troop 87",
        "lng": "-88.259163",
        "State": "Wisconsin",
        "NameOrID": "Prudlow, Patrick E.",
        "City": "Pewaukee",
        "Year": "1974",
        "lat": "43.082219",
        "Document": " docs/patrick-e-prudlow.pdf"
    },
    {
        "TroopNumber": "Troop 442",
        "lng": "-116.566254",
        "State": "Idaho",
        "NameOrID": "Pringle, Barry Charles",
        "City": "St. Maries",
        "Year": "1982",
        "lat": "47.316245",
        "Document": " docs/barry-charles-pringle.pdf"
    },
    {
        "TroopNumber": "Troop 1549",
        "lng": "-122.627469",
        "State": "Washington",
        "NameOrID": "Puerner, Lester A.",
        "City": "Bremerton",
        "Year": "1984",
        "lat": "47.565005",
        "Document": " docs/lester-a-puerner.pdf"
    },
    {
        "TroopNumber": "Troop 87",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "Prior, Robert G.",
        "City": "Syracuse",
        "Year": "1990",
        "lat": "43.04999",
        "Document": "docs/robert-g-prior.pdf"
    },
    {
        "TroopNumber": "Troop 4935",
        "lng": "-121.302544",
        "State": "California",
        "NameOrID": "Pugh, Ronald Kelly",
        "City": "Lathrop",
        "Year": "1990",
        "lat": "37.804395",
        "Document": "docs/ronald-kelly-pugh.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-76.425858",
        "State": "New York",
        "NameOrID": "Prior, Robert G.",
        "City": "Skaneateles",
        "Year": "1990",
        "lat": "42.945443",
        "Document": "docs/robert-g-prior.pdf"
    },
    {
        "TroopNumber": "Pack 4935",
        "lng": "-121.302544",
        "State": "California",
        "NameOrID": "Pugh, Ronald Kelly",
        "City": "Lathrop",
        "Year": "1990",
        "lat": "37.804395",
        "Document": "docs/ronald-kelly-pugh.pdf"
    },
    {
        "TroopNumber": "Explorer 200",
        "lng": "-73.698759",
        "State": "New York",
        "NameOrID": "Pugh, Gordon",
        "City": "Manhasset",
        "Year": "1975",
        "lat": "40.797895",
        "Document": " docs/gordon-pugh.pdf"
    },
    {
        "TroopNumber": "Troop 1604",
        "lng": "-83.142444",
        "State": "Michigan",
        "NameOrID": "Quick, Richard J.",
        "City": "Royal Oak",
        "Year": "1978",
        "lat": "42.488155",
        "Document": "docs/richard-j-quick.pdf"
    },
    {
        "TroopNumber": "Troop 243",
        "lng": "-77.945992",
        "State": "North Carolina",
        "NameOrID": "Quillin, James H.",
        "City": "Wilmington ",
        "Year": "1973",
        "lat": "34.23497",
        "Document": " docs/james-h-quillin.pdf"
    },
    {
        "TroopNumber": "Explorer 546",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Quinn, Michael",
        "City": "Philadelphia",
        "Year": "1972",
        "lat": "39.95228",
        "Document": " docs/michael-quinn.pdf"
    },
    {
        "TroopNumber": "Troop 201",
        "lng": "-73.645399",
        "State": "New York",
        "NameOrID": "Quintano, Anders",
        "City": "Williston Park",
        "Year": "1989",
        "lat": "40.75778",
        "Document": "docs/anders-quintano.pdf"
    },
    {
        "TroopNumber": "Troop 101",
        "lng": "-71.833923",
        "State": "Massachusetts",
        "NameOrID": "Pulling, Aubrey",
        "City": "Auburn",
        "Year": "1985",
        "lat": "42.196208",
        "Document": " docs/aubrey-pulling.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Rader, Thomas E.",
        "City": "",
        "Year": "1975",
        "lat": "",
        "Document": " docs/thomas-e-rader.pdf"
    },
    {
        "TroopNumber": "Troop 85",
        "lng": "-94.101584",
        "State": "Texas",
        "NameOrID": "Purcell, Andrew Allen",
        "City": "Beaumont",
        "Year": "1983",
        "lat": "30.08615",
        "Document": " docs/andrew-allen-purcell.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-90.639329",
        "State": "Illinois",
        "NameOrID": "Radloff, Gary L.",
        "City": "East Dubuque",
        "Year": "1987",
        "lat": "42.493704",
        "Document": "docs/gary-l-radloff.pdf"
    },
    {
        "TroopNumber": "Troop 755",
        "lng": "-83.364333",
        "State": "Michigan",
        "NameOrID": "Purdy, Robert",
        "City": "West Bloomfield",
        "Year": "1987",
        "lat": "42.540229",
        "Document": "docs/robert-purdy.pdf"
    },
    {
        "TroopNumber": "Troop 388",
        "lng": "-95.940469",
        "State": "Nebraska",
        "NameOrID": "Ramos, David A.",
        "City": "Omaha",
        "Year": "1988",
        "lat": "41.260675",
        "Document": "docs/david-a-ramos.pdf"
    },
    {
        "TroopNumber": "Troop 121",
        "lng": "-82.010226",
        "State": "Ohio",
        "NameOrID": "Randles, Merle R.",
        "City": "Dresden",
        "Year": "1988",
        "lat": "40.119923",
        "Document": "docs/merle-r-randles.pdf"
    },
    {
        "TroopNumber": "Troop 740",
        "lng": "-76.937269",
        "State": "Maryland",
        "NameOrID": "Rankin, David MacDonald",
        "City": "College Park",
        "Year": "1987",
        "lat": "38.980725",
        "Document": "docs/david-macdonald-rankin.pdf"
    },
    {
        "TroopNumber": "Pack 75",
        "lng": "-82.404264",
        "State": "Ohio",
        "NameOrID": "Purtell, John R.",
        "City": "Newark",
        "Year": "1988",
        "lat": "40.060215",
        "Document": "docs/john-r-purtell.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-82.404264",
        "State": "Ohio",
        "NameOrID": "Purtell, John R.",
        "City": "Newark",
        "Year": "1988",
        "lat": "40.060215",
        "Document": "docs/john-r-purtell.pdf"
    },
    {
        "TroopNumber": "Troop 140",
        "lng": "-80.637628",
        "State": "North Carolina",
        "NameOrID": "Rapson, Eugene Willie",
        "City": "Kannapolis",
        "Year": "1990",
        "lat": "35.504141",
        "Document": "docs/eugene-willie-rapson.pdf"
    },
    {
        "TroopNumber": "36",
        "lng": "-94.856074",
        "State": "Missouri",
        "NameOrID": "Purvis, Daniel K.",
        "City": "St. Joseph",
        "Year": "1991",
        "lat": "39.7666",
        "Document": "docs/daniel-k-purvis.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-147.722059",
        "State": "Alaska",
        "NameOrID": "Rarick, Kenneth R.",
        "City": "Fairbanks",
        "Year": "1982",
        "lat": "64.845085",
        "Document": " docs/kenneth-r-rarick.pdf"
    },
    {
        "TroopNumber": "Troop 96",
        "lng": "-93.712435",
        "State": "Iowa",
        "NameOrID": "Putney, Robert E.",
        "City": "Urbandale",
        "Year": "1988",
        "lat": "41.625058",
        "Document": "docs/robert-e-putney.pdf"
    },
    {
        "TroopNumber": "Troop 117",
        "lng": "-81.371569",
        "State": "Ohio",
        "NameOrID": "Ratchford, Richard W.",
        "City": "Canton",
        "Year": "1983",
        "lat": "40.79781",
        "Document": " docs/richard-w-ratchford.pdf"
    },
    {
        "TroopNumber": "312",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Pyatt, Michael J.",
        "City": "Philadelphia",
        "Year": "1977",
        "lat": "39.95228",
        "Document": " docs/michael-j-pyatt.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-91.392513",
        "State": "Wisconsin",
        "NameOrID": "Razor, Jim",
        "City": "Chippewa Falls",
        "Year": "1989",
        "lat": "44.937469",
        "Document": "docs/jim-razor.pdf"
    },
    {
        "TroopNumber": "Explorer 152",
        "lng": "-122.160794",
        "State": "California",
        "NameOrID": "Reade, Gordon",
        "City": "Palo Alto",
        "Year": "1988",
        "lat": "37.44466",
        "Document": "docs/gordon-reade.pdf"
    },
    {
        "TroopNumber": "Pack 3075",
        "lng": "-119.698869",
        "State": "California",
        "NameOrID": "Ratliff, Garth E.",
        "City": "Santa Barbara",
        "Year": "1989",
        "lat": "34.419255",
        "Document": "docs/garth-e-ratliff.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-91.673759",
        "State": "Iowa",
        "NameOrID": "Redacted, Redacted",
        "City": "Cedar Rapids",
        "Year": "1981",
        "lat": "41.976345",
        "Document": " docs/redacted-redacted.pdf"
    },
    {
        "TroopNumber": "Pack 156",
        "lng": "-81.506629",
        "State": "Florida",
        "NameOrID": "Raulerson, Joel Emmett",
        "City": "Avon Park",
        "Year": "1988",
        "lat": "27.595615",
        "Document": "docs/joel-emmett-raulerson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Redacted, Redacted",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": "docs/redacted-redacted-1977.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Raynard, Mark A.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/mark-a-raynard.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-81.371569",
        "State": "Ohio",
        "NameOrID": "Redacted, Redacted",
        "City": "Canton",
        "Year": "1975",
        "lat": "40.79781",
        "Document": "docs/redacted-redacted-ohio.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Francis, Allan R.",
        "City": "",
        "Year": "1981",
        "lat": "",
        "Document": "docs/allan-r-francis.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Redacted, Redacted",
        "City": "",
        "Year": "1975",
        "lat": "",
        "Document": "docs/redacted-redacted-1975.pdf"
    },
    {
        "TroopNumber": "Pack 702",
        "lng": "-97.335579",
        "State": "Kansas",
        "NameOrID": "Reeves, George W.",
        "City": "Wichita",
        "Year": "1983",
        "lat": "37.68698",
        "Document": " docs/george-w-reeves.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Redacted, Redacted",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": "docs/redacted-redacted-tenn.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Redacted, Redacted",
        "City": "",
        "Year": "1983",
        "lat": "",
        "Document": "docs/redacted-redacted-1983.pdf"
    },
    {
        "TroopNumber": "Explorer 2276",
        "lng": "-83.946673",
        "State": "Michigan",
        "NameOrID": "Reeves, David Paul",
        "City": "Saginaw",
        "Year": "1990",
        "lat": "43.419181",
        "Document": "docs/david-paul-reeves.pdf"
    },
    {
        "TroopNumber": "Troop 1600",
        "lng": "-87.752689",
        "State": "Illinois",
        "NameOrID": "Hacker, Thomas E.",
        "City": "Oak Lawn",
        "Year": "1970",
        "lat": "41.72029",
        "Document": " docs/redacted-thomas-e-redacted-hacker.pdf"
    },
    {
        "TroopNumber": "Troop 746",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Reeves, Robert",
        "City": "Dallas",
        "Year": "1985",
        "lat": "32.778155",
        "Document": " docs/robert-reeves.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Hacker, Thomas E.",
        "City": "Indianapolis",
        "Year": "1970",
        "lat": "39.76691",
        "Document": " docs/redacted-thomas-e-redacted-hacker.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Reich, Lee A.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/lee-a-reich.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-87.995949",
        "State": "Illinois",
        "NameOrID": "Hacker, Thomas E.",
        "City": "Arlington Heights",
        "Year": "1970",
        "lat": "42.138015",
        "Document": " docs/redacted-thomas-e-redacted-hacker.pdf"
    },
    {
        "TroopNumber": "Troop 811",
        "lng": "-97.38762",
        "State": "Texas",
        "NameOrID": "Reid, Larry W.",
        "City": "Cleburne",
        "Year": "1977",
        "lat": "32.350398",
        "Document": " docs/larry-w-reid.pdf"
    },
    {
        "TroopNumber": "Troop 40",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Reed, James William",
        "City": "Kansas City",
        "Year": "1976",
        "lat": "39.10296",
        "Document": " docs/james-william-reed.pdf"
    },
    {
        "TroopNumber": "Troop 1341",
        "lng": "-117.039187",
        "State": "California",
        "NameOrID": "Reed, David A.",
        "City": "Wonder Valley",
        "Year": "1988",
        "lat": "33.22227",
        "Document": "docs/david-a-reed.pdf"
    },
    {
        "TroopNumber": "Pack 275",
        "lng": "-95.931384",
        "State": "Iowa",
        "NameOrID": "Reinbold, Gilbert A.",
        "City": "Smithland",
        "Year": "1973",
        "lat": "42.22889",
        "Document": " docs/gilbert-a-reinbold.pdf"
    },
    {
        "TroopNumber": "Explorer 32",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Reed, Charles Anthony",
        "City": "St. Louis",
        "Year": "1988",
        "lat": "38.62774",
        "Document": "docs/charles-anthony-reed.pdf"
    },
    {
        "TroopNumber": "Troop 34",
        "lng": "-94.365369",
        "State": "Missouri",
        "NameOrID": "Remington, Rod L.",
        "City": "Neosho",
        "Year": "1980",
        "lat": "36.868482",
        "Document": " docs/rod-l-remington.pdf"
    },
    {
        "TroopNumber": "Troop 702",
        "lng": "-97.335579",
        "State": "Kansas",
        "NameOrID": "Reeves, George W.",
        "City": "Wichita",
        "Year": "1983",
        "lat": "37.68698",
        "Document": " docs/george-w-reeves.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-91.638594",
        "State": "Minnesota",
        "NameOrID": "Repice, Joseph Peter",
        "City": "Winona",
        "Year": "1990",
        "lat": "44.051295",
        "Document": "docs/joseph-peter-repice.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Revals, Charles J.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/charles-j-revals.pdf"
    },
    {
        "TroopNumber": "Troop 491",
        "lng": "-80.476314",
        "State": "Pennsylvania",
        "NameOrID": "Ricci, John Stephen",
        "City": "Connelton",
        "Year": "1986",
        "lat": "40.798925",
        "Document": "docs/john-stephen-ricci.pdf"
    },
    {
        "TroopNumber": "Explorer 521",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Rex, John L.",
        "City": "Rochester",
        "Year": "1974",
        "lat": "43.1555",
        "Document": " docs/john-l-rex.pdf"
    },
    {
        "TroopNumber": "Troop 762",
        "lng": "-73.326921",
        "State": "Vermont",
        "NameOrID": "Riccio, James E.",
        "City": "Banton",
        "Year": "1987",
        "lat": "44.149866",
        "Document": "docs/james-e-riccio.pdf"
    },
    {
        "TroopNumber": "Troop 267",
        "lng": "-94.415629",
        "State": "Missouri",
        "NameOrID": "Rice, Richard A.",
        "City": "Independence",
        "Year": "1990",
        "lat": "39.093245",
        "Document": "docs/richard-a-rice.pdf"
    },
    {
        "TroopNumber": "Explorer 747",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Rex, John L.",
        "City": "Rochester",
        "Year": "1974",
        "lat": "43.1555",
        "Document": " docs/john-l-rex.pdf"
    },
    {
        "TroopNumber": "Troop 124",
        "lng": "",
        "State": "",
        "NameOrID": "Rice, Fred L.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/fred-l-rice.pdf"
    },
    {
        "TroopNumber": "Troop 260",
        "lng": "-123.199129",
        "State": "Oregon",
        "NameOrID": "Reynolds, Bruce T.",
        "City": "McMinnville",
        "Year": "1987",
        "lat": "45.20894",
        "Document": "docs/bruce-t-reynolds.pdf"
    },
    {
        "TroopNumber": "Pack 240",
        "lng": "-122.990499",
        "State": "Oregon",
        "NameOrID": "Richards, James Ross",
        "City": "Hillsboro",
        "Year": "1988",
        "lat": "45.522675",
        "Document": "docs/james-ross-richards.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-95.979024",
        "State": "Oklahoma",
        "NameOrID": "Reynolds, Robert E.",
        "City": "Bartlesville",
        "Year": "1989",
        "lat": "36.74678",
        "Document": "docs/robert-e-reynolds.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-111.668509",
        "State": "Utah",
        "NameOrID": "Richins, Leroy H.",
        "City": "Provo",
        "Year": "1976",
        "lat": "40.233775",
        "Document": " docs/leroy-h-richins.pdf"
    },
    {
        "TroopNumber": "Pack 771",
        "lng": "",
        "State": "",
        "NameOrID": "Rhodes, Howard Alan",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/howard-alan-rhodes.pdf"
    },
    {
        "TroopNumber": "Troop 597",
        "lng": "-75.726649",
        "State": "Pennsylvania",
        "NameOrID": "Rickert, Scott A.",
        "City": "Douglassville",
        "Year": "1990",
        "lat": "40.25712",
        "Document": "docs/scott-a-rickert.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Rhodes, Terry James",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/terry-james-rhodes.pdf"
    },
    {
        "TroopNumber": "Troop 41",
        "lng": "-85.660384",
        "State": "Indiana",
        "NameOrID": "Rickman, Myron J.",
        "City": "Marion",
        "Year": "1988",
        "lat": "40.558805",
        "Document": "docs/myron-j-rickman.pdf"
    },
    {
        "TroopNumber": "Troop 50",
        "lng": "-81.975309",
        "State": "Georgia",
        "NameOrID": "Rhymer, Earl Andrew",
        "City": "Augusta",
        "Year": "1987",
        "lat": "33.47909",
        "Document": "docs/earl-andrew-rhymer.pdf"
    },
    {
        "TroopNumber": "Troop 16",
        "lng": "-103.206139",
        "State": "Colorado",
        "NameOrID": "Rieb, Mark A.",
        "City": "Sterling",
        "Year": "1988",
        "lat": "40.625945",
        "Document": "docs/mark-a-rieb.pdf"
    },
    {
        "TroopNumber": "Pack 552",
        "lng": "-89.068349",
        "State": "Illinois",
        "NameOrID": "Riffe, David Michael",
        "City": "Rochelle",
        "Year": "1985",
        "lat": "41.932375",
        "Document": " docs/david-michael-riffe.pdf"
    },
    {
        "TroopNumber": "Troop 339",
        "lng": "-86.512133",
        "State": "Tennessee",
        "NameOrID": "Rinehart, Victor K.",
        "City": "Smyrna",
        "Year": "1989",
        "lat": "35.974664",
        "Document": "docs/victor-k-rinehart.pdf"
    },
    {
        "TroopNumber": "Troop 400",
        "lng": "-73.796815",
        "State": "New York",
        "NameOrID": "Rigby, David W.",
        "City": "Scarsdale",
        "Year": "1976",
        "lat": "40.988237",
        "Document": " docs/david-w-rigby.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-71.106019",
        "State": "Massachusetts",
        "NameOrID": "Rileigh, Charles E.",
        "City": "Cambridge",
        "Year": "1986",
        "lat": "42.36679",
        "Document": "docs/charles-e-rileigh.pdf"
    },
    {
        "TroopNumber": "Pack 206",
        "lng": "-97.727109",
        "State": "Texas",
        "NameOrID": "Rios, Edward T.",
        "City": "Killeen",
        "Year": "1991",
        "lat": "31.116835",
        "Document": "docs/edward-t-rios.pdf"
    },
    {
        "TroopNumber": "Pack 3701",
        "lng": "-111.888229",
        "State": "Utah",
        "NameOrID": "Rimmasch, Phillip K.",
        "City": "Salt Lake City",
        "Year": "1988",
        "lat": "40.759505",
        "Document": "docs/phillip-k-rimmasch.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-74.276924",
        "State": "New Jersey",
        "NameOrID": "Ripley, Steven Michael",
        "City": "Maplewood",
        "Year": "1985",
        "lat": "40.73115",
        "Document": " docs/steven-michael-ripley.pdf"
    },
    {
        "TroopNumber": "Troop 75",
        "lng": "-117.936159",
        "State": "California",
        "NameOrID": "Rincon, Ed",
        "City": "Garden Grove",
        "Year": "1990",
        "lat": "33.77607",
        "Document": "docs/ed-rincon.pdf"
    },
    {
        "TroopNumber": "Explorer 106",
        "lng": "-86.512133",
        "State": "Tennessee",
        "NameOrID": "Rinehart, Victor K.",
        "City": "Smyrna",
        "Year": "1989",
        "lat": "35.974664",
        "Document": "docs/victor-k-rinehart.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 724",
        "lng": "-122.840484",
        "State": "Oregon",
        "NameOrID": "Rise, James Martin",
        "City": "Sherwood",
        "Year": "1987",
        "lat": "45.356495",
        "Document": "docs/james-martin-rise.pdf"
    },
    {
        "TroopNumber": "Troop 3064",
        "lng": "-104.821814",
        "State": "Colorado",
        "NameOrID": "Riste, Michael M.",
        "City": "Colorado Springs",
        "Year": "1981",
        "lat": "38.83345",
        "Document": " docs/michael-m-riste.pdf"
    },
    {
        "TroopNumber": "Troop 316",
        "lng": "-79.794704",
        "State": "North Carolina",
        "NameOrID": "Roberts, Douglas",
        "City": "Greensboro",
        "Year": "1986",
        "lat": "36.068994",
        "Document": "docs/douglas-roberts.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "Roberts, Matthew",
        "City": "Worcester",
        "Year": "1978",
        "lat": "42.263415",
        "Document": "docs/matthew-roberts.pdf"
    },
    {
        "TroopNumber": "Pack 430",
        "lng": "",
        "State": "",
        "NameOrID": "Rivera, Alfred A.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/alfred-a-rivera.pdf"
    },
    {
        "TroopNumber": "Pack 180",
        "lng": "-121.993429",
        "State": "California",
        "NameOrID": "Roberts, William E.",
        "City": "Vacaville",
        "Year": "1988",
        "lat": "38.351355",
        "Document": "docs/william-e-roberts.pdf"
    },
    {
        "TroopNumber": "Troop 101",
        "lng": "",
        "State": "",
        "NameOrID": "Rivera, Eduardo Pabon",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/eduardo-pabon-rivera.pdf"
    },
    {
        "TroopNumber": "Pack 3077",
        "lng": "-121.587335",
        "State": "California",
        "NameOrID": "Roberts, John F.",
        "City": "Marysville",
        "Year": "1988",
        "lat": "39.144961",
        "Document": "docs/john-f-roberts.pdf"
    },
    {
        "TroopNumber": "Troop 373",
        "lng": "-94.419419",
        "State": "Missouri",
        "NameOrID": "Roberts, Jack K.",
        "City": "Liberty",
        "Year": "1988",
        "lat": "39.245415",
        "Document": "docs/jack-k-roberts.pdf"
    },
    {
        "TroopNumber": "Pack 3139",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Rivers, John T.",
        "City": "Jacksonville",
        "Year": "1989",
        "lat": "30.33147",
        "Document": "docs/john-t-rivers.pdf"
    },
    {
        "TroopNumber": "Troop 372",
        "lng": "-94.419419",
        "State": "Missouri",
        "NameOrID": "Roberts, Jack K.",
        "City": "Liberty",
        "Year": "1988",
        "lat": "39.245415",
        "Document": "docs/jack-k-roberts.pdf"
    },
    {
        "TroopNumber": "Troop 374",
        "lng": "-94.419419",
        "State": "Missouri",
        "NameOrID": "Roberts, Jack K.",
        "City": "Liberty",
        "Year": "1988",
        "lat": "39.245415",
        "Document": "docs/jack-k-roberts.pdf"
    },
    {
        "TroopNumber": "Explorer 192",
        "lng": "",
        "State": "Georgia",
        "NameOrID": "Rivers, Randle G.",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": " docs/randle-g-rivers.pdf"
    },
    {
        "TroopNumber": "Troop 320",
        "lng": "-94.419419",
        "State": "Missouri",
        "NameOrID": "Roberts, Jack K.",
        "City": "Liberty",
        "Year": "1988",
        "lat": "39.245415",
        "Document": "docs/jack-k-roberts.pdf"
    },
    {
        "TroopNumber": "Troop 252",
        "lng": "-104.780268",
        "State": "Colorado",
        "NameOrID": "Robertson, James S.",
        "City": "Walsenburg",
        "Year": "1972",
        "lat": "37.625019",
        "Document": " docs/james-s-robertson.pdf"
    },
    {
        "TroopNumber": "Pack 116",
        "lng": "-81.690434",
        "State": "Ohio",
        "NameOrID": "Rix, James Patrick",
        "City": "Cleveland",
        "Year": "1973",
        "lat": "41.50438",
        "Document": " docs/james-patrick-rix.pdf"
    },
    {
        "TroopNumber": "Troop 75",
        "lng": "-72.674069",
        "State": "Connecticut",
        "NameOrID": "Robertson, James K.",
        "City": "Hartford",
        "Year": "1986",
        "lat": "41.763325",
        "Document": "docs/james-k-robertson.pdf"
    },
    {
        "TroopNumber": "Troop 247",
        "lng": "-85.693289",
        "State": "Michigan",
        "NameOrID": "Roback, Roger",
        "City": "Wyoming",
        "Year": "1982",
        "lat": "42.9134",
        "Document": " docs/roger-roback.pdf"
    },
    {
        "TroopNumber": "Pack 3323",
        "lng": "-83.475547",
        "State": "Michigan",
        "NameOrID": "Robins, Randi",
        "City": "Walled Lake",
        "Year": "1989",
        "lat": "42.538473",
        "Document": "docs/randi-robins.pdf"
    },
    {
        "TroopNumber": "Pack 247",
        "lng": "-85.693289",
        "State": "Michigan",
        "NameOrID": "Roback, Roger",
        "City": "Wyoming",
        "Year": "1982",
        "lat": "42.9134",
        "Document": " docs/roger-roback.pdf"
    },
    {
        "TroopNumber": "Troop 238",
        "lng": "-75.830129",
        "State": "Maryland",
        "NameOrID": "Robinson, Richard L.",
        "City": "Elkton",
        "Year": "1976",
        "lat": "39.608565",
        "Document": " docs/richard-l-robinson.pdf"
    },
    {
        "TroopNumber": "Troop 77",
        "lng": "-88.302039",
        "State": "Kentucky",
        "NameOrID": "Robinson, David W.",
        "City": "Murray",
        "Year": "1988",
        "lat": "36.61023",
        "Document": "docs/david-w-robinson.pdf"
    },
    {
        "TroopNumber": "Troop 193",
        "lng": "-121.744576",
        "State": "California",
        "NameOrID": "Robb, Charles William",
        "City": "Davis",
        "Year": "1986",
        "lat": "38.546699",
        "Document": "docs/charles-william-robb.pdf"
    },
    {
        "TroopNumber": "Troop 3120",
        "lng": "-94.219709",
        "State": "Minnesota",
        "NameOrID": "Robinson, Harlow D.",
        "City": "Rice",
        "Year": "1988",
        "lat": "45.75227",
        "Document": "docs/harlow-d-robinson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Roche, Rick",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/rick-roche.pdf"
    },
    {
        "TroopNumber": "Pack 193",
        "lng": "-121.744576",
        "State": "California",
        "NameOrID": "Robb, Charles William",
        "City": "Davis",
        "Year": "1986",
        "lat": "38.546699",
        "Document": "docs/charles-william-robb.pdf"
    },
    {
        "TroopNumber": "Pack 55",
        "lng": "-75.242762",
        "State": "New York",
        "NameOrID": "Rocker, Clifford W.",
        "City": "Utica",
        "Year": "1987",
        "lat": "43.095808",
        "Document": "docs/clifford-w-rocker.pdf"
    },
    {
        "TroopNumber": "Troop 255",
        "lng": "-75.761814",
        "State": "Delaware",
        "NameOrID": "Robbins, Eric D.",
        "City": "Newark",
        "Year": "1981",
        "lat": "39.678015",
        "Document": " docs/eric-d-robbins.pdf"
    },
    {
        "TroopNumber": "Troop 706",
        "lng": "-83.560633",
        "State": "Georgia",
        "NameOrID": "Rodgers, James S.",
        "City": "Maysville",
        "Year": "1989",
        "lat": "34.253502",
        "Document": "docs/james-s-rodgers.pdf"
    },
    {
        "TroopNumber": "Explorer 1507",
        "lng": "",
        "State": "",
        "NameOrID": "Roberts, John W.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/john-w-roberts.pdf"
    },
    {
        "TroopNumber": "Troop 46",
        "lng": "-83.12852",
        "State": "Ohio",
        "NameOrID": "Rogers, David A.",
        "City": "Marion",
        "Year": "1985",
        "lat": "40.58383",
        "Document": " docs/david-a-rogers.pdf"
    },
    {
        "TroopNumber": "Pack 3051",
        "lng": "-74.575554",
        "State": "New Jersey",
        "NameOrID": "Rogers, Christina J.",
        "City": "Ocean City",
        "Year": "1989",
        "lat": "39.2794",
        "Document": "docs/christina-j-rogers.pdf"
    },
    {
        "TroopNumber": "Troop 64",
        "lng": "-98.494619",
        "State": "Texas",
        "NameOrID": "Rohm, William W.",
        "City": "San Antonio",
        "Year": "1982",
        "lat": "29.42449",
        "Document": " docs/william-w-rohm.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Rotz, Ronald Charles",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": " docs/ronald-charles-rotz.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "",
        "State": "Louisiana",
        "NameOrID": "Rollins, Louis B.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/louis-b-rollins.pdf"
    },
    {
        "TroopNumber": "Troop 192",
        "lng": "-81.918484",
        "State": "Florida",
        "NameOrID": "Rowe, Laverne B.",
        "City": "Hilliard",
        "Year": "1976",
        "lat": "30.690535",
        "Document": " docs/laverne-b-rowe.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-73.755254",
        "State": "New York",
        "NameOrID": "Rosbozom, John Anthony",
        "City": "Albany",
        "Year": "1986",
        "lat": "42.651445",
        "Document": "docs/john-anthony-rosbozom.pdf"
    },
    {
        "TroopNumber": "Troop 672",
        "lng": "-118.144264",
        "State": "California",
        "NameOrID": "Rosen, Donald",
        "City": "Pasadena",
        "Year": "1987",
        "lat": "34.147235",
        "Document": "docs/donald-rosen.pdf"
    },
    {
        "TroopNumber": "Pack 14",
        "lng": "-104.821814",
        "State": "Colorado",
        "NameOrID": "Rowles, Reid A.",
        "City": "Colorado Springs",
        "Year": "1991",
        "lat": "38.83345",
        "Document": "docs/reid-a-rowles.pdf"
    },
    {
        "TroopNumber": "Troop 1513",
        "lng": "-84.391109",
        "State": "Georgia",
        "NameOrID": "Rosenberg, Irvin I.",
        "City": "Atlanta",
        "Year": "1990",
        "lat": "33.748315",
        "Document": "docs/irvin-i-rosenberg.pdf"
    },
    {
        "TroopNumber": "Troop 687",
        "lng": "-74.007124",
        "State": "New York",
        "NameOrID": "Rosenquist, John",
        "City": "New York",
        "Year": "1990",
        "lat": "40.71455",
        "Document": "docs/john-rosenquist.pdf"
    },
    {
        "TroopNumber": "Pack 3858",
        "lng": "-96.622634",
        "State": "Texas",
        "NameOrID": "Ruble, Calvin B.",
        "City": "Balch Springs",
        "Year": "1988",
        "lat": "32.72665",
        "Document": "docs/calvin-b-ruble.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "California",
        "NameOrID": "Roseveare, Melvin F.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/melvin-f-roseveare.pdf"
    },
    {
        "TroopNumber": "Troop 287",
        "lng": "-84.545879",
        "State": "Georgia",
        "NameOrID": "Ross, James G.",
        "City": "Marietta",
        "Year": "1987",
        "lat": "33.953195",
        "Document": "docs/james-g-ross.pdf"
    },
    {
        "TroopNumber": "Troop 106",
        "lng": "-88.153019",
        "State": "Illinois",
        "NameOrID": "Ruch, Kerry S.",
        "City": "Naperville",
        "Year": "1983",
        "lat": "41.770535",
        "Document": " docs/kerry-s-ruch.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Rossnick, Gerald H.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/gerald-h-rossnick.pdf"
    },
    {
        "TroopNumber": "Troop 39",
        "lng": "-91.501238",
        "State": "Wisconsin",
        "NameOrID": "Roswell, Arvid",
        "City": "Eau Claire",
        "Year": "1972",
        "lat": "44.816512",
        "Document": " docs/arvid-roswell.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-71.221841",
        "State": "Massachusetts",
        "NameOrID": "Rudolph, Mark H.",
        "City": "Westwood",
        "Year": "1971",
        "lat": "42.227195",
        "Document": " docs/mark-h-rudolph.pdf"
    },
    {
        "TroopNumber": "Explorer 81",
        "lng": "-90.663269",
        "State": "Iowa",
        "NameOrID": "Roth, Ben J.",
        "City": "Dubuque",
        "Year": "1988",
        "lat": "42.49913",
        "Document": "docs/ben-j-roth.pdf"
    },
    {
        "TroopNumber": "Troop 251",
        "lng": "-76.844304",
        "State": "Maryland",
        "NameOrID": "Ruggeri, Anthony F.",
        "City": "Seabrook",
        "Year": "1976",
        "lat": "38.967615",
        "Document": " docs/anthony-f-ruggeri.pdf"
    },
    {
        "TroopNumber": "Troop 76",
        "lng": "129.75412",
        "State": "Japan",
        "NameOrID": "Rothmeyer, Jody A.",
        "City": "Sasebo",
        "Year": "1991",
        "lat": "33.188911",
        "Document": "docs/jody-a-rothmeyer.pdf"
    },
    {
        "TroopNumber": "Pack 28",
        "lng": "-105.937406",
        "State": "New Mexico",
        "NameOrID": "Sanchez, Paul",
        "City": "Santa Fe",
        "Year": "1987",
        "lat": "35.691543",
        "Document": "docs/paul-sanchez.pdf"
    },
    {
        "TroopNumber": "Troop 27",
        "lng": "-100.917759",
        "State": "Texas",
        "NameOrID": "Rumpff, Terry",
        "City": "Snyder",
        "Year": "1987",
        "lat": "32.717159",
        "Document": "docs/terry-rumpff.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Sandage, Terry L.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/terry-l-sandage.pdf"
    },
    {
        "TroopNumber": "Pack 83",
        "lng": "-87.337154",
        "State": "Indiana",
        "NameOrID": "Sanders, Troy L.",
        "City": "Gary",
        "Year": "1971",
        "lat": "41.60299",
        "Document": " docs/troy-l-sanders.pdf"
    },
    {
        "TroopNumber": "Pack 36",
        "lng": "-100.917759",
        "State": "Texas",
        "NameOrID": "Rumpff, Terry",
        "City": "Snyder",
        "Year": "1987",
        "lat": "32.717159",
        "Document": "docs/terry-rumpff.pdf"
    },
    {
        "TroopNumber": "Pack 224",
        "lng": "-73.858444",
        "State": "New York",
        "NameOrID": "Sanders, Mell J.",
        "City": "Corona",
        "Year": "1989",
        "lat": "40.750813",
        "Document": "docs/mell-j-sanders.pdf"
    },
    {
        "TroopNumber": "Pack 3354",
        "lng": "-89.643604",
        "State": "Illinois",
        "NameOrID": "Sanford, John Lloyd",
        "City": "Springfield",
        "Year": "1988",
        "lat": "39.801055",
        "Document": "docs/john-lloyd-sanford.pdf"
    },
    {
        "TroopNumber": "Troop 252",
        "lng": "-76.536311",
        "State": "Maryland",
        "NameOrID": "Santini, Anthony Michael",
        "City": "Riverdale",
        "Year": "1988",
        "lat": "39.099567",
        "Document": "docs/anthony-michael-santini.pdf"
    },
    {
        "TroopNumber": "Troop 748",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Sartor, Thomas H.",
        "City": "Houston",
        "Year": "1978",
        "lat": "29.76045",
        "Document": "docs/thomas-h-sartor.pdf"
    },
    {
        "TroopNumber": "Troop 377",
        "lng": "-75.278239",
        "State": "Pennsylvania",
        "NameOrID": "Saunders, John G.",
        "City": "Collingdale",
        "Year": "1989",
        "lat": "39.911385",
        "Document": "docs/john-g-saunders.pdf"
    },
    {
        "TroopNumber": "Explorer 2317",
        "lng": "-91.233778",
        "State": "Louisiana",
        "NameOrID": "Sahrmann, David Louis",
        "City": "St. Louis",
        "Year": "1987",
        "lat": "30.253715",
        "Document": "docs/david-louis-sahrmann.pdf"
    },
    {
        "TroopNumber": "Pack 3602",
        "lng": "-123.324639",
        "State": "Oregon",
        "NameOrID": "Savard, James A.",
        "City": "Grants Pass",
        "Year": "1987",
        "lat": "42.44321",
        "Document": "docs/james-a-savard.pdf"
    },
    {
        "TroopNumber": "Troop 22",
        "lng": "-123.324639",
        "State": "Oregon",
        "NameOrID": "Savard, James A.",
        "City": "Grants Pass",
        "Year": "1987",
        "lat": "42.44321",
        "Document": "docs/james-a-savard.pdf"
    },
    {
        "TroopNumber": "Pack 34",
        "lng": "-87.978819",
        "State": "Illinois",
        "NameOrID": "Sample, Robert C.",
        "City": "Wood Dale",
        "Year": "1977",
        "lat": "41.967265",
        "Document": " docs/robert-c-sample.pdf"
    },
    {
        "TroopNumber": "Troop 31",
        "lng": "-70.822767",
        "State": "Massachusetts",
        "NameOrID": "Savastano, John",
        "City": "Rochester",
        "Year": "1990",
        "lat": "41.728652",
        "Document": "docs/john-savastano.pdf"
    },
    {
        "TroopNumber": "Troop 505",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Sawyer, Jeffrey Alan",
        "City": "Houston",
        "Year": "1990",
        "lat": "29.76045",
        "Document": "docs/jeffrey-alan-sawyer.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-87.978819",
        "State": "Illinois",
        "NameOrID": "Sample, Robert C.",
        "City": "Wood Dale",
        "Year": "1977",
        "lat": "41.967265",
        "Document": " docs/robert-c-sample.pdf"
    },
    {
        "TroopNumber": "Troop 546",
        "lng": "-124.054396",
        "State": "Washington",
        "NameOrID": "Schaefer, Orren Glen",
        "City": "Long Beach",
        "Year": "1990",
        "lat": "46.35233",
        "Document": "docs/orren-glen-schaefer.pdf"
    },
    {
        "TroopNumber": "Troop 211",
        "lng": "-123.886909",
        "State": "Washington",
        "NameOrID": "Schaefer, Orren Glen",
        "City": "Hoquiam",
        "Year": "1990",
        "lat": "46.979834",
        "Document": "docs/orren-glen-schaefer.pdf"
    },
    {
        "TroopNumber": "Pack 392",
        "lng": "-96.137054",
        "State": "Texas",
        "NameOrID": "Samples, Doyle C.",
        "City": "Quinlan",
        "Year": "1989",
        "lat": "32.91038",
        "Document": "docs/doyle-c-samples.pdf"
    },
    {
        "TroopNumber": "Pack 8653",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "Schaeffer, Richard A.",
        "City": "San Diego",
        "Year": "1988",
        "lat": "32.715695",
        "Document": "docs/richard-a-schaeffer.pdf"
    },
    {
        "TroopNumber": "Troop 8",
        "lng": "-66.898277",
        "State": "Venezuela",
        "NameOrID": "Sams, Jeffrey Andrew",
        "City": "Caracas",
        "Year": "1983",
        "lat": "10.49605",
        "Document": "docs/jeffrey-andrew-sams.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 1",
        "lng": "-122.644448",
        "State": "California",
        "NameOrID": "Schauer, Donald J.",
        "City": "Petaluma",
        "Year": "1988",
        "lat": "38.232471",
        "Document": "docs/donald-j-schauer.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "",
        "State": "Ohio",
        "NameOrID": "Schultz, Joshua J.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/joshua-j-schultz.pdf"
    },
    {
        "TroopNumber": "Explorer 2875",
        "lng": "-83.802844",
        "State": "Ohio",
        "NameOrID": "Schell, Barton L.",
        "City": "Whitehouse",
        "Year": "1987",
        "lat": "41.514495",
        "Document": "docs/barton-l-schell.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Schulz, Kenneth E.",
        "City": "Rockford",
        "Year": "1990",
        "lat": "42.2669",
        "Document": "docs/kenneth-e-schulz.pdf"
    },
    {
        "TroopNumber": "Troop 208",
        "lng": "-115.139974",
        "State": "Nevada",
        "NameOrID": "Schilling, William S.",
        "City": "Las Vegas",
        "Year": "1989",
        "lat": "36.171915",
        "Document": "docs/william-s-schilling.pdf"
    },
    {
        "TroopNumber": "Troop 235",
        "lng": "-109.528959",
        "State": "Utah",
        "NameOrID": "Schliesser, George M.",
        "City": "Vernal",
        "Year": "1989",
        "lat": "40.455715",
        "Document": "docs/george-m-schliesser.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-74.592003",
        "State": "New Jersey",
        "NameOrID": "Schumann, Robert James",
        "City": "McGuire AFB",
        "Year": "1972",
        "lat": "40.0168",
        "Document": " docs/robert-james-schumann.pdf"
    },
    {
        "TroopNumber": "Troop 228",
        "lng": "-116.692589",
        "State": "Idaho",
        "NameOrID": "Schmidt, James Phillip",
        "City": "Caldwell",
        "Year": "1983",
        "lat": "43.66744",
        "Document": " docs/james-phillip-schmidt.pdf"
    },
    {
        "TroopNumber": "Troop 200",
        "lng": "-116.909504",
        "State": "Idaho",
        "NameOrID": "Schmidt, James Phillip",
        "City": "Wilder",
        "Year": "1983",
        "lat": "43.67748",
        "Document": " docs/james-phillip-schmidt.pdf"
    },
    {
        "TroopNumber": "Troop 104",
        "lng": "-74.592003",
        "State": "New Jersey",
        "NameOrID": "Schumann, Robert James",
        "City": "McGuire AFB",
        "Year": "1972",
        "lat": "40.0168",
        "Document": " docs/robert-james-schumann.pdf"
    },
    {
        "TroopNumber": "Pack 3673",
        "lng": "-83.944439",
        "State": "Michigan",
        "NameOrID": "Schmidt, Brian Wayne",
        "City": "Tecumseh",
        "Year": "1984",
        "lat": "42.00407",
        "Document": " docs/brian-wayne-schmidt.pdf"
    },
    {
        "TroopNumber": "Troop 370",
        "lng": "-80.162224",
        "State": "Florida",
        "NameOrID": "Schuyler, Seth S.",
        "City": "North Miami Beach",
        "Year": "1985",
        "lat": "25.93323",
        "Document": " docs/seth-s-schuyler.pdf"
    },
    {
        "TroopNumber": "Troop 209",
        "lng": "-84.363084",
        "State": "Ohio",
        "NameOrID": "Schmutz, David J.",
        "City": "Madeira",
        "Year": "1983",
        "lat": "39.19008",
        "Document": " docs/david-j-schmutz.pdf"
    },
    {
        "TroopNumber": "Pack 10",
        "lng": "-80.162224",
        "State": "Florida",
        "NameOrID": "Schuyler, Seth S.",
        "City": "North Miami Beach",
        "Year": "1985",
        "lat": "25.93323",
        "Document": " docs/seth-s-schuyler.pdf"
    },
    {
        "TroopNumber": "Pack 3153",
        "lng": "-83.002984",
        "State": "Ohio",
        "NameOrID": "Schoedinger, Steven P.",
        "City": "Columbus",
        "Year": "1989",
        "lat": "39.96196",
        "Document": "docs/steven-p-schoedinger.pdf"
    },
    {
        "TroopNumber": "Troop 212",
        "lng": "-74.789254",
        "State": "New Jersey",
        "NameOrID": "Schwartz, Louis",
        "City": "Mount Holly",
        "Year": "1972",
        "lat": "39.992805",
        "Document": "docs/louis-schwartz.pdf"
    },
    {
        "TroopNumber": "Troop 354",
        "lng": "-83.002984",
        "State": "Ohio",
        "NameOrID": "Schoedinger, Steven P.",
        "City": "Columbus",
        "Year": "1989",
        "lat": "39.96196",
        "Document": "docs/steven-p-schoedinger.pdf"
    },
    {
        "TroopNumber": "Pack 700",
        "lng": "-80.134904",
        "State": "Florida",
        "NameOrID": "Schoenfeld, Irving",
        "City": "Miami Beach",
        "Year": "1986",
        "lat": "25.793095",
        "Document": "docs/irving-schoenfeld.pdf"
    },
    {
        "TroopNumber": "Troop 700",
        "lng": "-80.134904",
        "State": "Florida",
        "NameOrID": "Schoenfeld, Irving",
        "City": "Miami Beach",
        "Year": "1986",
        "lat": "25.793095",
        "Document": "docs/irving-schoenfeld.pdf"
    },
    {
        "TroopNumber": "Troop 873",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Scott, James Larry",
        "City": "Houston",
        "Year": "1985",
        "lat": "29.76045",
        "Document": " docs/james-larry-scott.pdf"
    },
    {
        "TroopNumber": "Troop 634",
        "lng": "-117.691249",
        "State": "California",
        "NameOrID": "Scott, Walter D.",
        "City": "El Toro",
        "Year": "1983",
        "lat": "33.62905",
        "Document": " docs/walter-d-scott.pdf"
    },
    {
        "TroopNumber": "District 8610",
        "lng": "-87.861698",
        "State": "Illinois",
        "NameOrID": "Schroeder, Alan J.",
        "City": "Frankfort",
        "Year": "1989",
        "lat": "41.497568",
        "Document": "docs/alan-j-schroeder.pdf"
    },
    {
        "TroopNumber": "Troop 535",
        "lng": "-118.591844",
        "State": "California",
        "NameOrID": "Schult, Dennis Walter",
        "City": "Chatsworth",
        "Year": "1991",
        "lat": "34.257245",
        "Document": "docs/dennis-walter-schult.pdf"
    },
    {
        "TroopNumber": "Explorer 400",
        "lng": "-81.045249",
        "State": "South Carolina",
        "NameOrID": "Scott, Ronald Christopher",
        "City": "Columbia",
        "Year": "1991",
        "lat": "33.99855",
        "Document": "docs/ronald-christopher-scott.pdf"
    },
    {
        "TroopNumber": "Troop 264",
        "lng": "-103.183669",
        "State": "New Mexico",
        "NameOrID": "Sewell, Jack E.",
        "City": "Clayton",
        "Year": "1989",
        "lat": "36.451951",
        "Document": "docs/jack-e-sewell.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Scott, Randolph R.",
        "City": "",
        "Year": "1980",
        "lat": "",
        "Document": " docs/randolph-r-scott.pdf"
    },
    {
        "TroopNumber": "Explorer 2898",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Sexton, Glen Howard",
        "City": "Houston",
        "Year": "1984",
        "lat": "29.76045",
        "Document": " docs/glen-howard-sexton.pdf"
    },
    {
        "TroopNumber": "Troop 36",
        "lng": "-117.327868",
        "State": "California",
        "NameOrID": "Scott, Rex",
        "City": "Lake Elsinore",
        "Year": "1988",
        "lat": "33.667578",
        "Document": "docs/rex-scott.pdf"
    },
    {
        "TroopNumber": "Troop 158",
        "lng": "-88.323984",
        "State": "Illinois",
        "NameOrID": "Shadden, Gary L.",
        "City": "Crystal Lake",
        "Year": "1988",
        "lat": "42.245217",
        "Document": "docs/gary-l-shadden.pdf"
    },
    {
        "TroopNumber": "512",
        "lng": "-86.126244",
        "State": "Indiana",
        "NameOrID": "Shafer, Ralph L.",
        "City": "Kokomo",
        "Year": "1986",
        "lat": "40.48651",
        "Document": "docs/ralph-l-shafer.pdf"
    },
    {
        "TroopNumber": "Pack 252",
        "lng": "-116.219425",
        "State": "California",
        "NameOrID": "Shaffer, Richard W.",
        "City": "Indio",
        "Year": "1989",
        "lat": "33.721702",
        "Document": "docs/richard-w-shaffer.pdf"
    },
    {
        "TroopNumber": "Troop 64",
        "lng": "-110.04131",
        "State": "Arizona",
        "NameOrID": "Sells, Wayne",
        "City": "Woodruff",
        "Year": "1988",
        "lat": "34.782275",
        "Document": "docs/wayne-sells.pdf"
    },
    {
        "TroopNumber": "Explorer 5",
        "lng": "-72.589294",
        "State": "Massachusetts",
        "NameOrID": "Shanklin, Earl J.",
        "City": "Springfield",
        "Year": "1989",
        "lat": "42.10125",
        "Document": "docs/earl-j-shanklin.pdf"
    },
    {
        "TroopNumber": "Troop 73",
        "lng": "-83.420114",
        "State": "Michigan",
        "NameOrID": "Sharp, John L.",
        "City": "Belle",
        "Year": "1989",
        "lat": "45.24813",
        "Document": "docs/john-l-sharp.pdf"
    },
    {
        "TroopNumber": "Pack 3013",
        "lng": "-121.654819",
        "State": "California",
        "NameOrID": "Serra, Sergio",
        "City": "Salinas",
        "Year": "1988",
        "lat": "36.67088",
        "Document": "docs/sergio-serra.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Shaver, Fremont",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/fremont-shaver.pdf"
    },
    {
        "TroopNumber": "Explorer 89",
        "lng": "-93.291564",
        "State": "Missouri",
        "NameOrID": "Shaw, Donald Ray",
        "City": "Springfield",
        "Year": "1989",
        "lat": "37.208975",
        "Document": "docs/donald-ray-shaw.pdf"
    },
    {
        "TroopNumber": "Pack 36",
        "lng": "-71.416399",
        "State": "Massachusetts",
        "NameOrID": "Sherman, Robert John",
        "City": "Framingham",
        "Year": "1983",
        "lat": "42.279715",
        "Document": " docs/robert-john-sherman.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Sheely, Alfred William",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/alfred-william-sheely.pdf"
    },
    {
        "TroopNumber": "Troop 110",
        "lng": "-78.835734",
        "State": "Pennsylvania",
        "NameOrID": "Shedloskey, George",
        "City": "Windber",
        "Year": "1990",
        "lat": "40.23902",
        "Document": "docs/george-shedloskey.pdf"
    },
    {
        "TroopNumber": "Pack 116",
        "lng": "-78.835734",
        "State": "Pennsylvania",
        "NameOrID": "Shedloskey, George",
        "City": "Windber",
        "Year": "1990",
        "lat": "40.23902",
        "Document": "docs/george-shedloskey.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-73.990364",
        "State": "New York",
        "NameOrID": "Shedloskey, George",
        "City": "Brooklyn ",
        "Year": "1990",
        "lat": "40.692455",
        "Document": "docs/george-shedloskey.pdf"
    },
    {
        "TroopNumber": "Troop 45",
        "lng": "-91.395829",
        "State": "Illinois",
        "NameOrID": "Shinn, Lawrence E.",
        "City": "Quincy",
        "Year": "1990",
        "lat": "39.935599",
        "Document": "docs/lawrence-e-shinn.pdf"
    },
    {
        "TroopNumber": "Pack 3244",
        "lng": "-99.793835",
        "State": "Texas",
        "NameOrID": "Sheedy, Willie C.",
        "City": "Uvalde",
        "Year": "1983",
        "lat": "29.207302",
        "Document": " docs/willie-c-sheedy.pdf"
    },
    {
        "TroopNumber": "Troop 713",
        "lng": "-84.545879",
        "State": "Georgia",
        "NameOrID": "Shirley, Robert L.",
        "City": "Marietta",
        "Year": "1990",
        "lat": "33.953195",
        "Document": "docs/robert-l-shirley.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Sheehan, William",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/william-sheehan.pdf"
    },
    {
        "TroopNumber": "Troop 157",
        "lng": "-84.835249",
        "State": "Michigan",
        "NameOrID": "Shepard, Larry William",
        "City": "Charlotte",
        "Year": "1987",
        "lat": "42.563976",
        "Document": "docs/larry-william-shepard.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "",
        "State": "",
        "NameOrID": "Short, Mark Thomas",
        "City": "",
        "Year": "1971",
        "lat": "",
        "Document": " docs/mark-thomas-short.pdf"
    },
    {
        "TroopNumber": "Pack 1",
        "lng": "-71.161244",
        "State": "Massachusetts",
        "NameOrID": "Silveria, Jose M.",
        "City": "Lawrence",
        "Year": "1991",
        "lat": "42.710328",
        "Document": "docs/jose-m-silveria.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Simmons, David M.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/david-m-simmons.pdf"
    },
    {
        "TroopNumber": "Pack 142",
        "lng": "-100.843755",
        "State": "Kansas",
        "NameOrID": "Shrimplin, John E.",
        "City": "Sublette",
        "Year": "1991",
        "lat": "37.47698",
        "Document": "docs/john-e-shrimplin.pdf"
    },
    {
        "TroopNumber": "Troop 1495",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Simon, Steven V.",
        "City": "Kansas City",
        "Year": "1989",
        "lat": "39.10296",
        "Document": "docs/steven-v-simon.pdf"
    },
    {
        "TroopNumber": "Troop 164",
        "lng": "-81.483727",
        "State": "Ohio",
        "NameOrID": "Shumway, Kenneth Karl",
        "City": "Cuyahoga Falls",
        "Year": "1972",
        "lat": "41.138411",
        "Document": " docs/kenneth-karl-shumway.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Sims, Lonie",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/lonie-sims.pdf"
    },
    {
        "TroopNumber": "Troop 173",
        "lng": "-77.604546",
        "State": "Pennsylvania",
        "NameOrID": "Shuty, Jeffery",
        "City": "Harbor Creek",
        "Year": "1991",
        "lat": "40.994705",
        "Document": "docs/jeffery-shuty.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-131.647854",
        "State": "Alaska",
        "NameOrID": "Sinclair, Duane J.",
        "City": "Ketchikan",
        "Year": "1988",
        "lat": "55.34214",
        "Document": "docs/duane-j-sinclair.pdf"
    },
    {
        "TroopNumber": "Troop 96",
        "lng": "-77.540759",
        "State": "North Carolina",
        "NameOrID": "Singley, Bruce L.",
        "City": "Tarboro",
        "Year": "1989",
        "lat": "35.906945",
        "Document": "docs/bruce-l-singley.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Siefert, Thomas G.",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/thomas-g-siefert.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-93.736684",
        "State": "Texas",
        "NameOrID": "Skeeler, Frank W.",
        "City": "Orange",
        "Year": "1991",
        "lat": "30.0931",
        "Document": "docs/frank-w-skeeler.pdf"
    },
    {
        "TroopNumber": "Troop 91",
        "lng": "-75.393534",
        "State": "Pennsylvania",
        "NameOrID": "Sieke, Carl Eric",
        "City": "Harleysville",
        "Year": "1982",
        "lat": "40.281201",
        "Document": " docs/carl-eric-sieke.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Skelly, Martin",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/martin-skelly.pdf"
    },
    {
        "TroopNumber": "Pack 3695",
        "lng": "-97.335579",
        "State": "Kansas",
        "NameOrID": "Skinner, Larry D.",
        "City": "Wichita",
        "Year": "1986",
        "lat": "37.68698",
        "Document": "docs/larry-d-skinner.pdf"
    },
    {
        "TroopNumber": "Troop 143",
        "lng": "-96.135254",
        "State": "Nebraska",
        "NameOrID": "Sievers, H. Neil",
        "City": "Blair",
        "Year": "1986",
        "lat": "41.543705",
        "Document": "docs/h-neil-sievers.pdf"
    },
    {
        "TroopNumber": "Troop 496",
        "lng": "-81.690434",
        "State": "Ohio",
        "NameOrID": "Skol, Albert H.",
        "City": "Cleveland",
        "Year": "1976",
        "lat": "41.50438",
        "Document": " docs/albert-h-skol.pdf"
    },
    {
        "TroopNumber": "Troop 159",
        "lng": "-74.158979",
        "State": "New Jersey",
        "NameOrID": "Sileo, John",
        "City": "Nutley",
        "Year": "1984",
        "lat": "40.819955",
        "Document": " docs/john-sileo.pdf"
    },
    {
        "TroopNumber": "Pack 496",
        "lng": "-81.690434",
        "State": "Ohio",
        "NameOrID": "Skol, Albert H.",
        "City": "Cleveland",
        "Year": "1976",
        "lat": "41.50438",
        "Document": " docs/albert-h-skol.pdf"
    },
    {
        "TroopNumber": "1030",
        "lng": "-118.063704",
        "State": "California",
        "NameOrID": "Slattery, Scott R.",
        "City": "Cerritos",
        "Year": "1986",
        "lat": "33.868545",
        "Document": "docs/scott-r-slattery.pdf"
    },
    {
        "TroopNumber": "Pack 145",
        "lng": "-74.158979",
        "State": "New Jersey",
        "NameOrID": "Sileo, John",
        "City": "Nutley",
        "Year": "1984",
        "lat": "40.819955",
        "Document": " docs/john-sileo.pdf"
    },
    {
        "TroopNumber": "Troop 507",
        "lng": "",
        "State": "Ohio",
        "NameOrID": "Slemmer, Robert Henry",
        "City": "",
        "Year": "1971",
        "lat": "",
        "Document": " docs/robert-henry-slemmer.pdf"
    },
    {
        "TroopNumber": "Troop 90",
        "lng": "-82.92902",
        "State": "Florida",
        "NameOrID": "Slocum, Jesse James",
        "City": "Branford",
        "Year": "1986",
        "lat": "29.958635",
        "Document": "docs/jesse-james-slocum.pdf"
    },
    {
        "TroopNumber": "Pack 3079",
        "lng": "-79.639669",
        "State": "Pennsylvania",
        "NameOrID": "Sill, Lynn",
        "City": "Cory",
        "Year": "1986",
        "lat": "41.92052",
        "Document": "docs/lynn-sill.pdf"
    },
    {
        "TroopNumber": "Troop 75",
        "lng": "-105.279239",
        "State": "Colorado",
        "NameOrID": "Slusher, Floyd David",
        "City": "Boulder",
        "Year": "1977",
        "lat": "40.01574",
        "Document": "docs/floyd-david-slusher.pdf"
    },
    {
        "TroopNumber": "202",
        "lng": "-81.24916",
        "State": "Georgia",
        "NameOrID": "Sills, Milton Harold",
        "City": "Pooler",
        "Year": "1986",
        "lat": "32.114888",
        "Document": "docs/milton-harold-sills.pdf"
    },
    {
        "TroopNumber": "Troop 73",
        "lng": "-93.317499",
        "State": "Colorado",
        "NameOrID": "Slusher, Floyd David",
        "City": "Boulder",
        "Year": "1977",
        "lat": "42.022525",
        "Document": "docs/floyd-david-slusher.pdf"
    },
    {
        "TroopNumber": "Troop 365",
        "lng": "-93.360099",
        "State": "Minnesota",
        "NameOrID": "Smeby, Donald",
        "City": "Crystal",
        "Year": "1988",
        "lat": "45.031835",
        "Document": "docs/donald-smeby.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Smith, Reginald",
        "City": "",
        "Year": "1986",
        "lat": "",
        "Document": "docs/reginald-smith.pdf"
    },
    {
        "TroopNumber": "Troop 223",
        "lng": "-75.448189",
        "State": "Pennsylvania",
        "NameOrID": "Smedley, John Joseph",
        "City": "Aston",
        "Year": "1987",
        "lat": "39.87428",
        "Document": "docs/john-joseph-smedley.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Smith, Michael Diaz",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/michael-diaz-smith.pdf"
    },
    {
        "TroopNumber": "Troop 77",
        "lng": "-76.367314",
        "State": "New York",
        "NameOrID": "Smith, James Warren",
        "City": "Groton",
        "Year": "1972",
        "lat": "42.58925",
        "Document": " docs/james-warren-smith.pdf"
    },
    {
        "TroopNumber": "Troop 254",
        "lng": "-81.371569",
        "State": "Ohio",
        "NameOrID": "Smith, John Harold",
        "City": "Canton",
        "Year": "1989",
        "lat": "40.79781",
        "Document": "docs/john-harold-smith.pdf"
    },
    {
        "TroopNumber": "Pack 199",
        "lng": "-94.053514",
        "State": "Iowa",
        "NameOrID": "Smith, Michael Finley",
        "City": "Lorimor",
        "Year": "1990",
        "lat": "41.128055",
        "Document": "docs/michael-finley-smith.pdf"
    },
    {
        "TroopNumber": "Troop 45",
        "lng": "-93.950154",
        "State": "Iowa",
        "NameOrID": "Smith, Michael Finley",
        "City": "Murray",
        "Year": "1990",
        "lat": "41.037825",
        "Document": "docs/michael-finley-smith.pdf"
    },
    {
        "TroopNumber": "Troop 5",
        "lng": "-88.952459",
        "State": "Illinois",
        "NameOrID": "Smith, Brian Lynn",
        "City": "Decatur",
        "Year": "1988",
        "lat": "39.845845",
        "Document": "docs/brian-lynn-smith.pdf"
    },
    {
        "TroopNumber": "Pack 3412",
        "lng": "-70.858614",
        "State": "Massachusetts",
        "NameOrID": "Smith, David A.",
        "City": "Hull",
        "Year": "1974",
        "lat": "42.272065",
        "Document": " docs/david-a-smith.pdf"
    },
    {
        "TroopNumber": "Troop 19",
        "lng": "-88.952459",
        "State": "Illinois",
        "NameOrID": "Smith, Brian Lynn",
        "City": "Decatur",
        "Year": "1988",
        "lat": "39.845845",
        "Document": "docs/brian-lynn-smith.pdf"
    },
    {
        "TroopNumber": "328",
        "lng": "-95.506019",
        "State": "Texas",
        "NameOrID": "Smith, James Dale",
        "City": "Frankston",
        "Year": "1991",
        "lat": "32.051935",
        "Document": "docs/james-dale-smith.pdf"
    },
    {
        "TroopNumber": "District 8603",
        "lng": "-84.403593",
        "State": "Ohio",
        "NameOrID": "Smitty, Floyd",
        "City": "Middletown",
        "Year": "1991",
        "lat": "39.509897",
        "Document": "docs/floyd-smitty.pdf"
    },
    {
        "TroopNumber": "Troop 269",
        "lng": "-123.842619",
        "State": "Oregon",
        "NameOrID": "Smith, Richard Herbert",
        "City": "Tillamook",
        "Year": "1990",
        "lat": "45.45632",
        "Document": "docs/richard-herbert-smith.pdf"
    },
    {
        "TroopNumber": "Troop 227",
        "lng": "-83.779779",
        "State": "Michigan",
        "NameOrID": "Smith, Kenneth L.",
        "City": "Birch Run",
        "Year": "1974",
        "lat": "43.250188",
        "Document": " docs/kenneth-l-smith.pdf"
    },
    {
        "TroopNumber": "Troop 145",
        "lng": "-82.304459",
        "State": "South Carolina",
        "NameOrID": "Snow, Ronald E.",
        "City": "Taylors",
        "Year": "1981",
        "lat": "34.88884",
        "Document": " docs/ronald-e-snow.pdf"
    },
    {
        "TroopNumber": "Troop 199",
        "lng": "-122.227102",
        "State": "California",
        "NameOrID": "Smith, Robert",
        "City": "Redwood City",
        "Year": "1987",
        "lat": "37.484175",
        "Document": "docs/robert-smith.pdf"
    },
    {
        "TroopNumber": "Pack 3705",
        "lng": "-102.645054",
        "State": "Texas",
        "NameOrID": "Sollars, Rickey Lee",
        "City": "Seminole",
        "Year": "1991",
        "lat": "32.726625",
        "Document": "docs/rickey-lee-sollars.pdf"
    },
    {
        "TroopNumber": "Troop 125",
        "lng": "-122.712427",
        "State": "California",
        "NameOrID": "Smith, Paul Gilbert",
        "City": "Santa Rosa",
        "Year": "1986",
        "lat": "38.437731",
        "Document": "docs/paul-gilbert-smith.pdf"
    },
    {
        "TroopNumber": "Troop 111",
        "lng": "-94.532539",
        "State": "Missouri",
        "NameOrID": "Smith, William O.",
        "City": "Grandview",
        "Year": "1991",
        "lat": "38.88926",
        "Document": "docs/william-o-smith.pdf"
    },
    {
        "TroopNumber": "Troop 588",
        "lng": "-80.37788",
        "State": "Florida",
        "NameOrID": "Somervell, Gardiner Stanton",
        "City": "Key Largo",
        "Year": "1982",
        "lat": "25.166917",
        "Document": " docs/gardiner-stanton-somervell.pdf"
    },
    {
        "TroopNumber": "Troop 100",
        "lng": "-87.884984",
        "State": "Wisconsin",
        "NameOrID": "Smith, Robert M.",
        "City": "Shorewood",
        "Year": "1980",
        "lat": "43.088295",
        "Document": " docs/robert-m-smith.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-71.503601",
        "State": "Rhode Island",
        "NameOrID": "Smith, Dale R.",
        "City": "Quonset Point",
        "Year": "1988",
        "lat": "41.582579",
        "Document": "docs/dale-r-smith.pdf"
    },
    {
        "TroopNumber": "Not Applicable ",
        "lng": "",
        "State": "",
        "NameOrID": "Sonju, William V.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/william-v-sonju.pdf"
    },
    {
        "TroopNumber": "Troop 20",
        "lng": "-76.798814",
        "State": "New York",
        "NameOrID": "Sparks, Guy K.",
        "City": "Elmira",
        "Year": "1990",
        "lat": "42.09041",
        "Document": "docs/guy-k-sparks.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Sowder, Gene Judson",
        "City": "",
        "Year": "1979",
        "lat": "",
        "Document": "docs/gene-judson-sowder.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-76.821994",
        "State": "New York",
        "NameOrID": "Sparks, Guy K.",
        "City": "Lodi",
        "Year": "1990",
        "lat": "42.614038",
        "Document": "docs/guy-k-sparks.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Sparks, Darrell C.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/darrell-c-sparks.pdf"
    },
    {
        "TroopNumber": "Not Applicable ",
        "lng": "",
        "State": "",
        "NameOrID": "Sparks, Jerry L.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/jerry-l-sparks.pdf"
    },
    {
        "TroopNumber": "Pack 3214",
        "lng": "-121.885794",
        "State": "California",
        "NameOrID": "Sowers, Thomas",
        "City": "San Jose",
        "Year": "1987",
        "lat": "37.338475",
        "Document": "docs/thomas-sowers.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-104.992259",
        "State": "Colorado",
        "NameOrID": "Spauling, Harry",
        "City": "Denver",
        "Year": "1989",
        "lat": "39.74001",
        "Document": "docs/harry-spauling.pdf"
    },
    {
        "TroopNumber": "Troop 60",
        "lng": "-122.254954",
        "State": "California",
        "NameOrID": "Spalding, Carl P.",
        "City": "Vallejo",
        "Year": "1972",
        "lat": "38.10083",
        "Document": " docs/carl-p-spalding.pdf"
    },
    {
        "TroopNumber": "Pack 63",
        "lng": "-74.316574",
        "State": "New York",
        "NameOrID": "Spear, Edward D.",
        "City": "Goshen",
        "Year": "1991",
        "lat": "41.40683",
        "Document": "docs/edward-d-spear.pdf"
    },
    {
        "TroopNumber": "Explorer 205",
        "lng": "-122.254954",
        "State": "California",
        "NameOrID": "Spalding, Carl P.",
        "City": "Vallejo",
        "Year": "1972",
        "lat": "38.10083",
        "Document": " docs/carl-p-spalding.pdf"
    },
    {
        "TroopNumber": "Troop 786",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Speed, Tommy",
        "City": "Dallas",
        "Year": "1990",
        "lat": "32.778155",
        "Document": "docs/tommy-speed.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-98.248379",
        "State": "Texas",
        "NameOrID": "Speed, Tommy",
        "City": "Graford",
        "Year": "1990",
        "lat": "32.938225",
        "Document": "docs/tommy-speed.pdf"
    },
    {
        "TroopNumber": "Explorer 2911",
        "lng": "-83.160492",
        "State": "Michigan",
        "NameOrID": "Spangler, Michael C.",
        "City": "Rochester Hills",
        "Year": "1989",
        "lat": "42.664819",
        "Document": "docs/michael-c-spangler.pdf"
    },
    {
        "TroopNumber": "Troop 684",
        "lng": "-83.002984",
        "State": "Ohio",
        "NameOrID": "Speer, Bryan Douglas",
        "City": "Columbus",
        "Year": "1984",
        "lat": "39.96196",
        "Document": "docs/bryan-douglas-speer.pdf"
    },
    {
        "TroopNumber": "Troop 149",
        "lng": "-84.169982",
        "State": "Georgia",
        "NameOrID": "Speice, Howard W.",
        "City": "Stone Mountain",
        "Year": "1989",
        "lat": "33.803855",
        "Document": "docs/howard-w-speice.pdf"
    },
    {
        "TroopNumber": "Troop 1152",
        "lng": "-117.915719",
        "State": "California",
        "NameOrID": "Spencer, Rex Harrison",
        "City": "Anaheim",
        "Year": "1984",
        "lat": "33.83276",
        "Document": "docs/rex-harrison-spencer.pdf"
    },
    {
        "TroopNumber": "Troop 62",
        "lng": "",
        "State": "Kansas",
        "NameOrID": "St. Laurent, Ronald A.",
        "City": "",
        "Year": "1978",
        "lat": "",
        "Document": "docs/ronald-a-st-laurent.pdf"
    },
    {
        "TroopNumber": "Troop 100",
        "lng": "-117.915719",
        "State": "California",
        "NameOrID": "Spencer, Rex Harrison",
        "City": "Anaheim",
        "Year": "1984",
        "lat": "33.83276",
        "Document": "docs/rex-harrison-spencer.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-112.075774",
        "State": "Arizona",
        "NameOrID": "St. Laurent, Ronald A.",
        "City": "Phoenix",
        "Year": "1978",
        "lat": "33.44826",
        "Document": "docs/ronald-a-st-laurent.pdf"
    },
    {
        "TroopNumber": "Not Applicable ",
        "lng": "",
        "State": "",
        "NameOrID": "Spencer, Sidney L.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/sidney-l-spencer.pdf"
    },
    {
        "TroopNumber": "District 5605",
        "lng": "-89.994469",
        "State": "Illinois",
        "NameOrID": "Spreitler, Michael R.",
        "City": "Red Bud",
        "Year": "1989",
        "lat": "38.2117",
        "Document": "docs/michael-r-spreitler.pdf"
    },
    {
        "TroopNumber": "Explorer 107",
        "lng": "-81.975309",
        "State": "Georgia",
        "NameOrID": "Sprinkle, Robert Mason",
        "City": "Augusta",
        "Year": "1991",
        "lat": "33.47909",
        "Document": "docs/robert-mason-sprinkle.pdf"
    },
    {
        "TroopNumber": "Troop 336",
        "lng": "-80.342364",
        "State": "South Carolina",
        "NameOrID": "Sprott, Rodney M.",
        "City": "Sumter",
        "Year": "1985",
        "lat": "33.92434",
        "Document": " docs/rodney-m-sprott.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-96.370424",
        "State": "Texas",
        "NameOrID": "Stamps, Thomas Lynn",
        "City": "Bryan ",
        "Year": "1990",
        "lat": "30.670985",
        "Document": "docs/thomas-lynn-stamps.pdf"
    },
    {
        "TroopNumber": "Troop 622",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "Stark, Rodney F.",
        "City": "San Diego",
        "Year": "1990",
        "lat": "32.715695",
        "Document": "docs/rodney-f-stark.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "-116.601969",
        "State": "California",
        "NameOrID": "Stark, Rodney F.",
        "City": "Julian",
        "Year": "1990",
        "lat": "33.07826",
        "Document": "docs/rodney-f-stark.pdf"
    },
    {
        "TroopNumber": "Troop 388",
        "lng": "-81.045249",
        "State": "South Carolina",
        "NameOrID": "Staten, Ralph Randolph",
        "City": "Columbia",
        "Year": "1987",
        "lat": "33.99855",
        "Document": "docs/ralph-randolph-staten.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-97.79567",
        "State": "Texas",
        "NameOrID": "Staten, Ralph Randolph",
        "City": "Ft. Hood",
        "Year": "1987",
        "lat": "31.12258",
        "Document": "docs/ralph-randolph-staten.pdf"
    },
    {
        "TroopNumber": "Troop 822",
        "lng": "-73.301584",
        "State": "Vermont",
        "NameOrID": "Steadman, Warren E.",
        "City": "South Hero",
        "Year": "1988",
        "lat": "44.645877",
        "Document": "docs/warren-e-steadman.pdf"
    },
    {
        "TroopNumber": "Troop 607",
        "lng": "-72.451653",
        "State": "Vermont",
        "NameOrID": "Steadman, Warren E.",
        "City": "Shellbourne",
        "Year": "1988",
        "lat": "43.871655",
        "Document": "docs/warren-e-steadman.pdf"
    },
    {
        "TroopNumber": "Explorer 2251",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Stearns, John Michael",
        "City": "St. Louis",
        "Year": "1975",
        "lat": "38.62774",
        "Document": " docs/john-michael-stearns.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Stearns, John Michael",
        "City": "St. Louis",
        "Year": "1975",
        "lat": "38.62774",
        "Document": " docs/john-michael-stearns.pdf"
    },
    {
        "TroopNumber": "District 8608",
        "lng": "",
        "State": "",
        "NameOrID": "Steele, James H.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/james-h-steele.pdf"
    },
    {
        "TroopNumber": "Troop 216",
        "lng": "-117.986499",
        "State": "California",
        "NameOrID": "Steelmon, Kenneth",
        "City": "Westminster",
        "Year": "1984",
        "lat": "33.759305",
        "Document": " docs/kenneth-steelmon.pdf"
    },
    {
        "TroopNumber": "Pack 75",
        "lng": "-73.459591",
        "State": "New York",
        "NameOrID": "Steen, Darryl F.",
        "City": "Port Henry",
        "Year": "1974",
        "lat": "44.04825",
        "Document": " docs/darryl-f-steen.pdf"
    },
    {
        "TroopNumber": "Pack ",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "Steffen, Gary Lee",
        "City": "Syracuse",
        "Year": "1987",
        "lat": "43.04999",
        "Document": "docs/gary-lee-steffen.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-105.100504",
        "State": "Colorado",
        "NameOrID": "Stein, Michael D.",
        "City": "Longmont",
        "Year": "1986",
        "lat": "40.16394",
        "Document": "docs/michael-d-stein.pdf"
    },
    {
        "TroopNumber": "Explorer 2083",
        "lng": "-104.992259",
        "State": "Colorado",
        "NameOrID": "Steinbach, Robert Wyne",
        "City": "Denver",
        "Year": "1988",
        "lat": "39.74001",
        "Document": "docs/robert-wyne-steinbach.pdf"
    },
    {
        "TroopNumber": "Explorer 142",
        "lng": "-73.429984",
        "State": "New York",
        "NameOrID": "Stella, John",
        "City": "Huntington",
        "Year": "1989",
        "lat": "40.871954",
        "Document": "docs/john-stella.pdf"
    },
    {
        "TroopNumber": "Troop 41",
        "lng": "-73.345242",
        "State": "New York",
        "NameOrID": "Stella, John",
        "City": "Northport",
        "Year": "1989",
        "lat": "40.901203",
        "Document": "docs/john-stella.pdf"
    },
    {
        "TroopNumber": "Sea Scouts ",
        "lng": "",
        "State": "California",
        "NameOrID": "Stenger, Richard C.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/richard-c-stenger.pdf"
    },
    {
        "TroopNumber": "Troop 129",
        "lng": "-77.604546",
        "State": "Pennsylvania",
        "NameOrID": "Stettenbauer, Jack Henry",
        "City": "Rohrstown",
        "Year": "1986",
        "lat": "40.994705",
        "Document": "docs/jack-henry-stettenbauer.pdf"
    },
    {
        "TroopNumber": "Troop 613",
        "lng": "-73.213234",
        "State": "Vermont",
        "NameOrID": "Stevens, Bert H.",
        "City": "Burlington",
        "Year": "1984",
        "lat": "44.47592",
        "Document": " docs/bert-h-stevens.pdf"
    },
    {
        "TroopNumber": "Pack 310",
        "lng": "-116.967889",
        "State": "California",
        "NameOrID": "Stevens, Gary James",
        "City": "Hemet",
        "Year": "1988",
        "lat": "33.74937",
        "Document": "docs/gary-james-stevens.pdf"
    },
    {
        "TroopNumber": "Pack 18",
        "lng": "-96.707739",
        "State": "Nebraska",
        "NameOrID": "Stewart, Robert Lee",
        "City": "Lincoln ",
        "Year": "1986",
        "lat": "40.81362",
        "Document": "docs/robert-lee-stewart.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-96.707739",
        "State": "Nebraska",
        "NameOrID": "Stewart, Robert Lee",
        "City": "Lincoln",
        "Year": "1986",
        "lat": "40.81362",
        "Document": "docs/robert-lee-stewart.pdf"
    },
    {
        "TroopNumber": "Troop 1025",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Stewart, Jack Z.",
        "City": "Kansas City",
        "Year": "1989",
        "lat": "39.10296",
        "Document": "docs/jack-z-stewart.pdf"
    },
    {
        "TroopNumber": "Pack 8650",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Stewart, Jack Z.",
        "City": "Kansas City",
        "Year": "1989",
        "lat": "39.10296",
        "Document": "docs/jack-z-stewart.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "Stidsen, Larry D.",
        "City": "Worcester",
        "Year": "1989",
        "lat": "42.263415",
        "Document": "docs/larry-d-stidsen.pdf"
    },
    {
        "TroopNumber": "Pack 234",
        "lng": "-81.637029",
        "State": "Florida",
        "NameOrID": "Stiefel, Ronald K.",
        "City": "Palatka",
        "Year": "1989",
        "lat": "29.648535",
        "Document": "docs/ronald-k-stiefel.pdf"
    },
    {
        "TroopNumber": "Troop 102",
        "lng": "127.132877",
        "State": "Japan",
        "NameOrID": "Stillings, Charles J.",
        "City": "Kadena AFB, Okinawa",
        "Year": "1984",
        "lat": "25.965785",
        "Document": " docs/charles-j-stillings.pdf"
    },
    {
        "TroopNumber": "Troop 591",
        "lng": "-111.668509",
        "State": "Utah",
        "NameOrID": "Stillman, Michael",
        "City": "Provo",
        "Year": "1989",
        "lat": "40.233775",
        "Document": "docs/michael-stillman.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-111.856569",
        "State": "Utah",
        "NameOrID": "Stillwell, Robert David",
        "City": "South Salt Lake City",
        "Year": "1987",
        "lat": "40.77337",
        "Document": "docs/robert-david-stillwell.pdf"
    },
    {
        "TroopNumber": "Pack 38",
        "lng": "-118.777624",
        "State": "Nevada",
        "NameOrID": "Stockmeier, Robert L.",
        "City": "Fallon",
        "Year": "1990",
        "lat": "39.474905",
        "Document": "docs/robert-l-stockmeier.pdf"
    },
    {
        "TroopNumber": "Troop 41",
        "lng": "-71.792259",
        "State": "Massachusetts",
        "NameOrID": "Stone, Walter N.",
        "City": "Fitchburg",
        "Year": "1989",
        "lat": "42.58176",
        "Document": "docs/walter-n-stone.pdf"
    },
    {
        "TroopNumber": "District 230",
        "lng": "-71.673684",
        "State": "Massachusetts",
        "NameOrID": "Stone, Walter N.",
        "City": "Lancaster",
        "Year": "1989",
        "lat": "42.456635",
        "Document": "docs/walter-n-stone.pdf"
    },
    {
        "TroopNumber": "Troop 178",
        "lng": "-75.338698",
        "State": "Pennsylvania",
        "NameOrID": "Stonier, Jess",
        "City": "Kulpsville",
        "Year": "1991",
        "lat": "40.245492",
        "Document": "docs/jess-stonier.pdf"
    },
    {
        "TroopNumber": "Troop 372",
        "lng": "-94.419419",
        "State": "Missouri",
        "NameOrID": "Strain, Larry W.",
        "City": "Liberty",
        "Year": "1973",
        "lat": "39.245415",
        "Document": " docs/larry-w-strain.pdf"
    },
    {
        "TroopNumber": "Troop 99",
        "lng": "-157.857614",
        "State": "Hawaii",
        "NameOrID": "Strain, Gary L.",
        "City": "Honolulu",
        "Year": "1983",
        "lat": "21.30477",
        "Document": " docs/gary-l-strain.pdf"
    },
    {
        "TroopNumber": "Pack 565",
        "lng": "-95.419294",
        "State": "Texas",
        "NameOrID": "Straney, Michael",
        "City": "Spring",
        "Year": "1989",
        "lat": "30.084539",
        "Document": "docs/michael-straney.pdf"
    },
    {
        "TroopNumber": "Troop 212",
        "lng": "-98.494619",
        "State": "Texas",
        "NameOrID": "Strauch, Otto",
        "City": "San Antonio",
        "Year": "1989",
        "lat": "29.42449",
        "Document": "docs/otto-strauch.pdf"
    },
    {
        "TroopNumber": "69",
        "lng": "-83.928169",
        "State": "Ohio",
        "NameOrID": "Strausbach, William Fred",
        "City": "Xenia ",
        "Year": "1986",
        "lat": "39.685095",
        "Document": "docs/william-fred-strausbach.pdf"
    },
    {
        "TroopNumber": "Pack 267",
        "lng": "-80.220569",
        "State": "Florida",
        "NameOrID": "Strauss, Ronald",
        "City": "North Lauderdale",
        "Year": "1984",
        "lat": "26.222185",
        "Document": " docs/ronald-strauss.pdf"
    },
    {
        "TroopNumber": "Troop 1337",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Street, James D.",
        "City": "Kansas City",
        "Year": "1989",
        "lat": "39.10296",
        "Document": "docs/james-d-street.pdf"
    },
    {
        "TroopNumber": "Explorer 300",
        "lng": "-96.747489",
        "State": "Nebraska",
        "NameOrID": "Strickland, Thomas Jay",
        "City": "Beatrice",
        "Year": "1986",
        "lat": "40.26584",
        "Document": "docs/thomas-jay-strickland.pdf"
    },
    {
        "TroopNumber": "Pack 132",
        "lng": "-85.953529",
        "State": "Alabama",
        "NameOrID": "Strickland, Bobby",
        "City": "Munford",
        "Year": "1987",
        "lat": "33.526665",
        "Document": "docs/bobby-strickland.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Strobel, Mark",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/mark-strobel.pdf"
    },
    {
        "TroopNumber": "Troop 591",
        "lng": "-89.086369",
        "State": "Wisconsin",
        "NameOrID": "Strobel, Ricky R.",
        "City": "Phelps",
        "Year": "1988",
        "lat": "46.06823",
        "Document": "docs/ricky-r-strobel.pdf"
    },
    {
        "TroopNumber": "Pack 473",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Strong, Randle G.",
        "City": "Indianapolis",
        "Year": "1988",
        "lat": "39.76691",
        "Document": "docs/randle-g-strong.pdf"
    },
    {
        "TroopNumber": "Pack 95",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Strong, Randle G.",
        "City": "Indianapolis",
        "Year": "1988",
        "lat": "39.76691",
        "Document": "docs/randle-g-strong.pdf"
    },
    {
        "TroopNumber": "Troop 491",
        "lng": "-83.171399",
        "State": "Ohio",
        "NameOrID": "Stroup, Gary A.",
        "City": "Harrisburg",
        "Year": "1989",
        "lat": "39.81077",
        "Document": "docs/gary-a-stroup.pdf"
    },
    {
        "TroopNumber": "Pack 8608",
        "lng": "-83.113854",
        "State": "Ohio",
        "NameOrID": "Stull, Clifford Allen",
        "City": "Fremont",
        "Year": "1991",
        "lat": "41.347405",
        "Document": "docs/clifford-allen-stull.pdf"
    },
    {
        "TroopNumber": "Pack 13",
        "lng": "-94.197519",
        "State": "Minnesota",
        "NameOrID": "Sufka, Alfred J.",
        "City": "St. Cloud",
        "Year": "1988",
        "lat": "45.56586",
        "Document": "docs/alfred-j-sufka.pdf"
    },
    {
        "TroopNumber": "Troop 299",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Suggs, Herman",
        "City": "Philadelphia",
        "Year": "1989",
        "lat": "39.95228",
        "Document": "docs/herman-suggs.pdf"
    },
    {
        "TroopNumber": "Troop 572",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Suggs, Herman",
        "City": "Philadelphia",
        "Year": "1989",
        "lat": "39.95228",
        "Document": "docs/herman-suggs.pdf"
    },
    {
        "TroopNumber": "Troop 100",
        "lng": "-95.940469",
        "State": "Nebraska",
        "NameOrID": "Sullivan, Larry John",
        "City": "Omaha",
        "Year": "1987",
        "lat": "41.260675",
        "Document": "docs/larry-john-sullivan.pdf"
    },
    {
        "TroopNumber": "Explorer 4",
        "lng": "-119.698869",
        "State": "California",
        "NameOrID": "Sullivan, Herbert Lewis",
        "City": "Santa Barbara",
        "Year": "1972",
        "lat": "34.419255",
        "Document": " docs/herbert-lewis-sullivan.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "",
        "NameOrID": "Sullivan, Michael Edward",
        "City": "",
        "Year": "1983",
        "lat": "",
        "Document": " docs/michael-edward-sullivan.pdf"
    },
    {
        "TroopNumber": "Troop 46",
        "lng": "-84.95088",
        "State": "Georgia",
        "NameOrID": "Sumler, Roger Lee",
        "City": "Calhoun",
        "Year": "1988",
        "lat": "34.505243",
        "Document": "docs/roger-lee-sumler.pdf"
    },
    {
        "TroopNumber": "Troop 145",
        "lng": "-98.389919",
        "State": "Oklahoma",
        "NameOrID": "Summers, George",
        "City": "Lawton",
        "Year": "1991",
        "lat": "34.605185",
        "Document": "docs/george-summers.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-83.779119",
        "State": "Michigan",
        "NameOrID": "Sundling, Frederick L.",
        "City": "Saline ",
        "Year": "1977",
        "lat": "42.168285",
        "Document": " docs/frederick-l-sundling.pdf"
    },
    {
        "TroopNumber": "Pack 192",
        "lng": "-112.124174",
        "State": "Idaho",
        "NameOrID": "Swanson, Mark A.",
        "City": "Shelley",
        "Year": "1988",
        "lat": "43.380535",
        "Document": "docs/mark-a-swanson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Swarthout, Arthur W.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/arthur-w-swarthout.pdf"
    },
    {
        "TroopNumber": "Pack 101",
        "lng": "-121.690299",
        "State": "California",
        "NameOrID": "Swasey, James Albert",
        "City": "Rio Vista",
        "Year": "1988",
        "lat": "38.155435",
        "Document": "docs/james-albert-swasey.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 129",
        "lng": "-121.690299",
        "State": "California",
        "NameOrID": "Swasey, James Albert",
        "City": "Rio Vista",
        "Year": "1988",
        "lat": "38.155435",
        "Document": "docs/james-albert-swasey.pdf"
    },
    {
        "TroopNumber": "Troop 243",
        "lng": "-85.601114",
        "State": "Michigan",
        "NameOrID": "Swearingen, Clyde H.",
        "City": "Kalamazoo",
        "Year": "1991",
        "lat": "42.292435",
        "Document": "docs/clyde-h-swearingen.pdf"
    },
    {
        "TroopNumber": "Troop 467",
        "lng": "-81.105844",
        "State": "Ohio",
        "NameOrID": "Van Swearingen, David",
        "City": "Alliance",
        "Year": "1989",
        "lat": "40.921365",
        "Document": "docs/david-van-swearingen.pdf"
    },
    {
        "TroopNumber": "Troop 59",
        "lng": "-81.105844",
        "State": "Ohio",
        "NameOrID": "Van Swearingen, David",
        "City": "Alliance",
        "Year": "1989",
        "lat": "40.921365",
        "Document": "docs/david-van-swearingen.pdf"
    },
    {
        "TroopNumber": "Pack 394",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Sweeney, William J.",
        "City": "Philadelphia",
        "Year": "1988",
        "lat": "39.95228",
        "Document": "docs/william-j-sweeney.pdf"
    },
    {
        "TroopNumber": "Troop 10",
        "lng": "-91.329264",
        "State": "Iowa",
        "NameOrID": "Swentik, Donald A.",
        "City": "Ft. Madison",
        "Year": "1985",
        "lat": "40.630215",
        "Document": " docs/donald-a-swentik.pdf"
    },
    {
        "TroopNumber": "3736",
        "lng": "",
        "State": "",
        "NameOrID": "Swift, Robert E.",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/robert-e-swift.pdf"
    },
    {
        "TroopNumber": "Explorer 14",
        "lng": "-87.566214",
        "State": "Alabama",
        "NameOrID": "Swindel, George W.",
        "City": "Tuscaloosa ",
        "Year": "1983",
        "lat": "33.210435",
        "Document": " docs/george-w-swindel.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-87.566214",
        "State": "Alabama",
        "NameOrID": "Swindel, George W.",
        "City": "Tuscaloosa",
        "Year": "1983",
        "lat": "33.210435",
        "Document": " docs/george-w-swindel.pdf"
    },
    {
        "TroopNumber": "Troop 51",
        "lng": "-70.438393",
        "State": "Maine",
        "NameOrID": "Sykes, Paul E.",
        "City": "North Windham",
        "Year": "1988",
        "lat": "43.83408",
        "Document": "docs/paul-e-sykes.pdf"
    },
    {
        "TroopNumber": "Explorer 2331",
        "lng": "-71.759614",
        "State": "Massachusetts",
        "NameOrID": "Symonds, David Michael",
        "City": "Leominster",
        "Year": "1987",
        "lat": "42.52642",
        "Document": "docs/david-michael-symonds.pdf"
    },
    {
        "TroopNumber": "Troop 89",
        "lng": "-75.752645",
        "State": "Pennsylvania",
        "NameOrID": "Symons, William Lee",
        "City": "New Tripoli",
        "Year": "1986",
        "lat": "40.680851",
        "Document": "docs/william-lee-symons.pdf"
    },
    {
        "TroopNumber": "Troop 423",
        "lng": "-118.128234",
        "State": "California",
        "NameOrID": "Taff, James David",
        "City": "Downey",
        "Year": "1984",
        "lat": "33.940035",
        "Document": " docs/james-david-taff.pdf"
    },
    {
        "TroopNumber": "Troop 278",
        "lng": "-90.049259",
        "State": "Tennessee",
        "NameOrID": "Talley, Neal",
        "City": "Memphis",
        "Year": "1983",
        "lat": "35.149766",
        "Document": " docs/neal-talley.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Tally, Edgar S.",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": " docs/edgar-s-tally.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Tampico, Jonathan",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/jonathan-tampico.pdf"
    },
    {
        "TroopNumber": "Troop 247",
        "lng": "-92.88378",
        "State": "Minnesota",
        "NameOrID": "Tancabel, Mark L.",
        "City": "Chisholm",
        "Year": "1983",
        "lat": "47.48975",
        "Document": " docs/mark-l-tancabel.pdf"
    },
    {
        "TroopNumber": "Troop 150",
        "lng": "-90.077749",
        "State": "Louisiana",
        "NameOrID": "Tardo, Joseph P.",
        "City": "New Orleans",
        "Year": "1988",
        "lat": "29.9537",
        "Document": "docs/joseph-p-tardo.pdf"
    },
    {
        "TroopNumber": "Troop 122",
        "lng": "-86.079315",
        "State": "Alabama",
        "NameOrID": "Tate, Mark Christopher",
        "City": "Scottsboro",
        "Year": "1990",
        "lat": "34.681391",
        "Document": "docs/mark-christopher-tate.pdf"
    },
    {
        "TroopNumber": "Not Applicable ",
        "lng": "",
        "State": "",
        "NameOrID": "Tatu, Robert J.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/robert-j-tatu.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Taus, Richard",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/richard-taus.pdf"
    },
    {
        "TroopNumber": "Troop 581",
        "lng": "-117.915719",
        "State": "California",
        "NameOrID": "Taylor, Robert",
        "City": "Anaheim",
        "Year": "1989",
        "lat": "33.83276",
        "Document": "docs/robert-taylor.pdf"
    },
    {
        "TroopNumber": "Pack 148",
        "lng": "-88.749304",
        "State": "Illinois",
        "NameOrID": "Taylor, Christopher",
        "City": "Dekalb",
        "Year": "1989",
        "lat": "41.929305",
        "Document": "docs/christopher-taylor.pdf"
    },
    {
        "TroopNumber": "867",
        "lng": "-77.604546",
        "State": "Pennsylvania",
        "NameOrID": "Tebbs, Albert H.",
        "City": "Thorunbury",
        "Year": "1974",
        "lat": "40.994705",
        "Document": " docs/albert-h-tebbs.pdf"
    },
    {
        "TroopNumber": "Troop 518",
        "lng": "-70.229997",
        "State": "Maine",
        "NameOrID": "Teel, Randall L.",
        "City": "Wilton",
        "Year": "1989",
        "lat": "44.58929",
        "Document": "docs/randall-l-teel.pdf"
    },
    {
        "TroopNumber": "12",
        "lng": "-73.941026",
        "State": "New York",
        "NameOrID": "Teeling, Robert H.",
        "City": "Schenectady",
        "Year": "1986",
        "lat": "42.81226",
        "Document": "docs/robert-h-teeling.pdf"
    },
    {
        "TroopNumber": "Pack 279",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "Tercey, Judith A.",
        "City": "San Diego",
        "Year": "1976",
        "lat": "32.715695",
        "Document": " docs/judith-a-tercey.pdf"
    },
    {
        "TroopNumber": "Pack 279",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "Tercey, Franklin K.",
        "City": "San Diego",
        "Year": "1976",
        "lat": "32.715695",
        "Document": " docs/franklin-k-tercey.pdf"
    },
    {
        "TroopNumber": "Troop 34",
        "lng": "-76.285069",
        "State": "Virginia",
        "NameOrID": "Terry, Michael E.",
        "City": "Norfolk",
        "Year": "1976",
        "lat": "36.846825",
        "Document": " docs/michael-e-terry.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-122.471219",
        "State": "Washington",
        "NameOrID": "Theel, John Richard",
        "City": "Bellingham",
        "Year": "1990",
        "lat": "48.75235",
        "Document": "docs/john-richard-theel.pdf"
    },
    {
        "TroopNumber": "Troop 38",
        "lng": "-87.977399",
        "State": "Illinois",
        "NameOrID": "Theis, Farrell J.",
        "City": "Westmont",
        "Year": "1989",
        "lat": "41.7952",
        "Document": "docs/farrell-j-theis.pdf"
    },
    {
        "TroopNumber": "Troop 223",
        "lng": "-74.420076",
        "State": "New Jersey",
        "NameOrID": "Thiel, Joseph J.",
        "City": "East Brunswick",
        "Year": "1987",
        "lat": "40.417592",
        "Document": "docs/joseph-j-thiel.pdf"
    },
    {
        "TroopNumber": "Pack 3037",
        "lng": "-122.562533",
        "State": "California",
        "NameOrID": "Thomas, James G.",
        "City": "San Anselmo",
        "Year": "1989",
        "lat": "37.974812",
        "Document": "docs/james-g-thomas.pdf"
    },
    {
        "TroopNumber": "Troop 215",
        "lng": "-81.448309",
        "State": "West Virginia",
        "NameOrID": "Thornton, Peter B.",
        "City": "Williamstown",
        "Year": "1988",
        "lat": "39.40271",
        "Document": "docs/peter-b-thornton.pdf"
    },
    {
        "TroopNumber": "Troop 625",
        "lng": "-73.990364",
        "State": "New York",
        "NameOrID": "Thorpe, Menica Oliver",
        "City": "Brooklyn",
        "Year": "1988",
        "lat": "40.692455",
        "Document": "docs/menica-oliver-thorpe.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Tibbetts, Jack D.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/jack-d-tibbetts.pdf"
    },
    {
        "TroopNumber": "Pack 425",
        "lng": "-80.905184",
        "State": "Florida",
        "NameOrID": "Tiffany, Harrison V.",
        "City": "Edgewater",
        "Year": "1989",
        "lat": "28.99367",
        "Document": "docs/harrison-v-tiffany.pdf"
    },
    {
        "TroopNumber": "Troop 9",
        "lng": "-90.663269",
        "State": "Iowa",
        "NameOrID": "Tillman, John E.",
        "City": "Dubuque",
        "Year": "1989",
        "lat": "42.49913",
        "Document": "docs/john-e-tillman.pdf"
    },
    {
        "TroopNumber": "Troop 167",
        "lng": "-94.915599",
        "State": "Kansas",
        "NameOrID": "Tinkham, Ken",
        "City": "Leavenworth",
        "Year": "1985",
        "lat": "39.31927",
        "Document": " docs/ken-tinkham.pdf"
    },
    {
        "TroopNumber": "480",
        "lng": "-122.441654",
        "State": "Washington",
        "NameOrID": "Tisdale, Edgar A.",
        "City": "Tacoma",
        "Year": "1990",
        "lat": "47.255135",
        "Document": "docs/edgar-a-tisdale.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "Washington",
        "NameOrID": "Tisdale, Edgar A.",
        "City": "Belfair",
        "Year": "1990",
        "lat": "",
        "Document": "docs/edgar-a-tisdale.pdf"
    },
    {
        "TroopNumber": "Troop 1531",
        "lng": "-77.354571",
        "State": "Virginia",
        "NameOrID": "Toland, Ray B.",
        "City": "Reston",
        "Year": "1988",
        "lat": "38.959374",
        "Document": "docs/ray-b-toland.pdf"
    },
    {
        "TroopNumber": "438",
        "lng": "",
        "State": "",
        "NameOrID": "Tolle, Daniel",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/daniel-tolle.pdf"
    },
    {
        "TroopNumber": "Explorer 2044",
        "lng": "-75.400159",
        "State": "Pennsylvania",
        "NameOrID": "Toner, Richard J.",
        "City": "Newton Square",
        "Year": "1978",
        "lat": "39.987756",
        "Document": "docs/richard-j-toner.pdf"
    },
    {
        "TroopNumber": "Explorer 961",
        "lng": "-82.637124",
        "State": "Florida",
        "NameOrID": "Townsend, Robert Clayton",
        "City": "Lake City",
        "Year": "1990",
        "lat": "30.19036",
        "Document": "docs/robert-clayton-townsend.pdf"
    },
    {
        "TroopNumber": "Troop 19",
        "lng": "-76.724289",
        "State": "Pennsylvania",
        "NameOrID": "Tracy, Gregory E.",
        "City": "York",
        "Year": "1985",
        "lat": "39.964535",
        "Document": "docs/gregory-e-tracy.pdf"
    },
    {
        "TroopNumber": "Troop 21",
        "lng": "-76.724289",
        "State": "Pennsylvania",
        "NameOrID": "Tracy, Gregory E.",
        "City": "York",
        "Year": "1985",
        "lat": "39.964535",
        "Document": "docs/gregory-e-tracy.pdf"
    },
    {
        "TroopNumber": "Troop 17",
        "lng": "-76.724289",
        "State": "Pennsylvania",
        "NameOrID": "Tracy, Gregory E.",
        "City": "York",
        "Year": "1985",
        "lat": "39.964535",
        "Document": "docs/gregory-e-tracy.pdf"
    },
    {
        "TroopNumber": "Troop 180",
        "lng": "-76.724289",
        "State": "Pennsylvania",
        "NameOrID": "Tracy, Gregory E.",
        "City": "York",
        "Year": "1985",
        "lat": "39.964535",
        "Document": "docs/gregory-e-tracy.pdf"
    },
    {
        "TroopNumber": "Explorer 70",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "Traver, Benjamin",
        "City": "Syracuse",
        "Year": "1991",
        "lat": "43.04999",
        "Document": "docs/benjamin-traver.pdf"
    },
    {
        "TroopNumber": "587",
        "lng": "-88.235513",
        "State": "Wisconsin",
        "NameOrID": "Treder, John P.",
        "City": "Waukesha",
        "Year": "1991",
        "lat": "43.014423",
        "Document": "docs/john-p-treder.pdf"
    },
    {
        "TroopNumber": "Explorer 551",
        "lng": "-88.235513",
        "State": "Wisconsin",
        "NameOrID": "Treder, John P.",
        "City": "Waukesha",
        "Year": "1991",
        "lat": "43.014423",
        "Document": "docs/john-p-treder.pdf"
    },
    {
        "TroopNumber": "Troop 586",
        "lng": "-70.150562",
        "State": "Maine",
        "NameOrID": "Tripp, Glendon E.",
        "City": "Farmington",
        "Year": "1989",
        "lat": "44.66982",
        "Document": "docs/glendon-e-tripp.pdf"
    },
    {
        "TroopNumber": "Troop 631",
        "lng": "-76.992559",
        "State": "Maryland",
        "NameOrID": "Tross, William Haskins",
        "City": "Westminster",
        "Year": "1991",
        "lat": "39.574485",
        "Document": "docs/william-haskins-tross.pdf"
    },
    {
        "TroopNumber": "Troop 188",
        "lng": "-89.313344",
        "State": "Illinois",
        "NameOrID": "Troyer, Duane Dale",
        "City": "Minier",
        "Year": "1982",
        "lat": "40.431895",
        "Document": " docs/duane-dale-troyer.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "",
        "State": "",
        "NameOrID": "Truman, Allon",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/allon-truman.pdf"
    },
    {
        "TroopNumber": "Venturing 244",
        "lng": "-112.032722",
        "State": "Utah",
        "NameOrID": "Tubbs, Robert",
        "City": "Slaterville",
        "Year": "1990",
        "lat": "41.264111",
        "Document": "docs/robert-tubbs.pdf"
    },
    {
        "TroopNumber": "684",
        "lng": "-101.845009",
        "State": "Texas",
        "NameOrID": "Turk, Michael A.",
        "City": "Lubbock",
        "Year": "1986",
        "lat": "33.58451",
        "Document": "docs/michael-a-turk.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "California",
        "NameOrID": "Turley, Richard J.",
        "City": "",
        "Year": "1979",
        "lat": "",
        "Document": "docs/richard-j-turley.pdf"
    },
    {
        "TroopNumber": "Troop 514",
        "lng": "-117.950634",
        "State": "California",
        "NameOrID": "Turley, Richard J.",
        "City": "Fountain Valley",
        "Year": "1979",
        "lat": "33.70907",
        "Document": "docs/richard-j-turley.pdf"
    },
    {
        "TroopNumber": "Not Applicable ",
        "lng": "",
        "State": "",
        "NameOrID": "Turmenne, David J.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/david-j-turmenne.pdf"
    },
    {
        "TroopNumber": "Explorer 2811",
        "lng": "-122.061628",
        "State": "California",
        "NameOrID": "Turner, Robert S.",
        "City": "Walnut Creek",
        "Year": "1989",
        "lat": "37.90118",
        "Document": "docs/robert-s-turner.pdf"
    },
    {
        "TroopNumber": "Pack 3996",
        "lng": "-82.112654",
        "State": "Florida",
        "NameOrID": "Tusing, Clifton Russell",
        "City": "Bushnell",
        "Year": "1987",
        "lat": "28.664535",
        "Document": "docs/clifton-russell-tusing.pdf"
    },
    {
        "TroopNumber": "Troop 104",
        "lng": "-96.498564",
        "State": "Nebraska",
        "NameOrID": "Tweedy, Mark A.",
        "City": "Fremont",
        "Year": "1989",
        "lat": "41.43441",
        "Document": "docs/mark-a-tweedy.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-71.464209",
        "State": "New Hampshire",
        "NameOrID": "Tyler, William M.",
        "City": "Nashua",
        "Year": "1986",
        "lat": "42.758365",
        "Document": "docs/william-m-tyler.pdf"
    },
    {
        "TroopNumber": "Troop 8",
        "lng": "-156.644638",
        "State": "Hawaii",
        "NameOrID": "Tyler, William M.",
        "City": "Maui",
        "Year": "1986",
        "lat": "20.862615",
        "Document": "docs/william-m-tyler.pdf"
    },
    {
        "TroopNumber": "Troop 425",
        "lng": "-77.433654",
        "State": "Virginia",
        "NameOrID": "Tyree, William Edward",
        "City": "Richmond",
        "Year": "1986",
        "lat": "37.5407",
        "Document": "docs/william-edward-tyree.pdf"
    },
    {
        "TroopNumber": "Pack 413",
        "lng": "-77.433654",
        "State": "Virginia",
        "NameOrID": "Underwood, Claude James",
        "City": "Richmond",
        "Year": "1971",
        "lat": "37.5407",
        "Document": " docs/claude-james-underwood.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-80.143564",
        "State": "Florida",
        "NameOrID": "Urig, Charles J.",
        "City": "Ft. Lauderdale",
        "Year": "1972",
        "lat": "26.12367",
        "Document": " docs/charles-j-urig.pdf"
    },
    {
        "TroopNumber": "Troop 5",
        "lng": "-73.829959",
        "State": "New York",
        "NameOrID": "Urig, Charles J.",
        "City": "Queens",
        "Year": "1972",
        "lat": "40.713985",
        "Document": " docs/charles-j-urig.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-82.974829",
        "State": "Ohio",
        "NameOrID": "Van Allen, John Richard",
        "City": "Clyde",
        "Year": "1987",
        "lat": "41.309345",
        "Document": "docs/john-richard-van-allen.pdf"
    },
    {
        "TroopNumber": "Troop 203",
        "lng": "-118.307504",
        "State": "California",
        "NameOrID": "Van Dyke, Larry",
        "City": "Gardena",
        "Year": "1987",
        "lat": "33.88379",
        "Document": "docs/larry-van-dyke.pdf"
    },
    {
        "TroopNumber": "Troop 38",
        "lng": "-81.353934",
        "State": "Ohio",
        "NameOrID": "VanFossen, Ernest L.",
        "City": "East Sparta",
        "Year": "1989",
        "lat": "40.666935",
        "Document": "docs/ernest-l-vanfossen.pdf"
    },
    {
        "TroopNumber": "60",
        "lng": "-75.673339",
        "State": "New York",
        "NameOrID": "Van Luven, Daniel W.",
        "City": "Copenhagen",
        "Year": "1986",
        "lat": "43.893325",
        "Document": "docs/daniel-w-van-luven.pdf"
    },
    {
        "TroopNumber": "Troop 41",
        "lng": "-81.514059",
        "State": "Ohio",
        "NameOrID": "Vannoy, Roger D.",
        "City": "Akron",
        "Year": "1973",
        "lat": "41.084195",
        "Document": " docs/roger-d-vannoy.pdf"
    },
    {
        "TroopNumber": "Troop 59",
        "lng": "-81.514059",
        "State": "Ohio",
        "NameOrID": "Vannoy, Roger D.",
        "City": "Akron",
        "Year": "1973",
        "lat": "41.084195",
        "Document": " docs/roger-d-vannoy.pdf"
    },
    {
        "TroopNumber": "Troop 519",
        "lng": "-86.270889",
        "State": "Indiana",
        "NameOrID": "Van Tyle, Thomas E.",
        "City": "Russiaville",
        "Year": "1990",
        "lat": "40.417486",
        "Document": "docs/thomas-e-van-tyle.pdf"
    },
    {
        "TroopNumber": "Troop 700",
        "lng": "-110.969754",
        "State": "Arizona",
        "NameOrID": "Vaughn, Miles S.",
        "City": "Tucson",
        "Year": "1981",
        "lat": "32.221553",
        "Document": " docs/miles-s-vaughn.pdf"
    },
    {
        "TroopNumber": "Troop 421",
        "lng": "",
        "State": "California",
        "NameOrID": "Vaughn, Charles S.",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": "docs/charles-s-vaughn.pdf"
    },
    {
        "TroopNumber": "Troop 345",
        "lng": "-79.437914",
        "State": "North Carolina",
        "NameOrID": "Vehaun, Charles Marion",
        "City": "Burlington",
        "Year": "1984",
        "lat": "36.096865",
        "Document": " docs/charles-marion-vehaun.pdf"
    },
    {
        "TroopNumber": "Troop 526",
        "lng": "-83.047924",
        "State": "Michigan",
        "NameOrID": "Vehoski, Robert J.",
        "City": "Detroit",
        "Year": "1987",
        "lat": "42.331685",
        "Document": "docs/robert-j-vehoski.pdf"
    },
    {
        "TroopNumber": "Troop 64",
        "lng": "-98.494619",
        "State": "Texas",
        "NameOrID": "Vela, Herlin E.",
        "City": "San Antonio",
        "Year": "1982",
        "lat": "29.42449",
        "Document": " docs/herlin-e-vela.pdf"
    },
    {
        "TroopNumber": "Troop 367",
        "lng": "-77.860704",
        "State": "Pennsylvania",
        "NameOrID": "Venett, Ross A.",
        "City": "State College",
        "Year": "1988",
        "lat": "40.79373",
        "Document": "docs/ross-a-venett.pdf"
    },
    {
        "TroopNumber": "Troop 770",
        "lng": "-80.241094",
        "State": "North Carolina",
        "NameOrID": "Verheyen, Philippe F.",
        "City": "Winston-Salem",
        "Year": "1989",
        "lat": "36.099625",
        "Document": "docs/philippe-f-verheyen.pdf"
    },
    {
        "TroopNumber": "Troop 178",
        "lng": "-75.338698",
        "State": "Pennsylvania",
        "NameOrID": "Verney, Douglas Geoffrey",
        "City": "Kulpsville",
        "Year": "1990",
        "lat": "40.245492",
        "Document": "docs/douglas-geoffrey-verney.pdf"
    },
    {
        "TroopNumber": "Troop 241",
        "lng": "-80.250729",
        "State": "North Carolina",
        "NameOrID": "Vernon, Rickey Martin",
        "City": "Lexington",
        "Year": "1983",
        "lat": "35.826245",
        "Document": " docs/rickey-martin-vernon.pdf"
    },
    {
        "TroopNumber": "Troop 1622",
        "lng": "-85.381734",
        "State": "Indiana",
        "NameOrID": "Vernon, Russell H.",
        "City": "Muncie",
        "Year": "1989",
        "lat": "40.193165",
        "Document": "docs/russell-h-vernon.pdf"
    },
    {
        "TroopNumber": "Troop 46",
        "lng": "",
        "State": "Idaho",
        "NameOrID": "Vetro, James",
        "City": "",
        "Year": "1972",
        "lat": "",
        "Document": " docs/james-vetro.pdf"
    },
    {
        "TroopNumber": "Troop 88",
        "lng": "-97.609704",
        "State": "Kansas",
        "NameOrID": "Vick, Earl W.",
        "City": "Salina",
        "Year": "1988",
        "lat": "38.840545",
        "Document": "docs/earl-w-vick.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Stachowski, Michael Jerome",
        "City": "",
        "Year": "1981",
        "lat": "",
        "Document": "docs/michael-jerome-stachowski.pdf"
    },
    {
        "TroopNumber": "Troop 706",
        "lng": "-80.726179",
        "State": "Florida",
        "NameOrID": "Vickers, William F.",
        "City": "Cocoa",
        "Year": "1989",
        "lat": "28.35167",
        "Document": "docs/william-f-vickers.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "",
        "State": "",
        "NameOrID": "Vila, James Edward",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/james-edward-vila.pdf"
    },
    {
        "TroopNumber": "Pack 101",
        "lng": "-80.12522",
        "State": "Florida",
        "NameOrID": "Stafford, Ernest Allan",
        "City": "Pompano Beach",
        "Year": "1985",
        "lat": "26.231452",
        "Document": " docs/ernest-allan-stafford.pdf"
    },
    {
        "TroopNumber": "Troop 330",
        "lng": "-104.992259",
        "State": "Colorado",
        "NameOrID": "Vincent, Matthew D.C.",
        "City": "Denver",
        "Year": "1990",
        "lat": "39.74001",
        "Document": "docs/matthew-dc-vincent.pdf"
    },
    {
        "TroopNumber": "Troop 60",
        "lng": "-120.42468",
        "State": "California",
        "NameOrID": "Stahl, Mark E.",
        "City": "Jamestown",
        "Year": "1984",
        "lat": "37.953407",
        "Document": " docs/mark-e-stahl.pdf"
    },
    {
        "TroopNumber": "Troop 536",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Vines, Michael D.",
        "City": "Dallas",
        "Year": "1973",
        "lat": "32.778155",
        "Document": " docs/michael-d-vines.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Voges, Harold C.",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": " docs/harold-c-voges.pdf"
    },
    {
        "TroopNumber": "Pack 3057",
        "lng": "-89.41342",
        "State": "Illinois",
        "NameOrID": "Volkmar, Arnold A.",
        "City": "Greenville",
        "Year": "1990",
        "lat": "38.89237",
        "Document": "docs/arnold-a-volkmar.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Von Aspern, Dean Ray",
        "City": "",
        "Year": "1986",
        "lat": "",
        "Document": "docs/dean-ray-von-aspern.pdf"
    },
    {
        "TroopNumber": "Troop 164",
        "lng": "-93.206174",
        "State": "Minnesota",
        "NameOrID": "Voll, Gerhard U.",
        "City": "Mounds View",
        "Year": "1980",
        "lat": "45.103585",
        "Document": "docs/gerhard-u-voll.pdf"
    },
    {
        "TroopNumber": "Pack 336",
        "lng": "-117.850399",
        "State": "California",
        "NameOrID": "Von Hovatter, Kurt E.",
        "City": "Orange",
        "Year": "1989",
        "lat": "33.787865",
        "Document": "docs/kurt-e-von-hovatter.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Walker, John L.",
        "City": "",
        "Year": "1977",
        "lat": "",
        "Document": " docs/john-l-walker.pdf"
    },
    {
        "TroopNumber": "Troop 50",
        "lng": "-76.789502",
        "State": "New York",
        "NameOrID": "VonNeida, Daryl E.",
        "City": "Odessa",
        "Year": "1978",
        "lat": "42.336248",
        "Document": "docs/daryl-e-vonneida.pdf"
    },
    {
        "TroopNumber": "Pack 419",
        "lng": "-81.127539",
        "State": "Florida",
        "NameOrID": "Walker, Jim R.",
        "City": "Flagler Beach",
        "Year": "1988",
        "lat": "29.481005",
        "Document": "docs/jim-r-walker.pdf"
    },
    {
        "TroopNumber": "25",
        "lng": "-83.806734",
        "State": "Ohio",
        "NameOrID": "Voorhees, David A.",
        "City": "Springfield",
        "Year": "1986",
        "lat": "39.92502",
        "Document": "docs/david-a-voorhees.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Walles, Albert Anthony",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/albert-anthony-walles.pdf"
    },
    {
        "TroopNumber": "Pack 5",
        "lng": "-82.428449",
        "State": "Michigan",
        "NameOrID": "Wadaga, Paul J.",
        "City": "Port Huron",
        "Year": "1988",
        "lat": "42.97583",
        "Document": "docs/paul-j-wadaga.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-75.913164",
        "State": "New York",
        "NameOrID": "Walrath, Rodney Bruce",
        "City": "Watertown",
        "Year": "1986",
        "lat": "43.975589",
        "Document": "docs/rodney-bruce-walrath.pdf"
    },
    {
        "TroopNumber": "Troop 17",
        "lng": "-75.913164",
        "State": "New York",
        "NameOrID": "Walrath, Rodney Bruce",
        "City": "Watertown",
        "Year": "1986",
        "lat": "43.975589",
        "Document": "docs/rodney-bruce-walrath.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Wade, Jim",
        "City": "",
        "Year": "1980",
        "lat": "",
        "Document": " docs/jim-wade.pdf"
    },
    {
        "TroopNumber": "Troop 701",
        "lng": "-80.685989",
        "State": "Florida",
        "NameOrID": "Wagner, Robert",
        "City": "Merritt Island",
        "Year": "1989",
        "lat": "28.357095",
        "Document": "docs/robert-wagner.pdf"
    },
    {
        "TroopNumber": "Pack 401",
        "lng": "-97.329389",
        "State": "Texas",
        "NameOrID": "Ward, Vernon W.",
        "City": "Ft. Worth",
        "Year": "1980",
        "lat": "32.748855",
        "Document": " docs/vernon-w-ward.pdf"
    },
    {
        "TroopNumber": "Troop 105",
        "lng": "-75.547839",
        "State": "Pennsylvania",
        "NameOrID": "Wakely, Lawrence",
        "City": "Spring City",
        "Year": "1971",
        "lat": "40.17676",
        "Document": " docs/lawrence-wakely.pdf"
    },
    {
        "TroopNumber": "Troop 40",
        "lng": "-97.329389",
        "State": "Texas",
        "NameOrID": "Ward, Vernon W.",
        "City": "Fort Worth",
        "Year": "1980",
        "lat": "32.748855",
        "Document": " docs/vernon-w-ward.pdf"
    },
    {
        "TroopNumber": "Troop 60",
        "lng": "-84.700769",
        "State": "Michigan",
        "NameOrID": "Waldo, Michael Lynn",
        "City": "Wacousta",
        "Year": "1990",
        "lat": "42.84958",
        "Document": "docs/michael-lynn-waldo.pdf"
    },
    {
        "TroopNumber": "Troop 87",
        "lng": "-71.192907",
        "State": "Massachusetts",
        "NameOrID": "Ward, Steven J.",
        "City": "Swansea",
        "Year": "1990",
        "lat": "41.749498",
        "Document": "docs/steven-j-ward.pdf"
    },
    {
        "TroopNumber": "8603",
        "lng": "",
        "State": "Texas",
        "NameOrID": "Walker, Harry James",
        "City": "",
        "Year": "1986",
        "lat": "",
        "Document": "docs/harry-james-walker.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-75.524239",
        "State": "New York",
        "NameOrID": "Ward, Timothy C.",
        "City": "Norwich",
        "Year": "1988",
        "lat": "42.536255",
        "Document": "docs/timothy-c-ward.pdf"
    },
    {
        "TroopNumber": "Troop 448",
        "lng": "-81.007449",
        "State": "Florida",
        "NameOrID": "Walker, Exum D.",
        "City": "South Daytona",
        "Year": "1973",
        "lat": "29.181635",
        "Document": " docs/exum-d-walker.pdf"
    },
    {
        "TroopNumber": "District 35",
        "lng": "-124.219519",
        "State": "Oregon",
        "NameOrID": "Ward, Frank",
        "City": "Coos Bay",
        "Year": "1989",
        "lat": "43.367955",
        "Document": "docs/frank-ward.pdf"
    },
    {
        "TroopNumber": "Not Applicable ",
        "lng": "",
        "State": "",
        "NameOrID": "Welander, James",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/james-welander.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Wellhausen, William George",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": " docs/william-george-wellhausen.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Wargo, Richard P.",
        "City": "",
        "Year": "1984",
        "lat": "",
        "Document": " docs/richard-p-wargo.pdf"
    },
    {
        "TroopNumber": "Troop 721",
        "lng": "",
        "State": "",
        "NameOrID": "Wells, Fred",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": " docs/fred-wells.pdf"
    },
    {
        "TroopNumber": "Troop 76",
        "lng": "-88.46234",
        "State": "Illinois",
        "NameOrID": "Warner, Tom",
        "City": "Atwood",
        "Year": "1975",
        "lat": "39.79702",
        "Document": " docs/tom-warner.pdf"
    },
    {
        "TroopNumber": "Troop 889",
        "lng": "-76.609264",
        "State": "Maryland",
        "NameOrID": "Welsh, Taunton",
        "City": "Baltimore",
        "Year": "1990",
        "lat": "39.290585",
        "Document": "docs/taunton-welsh.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Warner, Philip L.",
        "City": "",
        "Year": "1985",
        "lat": "",
        "Document": " docs/philip-l-warner.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 361",
        "lng": "-76.609264",
        "State": "Maryland",
        "NameOrID": "Welsh, Taunton",
        "City": "Baltimore",
        "Year": "1990",
        "lat": "39.290585",
        "Document": "docs/taunton-welsh.pdf"
    },
    {
        "TroopNumber": "Troop 192",
        "lng": "-80.052689",
        "State": "Florida",
        "NameOrID": "Warren, Thomas R.",
        "City": "West palm beach",
        "Year": "1983",
        "lat": "26.714388",
        "Document": " docs/thomas-r-warren.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 146",
        "lng": "-122.160794",
        "State": "California",
        "NameOrID": "Wentworth, Ronald S.",
        "City": "Palo Alto",
        "Year": "1972",
        "lat": "37.44466",
        "Document": " docs/ronald-s-wentworth.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-85.660384",
        "State": "Indiana",
        "NameOrID": "Warren, Lowell Dean",
        "City": "Marion",
        "Year": "1985",
        "lat": "40.558805",
        "Document": " docs/lowell-dean-warren.pdf"
    },
    {
        "TroopNumber": "Troop 333",
        "lng": "-76.285069",
        "State": "Virginia",
        "NameOrID": "Wentz, George Whitfield",
        "City": "Norfolk",
        "Year": "1984",
        "lat": "36.846825",
        "Document": " docs/george-whitfield-wentz.pdf"
    },
    {
        "TroopNumber": "Pack 3150",
        "lng": "-95.789504",
        "State": "Oklahoma",
        "NameOrID": "West, David Raymond",
        "City": "Broken Arrow",
        "Year": "1985",
        "lat": "36.050845",
        "Document": " docs/david-raymond-west.pdf"
    },
    {
        "TroopNumber": "Troop 151",
        "lng": "-117.850399",
        "State": "California",
        "NameOrID": "Wheeler, Daniel",
        "City": "Orange",
        "Year": "1985",
        "lat": "33.787865",
        "Document": " docs/daniel-wheeler.pdf"
    },
    {
        "TroopNumber": "Pack ",
        "lng": "-118.122289",
        "State": "California",
        "NameOrID": "West, John Henry",
        "City": "Bellflower",
        "Year": "1988",
        "lat": "33.882745",
        "Document": "docs/john-henry-west.pdf"
    },
    {
        "TroopNumber": "Troop 27",
        "lng": "-88.181149",
        "State": "Wisconsin",
        "NameOrID": "Whipple, David",
        "City": "West Bend",
        "Year": "1972",
        "lat": "43.40691",
        "Document": " docs/david-whipple.pdf"
    },
    {
        "TroopNumber": "Pack ",
        "lng": "-118.122289",
        "State": "California",
        "NameOrID": "West, Annabel",
        "City": "Bellflower",
        "Year": "1988",
        "lat": "33.882745",
        "Document": "docs/annabel-west.pdf"
    },
    {
        "TroopNumber": "Troop 22",
        "lng": "-122.270539",
        "State": "California",
        "NameOrID": "Whitacre, Alfred Carl",
        "City": "Berkeley",
        "Year": "1990",
        "lat": "37.869885",
        "Document": "docs/alfred-karl-whitacre.pdf"
    },
    {
        "TroopNumber": "Troop 5",
        "lng": "-117.850399",
        "State": "California",
        "NameOrID": "Wheeler, Daniel",
        "City": "Orange",
        "Year": "1985",
        "lat": "33.787865",
        "Document": " docs/daniel-wheeler.pdf"
    },
    {
        "TroopNumber": "Troop 399",
        "lng": "-79.02881",
        "State": "New York",
        "NameOrID": "White, Douglas Louis",
        "City": "Angola ",
        "Year": "1972",
        "lat": "42.637391",
        "Document": "docs/douglas-louis-white.pdf"
    },
    {
        "TroopNumber": "Pack 274",
        "lng": "-82.877409",
        "State": "Michigan",
        "NameOrID": "White, Robert E.",
        "City": "Mt. Clemens",
        "Year": "1985",
        "lat": "42.596605",
        "Document": " docs/robert-e-white.pdf"
    },
    {
        "TroopNumber": "Troop 893",
        "lng": "-77.44327",
        "State": "Virginia",
        "NameOrID": "Whitmer, Henry J.",
        "City": "Centreville",
        "Year": "1987",
        "lat": "38.837413",
        "Document": "docs/henry-j-whitmer.pdf"
    },
    {
        "TroopNumber": "Pack 208",
        "lng": "-82.615241",
        "State": "Ohio",
        "NameOrID": "White, Russell R.",
        "City": "Norwalk ",
        "Year": "1990",
        "lat": "41.241996",
        "Document": "docs/russell-r-white.pdf"
    },
    {
        "TroopNumber": "Explorer 117",
        "lng": "-77.44327",
        "State": "Virginia",
        "NameOrID": "Whitmer, Henry J.",
        "City": "Centreville",
        "Year": "1987",
        "lat": "38.837413",
        "Document": "docs/henry-j-whitmer.pdf"
    },
    {
        "TroopNumber": "Troop 162",
        "lng": "-82.425599",
        "State": "South Carolina",
        "NameOrID": "White, Charles E.",
        "City": "Keowee",
        "Year": "1978",
        "lat": "34.38697",
        "Document": "docs/charles-e-white.pdf"
    },
    {
        "TroopNumber": "Pack 157",
        "lng": "-82.105839",
        "State": "Ohio",
        "NameOrID": "Whiton, Curtis William",
        "City": "Elyria",
        "Year": "1983",
        "lat": "41.36768",
        "Document": " docs/curtis-william-whiton.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-91.186994",
        "State": "Louisiana",
        "NameOrID": "White, David D.",
        "City": "Baton Rouge",
        "Year": "1975",
        "lat": "30.443345",
        "Document": " docs/david-d-white.pdf"
    },
    {
        "TroopNumber": "Troop 109",
        "lng": "-82.105839",
        "State": "Ohio",
        "NameOrID": "Whiton, Curtis William",
        "City": "Elyria",
        "Year": "1983",
        "lat": "41.36768",
        "Document": " docs/curtis-william-whiton.pdf"
    },
    {
        "TroopNumber": "Venturing 6001",
        "lng": "-104.612374",
        "State": "Colorado",
        "NameOrID": "White, Neil Eldred",
        "City": "Pueblo",
        "Year": "1988",
        "lat": "38.26386",
        "Document": "docs/neil-eldred-white.pdf"
    },
    {
        "TroopNumber": "Troop 38",
        "lng": "-85.381734",
        "State": "Indiana",
        "NameOrID": "Whittenburg, John Casher",
        "City": "Muncie",
        "Year": "1986",
        "lat": "40.193165",
        "Document": "docs/john-casher-whittenburg.pdf"
    },
    {
        "TroopNumber": "Pack 129",
        "lng": "-105.116984",
        "State": "Colorado",
        "NameOrID": "Whittington, Douglas D.",
        "City": "Florence",
        "Year": "1988",
        "lat": "38.38979",
        "Document": " docs/douglas-d-whittington.pdf"
    },
    {
        "TroopNumber": "Troop 510",
        "lng": "-90.012718",
        "State": "Illinois",
        "NameOrID": "White, Donald Lee",
        "City": "Fairview Heights",
        "Year": "1988",
        "lat": "38.597875",
        "Document": "docs/donald-lee-white.pdf"
    },
    {
        "TroopNumber": "Troop 110",
        "lng": "-84.351719",
        "State": "Michigan",
        "NameOrID": "Wiggins, Tommie D.",
        "City": "Sault Saint Marie",
        "Year": "1982",
        "lat": "46.49231",
        "Document": " docs/tommie-d-wiggins.pdf"
    },
    {
        "TroopNumber": "Pack 5606",
        "lng": "-89.984234",
        "State": "Illinois",
        "NameOrID": "White, Donald Lee",
        "City": "Belleville",
        "Year": "1988",
        "lat": "38.512135",
        "Document": "docs/donald-lee-white.pdf"
    },
    {
        "TroopNumber": "Camp 471",
        "lng": "-72.481461",
        "State": "Vermont",
        "NameOrID": "Wilkins, Ronald",
        "City": "Springfield",
        "Year": "1986",
        "lat": "43.296562",
        "Document": "docs/ronald-wilkins.pdf"
    },
    {
        "TroopNumber": "Troop 643",
        "lng": "-89.084239",
        "State": "Wisconsin",
        "NameOrID": "White, Bradford A.",
        "City": "Waupaca",
        "Year": "1989",
        "lat": "44.35603",
        "Document": "docs/bradford-a-white.pdf"
    },
    {
        "TroopNumber": "Troop 291",
        "lng": "",
        "State": "",
        "NameOrID": "Willey, Carleton Benjamin",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/carleton-benjamin-willey.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-76.243524",
        "State": "Pennsylvania",
        "NameOrID": "Whitenight, Ronnie D.",
        "City": "Berwick",
        "Year": "1989",
        "lat": "41.050885",
        "Document": "docs/ronnie-d-whitenight.pdf"
    },
    {
        "TroopNumber": "Troop 301",
        "lng": "-112.022314",
        "State": "Utah",
        "NameOrID": "Williams, Keith G.",
        "City": "Clearfield",
        "Year": "1990",
        "lat": "41.11384",
        "Document": "docs/keith-g-williams.pdf"
    },
    {
        "TroopNumber": "Troop 75",
        "lng": "-123.207904",
        "State": "California",
        "NameOrID": "Williams, Richard Hugh",
        "City": "Ukiah",
        "Year": "1985",
        "lat": "39.150935",
        "Document": " docs/richard-hugh-williams.pdf"
    },
    {
        "TroopNumber": "Troop 442",
        "lng": "-76.059204",
        "State": "Virginia",
        "NameOrID": "Williams, Gregory Charles",
        "City": "Virginia Beach",
        "Year": "1985",
        "lat": "36.75502",
        "Document": " docs/gregory-charles-williams.pdf"
    },
    {
        "TroopNumber": "Troop 904",
        "lng": "-83.383481",
        "State": "Michigan",
        "NameOrID": "Whitlock, Robert N.",
        "City": "Wayne",
        "Year": "1977",
        "lat": "42.284745",
        "Document": " docs/robert-n-whitlock.pdf"
    },
    {
        "TroopNumber": "Troop 67",
        "lng": "-76.059204",
        "State": "Virginia",
        "NameOrID": "Williams, Gregory Charles",
        "City": "Virginia Beach",
        "Year": "1985",
        "lat": "36.75502",
        "Document": " docs/gregory-charles-williams.pdf"
    },
    {
        "TroopNumber": "District 8694",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Williams, Terry L.",
        "City": "Kansas City",
        "Year": "1987",
        "lat": "39.10296",
        "Document": "docs/terry-l-williams.pdf"
    },
    {
        "TroopNumber": "Troop 130",
        "lng": "-85.253189",
        "State": "Michigan",
        "NameOrID": "Williams, Gary Lee",
        "City": "Greenville",
        "Year": "1991",
        "lat": "43.178",
        "Document": "docs/gary-lee-williams.pdf"
    },
    {
        "TroopNumber": "Troop 850",
        "lng": "-84.391109",
        "State": "Georgia",
        "NameOrID": "Williams, Arthur Cornelius",
        "City": "Atlanta",
        "Year": "1984",
        "lat": "33.748315",
        "Document": " docs/arthur-cornelius-williams.pdf"
    },
    {
        "TroopNumber": "Troop 262",
        "lng": "-95.419294",
        "State": "Texas",
        "NameOrID": "Williams, Don",
        "City": "Spring",
        "Year": "1983",
        "lat": "30.084539",
        "Document": " docs/don-williams.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Williams, Dane Allen",
        "City": "",
        "Year": "1991",
        "lat": "",
        "Document": "docs/dane-allen-williams.pdf"
    },
    {
        "TroopNumber": "Explorer 715",
        "lng": "-122.592784",
        "State": "Oregon",
        "NameOrID": "Williams, Russ",
        "City": "Oregon City",
        "Year": "1989",
        "lat": "45.336518",
        "Document": "docs/russ-williams.pdf"
    },
    {
        "TroopNumber": "Troop 53",
        "lng": "-85.180929",
        "State": "Alabama",
        "NameOrID": "Wilson, Willie Lewis",
        "City": "Valley",
        "Year": "1988",
        "lat": "32.843965",
        "Document": "docs/willie-lewis-wilson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "Tennessee",
        "NameOrID": "Williams, James H.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/james-h-williams.pdf"
    },
    {
        "TroopNumber": "Troop 135",
        "lng": "-91.656939",
        "State": "Missouri",
        "NameOrID": "Wilson, Gary Dean",
        "City": "Sommersville",
        "Year": "1988",
        "lat": "37.17962",
        "Document": "docs/gary-dean-wilson.pdf"
    },
    {
        "TroopNumber": "Pack 375",
        "lng": "-79.885182",
        "State": "Virginia",
        "NameOrID": "Wilson, Billy Wayne",
        "City": "Wirtz",
        "Year": "1988",
        "lat": "37.071888",
        "Document": "docs/billy-wayne-wilson.pdf"
    },
    {
        "TroopNumber": "Pack 3001",
        "lng": "-88.416564",
        "State": "Wisconsin",
        "NameOrID": "Williamson, Kenneth E.",
        "City": "Appleton",
        "Year": "1983",
        "lat": "44.26178",
        "Document": " docs/kenneth-e-williamson.pdf"
    },
    {
        "TroopNumber": "Troop 49",
        "lng": "-79.326346",
        "State": "Pennsylvania",
        "NameOrID": "Wingard, Stephen Edward",
        "City": "Limestone",
        "Year": "1984",
        "lat": "41.128181",
        "Document": " docs/stephen-edward-wingard.pdf"
    },
    {
        "TroopNumber": "Pack 49",
        "lng": "-79.326346",
        "State": "Pennsylvania",
        "NameOrID": "Wingard, Stephen Edward",
        "City": "Limestone",
        "Year": "1984",
        "lat": "41.128181",
        "Document": " docs/stephen-edward-wingard.pdf"
    },
    {
        "TroopNumber": "District 4",
        "lng": "-88.416564",
        "State": "Wisconsin",
        "NameOrID": "Williamson, Kenneth E.",
        "City": "Appleton",
        "Year": "1983",
        "lat": "44.26178",
        "Document": " docs/kenneth-e-williamson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Winklepleck, Robert Harold",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/robert-harold-winklepleck.pdf"
    },
    {
        "TroopNumber": "Troop 150",
        "lng": "-76.127904",
        "State": "New York",
        "NameOrID": "Winner, Scott M.",
        "City": "North Syracuse",
        "Year": "1989",
        "lat": "43.132525",
        "Document": "docs/scott-m-winner.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Willie, Fred L.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/fred-l-willie.pdf"
    },
    {
        "TroopNumber": "Troop 177",
        "lng": "-74.306297",
        "State": "New Jersey",
        "NameOrID": "Winters, Eugene J.",
        "City": "Cranford",
        "Year": "1978",
        "lat": "40.656004",
        "Document": "docs/eugene-j-winters.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "",
        "State": "",
        "NameOrID": "Winters, William H.",
        "City": "",
        "Year": "1975",
        "lat": "",
        "Document": " docs/william-h-winters.pdf"
    },
    {
        "TroopNumber": "Troop 601",
        "lng": "-81.045249",
        "State": "South Carolina",
        "NameOrID": "Willock, Lloyd L.",
        "City": "Columbia",
        "Year": "1988",
        "lat": "33.99855",
        "Document": "docs/lloyd-l-willock.pdf"
    },
    {
        "TroopNumber": "Troop 42",
        "lng": "-87.254049",
        "State": "Indiana",
        "NameOrID": "Wojtan, Thomas J.",
        "City": "Hobart",
        "Year": "1984",
        "lat": "41.530705",
        "Document": " docs/thomas-j-wojtan.pdf"
    },
    {
        "TroopNumber": "Troop 258",
        "lng": "-123.263534",
        "State": "Oregon",
        "NameOrID": "Wilson, Roy S.",
        "City": "Corvallis",
        "Year": "1986",
        "lat": "44.56504",
        "Document": "docs/roy-s-wilson.pdf"
    },
    {
        "TroopNumber": "Pack 77",
        "lng": "-120.434994",
        "State": "California",
        "NameOrID": "Wolfersteig, H. Richard",
        "City": "Santa Maria",
        "Year": "1991",
        "lat": "34.95313",
        "Document": "docs/h-richard-wolfersteig.pdf"
    },
    {
        "TroopNumber": "Troop 92",
        "lng": "-72.18826",
        "State": "Connecticut",
        "NameOrID": "Wilson, David P.",
        "City": "Ashford",
        "Year": "1988",
        "lat": "41.86286",
        "Document": "docs/david-p-wilson.pdf"
    },
    {
        "TroopNumber": "Troop 211",
        "lng": "-97.727109",
        "State": "Texas",
        "NameOrID": "Wolfe, Edwin D.",
        "City": "Killeen",
        "Year": "1988",
        "lat": "31.116835",
        "Document": "docs/edwin-d-wolfe.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Wilson, Royce L.",
        "City": "",
        "Year": "1986",
        "lat": "",
        "Document": "docs/royce-l-wilson.pdf"
    },
    {
        "TroopNumber": "Troop 44",
        "lng": "-122.314834",
        "State": "California",
        "NameOrID": "Wong, Robert",
        "City": "San Mateo",
        "Year": "1989",
        "lat": "37.54703",
        "Document": "docs/robert-wong.pdf"
    },
    {
        "TroopNumber": "District ",
        "lng": "-157.857614",
        "State": "Hawaii",
        "NameOrID": "Wilson, Dale Edmund",
        "City": "Honolulu",
        "Year": "1971",
        "lat": "21.30477",
        "Document": " docs/dale-edmund-wilson.pdf"
    },
    {
        "TroopNumber": "Troop 59",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "Wood, Thomas R.",
        "City": "San Diego",
        "Year": "1991",
        "lat": "32.715695",
        "Document": "docs/thomas-r-wood.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-118.446517",
        "State": "California",
        "NameOrID": "Wood, Richard Edward",
        "City": "Van Nuys",
        "Year": "1982",
        "lat": "34.18416",
        "Document": " docs/richard-edward-wood.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Wood, Robert F.",
        "City": "",
        "Year": "1983",
        "lat": "",
        "Document": "docs/robert-f-wood.pdf"
    },
    {
        "TroopNumber": "Troop 425",
        "lng": "-84.329081",
        "State": "Georgia",
        "NameOrID": "Youngblood, Russell D.",
        "City": "Blue Ridge",
        "Year": "1989",
        "lat": "34.858491",
        "Document": "docs/russell-d-youngblood.pdf"
    },
    {
        "TroopNumber": "Troop 137",
        "lng": "-90.077749",
        "State": "Louisiana",
        "NameOrID": "Woodall, Raymond",
        "City": "New Orleans",
        "Year": "1976",
        "lat": "29.9537",
        "Document": " docs/raymond-woodall.pdf"
    },
    {
        "TroopNumber": "Troop 875",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Zak, John",
        "City": "Chicago",
        "Year": "1977",
        "lat": "41.88415",
        "Document": " docs/john-zak.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "Maryland",
        "NameOrID": "Woodford, Michael Grant",
        "City": "",
        "Year": "1988",
        "lat": "",
        "Document": "docs/michael-grant-woodford.pdf"
    },
    {
        "TroopNumber": "Troop 16",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Zak, John",
        "City": "Chicago",
        "Year": "1977",
        "lat": "41.88415",
        "Document": " docs/john-zak.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-76.243524",
        "State": "Pennsylvania",
        "NameOrID": "Zeart, Dewey J.",
        "City": "Berwick",
        "Year": "1971",
        "lat": "41.050885",
        "Document": " docs/dewey-j-zeart.pdf"
    },
    {
        "TroopNumber": "Troop 61",
        "lng": "-105.504764",
        "State": "Wyoming",
        "NameOrID": "Worcester, Jerry L.",
        "City": "Gillette",
        "Year": "1988",
        "lat": "44.293185",
        "Document": "docs/jerry-l-worcester.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Zegel, John C.",
        "City": "",
        "Year": "1989",
        "lat": "",
        "Document": "docs/john-c-zegel.pdf"
    },
    {
        "TroopNumber": "Troop 45",
        "lng": "-83.806734",
        "State": "Ohio",
        "NameOrID": "Wray, Ronald Russell",
        "City": "Springfield",
        "Year": "1985",
        "lat": "39.92502",
        "Document": " docs/ronald-russell-wray.pdf"
    },
    {
        "TroopNumber": "Troop 458",
        "lng": "-82.122759",
        "State": "Ohio",
        "NameOrID": "Zemanick, Carl",
        "City": "Spencer",
        "Year": "1979",
        "lat": "41.100095",
        "Document": "docs/carl-zemanick.pdf"
    },
    {
        "TroopNumber": "Pack 146",
        "lng": "-89.847221",
        "State": "Wisconsin",
        "NameOrID": "Zenzen, Arthur E.",
        "City": "Avery",
        "Year": "1991",
        "lat": "44.78636",
        "Document": "docs/arthur-e-zenzen.pdf"
    },
    {
        "TroopNumber": "Troop 768",
        "lng": "",
        "State": "California",
        "NameOrID": "Wright, Donald L.",
        "City": "",
        "Year": "1990",
        "lat": "",
        "Document": "docs/donald-l-wright.pdf"
    },
    {
        "TroopNumber": "Explorer 1615",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Ziegler, Herbert A.",
        "City": "Houston",
        "Year": "1991",
        "lat": "29.76045",
        "Document": "docs/herbert-a-ziegler.pdf"
    },
    {
        "TroopNumber": "Troop 628",
        "lng": "-117.691249",
        "State": "California",
        "NameOrID": "Wright, Donald L.",
        "City": "El Toro",
        "Year": "1990",
        "lat": "33.62905",
        "Document": "docs/donald-l-wright.pdf"
    },
    {
        "TroopNumber": "Pack 302",
        "lng": "-83.113854",
        "State": "Ohio",
        "NameOrID": "Zimmerman, Mark S.",
        "City": "Fremont",
        "Year": "1986",
        "lat": "41.347405",
        "Document": "docs/mark-s-zimmerman.pdf"
    },
    {
        "TroopNumber": "Troop 35",
        "lng": "-92.586854",
        "State": "Arkansas",
        "NameOrID": "Wright, Clifford W.",
        "City": "Benton",
        "Year": "1987",
        "lat": "34.563985",
        "Document": "docs/clifford-w-wright.pdf"
    },
    {
        "TroopNumber": "Pack 92",
        "lng": "-75.563939",
        "State": "Delaware",
        "NameOrID": "Zimmerman, William D.",
        "City": "New Castle",
        "Year": "1987",
        "lat": "39.65977",
        "Document": "docs/william-d-zimmerman.pdf"
    },
    {
        "TroopNumber": "Venturing 6129",
        "lng": "-111.651304",
        "State": "Arizona",
        "NameOrID": "Wright, Harvey Dee",
        "City": "Flagstaff",
        "Year": "1988",
        "lat": "35.1982",
        "Document": "docs/harvey-dee-wright.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Zottoli, Joseph",
        "City": "",
        "Year": "1983",
        "lat": "",
        "Document": " docs/joseph-zottoli.pdf"
    },
    {
        "TroopNumber": "Troop 71",
        "lng": "-71.411994",
        "State": "Rhode Island",
        "NameOrID": "Yates, John A.",
        "City": "Providence",
        "Year": "1988",
        "lat": "41.823875",
        "Document": "docs/john-a-yates.pdf"
    },
    {
        "TroopNumber": "Pack 210",
        "lng": "-83.633084",
        "State": "Michigan",
        "NameOrID": "Yoder, Thomas M.",
        "City": "Burton",
        "Year": "1978",
        "lat": "42.96847",
        "Document": "docs/thomas-m-yoder.pdf"
    },
    {
        "TroopNumber": "Troop 280",
        "lng": "-100.898179",
        "State": "Texas",
        "NameOrID": "Young, John Philip",
        "City": "Del Rio",
        "Year": "1985",
        "lat": "29.366245",
        "Document": " docs/john-philip-young.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Young, Gene R.",
        "City": "",
        "Year": "1987",
        "lat": "",
        "Document": "docs/gene-r-young.pdf"
    },
    {
        "TroopNumber": "Troop 156",
        "lng": "-83.722489",
        "State": "Georgia",
        "NameOrID": "Hodges, Thomas Daniel",
        "City": "Winder",
        "Year": "1963",
        "lat": "33.99121",
        "Document": "docs/thomas-daniel-hodges.pdf"
    },
    {
        "TroopNumber": "Troop 64",
        "lng": "-102.375189",
        "State": "Texas",
        "NameOrID": "Young, Jimmy D.",
        "City": "Odessa",
        "Year": "1989",
        "lat": "31.84915",
        "Document": "docs/jimmy-d-young.pdf"
    },
    {
        "TroopNumber": "Troop 3156",
        "lng": "-83.722489",
        "State": "Georgia",
        "NameOrID": "Hodges, Thomas Daniel",
        "City": "Winder",
        "Year": "1963",
        "lat": "33.99121",
        "Document": "docs/thomas-daniel-hodges.pdf"
    },
    {
        "TroopNumber": "Pack 271",
        "lng": "-81.877284",
        "State": "Florida",
        "NameOrID": "Young, Anthony L.",
        "City": "Leesburg",
        "Year": "1989",
        "lat": "28.811105",
        "Document": "docs/anthony-l-young.pdf"
    },
    {
        "TroopNumber": "Troop 820",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Sheffel, Joel H.",
        "City": "Chicago",
        "Year": "1970",
        "lat": "41.88415",
        "Document": "docs/joel-h-sheffel.pdf"
    },
    {
        "TroopNumber": "Troop 3094",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Sheffel, Joel H.",
        "City": "Chicago",
        "Year": "1970",
        "lat": "41.88415",
        "Document": "docs/joel-h-sheffel.pdf"
    },
    {
        "TroopNumber": "Pack 3550",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Focaccio, Frank Robert",
        "City": "Philadelphia",
        "Year": "1966",
        "lat": "39.95228",
        "Document": "docs/frank-robert-focaccio.pdf"
    },
    {
        "TroopNumber": "Pack 2146",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Focaccio, Frank Robert",
        "City": "Philadelphia",
        "Year": "1966",
        "lat": "39.95228",
        "Document": "docs/frank-robert-focaccio.pdf"
    },
    {
        "TroopNumber": "Troop 471",
        "lng": "-123.431304",
        "State": "Washington",
        "NameOrID": "Leard, Raymond D.",
        "City": "Port Angeles",
        "Year": "1962",
        "lat": "48.118725",
        "Document": "docs/raymond-d-leard.pdf"
    },
    {
        "TroopNumber": "Troop 666",
        "lng": "-77.031959",
        "State": "District of Columbia",
        "NameOrID": "Hamblin, Bruce W.",
        "City": "Washington",
        "Year": "1969",
        "lat": "38.89037",
        "Document": "docs/bruce-w-hamblin.pdf"
    },
    {
        "TroopNumber": "Troop 686",
        "lng": "-123.431304",
        "State": "Washington",
        "NameOrID": "Leard, Raymond D.",
        "City": "Port Angeles",
        "Year": "1962",
        "lat": "48.118725",
        "Document": "docs/raymond-d-leard.pdf"
    },
    {
        "TroopNumber": "Troop 1185",
        "lng": "-71.513467",
        "State": "Rhode Island",
        "NameOrID": "Hamblin, Bruce W.",
        "City": "Woonsocket",
        "Year": "1969",
        "lat": "42.003008",
        "Document": "docs/bruce-w-hamblin.pdf"
    },
    {
        "TroopNumber": "Troop 444",
        "lng": "-76.609264",
        "State": "Maryland",
        "NameOrID": "Lawrence, T. Franklin",
        "City": "Baltimore",
        "Year": "1965",
        "lat": "39.290585",
        "Document": "docs/t-franklin-lawrence.pdf"
    },
    {
        "TroopNumber": "Troop 989",
        "lng": "-76.609264",
        "State": "Maryland",
        "NameOrID": "Lawrence, T. Franklin",
        "City": "Baltimore",
        "Year": "1965",
        "lat": "39.290585",
        "Document": "docs/t-franklin-lawrence.pdf"
    },
    {
        "TroopNumber": "Troop 643",
        "lng": "",
        "State": "",
        "NameOrID": "Walker Sr., Chalma Lee",
        "City": "",
        "Year": "1965",
        "lat": "",
        "Document": "docs/chalma-lee-walker.pdf"
    },
    {
        "TroopNumber": "Troop 420",
        "lng": "-76.635829",
        "State": "Maryland",
        "NameOrID": "White, Theodore W.",
        "City": "Leonardtown",
        "Year": "1970",
        "lat": "38.291805",
        "Document": "docs/theodore-w-white.pdf"
    },
    {
        "TroopNumber": "Troop 913",
        "lng": "-76.635829",
        "State": "Maryland",
        "NameOrID": "White, Theodore W.",
        "City": "Leonardtown",
        "Year": "1970",
        "lat": "38.291805",
        "Document": "docs/theodore-w-white.pdf"
    },
    {
        "TroopNumber": "Explorer 604",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Albrecht, Gene",
        "City": "Houston",
        "Year": "1961",
        "lat": "29.76045",
        "Document": "docs/gene-albrecht.pdf"
    },
    {
        "TroopNumber": "Troop 856",
        "lng": "-77.191655",
        "State": "Virginia",
        "NameOrID": "McCalment, Charles S.",
        "City": "Springfield",
        "Year": "1964",
        "lat": "38.779044",
        "Document": "docs/charles-s-mccalment.pdf"
    },
    {
        "TroopNumber": "Troop 859",
        "lng": "-77.191655",
        "State": "Virginia",
        "NameOrID": "McCalment, Charles S.",
        "City": "Springfield",
        "Year": "1964",
        "lat": "38.779044",
        "Document": "docs/charles-s-mccalment.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "-79.502501",
        "State": "Pennsylvania",
        "NameOrID": "Culbertson, Raymond W.",
        "City": "Rimersberg",
        "Year": "1961",
        "lat": "41.042216",
        "Document": "docs/raymond-w-culbertson-jr.pdf"
    },
    {
        "TroopNumber": "Troop 547",
        "lng": "-78.824734",
        "State": "New York",
        "NameOrID": "Culbertson, Raymond W.",
        "City": "Lackawanna",
        "Year": "1961",
        "lat": "42.8258",
        "Document": "docs/raymond-w-culbertson-jr.pdf"
    },
    {
        "TroopNumber": "Troop 29",
        "lng": "-79.150149",
        "State": "Pennsylvania",
        "NameOrID": "Garris, Charles Wayne",
        "City": "Indiana",
        "Year": "1964",
        "lat": "40.622905",
        "Document": "docs/charles-wayne-garris.pdf"
    },
    {
        "TroopNumber": "Troop 518",
        "lng": "-79.150149",
        "State": "Pennsylvania",
        "NameOrID": "Garris, Charles Wayne",
        "City": "Indiana",
        "Year": "1964",
        "lat": "40.622905",
        "Document": "docs/charles-wayne-garris.pdf"
    },
    {
        "TroopNumber": "Troop 402",
        "lng": "-71.108759",
        "State": "Massachusetts",
        "NameOrID": "Reynolds, Charles E.",
        "City": "Medford",
        "Year": "1967",
        "lat": "42.419815",
        "Document": "docs/charles-e-reynolds-jr.pdf"
    },
    {
        "TroopNumber": "Troop 503",
        "lng": "-122.627469",
        "State": "Washington",
        "NameOrID": "Beck, Bruce William",
        "City": "Bremerton",
        "Year": "1961",
        "lat": "47.565005",
        "Document": "docs/bruce-william-beck.pdf"
    },
    {
        "TroopNumber": "Explorer 336",
        "lng": "-95.294299",
        "State": "Texas",
        "NameOrID": "Thurman, Ray E.",
        "City": "Tyler",
        "Year": "1963",
        "lat": "32.347475",
        "Document": "docs/ray-e-thurman.pdf"
    },
    {
        "TroopNumber": "Explorer 125",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Davis, D. Leroy",
        "City": "St. Louis",
        "Year": "1963",
        "lat": "38.62774",
        "Document": "docs/d-leroy-davis.pdf"
    },
    {
        "TroopNumber": "Pack 385",
        "lng": "-95.487968",
        "State": "Texas",
        "NameOrID": "Thurman, Ray E.",
        "City": "Mineola",
        "Year": "1963",
        "lat": "32.6638",
        "Document": "docs/ray-e-thurman.pdf"
    },
    {
        "TroopNumber": "Troop 294",
        "lng": "-77.028874",
        "State": "Maryland",
        "NameOrID": "Thompson, Charles Philip",
        "City": "Silver Spring",
        "Year": "1962",
        "lat": "38.99668",
        "Document": "docs/charles-philip-thompson.pdf"
    },
    {
        "TroopNumber": "Troop 466",
        "lng": "-77.028874",
        "State": "Maryland",
        "NameOrID": "Thompson, Charles Philip",
        "City": "Silver Spring",
        "Year": "1962",
        "lat": "38.99668",
        "Document": "docs/charles-philip-thompson.pdf"
    },
    {
        "TroopNumber": "Troop 366",
        "lng": "-95.885604",
        "State": "Nebraska",
        "NameOrID": "Linder, Alva D.",
        "City": "Plattsmouth",
        "Year": "1962",
        "lat": "41.011175",
        "Document": "docs/alva-d-linder.pdf"
    },
    {
        "TroopNumber": "Troop 495",
        "lng": "-122.025196",
        "State": "California",
        "NameOrID": "Schlieper, Ronald D.",
        "City": "Concord",
        "Year": "1969",
        "lat": "37.980816",
        "Document": "docs/ronald-d-schlieper.pdf"
    },
    {
        "TroopNumber": "Troop 369",
        "lng": "-95.885604",
        "State": "Nebraska",
        "NameOrID": "Linder, Alva D.",
        "City": "Plattsmouth",
        "Year": "1962",
        "lat": "41.011175",
        "Document": "docs/alva-d-linder.pdf"
    },
    {
        "TroopNumber": "Troop 465",
        "lng": "-122.025196",
        "State": "California",
        "NameOrID": "Schlieper, Ronald D.",
        "City": "Concord",
        "Year": "1969",
        "lat": "37.980816",
        "Document": "docs/ronald-d-schlieper.pdf"
    },
    {
        "TroopNumber": "Troop 37",
        "lng": "-74.278519",
        "State": "New Jersey",
        "NameOrID": "Koncz, Tibor J.",
        "City": "Woodbridge",
        "Year": "1968",
        "lat": "40.55452",
        "Document": "docs/tibor-j-koncz.pdf"
    },
    {
        "TroopNumber": "Troop 224",
        "lng": "8.242302",
        "State": "Germany",
        "NameOrID": "Wood, Larry W.",
        "City": "Wiesbaden",
        "Year": "1968",
        "lat": "50.084592",
        "Document": "docs/larry-w-wood.pdf"
    },
    {
        "TroopNumber": "Explorer 356",
        "lng": "-74.265741",
        "State": "New Jersey",
        "NameOrID": "Koncz, Tibor J.",
        "City": "Perth Amboy",
        "Year": "1968",
        "lat": "40.50642",
        "Document": "docs/tibor-j-koncz.pdf"
    },
    {
        "TroopNumber": "Troop 459",
        "lng": "8.242302",
        "State": "Germany",
        "NameOrID": "Wood, Larry W.",
        "City": "Wiesbaden",
        "Year": "1968",
        "lat": "50.084592",
        "Document": "docs/larry-w-wood.pdf"
    },
    {
        "TroopNumber": "Troop 257",
        "lng": "-78.957836",
        "State": "New York",
        "NameOrID": "Martin, Richard Earl",
        "City": "Grand Island",
        "Year": "1967",
        "lat": "43.012245",
        "Document": "docs/richard-earl-martin.pdf"
    },
    {
        "TroopNumber": "Troop 261",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Brinkman, Charles",
        "City": "Indianapolis",
        "Year": "1964",
        "lat": "39.76691",
        "Document": "docs/charles-brinkman.pdf"
    },
    {
        "TroopNumber": "Troop 335",
        "lng": "-78.878464",
        "State": "New York",
        "NameOrID": "Martin, Richard Earl",
        "City": "Buffalo",
        "Year": "1967",
        "lat": "42.88544",
        "Document": "docs/richard-earl-martin.pdf"
    },
    {
        "TroopNumber": "Troop 426",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Brinkman, Charles",
        "City": "Indianapolis",
        "Year": "1964",
        "lat": "39.76691",
        "Document": "docs/charles-brinkman.pdf"
    },
    {
        "TroopNumber": "Troop 644",
        "lng": "-117.596844",
        "State": "California",
        "NameOrID": "Smith, Bruce C.",
        "City": "Alta Loma",
        "Year": "1968",
        "lat": "34.1327",
        "Document": "docs/bruce-c-smith.pdf"
    },
    {
        "TroopNumber": "Troop 419",
        "lng": "-101.296074",
        "State": "North Dakota",
        "NameOrID": "Fried, Rienhold John",
        "City": "Minot",
        "Year": "1960",
        "lat": "48.236115",
        "Document": "docs/rienhold-john-fried.pdf"
    },
    {
        "TroopNumber": "Troop 325",
        "lng": "-117.648099",
        "State": "California",
        "NameOrID": "Smith, Bruce C.",
        "City": "Ontario",
        "Year": "1968",
        "lat": "34.064575",
        "Document": "docs/bruce-c-smith.pdf"
    },
    {
        "TroopNumber": "Pack 419",
        "lng": "-101.296074",
        "State": "North Dakota",
        "NameOrID": "Fried, Rienhold John",
        "City": "Minot",
        "Year": "1960",
        "lat": "48.236115",
        "Document": "docs/rienhold-john-fried.pdf"
    },
    {
        "TroopNumber": "Troop 401",
        "lng": "-71.108759",
        "State": "Massachusetts",
        "NameOrID": "Reynolds, Charles E.",
        "City": "Medford",
        "Year": "1967",
        "lat": "42.419815",
        "Document": "docs/charles-e-reynolds-jr.pdf"
    },
    {
        "TroopNumber": "Explorer 2184",
        "lng": "-81.483727",
        "State": "Ohio",
        "NameOrID": "Daves, Donald Bert",
        "City": "Cuyahoga Falls",
        "Year": "1967",
        "lat": "41.138411",
        "Document": "docs/donald-bert-daves.pdf"
    },
    {
        "TroopNumber": "Pack 317",
        "lng": "-81.514059",
        "State": "Ohio",
        "NameOrID": "Daves, Donald Bert",
        "City": "Akron",
        "Year": "1967",
        "lat": "41.084195",
        "Document": "docs/donald-bert-daves.pdf"
    },
    {
        "TroopNumber": "Pack 304",
        "lng": "-79.997459",
        "State": "Pennsylvania",
        "NameOrID": "Choate, Craig Dexter",
        "City": "Pittsburgh",
        "Year": "1963",
        "lat": "40.438335",
        "Document": "docs/craig-dexter-choate.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-88.309329",
        "State": "Illinois",
        "NameOrID": "Gebes, Ralph Edwin",
        "City": "Batavia",
        "Year": "1967",
        "lat": "41.849995",
        "Document": "docs/ralph-edwin-gebes.pdf"
    },
    {
        "TroopNumber": "Troop 90",
        "lng": "-82.92902",
        "State": "Florida",
        "NameOrID": "Edwards, Robert P.",
        "City": "Branford",
        "Year": "1961",
        "lat": "29.958635",
        "Document": "docs/robert-p-edwards.pdf"
    },
    {
        "TroopNumber": "Explorer 312",
        "lng": "-88.309329",
        "State": "Illinois",
        "NameOrID": "Gebes, Ralph Edwin",
        "City": "Batavia",
        "Year": "1967",
        "lat": "41.849995",
        "Document": "docs/ralph-edwin-gebes.pdf"
    },
    {
        "TroopNumber": "Troop 269",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Edwards, Robert P.",
        "City": "Jacksonville",
        "Year": "1961",
        "lat": "30.33147",
        "Document": "docs/robert-p-edwards.pdf"
    },
    {
        "TroopNumber": "Troop 304",
        "lng": "-79.997459",
        "State": "Pennsylvania",
        "NameOrID": "Choate, Craig Dexter",
        "City": "Pittsburgh",
        "Year": "1963",
        "lat": "40.438335",
        "Document": "docs/craig-dexter-choate.pdf"
    },
    {
        "TroopNumber": "Troop 281",
        "lng": "-75.339304",
        "State": "Pennsylvania",
        "NameOrID": "Wertman, Donald",
        "City": "Norristown",
        "Year": "1963",
        "lat": "40.114455",
        "Document": "docs/donald-wertman.pdf"
    },
    {
        "TroopNumber": "Troop 250",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Schneider, Leland Ward",
        "City": "Houston",
        "Year": "1969",
        "lat": "29.76045",
        "Document": "docs/leland-ward-schneider.pdf"
    },
    {
        "TroopNumber": "Troop 268",
        "lng": "-75.339304",
        "State": "Pennsylvania",
        "NameOrID": "Wertman, Donald",
        "City": "Norristown",
        "Year": "1963",
        "lat": "40.114455",
        "Document": "docs/donald-wertman.pdf"
    },
    {
        "TroopNumber": "Troop 483",
        "lng": "-122.038258",
        "State": "California",
        "NameOrID": "Smith, Edward Troy",
        "City": "Sunnyvale",
        "Year": "1961",
        "lat": "37.371612",
        "Document": "docs/edward-troy-smith.pdf"
    },
    {
        "TroopNumber": "Troop 233",
        "lng": "-121.885794",
        "State": "California",
        "NameOrID": "Smith, Edward Troy",
        "City": "San Jose",
        "Year": "1961",
        "lat": "37.338475",
        "Document": "docs/edward-troy-smith.pdf"
    },
    {
        "TroopNumber": "Troop 37",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "Fitch, Robert D.",
        "City": "Syracuse",
        "Year": "1967",
        "lat": "43.04999",
        "Document": "docs/robert-d-fitch.pdf"
    },
    {
        "TroopNumber": "Troop 102",
        "lng": "-84.504552",
        "State": "Ohio",
        "NameOrID": "Ballard, Joseph C.",
        "City": "Cincinnati",
        "Year": "1968",
        "lat": "39.106614",
        "Document": "docs/joseph-c-ballard.pdf"
    },
    {
        "TroopNumber": "Explorer 264",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "Fitch, Robert D.",
        "City": "Syracuse",
        "Year": "1967",
        "lat": "43.04999",
        "Document": "docs/robert-d-fitch.pdf"
    },
    {
        "TroopNumber": "Troop 213",
        "lng": "-84.455628",
        "State": "Ohio",
        "NameOrID": "Ballard, Joseph C.",
        "City": "Norwood ",
        "Year": "1968",
        "lat": "39.161663",
        "Document": "docs/joseph-c-ballard.pdf"
    },
    {
        "TroopNumber": "Troop 96",
        "lng": "-110.969754",
        "State": "Arizona",
        "NameOrID": "Webb, Eric Bruce",
        "City": "Tucson",
        "Year": "1963",
        "lat": "32.221553",
        "Document": "docs/eric-bruce-webb.pdf"
    },
    {
        "TroopNumber": "Troop 213",
        "lng": "-110.969754",
        "State": "Arizona",
        "NameOrID": "Webb, Eric Bruce",
        "City": "Tucson",
        "Year": "1963",
        "lat": "32.221553",
        "Document": "docs/eric-bruce-webb.pdf"
    },
    {
        "TroopNumber": "Troop 223",
        "lng": "-94.741075",
        "State": "Texas",
        "NameOrID": "Rowden, Gary Gene",
        "City": "Longview",
        "Year": "1965",
        "lat": "32.50064",
        "Document": "docs/gary-gene-rowden.pdf"
    },
    {
        "TroopNumber": "Troop 258",
        "lng": "-94.741075",
        "State": "Texas",
        "NameOrID": "Rowden, Gary Gene",
        "City": "Longview",
        "Year": "1965",
        "lat": "32.50064",
        "Document": "docs/gary-gene-rowden.pdf"
    },
    {
        "TroopNumber": "Troop 211",
        "lng": "-91.526534",
        "State": "Iowa",
        "NameOrID": "Jarvill, Larry",
        "City": "Iowa City",
        "Year": "1967",
        "lat": "41.657825",
        "Document": "docs/larry-jarvill.pdf"
    },
    {
        "TroopNumber": "Troop 259",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Breyette, Edwin E.",
        "City": "Rochester",
        "Year": "1969",
        "lat": "43.1555",
        "Document": "docs/edwin-e-breyette-sr.pdf"
    },
    {
        "TroopNumber": "Troop 207",
        "lng": "-91.586488",
        "State": "Iowa",
        "NameOrID": "Jarvill, Larry",
        "City": "Coralville",
        "Year": "1967",
        "lat": "41.676952",
        "Document": "docs/larry-jarvill.pdf"
    },
    {
        "TroopNumber": "Troop 219",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Schneider, Leland Ward",
        "City": "Houston",
        "Year": "1969",
        "lat": "29.76045",
        "Document": "docs/leland-ward-schneider.pdf"
    },
    {
        "TroopNumber": "Troop 208",
        "lng": "-71.056699",
        "State": "Massachusetts",
        "NameOrID": "Lydon, Robert",
        "City": "Boston",
        "Year": "1970",
        "lat": "42.358635",
        "Document": "docs/robert-lydon.pdf"
    },
    {
        "TroopNumber": "Pack 187",
        "lng": "-71.056699",
        "State": "Massachusetts",
        "NameOrID": "Lydon, Robert",
        "City": "Boston",
        "Year": "1970",
        "lat": "42.358635",
        "Document": "docs/robert-lydon.pdf"
    },
    {
        "TroopNumber": "Troop 111",
        "lng": "-97.345134",
        "State": "Kansas",
        "NameOrID": "Hillard, Robert L.",
        "City": "Newton",
        "Year": "1961",
        "lat": "38.05286",
        "Document": "docs/robert-l-hillard.pdf"
    },
    {
        "TroopNumber": "Troop 586",
        "lng": "-118.144779",
        "State": "California",
        "NameOrID": "Smith, Carl D.",
        "City": "Lancaster",
        "Year": "1961",
        "lat": "34.698905",
        "Document": "docs/carl-d-smith.pdf"
    },
    {
        "TroopNumber": "Troop 150",
        "lng": "-97.345134",
        "State": "Kansas",
        "NameOrID": "Hillard, Robert L.",
        "City": "Newton",
        "Year": "1961",
        "lat": "38.05286",
        "Document": "docs/robert-l-hillard.pdf"
    },
    {
        "TroopNumber": "Troop 186",
        "lng": "-118.144779",
        "State": "California",
        "NameOrID": "Smith, Carl D.",
        "City": "Lancaster",
        "Year": "1961",
        "lat": "34.698905",
        "Document": "docs/carl-d-smith.pdf"
    },
    {
        "TroopNumber": "Troop 389",
        "lng": "-73.829959",
        "State": "New York",
        "NameOrID": "Pederson, Hans",
        "City": "Queens",
        "Year": "1965",
        "lat": "40.713985",
        "Document": "docs/hans-pederson.pdf"
    },
    {
        "TroopNumber": "Troop 24",
        "lng": "-75.911271",
        "State": "New York",
        "NameOrID": "Carson, Robert J.",
        "City": "Binghamton",
        "Year": "1963",
        "lat": "42.098687",
        "Document": "docs/robert-j-carson.pdf"
    },
    {
        "TroopNumber": "Troop 143",
        "lng": "-74.007124",
        "State": "New York",
        "NameOrID": "Pederson, Hans",
        "City": "New York",
        "Year": "1965",
        "lat": "40.71455",
        "Document": "docs/hans-pederson.pdf"
    },
    {
        "TroopNumber": "Troop 166",
        "lng": "-75.911271",
        "State": "New York",
        "NameOrID": "Carson, Robert J.",
        "City": "Binghamton",
        "Year": "1963",
        "lat": "42.098687",
        "Document": "docs/robert-j-carson.pdf"
    },
    {
        "TroopNumber": "Troop 103",
        "lng": "-73.829959",
        "State": "New York",
        "NameOrID": "Diva, Cal",
        "City": "Queens",
        "Year": "1960",
        "lat": "40.713985",
        "Document": "docs/cal-diva.pdf"
    },
    {
        "TroopNumber": "Troop 135",
        "lng": "-73.829959",
        "State": "New York",
        "NameOrID": "Diva, Cal",
        "City": "Queens",
        "Year": "1960",
        "lat": "40.713985",
        "Document": "docs/cal-diva.pdf"
    },
    {
        "TroopNumber": "Troop 71",
        "lng": "-73.218264",
        "State": "New York",
        "NameOrID": "Bradford, Thomas Wayne",
        "City": "Islip",
        "Year": "1969",
        "lat": "40.72811",
        "Document": "docs/thomas-wayne-bradford.pdf"
    },
    {
        "TroopNumber": "Troop 134",
        "lng": "-71.346174",
        "State": "Massachusetts",
        "NameOrID": "Nathan, Paul Marshall",
        "City": "Natick",
        "Year": "1962",
        "lat": "42.28399",
        "Document": "docs/paul-marshall-nathan.pdf"
    },
    {
        "TroopNumber": "Troop 124",
        "lng": "127.132877",
        "State": "Japan",
        "NameOrID": "Bradford, Thomas Wayne",
        "City": "Okinawa",
        "Year": "1969",
        "lat": "25.965785",
        "Document": "docs/thomas-wayne-bradford.pdf"
    },
    {
        "TroopNumber": "Troop 123",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Miller, Howard Frank",
        "City": "Chicago",
        "Year": "1963",
        "lat": "41.88415",
        "Document": "docs/howard-frank-miller.pdf"
    },
    {
        "TroopNumber": "Troop 68",
        "lng": "11.02215",
        "State": "Germany",
        "NameOrID": "Putnam, Thomas E.",
        "City": "Schwaback",
        "Year": "1969",
        "lat": "49.32872",
        "Document": "docs/thomas-e-putnam.pdf"
    },
    {
        "TroopNumber": "Troop 131",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Miller, Howard Frank",
        "City": "Chicago",
        "Year": "1963",
        "lat": "41.88415",
        "Document": "docs/howard-frank-miller.pdf"
    },
    {
        "TroopNumber": "Troop 122",
        "lng": "10.454145",
        "State": "Germany",
        "NameOrID": "Putnam, Thomas E.",
        "City": "Zirndorft",
        "Year": "1969",
        "lat": "51.164175",
        "Document": "docs/thomas-e-putnam.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-85.444799",
        "State": "Indiana",
        "NameOrID": "Myers, Elmer Earl",
        "City": "Rushville",
        "Year": "1968",
        "lat": "39.60876",
        "Document": "docs/elmer-earl-myers.pdf"
    },
    {
        "TroopNumber": "Troop 127",
        "lng": "-85.444799",
        "State": "Indiana",
        "NameOrID": "Myers, Elmer Earl",
        "City": "Rushville",
        "Year": "1968",
        "lat": "39.60876",
        "Document": "docs/elmer-earl-myers.pdf"
    },
    {
        "TroopNumber": "Troop 212",
        "lng": "-90.364659",
        "State": "Illinois",
        "NameOrID": "Fox, Alan L.",
        "City": "Galesburg",
        "Year": "1968",
        "lat": "40.946915",
        "Document": "docs/alan-l-fox.pdf"
    },
    {
        "TroopNumber": "Explorer 95",
        "lng": "-93.741149",
        "State": "Missouri",
        "NameOrID": "Fenton, David C.",
        "City": "Warrensburg",
        "Year": "1968",
        "lat": "38.771224",
        "Document": "docs/david-c-fenton.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-92.342155",
        "State": "Iowa",
        "NameOrID": "Zahn, Frank Roy",
        "City": "Waterloo",
        "Year": "1967",
        "lat": "42.493104",
        "Document": "docs/frank-roy-zahn.pdf"
    },
    {
        "TroopNumber": "Troop 105",
        "lng": "-92.342155",
        "State": "Iowa",
        "NameOrID": "Zahn, Frank Roy",
        "City": "Waterloo",
        "Year": "1967",
        "lat": "42.493104",
        "Document": "docs/frank-roy-zahn.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-87.564125",
        "State": "Indiana",
        "NameOrID": "Estes, Alonzo E.",
        "City": "Evansville",
        "Year": "1961",
        "lat": "37.976911",
        "Document": "docs/alonzo-e-estes.pdf"
    },
    {
        "TroopNumber": "Troop 107",
        "lng": "-157.857614",
        "State": "Hawaii",
        "NameOrID": "Green, William L.",
        "City": "Honolulu",
        "Year": "1966",
        "lat": "21.30477",
        "Document": "docs/william-l-green.pdf"
    },
    {
        "TroopNumber": "Troop 100",
        "lng": "-87.564125",
        "State": "Indiana",
        "NameOrID": "Estes, Alonzo E.",
        "City": "Evansville",
        "Year": "1961",
        "lat": "37.976911",
        "Document": "docs/alonzo-e-estes.pdf"
    },
    {
        "TroopNumber": "Pack 89",
        "lng": "-122.061628",
        "State": "California",
        "NameOrID": "Green, William L.",
        "City": "Walnut Creek",
        "Year": "1966",
        "lat": "37.90118",
        "Document": "docs/william-l-green.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-79.458504",
        "State": "Virginia",
        "NameOrID": "Haynesworth, Josiah Edgar",
        "City": "Lynberg",
        "Year": "1966",
        "lat": "38.003351",
        "Document": "docs/josiah-edgar-haynesworth-iii.pdf"
    },
    {
        "TroopNumber": "Troop 84",
        "lng": "-79.034185",
        "State": "Virginia",
        "NameOrID": "Haynesworth, Josiah Edgar",
        "City": "Stuarts Draft",
        "Year": "1966",
        "lat": "38.030655",
        "Document": "docs/josiah-edgar-haynesworth-iii.pdf"
    },
    {
        "TroopNumber": "Troop 98",
        "lng": "-95.940469",
        "State": "Nebraska",
        "NameOrID": "Griffin, Raymond G.",
        "City": "Omaha",
        "Year": "1960",
        "lat": "41.260675",
        "Document": "docs/raymond-g-griffin.pdf"
    },
    {
        "TroopNumber": "Troop 95",
        "lng": "-92.782364",
        "State": "Missouri",
        "NameOrID": "Fenton, David C.",
        "City": "Tipton",
        "Year": "1968",
        "lat": "38.655555",
        "Document": "docs/david-c-fenton.pdf"
    },
    {
        "TroopNumber": "Troop 324",
        "lng": "-104.992259",
        "State": "Colorado",
        "NameOrID": "Mosher, David Eugene",
        "City": "Denver",
        "Year": "1967",
        "lat": "39.74001",
        "Document": "docs/david-eugene-mosher.pdf"
    },
    {
        "TroopNumber": "Troop 79",
        "lng": "-93.317499",
        "State": "Colorado",
        "NameOrID": "Mosher, David Eugene",
        "City": "Layfayette",
        "Year": "1967",
        "lat": "42.022525",
        "Document": "docs/david-eugene-mosher.pdf"
    },
    {
        "TroopNumber": "Troop 69",
        "lng": "-82.228687",
        "State": "Ohio",
        "NameOrID": "Harris, Bobby L.",
        "City": "Nelsonville",
        "Year": "1969",
        "lat": "39.457511",
        "Document": "docs/bobby-l-harris.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-93.747274",
        "State": "Louisiana",
        "NameOrID": "Baton, Jay A.",
        "City": "Shreveport",
        "Year": "1968",
        "lat": "32.514615",
        "Document": "docs/jay-a-baton.pdf"
    },
    {
        "TroopNumber": "Troop 22",
        "lng": "-90.210768",
        "State": "Illinois",
        "NameOrID": "Gaither, Robert Carroll",
        "City": "Greenfield",
        "Year": "1964",
        "lat": "39.344385",
        "Document": "docs/robert-carroll-gaither.pdf"
    },
    {
        "TroopNumber": "Troop 74",
        "lng": "-86.126244",
        "State": "Indiana",
        "NameOrID": "Baton, Jay A.",
        "City": "Kokomo",
        "Year": "1968",
        "lat": "40.48651",
        "Document": "docs/jay-a-baton.pdf"
    },
    {
        "TroopNumber": "Troop 64",
        "lng": "-82.228687",
        "State": "Ohio",
        "NameOrID": "Harris, Bobby L.",
        "City": "Nelsonville",
        "Year": "1969",
        "lat": "39.457511",
        "Document": "docs/bobby-l-harris.pdf"
    },
    {
        "TroopNumber": "Troop 107",
        "lng": "-118.492289",
        "State": "California",
        "NameOrID": "Chrisman, Thomas Lorenz",
        "City": "Santa Monica",
        "Year": "1967",
        "lat": "34.011565",
        "Document": "docs/thomas-lorenz-chrisman.pdf"
    },
    {
        "TroopNumber": "Troop 67",
        "lng": "-118.492289",
        "State": "California",
        "NameOrID": "Chrisman, Thomas Lorenz",
        "City": "Santa Monica",
        "Year": "1967",
        "lat": "34.011565",
        "Document": "docs/thomas-lorenz-chrisman.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-118.400344",
        "State": "California",
        "NameOrID": "Chrisman, Thomas Lorenz",
        "City": "Beverly Hills",
        "Year": "1967",
        "lat": "34.073485",
        "Document": "docs/thomas-lorenz-chrisman.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-121.000575",
        "State": "California",
        "NameOrID": "Chrisman, Thomas Lorenz",
        "City": "Westwood",
        "Year": "1967",
        "lat": "40.303175",
        "Document": "docs/thomas-lorenz-chrisman.pdf"
    },
    {
        "TroopNumber": "Troop 28",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "Zabek, Robert",
        "City": "Worcester",
        "Year": "1968",
        "lat": "42.263415",
        "Document": "docs/robert-zabek.pdf"
    },
    {
        "TroopNumber": "Troop 4",
        "lng": "-71.387204",
        "State": "Rhode Island",
        "NameOrID": "Beargeon, Andrew F.",
        "City": "Central Falls",
        "Year": "1968",
        "lat": "41.8874",
        "Document": "docs/andrew-f-beargeon.pdf"
    },
    {
        "TroopNumber": "Troop 60",
        "lng": "-73.482424",
        "State": "Connecticut",
        "NameOrID": "Curtis, Robert",
        "City": "Darien",
        "Year": "1966",
        "lat": "41.065365",
        "Document": "docs/robert-curtis.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-71.382481",
        "State": "Rhode Island",
        "NameOrID": "Beargeon, Andrew F.",
        "City": "Pawtucket",
        "Year": "1968",
        "lat": "41.879223",
        "Document": "docs/andrew-f-beargeon.pdf"
    },
    {
        "TroopNumber": "Pack 52",
        "lng": "-73.482424",
        "State": "Connecticut",
        "NameOrID": "Curtis, Robert",
        "City": "Darien",
        "Year": "1966",
        "lat": "41.065365",
        "Document": "docs/robert-curtis.pdf"
    },
    {
        "TroopNumber": "Troop 95",
        "lng": "8.683415",
        "State": "Germany",
        "NameOrID": "Clark, Kenneth Leon",
        "City": "Frankfurt",
        "Year": "1962",
        "lat": "50.11208",
        "Document": "docs/kenneth-leon-clark.pdf"
    },
    {
        "TroopNumber": "Explorer 51",
        "lng": "8.683415",
        "State": "Germany",
        "NameOrID": "Clark, Kenneth Leon",
        "City": "Frankfurt",
        "Year": "1962",
        "lat": "50.11208",
        "Document": "docs/kenneth-leon-clark.pdf"
    },
    {
        "TroopNumber": "Troop 287",
        "lng": "-106.648639",
        "State": "New Mexico",
        "NameOrID": "Barrett, Gordon R.",
        "City": "Albuquerque",
        "Year": "1966",
        "lat": "35.08418",
        "Document": "docs/gordon-r-barrett-jr.pdf"
    },
    {
        "TroopNumber": "Troop 61",
        "lng": "-77.44223",
        "State": "Pennsylvania",
        "NameOrID": "Barrett, Gordon R.",
        "City": "Lock Haven",
        "Year": "1966",
        "lat": "41.13765",
        "Document": "docs/gordon-r-barrett-jr.pdf"
    },
    {
        "TroopNumber": "Troop 52",
        "lng": "-93.232499",
        "State": "Louisiana",
        "NameOrID": "Burch, Barney",
        "City": "Longville",
        "Year": "1966",
        "lat": "30.606879",
        "Document": "docs/barney-burch.pdf"
    },
    {
        "TroopNumber": "Pack 61",
        "lng": "-72.096469",
        "State": "Connecticut",
        "NameOrID": "Follett, David",
        "City": "New London",
        "Year": "1975",
        "lat": "41.356445",
        "Document": "docs/david-follett.pdf"
    },
    {
        "TroopNumber": "Explorer 44",
        "lng": "-89.798839",
        "State": "Illinois",
        "NameOrID": "Burch, Barney",
        "City": "Moscoutah",
        "Year": "1966",
        "lat": "38.490285",
        "Document": "docs/barney-burch.pdf"
    },
    {
        "TroopNumber": "Troop 61",
        "lng": "-72.096469",
        "State": "Connecticut",
        "NameOrID": "Follett, David",
        "City": "New London",
        "Year": "1975",
        "lat": "41.356445",
        "Document": "docs/david-follett.pdf"
    },
    {
        "TroopNumber": "Explorer 136",
        "lng": "-82.105839",
        "State": "Ohio",
        "NameOrID": "Glick, Joseph D.",
        "City": "Elyria",
        "Year": "1962",
        "lat": "41.36768",
        "Document": "docs/joseph-d-glick.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-83.002984",
        "State": "Ohio",
        "NameOrID": "Glick, Joseph D.",
        "City": "Columbus",
        "Year": "1962",
        "lat": "39.96196",
        "Document": "docs/joseph-d-glick.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-106.487494",
        "State": "Texas",
        "NameOrID": "West, James Francis",
        "City": "El Paso",
        "Year": "1968",
        "lat": "31.759165",
        "Document": "docs/james-francis-west.pdf"
    },
    {
        "TroopNumber": "Troop 27",
        "lng": "-106.487494",
        "State": "Texas",
        "NameOrID": "West, James Francis",
        "City": "El Paso",
        "Year": "1968",
        "lat": "31.759165",
        "Document": "docs/james-francis-west.pdf"
    },
    {
        "TroopNumber": "Pack 16",
        "lng": "-111.70068",
        "State": "Montana",
        "NameOrID": "Pound, Harold",
        "City": "Cascade",
        "Year": "1963",
        "lat": "47.271506",
        "Document": "docs/harold-pound.pdf"
    },
    {
        "TroopNumber": "Troop 16",
        "lng": "-111.70068",
        "State": "Montana",
        "NameOrID": "Pound, Harold",
        "City": "Cascade",
        "Year": "1963",
        "lat": "47.271506",
        "Document": "docs/harold-pound.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-95.993334",
        "State": "Oklahoma",
        "NameOrID": "Rhodes, Joel Danzler",
        "City": "Tulsa",
        "Year": "1963",
        "lat": "36.149745",
        "Document": "docs/joel-danzler-rhodes.pdf"
    },
    {
        "TroopNumber": "Troop 16",
        "lng": "-84.993939",
        "State": "Indiana",
        "NameOrID": "Rhodes, Joel Danzler",
        "City": "Centerville",
        "Year": "1963",
        "lat": "39.817745",
        "Document": "docs/joel-danzler-rhodes.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-87.713749",
        "State": "Wisconsin",
        "NameOrID": "Forsterling, Benjamin C.",
        "City": "Sheboygan",
        "Year": "1961",
        "lat": "43.74978",
        "Document": "docs/benjamin-c-forsterling.pdf"
    },
    {
        "TroopNumber": "Troop 76",
        "lng": "-74.89255",
        "State": "New Jersey",
        "NameOrID": "Dyson, David S.",
        "City": "Mt. Laurel",
        "Year": "1966",
        "lat": "39.935287",
        "Document": "docs/david-s-dyson.pdf"
    },
    {
        "TroopNumber": "Troop 24",
        "lng": "-87.713749",
        "State": "Wisconsin",
        "NameOrID": "Forsterling, Benjamin C.",
        "City": "Sheboygan",
        "Year": "1961",
        "lat": "43.74978",
        "Document": "docs/benjamin-c-forsterling.pdf"
    },
    {
        "TroopNumber": "Troop 15",
        "lng": "-74.857514",
        "State": "New Jersey",
        "NameOrID": "Dyson, David S.",
        "City": "Rancocas Woods",
        "Year": "1966",
        "lat": "39.987585",
        "Document": "docs/david-s-dyson.pdf"
    },
    {
        "TroopNumber": "Troop 15",
        "lng": "-106.487494",
        "State": "Texas",
        "NameOrID": "Chavez, Joaquin",
        "City": "El Paso",
        "Year": "1964",
        "lat": "31.759165",
        "Document": "docs/joaquin-chavez.pdf"
    },
    {
        "TroopNumber": "Troop 9",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Jackson, Donald Lee",
        "City": "Rockford",
        "Year": "1968",
        "lat": "42.2669",
        "Document": "docs/donald-lee-jackson.pdf"
    },
    {
        "TroopNumber": "Pack 15",
        "lng": "-106.487494",
        "State": "Texas",
        "NameOrID": "Chavez, Joaquin",
        "City": "El Paso",
        "Year": "1964",
        "lat": "31.759165",
        "Document": "docs/joaquin-chavez.pdf"
    },
    {
        "TroopNumber": "Troop 21",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Jackson, Donald Lee",
        "City": "Rockford",
        "Year": "1968",
        "lat": "42.2669",
        "Document": "docs/donald-lee-jackson.pdf"
    },
    {
        "TroopNumber": "Troop 4",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Veline, Dale D.",
        "City": "Rockford",
        "Year": "1969",
        "lat": "42.2669",
        "Document": "docs/dale-d-veline.pdf"
    },
    {
        "TroopNumber": "Troop 20",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Veline, Dale D.",
        "City": "Rockford",
        "Year": "1969",
        "lat": "42.2669",
        "Document": "docs/dale-d-veline.pdf"
    },
    {
        "TroopNumber": "Troop 10",
        "lng": "-74.075283",
        "State": "New York",
        "NameOrID": "Anderson, Juan",
        "City": "Staten Island",
        "Year": "1966",
        "lat": "40.64244",
        "Document": "docs/juan-anderson.pdf"
    },
    {
        "TroopNumber": "Pack 8",
        "lng": "-90.183834",
        "State": "Illinois",
        "NameOrID": "Bailey, Ralph",
        "City": "Alton",
        "Year": "1964",
        "lat": "38.89086",
        "Document": "docs/ralph-bailey.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-89.8039845",
        "State": "Illinois",
        "NameOrID": "Bailey, Ralph",
        "City": "Benld",
        "Year": "1964",
        "lat": "39.0928246",
        "Document": "docs/ralph-bailey.pdf"
    },
    {
        "TroopNumber": "Troop 3412",
        "lng": "-81.866654",
        "State": "Ohio",
        "NameOrID": "Denton, John K.",
        "City": "Coshocton",
        "Year": "1962",
        "lat": "40.27229",
        "Document": "docs/john-k-denton.pdf"
    },
    {
        "TroopNumber": "Troop 73",
        "lng": "-73.935554",
        "State": "New York",
        "NameOrID": "Wall, Charles Vernon",
        "City": "Hyde Park",
        "Year": "1967",
        "lat": "41.7915",
        "Document": "docs/charles-vernon-wall.pdf"
    },
    {
        "TroopNumber": "Troop 3135",
        "lng": "-75.264659",
        "State": "Pennsylvania",
        "NameOrID": "Carpenter, Julian Delroy",
        "City": "Upper Darby",
        "Year": "1967",
        "lat": "39.96006",
        "Document": "docs/julian-delroy-carpenter.pdf"
    },
    {
        "TroopNumber": "Troop 3",
        "lng": "-81.766339",
        "State": "Florida",
        "NameOrID": "Wall, Charles Vernon",
        "City": "Clearmont",
        "Year": "1967",
        "lat": "28.551295",
        "Document": "docs/charles-vernon-wall.pdf"
    },
    {
        "TroopNumber": "Troop 3121",
        "lng": "-122.329439",
        "State": "Washington",
        "NameOrID": "Whitchurch, Melvin James",
        "City": "Seattle",
        "Year": "1970",
        "lat": "47.60356",
        "Document": "docs/melvin-james-whitchurch.pdf"
    },
    {
        "TroopNumber": "Troop 40",
        "lng": "-73.083034",
        "State": "Vermont",
        "NameOrID": "Comstock, John",
        "City": "St. Albans",
        "Year": "1961",
        "lat": "44.81302",
        "Document": "docs/john-comstock.pdf"
    },
    {
        "TroopNumber": "Pack 2",
        "lng": "-73.083034",
        "State": "Vermont",
        "NameOrID": "Comstock, John",
        "City": "St. Albans",
        "Year": "1961",
        "lat": "44.81302",
        "Document": "docs/john-comstock.pdf"
    },
    {
        "TroopNumber": "Troop G-1",
        "lng": "-73.542234",
        "State": "Connecticut",
        "NameOrID": "Olson, Edward B.",
        "City": "Stamford",
        "Year": "1966",
        "lat": "41.05182",
        "Document": "docs/edward-b-olson.pdf"
    },
    {
        "TroopNumber": "Explorer 2337",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Musser, John William",
        "City": "Kansas City",
        "Year": "1968",
        "lat": "39.10296",
        "Document": "docs/john-william-musser.pdf"
    },
    {
        "TroopNumber": "Pack 2227",
        "lng": "-75.321513",
        "State": "Pennsylvania",
        "NameOrID": "Mougakos, Milton Peter",
        "City": "Springfield",
        "Year": "1967",
        "lat": "39.930691",
        "Document": "docs/milton-peter-mougakos.pdf"
    },
    {
        "TroopNumber": "Pack 2211",
        "lng": "-77.096919",
        "State": "Maryland",
        "NameOrID": "Tietz, Fredric A.",
        "City": "Bethesda",
        "Year": "1966",
        "lat": "38.98637",
        "Document": "docs/fredric-a-tietz.pdf"
    },
    {
        "TroopNumber": "Explorer 2173",
        "lng": "-105.279239",
        "State": "Colorado",
        "NameOrID": "Wilber, Robert W.",
        "City": "Boulder",
        "Year": "1963",
        "lat": "40.01574",
        "Document": "docs/robert-w-wilber.pdf"
    },
    {
        "TroopNumber": "Pack 2088",
        "lng": "",
        "State": "",
        "NameOrID": "Orr, Lindley",
        "City": "",
        "Year": "1969",
        "lat": "",
        "Document": "docs/lindley-orr.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 5052",
        "lng": "-70.683928",
        "State": "Massachusetts",
        "NameOrID": "L'Heureux, Cleophus B.",
        "City": "Duxbury",
        "Year": "1961",
        "lat": "42.025865",
        "Document": "docs/cleophus-b-lheureux.pdf"
    },
    {
        "TroopNumber": "Pack 1781",
        "lng": "-83.206428",
        "State": "Michigan",
        "NameOrID": "Ciotti, Henry Italo",
        "City": "Southgate",
        "Year": "1969",
        "lat": "42.203743",
        "Document": "docs/henry-italo-ciotti.pdf"
    },
    {
        "TroopNumber": "Troop 1611",
        "lng": "-83.215514",
        "State": "Michigan",
        "NameOrID": "Feindt, C. Fred",
        "City": "Birmingham",
        "Year": "1962",
        "lat": "42.545945",
        "Document": "docs/c-fred-feindt.pdf"
    },
    {
        "TroopNumber": "Pack 909",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "Pyhala, Verner",
        "City": "San Diego",
        "Year": "1966",
        "lat": "32.715695",
        "Document": "docs/verner-pyhala.pdf"
    },
    {
        "TroopNumber": "Troop 1555",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Baker, Gilbert",
        "City": "Chicago",
        "Year": "1961",
        "lat": "41.88415",
        "Document": "docs/gilbert-baker.pdf"
    },
    {
        "TroopNumber": "Troop 1273",
        "lng": "-83.047924",
        "State": "Michigan",
        "NameOrID": "Simons, Harvey W.",
        "City": "Detroit",
        "Year": "1968",
        "lat": "42.331685",
        "Document": "docs/harvey-w-simons.pdf"
    },
    {
        "TroopNumber": "Troop 897",
        "lng": "-117.084279",
        "State": "California",
        "NameOrID": "Adams, William Dale",
        "City": "Chula Vista",
        "Year": "1970",
        "lat": "32.6409",
        "Document": "docs/william-dale-adams-jr.pdf"
    },
    {
        "TroopNumber": "Troop 892",
        "lng": "-87.632409",
        "State": "Illinois",
        "NameOrID": "Stephenson, Steve",
        "City": "Chicago",
        "Year": "1963",
        "lat": "41.88415",
        "Document": "docs/steve-stephenson.pdf"
    },
    {
        "TroopNumber": "Troop 877",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "Barkley, Owen Herbert",
        "City": "San Diego",
        "Year": "1963",
        "lat": "32.715695",
        "Document": "docs/owen-herbert-barkley.pdf"
    },
    {
        "TroopNumber": "Troop 1111",
        "lng": "-83.176239",
        "State": "Michigan",
        "NameOrID": "Weeks, J. W.",
        "City": "Dearborn",
        "Year": "1969",
        "lat": "42.32209",
        "Document": "docs/j-w-weeks.pdf"
    },
    {
        "TroopNumber": "Troop 874",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Silva, Julio H.",
        "City": "Houston",
        "Year": "1968",
        "lat": "29.76045",
        "Document": "docs/julio-h-silva.pdf"
    },
    {
        "TroopNumber": "Troop 801",
        "lng": "-117.170894",
        "State": "California",
        "NameOrID": "Barrett, Robert Loius",
        "City": "Coronado",
        "Year": "1969",
        "lat": "32.67619",
        "Document": "docs/robert-loius-barrett.pdf"
    },
    {
        "TroopNumber": "Troop 1094",
        "lng": "-77.2908166",
        "State": "Maryland",
        "NameOrID": "Skrutskie, John J.",
        "City": "Darrenstown",
        "Year": "1966",
        "lat": "39.1034409",
        "Document": "docs/john-j-skrutskie.pdf"
    },
    {
        "TroopNumber": "Troop 783",
        "lng": "-90.538789",
        "State": "Missouri",
        "NameOrID": "Gupton, Howard",
        "City": "Ballwin",
        "Year": "1969",
        "lat": "38.592985",
        "Document": "docs/howard-gupton.pdf"
    },
    {
        "TroopNumber": "Troop 1083",
        "lng": "-83.183904",
        "State": "Michigan",
        "NameOrID": "Sawyer, David A.",
        "City": "Berkley",
        "Year": "1970",
        "lat": "42.5028",
        "Document": "docs/david-a-sawyer.pdf"
    },
    {
        "TroopNumber": "Troop 1034",
        "lng": "-83.242454",
        "State": "Michigan",
        "NameOrID": "Eubank, James Harrell",
        "City": "Bloomfield Hills",
        "Year": "1969",
        "lat": "42.584215",
        "Document": "docs/james-harrell-eubank.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 776",
        "lng": "-117.292518",
        "State": "California",
        "NameOrID": "Peterson, Victor James",
        "City": "Encinitas",
        "Year": "1960",
        "lat": "33.045436",
        "Document": "docs/victor-james-peterson.pdf"
    },
    {
        "TroopNumber": "Troop 925",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Adams, Oatley F.",
        "City": "Houston",
        "Year": "1964",
        "lat": "29.76045",
        "Document": "docs/oatley-f-adams.pdf"
    },
    {
        "TroopNumber": "Troop 774",
        "lng": "-78.997354",
        "State": "North Carolina",
        "NameOrID": "Bruskin, Robert H.",
        "City": "Ft. Bragg",
        "Year": "1967",
        "lat": "35.135575",
        "Document": "docs/robert-h-bruskin.pdf"
    },
    {
        "TroopNumber": "Troop 765",
        "lng": "-122.329439",
        "State": "Washington",
        "NameOrID": "Noonan, John E.",
        "City": "Seattle",
        "Year": "1966",
        "lat": "47.60356",
        "Document": "docs/john-e-noonan.pdf"
    },
    {
        "TroopNumber": "Troop 913",
        "lng": "-76.635829",
        "State": "Maryland",
        "NameOrID": "Knowlton, James",
        "City": "Leonardtown",
        "Year": "1970",
        "lat": "38.291805",
        "Document": "docs/james-knowlton.pdf"
    },
    {
        "TroopNumber": "Troop 739",
        "lng": "-74.007124",
        "State": "New York",
        "NameOrID": "Rodriguez, Edward A.",
        "City": "New York",
        "Year": "1965",
        "lat": "40.71455",
        "Document": "docs/edward-a-rodriguez.pdf"
    },
    {
        "TroopNumber": "Pack 719",
        "lng": "-76.884338",
        "State": "Maryland",
        "NameOrID": "Simmons, John Wesley",
        "City": "Kent Village",
        "Year": "1968",
        "lat": "38.925282",
        "Document": "docs/john-wesley-simmons.pdf"
    },
    {
        "TroopNumber": "Troop 708",
        "lng": "-73.057337",
        "State": "Connecticut",
        "NameOrID": "Fricke, Donald Leroy",
        "City": "Milford",
        "Year": "1966",
        "lat": "41.225017",
        "Document": "docs/donald-leroy-fricke.pdf"
    },
    {
        "TroopNumber": "Troop 704",
        "lng": "-78.997354",
        "State": "North Carolina",
        "NameOrID": "Steinwinder, Harry L.",
        "City": "Ft. Bragg",
        "Year": "1967",
        "lat": "35.135575",
        "Document": "docs/harry-l-steinwinder.pdf"
    },
    {
        "TroopNumber": "Troop 633",
        "lng": "-118.2200712",
        "State": "California",
        "NameOrID": "Houck, Joel Elisla",
        "City": "Compton",
        "Year": "1967",
        "lat": "33.8958492",
        "Document": "docs/joel-elisla-houck-jr.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 684",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Gagnier, Brian M.",
        "City": "Portland",
        "Year": "1968",
        "lat": "45.511795",
        "Document": "docs/brian-m-gagnier.pdf"
    },
    {
        "TroopNumber": "Troop 682",
        "lng": "-74.007124",
        "State": "New York",
        "NameOrID": "Santinelli, Arthur",
        "City": "New York",
        "Year": "1966",
        "lat": "40.71455",
        "Document": "docs/arthur-santinelli.pdf"
    },
    {
        "TroopNumber": "Troop 676",
        "lng": "-79.790074",
        "State": "Pennsylvania",
        "NameOrID": "Cook, John Charles",
        "City": "Amend",
        "Year": "1966",
        "lat": "39.872935",
        "Document": "docs/john-charles-cook.pdf"
    },
    {
        "TroopNumber": "Troop 606",
        "lng": "-118.662044",
        "State": "California",
        "NameOrID": "Ungles, Warren K.",
        "City": "Santa Susana",
        "Year": "1970",
        "lat": "34.27025",
        "Document": "docs/warren-k-ungles.pdf"
    },
    {
        "TroopNumber": "Troop 668",
        "lng": "-81.690434",
        "State": "Ohio",
        "NameOrID": "Poorman, John Paul",
        "City": "Cleveland",
        "Year": "1962",
        "lat": "41.50438",
        "Document": "docs/john-paul-poorman.pdf"
    },
    {
        "TroopNumber": "Troop 605",
        "lng": "-97.335579",
        "State": "Kansas",
        "NameOrID": "Ashworth, Gerald",
        "City": "Wichita",
        "Year": "1968",
        "lat": "37.68698",
        "Document": "docs/gerald-ashworth.pdf"
    },
    {
        "TroopNumber": "Troop 662",
        "lng": "",
        "State": "",
        "NameOrID": "Henn, David W.",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/david-w-henn.pdf"
    },
    {
        "TroopNumber": "Troop 605",
        "lng": "-74.007124",
        "State": "New York",
        "NameOrID": "Morris, Ernest",
        "City": "New York",
        "Year": "1964",
        "lat": "40.71455",
        "Document": "docs/ernest-morris.pdf"
    },
    {
        "TroopNumber": "Troop 652",
        "lng": "-121.885794",
        "State": "California",
        "NameOrID": "O'Bryon, Jon Clifford",
        "City": "San Jose",
        "Year": "1969",
        "lat": "37.338475",
        "Document": "docs/jon-clifford-obryon.pdf"
    },
    {
        "TroopNumber": "Troop 596",
        "lng": "-97.335579",
        "State": "Kansas",
        "NameOrID": "Boewe, Robert A.",
        "City": "Wichita",
        "Year": "1970",
        "lat": "37.68698",
        "Document": "docs/robert-a-boewe.pdf"
    },
    {
        "TroopNumber": "Troop 649",
        "lng": "-95.369784",
        "State": "Texas",
        "NameOrID": "Adams, Joel Ray",
        "City": "Houston",
        "Year": "1960",
        "lat": "29.76045",
        "Document": "docs/joel-ray-adams.pdf"
    },
    {
        "TroopNumber": "Troop 585",
        "lng": "-74.006099",
        "State": "New York",
        "NameOrID": "Bains, Edward",
        "City": "Manhattan",
        "Year": "1964",
        "lat": "40.71443",
        "Document": "docs/edward-bains.pdf"
    },
    {
        "TroopNumber": "Troop 577",
        "lng": "-72.251361",
        "State": "Massachusetts",
        "NameOrID": "Winch, Daniel H.",
        "City": "Ware",
        "Year": "1968",
        "lat": "42.255912",
        "Document": "docs/daniel-h-winch.pdf"
    },
    {
        "TroopNumber": "Troop 636",
        "lng": "-86.149964",
        "State": "Indiana",
        "NameOrID": "Schafer, John C.",
        "City": "Indianapolis",
        "Year": "1970",
        "lat": "39.76691",
        "Document": "docs/john-c-schafer.pdf"
    },
    {
        "TroopNumber": "Troop 575",
        "lng": "-79.997459",
        "State": "Pennsylvania",
        "NameOrID": "Hardiman, Martin C.",
        "City": "Pittsburgh",
        "Year": "1970",
        "lat": "40.438335",
        "Document": "docs/martin-c-hardiman.pdf"
    },
    {
        "TroopNumber": "Troop 571",
        "lng": "-78.886371",
        "State": "New York",
        "NameOrID": "Aycoth, Richard J.",
        "City": "Tonawanda",
        "Year": "1963",
        "lat": "43.018839",
        "Document": "docs/richard-j-aycoth.pdf"
    },
    {
        "TroopNumber": "Troop 512",
        "lng": "-80.07375",
        "State": "South Carolina",
        "NameOrID": "Menius, E. Flynn",
        "City": "Hartsville",
        "Year": "1964",
        "lat": "34.375025",
        "Document": "docs/e-flynn-menius-jr.pdf"
    },
    {
        "TroopNumber": "Troop 506",
        "lng": "",
        "State": "",
        "NameOrID": "Iafelice, Frank",
        "City": "",
        "Year": "1970",
        "lat": "",
        "Document": "docs/frank-iafelice.pdf"
    },
    {
        "TroopNumber": "Troop 560",
        "lng": "-74.006099",
        "State": "New York",
        "NameOrID": "Schwieg, Daniel J.",
        "City": "Manhattan",
        "Year": "1964",
        "lat": "40.71443",
        "Document": "docs/daniel-j-schwieg.pdf"
    },
    {
        "TroopNumber": "Explorer 550",
        "lng": "-92.017054",
        "State": "Louisiana",
        "NameOrID": "Guillet, John Shelton",
        "City": "Lafayette",
        "Year": "1967",
        "lat": "30.220325",
        "Document": "docs/john-shelton-guillet.pdf"
    },
    {
        "TroopNumber": "Troop 501",
        "lng": "-121.978639",
        "State": "California",
        "NameOrID": "Watson, Thomas Burton",
        "City": "Los Gatos",
        "Year": "1966",
        "lat": "37.221245",
        "Document": "docs/thomas-burton-watson.pdf"
    },
    {
        "TroopNumber": "Troop 543",
        "lng": "-76.440336",
        "State": "Pennsylvania",
        "NameOrID": "Lissanski, James",
        "City": "Papermill",
        "Year": "1962",
        "lat": "41.027613",
        "Document": "docs/james-lissanski.pdf"
    },
    {
        "TroopNumber": "Troop 500",
        "lng": "-122.014019",
        "State": "California",
        "NameOrID": "Huntsinger, Richard Paul",
        "City": "Saratoga",
        "Year": "1969",
        "lat": "37.271056",
        "Document": "docs/richard-paul-huntsinger.pdf"
    },
    {
        "TroopNumber": "Troop 542",
        "lng": "-81.690434",
        "State": "Ohio",
        "NameOrID": "Wellborn, James A.",
        "City": "Cleveland",
        "Year": "1965",
        "lat": "41.50438",
        "Document": "docs/james-a-wellborn.pdf"
    },
    {
        "TroopNumber": "Troop 495",
        "lng": "-119.709034",
        "State": "California",
        "NameOrID": "Gibson, W. Gideon",
        "City": "Armona",
        "Year": "1968",
        "lat": "36.313425",
        "Document": "docs/w-gideon-gibson.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 541",
        "lng": "-88.010159",
        "State": "Wisconsin",
        "NameOrID": "Couvillion, William Robert",
        "City": "Green Bay",
        "Year": "1966",
        "lat": "44.512795",
        "Document": "docs/william-robert-couvillion.pdf"
    },
    {
        "TroopNumber": "Pack 535",
        "lng": "-78.878464",
        "State": "New York",
        "NameOrID": "Knoer, Robert Harold",
        "City": "Buffalo",
        "Year": "1966",
        "lat": "42.88544",
        "Document": "docs/robert-harold-knoer.pdf"
    },
    {
        "TroopNumber": "Troop 488",
        "lng": "-75.183969",
        "State": "Pennsylvania",
        "NameOrID": "Carr, Richard B.",
        "City": "Oreland",
        "Year": "1970",
        "lat": "40.11678",
        "Document": "docs/richard-b-carr.pdf"
    },
    {
        "TroopNumber": "Pack 531",
        "lng": "-73.990364",
        "State": "New York",
        "NameOrID": "Van Orden, John",
        "City": "Brooklyn",
        "Year": "1963",
        "lat": "40.692455",
        "Document": "docs/john-van-orden.pdf"
    },
    {
        "TroopNumber": "Troop 473",
        "lng": "-75.654929",
        "State": "New York",
        "NameOrID": "Caron, Gerard N.",
        "City": "Oneida",
        "Year": "1968",
        "lat": "43.098745",
        "Document": "docs/gerard-n-caron.pdf"
    },
    {
        "TroopNumber": "Explorer 522",
        "lng": "-112.075774",
        "State": "Arizona",
        "NameOrID": "Frederick, Lee",
        "City": "Phoenix",
        "Year": "1966",
        "lat": "33.44826",
        "Document": "docs/lee-frederick.pdf"
    },
    {
        "TroopNumber": "Troop 452",
        "lng": "-122.273024",
        "State": "California",
        "NameOrID": "Coffee, Tommie E.",
        "City": "Oakland",
        "Year": "1987",
        "lat": "37.805065",
        "Document": "docs/tommie-e-coffee.pdf"
    },
    {
        "TroopNumber": "Troop 521",
        "lng": "-108.553665",
        "State": "Colorado",
        "NameOrID": "Duncan, David Paul",
        "City": "Cortez",
        "Year": "1963",
        "lat": "37.354665",
        "Document": "docs/david-paul-duncan.pdf"
    },
    {
        "TroopNumber": "Troop 452",
        "lng": "-83.047924",
        "State": "Michigan",
        "NameOrID": "Giacalone, Michael",
        "City": "Detroit",
        "Year": "1962",
        "lat": "42.331685",
        "Document": "docs/michael-giacalone.pdf"
    },
    {
        "TroopNumber": "Troop 449",
        "lng": "-75.270114",
        "State": "Pennsylvania",
        "NameOrID": "Murphy, Austin D.",
        "City": "Wynnewood",
        "Year": "1963",
        "lat": "40.002995",
        "Document": "docs/austin-d-murphy.pdf"
    },
    {
        "TroopNumber": "Troop 518",
        "lng": "-80.07375",
        "State": "South Carolina",
        "NameOrID": "Garland, John Duckworth",
        "City": "Hartsville",
        "Year": "1967",
        "lat": "34.375025",
        "Document": "docs/john-duckworth-garland.pdf"
    },
    {
        "TroopNumber": "Explorer 516",
        "lng": "-86.614669",
        "State": "Florida",
        "NameOrID": "Stewart, Eddie Joe",
        "City": "Ft. Walton Beach",
        "Year": "1964",
        "lat": "30.4042",
        "Document": "docs/eddie-joe-stewart.pdf"
    },
    {
        "TroopNumber": "Troop 443",
        "lng": "-104.992259",
        "State": "Colorado",
        "NameOrID": "Wells, James",
        "City": "Denver",
        "Year": "1966",
        "lat": "39.74001",
        "Document": "docs/james-wells.pdf"
    },
    {
        "TroopNumber": "Troop 515",
        "lng": "-77.327299",
        "State": "Virginia",
        "NameOrID": "Parrott, Rowland M.",
        "City": "Highland Springs",
        "Year": "1970",
        "lat": "37.545665",
        "Document": "docs/rowland-m-parrott.pdf"
    },
    {
        "TroopNumber": "Troop 436",
        "lng": "-101.845009",
        "State": "Texas",
        "NameOrID": "Livingston, Ruben",
        "City": "Lubbock",
        "Year": "1964",
        "lat": "33.58451",
        "Document": "docs/ruben-livingston.pdf"
    },
    {
        "TroopNumber": "Troop 402",
        "lng": "-74.384629",
        "State": "New Jersey",
        "NameOrID": "Fidler, Raymond F.",
        "City": "Edison",
        "Year": "1968",
        "lat": "40.528045",
        "Document": "docs/raymond-f-fidler-jr.pdf"
    },
    {
        "TroopNumber": "Troop 401",
        "lng": "-75.183539",
        "State": "Pennsylvania",
        "NameOrID": "Waller, Donald Kay",
        "City": "Hawley",
        "Year": "1969",
        "lat": "41.47671",
        "Document": "docs/donald-kay-waller.pdf"
    },
    {
        "TroopNumber": "Troop 398",
        "lng": "-84.400524",
        "State": "Michigan",
        "NameOrID": "Miner, Gordon P.",
        "City": "Jackson",
        "Year": "1968",
        "lat": "42.244765",
        "Document": "docs/gordon-p-miner.pdf"
    },
    {
        "TroopNumber": "Explorer 418",
        "lng": "",
        "State": "",
        "NameOrID": "Steere, Thomas H.",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/thomas-h-steere-jr.pdf"
    },
    {
        "TroopNumber": "Troop 413",
        "lng": "-73.345242",
        "State": "New York",
        "NameOrID": "Van Baalen, Henry",
        "City": "Northport",
        "Year": "1966",
        "lat": "40.901203",
        "Document": "docs/henry-van-baalen-sr.pdf"
    },
    {
        "TroopNumber": "Troop 411",
        "lng": "-120.735454",
        "State": "California",
        "NameOrID": "Rogers, Paul Denton",
        "City": "Avila Beach",
        "Year": "1963",
        "lat": "35.17931",
        "Document": "docs/paul-denton-rogers.pdf"
    },
    {
        "TroopNumber": "Troop 410",
        "lng": "",
        "State": "",
        "NameOrID": "Monahan, John Joseph",
        "City": "",
        "Year": "1970",
        "lat": "",
        "Document": "docs/john-joseph-monahan.pdf"
    },
    {
        "TroopNumber": "Troop 407",
        "lng": "-101.296074",
        "State": "North Dakota",
        "NameOrID": "Knippel, Lawrence Arthur",
        "City": "Minot",
        "Year": "1960",
        "lat": "48.236115",
        "Document": "docs/lawrence-arthur-knippel.pdf"
    },
    {
        "TroopNumber": "Troop 405",
        "lng": "-95.940469",
        "State": "Nebraska",
        "NameOrID": "Hauswirth, Norman E.",
        "City": "Omaha",
        "Year": "1965",
        "lat": "41.260675",
        "Document": "docs/norman-e-hauswirth.pdf"
    },
    {
        "TroopNumber": "Troop 402",
        "lng": "-75.550844",
        "State": "Delaware",
        "NameOrID": "Burnett, Eugene Fred",
        "City": "Wilmington",
        "Year": "1970",
        "lat": "39.740235",
        "Document": "docs/eugene-fred-burnett.pdf"
    },
    {
        "TroopNumber": "Pack 398",
        "lng": "-93.098929",
        "State": "Iowa",
        "NameOrID": "Smith, Ted Allen",
        "City": "Eldora",
        "Year": "1963",
        "lat": "42.360645",
        "Document": "docs/ted-allen-smith.pdf"
    },
    {
        "TroopNumber": "Troop 363",
        "lng": "-80.493317",
        "State": "South Carolina",
        "NameOrID": "Davis, Carl W.",
        "City": "Shaw AFB",
        "Year": "1967",
        "lat": "33.993118",
        "Document": "docs/carl-w-davis.pdf"
    },
    {
        "TroopNumber": "Troop 392",
        "lng": "-120.550018",
        "State": "California",
        "NameOrID": "Brady, John L.",
        "City": "Vandenberg AFB",
        "Year": "1968",
        "lat": "34.751801",
        "Document": "docs/john-l-brady.pdf"
    },
    {
        "TroopNumber": "Troop 355",
        "lng": "-76.609264",
        "State": "Maryland",
        "NameOrID": "Shusta, Leo J.",
        "City": "Baltimore",
        "Year": "1960",
        "lat": "39.290585",
        "Document": "docs/leo-j-shusta.pdf"
    },
    {
        "TroopNumber": "Explorer 389",
        "lng": "-95.487968",
        "State": "Texas",
        "NameOrID": "Butler, Cary",
        "City": "Mineola",
        "Year": "1970",
        "lat": "32.6638",
        "Document": "docs/cary-butler.pdf"
    },
    {
        "TroopNumber": "Troop 352",
        "lng": "-95.940469",
        "State": "Nebraska",
        "NameOrID": "Kalasky, James E.",
        "City": "Omaha",
        "Year": "1969",
        "lat": "41.260675",
        "Document": "docs/james-e-kalasky.pdf"
    },
    {
        "TroopNumber": "Troop 378",
        "lng": "-84.504552",
        "State": "Ohio",
        "NameOrID": "Beakes, John W.",
        "City": "Cincinnati",
        "Year": "1961",
        "lat": "39.106614",
        "Document": "docs/john-w-beakes.pdf"
    },
    {
        "TroopNumber": "Pack 352",
        "lng": "-88.235513",
        "State": "Wisconsin",
        "NameOrID": "Haynes , Alexander G.",
        "City": "Waukesha",
        "Year": "1960",
        "lat": "43.014423",
        "Document": "docs/alexander-g-haynes.pdf"
    },
    {
        "TroopNumber": "Explorer 377",
        "lng": "-90.077749",
        "State": "Louisiana",
        "NameOrID": "O'Donnell, Cornelius J.",
        "City": "New Orleans",
        "Year": "1966",
        "lat": "29.9537",
        "Document": "docs/cornelius-j-odonnell.pdf"
    },
    {
        "TroopNumber": "Troop 351",
        "lng": "-74.006099",
        "State": "New York",
        "NameOrID": "O'Rourke, William",
        "City": "Manhattan",
        "Year": "1965",
        "lat": "40.71443",
        "Document": "docs/william-orourke.pdf"
    },
    {
        "TroopNumber": "Pack 376",
        "lng": "-89.078354",
        "State": "Illinois",
        "NameOrID": "Phillips, Roy E",
        "City": "Rockford",
        "Year": "1965",
        "lat": "42.2669",
        "Document": "docs/roy-e-phillips.pdf"
    },
    {
        "TroopNumber": "Troop 350",
        "lng": "-90.355924",
        "State": "Missouri",
        "NameOrID": "Beckman, Carl Henry",
        "City": "Webster Groves",
        "Year": "1964",
        "lat": "38.59265",
        "Document": "docs/carl-henry-beckman-iii.pdf"
    },
    {
        "TroopNumber": "Troop 375",
        "lng": "-116.959469",
        "State": "California",
        "NameOrID": "Dessert, William P.",
        "City": "El Cajon",
        "Year": "1964",
        "lat": "32.79495",
        "Document": "docs/william-p-dessert.pdf"
    },
    {
        "TroopNumber": "Troop 348",
        "lng": "-85.766404",
        "State": "Kentucky",
        "NameOrID": "Philpott, James",
        "City": "Louisville",
        "Year": "1961",
        "lat": "38.25486",
        "Document": "docs/james-philpott.pdf"
    },
    {
        "TroopNumber": "Pack 373",
        "lng": "-82.323184",
        "State": "Florida",
        "NameOrID": "Jones, George",
        "City": "Gainesville",
        "Year": "1964",
        "lat": "29.65195",
        "Document": "docs/george-jones-jr.pdf"
    },
    {
        "TroopNumber": "Explorer 347",
        "lng": "-76.480229",
        "State": "Virginia",
        "NameOrID": "Barton, Lynwood S.",
        "City": "Newport News",
        "Year": "1965",
        "lat": "37.082885",
        "Document": "docs/lynwood-s-barton.pdf"
    },
    {
        "TroopNumber": "Troop 372",
        "lng": "-118.057304",
        "State": "California",
        "NameOrID": "Newbery, William H.",
        "City": "Sierra Madre",
        "Year": "1967",
        "lat": "34.16187",
        "Document": "docs/william-h-newbery.pdf"
    },
    {
        "TroopNumber": "Troop 346",
        "lng": "-75.359539",
        "State": "Pennsylvania",
        "NameOrID": "Gallagher, Michael P.",
        "City": "Broomall",
        "Year": "1966",
        "lat": "39.98062",
        "Document": "docs/michael-p-gallagher.pdf"
    },
    {
        "TroopNumber": "Troop 371",
        "lng": "-117.936159",
        "State": "California",
        "NameOrID": "Drake, Donald Clarence",
        "City": "Garden Grove",
        "Year": "1965",
        "lat": "33.77607",
        "Document": "docs/donald-clarence-drake.pdf"
    },
    {
        "TroopNumber": "Troop 367",
        "lng": "-76.285069",
        "State": "Virginia",
        "NameOrID": "Culpepper, Linwood M.",
        "City": "Norfolk",
        "Year": "1963",
        "lat": "36.846825",
        "Document": "docs/linwood-m-culpepper.pdf"
    },
    {
        "TroopNumber": "Troop 344",
        "lng": "-74.384629",
        "State": "New Jersey",
        "NameOrID": "Bakelaar, John Philip",
        "City": "Edison",
        "Year": "1968",
        "lat": "40.528045",
        "Document": "docs/john-philip-bakelaar.pdf"
    },
    {
        "TroopNumber": "Troop 366",
        "lng": "-83.002984",
        "State": "Ohio",
        "NameOrID": "McGowan, Lawrence",
        "City": "Columbus",
        "Year": "1967",
        "lat": "39.96196",
        "Document": "docs/lawrence-mcgowan.pdf"
    },
    {
        "TroopNumber": "Troop 341",
        "lng": "-86.126244",
        "State": "Indiana",
        "NameOrID": "Hunsberger, Craig C.",
        "City": "Kokomo",
        "Year": "1967",
        "lat": "40.48651",
        "Document": "docs/craig-c-hunsberger.pdf"
    },
    {
        "TroopNumber": "Troop 341",
        "lng": "-90.336749",
        "State": "Missouri",
        "NameOrID": "Densker, Donald",
        "City": "Clayton",
        "Year": "1970",
        "lat": "38.650845",
        "Document": "docs/donald-densker.pdf"
    },
    {
        "TroopNumber": "Troop 337",
        "lng": "-80.606884",
        "State": "Florida",
        "NameOrID": "Smith, William D.",
        "City": "Melbourne",
        "Year": "1968",
        "lat": "28.07939",
        "Document": "docs/william-d-smith.pdf"
    },
    {
        "TroopNumber": "Explorer 340",
        "lng": "-79.762109",
        "State": "North Carolina",
        "NameOrID": "Hicks, Kenneth Lamar",
        "City": "Pleasant Garden",
        "Year": "1967",
        "lat": "35.9626",
        "Document": "docs/kenneth-lamar-hicks.pdf"
    },
    {
        "TroopNumber": "Troop 337",
        "lng": "",
        "State": "",
        "NameOrID": "Patton, William John",
        "City": "",
        "Year": "1969",
        "lat": "",
        "Document": "docs/william-john-patton.pdf"
    },
    {
        "TroopNumber": "Troop 335",
        "lng": "-118.116574",
        "State": "California",
        "NameOrID": "Blankenship, Wyman C.",
        "City": "Palmdale",
        "Year": "1969",
        "lat": "34.57923",
        "Document": "docs/wyman-c-blankenship.pdf"
    },
    {
        "TroopNumber": "Troop 311",
        "lng": "-87.747024",
        "State": "Illinois",
        "NameOrID": "Votava, Thomas",
        "City": "Cicero",
        "Year": "1965",
        "lat": "41.844835",
        "Document": "docs/thomas-votava.pdf"
    },
    {
        "TroopNumber": "Troop 327",
        "lng": "-97.038979",
        "State": "Kansas",
        "NameOrID": "Goatley, William H.",
        "City": "Arkansas City",
        "Year": "1969",
        "lat": "37.055865",
        "Document": "docs/william-h-goatley.pdf"
    },
    {
        "TroopNumber": "Troop 310",
        "lng": "-99.194313",
        "State": "Oklahoma",
        "NameOrID": "Gaston, William M.",
        "City": "Clinton-Sherman AFB",
        "Year": "1964",
        "lat": "35.354671",
        "Document": "docs/william-m-gaston-sr.pdf"
    },
    {
        "TroopNumber": "Troop 324",
        "lng": "9.171925",
        "State": "Germany",
        "NameOrID": "Martin, C. Paul",
        "City": "Stuttgart",
        "Year": "1969",
        "lat": "48.767675",
        "Document": "docs/c-paul-martin.pdf"
    },
    {
        "TroopNumber": "Troop 304",
        "lng": "-99.194313",
        "State": "Oklahoma",
        "NameOrID": "Dobson, David Neal",
        "City": "Clinton-Sherman AFB",
        "Year": "1965",
        "lat": "35.354671",
        "Document": "docs/david-neal-dobson.pdf"
    },
    {
        "TroopNumber": "Troop 323",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Enoch, Wesley G.",
        "City": "Kansas City",
        "Year": "1961",
        "lat": "39.10296",
        "Document": "docs/wesley-g-enoch.pdf"
    },
    {
        "TroopNumber": "Troop 323",
        "lng": "-92.825043",
        "State": "Iowa",
        "NameOrID": "Ewalt, Allen Clarence",
        "City": "Green Mountain",
        "Year": "1965",
        "lat": "42.102612",
        "Document": "docs/allen-clarence-ewalt.pdf"
    },
    {
        "TroopNumber": "Pack 323",
        "lng": "-122.329439",
        "State": "Washington",
        "NameOrID": "Wysong, Clifford Gerald",
        "City": "Seattle",
        "Year": "1964",
        "lat": "47.60356",
        "Document": "docs/clifford-gerald-wysong.pdf"
    },
    {
        "TroopNumber": "Troop 321",
        "lng": "7.519944",
        "State": "Germany",
        "NameOrID": "Clark, Frederick Eugene",
        "City": "Band Hersfeld",
        "Year": "1963",
        "lat": "50.695731",
        "Document": "docs/frederick-eugene-clark.pdf"
    },
    {
        "TroopNumber": "Troop 301",
        "lng": "-80.237419",
        "State": "Florida",
        "NameOrID": "Connell, Donald",
        "City": "Miami",
        "Year": "1969",
        "lat": "25.728985",
        "Document": "docs/donald-connell.pdf"
    },
    {
        "TroopNumber": "Troop 297",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Kelly, Curtis A.",
        "City": "Jacksonville",
        "Year": "1967",
        "lat": "30.33147",
        "Document": "docs/curtis-kelly.pdf"
    },
    {
        "TroopNumber": "Pack 321",
        "lng": "-112.075774",
        "State": "Arizona",
        "NameOrID": "Taylor, Wylie Jonathan",
        "City": "Phoenix",
        "Year": "1963",
        "lat": "33.44826",
        "Document": "docs/wylie-jonathan-taylor.pdf"
    },
    {
        "TroopNumber": "Troop 287",
        "lng": "-106.648639",
        "State": "New Mexico",
        "NameOrID": "Tully, Jerry Lee",
        "City": "Albuquerque",
        "Year": "1963",
        "lat": "35.08418",
        "Document": "docs/jerry-lee-tully.pdf"
    },
    {
        "TroopNumber": "Troop 318",
        "lng": "-72.336167",
        "State": "New Hampshire",
        "NameOrID": "Ricker, William G.",
        "City": "Claremont",
        "Year": "1970",
        "lat": "43.37419",
        "Document": "docs/william-g-ricker.pdf"
    },
    {
        "TroopNumber": "Troop 313",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Cromer, Leonard A.",
        "City": "St. Louis",
        "Year": "1970",
        "lat": "38.62774",
        "Document": "docs/leonard-a-cromer.pdf"
    },
    {
        "TroopNumber": "Troop 279",
        "lng": "-90.199514",
        "State": "Missouri",
        "NameOrID": "Burnett, Robert Benjamin",
        "City": "St. Louis",
        "Year": "1965",
        "lat": "38.62774",
        "Document": "docs/robert-benjamin-burnett.pdf"
    },
    {
        "TroopNumber": "Troop 313",
        "lng": "-80.734859",
        "State": "Florida",
        "NameOrID": "White, Emmett",
        "City": "Rockledge",
        "Year": "1966",
        "lat": "28.332689",
        "Document": "docs/emmett-white.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-83.047924",
        "State": "Michigan",
        "NameOrID": "Burnett, Robert Benjamin",
        "City": "Detroit",
        "Year": "1965",
        "lat": "42.331685",
        "Document": "docs/robert-benjamin-burnett.pdf"
    },
    {
        "TroopNumber": "Troop 277",
        "lng": "-74.007124",
        "State": "New York",
        "NameOrID": "Helmlinger, Lawrence C.",
        "City": "New York",
        "Year": "1961",
        "lat": "40.71455",
        "Document": "docs/lawrence-c-helmlinger.pdf"
    },
    {
        "TroopNumber": "Troop 278",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "McClure, John Allen",
        "City": "San Diego",
        "Year": "1968",
        "lat": "32.715695",
        "Document": "docs/john-allen-mcclure.pdf"
    },
    {
        "TroopNumber": "Troop 277",
        "lng": "-87.916949",
        "State": "Tennessee",
        "NameOrID": "Burger, Freddy",
        "City": "Houston",
        "Year": "1961",
        "lat": "35.240572",
        "Document": "docs/freddy-burger-jr.pdf"
    },
    {
        "TroopNumber": "Troop 274",
        "lng": "-75.382169",
        "State": "Pennsylvania",
        "NameOrID": "Thorpe, William James",
        "City": "Upland",
        "Year": "1966",
        "lat": "39.857055",
        "Document": "docs/william-james-thorpe.pdf"
    },
    {
        "TroopNumber": "Troop 274",
        "lng": "-79.997459",
        "State": "Pennsylvania",
        "NameOrID": "Jackson, James Juan",
        "City": "Pittsburgh",
        "Year": "1968",
        "lat": "40.438335",
        "Document": "docs/james-juan-jackson.pdf"
    },
    {
        "TroopNumber": "Troop 274",
        "lng": "-76.609264",
        "State": "Maryland",
        "NameOrID": "Dibartolomeo, Joseph A.",
        "City": "Baltimore",
        "Year": "1969",
        "lat": "39.290585",
        "Document": "docs/joseph-a-dibartolomeo.pdf"
    },
    {
        "TroopNumber": "Troop 246",
        "lng": "-122.592784",
        "State": "Oregon",
        "NameOrID": "Tanzer, Francis J.",
        "City": "Oregon City",
        "Year": "1966",
        "lat": "45.336518",
        "Document": "docs/francis-j-tanzer.pdf"
    },
    {
        "TroopNumber": "Troop 272",
        "lng": "-75.927843",
        "State": "Pennsylvania",
        "NameOrID": "Deputy, Edward E.",
        "City": "Reading",
        "Year": "1968",
        "lat": "40.336585",
        "Document": "docs/edward-e-deputy.pdf"
    },
    {
        "TroopNumber": "Troop 271",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Blakeslee, James L.",
        "City": "Rochester",
        "Year": "1962",
        "lat": "43.1555",
        "Document": "docs/james-l-blakeslee.pdf"
    },
    {
        "TroopNumber": "Troop 245",
        "lng": "-85.671179",
        "State": "Michigan",
        "NameOrID": "Ciminskie, Theodore J.",
        "City": "Grand Rapids",
        "Year": "1961",
        "lat": "42.96641",
        "Document": "docs/theodore-j-ciminskie.pdf"
    },
    {
        "TroopNumber": "Troop 270",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Jones, James A.",
        "City": "Jacksonville",
        "Year": "1966",
        "lat": "30.33147",
        "Document": "docs/james-a-jones.pdf"
    },
    {
        "TroopNumber": "Troop 241",
        "lng": "-83.613089",
        "State": "Michigan",
        "NameOrID": "Stout, James E.",
        "City": "Ypsilanti",
        "Year": "1969",
        "lat": "42.24088",
        "Document": "docs/james-e-stout.pdf"
    },
    {
        "TroopNumber": "Troop 254",
        "lng": "-76.558894",
        "State": "Pennsylvania",
        "NameOrID": "Donovan, Francis B.",
        "City": "Shamokin",
        "Year": "1964",
        "lat": "40.78435",
        "Document": "docs/francis-b-donovan.pdf"
    },
    {
        "TroopNumber": "Troop 239",
        "lng": "-88.084594",
        "State": "Illinois",
        "NameOrID": "Farrell, Patrick C.",
        "City": "Joliet",
        "Year": "1970",
        "lat": "41.524935",
        "Document": "docs/patrick-c-farrell.pdf"
    },
    {
        "TroopNumber": "Pack 251",
        "lng": "-121.885794",
        "State": "California",
        "NameOrID": "Newville, Charles",
        "City": "San Jose",
        "Year": "1962",
        "lat": "37.338475",
        "Document": "docs/charles-newville.pdf"
    },
    {
        "TroopNumber": "Pack 249",
        "lng": "-77.616033",
        "State": "New York",
        "NameOrID": "Clark, Charles R.",
        "City": "Rochester",
        "Year": "1969",
        "lat": "43.1555",
        "Document": "docs/charles-r-clark.pdf"
    },
    {
        "TroopNumber": "Troop 222",
        "lng": "-84.504552",
        "State": "Ohio",
        "NameOrID": "Gruber, Richard J.",
        "City": "Cincinnati",
        "Year": "1967",
        "lat": "39.106614",
        "Document": "docs/richard-j-gruber.pdf"
    },
    {
        "TroopNumber": "Troop 237",
        "lng": "-80.237419",
        "State": "Florida",
        "NameOrID": "Ewell, William Burton",
        "City": "Miami",
        "Year": "1969",
        "lat": "25.728985",
        "Document": "docs/william-burton-ewell.pdf"
    },
    {
        "TroopNumber": "Troop 235",
        "lng": "-122.435309",
        "State": "Oregon",
        "NameOrID": "Kennedy, David L.",
        "City": "Gresham",
        "Year": "1970",
        "lat": "45.50599",
        "Document": "docs/david-l-kennedy.pdf"
    },
    {
        "TroopNumber": "Troop 220",
        "lng": "-122.592784",
        "State": "Oregon",
        "NameOrID": "Brock, Clyde A",
        "City": "Oregon City",
        "Year": "1968",
        "lat": "45.336518",
        "Document": "docs/clyde-a-brock.pdf"
    },
    {
        "TroopNumber": "Troop 218",
        "lng": "-118.321135",
        "State": "California",
        "NameOrID": "Layman, Terrence Daniel",
        "City": "West Los Angeles",
        "Year": "1966",
        "lat": "34.089848",
        "Document": "docs/terrence-daniel-layman.pdf"
    },
    {
        "TroopNumber": "Troop 216",
        "lng": "-93.093326",
        "State": "Minnesota",
        "NameOrID": "Swendiman, Richard Fred",
        "City": "St. Paul",
        "Year": "1970",
        "lat": "44.943829",
        "Document": "docs/richard-fred-swendiman.pdf"
    },
    {
        "TroopNumber": "Troop 233",
        "lng": "-84.22982",
        "State": "Ohio",
        "NameOrID": "MacLardie, John Alfred",
        "City": "Miami",
        "Year": "1969",
        "lat": "40.039934",
        "Document": "docs/john-alfred-maclardie.pdf"
    },
    {
        "TroopNumber": "Troop 216",
        "lng": "-82.180794",
        "State": "Virginia",
        "NameOrID": "Fleenor, Ernest Howard",
        "City": "Briston",
        "Year": "1967",
        "lat": "36.598545",
        "Document": "docs/ernest-howard-fleenor.pdf"
    },
    {
        "TroopNumber": "Pack 233",
        "lng": "-121.443291",
        "State": "California",
        "NameOrID": "Cowling, Billie Richard",
        "City": "Rio Linda",
        "Year": "1968",
        "lat": "38.676534",
        "Document": "docs/billie-richard-cowling.pdf"
    },
    {
        "TroopNumber": "Troop 214",
        "lng": "-97.403564",
        "State": "Texas",
        "NameOrID": "Luckie, Richard M.",
        "City": "Corpus Christi",
        "Year": "1970",
        "lat": "27.79641",
        "Document": "docs/richard-m-luckie.pdf"
    },
    {
        "TroopNumber": "Explorer 214",
        "lng": "-6.57829",
        "State": "Morocco",
        "NameOrID": "Coppedge, Errol L.",
        "City": "Port Lyautey",
        "Year": "1961",
        "lat": "34.264061",
        "Document": "docs/errol-l-coppedge.pdf"
    },
    {
        "TroopNumber": "Troop 212",
        "lng": "-93.072089",
        "State": "Minnesota",
        "NameOrID": "Levang, Paul E.",
        "City": "Vadnais Heights",
        "Year": "1970",
        "lat": "45.06466",
        "Document": "docs/paul-e-levang.pdf"
    },
    {
        "TroopNumber": "Troop 228",
        "lng": "-73.990364",
        "State": "New York",
        "NameOrID": "Schneyer, Ronald W.",
        "City": "Brooklyn",
        "Year": "1965",
        "lat": "40.692455",
        "Document": "docs/ronald-w-schneyer.pdf"
    },
    {
        "TroopNumber": "Troop 212",
        "lng": "-118.192399",
        "State": "California",
        "NameOrID": "Harding, Robert L.",
        "City": "Long Beach",
        "Year": "1961",
        "lat": "33.766725",
        "Document": "docs/robert-l-harding.pdf"
    },
    {
        "TroopNumber": "Troop 210",
        "lng": "-117.412279",
        "State": "Washington",
        "NameOrID": "Cormana, William R.",
        "City": "Spokane",
        "Year": "1969",
        "lat": "47.65726",
        "Document": "docs/william-r-cormana.pdf"
    },
    {
        "TroopNumber": "Pack 226",
        "lng": "-85.766404",
        "State": "Kentucky",
        "NameOrID": "Parrish, Monroe",
        "City": "Louisville",
        "Year": "1967",
        "lat": "38.25486",
        "Document": "docs/monroe-parrish.pdf"
    },
    {
        "TroopNumber": "Troop 210",
        "lng": "-86.300629",
        "State": "Alabama",
        "NameOrID": "Andrews, Zachary D.",
        "City": "Montgomery",
        "Year": "1961",
        "lat": "32.38012",
        "Document": "docs/zachary-d-andrews.pdf"
    },
    {
        "TroopNumber": "Troop 223",
        "lng": "-97.403564",
        "State": "Texas",
        "NameOrID": "Goff, Bill",
        "City": "Corpus Christi",
        "Year": "1969",
        "lat": "27.79641",
        "Document": "docs/bill-goff.pdf"
    },
    {
        "TroopNumber": "Troop 203",
        "lng": "-73.990364",
        "State": "New York",
        "NameOrID": "Kelly, Robert J.",
        "City": "Brooklyn",
        "Year": "1964",
        "lat": "40.692455",
        "Document": "docs/robert-j-kelly.pdf"
    },
    {
        "TroopNumber": "Troop 201",
        "lng": "-84.612949",
        "State": "Ohio",
        "NameOrID": "Haskamp, Dennis J.",
        "City": "Cheviot",
        "Year": "1969",
        "lat": "39.156755",
        "Document": "docs/dennis-j-haskamp.pdf"
    },
    {
        "TroopNumber": "Troop 201",
        "lng": "-110.969754",
        "State": "Arizona",
        "NameOrID": "Wilbur, Frank",
        "City": "Tucson",
        "Year": "1965",
        "lat": "32.221553",
        "Document": "docs/frank-wilbur.pdf"
    },
    {
        "TroopNumber": "Troop 197",
        "lng": "-73.229624",
        "State": "Connecticut",
        "NameOrID": "Gagnon, Patrick",
        "City": "Fairfield",
        "Year": "1969",
        "lat": "41.165815",
        "Document": "docs/patrick-gagnon.pdf"
    },
    {
        "TroopNumber": "Troop 201",
        "lng": "-122.273024",
        "State": "California",
        "NameOrID": "Dohm, Charles Bert",
        "City": "Oakland",
        "Year": "1966",
        "lat": "37.805065",
        "Document": "docs/charles-bert-dohm.pdf"
    },
    {
        "TroopNumber": "Troop 193",
        "lng": "-83.752414",
        "State": "Ohio",
        "NameOrID": "Coleman, Ralph L.",
        "City": "Urbana",
        "Year": "1970",
        "lat": "40.108845",
        "Document": "docs/ralph-l-coleman.pdf"
    },
    {
        "TroopNumber": "Pack 190",
        "lng": "",
        "State": "",
        "NameOrID": "Pepper, Floyd Milton",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/floyd-milton-pepper.pdf"
    },
    {
        "TroopNumber": "Explorer 173",
        "lng": "-67.866334",
        "State": "Maine",
        "NameOrID": "Graves, Gene Vincent",
        "City": "Mars Hill",
        "Year": "1964",
        "lat": "46.513125",
        "Document": "docs/gene-vincent-graves.pdf"
    },
    {
        "TroopNumber": "Explorer 188",
        "lng": "-89.864303",
        "State": "Louisiana",
        "NameOrID": "Sullivan, Don M.",
        "City": "Bogalusa",
        "Year": "1962",
        "lat": "30.79062",
        "Document": "docs/don-m-sullivan.pdf"
    },
    {
        "TroopNumber": "Troop 171",
        "lng": "-77.001414",
        "State": "Pennsylvania",
        "NameOrID": "Fetzer, Alvie Hobert",
        "City": "Williamsport",
        "Year": "1969",
        "lat": "41.240945",
        "Document": "docs/alvie-hobert-fetzer-jr.pdf"
    },
    {
        "TroopNumber": "Troop 170",
        "lng": "-80.053374",
        "State": "Florida",
        "NameOrID": "Van Kleeck, Glen A",
        "City": "Juno Beach",
        "Year": "1970",
        "lat": "26.8797819",
        "Document": "docs/glen-a-van-kleeck.pdf"
    },
    {
        "TroopNumber": "Troop 169",
        "lng": "-75.297551",
        "State": "Pennsylvania",
        "NameOrID": "Riner, Richard L.",
        "City": "Norwood",
        "Year": "1962",
        "lat": "39.892183",
        "Document": "docs/richard-l-riner.pdf"
    },
    {
        "TroopNumber": "Troop 186",
        "lng": "-78.918354",
        "State": "Pennsylvania",
        "NameOrID": "Holtzman, Harry Patrick",
        "City": "Johnstown",
        "Year": "1962",
        "lat": "40.32726",
        "Document": "docs/harry-patrick-holtzman.pdf"
    },
    {
        "TroopNumber": "Troop 169",
        "lng": "-80.143564",
        "State": "Florida",
        "NameOrID": "Bush, James",
        "City": "Ft. Lauderdale",
        "Year": "1970",
        "lat": "26.12367",
        "Document": "docs/james-bush.pdf"
    },
    {
        "TroopNumber": "Troop 167",
        "lng": "-117.936159",
        "State": "California",
        "NameOrID": "Ulvilden, John Rainhardt",
        "City": "Garden Grove",
        "Year": "1960",
        "lat": "33.77607",
        "Document": "docs/john-rainhardt-ulvilden.pdf"
    },
    {
        "TroopNumber": "Troop 186",
        "lng": "-78.918354",
        "State": "Pennsylvania",
        "NameOrID": "Updyke, Robert B.",
        "City": "Johnstown",
        "Year": "1962",
        "lat": "40.32726",
        "Document": "docs/robert-b-updyke.pdf"
    },
    {
        "TroopNumber": "Pack 167",
        "lng": "-112.075774",
        "State": "Arizona",
        "NameOrID": "Sargent, Thomas George",
        "City": "Phoenix",
        "Year": "1970",
        "lat": "33.44826",
        "Document": "docs/thomas-george-sargent.pdf"
    },
    {
        "TroopNumber": "Troop 185",
        "lng": "-97.107719",
        "State": "Texas",
        "NameOrID": "Smith, Howell A.",
        "City": "Arlington",
        "Year": "1966",
        "lat": "32.7356",
        "Document": "docs/howell-a-smith.pdf"
    },
    {
        "TroopNumber": "Troop 160",
        "lng": "-75.296474",
        "State": "Pennsylvania",
        "NameOrID": "Mossburg, Philip L.",
        "City": "Drexel Hill",
        "Year": "1966",
        "lat": "39.949",
        "Document": "docs/philip-l-mossburg.pdf"
    },
    {
        "TroopNumber": "Troop 158",
        "lng": "-86.41843",
        "State": "Michigan",
        "NameOrID": "Sykes, Robert James",
        "City": "Kincheloe AFB",
        "Year": "1966",
        "lat": "44.94351",
        "Document": "docs/robert-james-sykes.pdf"
    },
    {
        "TroopNumber": "Troop ",
        "lng": "-93.27477",
        "State": "Minnesota",
        "NameOrID": "Sykes, Robert James",
        "City": "Burnsville",
        "Year": "1966",
        "lat": "44.765852",
        "Document": "docs/robert-james-sykes.pdf"
    },
    {
        "TroopNumber": "Troop 158",
        "lng": "-117.01666",
        "State": "Idaho",
        "NameOrID": "Christy, Vaughn McKay",
        "City": "Lewiston",
        "Year": "1962",
        "lat": "46.418657",
        "Document": "docs/vaughn-mckay-christy.pdf"
    },
    {
        "TroopNumber": "Troop 157",
        "lng": "-117.874307",
        "State": "California",
        "NameOrID": "Hurt, Richard Arlie",
        "City": "Santa Ana",
        "Year": "1962",
        "lat": "33.749588",
        "Document": "docs/richard-arlie-hurt.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 180",
        "lng": "-85.766404",
        "State": "Kentucky",
        "NameOrID": "Weeter, John C.",
        "City": "Louisville",
        "Year": "1966",
        "lat": "38.25486",
        "Document": "docs/john-c-weeter.pdf"
    },
    {
        "TroopNumber": "Troop 155",
        "lng": "-117.850399",
        "State": "California",
        "NameOrID": "Cormier, Leo Adrien",
        "City": "Orange",
        "Year": "1966",
        "lat": "33.787865",
        "Document": "docs/leo-adrien-cormier.pdf"
    },
    {
        "TroopNumber": "Pack 154",
        "lng": "-88.312844",
        "State": "Illinois",
        "NameOrID": "Schon, Fred Eugene",
        "City": "St. Charles",
        "Year": "1965",
        "lat": "41.913765",
        "Document": "docs/fred-eugene-schon-ii.pdf"
    },
    {
        "TroopNumber": "Troop 175",
        "lng": "-78.194199",
        "State": "New York",
        "NameOrID": "Parker, William Charles",
        "City": "Barre Center",
        "Year": "1968",
        "lat": "43.1861",
        "Document": "docs/william-charles-parker.pdf"
    },
    {
        "TroopNumber": "Pack 151",
        "lng": "-75.359539",
        "State": "Pennsylvania",
        "NameOrID": "Winslow, William H.",
        "City": "Broomall",
        "Year": "1963",
        "lat": "39.98062",
        "Document": "docs/william-h-winslow.pdf"
    },
    {
        "TroopNumber": "Explorer 138",
        "lng": "-89.587764",
        "State": "Illinois",
        "NameOrID": "Findlan, Glen",
        "City": "Peoria",
        "Year": "1968",
        "lat": "40.69214",
        "Document": "docs/glen-findlan.pdf"
    },
    {
        "TroopNumber": "Troop 149",
        "lng": "-84.373166",
        "State": "Ohio",
        "NameOrID": "Cordes, Kenneth M.",
        "City": "Mariemont",
        "Year": "1963",
        "lat": "39.144694",
        "Document": "docs/kenneth-m-cordes.pdf"
    },
    {
        "TroopNumber": "Troop 137",
        "lng": "-77.189499",
        "State": "New York",
        "NameOrID": "Miller, Eugene D.",
        "City": "Marion",
        "Year": "1960",
        "lat": "43.142605",
        "Document": "docs/eugene-d-miller.pdf"
    },
    {
        "TroopNumber": "Troop 147",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Bailey, Jerry",
        "City": "Jacksonville",
        "Year": "1964",
        "lat": "30.33147",
        "Document": "docs/jerry-bailey.pdf"
    },
    {
        "TroopNumber": "Pack 135",
        "lng": "-94.583062",
        "State": "Missouri",
        "NameOrID": "Derby, Donald Ray",
        "City": "Kansas City",
        "Year": "1968",
        "lat": "39.10296",
        "Document": "docs/donald-ray-derby.pdf"
    },
    {
        "TroopNumber": "Troop 134",
        "lng": "-88.010159",
        "State": "Wisconsin",
        "NameOrID": "Wicke, William",
        "City": "Green Bay",
        "Year": "1964",
        "lat": "44.512795",
        "Document": "docs/william-wicke.pdf"
    },
    {
        "TroopNumber": "Pack 146",
        "lng": "-76.609264",
        "State": "Maryland",
        "NameOrID": "Carrigan, Harry B.",
        "City": "Baltimore",
        "Year": "1967",
        "lat": "39.290585",
        "Document": "docs/harry-b-carrigan.pdf"
    },
    {
        "TroopNumber": "Troop 144",
        "lng": "-122.329439",
        "State": "Washington",
        "NameOrID": "Skoglund, Royce Arlen",
        "City": "Seattle",
        "Year": "1965",
        "lat": "47.60356",
        "Document": "docs/royce-arlen-skoglund.pdf"
    },
    {
        "TroopNumber": "Troop 133",
        "lng": "-93.220111",
        "State": "Louisiana",
        "NameOrID": "Legare, William R.",
        "City": "Lake Charles",
        "Year": "1967",
        "lat": "30.224033",
        "Document": "docs/william-r-legare.pdf"
    },
    {
        "TroopNumber": "Troop 143",
        "lng": "-120.157359",
        "State": "California",
        "NameOrID": "Enriquez, Nelson",
        "City": "South Lake Tahoe",
        "Year": "1967",
        "lat": "39.143185",
        "Document": "docs/nelson-enriquez.pdf"
    },
    {
        "TroopNumber": "Troop 139",
        "lng": "",
        "State": "",
        "NameOrID": "Boudreaux, Joseph D.",
        "City": "",
        "Year": "1965",
        "lat": "",
        "Document": "docs/joseph-d-boudreaux.pdf"
    },
    {
        "TroopNumber": "Troop 138",
        "lng": "-90.461529",
        "State": "Illinois",
        "NameOrID": "Varner, Gerald W.",
        "City": "Coal Valley",
        "Year": "1960",
        "lat": "41.428785",
        "Document": "docs/gerald-w-varner.pdf"
    },
    {
        "TroopNumber": "Troop 127",
        "lng": "6.875665",
        "State": "West Germany",
        "NameOrID": "Iveans, Gerald D.",
        "City": "Nelligen Base",
        "Year": "1965",
        "lat": "50.825775",
        "Document": "docs/gerald-d-iveans.pdf"
    },
    {
        "TroopNumber": "Troop 127",
        "lng": "-97.79567",
        "State": "Texas",
        "NameOrID": "Jackson, Jon S.",
        "City": "Ft. Hood",
        "Year": "1966",
        "lat": "31.12258",
        "Document": "docs/jon-s-jackson.pdf"
    },
    {
        "TroopNumber": "Troop 124",
        "lng": "127.132877",
        "State": "Japan",
        "NameOrID": "Clegg, Wayne D.",
        "City": "Kadena AFB, Okinawa",
        "Year": "1969",
        "lat": "25.965785",
        "Document": "docs/wayne-d-clegg.pdf"
    },
    {
        "TroopNumber": "Troop 115",
        "lng": "-110.969754",
        "State": "Arizona",
        "NameOrID": "Fousse, George",
        "City": "Tucson",
        "Year": "1966",
        "lat": "32.221553",
        "Document": "docs/george-fousse.pdf"
    },
    {
        "TroopNumber": "Troop 115",
        "lng": "-93.264929",
        "State": "Minnesota",
        "NameOrID": "Beck, Irving W.",
        "City": "Minneapolis",
        "Year": "1966",
        "lat": "44.979035",
        "Document": "docs/irving-w-beck.pdf"
    },
    {
        "TroopNumber": "Troop 115",
        "lng": "-73.697731",
        "State": "New York",
        "NameOrID": "O'Hara, Michael J.",
        "City": "Hewlett",
        "Year": "1966",
        "lat": "40.641775",
        "Document": "docs/michael-j-ohara.pdf"
    },
    {
        "TroopNumber": "Troop 123",
        "lng": "-84.280649",
        "State": "Florida",
        "NameOrID": "Lockhart, John",
        "City": "Tallahassee",
        "Year": "1962",
        "lat": "30.439775",
        "Document": "docs/john-lockhart.pdf"
    },
    {
        "TroopNumber": "Troop 114",
        "lng": "-89.645064",
        "State": "Wisconsin",
        "NameOrID": "Campbell, Lee A.",
        "City": "Monroe",
        "Year": "1961",
        "lat": "42.603021",
        "Document": "docs/lee-a-campbell.pdf"
    },
    {
        "TroopNumber": "Troop 123",
        "lng": "-74.14964",
        "State": "New York",
        "NameOrID": "Hughes, Tyrone Charles",
        "City": "Suffern",
        "Year": "1965",
        "lat": "41.114385",
        "Document": "docs/tyrone-charles-hughes.pdf"
    },
    {
        "TroopNumber": "Troop 114",
        "lng": "-74.209909",
        "State": "New Jersey",
        "NameOrID": "Stokes, Paul Kenneth",
        "City": "East Orange",
        "Year": "1968",
        "lat": "40.761",
        "Document": "docs/paul-kenneth-stokes.pdf"
    },
    {
        "TroopNumber": "Troop 123",
        "lng": "-122.712427",
        "State": "California",
        "NameOrID": "Verloop, Mark",
        "City": "Santa Rosa",
        "Year": "1969",
        "lat": "38.437731",
        "Document": "docs/mark-verloop.pdf"
    },
    {
        "TroopNumber": "Pack 111",
        "lng": "",
        "State": "",
        "NameOrID": "Verneuy, Melvin G.",
        "City": "Naha AFB",
        "Year": "1964",
        "lat": "",
        "Document": "docs/melvin-g-verneuy.pdf"
    },
    {
        "TroopNumber": "Troop 110",
        "lng": "-73.848119",
        "State": "New York",
        "NameOrID": "Pendleton, Jon Douglas",
        "City": "Wiccopee",
        "Year": "1967",
        "lat": "41.5397",
        "Document": "docs/jon-douglas-pendleton.pdf"
    },
    {
        "TroopNumber": "Troop 121",
        "lng": "-157.526855",
        "State": "Hawaii",
        "NameOrID": "Kubic, Jerome W.",
        "City": "Barber's Point",
        "Year": "1965",
        "lat": "20.573655",
        "Document": "docs/jerome-w-kubic.pdf"
    },
    {
        "TroopNumber": "Explorer 109",
        "lng": "",
        "State": "",
        "NameOrID": "Schindler, Robert Andrew",
        "City": "",
        "Year": "1964",
        "lat": "",
        "Document": "docs/robert-andrew-schindler.pdf"
    },
    {
        "TroopNumber": "Troop 107",
        "lng": "-80.052689",
        "State": "Florida",
        "NameOrID": "Watson, Harry R.",
        "City": "West Palm Beach",
        "Year": "1961",
        "lat": "26.714388",
        "Document": "docs/harry-r-watson-iii.pdf"
    },
    {
        "TroopNumber": "Troop 106",
        "lng": "-86.012239",
        "State": "Indiana",
        "NameOrID": "Heflin, Robert C.",
        "City": "Noblesville",
        "Year": "1969",
        "lat": "40.04515",
        "Document": "docs/robert-c-heflin.pdf"
    },
    {
        "TroopNumber": "Troop 105",
        "lng": "-118.446517",
        "State": "California",
        "NameOrID": "Brown, Stephen I.",
        "City": "Van Nuys",
        "Year": "1969",
        "lat": "34.18416",
        "Document": "docs/stephen-i-brown.pdf"
    },
    {
        "TroopNumber": "Troop 97",
        "lng": "-154.565033",
        "State": "Alaska",
        "NameOrID": "Goodwin, Elmer",
        "City": "Kotzebue",
        "Year": "1965",
        "lat": "61.325384",
        "Document": "docs/elmer-goodwin.pdf"
    },
    {
        "TroopNumber": "Troop 101",
        "lng": "-71.463089",
        "State": "New Hampshire",
        "NameOrID": "Doiron, Wilfred D.",
        "City": "Manchester",
        "Year": "1965",
        "lat": "42.991175",
        "Document": "docs/wilfred-d-doiron.pdf"
    },
    {
        "TroopNumber": "Troop 97",
        "lng": "-87.337154",
        "State": "Indiana",
        "NameOrID": "McKinlay, LaVon",
        "City": "Gary",
        "Year": "1964",
        "lat": "41.60299",
        "Document": "docs/lavon-mckinlay.pdf"
    },
    {
        "TroopNumber": "Troop 110",
        "lng": "-71.463089",
        "State": "New Hampshire",
        "NameOrID": "Doiron, Wilfred D.",
        "City": "Manchester",
        "Year": "1965",
        "lat": "42.991175",
        "Document": "docs/wilfred-d-doiron.pdf"
    },
    {
        "TroopNumber": "Troop 96",
        "lng": "-122.285219",
        "State": "California",
        "NameOrID": "Igling, Harry Cecil",
        "City": "Napa",
        "Year": "1965",
        "lat": "38.29886",
        "Document": "docs/harry-cecil-igling-jr.pdf"
    },
    {
        "TroopNumber": "Pack 101",
        "lng": "-75.470204",
        "State": "Pennsylvania",
        "NameOrID": "Lindenmuth, Richard F.",
        "City": "Allentown",
        "Year": "1968",
        "lat": "40.60257",
        "Document": "docs/richard-f-lindenmuth.pdf"
    },
    {
        "TroopNumber": "Troop 93",
        "lng": "-90.049259",
        "State": "Tennessee",
        "NameOrID": "Jones, Dean",
        "City": "Memphis",
        "Year": "1960",
        "lat": "35.149766",
        "Document": "docs/dean-jones.pdf"
    },
    {
        "TroopNumber": "Troop 92",
        "lng": "-78.591557",
        "State": "Pennsylvania",
        "NameOrID": "Anderson, Harry James",
        "City": "Cresson",
        "Year": "1969",
        "lat": "40.460022",
        "Document": "docs/harry-james-anderson.pdf"
    },
    {
        "TroopNumber": "Troop 92",
        "lng": "-84.932719",
        "State": "Georgia",
        "NameOrID": "Willis, Terrell",
        "City": "Blakely",
        "Year": "1966",
        "lat": "31.37763",
        "Document": "docs/terrell-willis.pdf"
    },
    {
        "TroopNumber": "Troop 100",
        "lng": "2.7012",
        "State": "France",
        "NameOrID": "Malone, James E.",
        "City": "Fountaineblau",
        "Year": "1966",
        "lat": "48.405041",
        "Document": "docs/james-e-malone.pdf"
    },
    {
        "TroopNumber": "Troop 89",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Frie, George O.",
        "City": "Portland",
        "Year": "1966",
        "lat": "45.511795",
        "Document": "docs/george-o-frie-jr.pdf"
    },
    {
        "TroopNumber": "Troop 100",
        "lng": "-71.063401",
        "State": "Massachusetts",
        "NameOrID": "Farrell, William J.",
        "City": "Dorchester",
        "Year": "1960",
        "lat": "42.307878",
        "Document": "docs/william-j-farrell.pdf"
    },
    {
        "TroopNumber": "Pack 88",
        "lng": "-115.118549",
        "State": "Nevada",
        "NameOrID": "Duvan, James",
        "City": "North Las Vegas",
        "Year": "1967",
        "lat": "36.198965",
        "Document": "docs/james-duvan.pdf"
    },
    {
        "TroopNumber": "Explorer 88",
        "lng": "-86.272619",
        "State": "Michigan",
        "NameOrID": "Henricksen, David O.",
        "City": "South Haven",
        "Year": "1963",
        "lat": "42.40321",
        "Document": "docs/david-o-henricksen.pdf"
    },
    {
        "TroopNumber": "Troop 99",
        "lng": "-117.373879",
        "State": "California",
        "NameOrID": "Busher, Gary Edison",
        "City": "Riverside",
        "Year": "1966",
        "lat": "33.98163",
        "Document": "docs/gary-edison-busher.pdf"
    },
    {
        "TroopNumber": "Explorer 87",
        "lng": "-106.648639",
        "State": "New Mexico",
        "NameOrID": "Kennedy, George Ivor",
        "City": "Albuquerque",
        "Year": "1968",
        "lat": "35.08418",
        "Document": "docs/george-ivor-kennedy.pdf"
    },
    {
        "TroopNumber": "Troop 99",
        "lng": "-82.122752",
        "State": "Georgia",
        "NameOrID": "Tomczak, William Alfred",
        "City": "Ft. Gordon",
        "Year": "1964",
        "lat": "33.398926",
        "Document": "docs/william-alfred-tomczak.pdf"
    },
    {
        "TroopNumber": "Troop 83",
        "lng": "-78.878464",
        "State": "New York",
        "NameOrID": "Hodge, Donald A.",
        "City": "Buffalo",
        "Year": "1965",
        "lat": "42.88544",
        "Document": "docs/donald-a-hodge.pdf"
    },
    {
        "TroopNumber": "Troop 99",
        "lng": "-82.122752",
        "State": "Georgia",
        "NameOrID": "Jones, Carl Robert",
        "City": "Ft. Gordon",
        "Year": "1967",
        "lat": "33.398926",
        "Document": "docs/carl-robert-jones.pdf"
    },
    {
        "TroopNumber": "Pack 83",
        "lng": "",
        "State": "",
        "NameOrID": "Branham, Robert Jason",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/robert-jason-branham.pdf"
    },
    {
        "TroopNumber": "Troop 99",
        "lng": "-88.006186",
        "State": "Illinois",
        "NameOrID": "Scanlan, Robert H.",
        "City": "Downers Grove",
        "Year": "1967",
        "lat": "41.79505",
        "Document": "docs/robert-h-scanlan.pdf"
    },
    {
        "TroopNumber": "Troop 81",
        "lng": "-93.389889",
        "State": "Iowa",
        "NameOrID": "Gregory, Don J.",
        "City": "Bettendorf",
        "Year": "1970",
        "lat": "41.93825",
        "Document": "docs/don-j-gregory.pdf"
    },
    {
        "TroopNumber": "Troop 80",
        "lng": "-123.088544",
        "State": "Oregon",
        "NameOrID": "Hester, Ferol Curtis",
        "City": "Eugene",
        "Year": "1964",
        "lat": "44.04992",
        "Document": "docs/ferol-curtis-hester.pdf"
    },
    {
        "TroopNumber": "Pack 78",
        "lng": "-87.839169",
        "State": "Illinois",
        "NameOrID": "Harris, J. D.",
        "City": "Maywood",
        "Year": "1962",
        "lat": "41.887115",
        "Document": "docs/j-d-harris.pdf"
    },
    {
        "TroopNumber": "Troop 70",
        "lng": "-118.245319",
        "State": "California",
        "NameOrID": "Gray, Eugene W.",
        "City": "Los Angeles",
        "Year": "1965",
        "lat": "34.05349",
        "Document": "docs/eugene-w-gray.pdf"
    },
    {
        "TroopNumber": "Troop 76",
        "lng": "-87.170179",
        "State": "Indiana",
        "NameOrID": "Miles, Donald E.",
        "City": "Washington",
        "Year": "1967",
        "lat": "38.657365",
        "Document": "docs/donald-e-miles.pdf"
    },
    {
        "TroopNumber": "Troop 70",
        "lng": "-74.675097",
        "State": "New York",
        "NameOrID": "Bero, Lewis John Alexander",
        "City": "Hogansburg",
        "Year": "1968",
        "lat": "44.976237",
        "Document": "docs/lewis-john-alexander-bero.pdf"
    },
    {
        "TroopNumber": "Troop 76",
        "lng": "-119.813439",
        "State": "Nevada",
        "NameOrID": "Mudge, Raymond",
        "City": "Reno",
        "Year": "1970",
        "lat": "39.527385",
        "Document": "docs/raymond-mudge.pdf"
    },
    {
        "TroopNumber": "Troop 76",
        "lng": "10.32",
        "State": "Italy",
        "NameOrID": "Atkins, Lawrence M",
        "City": "Leghorn",
        "Year": "1960",
        "lat": "43.55292",
        "Document": "docs/lawrence-m-atkins.pdf"
    },
    {
        "TroopNumber": "Troop 74",
        "lng": "-6.3481",
        "State": "Spain",
        "NameOrID": "Bartlett, Benhamin T.",
        "City": "Rota Naval Station",
        "Year": "1968",
        "lat": "36.6464",
        "Document": "docs/benhamin-t-bartlett-jr.pdf"
    },
    {
        "TroopNumber": "Pack 70",
        "lng": "-104.397559",
        "State": "New Mexico",
        "NameOrID": "Logan, Dale Lyle",
        "City": "Artesia",
        "Year": "1965",
        "lat": "32.842925",
        "Document": "docs/dale-lyle-logan.pdf"
    },
    {
        "TroopNumber": "Troop 73",
        "lng": "-85.381734",
        "State": "Indiana",
        "NameOrID": "Smith, Lafayette B.",
        "City": "Muncie",
        "Year": "1970",
        "lat": "40.193165",
        "Document": "docs/lafayette-b-smith.pdf"
    },
    {
        "TroopNumber": "Troop 69",
        "lng": "-84.939992",
        "State": "Indiana",
        "NameOrID": "Tobias, Glenn Richard",
        "City": "Lynn",
        "Year": "1969",
        "lat": "40.04889",
        "Document": "docs/glenn-richard-tobias.pdf"
    },
    {
        "TroopNumber": "Troop 72",
        "lng": "-111.937419",
        "State": "Arizona",
        "NameOrID": "Gonzales, Gilbert M.",
        "City": "Tempe",
        "Year": "1966",
        "lat": "33.42551",
        "Document": "docs/gilbert-m-gonzales.pdf"
    },
    {
        "TroopNumber": "Troop 71",
        "lng": "-86.876554",
        "State": "Indiana",
        "NameOrID": "Dierker, George A.",
        "City": "Lafayette",
        "Year": "1968",
        "lat": "40.41737",
        "Document": "docs/george-a-dierker.pdf"
    },
    {
        "TroopNumber": "Pack 71",
        "lng": "-117.874307",
        "State": "California",
        "NameOrID": "Montiel, Valente R.",
        "City": "Santa Ana",
        "Year": "1965",
        "lat": "33.749588",
        "Document": "docs/valente-r-montiel.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-74.075283",
        "State": "New York",
        "NameOrID": "Graf, Peter D.",
        "City": "Staten Island",
        "Year": "1961",
        "lat": "40.64244",
        "Document": "docs/peter-d-graf.pdf"
    },
    {
        "TroopNumber": "Troop 65",
        "lng": "-93.929379",
        "State": "Texas",
        "NameOrID": "Stone, William E.",
        "City": "Port Arthur",
        "Year": "1967",
        "lat": "29.89932",
        "Document": "docs/william-e-stone-jr.pdf"
    },
    {
        "TroopNumber": "Troop 67",
        "lng": "-88.053239",
        "State": "Alabama",
        "NameOrID": "Midgette, Benjamin Franklin",
        "City": "Mobile",
        "Year": "1965",
        "lat": "30.686395",
        "Document": "docs/benjamin-franklin-midgette.pdf"
    },
    {
        "TroopNumber": "Troop 67",
        "lng": "-90.920744",
        "State": "Louisiana",
        "NameOrID": "Richard, Larry",
        "City": "Gonzales",
        "Year": "1964",
        "lat": "30.23869",
        "Document": "docs/larry-richard.pdf"
    },
    {
        "TroopNumber": "Pack 67",
        "lng": "-81.690434",
        "State": "Ohio",
        "NameOrID": "Gallagher, Leonard S.",
        "City": "Cleveland",
        "Year": "1960",
        "lat": "41.50438",
        "Document": "docs/leonard-s-gallagher.pdf"
    },
    {
        "TroopNumber": "Pack 67",
        "lng": "-85.95295",
        "State": "Alabama",
        "NameOrID": "Atkinson, Bernard Colie",
        "City": "Alexander City",
        "Year": "1969",
        "lat": "32.938845",
        "Document": "docs/bernard-colie-atkinson.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-104.612374",
        "State": "Colorado",
        "NameOrID": "Eddy, James R.",
        "City": "Pueblo",
        "Year": "1964",
        "lat": "38.26386",
        "Document": "docs/james-r-eddy.pdf"
    },
    {
        "TroopNumber": "Troop 66",
        "lng": "-75.162454",
        "State": "Pennsylvania",
        "NameOrID": "Clark, Arthur F.",
        "City": "Philadelphia",
        "Year": "1965",
        "lat": "39.95228",
        "Document": "docs/arthur-f-clark.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-86.207249",
        "State": "Michigan",
        "NameOrID": "Peterson, James",
        "City": "Onekama",
        "Year": "1970",
        "lat": "44.363695",
        "Document": "docs/james-peterson.pdf"
    },
    {
        "TroopNumber": "Troop 66",
        "lng": "-79.931604",
        "State": "South Carolina",
        "NameOrID": "O'Tuell, Alton Louis",
        "City": "Charleston",
        "Year": "1966",
        "lat": "32.78115",
        "Document": "docs/alton-louis-otuell.pdf"
    },
    {
        "TroopNumber": "Troop 63",
        "lng": "-82.578074",
        "State": "Florida",
        "NameOrID": "Trombley, Dale Virgil",
        "City": "Bradenton",
        "Year": "1965",
        "lat": "27.4955",
        "Document": "docs/dale-virgil-trombley.pdf"
    },
    {
        "TroopNumber": "Troop 62",
        "lng": "-120.691261",
        "State": "Connecticut",
        "NameOrID": "Nabors, Gary Mitchell",
        "City": "Paso Robles",
        "Year": "1967",
        "lat": "35.625092",
        "Document": "docs/gary-mitchell-nabors.pdf"
    },
    {
        "TroopNumber": "Troop 57",
        "lng": "-71.056699",
        "State": "Massachusetts",
        "NameOrID": "Thomson, Lincoln T.",
        "City": "Boston",
        "Year": "1969",
        "lat": "42.358635",
        "Document": "docs/lincoln-t-thomson.pdf"
    },
    {
        "TroopNumber": "Troop 61",
        "lng": "-81.144319",
        "State": "Ohio",
        "NameOrID": "Dalton, Lee",
        "City": "Hiram",
        "Year": "1966",
        "lat": "41.309455",
        "Document": "docs/lee-dalton.pdf"
    },
    {
        "TroopNumber": "Troop 57",
        "lng": "-86.811504",
        "State": "Alabama",
        "NameOrID": "Stabler, Lemmie Clyde",
        "City": "Birmingham",
        "Year": "1968",
        "lat": "33.520295",
        "Document": "docs/lemmie-clyde-stabler.pdf"
    },
    {
        "TroopNumber": "Troop 60",
        "lng": "-71.802189",
        "State": "Massachusetts",
        "NameOrID": "Morin, Alfred",
        "City": "Worchester",
        "Year": "1961",
        "lat": "42.263415",
        "Document": "docs/alfred-morin.pdf"
    },
    {
        "TroopNumber": "Troop 59",
        "lng": "-76.609264",
        "State": "Maryland",
        "NameOrID": "Freeman, Robert Walker",
        "City": "Baltimore",
        "Year": "1963",
        "lat": "39.290585",
        "Document": "docs/robert-walker-freeman.pdf"
    },
    {
        "TroopNumber": "Troop 55",
        "lng": "-78.391474",
        "State": "Pennsylvania",
        "NameOrID": "Hamm, Richard G.",
        "City": "Hollidaysburg",
        "Year": "1965",
        "lat": "40.427865",
        "Document": "docs/richard-g-hamm.pdf"
    },
    {
        "TroopNumber": "Troop 55",
        "lng": "-119.5033",
        "State": "California",
        "NameOrID": "Doner, Paul A.",
        "City": "Carpenteria",
        "Year": "1962",
        "lat": "34.389796",
        "Document": "docs/paul-a-doner.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 58",
        "lng": "-84.489554",
        "State": "Kentucky",
        "NameOrID": "Keen, William C.",
        "City": "Newport ",
        "Year": "1962",
        "lat": "39.08776",
        "Document": "docs/william-c-keen.pdf"
    },
    {
        "TroopNumber": "Troop 54",
        "lng": "-93.467233",
        "State": "Louisiana",
        "NameOrID": "Tinker, Frank E.",
        "City": "Springhill",
        "Year": "1965",
        "lat": "33.006595",
        "Document": "docs/frank-e-tinker.pdf"
    },
    {
        "TroopNumber": "Pack 54",
        "lng": "-77.717579",
        "State": "Maryland",
        "NameOrID": "Shilling, Joseph Randolph",
        "City": "Hagerstown",
        "Year": "1964",
        "lat": "39.6425",
        "Document": "docs/joseph-randolph-shilling.pdf"
    },
    {
        "TroopNumber": "Troop 53",
        "lng": "-73.829959",
        "State": "New York",
        "NameOrID": "Montgomery, John E.",
        "City": "Queens",
        "Year": "1963",
        "lat": "40.713985",
        "Document": "docs/john-e-montgomery.pdf"
    },
    {
        "TroopNumber": "Troop 50",
        "lng": "-75.073351",
        "State": "New Jersey",
        "NameOrID": "Wilson, Harry W.",
        "City": "Runnemede",
        "Year": "1961",
        "lat": "39.853282",
        "Document": "docs/harry-w-wilson.pdf"
    },
    {
        "TroopNumber": "Pack 53",
        "lng": "-120.669064",
        "State": "California",
        "NameOrID": "O'Neal, Carlisle J.",
        "City": "Atascadero",
        "Year": "1961",
        "lat": "35.488695",
        "Document": "docs/carlisle-j-oneal.pdf"
    },
    {
        "TroopNumber": "Troop 51",
        "lng": "-123.442509",
        "State": "California",
        "NameOrID": "Elton, William Francis",
        "City": "Philo",
        "Year": "1969",
        "lat": "39.06642",
        "Document": "docs/william-francis-elton.pdf"
    },
    {
        "TroopNumber": "Troop 45",
        "lng": "-122.441654",
        "State": "Washington",
        "NameOrID": "Potter, Kenneth Leroy",
        "City": "Tacoma",
        "Year": "1963",
        "lat": "47.255135",
        "Document": "docs/kenneth-leroy-potter.pdf"
    },
    {
        "TroopNumber": "Troop 51",
        "lng": "-82.734947",
        "State": "Missouri",
        "NameOrID": "Lawson, George P.",
        "City": "Deckerville",
        "Year": "1967",
        "lat": "43.52721",
        "Document": "docs/george-p-lawson.pdf"
    },
    {
        "TroopNumber": "Troop 50",
        "lng": "-74.193789",
        "State": "New Jersey",
        "NameOrID": "Pratt, William R.",
        "City": "Toms River",
        "Year": "1961",
        "lat": "39.952885",
        "Document": "docs/william-r-pratt.pdf"
    },
    {
        "TroopNumber": "Explorer 45",
        "lng": "-74.043234",
        "State": "New Jersey",
        "NameOrID": "Faubion, Joseph T.",
        "City": "Jersey City",
        "Year": "1969",
        "lat": "40.7174",
        "Document": "docs/joseph-t-faubion.pdf"
    },
    {
        "TroopNumber": "Troop 43",
        "lng": "-78.398904",
        "State": "Pennsylvania",
        "NameOrID": "Espenlaub, William Eugene",
        "City": "Altoona",
        "Year": "1967",
        "lat": "40.50719",
        "Document": "docs/william-eugene-espenlaub.pdf"
    },
    {
        "TroopNumber": "Troop 36",
        "lng": "-74.664644",
        "State": "New Jersey",
        "NameOrID": "Grover, Charles J.",
        "City": "Yardville",
        "Year": "1961",
        "lat": "40.180765",
        "Document": "docs/charles-j-grover.pdf"
    },
    {
        "TroopNumber": "Troop 36",
        "lng": "-96.771664",
        "State": "Minnesota",
        "NameOrID": "Kise, Walter",
        "City": "Moorhead",
        "Year": "1960",
        "lat": "46.875045",
        "Document": "docs/walter-kise.pdf"
    },
    {
        "TroopNumber": "Explorer 36",
        "lng": "-83.806734",
        "State": "Ohio",
        "NameOrID": "Michael, Raymond Porter",
        "City": "Springfield",
        "Year": "1968",
        "lat": "39.92502",
        "Document": "docs/raymond-porter-michael.pdf"
    },
    {
        "TroopNumber": "Troop 35",
        "lng": "-80.947403",
        "State": "South Carolina",
        "NameOrID": "McCullough, Marion Edward",
        "City": "Charlotte",
        "Year": "1966",
        "lat": "33.626459",
        "Document": "docs/marion-edward-mccullough.pdf"
    },
    {
        "TroopNumber": "Troop 34",
        "lng": "-86.775874",
        "State": "Indiana",
        "NameOrID": "Golding, Ronald F.",
        "City": "North Judson",
        "Year": "1967",
        "lat": "41.214625",
        "Document": "docs/ronald-f-golding.pdf"
    },
    {
        "TroopNumber": "Troop 41",
        "lng": "-122.875614",
        "State": "Oregon",
        "NameOrID": "Lien, Howard Cameron",
        "City": "Medford",
        "Year": "1970",
        "lat": "42.32356",
        "Document": "docs/howard-cameron-lien.pdf"
    },
    {
        "TroopNumber": "Troop 33",
        "lng": "-121.556104",
        "State": "California",
        "NameOrID": "Graves, Gary Lester",
        "City": "Oroville",
        "Year": "1967",
        "lat": "39.51388",
        "Document": "docs/gary-lester-graves.pdf"
    },
    {
        "TroopNumber": "Troop 41",
        "lng": "-80.580674",
        "State": "Ohio",
        "NameOrID": "Caldwell, William",
        "City": "East Liverpool",
        "Year": "1969",
        "lat": "40.62058",
        "Document": "docs/william-caldwell.pdf"
    },
    {
        "TroopNumber": "Troop 33",
        "lng": "-73.191269",
        "State": "Connecticut",
        "NameOrID": "Joynes, Arthur",
        "City": "Bridgeport",
        "Year": "1968",
        "lat": "41.18188",
        "Document": "docs/arthur-joynes.pdf"
    },
    {
        "TroopNumber": "Troop 40",
        "lng": "-85.820559",
        "State": "Indiana",
        "NameOrID": "Dyson, Ralph Porter",
        "City": "Wabash",
        "Year": "1963",
        "lat": "40.79647",
        "Document": "docs/ralph-porter-dyson.pdf"
    },
    {
        "TroopNumber": "Troop 31",
        "lng": "-72.206332",
        "State": "Connecticut",
        "NameOrID": "Normandie, Lee F.",
        "City": "Willimantic",
        "Year": "1969",
        "lat": "41.710542",
        "Document": "docs/lee-f-normandie.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 31",
        "lng": "-81.690434",
        "State": "Ohio",
        "NameOrID": "Hawks, Claude B.",
        "City": "Cleveland",
        "Year": "1965",
        "lat": "41.50438",
        "Document": "docs/claude-b-hawks-jr.pdf"
    },
    {
        "TroopNumber": "Troop 39",
        "lng": "-96.795404",
        "State": "Texas",
        "NameOrID": "Abney, Herbert Taylor",
        "City": "Dallas",
        "Year": "1961",
        "lat": "32.778155",
        "Document": "docs/herbert-taylor-abney.pdf"
    },
    {
        "TroopNumber": "Troop 30",
        "lng": "-87.799364",
        "State": "Illinois",
        "NameOrID": "Tillman, John Adcox",
        "City": "Highland Park",
        "Year": "1962",
        "lat": "42.185665",
        "Document": "docs/john-adcox-tillman.pdf"
    },
    {
        "TroopNumber": "Troop 30",
        "lng": "-88.010159",
        "State": "Wisconsin",
        "NameOrID": "Roenfanz, Calvin",
        "City": "Green Bay",
        "Year": "1961",
        "lat": "44.512795",
        "Document": "docs/calvin-roenfanz.pdf"
    },
    {
        "TroopNumber": "Troop 38",
        "lng": "-76.798814",
        "State": "New York",
        "NameOrID": "Shope, Gary C.",
        "City": "Elmira",
        "Year": "1965",
        "lat": "42.09041",
        "Document": "docs/gary-c-shope.pdf"
    },
    {
        "TroopNumber": "Troop 29",
        "lng": "-73.829959",
        "State": "New York",
        "NameOrID": "Boresch, Michael",
        "City": "Queens ",
        "Year": "1967",
        "lat": "40.713985",
        "Document": "docs/michael-boresch.pdf"
    },
    {
        "TroopNumber": "Troop 28",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Santy, Donald L.",
        "City": "Portland",
        "Year": "1963",
        "lat": "45.511795",
        "Document": "docs/donald-l-santy.pdf"
    },
    {
        "TroopNumber": "Troop 28",
        "lng": "-122.441654",
        "State": "Washington",
        "NameOrID": "Hall, Victor John",
        "City": "Tacoma",
        "Year": "1968",
        "lat": "47.255135",
        "Document": "docs/victor-john-hall.pdf"
    },
    {
        "TroopNumber": "Pack 28",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Santy, Donald L.",
        "City": "Portland",
        "Year": "1963",
        "lat": "45.511795",
        "Document": "docs/donald-l-santy.pdf"
    },
    {
        "TroopNumber": "Troop 28",
        "lng": "-85.967299",
        "State": "Indiana",
        "NameOrID": "Armstrong, James Elmer",
        "City": "Greentown",
        "Year": "1962",
        "lat": "40.47795",
        "Document": "docs/james-elmer-armstrong.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-97.38762",
        "State": "Texas",
        "NameOrID": "Stewart, John LeRoy",
        "City": "Cleburne",
        "Year": "1965",
        "lat": "32.350398",
        "Document": "docs/john-leroy-stewart.pdf"
    },
    {
        "TroopNumber": "Troop 28",
        "lng": "-83.047924",
        "State": "Michigan",
        "NameOrID": "Parker, James Gilbert",
        "City": "Detroit",
        "Year": "1961",
        "lat": "42.331685",
        "Document": "docs/james-gilbert-parker.pdf"
    },
    {
        "TroopNumber": "Troop 22",
        "lng": "-75.550844",
        "State": "Delaware",
        "NameOrID": "Smith, Orttis Lloyd",
        "City": "Wilmington",
        "Year": "1964",
        "lat": "39.740235",
        "Document": "docs/orttis-lloyd-smith.pdf"
    },
    {
        "TroopNumber": "Troop 27",
        "lng": "-93.264929",
        "State": "Minnesota",
        "NameOrID": "McConahy, Malcolm Willis",
        "City": "Minneapolis",
        "Year": "1965",
        "lat": "44.979035",
        "Document": "docs/malcolm-willis-mcconahy.pdf"
    },
    {
        "TroopNumber": "Troop 22",
        "lng": "-71.622467",
        "State": "Massachusetts",
        "NameOrID": "Tinker, Ralph Irving",
        "City": "Uxbridge",
        "Year": "1966",
        "lat": "42.060711",
        "Document": "docs/ralph-irving-tinker.pdf"
    },
    {
        "TroopNumber": "Troop 27",
        "lng": "-112.52089",
        "State": "Montana",
        "NameOrID": "Solis, Alex",
        "City": "Butte ",
        "Year": "1962",
        "lat": "46.001753",
        "Document": "docs/alex-solis.pdf"
    },
    {
        "TroopNumber": "Troop 22",
        "lng": "-75.927843",
        "State": "Pennsylvania",
        "NameOrID": "Brendel, David",
        "City": "Reading",
        "Year": "1967",
        "lat": "40.336585",
        "Document": "docs/david-brendel.pdf"
    },
    {
        "TroopNumber": "Troop 22",
        "lng": "138.460247",
        "State": "Japan",
        "NameOrID": "Hough, David John",
        "City": "Atsugi NAS",
        "Year": "1964",
        "lat": "32.991405",
        "Document": "docs/david-john-hough.pdf"
    },
    {
        "TroopNumber": "Troop 26",
        "lng": "-73.410424",
        "State": "Connecticut",
        "NameOrID": "Dailey, David Hutchings",
        "City": "New Milford",
        "Year": "1968",
        "lat": "41.57751",
        "Document": "docs/david-hutchings-dailey.pdf"
    },
    {
        "TroopNumber": "Pack 22",
        "lng": "-104.612374",
        "State": "Colorado",
        "NameOrID": "Rhoads, Marvin Leroy",
        "City": "Pueblo",
        "Year": "1968",
        "lat": "38.26386",
        "Document": "docs/marvin-leroy-rhoads.pdf"
    },
    {
        "TroopNumber": "Troop 26",
        "lng": "-75.776814",
        "State": "New York",
        "NameOrID": "Watson, Clarence E.",
        "City": "Camp Drum",
        "Year": "1967",
        "lat": "44.035655",
        "Document": "docs/clarence-e-watson.pdf"
    },
    {
        "TroopNumber": "Troop 21",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "Mero, John A.",
        "City": "Syracuse",
        "Year": "1967",
        "lat": "43.04999",
        "Document": "docs/john-mero-jr.pdf"
    },
    {
        "TroopNumber": "Troop 21",
        "lng": "-81.656219",
        "State": "Florida",
        "NameOrID": "Agee, Walter Franklin",
        "City": "Jacksonville",
        "Year": "1964",
        "lat": "30.33147",
        "Document": "docs/walter-franklin-agee.pdf"
    },
    {
        "TroopNumber": "Troop 24",
        "lng": "-76.147389",
        "State": "New York",
        "NameOrID": "DeDell, R. Gary Jerome",
        "City": "Syracuse",
        "Year": "1967",
        "lat": "43.04999",
        "Document": "docs/r-gary-jerome-dedell.pdf"
    },
    {
        "TroopNumber": "Troop 20",
        "lng": "-84.562644",
        "State": "Ohio",
        "NameOrID": "Collins, Guy",
        "City": "Hamilton",
        "Year": "1964",
        "lat": "39.40008",
        "Document": "docs/guy-collins.pdf"
    },
    {
        "TroopNumber": "Troop 24",
        "lng": "-158.056046",
        "State": "Hawaii",
        "NameOrID": "Kamp, Michael William",
        "City": "Schofield Brooks",
        "Year": "1968",
        "lat": "21.497419",
        "Document": "docs/michael-william-kamp.pdf"
    },
    {
        "TroopNumber": "Troop 20",
        "lng": "-106.487494",
        "State": "Texas",
        "NameOrID": "Donahue, Edward Thomas",
        "City": "El Paso",
        "Year": "1965",
        "lat": "31.759165",
        "Document": "docs/edward-thomas-donahue-jr.pdf"
    },
    {
        "TroopNumber": "Troop 24",
        "lng": "-71.004164",
        "State": "Massachusetts",
        "NameOrID": "Lewis, Robert P.",
        "City": "Quincy",
        "Year": "1969",
        "lat": "42.252285",
        "Document": "docs/robert-p-lewis.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-74.465949",
        "State": "New York",
        "NameOrID": "Patnode, Joseph Lloyd",
        "City": "Tupper Lake",
        "Year": "1961",
        "lat": "44.22265",
        "Document": "docs/joseph-lloyd-patnode.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-134.406794",
        "State": "Alaska",
        "NameOrID": "Armstrong, Gerald T.",
        "City": "Juneau",
        "Year": "1961",
        "lat": "58.29974",
        "Document": "docs/gerald-t-armstrong.pdf"
    },
    {
        "TroopNumber": "Troop 23",
        "lng": "-90.663269",
        "State": "Iowa",
        "NameOrID": "Krakow, Kenneth J.",
        "City": "Dubuque",
        "Year": "1970",
        "lat": "42.49913",
        "Document": "docs/kenneth-j-krakow.pdf"
    },
    {
        "TroopNumber": "Troop 18",
        "lng": "-93.093326",
        "State": "Minnesota",
        "NameOrID": "Keys, Donald Leonard",
        "City": "St. Paul",
        "Year": "1970",
        "lat": "44.943829",
        "Document": "docs/donald-leonard-keys.pdf"
    },
    {
        "TroopNumber": "Troop 17",
        "lng": "-106.487494",
        "State": "Texas",
        "NameOrID": "Falcone, Eugene S.",
        "City": "El Paso",
        "Year": "1967",
        "lat": "31.759165",
        "Document": "docs/eugene-s-falcone.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-75.337044",
        "State": "New York",
        "NameOrID": "Oman, William Woodrow",
        "City": "Boonville",
        "Year": "1969",
        "lat": "43.482975",
        "Document": "docs/william-woodrow-oman.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "",
        "State": "",
        "NameOrID": "Thurman, Garrett N.",
        "City": "",
        "Year": "1963",
        "lat": "",
        "Document": "docs/garrett-n-thurman.pdf"
    },
    {
        "TroopNumber": "Troop 16",
        "lng": "",
        "State": "Illinois",
        "NameOrID": "Baxter, Jack L.",
        "City": "New Milford",
        "Year": "1967",
        "lat": "",
        "Document": "docs/jack-l-baxter.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-118.29097",
        "State": "California",
        "NameOrID": "Marshall, Gregory",
        "City": "Tujunga",
        "Year": "1963",
        "lat": "34.251838",
        "Document": "docs/gregory-marshall.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-101.845009",
        "State": "Texas",
        "NameOrID": "Jackson, David Marvin",
        "City": "Lubbock",
        "Year": "1964",
        "lat": "33.58451",
        "Document": "docs/david-marvin-jackson.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-89.018214",
        "State": "Wisconsin",
        "NameOrID": "Lawrence, Neil E.",
        "City": "Janesville",
        "Year": "1961",
        "lat": "42.687892",
        "Document": "docs/neil-e-lawrence.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-72.818709",
        "State": "Connecticut",
        "NameOrID": "Schultz, Curtis F.",
        "City": "Wallingford",
        "Year": "1965",
        "lat": "41.453626",
        "Document": "docs/curtis-f-schultz.pdf"
    },
    {
        "TroopNumber": "Troop 12",
        "lng": "-80.340119",
        "State": "West Virginia",
        "NameOrID": "Stewart, Ronnie Van",
        "City": "Clarksburg",
        "Year": "1969",
        "lat": "39.27942",
        "Document": "docs/ronnie-van-stewart.pdf"
    },
    {
        "TroopNumber": "Troop 14",
        "lng": "-84.500319",
        "State": "Kentucky",
        "NameOrID": "Hepburn, William D.",
        "City": "Lexington",
        "Year": "1968",
        "lat": "38.048595",
        "Document": "docs/william-d-hepburn.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-85.812648",
        "State": "Alabama",
        "NameOrID": "McDonald, Charles Jerris",
        "City": "Weaver",
        "Year": "1970",
        "lat": "33.750937",
        "Document": "docs/charles-jerris-mcdonald.pdf"
    },
    {
        "TroopNumber": "Pack 14",
        "lng": "-119.698869",
        "State": "California",
        "NameOrID": "Knutson, Kenneth N.",
        "City": "Santa Barbara",
        "Year": "1968",
        "lat": "34.419255",
        "Document": "docs/kenneth-n-knutson.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-123.043814",
        "State": "Oregon",
        "NameOrID": "Jones, Linford David",
        "City": "Salem",
        "Year": "1969",
        "lat": "44.93326",
        "Document": "docs/linford-david-jones.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-117.373879",
        "State": "California",
        "NameOrID": "Boyd, John C.",
        "City": "Riverside",
        "Year": "1963",
        "lat": "33.98163",
        "Document": "docs/john-c-boyd.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-82.428449",
        "State": "Michigan",
        "NameOrID": "Keltz, Leo S.",
        "City": "Port Huron",
        "Year": "1960",
        "lat": "42.97583",
        "Document": "docs/leo-s-keltz.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-76.285069",
        "State": "Virginia",
        "NameOrID": "Rupert, Robert",
        "City": "Norfolk",
        "Year": "1966",
        "lat": "36.846825",
        "Document": "docs/robert-rupert.pdf"
    },
    {
        "TroopNumber": "Troop 11",
        "lng": "-76.88223",
        "State": "Pennsylvania",
        "NameOrID": "Seaman, John Wilber",
        "City": "Harrisburg",
        "Year": "1961",
        "lat": "40.259865",
        "Document": "docs/john-wilber-seaman.pdf"
    },
    {
        "TroopNumber": "Troop 10",
        "lng": "-92.12783",
        "State": "Louisiana",
        "NameOrID": "Young, Tommy Fletcher",
        "City": "West Monroe",
        "Year": "1965",
        "lat": "32.504086",
        "Document": "docs/tommy-fletcher-young-jr.pdf"
    },
    {
        "TroopNumber": "Troop 13",
        "lng": "-80.143564",
        "State": "Florida",
        "NameOrID": "Knight, Richard Armour",
        "City": "Ft. Lauderdale",
        "Year": "1966",
        "lat": "26.12367",
        "Document": "docs/richard-armour-knight.pdf"
    },
    {
        "TroopNumber": "Troop 10",
        "lng": "-79.966671",
        "State": "Pennsylvania",
        "NameOrID": "Wolfe, Larry Henry",
        "City": "Meridian",
        "Year": "1966",
        "lat": "40.84526",
        "Document": "docs/larry-henry-wolfe.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "10.88094",
        "State": "Germany",
        "NameOrID": "Benoit, Ronald",
        "City": "Herzogenauraek",
        "Year": "1965",
        "lat": "49.568989",
        "Document": "docs/ronald-benoit.pdf"
    },
    {
        "TroopNumber": "Troop 9",
        "lng": "-77.043414",
        "State": "Virginia",
        "NameOrID": "Blevins, John Irvin Baynard",
        "City": "Alexandria",
        "Year": "1960",
        "lat": "38.804605",
        "Document": "docs/john-irvin-baynard-blevins.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-106.45052",
        "State": "Texas",
        "NameOrID": "Provost, Mitchell Peter",
        "City": "Ft. Bliss",
        "Year": "1966",
        "lat": "31.90333",
        "Document": "docs/mitchell-peter-provost.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-121.841985",
        "State": "California",
        "NameOrID": "Miller, David Alden",
        "City": "Chico",
        "Year": "1967",
        "lat": "39.73201",
        "Document": "docs/david-alden-miller.pdf"
    },
    {
        "TroopNumber": "Troop 8",
        "lng": "-120.507049",
        "State": "Washington",
        "NameOrID": "Zook, Michael",
        "City": "Yakima",
        "Year": "1966",
        "lat": "46.604135",
        "Document": "docs/michael-zook.pdf"
    },
    {
        "TroopNumber": "Pack 6",
        "lng": "-95.979024",
        "State": "Oklahoma",
        "NameOrID": "McClintock, Benny Leroy",
        "City": "Bartlesville",
        "Year": "1966",
        "lat": "36.74678",
        "Document": "docs/benny-leroy-mcclintock.pdf"
    },
    {
        "TroopNumber": "Troop 5",
        "lng": "-72.950717",
        "State": "Connecticut",
        "NameOrID": "Ellsworth, Dennis",
        "City": "West Haven",
        "Year": "1967",
        "lat": "41.271948",
        "Document": "docs/dennis-ellsworth.pdf"
    },
    {
        "TroopNumber": "Troop 5",
        "lng": "-123.088544",
        "State": "Oregon",
        "NameOrID": "Schuerman, Joseph Ray",
        "City": "Eugene",
        "Year": "1963",
        "lat": "44.04992",
        "Document": "docs/joseph-ray-schuerman.pdf"
    },
    {
        "TroopNumber": "Troop 8",
        "lng": "-80.134904",
        "State": "Florida",
        "NameOrID": "Allen, David P.",
        "City": "Miami Beach",
        "Year": "1967",
        "lat": "25.793095",
        "Document": "docs/david-p-allen.pdf"
    },
    {
        "TroopNumber": "Troop 8",
        "lng": "-80.237419",
        "State": "Florida",
        "NameOrID": "Harford, Matthew Joseph",
        "City": "Miami",
        "Year": "1969",
        "lat": "25.728985",
        "Document": "docs/matthew-joseph-harford.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-72.950717",
        "State": "Connecticut",
        "NameOrID": "Schaefer, Paul J.",
        "City": "West Haven",
        "Year": "1967",
        "lat": "41.271948",
        "Document": "docs/paul-j-schaefer.pdf"
    },
    {
        "TroopNumber": "Pack 8",
        "lng": "-122.206419",
        "State": "Washington",
        "NameOrID": "Moore, Ralph Eugene",
        "City": "Everett",
        "Year": "1960",
        "lat": "47.976575",
        "Document": "docs/ralph-eugene-moore.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-87.217239",
        "State": "Florida",
        "NameOrID": "Garrison, Paul Eugene",
        "City": "Pensacola",
        "Year": "1963",
        "lat": "30.420845",
        "Document": "docs/paul-eugene-garrison.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-72.97828",
        "State": "Vermont",
        "NameOrID": "Moreau, William J.",
        "City": "Rutland",
        "Year": "1964",
        "lat": "43.605098",
        "Document": "docs/william-j-moreau-jr.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "-81.377389",
        "State": "Florida",
        "NameOrID": "Kise, William S.",
        "City": "Orlando",
        "Year": "1968",
        "lat": "28.538235",
        "Document": "docs/william-s-kise.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-90.077749",
        "State": "Louisiana",
        "NameOrID": "Johnson, Trois E.",
        "City": "New Orleans",
        "Year": "1966",
        "lat": "29.9537",
        "Document": "docs/trois-e-johnson.pdf"
    },
    {
        "TroopNumber": "Troop 2",
        "lng": "",
        "State": "",
        "NameOrID": "O'Callaghan, Robert",
        "City": "Clark AFB",
        "Year": "1960",
        "lat": "",
        "Document": "docs/robert-ocallaghan.pdf"
    },
    {
        "TroopNumber": "Troop 7",
        "lng": "-84.156009",
        "State": "Georgia",
        "NameOrID": "Kirksey, George Jackson",
        "City": "Albany",
        "Year": "1971",
        "lat": "31.58076",
        "Document": "docs/george-jackson-kirksey.pdf"
    },
    {
        "TroopNumber": "Explorer 2",
        "lng": "-74.333986",
        "State": "New York",
        "NameOrID": "Nolte, Burdette",
        "City": "Middleburgh",
        "Year": "1969",
        "lat": "42.597772",
        "Document": "docs/burdette-nolte.pdf"
    },
    {
        "TroopNumber": "Troop 6",
        "lng": "-77.001414",
        "State": "Pennsylvania",
        "NameOrID": "Ettwein, Joseph S.",
        "City": "Willaimsport",
        "Year": "1966",
        "lat": "41.240945",
        "Document": "docs/joseph-s-ettwein.pdf"
    },
    {
        "TroopNumber": "Troop 1",
        "lng": "-122.675629",
        "State": "Oregon",
        "NameOrID": "Ady, Aard Bruce",
        "City": "Portland",
        "Year": "1965",
        "lat": "45.511795",
        "Document": "docs/aard-bruce-ady.pdf"
    },
    {
        "TroopNumber": "Troop 1",
        "lng": "-72.924979",
        "State": "Connecticut",
        "NameOrID": "Fowler, Richard",
        "City": "New Haven",
        "Year": "1960",
        "lat": "41.30713",
        "Document": "docs/richard-fowler.pdf"
    },
    {
        "TroopNumber": "Troop 1",
        "lng": "-85.766404",
        "State": "Kentucky",
        "NameOrID": "Wintz, Jerry D.",
        "City": "Louisville",
        "Year": "1965",
        "lat": "38.25486",
        "Document": "docs/jerry-d-wintz.pdf"
    },
    {
        "TroopNumber": "Explorer 1",
        "lng": "-74.759404",
        "State": "New Jersey",
        "NameOrID": ",",
        "City": "Trenton",
        "Year": "1960",
        "lat": "40.217875",
        "Document": "docs/redacted-redacted-1960.pdf"
    },
    {
        "TroopNumber": "Camp ",
        "lng": "",
        "State": "",
        "NameOrID": "Hulett, James D.",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/james-d-hulett.pdf"
    },
    {
        "TroopNumber": "Sea Scouts 511",
        "lng": "-117.161719",
        "State": "California",
        "NameOrID": "Pfanstiehl, Alfred",
        "City": "San Diego",
        "Year": "1963",
        "lat": "32.715695",
        "Document": "docs/alfred-pfanstiehl.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Tenney, Edwin Lren",
        "City": "",
        "Year": "1969",
        "lat": "",
        "Document": "docs/edwin-lren-tenney.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Bartlett, James W.",
        "City": "",
        "Year": "1961",
        "lat": "",
        "Document": "docs/james-w-bartlett.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Peterson, Robert Martin",
        "City": "",
        "Year": "1970",
        "lat": "",
        "Document": "docs/robert-martin-peterson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Tompkins, John R.",
        "City": "",
        "Year": "1970",
        "lat": "",
        "Document": "docs/john-r-tompkins.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Wrobel, Donald E.",
        "City": "",
        "Year": "1970",
        "lat": "",
        "Document": "docs/donald-e-wrobel.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Ramer, Duane Leigh",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/duane-leigh-ramer.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Thouin, Robert H.",
        "City": "",
        "Year": "1961",
        "lat": "",
        "Document": "docs/robert-h-thouin.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Stone, William S.",
        "City": "",
        "Year": "1964",
        "lat": "",
        "Document": "docs/william-s-stone.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Morton, Robert Joseph",
        "City": "",
        "Year": "1960",
        "lat": "",
        "Document": "docs/robert-joseph-morton.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Boggess, Michael Kendrick",
        "City": "",
        "Year": "1961",
        "lat": "",
        "Document": "docs/michael-kendrick-boggess.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Halpern, Samuel Barry",
        "City": "",
        "Year": "1961",
        "lat": "",
        "Document": "docs/samuel-barry-halpern.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hohl, Robert P.",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/robert-p-hohl.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Henne, David John",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/david-john-henne.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Perry, Merle G.",
        "City": "",
        "Year": "1962",
        "lat": "",
        "Document": "docs/merle-g-perry-jr.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Bischoff, Jimmie J.",
        "City": "",
        "Year": "1964",
        "lat": "",
        "Document": "docs/jimmie-j-bischoff.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Story, James Harold",
        "City": "",
        "Year": "1965",
        "lat": "",
        "Document": "docs/james-harold-story.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Orr, George Frederick",
        "City": "",
        "Year": "1969",
        "lat": "",
        "Document": "docs/george-frederick-orr.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Huelsman, Robert Elmer",
        "City": "",
        "Year": "1962",
        "lat": "",
        "Document": "docs/robert-elmer-huelsman.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Kidd, Ronald",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/ronald-kidd.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hicks, Charles Henry",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/charles-henry-hicks.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Sorenson, Donald Lee",
        "City": "",
        "Year": "1965",
        "lat": "",
        "Document": "docs/donald-lee-sorenson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Funkhouser, Jerry",
        "City": "",
        "Year": "1962",
        "lat": "",
        "Document": "docs/jerry-funkhouser.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Mates, Robert",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/robert-mates.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Steuart, William K.",
        "City": "",
        "Year": "1961",
        "lat": "",
        "Document": "docs/william-k-steuart.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Weniger, Robert L.",
        "City": "",
        "Year": "1964",
        "lat": "",
        "Document": "docs/robert-l-weniger.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Harvey, John",
        "City": "",
        "Year": "1970",
        "lat": "",
        "Document": "docs/john-harvey.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Brown, Douglas Lee",
        "City": "",
        "Year": "1961",
        "lat": "",
        "Document": "docs/douglas-lee-brown.pdf"
    },
    {
        "TroopNumber": "Troop 113",
        "lng": "",
        "State": "Illinois",
        "NameOrID": "Cunningham, Carl",
        "City": "",
        "Year": "1960",
        "lat": "",
        "Document": "docs/carl-cunningham.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Collier, Robert E.",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/robert-e-collier.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "McCadam, Paul",
        "City": "",
        "Year": "1970",
        "lat": "",
        "Document": "docs/paul-mccadam.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Donovan, William Edward",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/william-edward-donovan.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Leavis, John C.",
        "City": "",
        "Year": "1978",
        "lat": "",
        "Document": "docs/john-c-leavis.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Jordan, Robert Lester",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/robert-lester-jordan.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Olson, Frederick Daniel",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/frederick-daniel-olson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Kirkpatrick, Richard B.",
        "City": "",
        "Year": "1963",
        "lat": "",
        "Document": "docs/richard-b-kirkpatrick.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Stensland, Neil Francis",
        "City": "",
        "Year": "1961",
        "lat": "",
        "Document": "docs/neil-francis-stensland.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Gorton, William",
        "City": "",
        "Year": "1960",
        "lat": "",
        "Document": "docs/william-gorton.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Goddard, Jack O.",
        "City": "",
        "Year": "1962",
        "lat": "",
        "Document": "docs/jack-o-goddard.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Klecher, Anthony Charles",
        "City": "",
        "Year": "1963",
        "lat": "",
        "Document": "docs/anthony-charles-klecher.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Pearson, Kenneth Edwin",
        "City": "",
        "Year": "1964",
        "lat": "",
        "Document": "docs/kenneth-edwin-pearson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Martin, Earl Dean",
        "City": "",
        "Year": "1963",
        "lat": "",
        "Document": "docs/earl-dean-martin.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Gleston, Franklin",
        "City": "",
        "Year": "1965",
        "lat": "",
        "Document": "docs/franklin-gleston.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hoffmeyer, William A.",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/william-a-hoffmeyer.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Privette, Ernest T.",
        "City": "",
        "Year": "1970",
        "lat": "",
        "Document": "docs/ernest-t-privette.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Dudley, Shearon",
        "City": "",
        "Year": "1961",
        "lat": "",
        "Document": "docs/shearon-dudley.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Parthun, Wayne Earle",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/wayne-earle-parthun.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Overturf, James E.",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/james-e-overturf.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Miller, Alfred L.",
        "City": "",
        "Year": "1969",
        "lat": "",
        "Document": "docs/alfred-l-miller.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Talamo, Jack J.",
        "City": "",
        "Year": "1962",
        "lat": "",
        "Document": "docs/jack-j-talamo.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Roach, Jon Kent",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/jon-kent-roach.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Strine, R. Patterson",
        "City": "",
        "Year": "1961",
        "lat": "",
        "Document": "docs/r-patterson-strine.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Anglin, Clifford H.",
        "City": "",
        "Year": "1961",
        "lat": "",
        "Document": "docs/clifford-h-anglin.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Lomax, William G.",
        "City": "",
        "Year": "1963",
        "lat": "",
        "Document": "docs/william-g-lomax.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Smith, Raymond H.",
        "City": "",
        "Year": "1964",
        "lat": "",
        "Document": "docs/raymond-h-smith.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Silvius, Edward George",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/edward-george-silvius.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Patterson, Dwight Reed",
        "City": "",
        "Year": "1962",
        "lat": "",
        "Document": "docs/dwight-reed-patterson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Jones, Charles Frank",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/charles-frank-jones.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hill, Alfred",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/alfred-hill.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Rolloff, Gary Frederick",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/gary-frederick-rolloff.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Gladden, A. Lee",
        "City": "",
        "Year": "1965",
        "lat": "",
        "Document": "docs/a-lee-gladden-jr.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Iannuzzi, William",
        "City": "",
        "Year": "1965",
        "lat": "",
        "Document": "docs/william-iannuzzi.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Hiltner, Richard Montgomery",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/richard-montgomery-hiltner.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Reid, Samuel Lynn",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/samuel-lynn-reid.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Vance, Donald W.",
        "City": "",
        "Year": "1962",
        "lat": "",
        "Document": "docs/donald-w-vance.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Temple, Robert Eugene",
        "City": "",
        "Year": "1965",
        "lat": "",
        "Document": "docs/robert-eugene-temple.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Damon, Farnham W.",
        "City": "",
        "Year": "1963",
        "lat": "",
        "Document": "docs/farnham-w-damon.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Clayton, W. Dean",
        "City": "",
        "Year": "1963",
        "lat": "",
        "Document": "docs/w-dean-clayton.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Jones, Herbert F.H.",
        "City": "",
        "Year": "1970",
        "lat": "",
        "Document": "docs/herbert-fh-jones.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Foley, Warren R.",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/warren-r-foley.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Ward, James Delma",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/james-delma-ward.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Talt, Richard William",
        "City": "",
        "Year": "1969",
        "lat": "",
        "Document": "docs/richard-william-talt.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Boyle, Martin",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/martin-boyle.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Newman, Robert E.",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/robert-e-newman.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Brown, Ronald Lee",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/ronald-lee-brown.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Harris, Henry T.",
        "City": "",
        "Year": "1962",
        "lat": "",
        "Document": "docs/henry-t-harris.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Tomlin, Paul C.",
        "City": "",
        "Year": "1960",
        "lat": "",
        "Document": "docs/paul-c-tomlin.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Salyers, R. Glenn",
        "City": "",
        "Year": "1969",
        "lat": "",
        "Document": "docs/r-glenn-salyers.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Aziz, Joel",
        "City": "",
        "Year": "1964",
        "lat": "",
        "Document": "docs/joel-aziz.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Bradley, Marlowe William",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/marlowe-william-bradley.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Donelson, Floyd Dwight",
        "City": "",
        "Year": "1966",
        "lat": "",
        "Document": "docs/floyd-dwight-donelson.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Leight, Edward",
        "City": "",
        "Year": "1964",
        "lat": "",
        "Document": "docs/edward-leight.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Napier, Frank",
        "City": "",
        "Year": "1960",
        "lat": "",
        "Document": "docs/frank-napier.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Winthrow, Samuel Joseph",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/samuel-joseph-winthrow.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Austin, David K.",
        "City": "",
        "Year": "1969",
        "lat": "",
        "Document": "docs/david-k-austin.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Gourley, William E.",
        "City": "",
        "Year": "1970",
        "lat": "",
        "Document": "docs/william-e-gourley.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Gold, Thomas Hall",
        "City": "",
        "Year": "1968",
        "lat": "",
        "Document": "docs/thomas-hall-gold.pdf"
    },
    {
        "TroopNumber": "Troop 1",
        "lng": "-92.3429631",
        "State": "Iowa",
        "NameOrID": "Shadle, James L.",
        "City": "Waterloo",
        "Year": "1960",
        "lat": "42.4927641",
        "Document": "docs/james-l-shadle.pdf"
    },
    {
        "TroopNumber": "Troop 103",
        "lng": "-75.5243682",
        "State": "Delaware",
        "NameOrID": "Kissel, Gary Lee",
        "City": "Dover",
        "Year": "1960",
        "lat": "39.158168",
        "Document": "docs/gary-lee-kissel.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Berry, Frank",
        "City": "",
        "Year": "1962",
        "lat": "",
        "Document": "docs/frank-berry.pdf"
    },
    {
        "TroopNumber": "Troop 625",
        "lng": "-122.1244088",
        "State": "California",
        "NameOrID": "Hansen, Jene Albert",
        "City": "San Lorenzo",
        "Year": "1960",
        "lat": "37.6810422",
        "Document": "docs/jene-albert-hansen.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "Gray, Brandon P.",
        "City": "",
        "Year": "1967",
        "lat": "",
        "Document": "docs/brandon-p-gray.pdf"
    },
    {
        "TroopNumber": "Troop 602",
        "lng": "-122.1244088",
        "State": "California",
        "NameOrID": "Hansen, Jene Albert",
        "City": "San Lorenzo",
        "Year": "1960",
        "lat": "37.6810422",
        "Document": "docs/jene-albert-hansen.pdf"
    },
    {
        "TroopNumber": "",
        "lng": "",
        "State": "",
        "NameOrID": "McDonald, Leo Patrick",
        "City": "",
        "Year": "1963",
        "lat": "",
        "Document": "docs/leo-patrick-mcdonald.pdf"
    },
    {
        "TroopNumber": "Explorer 143",
        "lng": "-0.3313576",
        "State": "England",
        "NameOrID": "Landis, Richard M.",
        "City": "Bushy Park AB",
        "Year": "1962",
        "lat": "51.417368",
        "Document": "docs/richard-m-landis.pdf"
    },
    {
        "TroopNumber": "Troop 19",
        "lng": "-76.3452218",
        "State": "Virginia",
        "NameOrID": "Hartley, James C.",
        "City": "Hampton",
        "Year": "1964",
        "lat": "37.0298687",
        "Document": "docs/james-c-hartley.pdf"
    },
    {
        "TroopNumber": "Troop 275",
        "lng": "-0.922395",
        "State": "France",
        "NameOrID": "Witherspoon, Charles C.",
        "City": "Ingrandes",
        "Year": "1964",
        "lat": "47.403699",
        "Document": "docs/charles-c-witherspoon-jr.pdf"
    },
    {
        "TroopNumber": "Troop 67",
        "lng": "-78.4055767",
        "State": "New York",
        "NameOrID": "Merritt, Lowell L.",
        "City": "Corfu",
        "Year": "1969",
        "lat": "42.9600595",
        "Document": "docs/lowell-l-merritt.pdf"
    },
    {
        "TroopNumber": "Troop 25",
        "lng": "-114.3224548",
        "State": "Arizona",
        "NameOrID": "Perdue, Barney A.",
        "City": "Lake Havasu City",
        "Year": "1964",
        "lat": "34.483901",
        "Document": "docs/barney-perdue.pdf"
    },
    {
        "TroopNumber": "Pack 492",
        "lng": "-97.3361111",
        "State": "Kansas",
        "NameOrID": "Hunnicutt, Troy Wayne",
        "City": "Wichita",
        "Year": "1976",
        "lat": "37.6888889",
        "Document": "docs/troy-wayne-hunnicutt.pdf"
    },
    {
        "TroopNumber": "Troop 402",
        "lng": "-72.799397",
        "State": "Connecticut",
        "NameOrID": "Jennings, Edward Oscar",
        "City": "Branford",
        "Year": "1965",
        "lat": "41.2778931",
        "Document": "docs/edward-oscar-jennings.pdf"
    },
    {
        "TroopNumber": "Troop 510",
        "lng": "-97.7430608",
        "State": "Texas",
        "NameOrID": "James, Kenneth W.",
        "City": "Austin",
        "Year": "1979",
        "lat": "30.267153",
        "Document": "docs/james-w-kenneth.pdf"
    },
    {
        "TroopNumber": "Explorer 2070",
        "lng": "-70.9457635",
        "State": "Massachusetts",
        "NameOrID": "Daniels, Robert",
        "City": "South Dartmouth",
        "Year": "1966",
        "lat": "41.6035925",
        "Document": "docs/robert-daniels.pdf"
    },
    {
        "TroopNumber": "Troop 677",
        "lng": "-95.3693896",
        "State": "Texas",
        "NameOrID": "James, Kenneth W.",
        "City": "Houston",
        "Year": "1979",
        "lat": "29.7601927",
        "Document": "docs/james-w-kenneth.pdf"
    },
    {
        "TroopNumber": "Troop 639",
        "lng": "-74.0059731",
        "State": "New York",
        "NameOrID": "Sigmund, Frederick",
        "City": "New York",
        "Year": "1967",
        "lat": "40.7143528",
        "Document": "docs/frederick-sigmund.pdf"
    },
    {
        "TroopNumber": "Explorer 327",
        "lng": "-90.0489801",
        "State": "Tennessee",
        "NameOrID": "Evans, Stanley Thomas",
        "City": "Memphis",
        "Year": "1978",
        "lat": "35.1495343",
        "Document": "docs/stanley-thomas-evans-sr.pdf"
    },
    {
        "TroopNumber": "Troop 677",
        "lng": "-74.0059731",
        "State": "New York",
        "NameOrID": "Sigmund, Frederick",
        "City": "New York",
        "Year": "1967",
        "lat": "40.7143528",
        "Document": "docs/frederick-sigmund.pdf"
    },
    {
        "TroopNumber": "Troop 82",
        "lng": "-80.8431267",
        "State": "North Carolina",
        "NameOrID": "Fulbright, Ricky",
        "City": "Charlotte",
        "Year": "1979",
        "lat": "35.2270869",
        "Document": "docs/ricky-fulbright.pdf"
    },
    {
        "TroopNumber": "Troop 819",
        "lng": "",
        "State": "Illinois",
        "NameOrID": "Burns, Robert W.",
        "City": "Chicago",
        "Year": "1967",
        "lat": "",
        "Document": "docs/robert-w-burns.pdf"
    },
    {
        "TroopNumber": "Troop 813",
        "lng": "",
        "State": "Illinois",
        "NameOrID": "Burns, Robert W.",
        "City": "Chicago",
        "Year": "1967",
        "lat": "",
        "Document": "docs/robert-w-burns.pdf"
    },
    {
        "TroopNumber": "Pack 276",
        "lng": "-81.5742898",
        "State": "Ohio",
        "NameOrID": "Unklesbay, Paul J.",
        "City": "Moffitt Heights",
        "Year": "1967",
        "lat": "40.7783906",
        "Document": "docs/paul-j-unklesbay.pdf"
    },
    {
        "TroopNumber": "Troop 73",
        "lng": "-82.7192671",
        "State": "Florida",
        "NameOrID": "Foley, Warren Raymond",
        "City": "New Port Richey",
        "Year": "1983",
        "lat": "28.2441768",
        "Document": "docs/warren-raymond-foley.pdf"
    },
    {
        "TroopNumber": "Troop 249",
        "lng": "-74.0059731",
        "State": "New York",
        "NameOrID": "Clark, Richard",
        "City": "Rochester",
        "Year": "1969",
        "lat": "40.7143528",
        "Document": "docs/richard-clark.pdf"
    },
    {
        "TroopNumber": "Troop 78",
        "lng": "-82.7192671",
        "State": "Florida",
        "NameOrID": "Foley, Warren Raymond",
        "City": "New Port Richey",
        "Year": "1983",
        "lat": "28.2441768",
        "Document": "docs/warren-raymond-foley.pdf"
    },
    {
        "TroopNumber": "Pack 249",
        "lng": "-74.0059731",
        "State": "New York",
        "NameOrID": "Clark, Richard",
        "City": "Rochester",
        "Year": "1969",
        "lat": "40.7143528",
        "Document": "docs/richard-clark.pdf"
    },
    {
        "TroopNumber": "Troop 132",
        "lng": "-122.2020794",
        "State": "Washington",
        "NameOrID": "Ewalt, Allen",
        "City": "Everett",
        "Year": "1987",
        "lat": "47.9789848",
        "Document": "docs/allen-ewalt.pdf"
    },
    {
        "TroopNumber": "Troop 475",
        "lng": "-79.7625625",
        "State": "South Carolina",
        "NameOrID": "Dozier, John Ben",
        "City": "Florence",
        "Year": "1968",
        "lat": "34.1954331",
        "Document": "docs/john-ben-dozier.pdf"
    },
    {
        "TroopNumber": "Troop 864",
        "lng": "-87.6297982",
        "State": "Illinois",
        "NameOrID": "Whalen, Thomas M.",
        "City": "Chicago",
        "Year": "1986",
        "lat": "41.8781136",
        "Document": "docs/thomas-m-whalen.pdf"
    },
    {
        "TroopNumber": "Troop 257",
        "lng": "-86.3097278",
        "State": "Indiana",
        "NameOrID": "Fish, Lloyd W.",
        "City": "Plymouth",
        "Year": "1991",
        "lat": "41.3436554",
        "Document": "docs/lloyd-w-fish.pdf"
    }
]
