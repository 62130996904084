export default {
    "troop 22": [
        0,
        901,
        1214,
        1737,
        2066,
        2256,
        2614,
        2616,
        2618,
        2619
    ],
    "georgia": [
        0,
        1,
        2,
        43,
        53,
        54,
        55,
        56,
        92,
        93,
        113,
        118,
        267,
        282,
        339,
        340,
        408,
        424,
        450,
        503,
        519,
        588,
        599,
        676,
        697,
        703,
        871,
        899,
        976,
        1314,
        1394,
        1396,
        1464,
        1489,
        1495,
        1519,
        1663,
        1686,
        1702,
        1713,
        1717,
        1791,
        1819,
        1859,
        1866,
        1923,
        2094,
        2123,
        2146,
        2148,
        2532,
        2540,
        2542,
        2671
    ],
    "boland, ernest p.": [
        0,
        1,
        2
    ],
    "athens": [
        0,
        1,
        2,
        643
    ],
    "1977": [
        0,
        1,
        2,
        34,
        35,
        76,
        77,
        78,
        111,
        150,
        151,
        152,
        195,
        196,
        197,
        198,
        297,
        298,
        302,
        320,
        402,
        411,
        412,
        413,
        430,
        448,
        513,
        546,
        584,
        605,
        719,
        800,
        813,
        880,
        901,
        902,
        938,
        1026,
        1059,
        1177,
        1180,
        1282,
        1313,
        1419,
        1474,
        1557,
        1620,
        1626,
        1632,
        1640,
        1686,
        1738,
        1741,
        1818,
        1820,
        1925,
        1943,
        2025,
        2030,
        2090,
        2125,
        2127
    ],
    "troop 2": [
        1,
        8,
        449,
        453,
        612,
        747,
        823,
        907,
        1203,
        1239,
        1671,
        2255,
        2282,
        2664,
        2666,
        2668,
        2670
    ],
    "troop 3": [
        2,
        11,
        246,
        311,
        893,
        923,
        932,
        969,
        997,
        1532,
        1721,
        2075,
        2110,
        2300
    ],
    "troop 77": [
        3,
        55,
        277,
        496,
        1309,
        1472,
        1695,
        1825
    ],
    "california": [
        3,
        4,
        5,
        11,
        12,
        13,
        98,
        99,
        100,
        128,
        129,
        238,
        250,
        251,
        253,
        279,
        286,
        305,
        307,
        326,
        327,
        338,
        363,
        378,
        415,
        438,
        440,
        460,
        464,
        473,
        497,
        518,
        541,
        555,
        582,
        597,
        598,
        644,
        690,
        691,
        693,
        733,
        734,
        741,
        759,
        760,
        762,
        770,
        771,
        816,
        829,
        830,
        835,
        869,
        877,
        901,
        902,
        923,
        934,
        935,
        957,
        959,
        964,
        966,
        967,
        991,
        999,
        1001,
        1013,
        1027,
        1029,
        1045,
        1058,
        1060,
        1063,
        1070,
        1095,
        1103,
        1109,
        1118,
        1119,
        1120,
        1121,
        1134,
        1153,
        1170,
        1171,
        1173,
        1175,
        1187,
        1199,
        1203,
        1205,
        1265,
        1296,
        1298,
        1323,
        1324,
        1325,
        1327,
        1346,
        1352,
        1357,
        1368,
        1372,
        1389,
        1390,
        1403,
        1405,
        1418,
        1421,
        1430,
        1431,
        1433,
        1442,
        1446,
        1484,
        1493,
        1512,
        1538,
        1540,
        1542,
        1557,
        1564,
        1571,
        1578,
        1597,
        1599,
        1622,
        1623,
        1642,
        1672,
        1679,
        1681,
        1696,
        1699,
        1711,
        1716,
        1745,
        1747,
        1767,
        1769,
        1774,
        1777,
        1781,
        1813,
        1837,
        1839,
        1850,
        1852,
        1854,
        1860,
        1862,
        1869,
        1870,
        1878,
        1885,
        1888,
        1921,
        1928,
        1929,
        1941,
        1950,
        1955,
        1956,
        1961,
        1987,
        1988,
        1990,
        2000,
        2007,
        2023,
        2029,
        2058,
        2062,
        2063,
        2065,
        2066,
        2067,
        2088,
        2114,
        2118,
        2120,
        2121,
        2134,
        2136,
        2178,
        2180,
        2189,
        2191,
        2205,
        2206,
        2222,
        2224,
        2247,
        2259,
        2260,
        2261,
        2262,
        2313,
        2316,
        2318,
        2321,
        2326,
        2335,
        2339,
        2344,
        2356,
        2358,
        2360,
        2367,
        2381,
        2388,
        2398,
        2402,
        2404,
        2413,
        2433,
        2446,
        2452,
        2456,
        2461,
        2470,
        2480,
        2488,
        2490,
        2503,
        2516,
        2523,
        2528,
        2538,
        2548,
        2559,
        2578,
        2584,
        2585,
        2597,
        2638,
        2645,
        2647,
        2657,
        2679,
        2769,
        2771
    ],
    "hanigan, kenneth l.": [
        3,
        4,
        5
    ],
    "modesto": [
        3,
        4,
        5,
        98,
        99,
        100
    ],
    "1987": [
        3,
        4,
        5,
        39,
        40,
        41,
        57,
        58,
        59,
        158,
        186,
        187,
        188,
        208,
        209,
        218,
        220,
        226,
        245,
        264,
        275,
        279,
        291,
        321,
        322,
        339,
        340,
        341,
        349,
        354,
        355,
        362,
        363,
        372,
        373,
        379,
        398,
        405,
        447,
        461,
        462,
        480,
        497,
        525,
        535,
        536,
        537,
        552,
        553,
        567,
        568,
        574,
        575,
        576,
        583,
        590,
        603,
        604,
        613,
        614,
        615,
        616,
        635,
        636,
        637,
        642,
        644,
        645,
        689,
        699,
        703,
        725,
        735,
        736,
        742,
        743,
        752,
        753,
        754,
        764,
        805,
        806,
        814,
        815,
        817,
        818,
        825,
        826,
        839,
        847,
        848,
        864,
        897,
        898,
        907,
        908,
        922,
        928,
        944,
        945,
        980,
        981,
        982,
        983,
        999,
        1001,
        1031,
        1038,
        1040,
        1085,
        1087,
        1113,
        1114,
        1115,
        1122,
        1124,
        1125,
        1137,
        1138,
        1149,
        1159,
        1173,
        1175,
        1224,
        1226,
        1243,
        1255,
        1257,
        1262,
        1267,
        1268,
        1295,
        1297,
        1299,
        1311,
        1312,
        1317,
        1319,
        1329,
        1339,
        1347,
        1349,
        1355,
        1356,
        1377,
        1418,
        1451,
        1462,
        1480,
        1491,
        1496,
        1518,
        1523,
        1527,
        1529,
        1541,
        1563,
        1583,
        1608,
        1609,
        1612,
        1651,
        1655,
        1661,
        1663,
        1674,
        1700,
        1711,
        1717,
        1725,
        1726,
        1729,
        1735,
        1736,
        1737,
        1749,
        1785,
        1793,
        1823,
        1824,
        1837,
        1850,
        1871,
        1872,
        1880,
        1897,
        1910,
        1920,
        1927,
        1939,
        1960,
        1991,
        1999,
        2000,
        2009,
        2070,
        2072,
        2092,
        2138,
        2139,
        2145,
        2367,
        2794
    ],
    "pack 56": [
        4,
        368
    ],
    "pack 5": [
        5,
        2035
    ],
    "pack 7": [
        6,
        307,
        330
    ],
    "": [
        6,
        7,
        42,
        118,
        126,
        153,
        162,
        184,
        190,
        202,
        210,
        211,
        218,
        219,
        221,
        225,
        228,
        229,
        242,
        243,
        244,
        248,
        255,
        266,
        273,
        276,
        277,
        278,
        280,
        292,
        299,
        304,
        308,
        314,
        317,
        319,
        323,
        332,
        337,
        360,
        372,
        373,
        398,
        406,
        407,
        410,
        416,
        422,
        425,
        426,
        433,
        447,
        448,
        454,
        455,
        459,
        467,
        477,
        490,
        500,
        502,
        503,
        504,
        509,
        517,
        525,
        531,
        540,
        544,
        545,
        551,
        558,
        564,
        569,
        572,
        578,
        584,
        595,
        596,
        616,
        625,
        634,
        637,
        639,
        646,
        655,
        656,
        659,
        663,
        665,
        685,
        694,
        704,
        717,
        728,
        756,
        763,
        774,
        784,
        788,
        790,
        798,
        800,
        815,
        821,
        824,
        827,
        842,
        847,
        859,
        872,
        882,
        883,
        897,
        921,
        942,
        951,
        953,
        954,
        968,
        974,
        982,
        986,
        992,
        995,
        1015,
        1016,
        1020,
        1021,
        1023,
        1026,
        1029,
        1030,
        1033,
        1036,
        1038,
        1039,
        1042,
        1056,
        1057,
        1059,
        1070,
        1089,
        1091,
        1094,
        1105,
        1123,
        1146,
        1148,
        1154,
        1178,
        1188,
        1190,
        1192,
        1210,
        1211,
        1212,
        1213,
        1215,
        1217,
        1219,
        1220,
        1247,
        1248,
        1249,
        1251,
        1257,
        1262,
        1269,
        1271,
        1275,
        1296,
        1297,
        1305,
        1306,
        1316,
        1317,
        1321,
        1332,
        1342,
        1343,
        1344,
        1345,
        1350,
        1363,
        1364,
        1379,
        1382,
        1385,
        1391,
        1419,
        1422,
        1423,
        1425,
        1428,
        1438,
        1440,
        1443,
        1444,
        1446,
        1451,
        1462,
        1468,
        1473,
        1477,
        1482,
        1484,
        1487,
        1490,
        1491,
        1503,
        1505,
        1522,
        1525,
        1527,
        1529,
        1530,
        1534,
        1536,
        1561,
        1562,
        1564,
        1566,
        1588,
        1590,
        1606,
        1624,
        1626,
        1627,
        1628,
        1629,
        1630,
        1632,
        1633,
        1638,
        1639,
        1648,
        1654,
        1658,
        1659,
        1661,
        1678,
        1680,
        1686,
        1698,
        1703,
        1707,
        1708,
        1716,
        1719,
        1727,
        1748,
        1772,
        1782,
        1785,
        1792,
        1794,
        1796,
        1800,
        1804,
        1807,
        1815,
        1822,
        1824,
        1844,
        1846,
        1848,
        1849,
        1861,
        1864,
        1868,
        1872,
        1877,
        1881,
        1885,
        1911,
        1922,
        1927,
        1935,
        1943,
        1944,
        1948,
        1949,
        1964,
        1971,
        1984,
        1987,
        1989,
        2007,
        2016,
        2018,
        2020,
        2025,
        2027,
        2030,
        2034,
        2038,
        2045,
        2049,
        2050,
        2051,
        2052,
        2055,
        2059,
        2085,
        2096,
        2099,
        2106,
        2108,
        2110,
        2117,
        2122,
        2126,
        2130,
        2134,
        2141,
        2145,
        2160,
        2166,
        2309,
        2342,
        2353,
        2379,
        2382,
        2412,
        2472,
        2504,
        2517,
        2520,
        2543,
        2636,
        2670,
        2678,
        2680,
        2681,
        2682,
        2683,
        2684,
        2685,
        2686,
        2687,
        2688,
        2689,
        2690,
        2691,
        2692,
        2693,
        2694,
        2695,
        2696,
        2697,
        2698,
        2699,
        2700,
        2701,
        2702,
        2703,
        2704,
        2705,
        2706,
        2707,
        2708,
        2709,
        2710,
        2711,
        2712,
        2713,
        2714,
        2715,
        2716,
        2717,
        2718,
        2719,
        2720,
        2721,
        2722,
        2723,
        2724,
        2725,
        2726,
        2727,
        2728,
        2729,
        2730,
        2731,
        2732,
        2733,
        2734,
        2735,
        2736,
        2737,
        2738,
        2739,
        2740,
        2741,
        2742,
        2743,
        2744,
        2745,
        2746,
        2747,
        2748,
        2749,
        2750,
        2751,
        2752,
        2753,
        2754,
        2755,
        2756,
        2757,
        2758,
        2759,
        2760,
        2761,
        2762,
        2763,
        2764,
        2765,
        2768,
        2770,
        2772
    ],
    "michell, david h.": [
        6
    ],
    "1966": [
        6,
        60,
        61,
        62,
        63,
        2152,
        2153,
        2245,
        2247,
        2248,
        2249,
        2265,
        2267,
        2270,
        2271,
        2272,
        2274,
        2285,
        2287,
        2294,
        2304,
        2307,
        2313,
        2322,
        2329,
        2333,
        2337,
        2338,
        2342,
        2356,
        2361,
        2362,
        2366,
        2373,
        2380,
        2394,
        2403,
        2430,
        2435,
        2438,
        2442,
        2452,
        2470,
        2483,
        2484,
        2485,
        2486,
        2489,
        2490,
        2507,
        2509,
        2510,
        2511,
        2532,
        2533,
        2534,
        2538,
        2543,
        2557,
        2569,
        2573,
        2594,
        2616,
        2649,
        2652,
        2653,
        2656,
        2658,
        2659,
        2669,
        2673,
        2685,
        2691,
        2699,
        2708,
        2713,
        2725,
        2729,
        2738,
        2749,
        2759,
        2781
    ],
    "wyoming": [
        7,
        865,
        1301,
        1303,
        1691,
        1693,
        2129
    ],
    "later, kip e.": [
        7
    ],
    "evanston": [
        7,
        1139
    ],
    "1988": [
        7,
        94,
        95,
        96,
        97,
        118,
        166,
        167,
        174,
        175,
        176,
        184,
        185,
        222,
        227,
        252,
        253,
        254,
        271,
        273,
        274,
        277,
        288,
        293,
        295,
        306,
        313,
        318,
        323,
        329,
        335,
        346,
        347,
        375,
        376,
        393,
        416,
        429,
        440,
        446,
        452,
        454,
        457,
        474,
        475,
        489,
        490,
        509,
        514,
        527,
        528,
        530,
        531,
        549,
        557,
        561,
        578,
        597,
        598,
        602,
        606,
        633,
        638,
        640,
        659,
        662,
        663,
        664,
        674,
        677,
        678,
        679,
        680,
        684,
        695,
        698,
        700,
        709,
        722,
        729,
        740,
        741,
        744,
        751,
        759,
        760,
        761,
        772,
        773,
        777,
        781,
        782,
        793,
        803,
        804,
        823,
        875,
        885,
        887,
        889,
        899,
        905,
        912,
        930,
        937,
        940,
        941,
        943,
        953,
        954,
        956,
        961,
        964,
        966,
        967,
        968,
        969,
        973,
        989,
        992,
        994,
        1003,
        1008,
        1016,
        1024,
        1028,
        1039,
        1047,
        1048,
        1050,
        1053,
        1054,
        1062,
        1067,
        1069,
        1073,
        1075,
        1089,
        1092,
        1094,
        1107,
        1110,
        1116,
        1120,
        1145,
        1147,
        1148,
        1153,
        1171,
        1185,
        1192,
        1203,
        1205,
        1214,
        1216,
        1218,
        1221,
        1223,
        1236,
        1237,
        1238,
        1239,
        1244,
        1252,
        1253,
        1264,
        1271,
        1273,
        1283,
        1284,
        1290,
        1305,
        1328,
        1336,
        1338,
        1340,
        1344,
        1350,
        1352,
        1361,
        1364,
        1371,
        1384,
        1385,
        1397,
        1412,
        1413,
        1414,
        1420,
        1429,
        1430,
        1431,
        1433,
        1438,
        1440,
        1442,
        1445,
        1447,
        1449,
        1450,
        1452,
        1466,
        1472,
        1476,
        1484,
        1485,
        1490,
        1507,
        1513,
        1515,
        1517,
        1531,
        1537,
        1540,
        1542,
        1543,
        1544,
        1545,
        1553,
        1562,
        1567,
        1610,
        1611,
        1613,
        1614,
        1618,
        1622,
        1625,
        1642,
        1644,
        1656,
        1662,
        1664,
        1670,
        1679,
        1680,
        1681,
        1682,
        1684,
        1685,
        1687,
        1695,
        1697,
        1715,
        1722,
        1731,
        1732,
        1745,
        1747,
        1774,
        1775,
        1778,
        1781,
        1802,
        1804,
        1821,
        1829,
        1831,
        1843,
        1873,
        1874,
        1882,
        1888,
        1911,
        1912,
        1913,
        1914,
        1917,
        1923,
        1926,
        1928,
        1929,
        1933,
        1938,
        1946,
        1962,
        1963,
        1970,
        2011,
        2017,
        2020,
        2032,
        2035,
        2046,
        2063,
        2065,
        2077,
        2079,
        2080,
        2082,
        2098,
        2100,
        2101,
        2111,
        2115,
        2116,
        2126,
        2129,
        2140,
        2142
    ],
    "rhode island": [
        8,
        9,
        10,
        288,
        528,
        1047,
        1078,
        1110,
        1214,
        1216,
        1218,
        1221,
        1315,
        1363,
        1377,
        1843,
        2142,
        2157,
        2264,
        2266
    ],
    "crepean, ronald f.": [
        8,
        9,
        10
    ],
    "woonsocket": [
        8,
        9,
        10,
        2157
    ],
    "1962": [
        8,
        9,
        10,
        202,
        2154,
        2156,
        2175,
        2176,
        2177,
        2179,
        2232,
        2268,
        2269,
        2276,
        2277,
        2297,
        2312,
        2340,
        2357,
        2369,
        2440,
        2446,
        2474,
        2477,
        2478,
        2481,
        2487,
        2488,
        2512,
        2547,
        2578,
        2579,
        2604,
        2611,
        2617,
        2693,
        2697,
        2701,
        2717,
        2728,
        2735,
        2743,
        2754,
        2768,
        2773
    ],
    "troop 8": [
        9,
        18,
        73,
        226,
        1746,
        1994,
        2658,
        2662,
        2663
    ],
    "troop 9": [
        10,
        174,
        518,
        1050,
        1204,
        1966,
        2289,
        2655
    ],
    "thomas, raymond l.": [
        11,
        12,
        13
    ],
    "auburn": [
        11,
        1605
    ],
    "1967": [
        11,
        12,
        13,
        24,
        25,
        26,
        27,
        127,
        128,
        129,
        2170,
        2185,
        2187,
        2193,
        2194,
        2195,
        2197,
        2199,
        2207,
        2209,
        2215,
        2217,
        2242,
        2243,
        2252,
        2253,
        2259,
        2260,
        2261,
        2262,
        2298,
        2299,
        2300,
        2306,
        2328,
        2334,
        2335,
        2355,
        2371,
        2379,
        2387,
        2402,
        2407,
        2408,
        2411,
        2424,
        2448,
        2455,
        2463,
        2472,
        2500,
        2502,
        2503,
        2518,
        2536,
        2542,
        2544,
        2549,
        2561,
        2571,
        2587,
        2590,
        2595,
        2597,
        2607,
        2618,
        2622,
        2623,
        2625,
        2634,
        2637,
        2657,
        2660,
        2662,
        2664,
        2698,
        2710,
        2737,
        2741,
        2742,
        2752,
        2753,
        2758,
        2770,
        2783,
        2785,
        2787,
        2788,
        2789
    ],
    "troop 14": [
        12,
        35,
        1012,
        1308,
        1403,
        1410,
        1608,
        1802,
        1937,
        2641,
        2643
    ],
    "roseville": [
        12,
        13
    ],
    "explorer 11": [
        13,
        551
    ],
    "explorer 510": [
        14
    ],
    "indiana": [
        14,
        15,
        16,
        154,
        227,
        344,
        374,
        394,
        522,
        685,
        715,
        735,
        881,
        884,
        926,
        930,
        940,
        1007,
        1104,
        1176,
        1229,
        1355,
        1356,
        1400,
        1501,
        1524,
        1526,
        1637,
        1662,
        1728,
        1776,
        1913,
        1914,
        2005,
        2015,
        2059,
        2078,
        2112,
        2168,
        2169,
        2186,
        2188,
        2238,
        2239,
        2244,
        2246,
        2257,
        2283,
        2349,
        2408,
        2522,
        2526,
        2549,
        2555,
        2556,
        2558,
        2595,
        2600,
        2611,
        2797
    ],
    "merritt, randall b.": [
        14,
        15,
        16
    ],
    "indianapolis": [
        14,
        15,
        16,
        154,
        374,
        881,
        1104,
        1355,
        1356,
        1637,
        1913,
        1914,
        2186,
        2188,
        2349
    ],
    "1982": [
        14,
        15,
        16,
        142,
        143,
        268,
        269,
        283,
        284,
        328,
        353,
        367,
        485,
        486,
        539,
        599,
        658,
        675,
        687,
        688,
        762,
        852,
        1130,
        1152,
        1189,
        1191,
        1233,
        1234,
        1298,
        1326,
        1411,
        1565,
        1594,
        1617,
        1691,
        1693,
        1706,
        1806,
        1841,
        1983,
        2010,
        2081,
        2121
    ],
    "explorer 123": [
        15
    ],
    "explorer 12": [
        16
    ],
    "troop 5": [
        17,
        744,
        767,
        1055,
        1086,
        1829,
        1998,
        2067,
        2660,
        2661
    ],
    "west virginia": [
        17,
        18,
        19,
        72,
        211,
        230,
        231,
        299,
        379,
        495,
        801,
        802,
        891,
        1116,
        1427,
        1962,
        2642
    ],
    "liontakis, luigi e.": [
        17,
        18,
        19
    ],
    "huntington": [
        17,
        18,
        19,
        211,
        230,
        231,
        1883
    ],
    "1964": [
        17,
        18,
        19,
        2164,
        2165,
        2168,
        2169,
        2186,
        2188,
        2256,
        2288,
        2290,
        2295,
        2296,
        2327,
        2343,
        2347,
        2352,
        2354,
        2372,
        2375,
        2397,
        2398,
        2400,
        2416,
        2421,
        2444,
        2466,
        2473,
        2497,
        2499,
        2517,
        2520,
        2526,
        2540,
        2546,
        2563,
        2566,
        2581,
        2614,
        2619,
        2624,
        2626,
        2639,
        2667,
        2687,
        2694,
        2704,
        2719,
        2733,
        2757,
        2760,
        2774,
        2775,
        2777
    ],
    "troop 13": [
        19,
        1160,
        1226,
        1319,
        1355,
        1614,
        1621,
        1863,
        2086,
        2635,
        2636,
        2647,
        2649,
        2652
    ],
    "pack 1": [
        20,
        1795
    ],
    "new york": [
        20,
        21,
        22,
        23,
        67,
        68,
        69,
        115,
        116,
        133,
        134,
        135,
        136,
        137,
        138,
        168,
        169,
        170,
        215,
        216,
        255,
        271,
        273,
        281,
        318,
        357,
        369,
        383,
        399,
        404,
        461,
        492,
        507,
        523,
        524,
        549,
        552,
        553,
        584,
        592,
        600,
        601,
        630,
        635,
        636,
        637,
        640,
        645,
        648,
        682,
        686,
        695,
        696,
        752,
        753,
        803,
        804,
        807,
        808,
        844,
        846,
        874,
        878,
        948,
        950,
        989,
        992,
        994,
        1002,
        1004,
        1031,
        1065,
        1079,
        1090,
        1165,
        1177,
        1189,
        1196,
        1236,
        1238,
        1280,
        1282,
        1283,
        1365,
        1388,
        1392,
        1402,
        1415,
        1416,
        1417,
        1420,
        1471,
        1479,
        1480,
        1481,
        1492,
        1596,
        1598,
        1600,
        1604,
        1650,
        1653,
        1667,
        1700,
        1710,
        1714,
        1730,
        1788,
        1825,
        1845,
        1847,
        1853,
        1879,
        1880,
        1883,
        1884,
        1954,
        1963,
        1978,
        1998,
        2002,
        2031,
        2036,
        2037,
        2046,
        2068,
        2107,
        2167,
        2185,
        2187,
        2207,
        2209,
        2216,
        2225,
        2226,
        2227,
        2228,
        2229,
        2230,
        2231,
        2294,
        2298,
        2331,
        2337,
        2343,
        2347,
        2351,
        2354,
        2362,
        2364,
        2365,
        2380,
        2395,
        2432,
        2440,
        2447,
        2460,
        2466,
        2492,
        2496,
        2511,
        2514,
        2518,
        2541,
        2550,
        2560,
        2582,
        2606,
        2607,
        2622,
        2623,
        2625,
        2630,
        2635,
        2672,
        2776,
        2783,
        2785,
        2791,
        2793
    ],
    "gonzalez, guillermo": [
        20,
        21,
        22,
        23
    ],
    "baldwin": [
        20,
        21
    ],
    "1984": [
        20,
        21,
        22,
        23,
        79,
        80,
        81,
        87,
        88,
        89,
        90,
        110,
        159,
        160,
        161,
        207,
        214,
        217,
        233,
        248,
        259,
        261,
        266,
        276,
        280,
        382,
        399,
        403,
        404,
        406,
        409,
        421,
        463,
        465,
        466,
        491,
        496,
        498,
        523,
        524,
        529,
        558,
        560,
        570,
        572,
        608,
        641,
        654,
        822,
        824,
        829,
        830,
        855,
        856,
        862,
        906,
        914,
        984,
        1014,
        1070,
        1080,
        1090,
        1099,
        1101,
        1103,
        1105,
        1106,
        1111,
        1134,
        1151,
        1193,
        1230,
        1231,
        1242,
        1285,
        1292,
        1362,
        1366,
        1394,
        1396,
        1533,
        1535,
        1578,
        1595,
        1757,
        1773,
        1811,
        1814,
        1858,
        1860,
        1862,
        1878,
        1887,
        1895,
        1907,
        1941,
        2008,
        2023,
        2051,
        2060,
        2094,
        2103,
        2104,
        2112
    ],
    "pack 217": [
        21
    ],
    "pack 18": [
        22,
        1889
    ],
    "freeport": [
        22
    ],
    "troop 138": [
        23,
        96,
        858,
        1015,
        2505
    ],
    "freeport ": [
        23
    ],
    "sea scouts 50": [
        24
    ],
    "wisconsin": [
        24,
        25,
        26,
        27,
        45,
        46,
        64,
        111,
        150,
        151,
        152,
        155,
        156,
        157,
        247,
        285,
        350,
        351,
        364,
        368,
        551,
        576,
        625,
        717,
        912,
        927,
        952,
        1041,
        1083,
        1102,
        1141,
        1159,
        1166,
        1193,
        1194,
        1237,
        1239,
        1429,
        1455,
        1504,
        1552,
        1581,
        1593,
        1621,
        1720,
        1842,
        1912,
        1979,
        1980,
        2064,
        2084,
        2102,
        2105,
        2133,
        2284,
        2286,
        2361,
        2393,
        2499,
        2513,
        2605,
        2640
    ],
    "gort, jack c.": [
        24,
        25,
        26,
        27
    ],
    "sheboygan": [
        24,
        25,
        26,
        27,
        2284,
        2286
    ],
    "troop 4": [
        25,
        70,
        210,
        370,
        1078,
        1207,
        1227,
        1295,
        2264,
        2292
    ],
    "troop 18": [
        26,
        475,
        599,
        817,
        1048,
        1753,
        1843,
        1890,
        2121,
        2277,
        2633
    ],
    "troop 19": [
        27,
        486,
        1181,
        1220,
        1831,
        1974,
        2774
    ],
    "troop 28": [
        28,
        668,
        967,
        2263,
        2608,
        2609,
        2611,
        2613
    ],
    "north carolina": [
        28,
        29,
        30,
        60,
        61,
        62,
        63,
        289,
        400,
        418,
        430,
        471,
        496,
        590,
        618,
        619,
        720,
        721,
        769,
        814,
        815,
        821,
        946,
        965,
        975,
        1008,
        1025,
        1233,
        1234,
        1353,
        1602,
        1615,
        1676,
        1803,
        2008,
        2012,
        2014,
        2328,
        2334,
        2411,
        2786
    ],
    "hardin, terry": [
        28,
        29,
        30
    ],
    "raleigh": [
        28,
        29,
        30,
        720,
        721
    ],
    "1989": [
        28,
        29,
        30,
        44,
        45,
        46,
        86,
        104,
        105,
        106,
        130,
        131,
        132,
        190,
        210,
        213,
        215,
        216,
        228,
        229,
        235,
        240,
        247,
        255,
        256,
        257,
        258,
        265,
        272,
        294,
        303,
        311,
        325,
        326,
        327,
        331,
        334,
        357,
        361,
        366,
        369,
        377,
        378,
        388,
        389,
        390,
        391,
        396,
        397,
        400,
        401,
        408,
        415,
        420,
        433,
        438,
        449,
        450,
        456,
        458,
        472,
        483,
        487,
        495,
        499,
        503,
        518,
        544,
        548,
        550,
        551,
        569,
        596,
        607,
        611,
        612,
        627,
        628,
        639,
        665,
        668,
        669,
        671,
        672,
        673,
        705,
        714,
        716,
        717,
        727,
        728,
        731,
        733,
        734,
        749,
        750,
        767,
        787,
        790,
        794,
        795,
        811,
        812,
        821,
        832,
        833,
        874,
        876,
        881,
        882,
        883,
        884,
        895,
        910,
        927,
        949,
        952,
        963,
        971,
        974,
        975,
        977,
        978,
        985,
        987,
        993,
        996,
        1005,
        1007,
        1010,
        1015,
        1017,
        1020,
        1023,
        1029,
        1041,
        1061,
        1065,
        1068,
        1071,
        1072,
        1074,
        1078,
        1104,
        1117,
        1118,
        1119,
        1121,
        1141,
        1146,
        1161,
        1164,
        1165,
        1166,
        1167,
        1169,
        1170,
        1178,
        1182,
        1184,
        1188,
        1200,
        1202,
        1209,
        1228,
        1235,
        1245,
        1249,
        1272,
        1281,
        1286,
        1288,
        1304,
        1308,
        1309,
        1330,
        1332,
        1333,
        1335,
        1375,
        1378,
        1379,
        1382,
        1383,
        1386,
        1392,
        1393,
        1417,
        1421,
        1427,
        1434,
        1435,
        1436,
        1448,
        1455,
        1459,
        1461,
        1468,
        1477,
        1482,
        1483,
        1486,
        1487,
        1497,
        1522,
        1528,
        1532,
        1539,
        1548,
        1549,
        1550,
        1551,
        1566,
        1572,
        1573,
        1584,
        1585,
        1586,
        1587,
        1590,
        1604,
        1621,
        1623,
        1657,
        1666,
        1673,
        1683,
        1692,
        1702,
        1705,
        1708,
        1716,
        1730,
        1734,
        1744,
        1748,
        1751,
        1752,
        1761,
        1763,
        1768,
        1771,
        1777,
        1779,
        1780,
        1783,
        1792,
        1798,
        1803,
        1826,
        1844,
        1849,
        1851,
        1857,
        1859,
        1864,
        1865,
        1877,
        1883,
        1884,
        1885,
        1891,
        1892,
        1893,
        1894,
        1896,
        1899,
        1900,
        1904,
        1905,
        1908,
        1915,
        1918,
        1919,
        1931,
        1932,
        1948,
        1950,
        1951,
        1953,
        1959,
        1961,
        1965,
        1966,
        1981,
        1984,
        1989,
        1990,
        1992,
        2001,
        2012,
        2015,
        2019,
        2029,
        2039,
        2048,
        2049,
        2084,
        2086,
        2097,
        2099,
        2107,
        2108,
        2118,
        2123,
        2130,
        2147,
        2149
    ],
    "troop 387": [
        29
    ],
    "troop 33": [
        30,
        331,
        464,
        1327,
        2597,
        2599
    ],
    "troop 79": [
        31,
        167,
        989,
        1116,
        2253
    ],
    "washington": [
        31,
        32,
        33,
        222,
        239,
        291,
        325,
        377,
        388,
        392,
        421,
        520,
        660,
        664,
        671,
        672,
        689,
        737,
        768,
        885,
        916,
        979,
        1052,
        1067,
        1069,
        1222,
        1225,
        1228,
        1253,
        1318,
        1322,
        1331,
        1437,
        1553,
        1561,
        1595,
        1742,
        1743,
        1958,
        1968,
        1969,
        2154,
        2155,
        2156,
        2171,
        2301,
        2329,
        2421,
        2462,
        2501,
        2549,
        2586,
        2609,
        2658,
        2665,
        2794
    ],
    "matlock, james r.": [
        31,
        32,
        33
    ],
    "snohomish": [
        31,
        32,
        33
    ],
    "1991": [
        31,
        32,
        33,
        53,
        54,
        55,
        56,
        153,
        219,
        225,
        239,
        242,
        243,
        244,
        250,
        251,
        260,
        262,
        290,
        305,
        315,
        336,
        342,
        387,
        414,
        436,
        478,
        500,
        504,
        512,
        516,
        522,
        538,
        571,
        582,
        585,
        593,
        609,
        610,
        618,
        619,
        626,
        646,
        685,
        704,
        706,
        707,
        726,
        732,
        737,
        739,
        757,
        758,
        763,
        765,
        766,
        784,
        785,
        786,
        796,
        827,
        838,
        861,
        865,
        871,
        886,
        888,
        892,
        934,
        935,
        939,
        947,
        965,
        1035,
        1037,
        1046,
        1057,
        1077,
        1079,
        1081,
        1086,
        1093,
        1095,
        1128,
        1135,
        1140,
        1142,
        1155,
        1212,
        1213,
        1260,
        1266,
        1287,
        1289,
        1307,
        1314,
        1315,
        1316,
        1320,
        1331,
        1334,
        1348,
        1367,
        1409,
        1410,
        1424,
        1456,
        1464,
        1489,
        1506,
        1509,
        1511,
        1568,
        1574,
        1581,
        1588,
        1589,
        1616,
        1627,
        1638,
        1654,
        1659,
        1669,
        1698,
        1712,
        1724,
        1727,
        1769,
        1770,
        1795,
        1797,
        1801,
        1805,
        1807,
        1832,
        1833,
        1838,
        1840,
        1848,
        1853,
        1866,
        1901,
        1916,
        1924,
        1930,
        1935,
        1949,
        1971,
        1978,
        1979,
        1980,
        1982,
        2034,
        2085,
        2093,
        2096,
        2114,
        2120,
        2133,
        2135,
        2797
    ],
    "pack 45": [
        32
    ],
    "troop 36": [
        33,
        405,
        1486,
        1774,
        2591,
        2592
    ],
    "troop 16": [
        34,
        386,
        807,
        1139,
        1166,
        1664,
        2127,
        2281,
        2283,
        2637
    ],
    "arkansas": [
        34,
        35,
        212,
        414,
        446,
        537,
        1019,
        1304,
        2138
    ],
    "otts, sam c.": [
        34,
        35
    ],
    "hot springs ": [
        34
    ],
    "hot springs": [
        35
    ],
    "68": [
        36
    ],
    "ohio": [
        36,
        37,
        38,
        94,
        95,
        96,
        97,
        189,
        228,
        246,
        268,
        269,
        270,
        280,
        302,
        310,
        321,
        322,
        346,
        349,
        356,
        380,
        381,
        389,
        390,
        391,
        405,
        456,
        501,
        533,
        559,
        574,
        607,
        650,
        651,
        677,
        678,
        679,
        680,
        725,
        848,
        863,
        924,
        939,
        984,
        1012,
        1066,
        1096,
        1143,
        1158,
        1167,
        1169,
        1201,
        1273,
        1279,
        1302,
        1335,
        1376,
        1381,
        1387,
        1398,
        1411,
        1486,
        1611,
        1613,
        1614,
        1619,
        1628,
        1689,
        1704,
        1748,
        1749,
        1759,
        1761,
        1763,
        1799,
        1810,
        1812,
        1815,
        1826,
        1833,
        1858,
        1906,
        1915,
        1916,
        1931,
        1932,
        1999,
        2001,
        2003,
        2004,
        2033,
        2071,
        2074,
        2076,
        2131,
        2132,
        2137,
        2194,
        2195,
        2208,
        2210,
        2254,
        2258,
        2276,
        2277,
        2297,
        2340,
        2359,
        2392,
        2407,
        2448,
        2454,
        2467,
        2471,
        2495,
        2564,
        2573,
        2593,
        2598,
        2602,
        2626,
        2789
    ],
    "keyes, brian thomas": [
        36,
        37,
        38
    ],
    "lakewood ": [
        36
    ],
    "1985": [
        36,
        37,
        38,
        119,
        120,
        121,
        122,
        168,
        169,
        170,
        189,
        203,
        204,
        205,
        206,
        230,
        231,
        281,
        286,
        308,
        319,
        332,
        333,
        370,
        371,
        384,
        394,
        431,
        432,
        435,
        437,
        441,
        442,
        467,
        468,
        469,
        476,
        510,
        511,
        556,
        586,
        587,
        600,
        601,
        657,
        666,
        667,
        682,
        683,
        801,
        802,
        819,
        820,
        844,
        868,
        870,
        872,
        894,
        896,
        911,
        958,
        995,
        1027,
        1032,
        1033,
        1042,
        1058,
        1064,
        1108,
        1112,
        1133,
        1143,
        1187,
        1190,
        1241,
        1248,
        1265,
        1274,
        1275,
        1293,
        1294,
        1373,
        1390,
        1395,
        1422,
        1453,
        1457,
        1463,
        1465,
        1488,
        1493,
        1580,
        1605,
        1636,
        1665,
        1671,
        1704,
        1707,
        1758,
        1760,
        1766,
        1867,
        1934,
        1967,
        1974,
        1975,
        1976,
        1977,
        2007,
        2021,
        2050,
        2052,
        2055,
        2059,
        2061,
        2062,
        2067,
        2069,
        2088,
        2089,
        2091,
        2131,
        2144
    ],
    "727": [
        37
    ],
    "lakewood": [
        37
    ],
    "41": [
        38
    ],
    "bayvillage": [
        38
    ],
    "troop 66": [
        39,
        86,
        296,
        470,
        1313,
        2567,
        2569
    ],
    "nebraska": [
        39,
        40,
        41,
        758,
        767,
        1037,
        1197,
        1272,
        1300,
        1610,
        1809,
        1889,
        1890,
        1909,
        1920,
        1992,
        2177,
        2179,
        2250,
        2384,
        2391
    ],
    "shockley, douglas a.": [
        39,
        40,
        41
    ],
    "lincoln": [
        39,
        40,
        41,
        767,
        1037,
        1088,
        1890
    ],
    "troop 46": [
        40,
        492,
        1031,
        1124,
        1704,
        1923,
        2016
    ],
    "troop 51": [
        41,
        257,
        283,
        727,
        1276,
        1555,
        1938,
        2585,
        2587
    ],
    "troop 555": [
        42,
        1230
    ],
    "willis, abrom": [
        42,
        43
    ],
    "1965": [
        42,
        43,
        91,
        92,
        93,
        101,
        102,
        103,
        199,
        200,
        201,
        2158,
        2159,
        2160,
        2213,
        2214,
        2225,
        2227,
        2331,
        2359,
        2384,
        2395,
        2396,
        2401,
        2404,
        2414,
        2418,
        2420,
        2429,
        2431,
        2460,
        2468,
        2491,
        2501,
        2504,
        2506,
        2514,
        2519,
        2524,
        2525,
        2527,
        2528,
        2541,
        2548,
        2554,
        2559,
        2562,
        2567,
        2570,
        2577,
        2580,
        2602,
        2606,
        2612,
        2615,
        2628,
        2641,
        2651,
        2654,
        2674,
        2676,
        2695,
        2700,
        2721,
        2739,
        2740,
        2744,
        2779
    ],
    "explorer 58": [
        43
    ],
    "warner robins": [
        43,
        92,
        93,
        976
    ],
    "troop 6": [
        44,
        175,
        613,
        719,
        873,
        899,
        1387,
        1750,
        1958,
        2296,
        2654,
        2656,
        2657,
        2673
    ],
    "minnesota": [
        44,
        142,
        143,
        296,
        355,
        449,
        457,
        575,
        855,
        990,
        1135,
        1150,
        1162,
        1349,
        1361,
        1478,
        1494,
        1518,
        1558,
        1569,
        1585,
        1647,
        1697,
        1821,
        1917,
        1945,
        2028,
        2453,
        2459,
        2486,
        2510,
        2592,
        2615,
        2633
    ],
    "hopkins, timothy r.": [
        44,
        45,
        46
    ],
    "winona": [
        44,
        1647
    ],
    "pack 59": [
        45,
        964
    ],
    "black river falls": [
        45,
        46
    ],
    "troop 59": [
        46,
        159,
        434,
        789,
        1142,
        1252,
        1932,
        2004,
        2120,
        2576
    ],
    "troop 118": [
        47,
        310,
        1463
    ],
    "maine": [
        47,
        48,
        49,
        50,
        263,
        402,
        429,
        521,
        529,
        560,
        783,
        1088,
        1264,
        1454,
        1523,
        1938,
        1953,
        1981,
        2473
    ],
    "baker, charles s.": [
        47,
        48,
        49,
        50
    ],
    "shirley mills": [
        47
    ],
    "1990": [
        47,
        48,
        49,
        50,
        154,
        155,
        156,
        157,
        171,
        172,
        173,
        191,
        192,
        193,
        194,
        212,
        221,
        224,
        232,
        234,
        278,
        285,
        299,
        301,
        307,
        310,
        312,
        314,
        317,
        338,
        344,
        365,
        386,
        427,
        439,
        443,
        444,
        445,
        460,
        464,
        477,
        494,
        508,
        519,
        521,
        532,
        534,
        540,
        554,
        563,
        566,
        588,
        594,
        643,
        647,
        649,
        650,
        651,
        656,
        661,
        708,
        745,
        768,
        771,
        774,
        775,
        776,
        788,
        791,
        797,
        798,
        846,
        853,
        854,
        863,
        904,
        920,
        926,
        932,
        946,
        950,
        955,
        962,
        979,
        1002,
        1009,
        1018,
        1025,
        1030,
        1036,
        1043,
        1052,
        1055,
        1056,
        1060,
        1084,
        1088,
        1100,
        1158,
        1162,
        1179,
        1208,
        1210,
        1215,
        1222,
        1227,
        1229,
        1246,
        1258,
        1263,
        1269,
        1296,
        1300,
        1302,
        1310,
        1321,
        1323,
        1337,
        1342,
        1345,
        1358,
        1363,
        1374,
        1376,
        1391,
        1400,
        1401,
        1402,
        1423,
        1432,
        1439,
        1441,
        1443,
        1444,
        1446,
        1454,
        1467,
        1498,
        1499,
        1502,
        1503,
        1505,
        1519,
        1524,
        1526,
        1530,
        1547,
        1552,
        1559,
        1561,
        1571,
        1582,
        1596,
        1597,
        1598,
        1599,
        1615,
        1634,
        1647,
        1648,
        1652,
        1660,
        1672,
        1678,
        1703,
        1713,
        1714,
        1719,
        1739,
        1740,
        1742,
        1743,
        1750,
        1782,
        1786,
        1787,
        1788,
        1789,
        1791,
        1796,
        1800,
        1827,
        1828,
        1834,
        1845,
        1847,
        1855,
        1856,
        1868,
        1869,
        1870,
        1898,
        1944,
        1947,
        1958,
        1964,
        1968,
        1969,
        1973,
        1985,
        2005,
        2013,
        2022,
        2026,
        2043,
        2044,
        2054,
        2056,
        2066,
        2071,
        2087,
        2106,
        2134,
        2136
    ],
    "troop 120": [
        48,
        93,
        326,
        447,
        1067,
        1475
    ],
    "greenville": [
        48,
        701,
        702,
        2026,
        2093
    ],
    "troop 62": [
        49,
        287,
        387,
        718,
        971,
        1861,
        2571
    ],
    "wellington": [
        49
    ],
    "troop 117": [
        50,
        675,
        705,
        1164,
        1168,
        1359,
        1576,
        1619
    ],
    "guildford": [
        50
    ],
    "troop 40": [
        51,
        177,
        996,
        1301,
        1559,
        1641,
        2042,
        2302,
        2600
    ],
    "illinois": [
        51,
        81,
        191,
        192,
        193,
        194,
        195,
        196,
        197,
        198,
        199,
        200,
        201,
        229,
        275,
        358,
        359,
        401,
        423,
        452,
        571,
        586,
        593,
        605,
        647,
        652,
        716,
        719,
        736,
        742,
        748,
        764,
        781,
        782,
        794,
        795,
        813,
        857,
        858,
        879,
        898,
        900,
        903,
        914,
        931,
        933,
        949,
        955,
        956,
        1018,
        1040,
        1049,
        1051,
        1054,
        1055,
        1117,
        1139,
        1172,
        1186,
        1209,
        1224,
        1226,
        1294,
        1307,
        1308,
        1358,
        1362,
        1369,
        1370,
        1383,
        1395,
        1409,
        1410,
        1485,
        1547,
        1549,
        1550,
        1551,
        1584,
        1586,
        1608,
        1635,
        1639,
        1665,
        1718,
        1731,
        1738,
        1741,
        1750,
        1768,
        1775,
        1789,
        1829,
        1831,
        1865,
        1951,
        1959,
        1983,
        2026,
        2053,
        2080,
        2082,
        2125,
        2127,
        2150,
        2151,
        2197,
        2199,
        2234,
        2236,
        2240,
        2256,
        2274,
        2289,
        2291,
        2292,
        2293,
        2295,
        2296,
        2314,
        2317,
        2396,
        2414,
        2445,
        2491,
        2494,
        2505,
        2544,
        2547,
        2604,
        2637,
        2707,
        2787,
        2788,
        2796
    ],
    "morris, frank h.": [
        51,
        52
    ],
    "st. joseph": [
        51,
        945,
        1371,
        1616
    ],
    "1986": [
        51,
        52,
        147,
        148,
        149,
        236,
        237,
        270,
        287,
        345,
        380,
        381,
        392,
        424,
        479,
        484,
        488,
        515,
        517,
        541,
        543,
        555,
        579,
        580,
        581,
        592,
        622,
        652,
        653,
        655,
        670,
        696,
        780,
        783,
        789,
        828,
        840,
        841,
        860,
        877,
        915,
        916,
        923,
        924,
        997,
        1006,
        1012,
        1049,
        1051,
        1063,
        1096,
        1109,
        1129,
        1131,
        1132,
        1168,
        1201,
        1206,
        1225,
        1240,
        1251,
        1291,
        1341,
        1357,
        1359,
        1368,
        1369,
        1370,
        1372,
        1380,
        1381,
        1389,
        1398,
        1406,
        1408,
        1415,
        1425,
        1458,
        1460,
        1479,
        1481,
        1495,
        1501,
        1534,
        1570,
        1649,
        1668,
        1676,
        1690,
        1696,
        1699,
        1710,
        1764,
        1765,
        1776,
        1808,
        1809,
        1813,
        1816,
        1817,
        1819,
        1822,
        1839,
        1881,
        1886,
        1889,
        1890,
        1906,
        1909,
        1940,
        1954,
        1986,
        1993,
        1994,
        1995,
        2002,
        2027,
        2033,
        2036,
        2037,
        2045,
        2078,
        2083,
        2113,
        2117,
        2137,
        2796
    ],
    "troop 76": [
        52,
        154,
        1724,
        2053,
        2285,
        2549,
        2551,
        2552
    ],
    "pennsylvania": [
        52,
        65,
        66,
        86,
        130,
        131,
        132,
        235,
        236,
        237,
        261,
        265,
        290,
        300,
        353,
        472,
        489,
        546,
        567,
        627,
        628,
        665,
        668,
        673,
        683,
        710,
        714,
        718,
        730,
        755,
        757,
        785,
        786,
        840,
        841,
        843,
        847,
        851,
        852,
        962,
        1024,
        1034,
        1144,
        1152,
        1164,
        1168,
        1219,
        1295,
        1351,
        1407,
        1436,
        1456,
        1469,
        1476,
        1509,
        1511,
        1572,
        1603,
        1620,
        1649,
        1660,
        1734,
        1786,
        1787,
        1801,
        1806,
        1817,
        1823,
        1886,
        1901,
        1918,
        1919,
        1933,
        1940,
        1952,
        1972,
        1974,
        1975,
        1976,
        1977,
        2011,
        2013,
        2041,
        2086,
        2103,
        2104,
        2128,
        2152,
        2153,
        2166,
        2168,
        2169,
        2196,
        2201,
        2202,
        2204,
        2271,
        2299,
        2306,
        2338,
        2350,
        2357,
        2363,
        2370,
        2377,
        2403,
        2435,
        2436,
        2439,
        2444,
        2475,
        2477,
        2478,
        2481,
        2484,
        2493,
        2529,
        2531,
        2567,
        2577,
        2590,
        2618,
        2650,
        2653,
        2673
    ],
    "feasterville": [
        52
    ],
    "explorer 697": [
        53,
        1582
    ],
    "kennedy, daniel w.": [
        53,
        54,
        55,
        56
    ],
    "atlanta": [
        53,
        54,
        55,
        56,
        1713,
        2094
    ],
    "troop 70": [
        54,
        378,
        541,
        689,
        796,
        1444,
        2548,
        2550
    ],
    "troop 550": [
        56
    ],
    "troop 372": [
        57,
        1684,
        1902,
        2402
    ],
    "michigan": [
        57,
        58,
        59,
        101,
        102,
        103,
        159,
        160,
        161,
        232,
        256,
        260,
        345,
        367,
        375,
        376,
        435,
        488,
        516,
        527,
        538,
        565,
        617,
        629,
        765,
        766,
        793,
        845,
        861,
        862,
        865,
        868,
        870,
        894,
        896,
        904,
        938,
        954,
        960,
        1003,
        1098,
        1100,
        1105,
        1106,
        1108,
        1130,
        1149,
        1227,
        1374,
        1443,
        1502,
        1528,
        1568,
        1601,
        1609,
        1634,
        1691,
        1692,
        1693,
        1757,
        1780,
        1793,
        1835,
        1857,
        1925,
        1930,
        2009,
        2035,
        2043,
        2069,
        2081,
        2090,
        2093,
        2143,
        2311,
        2312,
        2315,
        2319,
        2324,
        2325,
        2369,
        2378,
        2431,
        2441,
        2443,
        2485,
        2537,
        2568,
        2613,
        2648
    ],
    "martin, michael james": [
        57,
        58,
        59
    ],
    "durand": [
        57,
        58,
        59
    ],
    "troop 84": [
        58,
        1123,
        2249
    ],
    "pack 84": [
        59
    ],
    "pack 88": [
        60,
        2536
    ],
    "cameron, talmond morris": [
        60,
        61,
        62,
        63
    ],
    "mccain": [
        60,
        61,
        62,
        63
    ],
    "troop 85": [
        61,
        644,
        1607
    ],
    "troop 88": [
        62,
        1198,
        2017
    ],
    "troop 686": [
        63,
        2156
    ],
    "pack 1088": [
        64
    ],
    "longway, brian paul": [
        64,
        65,
        66
    ],
    "marinette": [
        64
    ],
    "1973": [
        64,
        65,
        66,
        282,
        330,
        473,
        526,
        547,
        620,
        621,
        630,
        631,
        632,
        681,
        690,
        691,
        694,
        723,
        724,
        746,
        755,
        756,
        831,
        851,
        921,
        936,
        1098,
        1127,
        1199,
        1211,
        1247,
        1388,
        1473,
        1504,
        1520,
        1525,
        1536,
        1538,
        1564,
        1602,
        1643,
        1689,
        1902,
        2003,
        2004,
        2024,
        2047
    ],
    "troop 135": [
        65,
        809,
        2100,
        2230
    ],
    "parkesburg": [
        65
    ],
    "troop 90": [
        66,
        393,
        871,
        1816,
        2198
    ],
    "mechanicsburg": [
        66
    ],
    "troop 356": [
        67
    ],
    "harper, arthur": [
        67,
        68,
        69
    ],
    "rochester ": [
        67,
        686,
        990,
        1492
    ],
    "1972": [
        67,
        68,
        69,
        163,
        164,
        165,
        223,
        267,
        296,
        304,
        352,
        356,
        358,
        359,
        407,
        470,
        471,
        520,
        617,
        676,
        711,
        712,
        809,
        810,
        866,
        867,
        873,
        891,
        893,
        933,
        948,
        1004,
        1045,
        1126,
        1144,
        1174,
        1181,
        1194,
        1195,
        1360,
        1365,
        1407,
        1416,
        1469,
        1492,
        1556,
        1575,
        1591,
        1592,
        1603,
        1688,
        1720,
        1753,
        1756,
        1762,
        1799,
        1825,
        1852,
        1854,
        1921,
        1997,
        1998,
        2016,
        2058,
        2064,
        2068
    ],
    "troop 98": [
        68,
        593,
        691,
        2250
    ],
    "rochester": [
        68,
        69,
        318,
        369,
        577,
        1228,
        1650,
        1653,
        1739,
        2216,
        2440,
        2447,
        2791,
        2793
    ],
    "troop 100": [
        69,
        534,
        1553,
        1842,
        1862,
        1920,
        2246,
        2533,
        2535
    ],
    "maryland": [
        70,
        71,
        91,
        112,
        123,
        124,
        125,
        126,
        292,
        365,
        409,
        642,
        705,
        890,
        917,
        918,
        1076,
        1084,
        1247,
        1266,
        1287,
        1289,
        1413,
        1414,
        1447,
        1449,
        1612,
        1694,
        1723,
        1732,
        1982,
        2054,
        2056,
        2126,
        2158,
        2159,
        2161,
        2162,
        2175,
        2176,
        2307,
        2322,
        2330,
        2332,
        2389,
        2437,
        2500,
        2576,
        2581
    ],
    "margulies, arthur d.": [
        70,
        71,
        72
    ],
    "cumberland": [
        70
    ],
    "1980": [
        70,
        71,
        72,
        180,
        181,
        182,
        183,
        292,
        309,
        451,
        505,
        506,
        507,
        559,
        623,
        738,
        807,
        808,
        834,
        957,
        959,
        991,
        1011,
        1172,
        1232,
        1254,
        1256,
        1270,
        1514,
        1554,
        1579,
        1645,
        1772,
        1842,
        2028,
        2038,
        2040,
        2042
    ],
    "troop 256": [
        71,
        90
    ],
    "wheaton": [
        71,
        716
    ],
    "troop 101": [
        72,
        411,
        652,
        1254,
        1586,
        1605,
        1680,
        2525
    ],
    "sutton": [
        72
    ],
    "massachusetts": [
        73,
        74,
        75,
        82,
        83,
        84,
        85,
        166,
        167,
        174,
        175,
        176,
        224,
        252,
        331,
        382,
        384,
        403,
        437,
        451,
        485,
        486,
        530,
        534,
        548,
        620,
        621,
        653,
        729,
        732,
        744,
        747,
        823,
        826,
        856,
        875,
        932,
        970,
        972,
        1093,
        1160,
        1182,
        1184,
        1254,
        1256,
        1259,
        1261,
        1276,
        1293,
        1309,
        1326,
        1330,
        1347,
        1397,
        1404,
        1412,
        1605,
        1668,
        1677,
        1721,
        1739,
        1779,
        1784,
        1795,
        1830,
        1893,
        1899,
        1900,
        1939,
        2044,
        2170,
        2193,
        2219,
        2220,
        2232,
        2263,
        2310,
        2348,
        2535,
        2572,
        2575,
        2616,
        2629,
        2781
    ],
    "gates, richard d.": [
        73,
        74,
        75
    ],
    "worcester": [
        73,
        74,
        75,
        224,
        403,
        875,
        1259,
        1261,
        1677,
        1893,
        2263
    ],
    "1961": [
        73,
        74,
        75,
        2163,
        2166,
        2167,
        2171,
        2198,
        2200,
        2205,
        2206,
        2221,
        2222,
        2223,
        2224,
        2244,
        2246,
        2284,
        2286,
        2302,
        2303,
        2310,
        2314,
        2392,
        2399,
        2419,
        2432,
        2434,
        2441,
        2458,
        2461,
        2464,
        2513,
        2521,
        2560,
        2575,
        2583,
        2584,
        2588,
        2591,
        2603,
        2605,
        2613,
        2630,
        2631,
        2640,
        2650,
        2681,
        2686,
        2689,
        2690,
        2703,
        2706,
        2715,
        2724,
        2730,
        2731
    ],
    "troop 63": [
        74,
        127,
        223,
        1000,
        1102,
        1180,
        1893,
        1897,
        2046,
        2566,
        2568,
        2570
    ],
    "troop 103": [
        75,
        471,
        530,
        1234,
        1397,
        2229,
        2767
    ],
    "troop 55": [
        76,
        878,
        1054,
        1127,
        2577,
        2578
    ],
    "new jersey": [
        76,
        77,
        78,
        117,
        207,
        257,
        287,
        303,
        328,
        343,
        385,
        439,
        474,
        475,
        478,
        491,
        505,
        506,
        510,
        511,
        539,
        561,
        613,
        623,
        641,
        727,
        746,
        772,
        780,
        796,
        817,
        818,
        839,
        860,
        867,
        873,
        895,
        996,
        1011,
        1092,
        1094,
        1128,
        1202,
        1284,
        1359,
        1560,
        1671,
        1705,
        1753,
        1756,
        1762,
        1811,
        1814,
        1960,
        2109,
        2181,
        2183,
        2285,
        2287,
        2376,
        2406,
        2515,
        2583,
        2588,
        2589,
        2591,
        2677
    ],
    "hollender, john f.": [
        76,
        77,
        78
    ],
    "berlin": [
        76,
        856,
        1319
    ],
    "troop 102": [
        77,
        1895,
        2208
    ],
    "woodlyn": [
        77
    ],
    "troop 105": [
        78,
        249,
        375,
        522,
        2041,
        2243,
        2523
    ],
    "collingswood": [
        78
    ],
    "troop 393": [
        79
    ],
    "new hampshire": [
        79,
        80,
        330,
        577,
        622,
        657,
        675,
        754,
        936,
        1241,
        1508,
        1510,
        1993,
        2427,
        2525,
        2527
    ],
    "cote, alfred francis": [
        79,
        80,
        81
    ],
    "manchester": [
        79,
        675,
        2525,
        2527
    ],
    "troop 412": [
        80,
        981
    ],
    "derry": [
        80
    ],
    "troop 107": [
        81,
        1136,
        2245,
        2259,
        2521
    ],
    "chicago": [
        81,
        191,
        192,
        193,
        194,
        195,
        196,
        197,
        198,
        199,
        200,
        201,
        358,
        359,
        813,
        914,
        1485,
        1547,
        1551,
        2125,
        2127,
        2150,
        2151,
        2234,
        2236,
        2314,
        2317,
        2787,
        2788,
        2796
    ],
    "troop 34": [
        82,
        83,
        931,
        1171,
        1256,
        1303,
        1350,
        1645,
        1957,
        2595
    ],
    "bulshey, mark t.": [
        82,
        83,
        84,
        85
    ],
    "north adams": [
        82,
        83,
        84,
        85
    ],
    "1976": [
        82,
        83,
        84,
        85,
        115,
        116,
        117,
        300,
        385,
        493,
        562,
        697,
        720,
        721,
        816,
        909,
        917,
        918,
        919,
        1021,
        1150,
        1217,
        1219,
        1220,
        1250,
        1437,
        1500,
        1516,
        1555,
        1641,
        1658,
        1667,
        1694,
        1709,
        1723,
        1810,
        1812,
        1955,
        1956,
        1957,
        2124,
        2778
    ],
    "troop 108": [
        84,
        560,
        1019
    ],
    "troop 125": [
        85,
        835,
        1839
    ],
    "gallardi, robert": [
        86
    ],
    "pittsburgh ": [
        86,
        668
    ],
    "troop 212": [
        87,
        970,
        1762,
        1905,
        2240,
        2459,
        2461
    ],
    "kansas": [
        87,
        90,
        342,
        431,
        432,
        458,
        579,
        580,
        581,
        602,
        666,
        667,
        1077,
        1129,
        1131,
        1174,
        1360,
        1386,
        1631,
        1646,
        1797,
        1808,
        1861,
        1967,
        2017,
        2221,
        2223,
        2341,
        2345,
        2415,
        2778
    ],
    "capra, john r.": [
        87,
        88,
        89,
        90
    ],
    "salina ": [
        87
    ],
    "troop 116": [
        88,
        938
    ],
    "missouri": [
        88,
        89,
        107,
        108,
        109,
        139,
        140,
        141,
        186,
        187,
        188,
        309,
        326,
        327,
        543,
        589,
        698,
        723,
        724,
        738,
        761,
        775,
        776,
        937,
        944,
        945,
        961,
        1000,
        1044,
        1072,
        1074,
        1097,
        1111,
        1133,
        1137,
        1138,
        1151,
        1223,
        1242,
        1252,
        1371,
        1432,
        1441,
        1470,
        1475,
        1500,
        1521,
        1555,
        1565,
        1570,
        1573,
        1587,
        1589,
        1616,
        1641,
        1644,
        1645,
        1652,
        1682,
        1684,
        1685,
        1687,
        1783,
        1798,
        1840,
        1875,
        1876,
        1891,
        1892,
        1902,
        1908,
        2092,
        2100,
        2173,
        2241,
        2251,
        2305,
        2323,
        2397,
        2409,
        2419,
        2428,
        2429,
        2498,
        2587
    ],
    "kansas city": [
        88,
        89,
        187,
        188,
        309,
        342,
        602,
        775,
        776,
        1072,
        1074,
        1111,
        1432,
        1587,
        1641,
        1798,
        1891,
        1892,
        1908,
        2092,
        2305,
        2419,
        2498
    ],
    "pack 116": [
        89,
        610,
        1689,
        1787
    ],
    "overland park": [
        90
    ],
    "troop 919": [
        91,
        1526
    ],
    "franklin, claudie hershel": [
        91,
        92,
        93
    ],
    "indian head": [
        91
    ],
    "troop 220": [
        92,
        2451
    ],
    "troop 60": [
        94,
        877,
        886,
        1852,
        2023,
        2043,
        2265,
        2575
    ],
    "mathisen, paul gerhard": [
        94,
        95,
        96,
        97
    ],
    "bucyrus": [
        94
    ],
    "pack ": [
        95,
        314,
        632,
        1283,
        1880,
        2063,
        2065
    ],
    "rockbridge": [
        95
    ],
    "sycamore": [
        96,
        97
    ],
    "pack 118": [
        97,
        703
    ],
    "troop 39": [
        98,
        424,
        908,
        1720,
        2603
    ],
    "pugh, henry": [
        98,
        99,
        100
    ],
    "1983": [
        98,
        99,
        100,
        112,
        113,
        114,
        177,
        178,
        179,
        238,
        246,
        289,
        337,
        364,
        374,
        395,
        410,
        426,
        428,
        492,
        629,
        718,
        792,
        842,
        900,
        903,
        913,
        925,
        929,
        990,
        1082,
        1091,
        1136,
        1139,
        1160,
        1198,
        1276,
        1278,
        1306,
        1351,
        1387,
        1403,
        1404,
        1405,
        1470,
        1475,
        1560,
        1569,
        1607,
        1619,
        1631,
        1633,
        1646,
        1718,
        1746,
        1754,
        1755,
        1759,
        1767,
        1784,
        1790,
        1903,
        1922,
        1936,
        1937,
        1942,
        1945,
        2014,
        2057,
        2074,
        2076,
        2095,
        2102,
        2105,
        2122,
        2141,
        2790,
        2792
    ],
    "pack 39": [
        99
    ],
    "pack 139": [
        100,
        321,
        322
    ],
    "troop 80": [
        101,
        930,
        1415,
        2546
    ],
    "clark, david earl": [
        101,
        102,
        103
    ],
    "utica": [
        101,
        102,
        103,
        1471,
        1700
    ],
    "explorer 80": [
        102
    ],
    "troop 141": [
        103,
        914,
        1370
    ],
    "troop 164": [
        104,
        947,
        1331,
        1799,
        2028
    ],
    "connecticut": [
        104,
        105,
        106,
        226,
        258,
        329,
        463,
        568,
        587,
        669,
        674,
        777,
        985,
        987,
        1064,
        1114,
        1181,
        1200,
        1292,
        1319,
        1350,
        1458,
        1460,
        1690,
        2115,
        2265,
        2267,
        2273,
        2275,
        2304,
        2333,
        2469,
        2571,
        2599,
        2601,
        2620,
        2641,
        2660,
        2664,
        2675,
        2779
    ],
    "dyber, james": [
        104,
        105,
        106
    ],
    "west hartford": [
        104,
        105,
        106
    ],
    "troop 44": [
        105,
        264,
        342,
        732,
        2118
    ],
    "troop 146": [
        106,
        206,
        299
    ],
    "pack 169": [
        107
    ],
    "woods, rolland l.": [
        107,
        108,
        109
    ],
    "independence": [
        107,
        108,
        109,
        186,
        961,
        1521,
        1565,
        1652
    ],
    "1974": [
        107,
        108,
        109,
        162,
        360,
        417,
        459,
        502,
        634,
        715,
        730,
        843,
        857,
        858,
        869,
        890,
        976,
        986,
        988,
        1066,
        1076,
        1102,
        1123,
        1156,
        1163,
        1183,
        1343,
        1353,
        1399,
        1471,
        1593,
        1650,
        1653,
        1830,
        1835,
        1879,
        1952
    ],
    "troop 206": [
        108,
        348,
        814
    ],
    "troop 169": [
        109,
        737,
        2477,
        2479
    ],
    "troop 186": [
        110,
        1010,
        2224,
        2478,
        2481
    ],
    "oregon": [
        110,
        259,
        264,
        316,
        515,
        583,
        662,
        670,
        699,
        739,
        779,
        792,
        809,
        810,
        834,
        907,
        908,
        911,
        947,
        988,
        1043,
        1311,
        1312,
        1380,
        1393,
        1451,
        1537,
        1544,
        1655,
        1656,
        1674,
        1736,
        1737,
        1834,
        2048,
        2097,
        2113,
        2336,
        2438,
        2450,
        2451,
        2534,
        2546,
        2596,
        2608,
        2610,
        2646,
        2661,
        2674
    ],
    "tobiassen, william e.": [
        110
    ],
    "corvallis ": [
        110
    ],
    "troop 191": [
        111,
        380
    ],
    "kunde, cliffe w.": [
        111
    ],
    "milwaukee": [
        111,
        155,
        156,
        157,
        368,
        1083,
        1581
    ],
    "troop 61": [
        112,
        408,
        1083,
        1405,
        2129,
        2271,
        2275,
        2573
    ],
    "horowitz, alan j.": [
        112,
        113,
        114
    ],
    "smithsburg": [
        112
    ],
    "pack 64": [
        113,
        1028
    ],
    "augusta": [
        113,
        1663,
        1866
    ],
    "troop 202": [
        114,
        340,
        1508
    ],
    "iowa": [
        114,
        241,
        434,
        465,
        466,
        892,
        973,
        1042,
        1268,
        1452,
        1474,
        1563,
        1576,
        1577,
        1580,
        1618,
        1624,
        1643,
        1722,
        1827,
        1828,
        1934,
        1966,
        2215,
        2217,
        2242,
        2243,
        2386,
        2420,
        2545,
        2632,
        2766
    ],
    "iowa city": [
        114,
        2215
    ],
    "pack 158": [
        115
    ],
    "jacoby, robert j.": [
        115,
        116,
        117
    ],
    "troop 158": [
        116,
        892,
        1775,
        2485,
        2487
    ],
    "new york city": [
        116
    ],
    "troop 205": [
        117
    ],
    "teaneck": [
        117
    ],
    "troop 208": [
        118,
        1751,
        2219
    ],
    "calabro, larry": [
        118
    ],
    "troop 565": [
        119,
        583,
        1420
    ],
    "texas": [
        119,
        120,
        121,
        122,
        158,
        171,
        172,
        173,
        213,
        217,
        220,
        240,
        245,
        335,
        352,
        354,
        361,
        420,
        427,
        436,
        444,
        445,
        481,
        482,
        494,
        499,
        508,
        566,
        591,
        609,
        610,
        624,
        638,
        684,
        692,
        731,
        787,
        791,
        797,
        819,
        820,
        876,
        886,
        888,
        889,
        905,
        941,
        943,
        963,
        1006,
        1009,
        1017,
        1022,
        1048,
        1080,
        1115,
        1140,
        1142,
        1180,
        1183,
        1195,
        1250,
        1260,
        1263,
        1270,
        1299,
        1328,
        1337,
        1339,
        1341,
        1354,
        1367,
        1373,
        1399,
        1424,
        1435,
        1459,
        1461,
        1467,
        1554,
        1559,
        1567,
        1583,
        1607,
        1636,
        1640,
        1669,
        1706,
        1715,
        1726,
        1729,
        1733,
        1740,
        1744,
        1766,
        1773,
        1790,
        1805,
        1832,
        1838,
        1855,
        1856,
        1868,
        1872,
        1904,
        1905,
        1986,
        2010,
        2024,
        2040,
        2042,
        2045,
        2095,
        2116,
        2135,
        2144,
        2147,
        2163,
        2172,
        2174,
        2203,
        2213,
        2214,
        2218,
        2278,
        2279,
        2288,
        2290,
        2320,
        2327,
        2346,
        2375,
        2390,
        2457,
        2465,
        2483,
        2507,
        2561,
        2603,
        2612,
        2628,
        2634,
        2639,
        2656,
        2780,
        2782
    ],
    "diaz, felix": [
        119,
        120,
        121,
        122
    ],
    "san antonia": [
        119
    ],
    "pack 565": [
        120,
        1904
    ],
    "san antonio ": [
        120
    ],
    "troop 216": [
        121,
        804,
        1122,
        1878,
        2453,
        2455
    ],
    "san antonio": [
        121,
        122,
        1260,
        1554,
        1706,
        1905,
        2010
    ],
    "12": [
        122,
        1954
    ],
    "troop 132": [
        123,
        472,
        1284,
        2794
    ],
    "culver, david a.": [
        123,
        124,
        125,
        126
    ],
    "elkton": [
        123,
        125,
        126,
        1694
    ],
    "1979": [
        123,
        124,
        125,
        126,
        241,
        316,
        418,
        419,
        453,
        533,
        589,
        799,
        835,
        879,
        1097,
        1154,
        1204,
        1207,
        1277,
        1279,
        1322,
        1426,
        1478,
        1508,
        1510,
        1846,
        1987,
        1988,
        2132,
        2780,
        2782,
        2786
    ],
    "troop 366": [
        124,
        2177,
        2407
    ],
    "perryville": [
        124,
        723
    ],
    "troop 238": [
        125,
        535,
        1351,
        1694
    ],
    "virginia": [
        127,
        163,
        164,
        165,
        254,
        295,
        333,
        336,
        396,
        397,
        419,
        514,
        649,
        706,
        707,
        708,
        822,
        825,
        922,
        998,
        1014,
        1032,
        1033,
        1099,
        1101,
        1185,
        1191,
        1206,
        1375,
        1507,
        1516,
        1574,
        1957,
        1970,
        1995,
        1996,
        2060,
        2070,
        2072,
        2089,
        2091,
        2101,
        2164,
        2165,
        2248,
        2249,
        2374,
        2401,
        2405,
        2455,
        2649,
        2655,
        2774
    ],
    "metzger, arthur robert": [
        127,
        128,
        129
    ],
    "suffolk county": [
        127
    ],
    "troop 15": [
        128,
        323,
        367,
        1191,
        1288,
        1412,
        1480,
        2287,
        2288
    ],
    "san diego": [
        128,
        1103,
        1745,
        1869,
        1955,
        1956,
        2120,
        2313,
        2318,
        2433,
        2679
    ],
    "troop 244": [
        129
    ],
    "miramar": [
        129
    ],
    "pack 9": [
        130,
        1255,
        1571
    ],
    "nugent, samuel j.": [
        130,
        131,
        132
    ],
    "pittsburgh": [
        130,
        131,
        132,
        2196,
        2201,
        2350,
        2436
    ],
    "pack 274": [
        131,
        2069
    ],
    "troop 274": [
        132,
        1430,
        2435,
        2436,
        2437
    ],
    "troop 112": [
        133,
        298,
        837,
        911,
        946
    ],
    "king, david w.": [
        133,
        134,
        135
    ],
    "webster": [
        133,
        134,
        135
    ],
    "1969": [
        133,
        134,
        135,
        2155,
        2157,
        2178,
        2180,
        2203,
        2216,
        2218,
        2231,
        2233,
        2235,
        2237,
        2254,
        2258,
        2292,
        2293,
        2309,
        2311,
        2319,
        2321,
        2323,
        2325,
        2344,
        2358,
        2377,
        2391,
        2412,
        2413,
        2415,
        2417,
        2423,
        2437,
        2443,
        2447,
        2449,
        2454,
        2462,
        2465,
        2467,
        2469,
        2475,
        2508,
        2516,
        2522,
        2523,
        2531,
        2556,
        2565,
        2572,
        2585,
        2589,
        2598,
        2601,
        2629,
        2635,
        2642,
        2646,
        2663,
        2672,
        2680,
        2696,
        2727,
        2750,
        2756,
        2763,
        2776,
        2791,
        2793
    ],
    "troop 262": [
        134,
        418,
        2095
    ],
    "troop 314": [
        135,
        189
    ],
    "troop 712": [
        136
    ],
    "hungate, james m.": [
        136,
        137,
        138
    ],
    "manhattan": [
        136,
        1479,
        1481,
        2347,
        2354,
        2395
    ],
    "1960": [
        136,
        137,
        138,
        2190,
        2192,
        2229,
        2230,
        2250,
        2326,
        2346,
        2383,
        2389,
        2393,
        2480,
        2496,
        2505,
        2530,
        2535,
        2552,
        2564,
        2592,
        2648,
        2655,
        2665,
        2670,
        2675,
        2677,
        2688,
        2707,
        2716,
        2755,
        2761,
        2766,
        2767,
        2769,
        2771
    ],
    "troop 226": [
        137,
        376,
        712,
        1175,
        1265,
        1346,
        1493
    ],
    "brooklyn": [
        137,
        138,
        1388,
        1963,
        2364,
        2460,
        2466
    ],
    "troop 333": [
        138,
        1574,
        2060
    ],
    "explorer 384": [
        139
    ],
    "wantuck, robert l.": [
        139,
        140,
        141
    ],
    "st. louis": [
        139,
        140,
        141,
        589,
        1000,
        1044,
        1151,
        1555,
        1644,
        1735,
        1875,
        1876,
        2173,
        2428,
        2429
    ],
    "1963": [
        139,
        140,
        141,
        2146,
        2148,
        2172,
        2173,
        2174,
        2196,
        2201,
        2202,
        2204,
        2211,
        2212,
        2226,
        2228,
        2234,
        2236,
        2280,
        2281,
        2282,
        2283,
        2308,
        2317,
        2318,
        2351,
        2364,
        2368,
        2370,
        2381,
        2386,
        2405,
        2422,
        2425,
        2426,
        2493,
        2495,
        2537,
        2576,
        2582,
        2586,
        2600,
        2608,
        2610,
        2636,
        2638,
        2647,
        2661,
        2666,
        2679,
        2714,
        2718,
        2720,
        2732,
        2745,
        2746,
        2772
    ],
    "explorer 765": [
        140
    ],
    "troop 384": [
        141,
        936
    ],
    "pack 179": [
        142
    ],
    "froehlig, james william": [
        142,
        143
    ],
    "minneapolis": [
        142,
        143,
        2510,
        2615
    ],
    "troop 179": [
        143,
        1452
    ],
    "troop 299": [
        144,
        1918
    ],
    "florida": [
        144,
        145,
        146,
        177,
        178,
        179,
        214,
        223,
        283,
        284,
        301,
        306,
        311,
        312,
        386,
        407,
        411,
        412,
        413,
        462,
        470,
        480,
        484,
        498,
        513,
        526,
        532,
        542,
        556,
        573,
        632,
        661,
        745,
        773,
        811,
        812,
        832,
        833,
        842,
        849,
        850,
        864,
        887,
        971,
        980,
        981,
        982,
        983,
        1046,
        1062,
        1068,
        1073,
        1075,
        1081,
        1085,
        1087,
        1112,
        1113,
        1145,
        1147,
        1155,
        1157,
        1198,
        1235,
        1245,
        1274,
        1278,
        1285,
        1334,
        1366,
        1401,
        1406,
        1408,
        1426,
        1463,
        1465,
        1466,
        1483,
        1488,
        1514,
        1517,
        1541,
        1556,
        1582,
        1625,
        1683,
        1709,
        1758,
        1760,
        1764,
        1765,
        1816,
        1841,
        1894,
        1907,
        1965,
        1973,
        1991,
        1997,
        2019,
        2021,
        2032,
        2039,
        2047,
        2057,
        2149,
        2198,
        2200,
        2300,
        2372,
        2400,
        2410,
        2423,
        2424,
        2430,
        2442,
        2449,
        2476,
        2479,
        2497,
        2512,
        2521,
        2570,
        2624,
        2652,
        2662,
        2663,
        2666,
        2668,
        2790,
        2792
    ],
    "chandler, anthony s.": [
        144,
        145,
        146
    ],
    "coconut grove": [
        144,
        145
    ],
    "1968": [
        144,
        145,
        146,
        2181,
        2182,
        2183,
        2184,
        2189,
        2191,
        2208,
        2210,
        2238,
        2239,
        2240,
        2241,
        2251,
        2255,
        2257,
        2263,
        2264,
        2266,
        2278,
        2279,
        2289,
        2291,
        2305,
        2315,
        2320,
        2332,
        2336,
        2341,
        2348,
        2360,
        2365,
        2376,
        2378,
        2388,
        2406,
        2410,
        2433,
        2436,
        2439,
        2451,
        2456,
        2492,
        2494,
        2498,
        2515,
        2529,
        2539,
        2550,
        2553,
        2558,
        2574,
        2593,
        2599,
        2609,
        2620,
        2621,
        2627,
        2643,
        2645,
        2668,
        2678,
        2692,
        2702,
        2712,
        2722,
        2726,
        2734,
        2736,
        2748,
        2751,
        2762,
        2765,
        2795
    ],
    "troop 21": [
        145,
        214,
        806,
        1152,
        1267,
        1975,
        2291,
        2623,
        2624
    ],
    "troop 419": [
        146,
        2190
    ],
    "miami": [
        146,
        214,
        470,
        526,
        661,
        1198,
        2423,
        2449,
        2454,
        2663
    ],
    "troop 255": [
        147,
        1538,
        1701
    ],
    "arizona": [
        147,
        148,
        149,
        294,
        348,
        366,
        479,
        570,
        709,
        712,
        740,
        1179,
        1257,
        1329,
        1336,
        1338,
        1340,
        1532,
        1539,
        1778,
        1863,
        2006,
        2140,
        2211,
        2212,
        2366,
        2425,
        2468,
        2482,
        2509,
        2557,
        2777
    ],
    "judd, larry wright": [
        147,
        148,
        149
    ],
    "mesa": [
        147,
        148,
        149
    ],
    "troop 152": [
        148,
        1339
    ],
    "troop 430": [
        149,
        1395
    ],
    "troop 455": [
        150
    ],
    "syring, david l.": [
        150,
        151,
        152
    ],
    "mosinee": [
        150,
        151
    ],
    "explorer 455": [
        151
    ],
    "troop 438": [
        152
    ],
    "schofield": [
        152
    ],
    "troop 338": [
        153,
        1495
    ],
    "kinney, vernon c.": [
        153
    ],
    "tannehill, thomas m.": [
        154,
        155,
        156,
        157
    ],
    "troop 804": [
        155,
        1423
    ],
    "troop 472": [
        156,
        1014
    ],
    "troop 317": [
        157,
        1228
    ],
    "troop 560": [
        158,
        976,
        2354
    ],
    "beaudoin, charles": [
        158
    ],
    "lubbock": [
        158,
        889,
        1986,
        2375,
        2639
    ],
    "workman, james hardy": [
        159,
        160,
        161
    ],
    "charlotte": [
        159,
        160,
        161,
        946,
        975,
        1793,
        2594,
        2786
    ],
    "troop 606": [
        160,
        2339
    ],
    "pack 606": [
        161
    ],
    "troop 324": [
        162,
        2252,
        2417
    ],
    "macemore, claude e.": [
        162
    ],
    "troop 410": [
        163,
        849,
        1290,
        2382
    ],
    "hampton, thomas b.": [
        163,
        164,
        165
    ],
    "richmond": [
        163,
        164,
        165,
        706,
        707,
        922,
        1033,
        1516,
        1995,
        1996
    ],
    "troop 703": [
        164
    ],
    "troop 701": [
        165,
        2039
    ],
    "pack 75": [
        166,
        1613,
        1879
    ],
    "darby, ernest m.": [
        166,
        167
    ],
    "andover": [
        166,
        167,
        1309
    ],
    "explorer 829": [
        168
    ],
    "filicia, salvatore": [
        168,
        169,
        170
    ],
    "pulaski": [
        168,
        169,
        170
    ],
    "troop 829": [
        169,
        170
    ],
    "pack 854": [
        171
    ],
    "draper, john t.": [
        171,
        172,
        173
    ],
    "houston": [
        171,
        172,
        173,
        335,
        361,
        941,
        943,
        963,
        1559,
        1567,
        1733,
        1740,
        1766,
        1773,
        2135,
        2163,
        2203,
        2218,
        2320,
        2327,
        2346,
        2434,
        2782
    ],
    "troop 854": [
        172
    ],
    "explorer 854": [
        173
    ],
    "galbraith, bruce": [
        174,
        175,
        176
    ],
    "northfield": [
        174
    ],
    "turner's falls": [
        175
    ],
    "troop 901": [
        176
    ],
    "greenfield": [
        176,
        2256
    ],
    "baldwin, william mark": [
        177,
        178,
        179
    ],
    "tampa": [
        177,
        178,
        179,
        811,
        812,
        1145,
        1147
    ],
    "pack 40": [
        178,
        389,
        948
    ],
    "explorer 911": [
        179,
        443
    ],
    "troop 213": [
        180,
        183,
        2210,
        2212
    ],
    "nevada": [
        180,
        181,
        182,
        183,
        233,
        726,
        880,
        1035,
        1133,
        1246,
        1333,
        1497,
        1751,
        1898,
        2536,
        2551
    ],
    "doran, bernard p.": [
        180,
        181,
        182,
        183
    ],
    "sparks": [
        180,
        181,
        182,
        183,
        726,
        880
    ],
    "explorer 113": [
        181
    ],
    "explorer 913": [
        182
    ],
    "pack 3200": [
        184
    ],
    "hale, james a.": [
        184,
        185
    ],
    "camp ": [
        185,
        209,
        239,
        507,
        537,
        576,
        669,
        733,
        790,
        793,
        795,
        825,
        832,
        847,
        884,
        898,
        945,
        1005,
        1053,
        1068,
        1087,
        1113,
        1115,
        1125,
        1138,
        1146,
        1176,
        1218,
        1219,
        1221,
        1229,
        1337,
        1377,
        1402,
        1426,
        1484,
        1598,
        1716,
        1847,
        1856,
        1870,
        1922,
        1969,
        1987,
        2678
    ],
    "hawaii": [
        185,
        687,
        688,
        1903,
        1994,
        2119,
        2245,
        2519,
        2627
    ],
    "oahu": [
        185
    ],
    "troop 221": [
        186,
        1273
    ],
    "tezon, peter e.": [
        186,
        187,
        188
    ],
    "pack 165": [
        187
    ],
    "explorer 2147": [
        188
    ],
    "sherrard, david": [
        189
    ],
    "lorain": [
        189,
        356,
        651
    ],
    "pack 3591": [
        190
    ],
    "kriner, kevin george": [
        190
    ],
    "troop 863": [
        191
    ],
    "stensland, donald": [
        191,
        192,
        193,
        194
    ],
    "explorer 2863": [
        192
    ],
    "pack 3863": [
        193
    ],
    "explorer 9763": [
        194
    ],
    "troop 396": [
        195,
        357
    ],
    "buzzek, david j.": [
        195,
        196,
        197,
        198
    ],
    "district 904": [
        196
    ],
    "district 3904": [
        197
    ],
    "district 2904": [
        198
    ],
    "pack 3883": [
        199
    ],
    "wishnie, joseph b.": [
        199,
        200,
        201
    ],
    "pack 3981": [
        200
    ],
    "pack 3956": [
        201
    ],
    "sea scouts 34": [
        202
    ],
    "herring, david thomas": [
        202
    ],
    "troop 124": [
        203,
        1061,
        1654,
        2233,
        2508
    ],
    "south carolina": [
        203,
        204,
        205,
        206,
        468,
        469,
        483,
        701,
        702,
        1384,
        1499,
        1546,
        1770,
        1836,
        1867,
        1871,
        2073,
        2111,
        2352,
        2371,
        2387,
        2569,
        2594,
        2795
    ],
    "burgess, mitchell p.": [
        203,
        204,
        205,
        206
    ],
    "aiken ": [
        203
    ],
    "troop 404": [
        204
    ],
    "aiken": [
        204,
        205,
        206
    ],
    "troop 8604": [
        205
    ],
    "troop 147": [
        207,
        542,
        2497
    ],
    "abidiwan, michael j.": [
        207
    ],
    "nutley": [
        207,
        1811,
        1814
    ],
    "troop 331": [
        208,
        547
    ],
    "louisiana": [
        208,
        334,
        387,
        547,
        562,
        603,
        604,
        743,
        828,
        919,
        1050,
        1053,
        1061,
        1082,
        1107,
        1122,
        1124,
        1125,
        1136,
        1204,
        1207,
        1286,
        1288,
        1513,
        1515,
        1531,
        1591,
        1592,
        1708,
        1735,
        1946,
        2075,
        2124,
        2255,
        2272,
        2355,
        2394,
        2474,
        2502,
        2563,
        2580,
        2651,
        2669
    ],
    "adams, steve allen": [
        208,
        209
    ],
    "schriever": [
        208
    ],
    "mississippi": [
        209,
        578,
        614,
        615,
        616,
        958,
        977,
        978,
        997
    ],
    "kiln": [
        209
    ],
    "adams, julian": [
        210
    ],
    "adkins, royce lee": [
        211
    ],
    "1971": [
        211,
        249,
        343,
        348,
        368,
        481,
        482,
        564,
        648,
        686,
        713,
        770,
        845,
        942,
        951,
        960,
        970,
        972,
        998,
        1000,
        1022,
        1157,
        1176,
        1196,
        1197,
        1280,
        1301,
        1303,
        1324,
        1354,
        1494,
        1512,
        1521,
        1721,
        1728,
        1794,
        1815,
        1996,
        2041,
        2119,
        2128,
        2671
    ],
    "pack 36": [
        212,
        973,
        1729,
        1784
    ],
    "agarand, joseph a.": [
        212
    ],
    "bentonville": [
        212
    ],
    "troop 225": [
        213
    ],
    "ahern, stephen edward": [
        213
    ],
    "ft. hood": [
        213,
        1872,
        2507
    ],
    "aiken, david": [
        214
    ],
    "explorer 724": [
        215
    ],
    "alberga, karl a.": [
        215,
        216
    ],
    "schenectady": [
        215,
        216,
        696,
        1236,
        1238,
        1954
    ],
    "troop 357": [
        216
    ],
    "pack 535": [
        217,
        2362
    ],
    "albright, charles f.": [
        217
    ],
    "dallas": [
        217,
        444,
        445,
        624,
        905,
        1180,
        1270,
        1339,
        1636,
        1855,
        2024,
        2603
    ],
    "alexander, arthur w.": [
        218
    ],
    "alexander, ray": [
        219
    ],
    "troop 57": [
        220,
        791,
        2572,
        2574
    ],
    "alford, kenneth lyle": [
        220
    ],
    "odessa": [
        220,
        1589,
        2031,
        2147
    ],
    "troop ": [
        221,
        513,
        546,
        589,
        670,
        679,
        762,
        1066,
        1103,
        1251,
        1298,
        1425,
        1491,
        1617,
        1637,
        1708,
        1710,
        1748,
        1788,
        1794,
        1851,
        1925,
        1984,
        1999,
        2261,
        2431,
        2486
    ],
    "allan, robert": [
        221
    ],
    "troop 612": [
        222
    ],
    "allen, ronald william": [
        222
    ],
    "washougal": [
        222
    ],
    "allen, girard h.": [
        223
    ],
    "new port richey": [
        223,
        2790,
        2792
    ],
    "pack 407": [
        224
    ],
    "allen, jeffrey": [
        224
    ],
    "allen, lawrence n.": [
        225
    ],
    "alpren, larry k.": [
        226
    ],
    "new haven": [
        226,
        2675
    ],
    "troop 25": [
        227,
        575,
        660,
        839,
        840,
        949,
        1011,
        1178,
        2777
    ],
    "alter, michael r.": [
        227
    ],
    "rushville": [
        227,
        2238,
        2239
    ],
    "altomere, joseph f.": [
        228,
        229
    ],
    "cincinnati": [
        228,
        533,
        1387,
        2208,
        2392,
        2448
    ],
    "springfield": [
        229,
        1412,
        1731,
        1779,
        1783,
        2033,
        2083,
        2131,
        2164,
        2165,
        2306,
        2593
    ],
    "14": [
        230,
        684
    ],
    "aluise, richard augustus": [
        230,
        231
    ],
    "99": [
        231
    ],
    "pack 1022": [
        232
    ],
    "anderson, william j.": [
        232
    ],
    "detroit": [
        232,
        376,
        538,
        793,
        2009,
        2315,
        2369,
        2431,
        2613
    ],
    "troop 126": [
        233,
        819,
        1052
    ],
    "anderson, charles o.": [
        233
    ],
    "las vegas": [
        233,
        1246,
        1751
    ],
    "pack 132": [
        234,
        1305,
        1910
    ],
    "oklahoma": [
        234,
        249,
        262,
        341,
        789,
        928,
        969,
        1277,
        1439,
        1450,
        1543,
        1657,
        1924,
        2061,
        2282,
        2416,
        2418,
        2659
    ],
    "anderson, frank r.": [
        234
    ],
    "grandfield": [
        234
    ],
    "troop 73": [
        235,
        346,
        491,
        641,
        758,
        1780,
        1820,
        2298,
        2555,
        2790
    ],
    "appler, russell c.": [
        235
    ],
    "phoenixville": [
        235
    ],
    "pack 24": [
        236
    ],
    "arce, jesus nathaniel": [
        236,
        237
    ],
    "lancaster": [
        236,
        237,
        840,
        841,
        1167,
        1169,
        1295,
        1900,
        2222,
        2224
    ],
    "pack 43": [
        237
    ],
    "troop 171": [
        238,
        843,
        2475
    ],
    "ardrey, richard h.": [
        238
    ],
    "riverside": [
        238,
        307,
        734,
        2538,
        2647
    ],
    "arevalo, marcos adrian": [
        239
    ],
    "tacoma": [
        239,
        664,
        1968,
        2586,
        2609
    ],
    "troop 421": [
        240,
        2007
    ],
    "argyris, alexander dalton": [
        240
    ],
    "austin": [
        240,
        684,
        1022,
        2780
    ],
    "troop 557": [
        241
    ],
    "armstrong, vaughn a.": [
        241
    ],
    "leon": [
        241
    ],
    "armstrong, isaiah": [
        242
    ],
    "idaho": [
        243,
        313,
        633,
        713,
        805,
        806,
        909,
        1089,
        1156,
        1579,
        1594,
        1754,
        1755,
        1926,
        2016,
        2487
    ],
    "arnold, larren bybee": [
        243,
        244
    ],
    "boise": [
        243,
        713,
        1089,
        1156
    ],
    "utah": [
        244,
        274,
        700,
        722,
        906,
        920,
        1192,
        1290,
        1320,
        1496,
        1548,
        1658,
        1670,
        1752,
        1896,
        1897,
        1985,
        2087
    ],
    "salt lake city": [
        244,
        1290,
        1496,
        1670
    ],
    "district ": [
        245,
        349,
        350,
        354,
        413,
        742,
        743,
        754,
        1378,
        1401,
        1462,
        1490,
        1876,
        1997,
        2020,
        2119
    ],
    "artusy, willard henry": [
        245
    ],
    "kingsland": [
        245
    ],
    "ashbrook, lester a.": [
        246
    ],
    "galena": [
        246
    ],
    "troop 11": [
        247,
        760,
        1090,
        1261,
        1416,
        1647,
        1677,
        2242,
        2244,
        2248,
        2644,
        2646,
        2648,
        2650
    ],
    "ashmore, travis asbjorn": [
        247
    ],
    "madison": [
        247,
        587
    ],
    "astleford, dale lawrence": [
        248
    ],
    "atteberry, edsel ray": [
        249
    ],
    "lawton": [
        249,
        766,
        1924
    ],
    "explorer 336": [
        250,
        2172
    ],
    "atwood, john": [
        250,
        251
    ],
    "orange": [
        250,
        876,
        1805,
        2029,
        2062,
        2067,
        2490
    ],
    "troop 210": [
        251,
        371,
        990,
        2462,
        2464
    ],
    "oxnard": [
        251,
        286,
        829,
        830
    ],
    "troop 87": [
        252,
        315,
        950,
        1004,
        1184,
        1196,
        1593,
        1596,
        2044
    ],
    "auclair, james andrew": [
        252
    ],
    "groveland": [
        252,
        311
    ],
    "264": [
        253
    ],
    "auleb, robert kenneth": [
        253
    ],
    "pacifica": [
        253
    ],
    "pack 441": [
        254,
        920
    ],
    "avant, eric patrick": [
        254
    ],
    "virginia beach": [
        254,
        822,
        998,
        2089,
        2091
    ],
    "pack 196": [
        255
    ],
    "aviles, daniel": [
        255
    ],
    "pack 3329": [
        256
    ],
    "ayotte, wilfred j.": [
        256
    ],
    "ishpeming": [
        256
    ],
    "azzalina, tom": [
        257
    ],
    "phillipsburg": [
        257,
        727
    ],
    "troop 10": [
        258,
        621,
        797,
        1326,
        1585,
        1934,
        2294,
        2651,
        2653
    ],
    "babyak, donald r.": [
        258
    ],
    "greenwich": [
        258
    ],
    "explorer 5": [
        259,
        1779
    ],
    "backes, richard allen": [
        259
    ],
    "medford": [
        259,
        2170,
        2193,
        2596
    ],
    "pack 3049": [
        260
    ],
    "backoff, john a.": [
        260
    ],
    "boyne city": [
        260
    ],
    "troop 361": [
        261,
        1034
    ],
    "bagshaw, timothy j.": [
        261
    ],
    "state college ": [
        261
    ],
    "troop 133": [
        262,
        604,
        1051,
        1572,
        2502
    ],
    "bahaus, michael john": [
        262
    ],
    "tulsa": [
        262,
        789,
        1439,
        2282
    ],
    "troop 82": [
        263,
        301,
        374,
        550,
        891,
        991,
        2786
    ],
    "bailey, harold e.": [
        263
    ],
    "bucksport": [
        263
    ],
    "1978": [
        263,
        324,
        383,
        434,
        565,
        573,
        692,
        693,
        747,
        778,
        859,
        1259,
        1261,
        1428,
        1546,
        1558,
        1601,
        1677,
        1733,
        1861,
        1863,
        1972,
        2031,
        2073,
        2109,
        2143,
        2711,
        2784
    ],
    "bailey, thomas lee": [
        264
    ],
    "salem": [
        264,
        336,
        2646
    ],
    "troop 7": [
        265,
        414,
        482,
        484,
        713,
        729,
        1668,
        1993,
        2667,
        2669,
        2671
    ],
    "bailey, william n.": [
        265
    ],
    "philadelphia": [
        265,
        714,
        755,
        851,
        1603,
        1620,
        1918,
        1919,
        1933,
        2152,
        2153,
        2567
    ],
    "bailey, michael edward": [
        266
    ],
    "troop 204": [
        267
    ],
    "baird, rupert m.": [
        267
    ],
    "albany": [
        267,
        1710,
        2671
    ],
    "pack 3099": [
        268
    ],
    "baker, alan douglas": [
        268,
        269
    ],
    "toledo": [
        268,
        269,
        380,
        381,
        677,
        678,
        1012,
        1381,
        1411
    ],
    "pack 3211": [
        269
    ],
    "pack 3110": [
        270
    ],
    "baker, john a.": [
        270
    ],
    "fairborn": [
        270
    ],
    "troop 547": [
        271,
        2167
    ],
    "baker, william d.": [
        271
    ],
    "lackawanna": [
        271,
        2167
    ],
    "troop 320": [
        272,
        499,
        725,
        1687
    ],
    "alabama": [
        272,
        395,
        853,
        854,
        910,
        993,
        1243,
        1244,
        1281,
        1533,
        1535,
        1545,
        1910,
        1936,
        1937,
        1947,
        2098,
        2464,
        2562,
        2565,
        2574,
        2644
    ],
    "baker, claiborne b.": [
        272
    ],
    "montgomery": [
        272,
        1281,
        2464
    ],
    "balles, james richard": [
        273
    ],
    "fredonia": [
        273
    ],
    "476": [
        274
    ],
    "barber, norman carl": [
        274
    ],
    "syracuse": [
        274,
        492,
        645,
        950,
        994,
        1596,
        1880,
        1978,
        2207,
        2209,
        2623,
        2625
    ],
    "troop 960": [
        275
    ],
    "bardy, michael j.": [
        275
    ],
    "norridge": [
        275
    ],
    "barner, bryan a.": [
        276
    ],
    "barnes, matthew": [
        277
    ],
    "barnes, walter c.": [
        278
    ],
    "273": [
        279
    ],
    "barney, william": [
        279
    ],
    "union city": [
        279
    ],
    "barnhart, thomas": [
        280
    ],
    "warren": [
        280
    ],
    "50": [
        281
    ],
    "barone, richard v.": [
        281
    ],
    "amsterdam": [
        281
    ],
    "troop 732": [
        282
    ],
    "barrett, mike l.": [
        282
    ],
    "smyrna": [
        282,
        408,
        1666,
        1673
    ],
    "bartel, benjamin reese": [
        283,
        284
    ],
    "bradenton": [
        283,
        284,
        2570
    ],
    "troop 12": [
        284,
        845,
        1037,
        1513,
        2036,
        2128,
        2197,
        2284,
        2638,
        2639,
        2640,
        2642
    ],
    "explorer 580": [
        285
    ],
    "bartelt, lavern j.": [
        285
    ],
    "janesville": [
        285,
        350,
        1041,
        2640
    ],
    "254": [
        286
    ],
    "bartholomew, edward bruce": [
        286
    ],
    "bartiromo, pasquale j.": [
        287
    ],
    "newark": [
        287,
        817,
        818,
        1128,
        1506,
        1613,
        1614,
        1701
    ],
    "troop 49": [
        288,
        439,
        654,
        1156,
        1392,
        2103
    ],
    "bartlett, jeffrey a.": [
        288
    ],
    "providence": [
        288,
        1110,
        1214,
        1315,
        1363,
        2142
    ],
    "229": [
        289
    ],
    "barton, james d.": [
        289
    ],
    "lexington": [
        289,
        2014,
        2643
    ],
    "pack 3408": [
        290
    ],
    "bath, edward george": [
        290
    ],
    "glen lyon": [
        290
    ],
    "troop 150": [
        291,
        309,
        1134,
        1946,
        2107,
        2223
    ],
    "baugh, denis": [
        291
    ],
    "pasco": [
        291
    ],
    "bauman, robert e.": [
        292
    ],
    "easton": [
        292
    ],
    "troop 310": [
        293,
        2416
    ],
    "philippines": [
        293
    ],
    "baxley, james allen": [
        293
    ],
    "clark ab": [
        293
    ],
    "troop 729": [
        294,
        433,
        570
    ],
    "beahm, harley david": [
        294
    ],
    "tucson": [
        294,
        570,
        1539,
        2006,
        2211,
        2212,
        2468,
        2509
    ],
    "troop 993": [
        295
    ],
    "beard, ronald": [
        295
    ],
    "alexandria": [
        295,
        1050,
        1053,
        1061,
        1204,
        1207,
        1507,
        2655
    ],
    "beardsley, david jerome": [
        296
    ],
    "st. paul": [
        296,
        575,
        855,
        1478,
        1494,
        2453,
        2633
    ],
    "troop 358": [
        297
    ],
    "tennessee": [
        297,
        298,
        320,
        370,
        371,
        443,
        487,
        535,
        536,
        557,
        913,
        1071,
        1161,
        1240,
        1448,
        1666,
        1673,
        1942,
        2099,
        2434,
        2530,
        2784
    ],
    "beasley, steven d.": [
        297,
        298
    ],
    "murfreesboro": [
        297
    ],
    "guild": [
        298
    ],
    "beasley, charles e.": [
        299
    ],
    "troop 222": [
        300,
        902,
        2448
    ],
    "beatty, rodger l.": [
        300
    ],
    "newport": [
        300,
        1100
    ],
    "beauregard, albert w.": [
        301
    ],
    "gainesville": [
        301,
        745,
        2400
    ],
    "troop 93": [
        302,
        608,
        2530
    ],
    "beck, charles o.": [
        302
    ],
    "delaware": [
        302,
        441,
        442,
        1506,
        1701,
        2139,
        2385,
        2614,
        2767
    ],
    "troop 1": [
        303,
        528,
        1579,
        2674,
        2675,
        2676,
        2766
    ],
    "becker, ronald a.": [
        303
    ],
    "monmouth beach": [
        303
    ],
    "becker, william n.": [
        304
    ],
    "pack 3721": [
        305
    ],
    "becker, samuel j.": [
        305
    ],
    "canoga park": [
        305
    ],
    "explorer 487": [
        306
    ],
    "becker, donald charles": [
        306
    ],
    "ft. pierce": [
        306
    ],
    "beers, chris j.": [
        307
    ],
    "troop 17": [
        308,
        436,
        485,
        606,
        653,
        1976,
        2037,
        2634
    ],
    "belford, stephen p.": [
        308
    ],
    "belke, earl": [
        309
    ],
    "bell, harry e.": [
        310
    ],
    "zanesville": [
        310
    ],
    "bell, ronald edwin": [
        311
    ],
    "troop 311": [
        312,
        673,
        1185,
        2414
    ],
    "bellavance, donn t.": [
        312
    ],
    "ft. lauderdale": [
        312,
        1997,
        2479,
        2652
    ],
    "district 355": [
        313
    ],
    "belmer, donald d.": [
        313
    ],
    "payette": [
        313
    ],
    "bendler, clifford": [
        314
    ],
    "korea": [
        315
    ],
    "bennett, david william": [
        315
    ],
    "yongsan": [
        315
    ],
    "sea scouts 602": [
        316
    ],
    "benson, gregory a.": [
        316
    ],
    "portland": [
        316,
        515,
        699,
        907,
        908,
        947,
        988,
        2336,
        2534,
        2608,
        2610,
        2674
    ],
    "berger, byron": [
        317
    ],
    "troop 184": [
        318
    ],
    "bergeron, wilfred h.": [
        318
    ],
    "bernstein, robert bobby": [
        319
    ],
    "troop 252": [
        320,
        1688,
        1732
    ],
    "bethany, jacob h.": [
        320
    ],
    "kingsport": [
        320
    ],
    "betz, donald l.": [
        321,
        322
    ],
    "sherwood": [
        321,
        322,
        1674
    ],
    "bibeau, david a.": [
        323
    ],
    "troop 377": [
        324,
        1734
    ],
    "colorado": [
        324,
        362,
        554,
        585,
        626,
        799,
        836,
        837,
        925,
        1010,
        1028,
        1056,
        1163,
        1232,
        1258,
        1310,
        1378,
        1434,
        1472,
        1498,
        1575,
        1664,
        1675,
        1688,
        1712,
        1818,
        1820,
        1851,
        1881,
        1882,
        2022,
        2077,
        2079,
        2252,
        2253,
        2308,
        2368,
        2373,
        2566,
        2621
    ],
    "bieber, ray l.": [
        324
    ],
    "boulder": [
        324,
        1472,
        1818,
        1820,
        2308
    ],
    "troop 559": [
        325
    ],
    "bilger, mark william": [
        325
    ],
    "vancouver": [
        325,
        1225
    ],
    "bird, aubrey eugene": [
        326,
        327
    ],
    "explorer 120": [
        327
    ],
    "troop 180": [
        328,
        1977
    ],
    "bischoff, albert": [
        328
    ],
    "bergenfield": [
        328
    ],
    "troop 31": [
        329,
        674,
        771,
        1009,
        1266,
        1289,
        1739,
        2601
    ],
    "bishop, david a.": [
        329
    ],
    "southington": [
        329
    ],
    "bishop, robert frederick": [
        330
    ],
    "nashua": [
        330,
        1993
    ],
    "bishop, edward": [
        331
    ],
    "bedford": [
        331
    ],
    "bittenbender, carlton l.": [
        332,
        333
    ],
    "1970": [
        333,
        836,
        837,
        1635,
        1637,
        1639,
        2150,
        2151,
        2161,
        2162,
        2219,
        2220,
        2301,
        2316,
        2324,
        2330,
        2339,
        2345,
        2349,
        2350,
        2353,
        2363,
        2374,
        2382,
        2385,
        2390,
        2409,
        2427,
        2428,
        2445,
        2450,
        2453,
        2457,
        2459,
        2471,
        2476,
        2479,
        2482,
        2545,
        2551,
        2555,
        2568,
        2596,
        2632,
        2633,
        2644,
        2682,
        2683,
        2684,
        2705,
        2709,
        2723,
        2747,
        2764
    ],
    "reston": [
        333,
        1970
    ],
    "troop 127": [
        334,
        2239,
        2506,
        2507
    ],
    "bivins, lewis t.": [
        334
    ],
    "monroe": [
        334,
        689,
        1125,
        2513
    ],
    "pack 3241": [
        335
    ],
    "bjorklund, buford e.": [
        335
    ],
    "troop 610": [
        336
    ],
    "blackiston, john william": [
        336
    ],
    "blacklock, russell": [
        337
    ],
    "pack 493": [
        338
    ],
    "blackwell, terry": [
        338
    ],
    "lathrop": [
        338,
        1597,
        1599
    ],
    "troop 30": [
        339,
        910,
        1244,
        1294,
        1333,
        2604,
        2605
    ],
    "blaine, b. christy": [
        339,
        340
    ],
    "savannah": [
        339,
        599,
        676
    ],
    "brunswick": [
        340,
        1264
    ],
    "troop 355": [
        341,
        1187,
        1516,
        1543,
        2389
    ],
    "blaskovic, william e.": [
        341
    ],
    "weatherford": [
        341,
        1543
    ],
    "blauvelt, henry h.": [
        342
    ],
    "troop 72": [
        343,
        598,
        2557
    ],
    "blazer, william henry": [
        343
    ],
    "wildwood crest": [
        343
    ],
    "troop 429": [
        344
    ],
    "bliley, john joseph": [
        344
    ],
    "south bend": [
        344,
        1176
    ],
    "troop 92": [
        345,
        1568,
        2115,
        2531,
        2532
    ],
    "blondin, guy p.": [
        345
    ],
    "vernon": [
        345,
        1568
    ],
    "blough, elmer l.": [
        346
    ],
    "smithville": [
        346,
        761,
        1341
    ],
    "troop 607": [
        347,
        1874
    ],
    "vermont": [
        347,
        512,
        594,
        631,
        658,
        915,
        1453,
        1457,
        1651,
        1873,
        1874,
        1887,
        2083,
        2302,
        2303,
        2667
    ],
    "blow, harvey louis": [
        347
    ],
    "shelburne": [
        347
    ],
    "blum, jon michael": [
        348
    ],
    "phoenix": [
        348,
        479,
        712,
        740,
        1532,
        1863,
        2366,
        2425,
        2482
    ],
    "blythe, winston": [
        349
    ],
    "dayton": [
        349
    ],
    "boardman, michael l.": [
        350,
        351
    ],
    "1981": [
        350,
        351,
        422,
        425,
        455,
        577,
        624,
        625,
        748,
        769,
        779,
        849,
        850,
        1019,
        1034,
        1044,
        1083,
        1186,
        1318,
        1624,
        1629,
        1675,
        1701,
        1836,
        2006,
        2018
    ],
    "explorer 343": [
        351
    ],
    "delavan": [
        351
    ],
    "troop 217": [
        352
    ],
    "boardman, james e.": [
        352
    ],
    "killeen": [
        352,
        1424,
        1669,
        2116
    ],
    "troop 136": [
        353,
        1411
    ],
    "boblitt, allen l.": [
        353
    ],
    "warminster": [
        353
    ],
    "bobo, gene paul": [
        354
    ],
    "midland": [
        354,
        819,
        820
    ],
    "494": [
        355
    ],
    "bobrowske, kenneth john": [
        355
    ],
    "howard lake": [
        355
    ],
    "troop 371": [
        356,
        783,
        2404
    ],
    "bodak, james a.": [
        356
    ],
    "boder, donald m.": [
        357
    ],
    "uniondale": [
        357
    ],
    "troop 923": [
        358
    ],
    "bogart, bradley": [
        358,
        359
    ],
    "explorer 9007": [
        359
    ],
    "bohrer, paul j.": [
        360
    ],
    "explorer 453": [
        361
    ],
    "bolog, richard v.": [
        361
    ],
    "104": [
        362
    ],
    "boone, david lee": [
        362
    ],
    "castle rock": [
        362
    ],
    "45": [
        363,
        602
    ],
    "booth, wayne spowyer": [
        363
    ],
    "salinas": [
        363,
        1578,
        1781
    ],
    "troop 803": [
        364
    ],
    "borchardt, jerry a.": [
        364
    ],
    "cedarburg": [
        364
    ],
    "pack 184": [
        365,
        1145
    ],
    "borelli, john keith": [
        365
    ],
    "fruitland": [
        365
    ],
    "troop 435": [
        366,
        488
    ],
    "borg, david james": [
        366
    ],
    "sierra vista": [
        366
    ],
    "bornschein, joseph john": [
        367
    ],
    "bellaire": [
        367
    ],
    "bottesi, dennis r.": [
        368
    ],
    "troop 327": [
        369,
        2415
    ],
    "bouchard, stephen c.": [
        369
    ],
    "bowden, robert crawford": [
        370,
        371
    ],
    "nashville": [
        370,
        371,
        487,
        1161
    ],
    "troop 81": [
        372,
        402,
        1318,
        1347,
        1358,
        2545
    ],
    "bowen, arvin r.": [
        372
    ],
    "bowen, thomas neal": [
        373
    ],
    "boyer, ronald dean": [
        374
    ],
    "bradley, michael t.": [
        375,
        376
    ],
    "goodrich": [
        375
    ],
    "pack 3047": [
        377
    ],
    "bradley, carl polk": [
        377
    ],
    "bellingham": [
        377,
        660,
        1958
    ],
    "branscomb, james o.": [
        378
    ],
    "willits": [
        378
    ],
    "troop 137": [
        379,
        562,
        919,
        2124,
        2496
    ],
    "brant, dale thomas": [
        379
    ],
    "new manchester": [
        379
    ],
    "branyan, larry woodroe": [
        380,
        381
    ],
    "pack 3035": [
        381
    ],
    "pack 121": [
        382
    ],
    "breen, robert e.": [
        382
    ],
    "lynn": [
        382,
        2556
    ],
    "explorer 192": [
        383,
        1686
    ],
    "breglio, robert j.": [
        383
    ],
    "staten island": [
        383,
        635,
        636,
        637,
        2294,
        2560
    ],
    "703": [
        384
    ],
    "brehaut, lawrence o.": [
        384
    ],
    "wakefield": [
        384
    ],
    "explorer 189": [
        385
    ],
    "brewer, john": [
        385
    ],
    "wyckoff": [
        385
    ],
    "brier, steven vincent": [
        386
    ],
    "jacksonville": [
        386,
        484,
        532,
        982,
        1362,
        1541,
        1683,
        2200,
        2424,
        2442,
        2497,
        2624
    ],
    "briggs, walter e.": [
        387
    ],
    "downsville": [
        387
    ],
    "troop 359": [
        388
    ],
    "briley, donald m.": [
        388
    ],
    "spokane": [
        388,
        520,
        1222,
        2462
    ],
    "bristol, charles w.": [
        389,
        390,
        391
    ],
    "akron": [
        389,
        390,
        391,
        1143,
        2003,
        2004,
        2195
    ],
    "pack 204": [
        390,
        1510
    ],
    "pack 3204": [
        391
    ],
    "troop 460": [
        392,
        614
    ],
    "brock, donald c.": [
        392
    ],
    "pullman": [
        392
    ],
    "kentucky": [
        393,
        453,
        681,
        778,
        838,
        1005,
        1127,
        1445,
        1695,
        2399,
        2463,
        2489,
        2579,
        2643,
        2676
    ],
    "broddle, robert o.": [
        393
    ],
    "madisonville": [
        393
    ],
    "pack 241": [
        394
    ],
    "brooks, danny edward": [
        394
    ],
    "petersburg": [
        394
    ],
    "troop 121": [
        395,
        770,
        1389,
        1611,
        2519
    ],
    "brooks, don": [
        395
    ],
    "theodore": [
        395
    ],
    "troop 710": [
        396
    ],
    "brooks, allan l.": [
        396,
        397
    ],
    "goochland": [
        396
    ],
    "troop 700": [
        397,
        1765,
        2006
    ],
    "ashland": [
        397,
        911
    ],
    "brotherton, daniel ray": [
        398
    ],
    "troop 724": [
        399
    ],
    "brown, michael raymond": [
        399
    ],
    "constantia": [
        399
    ],
    "troop 444": [
        400,
        423,
        1250,
        2158
    ],
    "brown, james randolph": [
        400
    ],
    "walnut cove": [
        400
    ],
    "troop 316": [
        401,
        629,
        1501,
        1676
    ],
    "brown, gerald l.": [
        401
    ],
    "st. anne": [
        401
    ],
    "brown, william b.": [
        402
    ],
    "westbrook": [
        402
    ],
    "troop 52": [
        403,
        548,
        636,
        2272
    ],
    "brown, edward c.": [
        403
    ],
    "troop 42": [
        404,
        1580,
        2112
    ],
    "brown, john henry": [
        404
    ],
    "russell": [
        404
    ],
    "brown, john d.": [
        405
    ],
    "maineville": [
        405
    ],
    "brown, ronald gene": [
        406
    ],
    "brown, james h.": [
        407
    ],
    "lakeland": [
        407,
        773
    ],
    "brown, robert k.": [
        408
    ],
    "explorer 221": [
        409,
        924
    ],
    "brownscombe, tom": [
        409
    ],
    "hyattsville": [
        409
    ],
    "brunette, david j.": [
        410
    ],
    "brus, antonie": [
        411,
        412,
        413
    ],
    "orlando": [
        411,
        412,
        864,
        1401,
        1582,
        2668
    ],
    "troop 78": [
        412,
        2792
    ],
    "orlando ": [
        413
    ],
    "bryant, richard dale": [
        414
    ],
    "little rock": [
        414,
        446
    ],
    "troop 268": [
        415,
        2204
    ],
    "bryant, charles": [
        415
    ],
    "fresno": [
        415,
        690,
        691
    ],
    "buckingham, darryl l.": [
        416
    ],
    "explorer 3": [
        417,
        1361
    ],
    "canal zone": [
        417
    ],
    "bucko, robert": [
        417
    ],
    "ft. clayton": [
        417
    ],
    "bumgarner, mark f.": [
        418,
        419
    ],
    "lenoir": [
        418
    ],
    "explorer 1145": [
        419
    ],
    "fairfax": [
        419
    ],
    "pack 422": [
        420,
        1565
    ],
    "bump, jeffery lloyd": [
        420
    ],
    "crockett": [
        420
    ],
    "district 4": [
        421,
        2105
    ],
    "burgett, howard eugene sanderson": [
        421
    ],
    "seattle": [
        421,
        1067,
        1069,
        1553,
        1561,
        2301,
        2329,
        2421,
        2501
    ],
    "burgie, gerald w.": [
        422
    ],
    "burke, tom m.": [
        423
    ],
    "bensenville": [
        423
    ],
    "1975": [
        423,
        501,
        542,
        545,
        591,
        595,
        660,
        701,
        702,
        710,
        878,
        931,
        1013,
        1325,
        1327,
        1346,
        1576,
        1577,
        1600,
        1606,
        1628,
        1630,
        1875,
        1876,
        2053,
        2075,
        2110,
        2273,
        2275
    ],
    "burke, james a.": [
        424
    ],
    "calhoun": [
        424,
        1923
    ],
    "burke, david": [
        425
    ],
    "burkhardt, richard": [
        426
    ],
    "pack 402": [
        427
    ],
    "burnett, jimmy d.": [
        427
    ],
    "pampa": [
        427
    ],
    "pack 3009": [
        428
    ],
    "alaska": [
        428,
        476,
        550,
        654,
        749,
        750,
        1230,
        1231,
        1255,
        1291,
        1617,
        1802,
        2524,
        2631
    ],
    "burns, kenneth a.": [
        428
    ],
    "edelson afb": [
        428
    ],
    "troop 304": [
        429,
        1186,
        2201,
        2418
    ],
    "burr, peter h.": [
        429
    ],
    "kennebunk": [
        429
    ],
    "pack 50": [
        430
    ],
    "burr, william j.": [
        430
    ],
    "albermarle": [
        430
    ],
    "troop 503": [
        431,
        441,
        624,
        2171
    ],
    "burris, william alva": [
        431,
        432
    ],
    "wichita": [
        431,
        432,
        580,
        1077,
        1174,
        1631,
        1646,
        1808,
        2341,
        2345,
        2778
    ],
    "troop 526": [
        432,
        831,
        2009
    ],
    "burt, robert w.": [
        433
    ],
    "bush, leavitt l.": [
        434
    ],
    "colesburg": [
        434
    ],
    "60": [
        435,
        870,
        896,
        2002
    ],
    "bushard, donald e.": [
        435
    ],
    "wacousta": [
        435,
        868,
        870,
        894,
        896,
        2043
    ],
    "bussey, ronald eugene": [
        436
    ],
    "wichita falls": [
        436,
        1337
    ],
    "troop 26": [
        437,
        772,
        1271,
        1279,
        1386,
        1439,
        2620,
        2622
    ],
    "butler, frank j.": [
        437
    ],
    "becket": [
        437
    ],
    "troop 295": [
        438,
        965,
        1563
    ],
    "butler, george": [
        438
    ],
    "san jose": [
        438,
        497,
        1120,
        1850,
        2206,
        2344,
        2446
    ],
    "buttel, curtis": [
        439
    ],
    "milton": [
        439
    ],
    "troop 341": [
        440,
        2408,
        2409
    ],
    "buxton, james j.": [
        440
    ],
    "wonder valley": [
        440,
        1642
    ],
    "bye, robert n.": [
        441,
        442
    ],
    "wilmington": [
        441,
        442,
        2385,
        2614
    ],
    "troop 527": [
        442
    ],
    "cadle, wade s.": [
        443
    ],
    "knoxville": [
        443
    ],
    "pack 797": [
        444
    ],
    "cagle, robert j.": [
        444,
        445
    ],
    "district 8660": [
        445
    ],
    "troop 604": [
        446
    ],
    "calamia, waverly t.": [
        446
    ],
    "callahan, bruce k.": [
        447
    ],
    "calvert, patrick c.": [
        448
    ],
    "campina, arthur ronald": [
        449
    ],
    "st. cloud": [
        449,
        1361,
        1917
    ],
    "explorer 209": [
        450
    ],
    "cannon, steven j.": [
        450
    ],
    "peachtree city": [
        450
    ],
    "27": [
        451
    ],
    "capasso, conrad t.": [
        451
    ],
    "haverhill": [
        451
    ],
    "pack 3486": [
        452
    ],
    "cardemas, mario joseph": [
        452
    ],
    "west beverly": [
        452
    ],
    "carl, coley t.": [
        453
    ],
    "earlington": [
        453
    ],
    "carlisle, randolph": [
        454
    ],
    "redacted, redacted": [
        455,
        1624,
        1626,
        1628,
        1630,
        1632,
        1633
    ],
    "troop 301": [
        456,
        2087,
        2423
    ],
    "carloni, louis d.": [
        456
    ],
    "the plains": [
        456
    ],
    "troop 113": [
        457,
        592,
        1362,
        2707
    ],
    "carlson, richard butch": [
        457
    ],
    "bayport": [
        457
    ],
    "pack 3205": [
        458
    ],
    "carlson, melvin douglas": [
        458
    ],
    "salina": [
        458,
        2017
    ],
    "carmean, arthur c.": [
        459
    ],
    "district 8633": [
        460
    ],
    "carpontier, donald k.": [
        460
    ],
    "milpitas ": [
        460
    ],
    "explorer 951": [
        461
    ],
    "carr, john j.": [
        461
    ],
    "jamesville": [
        461
    ],
    "troop 466": [
        462,
        2176
    ],
    "carroll, joseph": [
        462
    ],
    "north miami": [
        462
    ],
    "troop 280": [
        463,
        1292,
        2144
    ],
    "carroll, edgar a.": [
        463
    ],
    "east hartford": [
        463,
        1200,
        1292
    ],
    "carson-hull, gary a.": [
        464
    ],
    "los altos": [
        464,
        959
    ],
    "troop 95": [
        465,
        2251,
        2268
    ],
    "carter, john f.": [
        465,
        466
    ],
    "manson": [
        465,
        466
    ],
    "pack 95": [
        466,
        1914
    ],
    "carter, clemens george": [
        467
    ],
    "explorer 601": [
        468
    ],
    "carver, timothy g.": [
        468,
        469
    ],
    "seneca": [
        468
    ],
    "troop 27": [
        469,
        623,
        1208,
        1726,
        2064,
        2279,
        2615,
        2617
    ],
    "liberty": [
        469,
        483,
        937,
        1682,
        1684,
        1685,
        1687,
        1902
    ],
    "casanova, nelson enriquez": [
        470
    ],
    "case, roy lindsay": [
        471
    ],
    "summerfield": [
        471
    ],
    "casey, joseph g.": [
        472
    ],
    "bensalem": [
        472
    ],
    "troop 576": [
        473,
        1253
    ],
    "cates, david e.": [
        473
    ],
    "sacramento": [
        473,
        1063,
        1493,
        1538
    ],
    "troop 250": [
        474,
        723,
        977,
        2203
    ],
    "cathcart, dieter": [
        474,
        475
    ],
    "cherry hill": [
        474,
        475
    ],
    "pack 2047": [
        476
    ],
    "cavanaugh, robert w.": [
        476
    ],
    "fairbanks": [
        476,
        1291,
        1617
    ],
    "ceballes, charles r.": [
        477
    ],
    "troop 155": [
        478,
        1330,
        2490
    ],
    "centrella, arthur": [
        478
    ],
    "west berlin": [
        478
    ],
    "troop 522": [
        479
    ],
    "challberg, william j.": [
        479
    ],
    "pack 109": [
        480,
        568
    ],
    "chamblee, john thomas": [
        480
    ],
    "melrose": [
        480
    ],
    "pack 304": [
        481,
        2196
    ],
    "chandler, oliver carl": [
        481,
        482
    ],
    "san angelo": [
        481,
        482,
        1399
    ],
    "troop 172": [
        483,
        766
    ],
    "chapman, john f.": [
        483
    ],
    "chapman-anderson, leonard h.": [
        484
    ],
    "chappell, maurice s.": [
        485,
        486
    ],
    "brockton": [
        485,
        486
    ],
    "explorer 335": [
        487
    ],
    "chappell, everett a.": [
        487
    ],
    "chase, herbert w.": [
        488
    ],
    "lansing": [
        488,
        1149
    ],
    "troop 656": [
        489
    ],
    "cherry, robert l.": [
        489
    ],
    "dresher": [
        489
    ],
    "cherry, kyle j.": [
        490
    ],
    "chiappini, william a.": [
        491
    ],
    "egg harbor": [
        491,
        641,
        839
    ],
    "chiappone, alan m.": [
        492
    ],
    "56": [
        493
    ],
    "south dakota": [
        493,
        1208
    ],
    "childs, george": [
        493
    ],
    "rapid city": [
        493
    ],
    "troop 56": [
        494,
        521
    ],
    "chrystie, bruce w.": [
        494
    ],
    "arlington": [
        494,
        2483
    ],
    "troop 58": [
        495,
        802,
        808
    ],
    "church, bruce e.": [
        495
    ],
    "martinsburg": [
        495,
        801,
        802
    ],
    "clapp, michael stephen": [
        496
    ],
    "high point": [
        496
    ],
    "pack 278": [
        497,
        1349
    ],
    "clark, nathan albert": [
        497
    ],
    "troop 259": [
        498,
        657,
        2216
    ],
    "clark, gregory m.": [
        498
    ],
    "opa locka": [
        498,
        542
    ],
    "clark, kenneth": [
        499
    ],
    "canyon lake": [
        499
    ],
    "clark, jesse steven": [
        500
    ],
    "pack 437": [
        501
    ],
    "clayborne, stewart": [
        501
    ],
    "cleveland ": [
        501
    ],
    "cleveland, blanchard l.": [
        502
    ],
    "troop 45": [
        503,
        934,
        1162,
        1437,
        1789,
        1828,
        2131,
        2586
    ],
    "cline, joseph w.": [
        503
    ],
    "clutter, elwin dean": [
        504
    ],
    "troop 337": [
        505,
        1097,
        1539,
        2410,
        2412
    ],
    "coakley, robert j": [
        505,
        506,
        507
    ],
    "emerson": [
        505,
        506
    ],
    "explorer 337": [
        506
    ],
    "forrestport": [
        507
    ],
    "troop 209": [
        508,
        1759
    ],
    "cochnauer, michael d.": [
        508
    ],
    "marshall": [
        508,
        904
    ],
    "cochran, robert": [
        509
    ],
    "troop 65": [
        510,
        640,
        682,
        780,
        1135,
        1193,
        1383,
        1741,
        2262,
        2266,
        2560,
        2561
    ],
    "codispoti, arnold edward": [
        510,
        511
    ],
    "cedar grove": [
        510,
        780
    ],
    "explorer 654": [
        511
    ],
    "newark ": [
        511
    ],
    "explorer 730": [
        512
    ],
    "codling, donald r.": [
        512
    ],
    "barre": [
        512
    ],
    "coe, roger a.": [
        513
    ],
    "tallahassee": [
        513,
        2512
    ],
    "explorer 173": [
        514,
        2473
    ],
    "coffey, bradley martin": [
        514
    ],
    "winchester": [
        514
    ],
    "pack 89": [
        515,
        2247
    ],
    "coffey, carleton t.": [
        515
    ],
    "pack 8602": [
        516
    ],
    "coffman, douglas j.": [
        516
    ],
    "marquette": [
        516
    ],
    "cohoon, leo": [
        517
    ],
    "colbert, joseph a.": [
        518
    ],
    "long beach": [
        518,
        1357,
        1368,
        1389,
        1403,
        1405,
        1512,
        1742,
        2461
    ],
    "explorer 553": [
        519
    ],
    "collar, richard e.": [
        519
    ],
    "snellville": [
        519
    ],
    "pack 323": [
        520,
        2421
    ],
    "collins, dennis david": [
        520
    ],
    "comer, martin j.": [
        521
    ],
    "medway": [
        521
    ],
    "cometto, nick i.": [
        522
    ],
    "cedar lake": [
        522
    ],
    "troop 38": [
        523,
        1959,
        2001,
        2078,
        2606
    ],
    "condlin, richard allen": [
        523,
        524
    ],
    "waddington": [
        523,
        524
    ],
    "pack 38": [
        524,
        620,
        1063,
        1161,
        1898
    ],
    "troop 37": [
        525,
        559,
        635,
        875,
        2181,
        2207
    ],
    "conekin, albert m.": [
        525
    ],
    "troop 50": [
        526,
        1088,
        1147,
        1663,
        2031,
        2583,
        2588
    ],
    "conkey, guy e.": [
        526
    ],
    "pack 321": [
        527,
        687,
        2425
    ],
    "conklin, harry e.": [
        527
    ],
    "battle creek": [
        527
    ],
    "connell, john d.": [
        528
    ],
    "harrisville": [
        528
    ],
    "troop 647": [
        529
    ],
    "conrad, alfred j.": [
        529
    ],
    "hallowell": [
        529
    ],
    "conti, steven a.": [
        530
    ],
    "burlington": [
        530,
        1233,
        1234,
        1453,
        1457,
        1887,
        2008
    ],
    "858": [
        531
    ],
    "cook, steven l.": [
        531
    ],
    "13": [
        532
    ],
    "cook, robert a.": [
        532
    ],
    "pack 880": [
        533
    ],
    "cooke, robert w.": [
        533
    ],
    "cooney, james j.": [
        534
    ],
    "westport": [
        534
    ],
    "cooper, william e.": [
        535,
        536,
        537
    ],
    "memphis": [
        535,
        536,
        913,
        1942,
        2530,
        2784
    ],
    "pack 8611": [
        536
    ],
    "hardy": [
        537
    ],
    "troop 668": [
        538,
        2340
    ],
    "cooper, samuel lee": [
        538
    ],
    "troop 350": [
        539,
        869,
        2397
    ],
    "cooper, james e.": [
        539
    ],
    "paramus": [
        539
    ],
    "cooper, brian r.": [
        540
    ],
    "cope, william burke": [
        541
    ],
    "dorris": [
        541
    ],
    "copps, william j.": [
        542
    ],
    "explorer 2972": [
        543
    ],
    "corbley, robert kevin": [
        543
    ],
    "gladstone": [
        543
    ],
    "not applicable ": [
        544,
        639,
        1562,
        1844,
        1849,
        1864,
        1948,
        1989,
        2049
    ],
    "corcoran, vincent james": [
        544
    ],
    "corringham, brian": [
        545
    ],
    "corssan, robert f.": [
        546
    ],
    "york": [
        546,
        1974,
        1975,
        1976,
        1977
    ],
    "cortez, stephen joseph": [
        547
    ],
    "vidalia": [
        547
    ],
    "costa, george a.": [
        548
    ],
    "duxbury": [
        548,
        2310
    ],
    "pack 185": [
        549
    ],
    "cote, john l.": [
        549
    ],
    "north tonawanda": [
        549
    ],
    "cottrell, paul v.": [
        550
    ],
    "anchorage": [
        550,
        750
    ],
    "coulter, mark a.": [
        551
    ],
    "troop 513": [
        552,
        1311
    ],
    "cousins, joseph a.": [
        552,
        553
    ],
    "troy": [
        552,
        553
    ],
    "pack 563": [
        553
    ],
    "79": [
        554
    ],
    "cowan, charles d.": [
        554
    ],
    "lafayette": [
        554,
        2355,
        2558
    ],
    "troop 999": [
        555,
        672,
        906,
        1485
    ],
    "cox, robert edward": [
        555
    ],
    "livermore": [
        555
    ],
    "troop 500": [
        556,
        2358
    ],
    "cox, harvey a.": [
        556
    ],
    "babson park": [
        556
    ],
    "troop 86": [
        557,
        1110,
        1408
    ],
    "cox, thomas r.": [
        557
    ],
    "maryville": [
        557
    ],
    "pack 61": [
        558,
        2273
    ],
    "germany": [
        558,
        608,
        1086,
        1132,
        2182,
        2184,
        2235,
        2237,
        2268,
        2269,
        2417,
        2422,
        2654
    ],
    "cox, earl w.": [
        558
    ],
    "cox, marcus": [
        559
    ],
    "hillsboro": [
        559,
        1451,
        1656
    ],
    "cram, fred a.": [
        560
    ],
    "casco": [
        560
    ],
    "district 8604": [
        561
    ],
    "cramer, william n.": [
        561
    ],
    "denville": [
        561
    ],
    "cramer, harry": [
        562
    ],
    "new orleans": [
        562,
        919,
        1591,
        1946,
        2124,
        2394,
        2669
    ],
    "troop 1903": [
        563
    ],
    "montana": [
        563,
        606,
        1267,
        1313,
        2280,
        2281,
        2617
    ],
    "crist, stephen d.": [
        563
    ],
    "missoula": [
        563
    ],
    "cronenwett, william herbert": [
        564
    ],
    "156": [
        565
    ],
    "crooks, dennis c.": [
        565
    ],
    "grosse pointe farms": [
        565
    ],
    "explorer 158": [
        566
    ],
    "crowder, david v.": [
        566
    ],
    "cedar park": [
        566
    ],
    "pack 314": [
        567
    ],
    "cseripko, michael j.": [
        567,
        568
    ],
    "duquesne": [
        567
    ],
    "nagatuck": [
        568
    ],
    "district 324": [
        569
    ],
    "culhane, bonnie j.": [
        569
    ],
    "cummings, william s.": [
        570
    ],
    "279": [
        571
    ],
    "cunningham, howard": [
        571
    ],
    "walnut hill": [
        571
    ],
    "cunyus, david wayne": [
        572
    ],
    "sea scouts 1": [
        573,
        1747
    ],
    "curls, william c.": [
        573
    ],
    "ft. myers ": [
        573
    ],
    "pack 47": [
        574
    ],
    "curren, dale": [
        574
    ],
    "marengo": [
        574
    ],
    "currie, richard eugene": [
        575,
        576
    ],
    "long lake": [
        576
    ],
    "explorer 240": [
        577
    ],
    "currier, hazen james": [
        577
    ],
    "curry, william neil": [
        578
    ],
    "hattiesburg": [
        578
    ],
    "troop 699": [
        579
    ],
    "curtiss, charles p.": [
        579,
        580,
        581
    ],
    "derby": [
        579,
        581
    ],
    "troop 620": [
        580
    ],
    "sea scouts 699": [
        581
    ],
    "troop 453": [
        582
    ],
    "cusimano, ross": [
        582
    ],
    "west covina": [
        582
    ],
    "dahlgren, gary g.": [
        583
    ],
    "enterprise": [
        583
    ],
    "troop 284": [
        584
    ],
    "d'amore, robert f.": [
        584
    ],
    "explorer 2070": [
        585,
        2781
    ],
    "danhauer, gary d.": [
        585
    ],
    "salida": [
        585
    ],
    "5033": [
        586
    ],
    "daniels, vernon r.": [
        586
    ],
    "cherry valley": [
        586
    ],
    "troop 490": [
        587
    ],
    "davenport, david dirk": [
        587
    ],
    "pack 706": [
        588
    ],
    "david, larry james": [
        588
    ],
    "maysville": [
        588,
        1702
    ],
    "davidson, eugene": [
        589
    ],
    "troop 867": [
        590
    ],
    "davis, james t.": [
        590
    ],
    "winston-salem": [
        590,
        619,
        1008,
        2012
    ],
    "troop 773": [
        591
    ],
    "davis, gerald l.": [
        591
    ],
    "irving": [
        591
    ],
    "davis, francis eugene": [
        592
    ],
    "walworth": [
        592
    ],
    "davis, harold e.": [
        593
    ],
    "sterling ": [
        593
    ],
    "troop 43": [
        594,
        885,
        1488,
        1549,
        2590
    ],
    "davis, mark w.": [
        594
    ],
    "westminster": [
        594,
        836,
        917,
        918,
        1878,
        1982
    ],
    "davis, donald l.": [
        595
    ],
    "davis, samuel d.": [
        596
    ],
    "pack 3072": [
        597
    ],
    "day, howard a.": [
        597,
        598
    ],
    "cerritos ": [
        597
    ],
    "cerritos": [
        598,
        1813
    ],
    "deal, robert d.": [
        599
    ],
    "troop 130": [
        600,
        2093
    ],
    "dean, alan miles": [
        600,
        601
    ],
    "marion": [
        600,
        601,
        826,
        892,
        1584,
        1586,
        1662,
        1704,
        2059,
        2496
    ],
    "explorer 137": [
        601
    ],
    "deane, jay w.": [
        602
    ],
    "pack 133": [
        603
    ],
    "debose, michael ray": [
        603,
        604
    ],
    "ruston": [
        603,
        604
    ],
    "explorer 245": [
        605
    ],
    "decesare, robert f.": [
        605
    ],
    "villa park": [
        605
    ],
    "deck, james h.": [
        606
    ],
    "great falls": [
        606,
        1267
    ],
    "troop 273": [
        607
    ],
    "decker, david g.": [
        607
    ],
    "stow": [
        607,
        679,
        680
    ],
    "defoor, rae c.": [
        608
    ],
    "mainz": [
        608
    ],
    "pack 245": [
        609
    ],
    "dejean, prescott louis": [
        609,
        610
    ],
    "port lavaca": [
        609
    ],
    "port o'connor": [
        610
    ],
    "pack 22": [
        611,
        662,
        1497,
        2621
    ],
    "new mexico": [
        611,
        612,
        790,
        929,
        1348,
        1725,
        1771,
        2270,
        2426,
        2539,
        2554
    ],
    "delgado, ernie": [
        611,
        612
    ],
    "roswell": [
        611,
        612
    ],
    "dellomo, angelo": [
        613
    ],
    "atlantic city": [
        613
    ],
    "deloach, g. lake": [
        614,
        615,
        616
    ],
    "greenwood": [
        614
    ],
    "troop 23": [
        615,
        658,
        716,
        852,
        994,
        1657,
        1805,
        2238,
        2278,
        2612,
        2630,
        2631,
        2632
    ],
    "cleveland": [
        615,
        1201,
        1689,
        1810,
        1812,
        2340,
        2359,
        2564,
        2602
    ],
    "hazelhurst": [
        616
    ],
    "troop 282": [
        617,
        1521
    ],
    "delong, robert w.": [
        617
    ],
    "saginaw": [
        617,
        1634
    ],
    "pack 194": [
        618
    ],
    "delreal, robert": [
        618,
        619
    ],
    "rural hall": [
        618
    ],
    "troop 962": [
        619
    ],
    "demelia, james": [
        620,
        621
    ],
    "marlboro": [
        620,
        621
    ],
    "troop 347": [
        622,
        643
    ],
    "demers, david c.": [
        622
    ],
    "keene": [
        622
    ],
    "deneke, john": [
        623
    ],
    "glen rock": [
        623
    ],
    "denison, joe dan": [
        624
    ],
    "dennis, mark a.": [
        625
    ],
    "la crosse": [
        625
    ],
    "explorer 2116": [
        626
    ],
    "derosa, thomas a.": [
        626
    ],
    "colorado springs": [
        626,
        1675,
        1712
    ],
    "troop 123": [
        627,
        1045,
        1286,
        1433,
        2234,
        2512,
        2514,
        2516
    ],
    "devine, james": [
        627,
        628
    ],
    "levittown": [
        627,
        628
    ],
    "pack 123": [
        628
    ],
    "devries, david a.": [
        629
    ],
    "casnovia": [
        629
    ],
    "troop 41": [
        630,
        1662,
        1884,
        1899,
        2003,
        2596,
        2598
    ],
    "dewey, robert l.": [
        630,
        631,
        632
    ],
    "whitehall": [
        630
    ],
    "pack 106": [
        631
    ],
    "rutland": [
        631,
        2667
    ],
    "kissimmee": [
        632
    ],
    "136": [
        633
    ],
    "deyarmin, daniel a.": [
        633
    ],
    "lewiston ": [
        633
    ],
    "164": [
        634
    ],
    "dhalluin, james t.": [
        634
    ],
    "diaz, christopher": [
        635,
        636,
        637
    ],
    "3371": [
        638
    ],
    "dickens, david kimbel": [
        638
    ],
    "bryan": [
        638
    ],
    "dickenson, george henri": [
        639
    ],
    "dickinson, richard l.": [
        640
    ],
    "liverpool": [
        640,
        682
    ],
    "diegelman, george j.": [
        641
    ],
    "troop 315": [
        642,
        1310
    ],
    "digiacomo, michael a.": [
        642
    ],
    "reisterstown": [
        642
    ],
    "greece": [
        643
    ],
    "dilley, richard l.": [
        643
    ],
    "dillingham, edward": [
        644
    ],
    "pacific palisades": [
        644,
        1372
    ],
    "76": [
        645
    ],
    "diminuco, frank g.": [
        645
    ],
    "dimsha, charles": [
        646
    ],
    "troop 35": [
        647,
        818,
        1368,
        2138,
        2594
    ],
    "dine, daniel russell": [
        647
    ],
    "wood river": [
        647
    ],
    "troop 48": [
        648,
        794,
        1126,
        1537
    ],
    "dingman, kenneth allan": [
        648
    ],
    "albion": [
        648
    ],
    "pack 3825": [
        649
    ],
    "disbennett, terry d.": [
        649
    ],
    "norfolk": [
        649,
        1191,
        1272,
        1375,
        1574,
        1957,
        2060,
        2405,
        2649
    ],
    "troop 236": [
        650
    ],
    "dittmar, robert a.": [
        650
    ],
    "dayton ": [
        650
    ],
    "pack 3395": [
        651
    ],
    "ditzler, kenneth s.": [
        651
    ],
    "dole, edward j.": [
        652
    ],
    "cambridge": [
        652,
        1668
    ],
    "doley, kenneth p.": [
        653
    ],
    "springfiled": [
        653
    ],
    "dollar, don h.": [
        654
    ],
    "north pole": [
        654
    ],
    "d'ombrain, nicolas paul": [
        655
    ],
    "donaghe, sam w.": [
        656
    ],
    "donahue, daniel t.": [
        657
    ],
    "nahshua": [
        657
    ],
    "dow, carl": [
        658
    ],
    "south burlington": [
        658
    ],
    "dowdle, j. reed": [
        659
    ],
    "downing, arthur h.": [
        660
    ],
    "troop 411": [
        661,
        759,
        2381
    ],
    "draher, william": [
        661
    ],
    "drake, benjamin e.": [
        662
    ],
    "grants pass": [
        662,
        1380,
        1736,
        1737
    ],
    "drake, robert fredrick": [
        663
    ],
    "explorer 111": [
        664
    ],
    "dreher, marty v.": [
        664
    ],
    "troop 336": [
        665,
        1867
    ],
    "dreisbach, edward a.": [
        665
    ],
    "105": [
        666
    ],
    "drummond, l.z.": [
        666,
        667
    ],
    "paola": [
        666,
        667
    ],
    "107": [
        667
    ],
    "drummond, brian e.": [
        668
    ],
    "drummond, mark gavin": [
        669
    ],
    "new hartford": [
        669
    ],
    "drury, kenneth james": [
        670
    ],
    "mckenzie bridge": [
        670
    ],
    "troop 965": [
        671
    ],
    "druxman, ed": [
        671,
        672
    ],
    "kettle falls": [
        671
    ],
    "colville": [
        672
    ],
    "drzewinski, edward r.": [
        673
    ],
    "fox chapel": [
        673
    ],
    "dubeau, paul a.": [
        674
    ],
    "willamantic": [
        674
    ],
    "dubois, richard a.": [
        675
    ],
    "5": [
        676
    ],
    "dubois, samuel max": [
        676
    ],
    "troop 263": [
        677,
        1201
    ],
    "duckworth, john edwin": [
        677,
        678,
        679,
        680
    ],
    "explorer 263": [
        678
    ],
    "explorer 177": [
        680
    ],
    "troop 884": [
        681
    ],
    "dudenhoeffer, kirk": [
        681
    ],
    "butler": [
        681,
        1509,
        1511
    ],
    "duerr, donald": [
        682
    ],
    "explorer 45": [
        683,
        2589
    ],
    "dufour, peter j.": [
        683
    ],
    "valley forge": [
        683
    ],
    "dugan, mike j.": [
        684
    ],
    "pack 3338": [
        685
    ],
    "duncan, donald g.": [
        685
    ],
    "pack 181": [
        686
    ],
    "duncan, frank edward": [
        686
    ],
    "dunham, edward p.": [
        687,
        688
    ],
    "wahiawa/wheeler afb": [
        687
    ],
    "troop 176": [
        688,
        1073,
        1469
    ],
    "wahiawa": [
        688
    ],
    "dunkin, mark l.e.": [
        689
    ],
    "pack 98": [
        690
    ],
    "dunlap, alan craig": [
        690,
        691
    ],
    "troop 378": [
        692,
        2392
    ],
    "dunn, rayford t.": [
        692
    ],
    "hico": [
        692
    ],
    "troop 326": [
        693,
        1404
    ],
    "dunn, alan k.": [
        693
    ],
    "venice": [
        693,
        1171
    ],
    "dunn, robert arnold": [
        694
    ],
    "20": [
        695,
        1471
    ],
    "dunnigan, daniel l.": [
        695
    ],
    "grand gorge": [
        695
    ],
    "pack 29": [
        696
    ],
    "dupont, lawrence j.": [
        696
    ],
    "troop 99": [
        697,
        792,
        867,
        952,
        1903,
        2538,
        2540,
        2542,
        2544
    ],
    "dupuy, william arley": [
        697
    ],
    "ft. gordon": [
        697,
        2540,
        2542
    ],
    "pack 3173": [
        698
    ],
    "dwyer, joe t.": [
        698
    ],
    "gower": [
        698
    ],
    "719": [
        699
    ],
    "dykes, timur v.": [
        699
    ],
    "troop 945": [
        700
    ],
    "eames, oliver d.": [
        700
    ],
    "farmington": [
        700,
        1138,
        1981
    ],
    "troop 239": [
        701,
        830,
        2445
    ],
    "easley, james j.": [
        701,
        702
    ],
    "troop 733": [
        702,
        1157
    ],
    "eaton, leon e.": [
        703
    ],
    "stone mountain": [
        703,
        1859
    ],
    "eaton, wayne martin": [
        704
    ],
    "ebberts, fred thomas": [
        705
    ],
    "clear spring": [
        705
    ],
    "explorer 8608": [
        706
    ],
    "eck, michael e.": [
        706,
        707
    ],
    "troop 891": [
        707
    ],
    "explorer 2161": [
        708
    ],
    "edmundson, sean a.": [
        708
    ],
    "chesapeake": [
        708,
        1014
    ],
    "troop 330": [
        709,
        2022
    ],
    "edwards, william reese": [
        709
    ],
    "prescott valley": [
        709
    ],
    "troop 160": [
        710,
        1335,
        2484
    ],
    "eisenhart, gregory l.": [
        710
    ],
    "drexel hill": [
        710,
        2484
    ],
    "23": [
        711
    ],
    "guam": [
        711
    ],
    "ellington, david joseph": [
        711,
        712
    ],
    "agana": [
        711
    ],
    "elliott, robert francis": [
        713
    ],
    "troop 185": [
        714,
        2483
    ],
    "ellis, david l.": [
        714
    ],
    "pack 60": [
        715,
        1281
    ],
    "elmquist, edward s.": [
        715
    ],
    "dugger": [
        715
    ],
    "emanuelson, arthur r.": [
        716,
        717
    ],
    "oxford ": [
        717
    ],
    "embly, kristofer j.": [
        718
    ],
    "bainbridge": [
        718
    ],
    "emmons, paul r.": [
        719
    ],
    "decatur": [
        719,
        736,
        949,
        1055,
        1829,
        1831
    ],
    "troop 345": [
        720,
        2008
    ],
    "emory, ernest c.": [
        720,
        721
    ],
    "troop 354": [
        721,
        861,
        1179,
        1763
    ],
    "district 5": [
        722,
        1174
    ],
    "empey, dennis j.": [
        722
    ],
    "provo": [
        722,
        1658,
        1896
    ],
    "enderle, jay j.a.": [
        723,
        724
    ],
    "explorer 887": [
        724
    ],
    "chaffee ": [
        724
    ],
    "engle, william b.": [
        725
    ],
    "clyde": [
        725,
        1999
    ],
    "pack 3168": [
        726
    ],
    "english, donald lee": [
        726
    ],
    "enz, paul j.": [
        727
    ],
    "erickson, keith duane": [
        728
    ],
    "erwin, michael j.": [
        729
    ],
    "taunton": [
        729
    ],
    "troop 154": [
        730,
        1500
    ],
    "eshelman, kenneth l.": [
        730
    ],
    "littitz": [
        730
    ],
    "troop 223": [
        731,
        1024,
        1823,
        1960,
        2213,
        2465
    ],
    "estes, melvin christopher": [
        731
    ],
    "amarillo": [
        731,
        1009
    ],
    "estey, scott cecil": [
        732
    ],
    "randolph": [
        732
    ],
    "evans, arthur c.": [
        733,
        734
    ],
    "idyllwild": [
        733
    ],
    "pack 8601": [
        734,
        1121
    ],
    "troop 506": [
        735,
        2353
    ],
    "evans, richard allen": [
        735,
        736
    ],
    "hammond": [
        735
    ],
    "pack 70": [
        736,
        2554
    ],
    "evans, jonathan w.": [
        737
    ],
    "kennewick": [
        737,
        1052,
        1331
    ],
    "troop 303": [
        738,
        786
    ],
    "every, steve b.": [
        738
    ],
    "webster groves": [
        738,
        2397
    ],
    "pack 777": [
        739
    ],
    "ewing, robert joseph": [
        739
    ],
    "florence": [
        739,
        1499,
        2079,
        2795
    ],
    "721": [
        740
    ],
    "falk, herbert henry": [
        740,
        741
    ],
    "troop 148": [
        741,
        1263
    ],
    "stockton": [
        741,
        1352
    ],
    "fanning, john wood": [
        742,
        743
    ],
    "park ridge": [
        742
    ],
    "lake charles": [
        743,
        1136,
        1592,
        2502
    ],
    "fannon, walter": [
        744
    ],
    "south boston": [
        744,
        970,
        972
    ],
    "troop 688": [
        745
    ],
    "farar, boyd": [
        745
    ],
    "troop 111": [
        746,
        1840,
        2221
    ],
    "farley, joel michael": [
        746
    ],
    "oak ridge": [
        746,
        1240
    ],
    "farrar, alden r.": [
        747
    ],
    "ayer": [
        747
    ],
    "troop 54": [
        748,
        2580
    ],
    "farrell, frank a.": [
        748
    ],
    "capertersville": [
        748
    ],
    "explorer 195": [
        749
    ],
    "feichtinger, eric frank": [
        749,
        750
    ],
    "anchorage ": [
        749
    ],
    "explorer 2": [
        750,
        2672
    ],
    "troop 514": [
        751,
        1988
    ],
    "puerto rico": [
        751,
        866
    ],
    "feliciano, eladio j.": [
        751
    ],
    "bayamon": [
        751
    ],
    "explorer 75": [
        752
    ],
    "felker, richard h.": [
        752,
        753
    ],
    "elmira": [
        752,
        1480,
        1845,
        2606
    ],
    "sea scouts 75": [
        753
    ],
    "horseheads": [
        753
    ],
    "fellows, richard carol": [
        754
    ],
    "manchester ": [
        754
    ],
    "troop 193": [
        755,
        1144,
        1696,
        2471
    ],
    "ferguson, samuel": [
        755
    ],
    "ferris, elwood j.": [
        756
    ],
    "troop 339": [
        757,
        1666
    ],
    "fertal, robert e.": [
        757
    ],
    "bethlehem": [
        757,
        1034
    ],
    "fiehn, larry o.": [
        758
    ],
    "omaha": [
        758,
        1610,
        1920,
        2250,
        2384,
        2391
    ],
    "field, stephen d.": [
        759,
        760
    ],
    "fillmore": [
        759
    ],
    "santa monica": [
        760,
        901,
        902,
        1442,
        2259,
        2260
    ],
    "troop 4412": [
        761
    ],
    "fields, virgil lynn": [
        761
    ],
    "fields, allen l.": [
        762
    ],
    "tustin": [
        762
    ],
    "finch, john e.": [
        763
    ],
    "venturing 6001": [
        764,
        2077
    ],
    "findlay, gary l.": [
        764
    ],
    "carol stream": [
        764
    ],
    "district 8601": [
        765
    ],
    "firestone, thomas leo": [
        765,
        766
    ],
    "kalamazoo": [
        765,
        954,
        1930
    ],
    "fischbach, john e.": [
        767
    ],
    "517": [
        768
    ],
    "fischer, michael james": [
        768
    ],
    "spanaway": [
        768
    ],
    "troop 749": [
        769
    ],
    "fisher, william h.": [
        769
    ],
    "reidsville": [
        769
    ],
    "fisher, walter lee": [
        770
    ],
    "san francisco": [
        770
    ],
    "fisher, gail d.": [
        771
    ],
    "san mateo": [
        771,
        2118
    ],
    "fitzgerald, john t.": [
        772
    ],
    "barneat": [
        772
    ],
    "troop 746": [
        773,
        1636
    ],
    "flatt, rodney g.": [
        773
    ],
    "flavin, michael l.": [
        774
    ],
    "troop 1052": [
        775
    ],
    "fleer, earl": [
        775,
        776
    ],
    "4052": [
        776
    ],
    "troop 9018": [
        777
    ],
    "fleming, henry w.": [
        777
    ],
    "groton": [
        777,
        1825
    ],
    "troop 364": [
        778
    ],
    "fleming, timothy l.": [
        778
    ],
    "louisville": [
        778,
        2399,
        2463,
        2489,
        2676
    ],
    "pack 67": [
        779,
        2564,
        2565
    ],
    "flemming, jerrold s.": [
        779
    ],
    "eugene": [
        779,
        2546,
        2661
    ],
    "flemming, gregory": [
        780
    ],
    "explorer 2079": [
        781
    ],
    "flosi, gregory j.": [
        781,
        782
    ],
    "round lake": [
        781
    ],
    "troop 83": [
        782,
        2541
    ],
    "lindenhurst": [
        782
    ],
    "fogarty, bernard n.": [
        783
    ],
    "saco": [
        783
    ],
    "folley, ernest john": [
        784
    ],
    "district 8690": [
        785
    ],
    "fonzo, gerald a.": [
        785,
        786
    ],
    "avoca": [
        785
    ],
    "exeter": [
        786
    ],
    "troop 592": [
        787
    ],
    "ford, william h.": [
        787
    ],
    "grand prairie": [
        787
    ],
    "forsythe, james a.": [
        788
    ],
    "foster, roland daniel": [
        789
    ],
    "foster, james b.": [
        790
    ],
    "fox, edgar l.": [
        791
    ],
    "quanah": [
        791
    ],
    "francies, david john": [
        792
    ],
    "myrtle point": [
        792
    ],
    "francis, jimmie leroy": [
        793
    ],
    "frankenfield, robert c.": [
        794,
        795
    ],
    "west frankfort": [
        794
    ],
    "little grassy lake": [
        795
    ],
    "franklin, roy a.": [
        796
    ],
    "lincoln park": [
        796
    ],
    "frantz, gene": [
        797
    ],
    "ranger": [
        797
    ],
    "pack 301": [
        798
    ],
    "frazier, jerry b.": [
        798
    ],
    "pack 6": [
        799,
        2659
    ],
    "freeman, steve": [
        799
    ],
    "wheat ridge": [
        799
    ],
    "freitas, john j.": [
        800
    ],
    "troop 161": [
        801,
        881
    ],
    "french, john eric": [
        801,
        802
    ],
    "district 8644": [
        803
    ],
    "fried, samuel l.": [
        803,
        804
    ],
    "queens": [
        803,
        1365,
        1998,
        2225,
        2229,
        2230,
        2582
    ],
    "bellerose": [
        804
    ],
    "pack 355": [
        805
    ],
    "frisbie, vincent paul": [
        805,
        806
    ],
    "kellogg": [
        805,
        806
    ],
    "fross, james a.": [
        807,
        808
    ],
    "tivoli": [
        807
    ],
    "red hook": [
        808
    ],
    "frost, ronald r.": [
        809,
        810
    ],
    "powers": [
        809,
        810
    ],
    "pack 135": [
        810,
        2498
    ],
    "sea scouts 241": [
        811
    ],
    "fuentes, roland r.": [
        811,
        812
    ],
    "sea scouts 210": [
        812
    ],
    "troop 294": [
        813,
        2175
    ],
    "fugate, charles f.": [
        813
    ],
    "fultz, david m.": [
        814,
        815
    ],
    "greensboro": [
        814,
        1676
    ],
    "browns summit": [
        815
    ],
    "troop 432": [
        816,
        1165
    ],
    "furness, jayne a.": [
        816
    ],
    "arroyo grande": [
        816
    ],
    "galdon, richard m.": [
        817,
        818
    ],
    "gallagher, joseph j.": [
        819,
        820
    ],
    "troop 94": [
        820
    ],
    "gallick, mark": [
        821
    ],
    "carthage": [
        821
    ],
    "troop 437": [
        822
    ],
    "gardner, keith m.": [
        822
    ],
    "gardner, richard e.": [
        823
    ],
    "weymouth": [
        823
    ],
    "gardner, mark richard": [
        824
    ],
    "gardner, edward m.": [
        825,
        826
    ],
    "palmyra": [
        825
    ],
    "explorer ": [
        826,
        1441
    ],
    "garon, peter p.": [
        827
    ],
    "troop 211": [
        828,
        972,
        987,
        1743,
        2116,
        2215
    ],
    "garrison, dennis": [
        828
    ],
    "donaldsville": [
        828
    ],
    "pack 3207": [
        829
    ],
    "garver, allen james": [
        829,
        830
    ],
    "north dakota": [
        831,
        1520,
        2190,
        2192,
        2383
    ],
    "garwood, bert r.": [
        831
    ],
    "souris": [
        831
    ],
    "gates, james": [
        832,
        833
    ],
    "paisley": [
        832
    ],
    "troop 369": [
        833,
        2179
    ],
    "merritt island": [
        833,
        2039
    ],
    "troop 664": [
        834
    ],
    "gates, kenneth": [
        834
    ],
    "gresham": [
        834,
        2450
    ],
    "gates, roger": [
        835
    ],
    "arcadia": [
        835
    ],
    "troop 408": [
        836,
        905,
        1499
    ],
    "gauthe, richard james": [
        836,
        837
    ],
    "denver": [
        837,
        925,
        1258,
        1851,
        1882,
        2022,
        2252,
        2373
    ],
    "155": [
        838
    ],
    "gehly, theodore e.": [
        838
    ],
    "elizabethtown": [
        838
    ],
    "geiger, charles paul": [
        839
    ],
    "geiter, dennis c.": [
        840,
        841
    ],
    "pack 16": [
        841,
        2280
    ],
    "geller, stewart ian": [
        842
    ],
    "george, owen ronald": [
        843
    ],
    "williamsport": [
        843,
        2475
    ],
    "explorer 59": [
        844
    ],
    "germeo, gary r.": [
        844
    ],
    "clarendon": [
        844
    ],
    "gesell, robert william": [
        845
    ],
    "ann arbor": [
        845
    ],
    "troop 74": [
        846,
        2257,
        2553
    ],
    "getman, douglas g.": [
        846
    ],
    "lefargeville": [
        846
    ],
    "giammarino, frank michael": [
        847
    ],
    "pack 3928": [
        848
    ],
    "gibbens, joseph e.": [
        848
    ],
    "navarre": [
        848
    ],
    "gibson, joe n.": [
        849,
        850
    ],
    "clearwater": [
        849,
        850
    ],
    "pack 410": [
        850
    ],
    "troop 385": [
        851
    ],
    "gibson, gerald w.": [
        851
    ],
    "gibson, donald eugene": [
        852
    ],
    "altoona": [
        852,
        2590
    ],
    "pack 20": [
        853
    ],
    "giddens, charles": [
        853,
        854
    ],
    "scottsboro": [
        853,
        854,
        1947
    ],
    "pack 263": [
        854
    ],
    "troop 593": [
        855
    ],
    "gilbert, robert h.": [
        855
    ],
    "troop 1231": [
        856
    ],
    "gill, robert w.": [
        856
    ],
    "troop 240": [
        857
    ],
    "gilman, david l.": [
        857,
        858
    ],
    "peoria": [
        857,
        858,
        1186,
        2494
    ],
    "gilpin, joel": [
        859
    ],
    "pack 68": [
        860
    ],
    "glazewski, vincent w.": [
        860
    ],
    "hammonton": [
        860
    ],
    "gleason, robert walter": [
        861
    ],
    "jenison": [
        861
    ],
    "pack 4151": [
        862
    ],
    "glover, ernest": [
        862
    ],
    "shelby": [
        862
    ],
    "troop 159": [
        863,
        1811
    ],
    "gloyd, ralph r.": [
        863
    ],
    "ashville": [
        863
    ],
    "troop 170": [
        864,
        2476
    ],
    "godin, walter lee": [
        864
    ],
    "troop 352": [
        865,
        2391
    ],
    "goei, howard": [
        865
    ],
    "troop 253": [
        866
    ],
    "goldstraw, john edward": [
        866,
        867
    ],
    "raney afb": [
        866
    ],
    "mcguire afb": [
        867,
        1753,
        1756
    ],
    "3260": [
        868,
        894
    ],
    "gowing, donald a.": [
        868,
        870
    ],
    "gomez, emilio a.": [
        869
    ],
    "san luis obispo": [
        869
    ],
    "good, kenneth r.": [
        871
    ],
    "martinez": [
        871
    ],
    "gowing, delmar b.": [
        872
    ],
    "gooding, alexander john": [
        873
    ],
    "east orange": [
        873,
        2515
    ],
    "troop 567": [
        874
    ],
    "grace, john e.": [
        874
    ],
    "depew": [
        874,
        1420
    ],
    "grady, daniel t.": [
        875
    ],
    "explorer 401": [
        876
    ],
    "goodman, james e.": [
        876
    ],
    "graham, john": [
        877
    ],
    "barstow": [
        877
    ],
    "graham, charles n.": [
        878
    ],
    "northville": [
        878
    ],
    "troop 323": [
        879,
        2419,
        2420
    ],
    "goodman, lester l.": [
        879
    ],
    "waterloo": [
        879,
        2242,
        2243,
        2766
    ],
    "pack 921": [
        880,
        1008
    ],
    "grassini, randee e.": [
        880
    ],
    "goodwin, james": [
        881,
        884
    ],
    "graybill, wayne": [
        882
    ],
    "gregoire, james f.": [
        883
    ],
    "bloomington": [
        884,
        940
    ],
    "grewe, charles": [
        885
    ],
    "lake stevens": [
        885
    ],
    "griffeth, richard j.": [
        886,
        888
    ],
    "mesquite": [
        886,
        888,
        1017
    ],
    "troop 436": [
        887,
        1456,
        2375
    ],
    "gorski, roy d.": [
        887
    ],
    "port orange": [
        887
    ],
    "pack 855": [
        888
    ],
    "troop 549": [
        889
    ],
    "goulet, dana edward": [
        889
    ],
    "troop 727": [
        890
    ],
    "griffin, eric": [
        890
    ],
    "arnold": [
        890
    ],
    "gower, john f.": [
        891
    ],
    "glendale": [
        891,
        1421
    ],
    "griffith, robert a.": [
        892
    ],
    "panama": [
        893
    ],
    "grimsley, ralph john": [
        893
    ],
    "balboa": [
        893
    ],
    "gowing, jeffrey donald": [
        894,
        896
    ],
    "pack 137": [
        895
    ],
    "gromack, gary": [
        895
    ],
    "hazlet": [
        895
    ],
    "grooms, morris w.": [
        897,
        898
    ],
    "carlyle": [
        898
    ],
    "gross, peter a.": [
        899
    ],
    "clayton": [
        899,
        1771,
        2409
    ],
    "troop 465": [
        900,
        1183,
        2180
    ],
    "haber, robert m.": [
        900,
        903
    ],
    "rockford": [
        900,
        903,
        933,
        1040,
        1294,
        1750,
        2289,
        2291,
        2292,
        2293,
        2396
    ],
    "grossman, alan h.": [
        901,
        902
    ],
    "pack 465": [
        903
    ],
    "troop 362": [
        904
    ],
    "groth, peter j.": [
        904
    ],
    "grounds, david p.": [
        905
    ],
    "hacking, eric": [
        906
    ],
    "orem": [
        906,
        1548
    ],
    "grubbs, robert monroe": [
        907,
        908
    ],
    "explorer 118": [
        909
    ],
    "hagen, bill j.": [
        909
    ],
    "nampa": [
        909
    ],
    "guerra, vincent m.": [
        910
    ],
    "birmingham": [
        910,
        1533,
        2312,
        2574
    ],
    "gunter, gerald wayne": [
        911
    ],
    "sea scouts 5011": [
        912
    ],
    "hahn, larry r.": [
        912
    ],
    "appleton": [
        912,
        1237,
        1239,
        2102,
        2105
    ],
    "troop 97": [
        913,
        2524,
        2526
    ],
    "gunter, riley wayne": [
        913
    ],
    "hale, gwenn": [
        914
    ],
    "troop 750": [
        915
    ],
    "guptil, mark t.": [
        915
    ],
    "waterbury": [
        915
    ],
    "troop 714": [
        916
    ],
    "guzzone, byron john": [
        916
    ],
    "renton": [
        916
    ],
    "troop 380": [
        917
    ],
    "hall, kenneth e.": [
        917,
        918
    ],
    "troop 381": [
        918
    ],
    "halvorsen, richard stanley": [
        919
    ],
    "hamblin, leroy b.": [
        920
    ],
    "kaysville": [
        920
    ],
    "hampton, raymond a.": [
        921
    ],
    "troop 403": [
        922
    ],
    "hall, william henry": [
        922
    ],
    "hankin, harvey s.": [
        923
    ],
    "napa": [
        923,
        1203,
        1205,
        2528
    ],
    "hanrahan, michael": [
        924
    ],
    "columbus": [
        924,
        984,
        997,
        1302,
        1761,
        1763,
        1858,
        2277,
        2407
    ],
    "troop 376": [
        925
    ],
    "hall, gerald j.": [
        925
    ],
    "519": [
        926
    ],
    "hanson, james a.": [
        926
    ],
    "russiaville": [
        926,
        2005
    ],
    "troop 251": [
        927,
        1723
    ],
    "hall, lloyd m.": [
        927
    ],
    "racine": [
        927
    ],
    "troop 831": [
        928,
        1151
    ],
    "hardesty, mike d.": [
        928
    ],
    "stillwater": [
        928
    ],
    "pack 368": [
        929
    ],
    "harkins, michael j.": [
        929
    ],
    "albuquerque": [
        929,
        2270,
        2426,
        2539
    ],
    "hall, daniel albert": [
        930
    ],
    "charleston": [
        930,
        1209,
        2569
    ],
    "harkness, ned m.": [
        931
    ],
    "arlington heights": [
        931,
        1639
    ],
    "hallisey, david a.": [
        932
    ],
    "marlborough": [
        932
    ],
    "pack 412": [
        933
    ],
    "hallquist, gene": [
        933
    ],
    "harper, kim morgan": [
        934,
        935
    ],
    "los angeles": [
        934,
        1070,
        1187,
        2548
    ],
    "troop 471": [
        935,
        1137,
        1551,
        2154
    ],
    "buena park": [
        935
    ],
    "harper, ralph r.": [
        936
    ],
    "boscawen": [
        936
    ],
    "troop 1373": [
        937
    ],
    "harper, christopher": [
        937
    ],
    "hatcher, robert luther": [
        938
    ],
    "flint ": [
        938
    ],
    "troop 452": [
        939,
        1197,
        2367,
        2369
    ],
    "haugh, fred": [
        939
    ],
    "cinti": [
        939
    ],
    "explorer 153": [
        940
    ],
    "harper, michael l.": [
        940
    ],
    "explorer 2061": [
        941
    ],
    "hawkins, richard l.": [
        941,
        943
    ],
    "harper, wallace george": [
        942
    ],
    "explorer 5061": [
        943
    ],
    "troop 69": [
        944,
        1533,
        2254,
        2556
    ],
    "hawks, vernon j.": [
        944,
        945
    ],
    "osborn": [
        944
    ],
    "haytock, richard k.": [
        946
    ],
    "heagy, steven miles": [
        947
    ],
    "heath, james l.": [
        948
    ],
    "binghamton": [
        948,
        2226,
        2228
    ],
    "harrison, richard": [
        949
    ],
    "hebert, donald j.": [
        950
    ],
    "troop 852": [
        951
    ],
    "hart, joseph c.": [
        951
    ],
    "heindl, bernard f.": [
        952
    ],
    "hartland": [
        952
    ],
    "troop 306": [
        953
    ],
    "harter, dave": [
        953,
        954
    ],
    "pack 21": [
        955
    ],
    "helms, charles david": [
        955
    ],
    "south elgin": [
        955
    ],
    "3": [
        956
    ],
    "hemley, daniel r.": [
        956
    ],
    "joliet": [
        956,
        2445
    ],
    "30": [
        957,
        1506
    ],
    "hartley, craig d.": [
        957,
        959
    ],
    "lod altos": [
        957
    ],
    "troop 91": [
        958,
        1194,
        1806
    ],
    "henley, dale smallwood": [
        958
    ],
    "belzoni": [
        958
    ],
    "36": [
        959,
        1616
    ],
    "troop 67": [
        960,
        1062,
        2091,
        2260,
        2562,
        2563,
        2776
    ],
    "henner, robert ray": [
        960
    ],
    "benton harbor": [
        960
    ],
    "troop 382": [
        961
    ],
    "harvey, edward l.": [
        961
    ],
    "pack 350": [
        962
    ],
    "henrikson, robert c.": [
        962
    ],
    "castle shannon": [
        962
    ],
    "troop 652": [
        963,
        2344
    ],
    "haskett, charles r.": [
        963
    ],
    "henson, jerry": [
        964
    ],
    "desert hot springs": [
        964
    ],
    "haskett, robbie c.": [
        965
    ],
    "whitnel": [
        965
    ],
    "pack 203": [
        966
    ],
    "hernandez, paul f.": [
        966,
        967
    ],
    "santa ana": [
        966,
        967,
        1045,
        1433,
        2488,
        2559
    ],
    "herring, charles francis": [
        968,
        969
    ],
    "tulsa ": [
        969
    ],
    "hightower, paul a.": [
        970,
        972
    ],
    "hersey, jake gary": [
        971
    ],
    "palatka": [
        971,
        1894
    ],
    "hess, terry": [
        973
    ],
    "cedar rapids": [
        973,
        1576,
        1577,
        1580,
        1624
    ],
    "higley, douglas lee": [
        974
    ],
    "154": [
        975
    ],
    "hester, luther wayne": [
        975
    ],
    "hill, paul b.": [
        976
    ],
    "hibbard, james d.": [
        977,
        978
    ],
    "biloxi": [
        977,
        978
    ],
    "pack 219": [
        978
    ],
    "explorer 2131": [
        979
    ],
    "hicks, michael l.": [
        979
    ],
    "yakima": [
        979,
        2658
    ],
    "troop 418": [
        980,
        1455
    ],
    "hillebrenner, eric": [
        980,
        983
    ],
    "orange park": [
        980,
        981,
        983
    ],
    "hicks, patrick h.": [
        981,
        982
    ],
    "explorer 418": [
        983,
        2379
    ],
    "troop 261": [
        984,
        2186
    ],
    "highman, michael shriver": [
        984
    ],
    "pack 111": [
        985,
        2517
    ],
    "hiller, john": [
        985,
        987
    ],
    "bridgeport": [
        985,
        987,
        2599
    ],
    "pack 406": [
        986
    ],
    "hogan, james f.": [
        986,
        988
    ],
    "explorer 812": [
        988
    ],
    "hills, mark e.": [
        989,
        992
    ],
    "moreland": [
        989
    ],
    "hokanson, richard": [
        990
    ],
    "holdsworth, william": [
        991
    ],
    "twentynine palms": [
        991
    ],
    "dix": [
        992
    ],
    "explorer 2027": [
        993
    ],
    "holland, ricky a.": [
        993
    ],
    "selma": [
        993
    ],
    "hollenback, kenneth": [
        994
    ],
    "hinton, darryl keith": [
        995
    ],
    "holzschuh, william howard": [
        996
    ],
    "belmar": [
        996
    ],
    "hoolihan, steve": [
        997
    ],
    "troop 479": [
        998
    ],
    "hobson, bernard c.": [
        998
    ],
    "348": [
        999
    ],
    "hooper, george richard": [
        999,
        1001
    ],
    "west point": [
        999,
        1001
    ],
    "hoffelder, douglas k.": [
        1000
    ],
    "pack 348": [
        1001
    ],
    "troop 234": [
        1002
    ],
    "hooper, jack": [
        1002
    ],
    "endicott": [
        1002
    ],
    "troop 145": [
        1003,
        1836,
        1924
    ],
    "hopgood, daniel": [
        1003
    ],
    "mt. clemens": [
        1003,
        2069
    ],
    "hopkins, robert james": [
        1004
    ],
    "lee center": [
        1004
    ],
    "horrall, steve r.": [
        1005,
        1007
    ],
    "benton": [
        1005,
        2138
    ],
    "explorer 257": [
        1006
    ],
    "horn, billie lorenz": [
        1006
    ],
    "copperas cove": [
        1006
    ],
    "troop 203": [
        1007,
        1245,
        2000,
        2466
    ],
    "wheatland": [
        1007
    ],
    "horton, john edward": [
        1008
    ],
    "hughes, george wayne": [
        1009
    ],
    "horton, eldon": [
        1010
    ],
    "dillon": [
        1010
    ],
    "hughes, brian": [
        1011
    ],
    "elizabeth": [
        1011
    ],
    "horton, gary r.": [
        1012
    ],
    "troop 811": [
        1013,
        1640
    ],
    "humphrey, richard e.": [
        1013
    ],
    "chula vista": [
        1013,
        2316
    ],
    "humphries, arthur w.": [
        1014
    ],
    "hosch, michael james": [
        1015
    ],
    "hunnicutt, william ray": [
        1016
    ],
    "troop 856": [
        1017,
        2164
    ],
    "hoseck, don eugene": [
        1017
    ],
    "troop 650": [
        1018
    ],
    "hunter, john k.": [
        1018
    ],
    "waukegan": [
        1018
    ],
    "huntzicker, eugene": [
        1019
    ],
    "blytheville": [
        1019
    ],
    "hotchkiss, harold": [
        1020
    ],
    "troop 635": [
        1021
    ],
    "hurst, david m.": [
        1021
    ],
    "troop 504": [
        1022
    ],
    "howard, george james": [
        1022
    ],
    "hutton, george": [
        1023
    ],
    "huyett, thomas w.": [
        1024
    ],
    "reading ": [
        1024
    ],
    "troop 523": [
        1025
    ],
    "jacobs, herbert melton": [
        1025
    ],
    "gastonia": [
        1025
    ],
    "hyslop, james": [
        1026
    ],
    "571": [
        1027
    ],
    "idle, donnie allen": [
        1027
    ],
    "norwalk": [
        1027,
        1060
    ],
    "jacobson, jack d.": [
        1028
    ],
    "longmont": [
        1028,
        1575,
        1881
    ],
    "troop 201": [
        1029,
        1604,
        2467,
        2468,
        2470
    ],
    "immel, kirk o.": [
        1029
    ],
    "jaggers, mark aaron": [
        1030
    ],
    "ingraham, robert l.": [
        1031
    ],
    "fort ann": [
        1031
    ],
    "6535": [
        1032
    ],
    "jamerson, wilbur j.": [
        1032,
        1033
    ],
    "dillwyn": [
        1032
    ],
    "jeffries, walter j.": [
        1034
    ],
    "512": [
        1035,
        1776
    ],
    "jellum, paul m.": [
        1035
    ],
    "henderson": [
        1035
    ],
    "janiak, thomas a.": [
        1036
    ],
    "jellum, patrick k.": [
        1037
    ],
    "jarman, gerald roger": [
        1038,
        1040
    ],
    "jennette, herman": [
        1039
    ],
    "pack 371": [
        1040,
        1302
    ],
    "troop 521": [
        1041,
        2368
    ],
    "jensen, eugene h.": [
        1041
    ],
    "jasper, james lewis": [
        1042
    ],
    "ft. dodge": [
        1042
    ],
    "troop 287": [
        1043,
        1424,
        1717,
        2270,
        2426
    ],
    "jensen, dick a.": [
        1043
    ],
    "mcminnville": [
        1043,
        1655
    ],
    "troop 731": [
        1044
    ],
    "jeanotte, charles e.": [
        1044
    ],
    "jefferson, donald": [
        1045
    ],
    "3334": [
        1046
    ],
    "jessop, richard j.": [
        1046
    ],
    "st. petersburg": [
        1046,
        1274
    ],
    "pack 10": [
        1047,
        1216,
        1760
    ],
    "jette, regis": [
        1047
    ],
    "cumberland ": [
        1047
    ],
    "johnson, frank aubry": [
        1048
    ],
    "corpus christi": [
        1048,
        2457,
        2465
    ],
    "troop 682": [
        1049,
        2337
    ],
    "johnson, warren leslie": [
        1049,
        1051
    ],
    "taylorville": [
        1049,
        1051
    ],
    "johnson, leonard c.": [
        1050,
        1053
    ],
    "johnson, richard": [
        1052
    ],
    "johnson, daniel w.": [
        1054
    ],
    "glenview": [
        1054
    ],
    "johnson, harry l.": [
        1055
    ],
    "johnson, william h.": [
        1056
    ],
    "brush": [
        1056
    ],
    "johnson, r. martin": [
        1057
    ],
    "troop 386": [
        1058,
        1315
    ],
    "jones, lawrence stephen": [
        1058
    ],
    "carmichael": [
        1058
    ],
    "johnson, john t.": [
        1059
    ],
    "379": [
        1060
    ],
    "jones, charles m.": [
        1060
    ],
    "jones, theron a.": [
        1061
    ],
    "johnston, charles d.": [
        1062
    ],
    "st. augustine": [
        1062
    ],
    "jones, hank a.": [
        1063
    ],
    "explorer 32": [
        1064,
        1644
    ],
    "johnston, gary": [
        1064
    ],
    "willimantie ": [
        1064
    ],
    "explorer 10": [
        1065
    ],
    "jones, timothy h.": [
        1065
    ],
    "buffalo": [
        1065,
        2187,
        2362,
        2541
    ],
    "johnston, john r.": [
        1066
    ],
    "wapakoneta": [
        1066
    ],
    "jonasson, richard c.": [
        1067,
        1069
    ],
    "jones, thomas": [
        1068
    ],
    "hawthorne": [
        1068
    ],
    "pack 120": [
        1069
    ],
    "jones, samuel l.": [
        1070
    ],
    "22": [
        1071,
        1399
    ],
    "jones, carl arthur": [
        1071
    ],
    "paris": [
        1071
    ],
    "troop 215": [
        1072,
        1564,
        1962
    ],
    "jones, sam c.": [
        1072,
        1074
    ],
    "jordan, ronald allen": [
        1073,
        1075
    ],
    "sarasota": [
        1073,
        1075
    ],
    "explorer 2388": [
        1074
    ],
    "explorer 25": [
        1075
    ],
    "769": [
        1076
    ],
    "jones, alfred m.": [
        1076
    ],
    "odenton": [
        1076
    ],
    "616": [
        1077
    ],
    "joy, michael r.": [
        1077
    ],
    "joynes, ronald": [
        1078
    ],
    "barrington": [
        1078
    ],
    "7": [
        1079
    ],
    "jungers, albert kenneth": [
        1079
    ],
    "fly creek": [
        1079
    ],
    "troop 365": [
        1080,
        1821
    ],
    "judd, mack arthur": [
        1080
    ],
    "victoria": [
        1080
    ],
    "explorer 851": [
        1081
    ],
    "jurek, allen g.": [
        1081
    ],
    "oviedo": [
        1081
    ],
    "troop 29": [
        1082,
        2168,
        2607
    ],
    "judd, bobby": [
        1082
    ],
    "welsh": [
        1082
    ],
    "july, john paul": [
        1083
    ],
    "explorer 689": [
        1084
    ],
    "jury, larry b.": [
        1084
    ],
    "frederick": [
        1084,
        1447,
        1449
    ],
    "troop 706": [
        1085,
        1702,
        2019
    ],
    "kees, james s.": [
        1085,
        1087
    ],
    "cocoa": [
        1085,
        2019
    ],
    "kablach, john e.": [
        1086
    ],
    "bremerhaven": [
        1086
    ],
    "paiseley": [
        1087
    ],
    "kanes, douglas f.": [
        1088
    ],
    "keheley, james e.": [
        1089
    ],
    "kaopuiki, bradford": [
        1090
    ],
    "corning": [
        1090
    ],
    "troop 110": [
        1091,
        1476,
        1587,
        1786,
        2081,
        2518,
        2527
    ],
    "kellar, dennis e.": [
        1091
    ],
    "district 8699": [
        1092
    ],
    "karczewski, julian a.": [
        1092,
        1094
    ],
    "alloway": [
        1092,
        1094
    ],
    "explorer 1014": [
        1093
    ],
    "kelleher, dale mathew": [
        1093
    ],
    "attleboro": [
        1093
    ],
    "troop 178": [
        1095,
        1901,
        2013
    ],
    "kelleher, john l.": [
        1095
    ],
    "monterey": [
        1095
    ],
    "troop 89": [
        1096,
        1940,
        2534
    ],
    "kasek, ronald j.": [
        1096
    ],
    "austintown": [
        1096
    ],
    "kelley, john j.": [
        1097
    ],
    "richmond hights": [
        1097
    ],
    "troop 865": [
        1098
    ],
    "kassel, tony r.": [
        1098
    ],
    "westland": [
        1098
    ],
    "troop 375": [
        1099,
        2398
    ],
    "kelly, max c.w.": [
        1099,
        1101,
        1103
    ],
    "burnt chimney": [
        1099
    ],
    "troop 533": [
        1100
    ],
    "kastura, george josh": [
        1100
    ],
    "pack 456": [
        1101
    ],
    "rocky mount": [
        1101
    ],
    "keating, jack w.": [
        1102
    ],
    "waukesha": [
        1102,
        1979,
        1980,
        2393
    ],
    "troop 115": [
        1104,
        1192,
        1278,
        1366,
        2509,
        2510,
        2511
    ],
    "keaton, robert jeff": [
        1104
    ],
    "kelsey, louis charles": [
        1105,
        1106
    ],
    "traverse city": [
        1105
    ],
    "pack 63": [
        1106,
        1853
    ],
    "onekama": [
        1106,
        2568
    ],
    "troop 181": [
        1107
    ],
    "kee, norman hoyt": [
        1107
    ],
    "west monroe": [
        1107,
        2651
    ],
    "545": [
        1108
    ],
    "kelto, douglas loren": [
        1108
    ],
    "addison": [
        1108
    ],
    "pack 3305": [
        1109
    ],
    "kennaday, donald f.": [
        1109
    ],
    "la crescenta": [
        1109
    ],
    "kempf, jack j.": [
        1110
    ],
    "1150": [
        1111
    ],
    "kennedy, james a.": [
        1111
    ],
    "troop 425": [
        1112,
        1995,
        2123
    ],
    "kikta, joseph": [
        1112
    ],
    "miami springs": [
        1112
    ],
    "kerns, robert dale": [
        1113,
        1115
    ],
    "islamorada": [
        1113
    ],
    "pack 232": [
        1114
    ],
    "kimber, donald j.": [
        1114
    ],
    "wolcott": [
        1114
    ],
    "graford": [
        1115,
        1856
    ],
    "kincaid, edker": [
        1116
    ],
    "logan": [
        1116
    ],
    "troop 507": [
        1117,
        1815
    ],
    "keyes, alan g.": [
        1117
    ],
    "naperville": [
        1117,
        1358,
        1718
    ],
    "troop 309": [
        1118
    ],
    "king, willis dale": [
        1118
    ],
    "santa paula": [
        1118
    ],
    "troop 119": [
        1119,
        1280,
        1381,
        1489,
        1522,
        1542
    ],
    "kinsler, norbert m.": [
        1119,
        1121
    ],
    "ventura ": [
        1119
    ],
    "troop 400": [
        1120,
        1223,
        1372,
        1667
    ],
    "keyes, david e.": [
        1120
    ],
    "ventura": [
        1121
    ],
    "keys, michael wayne": [
        1122,
        1124,
        1125
    ],
    "bossier city": [
        1122
    ],
    "kisthart, larry w.": [
        1123
    ],
    "bossier": [
        1124
    ],
    "japan": [
        1126,
        1724,
        1895,
        2233,
        2508,
        2619
    ],
    "keyser, robert norman": [
        1126
    ],
    "yokohama": [
        1126
    ],
    "kidd, lawrence paul": [
        1127
    ],
    "wilmore": [
        1127
    ],
    "pack 529": [
        1128
    ],
    "kistner, peter": [
        1128
    ],
    "troop 893": [
        1129,
        2070
    ],
    "kieffer, mark w.": [
        1129,
        1131
    ],
    "haysville": [
        1129,
        1131
    ],
    "pack 270": [
        1130
    ],
    "kittsteiner, karl h.": [
        1130
    ],
    "livonia": [
        1130
    ],
    "pack 893": [
        1131
    ],
    "troop 197": [
        1132,
        2469
    ],
    "klein, steven c.": [
        1132
    ],
    "bad kreuznach": [
        1132
    ],
    "explorer 55": [
        1133
    ],
    "kiger, claude orvel": [
        1133
    ],
    "knarich, curtis h.": [
        1134
    ],
    "travis afb": [
        1134
    ],
    "knoll, kenneth j.": [
        1135
    ],
    "elgin": [
        1135
    ],
    "knox, bob": [
        1136
    ],
    "koch, david f.": [
        1137,
        1138
    ],
    "farmington ": [
        1137
    ],
    "koefoot, paul scott": [
        1139
    ],
    "troop 188": [
        1140,
        1375,
        1983
    ],
    "kral, donald lee": [
        1140,
        1142
    ],
    "el paso": [
        1140,
        1142,
        2278,
        2279,
        2288,
        2290,
        2628,
        2634
    ],
    "pack 3507": [
        1141
    ],
    "koehler, robert j.": [
        1141
    ],
    "kenosha": [
        1141,
        1455
    ],
    "explorer 2285": [
        1143
    ],
    "kolp, joseph p.": [
        1143
    ],
    "kramer, george edward": [
        1144
    ],
    "lower allen township": [
        1144
    ],
    "krause, david a.": [
        1145,
        1147
    ],
    "korecky, david r.": [
        1146
    ],
    "korman, john j.": [
        1148
    ],
    "troop 414": [
        1149
    ],
    "krauszowski, david m.": [
        1149
    ],
    "troop 293": [
        1150
    ],
    "koski, martin r.": [
        1150
    ],
    "minnewasta": [
        1150
    ],
    "krechel, thomas d.": [
        1151
    ],
    "kost, charles a.": [
        1152
    ],
    "shenandoah": [
        1152
    ],
    "explorer 24": [
        1153
    ],
    "kredel, robert e.": [
        1153
    ],
    "irvine": [
        1153
    ],
    "explorer 16": [
        1154
    ],
    "kreil, william g.": [
        1154
    ],
    "pack 888": [
        1155
    ],
    "kostiuk, terry lee": [
        1155
    ],
    "stuart": [
        1155
    ],
    "krigbaum, arthur n.": [
        1156
    ],
    "kovatch, william j.": [
        1157
    ],
    "plant city": [
        1157
    ],
    "pack 3506": [
        1158
    ],
    "kroyer, douglas j.": [
        1158
    ],
    "medina": [
        1158,
        1398
    ],
    "troop 505": [
        1159,
        1740
    ],
    "kowalski, thomas h.": [
        1159
    ],
    "greendale ": [
        1159
    ],
    "kruger, donn w.": [
        1160
    ],
    "pepperell": [
        1160
    ],
    "kozel, john nelson": [
        1161
    ],
    "kruse, wesley a.": [
        1162
    ],
    "duluth": [
        1162
    ],
    "troop 237": [
        1163,
        2449
    ],
    "kracke, nyle": [
        1163
    ],
    "la junta": [
        1163
    ],
    "kuhn, kyle s.": [
        1164
    ],
    "coatsville": [
        1164
    ],
    "krajewski, raymond": [
        1165
    ],
    "jersey city": [
        1165,
        2589
    ],
    "kummer, gerald william": [
        1166
    ],
    "west allis": [
        1166
    ],
    "pack 311": [
        1167
    ],
    "lambert, brian k.": [
        1167,
        1169
    ],
    "kunkel, james a.": [
        1168
    ],
    "honey brook": [
        1168
    ],
    "troop 233": [
        1169,
        2206,
        2454
    ],
    "pack 100": [
        1170
    ],
    "kuper, edward p.": [
        1170
    ],
    "moreno valley": [
        1170
    ],
    "kyle, donald s.": [
        1171
    ],
    "pack 4057": [
        1172
    ],
    "lambert, robert a.": [
        1172
    ],
    "depue": [
        1172
    ],
    "pack 226": [
        1173,
        2463
    ],
    "labadie, al": [
        1173,
        1175
    ],
    "huntington beach": [
        1173,
        1175,
        1430,
        1431
    ],
    "lambert, marvin l.": [
        1174
    ],
    "labeau, gary m.": [
        1176
    ],
    "66": [
        1177
    ],
    "lamkin, laverne s.": [
        1177
    ],
    "indian falls": [
        1177
    ],
    "lamont, charles a.": [
        1178
    ],
    "labelle, kenneth r.": [
        1179
    ],
    "payson": [
        1179
    ],
    "lane, jerry w.": [
        1180
    ],
    "labrecque, john paul": [
        1181
    ],
    "norwich": [
        1181,
        2046
    ],
    "pack 87": [
        1182
    ],
    "lachapelle, richard a.": [
        1182,
        1184
    ],
    "north andover": [
        1182,
        1184,
        1347
    ],
    "langford, calvin r.": [
        1183
    ],
    "pilot point": [
        1183
    ],
    "langley, william lawrence": [
        1185
    ],
    "atlantic": [
        1185
    ],
    "lankton, james r.": [
        1186
    ],
    "lachica, joel": [
        1187
    ],
    "lantz, kenneth lee": [
        1188
    ],
    "troop 24": [
        1189,
        1205,
        1384,
        2226,
        2286,
        2625,
        2627,
        2629
    ],
    "ladison, charles e.": [
        1189,
        1191
    ],
    "belmont": [
        1189
    ],
    "pack 2": [
        1190,
        2303
    ],
    "laroche, michael james": [
        1190
    ],
    "larsen, michael l.": [
        1192
    ],
    "laehn, ronald j.": [
        1193
    ],
    "mondovi": [
        1193
    ],
    "larsen, allen gorden": [
        1194
    ],
    "oneida": [
        1194,
        2365
    ],
    "troop 875": [
        1195,
        2125
    ],
    "lassman, jeffrey m.": [
        1195
    ],
    "garland": [
        1195
    ],
    "latham, richard william": [
        1196,
        1197
    ],
    "cortland": [
        1196,
        1416
    ],
    "offutt afb": [
        1197
    ],
    "laurita, joseph a.": [
        1198
    ],
    "troop 20": [
        1199,
        1845,
        2293,
        2626,
        2628
    ],
    "leavitt, george william": [
        1199
    ],
    "berkeley": [
        1199,
        2066
    ],
    "explorer 2743": [
        1200
    ],
    "lavoie, john p.": [
        1200
    ],
    "leckler, kurt w.": [
        1201
    ],
    "explorer 2740": [
        1202
    ],
    "lawlor, james": [
        1202
    ],
    "ocean": [
        1202
    ],
    "lawrence, charles edward": [
        1203,
        1205
    ],
    "ledezma, alberto j.": [
        1204,
        1207
    ],
    "troop 1346": [
        1206
    ],
    "lawrence, richard e.": [
        1206
    ],
    "burke": [
        1206
    ],
    "lawrence, raymond g.w.": [
        1208
    ],
    "edgemont": [
        1208
    ],
    "pack 41": [
        1209
    ],
    "lee, lester d.": [
        1209
    ],
    "lawrence, christopher": [
        1210
    ],
    "lee, richard d.": [
        1211
    ],
    "laws, don": [
        1212
    ],
    "lee, michael": [
        1213
    ],
    "lazzareschi, william": [
        1214,
        1216
    ],
    "leight, cletus": [
        1215
    ],
    "johnston": [
        1216
    ],
    "explorer 131": [
        1217
    ],
    "leininger, william h.": [
        1217
    ],
    "leach, henry": [
        1218,
        1221
    ],
    "pascoag": [
        1218
    ],
    "leiter, harold e.": [
        1219
    ],
    "leland, edward l.": [
        1220
    ],
    "hopkinton": [
        1221,
        1377
    ],
    "district 8603": [
        1222,
        1833
    ],
    "lemley, robert c.": [
        1222
    ],
    "leahy, jonathan f.": [
        1223
    ],
    "warrensburg": [
        1223,
        2241
    ],
    "pack 13": [
        1224,
        1356,
        1917
    ],
    "lemp, james michael": [
        1224,
        1226
    ],
    "granite city": [
        1224,
        1226
    ],
    "troop 525": [
        1225
    ],
    "leppard, ronald david": [
        1225
    ],
    "lesh, david g.": [
        1227
    ],
    "alanson": [
        1227
    ],
    "letteer, mark": [
        1228
    ],
    "lenwell, john": [
        1229
    ],
    "terre haute": [
        1229
    ],
    "lewis, jerry wayne": [
        1230,
        1231
    ],
    "homer": [
        1230,
        1231
    ],
    "pack 555": [
        1231
    ],
    "troop 230": [
        1232
    ],
    "lister, joe a.": [
        1232
    ],
    "edwards": [
        1232
    ],
    "pack 103": [
        1233
    ],
    "lewis, thomas benton": [
        1233,
        1234
    ],
    "troop 638": [
        1235
    ],
    "liter, david c.": [
        1235
    ],
    "pensacola": [
        1235,
        2666
    ],
    "explorer 7": [
        1236
    ],
    "lewis, james r.": [
        1236,
        1238
    ],
    "pack 25": [
        1237
    ],
    "litscher, jerome c.": [
        1237,
        1239
    ],
    "explorer 1": [
        1238,
        2677
    ],
    "329": [
        1240
    ],
    "lewis, james b.": [
        1240
    ],
    "troop 243": [
        1241,
        1602,
        1930
    ],
    "litteer, robert": [
        1241
    ],
    "gilford": [
        1241
    ],
    "pack 309": [
        1242
    ],
    "lewis, william": [
        1242
    ],
    "excelsior springs": [
        1242
    ],
    "pack 382": [
        1243
    ],
    "little, daniel ray": [
        1243
    ],
    "huntsville": [
        1243
    ],
    "lewis, ricky l.": [
        1244
    ],
    "daleville": [
        1244
    ],
    "loch, michael j.": [
        1245
    ],
    "lantana": [
        1245
    ],
    "3169": [
        1246
    ],
    "lieberman, richard p.": [
        1246
    ],
    "lilley, joseph k.": [
        1247
    ],
    "aberdeen": [
        1247
    ],
    "lokken, scott": [
        1248
    ],
    "long, lauri b.": [
        1249
    ],
    "lynch, kenneth r.": [
        1250
    ],
    "ft. worth": [
        1250,
        2040
    ],
    "longo, robert john": [
        1251
    ],
    "lyon, paul k.": [
        1252
    ],
    "goodman ": [
        1252
    ],
    "loomis, harold wayne": [
        1253
    ],
    "buckley": [
        1253
    ],
    "macdonald, brian": [
        1254,
        1256
    ],
    "hansen": [
        1254,
        1256
    ],
    "lopardo, joseph r.": [
        1255,
        1257
    ],
    "eiselsen afb": [
        1255
    ],
    "yacca council": [
        1257
    ],
    "troop 229": [
        1258
    ],
    "lopez, gregory": [
        1258
    ],
    "pack 11": [
        1259
    ],
    "macgregor, george w.": [
        1259,
        1261
    ],
    "troop 177": [
        1260,
        2109
    ],
    "lovell, dennis": [
        1260
    ],
    "mackey, john michael": [
        1262
    ],
    "lowry, david p.": [
        1263
    ],
    "granbury": [
        1263
    ],
    "548": [
        1264
    ],
    "macleod, james a.": [
        1264
    ],
    "maddock, terrance j.": [
        1265
    ],
    "sacramento ": [
        1265
    ],
    "lucas, randy r.": [
        1266
    ],
    "oakland": [
        1266,
        1287,
        1289,
        2367,
        2470
    ],
    "maddox, roger s.": [
        1267
    ],
    "explorer 9681": [
        1268
    ],
    "lundell, david e.": [
        1268
    ],
    "bettendorf": [
        1268,
        2545
    ],
    "maddox, bruce w.": [
        1269
    ],
    "troop 456": [
        1270
    ],
    "lundy, richard j.": [
        1270
    ],
    "madigan, glen a.": [
        1271
    ],
    "pack 124": [
        1272
    ],
    "lurz, hank w.": [
        1272
    ],
    "maegly, john e.": [
        1273
    ],
    "kalida": [
        1273
    ],
    "294": [
        1274
    ],
    "lutz, roger e.": [
        1274
    ],
    "magar, dewey eugene": [
        1275
    ],
    "maitland, frederick r.": [
        1276
    ],
    "lowell": [
        1276
    ],
    "troop 449": [
        1277,
        2370
    ],
    "magreevy, william d.": [
        1277,
        1279
    ],
    "eufaula": [
        1277
    ],
    "maitre, william j.": [
        1278
    ],
    "wilton manors": [
        1278,
        1366
    ],
    "ashtabula": [
        1279
    ],
    "malone, matthew": [
        1280
    ],
    "hempstead": [
        1280
    ],
    "marshall, john": [
        1281
    ],
    "troop 270": [
        1282,
        2442
    ],
    "mancuso, patrick a.": [
        1282
    ],
    "bronx": [
        1282
    ],
    "maniscalco, leonard": [
        1283
    ],
    "west babylon": [
        1283
    ],
    "martginetti, anthony j.": [
        1284
    ],
    "old tappan": [
        1284
    ],
    "explorer 97": [
        1285
    ],
    "manley, mark": [
        1285
    ],
    "margate": [
        1285,
        1465
    ],
    "martin, robert a.": [
        1286,
        1288
    ],
    "shreveport": [
        1286,
        2255
    ],
    "pack 31": [
        1287
    ],
    "manning, roberta m.": [
        1287
    ],
    "shreveport ": [
        1288
    ],
    "manning, donald w.": [
        1289
    ],
    "martin, david w.": [
        1290
    ],
    "troop 3020": [
        1291
    ],
    "mapes, jack c.": [
        1291
    ],
    "martin, david c.": [
        1292
    ],
    "pack 3": [
        1293,
        1511,
        1592
    ],
    "mares, manuel a.": [
        1293
    ],
    "brighton": [
        1293,
        1310,
        1404
    ],
    "margrave, gerald e.": [
        1294
    ],
    "martindale, william ben": [
        1295
    ],
    "martinelli, roderick patrick": [
        1296
    ],
    "san rafael": [
        1296
    ],
    "martinez, joe a.": [
        1297,
        1299
    ],
    "markinson, craig": [
        1298
    ],
    "daly city": [
        1298
    ],
    "troop 1631": [
        1299
    ],
    "sugar land": [
        1299
    ],
    "pack 101": [
        1300,
        1928,
        2021,
        2529
    ],
    "marshall, david alan": [
        1300
    ],
    "wilber": [
        1300
    ],
    "martinez, luciano ernest": [
        1301,
        1303
    ],
    "greybull": [
        1301
    ],
    "mason, richard": [
        1302
    ],
    "basin": [
        1303
    ],
    "troop 975": [
        1304
    ],
    "massey, leonard r.": [
        1304
    ],
    "camden": [
        1304
    ],
    "martinez, antonio": [
        1305
    ],
    "massey, carroll": [
        1306
    ],
    "troop 781": [
        1307
    ],
    "martz, keith a.": [
        1307
    ],
    "forreston": [
        1307
    ],
    "matheny, donald w.": [
        1308
    ],
    "la grange": [
        1308
    ],
    "mathews, robert": [
        1309
    ],
    "mcarthur, theodore": [
        1310
    ],
    "mathias, franklin leon": [
        1311,
        1312
    ],
    "la grande": [
        1311,
        1312
    ],
    "troop 516": [
        1312
    ],
    "mcbride, john d.": [
        1313
    ],
    "libby": [
        1313
    ],
    "troop 3204": [
        1314
    ],
    "mattice, kenneth edwin": [
        1314
    ],
    "flowery branch ": [
        1314
    ],
    "mccaffrey, elmer e.": [
        1315
    ],
    "mattice-walters, pattie ann": [
        1316
    ],
    "mccallie, ernest l.": [
        1317
    ],
    "mauch, michael s.": [
        1318
    ],
    "anacortes": [
        1318
    ],
    "maule, david p.": [
        1319
    ],
    "pack 2391": [
        1320
    ],
    "maxfield, norman": [
        1320
    ],
    "clinton": [
        1320
    ],
    "maxson, bradley": [
        1321
    ],
    "troop 96": [
        1322,
        1618,
        1803,
        2211,
        2528
    ],
    "district of columbia": [
        1322,
        2155
    ],
    "may, michael": [
        1322
    ],
    "explorer 265": [
        1323
    ],
    "mccormick, james": [
        1323
    ],
    "baker": [
        1323
    ],
    "troop 463": [
        1324
    ],
    "mccrery, charles": [
        1324
    ],
    "sunnyvale": [
        1324,
        2205
    ],
    "troop 433": [
        1325
    ],
    "mckinney, melvin e.": [
        1325,
        1327
    ],
    "norco": [
        1325,
        1327
    ],
    "mcdonnell, david m.": [
        1326
    ],
    "needham": [
        1326
    ],
    "explorer 98": [
        1328
    ],
    "mcduffie, russell b.": [
        1328
    ],
    "texarkana": [
        1328
    ],
    "troop 640": [
        1329
    ],
    "mckinney, ralph a.": [
        1329
    ],
    "scottsdale": [
        1329
    ],
    "mcelreath, richard l.": [
        1330
    ],
    "whitinsville": [
        1330
    ],
    "mclallen, jerald a.": [
        1331
    ],
    "mcfarland, fred": [
        1332
    ],
    "mclaughlin, kevin j.": [
        1333
    ],
    "fallon": [
        1333,
        1898
    ],
    "explorer 555": [
        1334
    ],
    "mcgahee, rubin curtis": [
        1334
    ],
    "macclenny": [
        1334
    ],
    "mclaughlin, thomas l.": [
        1335
    ],
    "new albany": [
        1335
    ],
    "explorer 2360": [
        1336
    ],
    "mcmacken, dennis king": [
        1336,
        1338,
        1340
    ],
    "flagstaff": [
        1336,
        1338,
        1340,
        2140
    ],
    "mcgilvray, david": [
        1337
    ],
    "pack 3034": [
        1338
    ],
    "mcgrew, john e.": [
        1339
    ],
    "troop 3034": [
        1340
    ],
    "183": [
        1341
    ],
    "mcmillian, billy ray": [
        1341
    ],
    "means, clyde h.": [
        1342
    ],
    "mcmurry, roger": [
        1343
    ],
    "mebane, john d.": [
        1344
    ],
    "district 8620": [
        1345
    ],
    "mcreynolds, steve": [
        1345
    ],
    "meeks, dan w.": [
        1346
    ],
    "lodi": [
        1346,
        1847
    ],
    "mcrobbie, daniel r.": [
        1347
    ],
    "troop 114": [
        1348,
        2513,
        2515
    ],
    "meisner, lawrence": [
        1348
    ],
    "silver city ": [
        1348
    ],
    "melbourne, patrick joseph": [
        1349
    ],
    "hastings": [
        1349
    ],
    "mead, alan d.": [
        1350
    ],
    "memmo, paul": [
        1351
    ],
    "broomall": [
        1351,
        2403,
        2493
    ],
    "pack 3100": [
        1352
    ],
    "meadows, daniel": [
        1352
    ],
    "troop 786": [
        1353,
        1855
    ],
    "menghi, thomas j.": [
        1353
    ],
    "fayetteville": [
        1353
    ],
    "troop 399": [
        1354,
        2068
    ],
    "means, robert ted": [
        1354
    ],
    "lorenzo": [
        1354
    ],
    "merila, richard e.": [
        1355,
        1356
    ],
    "troop 140": [
        1357,
        1615
    ],
    "meyer, keith dale": [
        1357,
        1368
    ],
    "merkel, stephen j.": [
        1358
    ],
    "meyer, herman g.": [
        1359
    ],
    "blairstown": [
        1359
    ],
    "113": [
        1360
    ],
    "merlau, edson": [
        1360
    ],
    "hoisington": [
        1360
    ],
    "messersmith, robert": [
        1361
    ],
    "meyer, james m.": [
        1362
    ],
    "micarelli, edmond c.": [
        1363
    ],
    "michaels, charles alan": [
        1364
    ],
    "explorer 48": [
        1365
    ],
    "methvin, robert": [
        1365
    ],
    "metzger, frank": [
        1366
    ],
    "pack 3389": [
        1367
    ],
    "middleton, william": [
        1367
    ],
    "arlington ": [
        1367
    ],
    "pack 141": [
        1369
    ],
    "miller, george r.": [
        1369,
        1370
    ],
    "belvidere": [
        1369,
        1370
    ],
    "explorer 244": [
        1371
    ],
    "miller, dean o.": [
        1371
    ],
    "miller, ken": [
        1372
    ],
    "troop 420": [
        1373,
        2161
    ],
    "mills, william r.": [
        1373
    ],
    "junction": [
        1373
    ],
    "troop 227": [
        1374,
        1835
    ],
    "miller, phillip f.": [
        1374
    ],
    "grand rapids": [
        1374,
        2441
    ],
    "miller, mark": [
        1375
    ],
    "troop 64": [
        1376,
        1478,
        1575,
        1706,
        1778,
        2010,
        2147,
        2258
    ],
    "miller, dennis a.": [
        1376
    ],
    "painesville": [
        1376
    ],
    "mink, albert": [
        1377
    ],
    "miller, robert e.": [
        1378
    ],
    "pueblo": [
        1378,
        2077,
        2566,
        2621
    ],
    "miller, kenneth g.": [
        1379
    ],
    "troop 6090": [
        1380
    ],
    "mitchell, jay douglas": [
        1380
    ],
    "mitchell, frank e.": [
        1381
    ],
    "mitchell, albert t.": [
        1382
    ],
    "monroe, gary l.": [
        1383
    ],
    "wood dale": [
        1383,
        1738,
        1741
    ],
    "mixon, larry w.": [
        1384
    ],
    "spartanburg": [
        1384
    ],
    "monroe, wes d.": [
        1385
    ],
    "montague, jeff": [
        1386
    ],
    "topeka": [
        1386
    ],
    "moberly, john thomas": [
        1387
    ],
    "pack 494": [
        1388
    ],
    "modica, freddie": [
        1388
    ],
    "montoya, daniel": [
        1389
    ],
    "troop 75": [
        1390,
        1535,
        1672,
        1690,
        1818,
        2088
    ],
    "moeller, david william": [
        1390
    ],
    "anderson": [
        1390
    ],
    "mooers, howard t.": [
        1391
    ],
    "mooney, michael": [
        1392
    ],
    "blue point": [
        1392
    ],
    "troop 149": [
        1393,
        1859,
        2495
    ],
    "moffitt, david w.": [
        1393
    ],
    "oregon city": [
        1393,
        2097,
        2438,
        2451
    ],
    "pack 310": [
        1394,
        1396,
        1888
    ],
    "moore, kathlene g.": [
        1394
    ],
    "dearing": [
        1394,
        1396
    ],
    "mohr, johnnie l.": [
        1395
    ],
    "reynolds": [
        1395
    ],
    "moore, frederick andrew": [
        1396
    ],
    "moore, daniel j.": [
        1397
    ],
    "boylston": [
        1397
    ],
    "troop 501": [
        1398,
        2356
    ],
    "mollica, anthony j.": [
        1398
    ],
    "moos, dale c.": [
        1399
    ],
    "troop 457": [
        1400
    ],
    "mondary, roy e.": [
        1400
    ],
    "jasonville": [
        1400
    ],
    "morgan, andrew t.": [
        1401,
        1402
    ],
    "white lake": [
        1402
    ],
    "morrison, kevin keen": [
        1403,
        1405
    ],
    "morley, robert nelson": [
        1404
    ],
    "pack 4": [
        1406
    ],
    "morris, alan richard": [
        1406,
        1408
    ],
    "cape coral": [
        1406,
        1408
    ],
    "pack 54": [
        1407,
        2581
    ],
    "morrison, james randolph": [
        1407
    ],
    "leola": [
        1407
    ],
    "pack 14": [
        1409,
        1712,
        2645
    ],
    "morrison, lenny m.": [
        1409,
        1410
    ],
    "belleville": [
        1409,
        1410,
        2082
    ],
    "murray, gerald f.": [
        1411
    ],
    "mosher, reginald": [
        1412
    ],
    "explorer 28": [
        1413
    ],
    "murray, harold j.": [
        1413,
        1414
    ],
    "hagerstown": [
        1413,
        1414,
        2581
    ],
    "explorer 139": [
        1414
    ],
    "myhre, john e.": [
        1415
    ],
    "oppenheim": [
        1415
    ],
    "moulton, john dudley": [
        1416
    ],
    "troop 450": [
        1417
    ],
    "naffky, peter": [
        1417
    ],
    "tonawanda": [
        1417,
        2351
    ],
    "troop 1058": [
        1418
    ],
    "nahodyl, james j.": [
        1418
    ],
    "victorville": [
        1418
    ],
    "england": [
        1419,
        2773
    ],
    "mulnix, walter j.": [
        1419
    ],
    "lakenheath air force base": [
        1419
    ],
    "nail, douglas w.": [
        1420
    ],
    "district 1": [
        1421
    ],
    "mumford, jeffrey h.": [
        1421
    ],
    "nakanishi, stanley j.": [
        1422
    ],
    "mundy, merlin edward": [
        1423
    ],
    "narmi, mark e.": [
        1424
    ],
    "murphree, casey": [
        1425
    ],
    "needle, howie": [
        1426
    ],
    "inverness": [
        1426
    ],
    "troop 195": [
        1427
    ],
    "neff, james l.": [
        1427
    ],
    "south charleston": [
        1427
    ],
    "neftzger, joe n.": [
        1428
    ],
    "troop 705": [
        1429
    ],
    "nehring, clifford gordon": [
        1429
    ],
    "north fond du lac": [
        1429
    ],
    "negrette, david robert": [
        1430,
        1431,
        1433
    ],
    "troop 348": [
        1431,
        2399
    ],
    "16": [
        1432
    ],
    "nelson, david james": [
        1432
    ],
    "pack 487": [
        1434
    ],
    "nelson, richard w.": [
        1434
    ],
    "loveland": [
        1434
    ],
    "pack 395": [
        1435
    ],
    "nelson, states lee": [
        1435
    ],
    "benbrook": [
        1435
    ],
    "troop 577": [
        1436,
        2348
    ],
    "newman, emanuel": [
        1436
    ],
    "willowgrove": [
        1436
    ],
    "newman, arnold o.": [
        1437
    ],
    "ferndale": [
        1437
    ],
    "nelson, keven b.": [
        1438
    ],
    "newman, george": [
        1439
    ],
    "nelson, david l.": [
        1440
    ],
    "newman, edward al": [
        1441,
        1443
    ],
    "st. charles": [
        1441,
        2491
    ],
    "pack 605": [
        1442
    ],
    "nenno, patrick william": [
        1442
    ],
    "osceola county": [
        1443
    ],
    "nerau, alan r.": [
        1444
    ],
    "pack 735": [
        1445
    ],
    "newman, daniel keith": [
        1445
    ],
    "covington": [
        1445
    ],
    "troop 266": [
        1446
    ],
    "ney, robert graham": [
        1446
    ],
    "troop 1600": [
        1447,
        1635
    ],
    "neufeld, harold a.": [
        1447,
        1449
    ],
    "pack 594": [
        1448
    ],
    "nichols, gene e.": [
        1448
    ],
    "kingston springs": [
        1448
    ],
    "troop 799": [
        1449
    ],
    "explorer 373": [
        1450
    ],
    "nichols, robert f.": [
        1450
    ],
    "pauls valley": [
        1450
    ],
    "newby, rand darrell": [
        1451
    ],
    "nicholson, dennis d.": [
        1452
    ],
    "cedar falls": [
        1452
    ],
    "pack 3641": [
        1453
    ],
    "newcity, randall": [
        1453,
        1457
    ],
    "troop 528": [
        1454
    ],
    "nickerson, william h.": [
        1454
    ],
    "livermore falls": [
        1454
    ],
    "nicoll, george t.": [
        1455
    ],
    "niedringhaus, william": [
        1456
    ],
    "narberth": [
        1456
    ],
    "troop 641": [
        1457
    ],
    "troop 1044": [
        1458
    ],
    "nobert, mark": [
        1458,
        1460
    ],
    "south meriden": [
        1458,
        1460
    ],
    "troop 614": [
        1459
    ],
    "newman, charles r.": [
        1459,
        1461
    ],
    "longview": [
        1459,
        1461,
        2213,
        2214
    ],
    "troop 1020": [
        1460
    ],
    "explorer 801": [
        1461
    ],
    "noble, martin frederick": [
        1462
    ],
    "nolan, gary": [
        1463,
        1465
    ],
    "davie": [
        1463
    ],
    "explorer 1119": [
        1464
    ],
    "o'connor, rory j.": [
        1464,
        1489
    ],
    "morrow": [
        1464,
        1489
    ],
    "troop 836": [
        1465
    ],
    "troop 196": [
        1466
    ],
    "o'conner, robert jake": [
        1466
    ],
    "winter springs": [
        1466
    ],
    "troop 249": [
        1467,
        2791
    ],
    "nolte, randy j.": [
        1467
    ],
    "winters": [
        1467
    ],
    "odell, amos f.": [
        1468
    ],
    "nonclerg, michael d.": [
        1469
    ],
    "edinboro": [
        1469
    ],
    "troop 214": [
        1470,
        2457
    ],
    "o'dell, glen": [
        1470
    ],
    "pleasant valley": [
        1470
    ],
    "noreau, lawrence f.": [
        1471
    ],
    "odya, robert e.": [
        1472
    ],
    "norton, roger b.": [
        1473
    ],
    "troop 182": [
        1474
    ],
    "novac, robert j.": [
        1474
    ],
    "ankeny": [
        1474
    ],
    "ogan, james e.": [
        1475
    ],
    "chilicothe": [
        1475
    ],
    "novak, david a.": [
        1476
    ],
    "windber": [
        1476,
        1786,
        1787
    ],
    "okerlund, denes p.": [
        1477
    ],
    "novak, roger": [
        1478
    ],
    "troop 515": [
        1479,
        2374
    ],
    "olaskowitz, michael": [
        1479,
        1481
    ],
    "noyes, thomas e.": [
        1480
    ],
    "pack 515": [
        1481
    ],
    "nye, edward e.": [
        1482
    ],
    "explorer 907": [
        1483
    ],
    "oldroyd, robert j.": [
        1483
    ],
    "apopka": [
        1483
    ],
    "nykamp, john martin": [
        1484
    ],
    "oliva, rudy t.": [
        1485
    ],
    "oborn, edward a.": [
        1486
    ],
    "barberton": [
        1486
    ],
    "o'boyle, james j.": [
        1487
    ],
    "oliver, donald duane": [
        1488
    ],
    "safety harbor": [
        1488
    ],
    "oliver, william": [
        1490
    ],
    "o'neal, john c.": [
        1491
    ],
    "pack 231": [
        1492
    ],
    "olivieri, albert j.": [
        1492
    ],
    "olson, john norman": [
        1493
    ],
    "explorer 2012": [
        1494
    ],
    "opalinski, leland": [
        1494
    ],
    "omahen, alan lee": [
        1495
    ],
    "lithonia": [
        1495
    ],
    "district 3": [
        1496
    ],
    "opfar, thomas": [
        1496
    ],
    "opfer, ray": [
        1497
    ],
    "reno": [
        1497,
        2551
    ],
    "pack 242": [
        1498
    ],
    "overholt, dan s.": [
        1498
    ],
    "las animas": [
        1498
    ],
    "orman, ernest nestor": [
        1499
    ],
    "overlin, kim a.": [
        1500
    ],
    "ft. wood": [
        1500
    ],
    "ormond, david michael": [
        1501
    ],
    "evansville": [
        1501,
        2244,
        2246
    ],
    "troop 427": [
        1502
    ],
    "overton, michael j.": [
        1502
    ],
    "mendon": [
        1502
    ],
    "osher, richard e.": [
        1503
    ],
    "troop 47": [
        1504
    ],
    "owens, richard": [
        1504
    ],
    "oshkosh": [
        1504
    ],
    "ostopowich, darrald timmie": [
        1505
    ],
    "owens, robert": [
        1506
    ],
    "troop 988": [
        1507
    ],
    "ostrowski, theodore j.": [
        1507
    ],
    "oxton, alfred j.": [
        1508,
        1510
    ],
    "gorham": [
        1508,
        1510
    ],
    "pack 30": [
        1509
    ],
    "otte, albert william": [
        1509,
        1511
    ],
    "pack 153": [
        1512
    ],
    "pace, norman james": [
        1512
    ],
    "oubre, beatrice t.": [
        1513,
        1515
    ],
    "berwick": [
        1513,
        2086,
        2128
    ],
    "explorer 89": [
        1514,
        1783
    ],
    "pace, roy k.": [
        1514
    ],
    "seminole": [
        1514,
        1556,
        1838
    ],
    "pack 438": [
        1515
    ],
    "morgan city": [
        1515
    ],
    "overby, archer r.": [
        1516
    ],
    "troop 570": [
        1517
    ],
    "pace, marc steve": [
        1517
    ],
    "deltona": [
        1517
    ],
    "pack 3192": [
        1518
    ],
    "paech, manfred wolfgang": [
        1518
    ],
    "ogilvie": [
        1518
    ],
    "pack 902": [
        1519
    ],
    "palmer, thomas": [
        1519
    ],
    "jonesboro": [
        1519
    ],
    "troop 458": [
        1520,
        2132
    ],
    "page, dennis r.": [
        1520
    ],
    "surrey": [
        1520
    ],
    "palmer, harry a.": [
        1521
    ],
    "paige, gilbert l.": [
        1522
    ],
    "troop 580": [
        1523
    ],
    "parent, timothy d.": [
        1523
    ],
    "rumford": [
        1523
    ],
    "pack 919": [
        1524
    ],
    "parker, rolland keith": [
        1524,
        1526
    ],
    "boone grove": [
        1524,
        1526
    ],
    "pajot, john walter": [
        1525
    ],
    "palank, darrell": [
        1527
    ],
    "2430": [
        1528
    ],
    "parks, harold d.": [
        1528
    ],
    "ithaca": [
        1528
    ],
    "palese, ronald e.": [
        1529
    ],
    "parrish, jerry lee": [
        1530
    ],
    "pack 227": [
        1531
    ],
    "parsons, karolyn": [
        1531
    ],
    "haughton": [
        1531
    ],
    "peabody, david w.": [
        1532
    ],
    "partain, ricky carl": [
        1533,
        1535
    ],
    "troop 228": [
        1534,
        1754,
        2460
    ],
    "pearson, bobby gene": [
        1534
    ],
    "pinson": [
        1535
    ],
    "pease, david b.": [
        1536,
        1538
    ],
    "patterson, william ronald daniel": [
        1537
    ],
    "eagle point": [
        1537
    ],
    "paul, les": [
        1539
    ],
    "pack 3084": [
        1540
    ],
    "peat, david a.": [
        1540,
        1542
    ],
    "galt": [
        1540,
        1542
    ],
    "troop 602": [
        1541,
        2771
    ],
    "pawlowski, arnold lee": [
        1541
    ],
    "payne, larry james": [
        1543
    ],
    "pack 763": [
        1544
    ],
    "peetz, james frederick": [
        1544
    ],
    "beaverton": [
        1544
    ],
    "pack 3048": [
        1545
    ],
    "payne, steven e.": [
        1545
    ],
    "oxford": [
        1545
    ],
    "district 9": [
        1546
    ],
    "pelkey, giles": [
        1546
    ],
    "lexington ": [
        1546
    ],
    "troop 716": [
        1547
    ],
    "pence, edward a.": [
        1547
    ],
    "troop 187": [
        1548
    ],
    "peterson, brian r.": [
        1548
    ],
    "petitt, alan j.": [
        1549,
        1550
    ],
    "batavia": [
        1549,
        1550,
        2197,
        2199
    ],
    "pack 112": [
        1550
    ],
    "petrauskas, thomas j.": [
        1551
    ],
    "pack 343": [
        1552
    ],
    "peot, frank r.": [
        1552
    ],
    "sun prairie": [
        1552
    ],
    "petrie, john phillip": [
        1553
    ],
    "district 13": [
        1554
    ],
    "perales, alonso m.": [
        1554
    ],
    "phegley, luvern w.": [
        1555
    ],
    "troop 340": [
        1556
    ],
    "perkins, herman myles": [
        1556
    ],
    "explorer 733": [
        1557
    ],
    "phillips, david w.": [
        1557
    ],
    "yorba linda": [
        1557
    ],
    "pack 151": [
        1558,
        2493
    ],
    "permar, bernard j.": [
        1558
    ],
    "international  falls": [
        1558
    ],
    "phillips, jeffrey patrick": [
        1559
    ],
    "troop 258": [
        1560,
        2113,
        2214
    ],
    "perrucci, thomas j.": [
        1560
    ],
    "mahwah": [
        1560
    ],
    "phillips, gary brian": [
        1561
    ],
    "phillips, bryan g.": [
        1562
    ],
    "phipps, walter j.": [
        1563
    ],
    "west des moines": [
        1563
    ],
    "pilkington, gregory alan": [
        1564
    ],
    "picon, frank a.": [
        1565
    ],
    "piloco, arthur": [
        1566
    ],
    "troop 1776": [
        1567
    ],
    "pierson, barry n.": [
        1567
    ],
    "pincomb, william t.": [
        1568
    ],
    "499": [
        1569
    ],
    "pietz, floyd fenten": [
        1569
    ],
    "apple valley": [
        1569
    ],
    "pack 3027": [
        1570
    ],
    "pinson, david d.": [
        1570
    ],
    "fredericktown": [
        1570
    ],
    "pike, thomas j.": [
        1571
    ],
    "redlands": [
        1571
    ],
    "pitman, lester r.": [
        1572
    ],
    "chalfont": [
        1572
    ],
    "pack 3093": [
        1573
    ],
    "potter, darrell g.": [
        1573
    ],
    "noel": [
        1573
    ],
    "pitz, michael t.": [
        1574
    ],
    "potter, raymond": [
        1575
    ],
    "poland, james a.": [
        1576,
        1577
    ],
    "pack 17": [
        1577
    ],
    "troop 232": [
        1578
    ],
    "polinski, albert a.": [
        1578
    ],
    "powell, james l.": [
        1579
    ],
    "meridian ": [
        1579
    ],
    "pollock, richard lee": [
        1580
    ],
    "pack 160": [
        1581
    ],
    "powers, john f.": [
        1581
    ],
    "prentiss, william c.": [
        1582
    ],
    "troop 254": [
        1583,
        1826,
        2444
    ],
    "pomeroy, robert r.": [
        1583
    ],
    "euless": [
        1583
    ],
    "explorer 161": [
        1584
    ],
    "presson, rex d.": [
        1584,
        1586
    ],
    "poncin, gary c.": [
        1585
    ],
    "windom": [
        1585
    ],
    "preze, kipp": [
        1587
    ],
    "porter, malcolm w.": [
        1588
    ],
    "camp 4120": [
        1589
    ],
    "price, vernon lee": [
        1589
    ],
    "price, byard j.": [
        1590
    ],
    "troop 582": [
        1591
    ],
    "primeaux, whitney joseph": [
        1591,
        1592
    ],
    "prudlow, patrick e.": [
        1593
    ],
    "pewaukee": [
        1593
    ],
    "troop 442": [
        1594,
        2089
    ],
    "pringle, barry charles": [
        1594
    ],
    "st. maries": [
        1594
    ],
    "troop 1549": [
        1595
    ],
    "puerner, lester a.": [
        1595
    ],
    "bremerton": [
        1595,
        2171
    ],
    "prior, robert g.": [
        1596,
        1598
    ],
    "troop 4935": [
        1597
    ],
    "pugh, ronald kelly": [
        1597,
        1599
    ],
    "skaneateles": [
        1598
    ],
    "pack 4935": [
        1599
    ],
    "explorer 200": [
        1600
    ],
    "pugh, gordon": [
        1600
    ],
    "manhasset": [
        1600
    ],
    "troop 1604": [
        1601
    ],
    "quick, richard j.": [
        1601
    ],
    "royal oak": [
        1601
    ],
    "quillin, james h.": [
        1602
    ],
    "wilmington ": [
        1602
    ],
    "explorer 546": [
        1603
    ],
    "quinn, michael": [
        1603
    ],
    "quintano, anders": [
        1604
    ],
    "williston park": [
        1604
    ],
    "pulling, aubrey": [
        1605
    ],
    "rader, thomas e.": [
        1606
    ],
    "purcell, andrew allen": [
        1607
    ],
    "beaumont": [
        1607
    ],
    "radloff, gary l.": [
        1608
    ],
    "east dubuque": [
        1608
    ],
    "troop 755": [
        1609
    ],
    "purdy, robert": [
        1609
    ],
    "west bloomfield": [
        1609
    ],
    "troop 388": [
        1610,
        1871
    ],
    "ramos, david a.": [
        1610
    ],
    "randles, merle r.": [
        1611
    ],
    "dresden": [
        1611
    ],
    "troop 740": [
        1612
    ],
    "rankin, david macdonald": [
        1612
    ],
    "college park": [
        1612
    ],
    "purtell, john r.": [
        1613,
        1614
    ],
    "rapson, eugene willie": [
        1615
    ],
    "kannapolis": [
        1615
    ],
    "purvis, daniel k.": [
        1616
    ],
    "rarick, kenneth r.": [
        1617
    ],
    "putney, robert e.": [
        1618
    ],
    "urbandale": [
        1618
    ],
    "ratchford, richard w.": [
        1619
    ],
    "canton": [
        1619,
        1628,
        1826
    ],
    "312": [
        1620
    ],
    "pyatt, michael j.": [
        1620
    ],
    "razor, jim": [
        1621
    ],
    "chippewa falls": [
        1621
    ],
    "explorer 152": [
        1622
    ],
    "reade, gordon": [
        1622
    ],
    "palo alto": [
        1622,
        2058
    ],
    "pack 3075": [
        1623
    ],
    "ratliff, garth e.": [
        1623
    ],
    "santa barbara": [
        1623,
        1921,
        2645
    ],
    "pack 156": [
        1625
    ],
    "raulerson, joel emmett": [
        1625
    ],
    "avon park": [
        1625
    ],
    "raynard, mark a.": [
        1627
    ],
    "francis, allan r.": [
        1629
    ],
    "pack 702": [
        1631
    ],
    "reeves, george w.": [
        1631,
        1646
    ],
    "explorer 2276": [
        1634
    ],
    "reeves, david paul": [
        1634
    ],
    "hacker, thomas e.": [
        1635,
        1637,
        1639
    ],
    "oak lawn": [
        1635
    ],
    "reeves, robert": [
        1636
    ],
    "reich, lee a.": [
        1638
    ],
    "reid, larry w.": [
        1640
    ],
    "cleburne": [
        1640,
        2612
    ],
    "reed, james william": [
        1641
    ],
    "troop 1341": [
        1642
    ],
    "reed, david a.": [
        1642
    ],
    "pack 275": [
        1643
    ],
    "reinbold, gilbert a.": [
        1643
    ],
    "smithland": [
        1643
    ],
    "reed, charles anthony": [
        1644
    ],
    "remington, rod l.": [
        1645
    ],
    "neosho": [
        1645
    ],
    "troop 702": [
        1646
    ],
    "repice, joseph peter": [
        1647
    ],
    "revals, charles j.": [
        1648
    ],
    "troop 491": [
        1649,
        1915
    ],
    "ricci, john stephen": [
        1649
    ],
    "connelton": [
        1649
    ],
    "explorer 521": [
        1650
    ],
    "rex, john l.": [
        1650,
        1653
    ],
    "troop 762": [
        1651
    ],
    "riccio, james e.": [
        1651
    ],
    "banton": [
        1651
    ],
    "troop 267": [
        1652
    ],
    "rice, richard a.": [
        1652
    ],
    "explorer 747": [
        1653
    ],
    "rice, fred l.": [
        1654
    ],
    "troop 260": [
        1655
    ],
    "reynolds, bruce t.": [
        1655
    ],
    "pack 240": [
        1656
    ],
    "richards, james ross": [
        1656
    ],
    "reynolds, robert e.": [
        1657
    ],
    "bartlesville": [
        1657,
        2659
    ],
    "richins, leroy h.": [
        1658
    ],
    "pack 771": [
        1659
    ],
    "rhodes, howard alan": [
        1659
    ],
    "troop 597": [
        1660
    ],
    "rickert, scott a.": [
        1660
    ],
    "douglassville": [
        1660
    ],
    "rhodes, terry james": [
        1661
    ],
    "rickman, myron j.": [
        1662
    ],
    "rhymer, earl andrew": [
        1663
    ],
    "rieb, mark a.": [
        1664
    ],
    "sterling": [
        1664
    ],
    "pack 552": [
        1665
    ],
    "riffe, david michael": [
        1665
    ],
    "rochelle": [
        1665
    ],
    "rinehart, victor k.": [
        1666,
        1673
    ],
    "rigby, david w.": [
        1667
    ],
    "scarsdale": [
        1667
    ],
    "rileigh, charles e.": [
        1668
    ],
    "pack 206": [
        1669
    ],
    "rios, edward t.": [
        1669
    ],
    "pack 3701": [
        1670
    ],
    "rimmasch, phillip k.": [
        1670
    ],
    "ripley, steven michael": [
        1671
    ],
    "maplewood": [
        1671
    ],
    "rincon, ed": [
        1672
    ],
    "garden grove": [
        1672,
        2404,
        2480
    ],
    "explorer 106": [
        1673
    ],
    "sea scouts 724": [
        1674
    ],
    "rise, james martin": [
        1674
    ],
    "troop 3064": [
        1675
    ],
    "riste, michael m.": [
        1675
    ],
    "roberts, douglas": [
        1676
    ],
    "roberts, matthew": [
        1677
    ],
    "pack 430": [
        1678
    ],
    "rivera, alfred a.": [
        1678
    ],
    "pack 180": [
        1679
    ],
    "roberts, william e.": [
        1679
    ],
    "vacaville": [
        1679
    ],
    "rivera, eduardo pabon": [
        1680
    ],
    "pack 3077": [
        1681
    ],
    "roberts, john f.": [
        1681
    ],
    "marysville": [
        1681
    ],
    "troop 373": [
        1682
    ],
    "roberts, jack k.": [
        1682,
        1684,
        1685,
        1687
    ],
    "pack 3139": [
        1683
    ],
    "rivers, john t.": [
        1683
    ],
    "troop 374": [
        1685
    ],
    "rivers, randle g.": [
        1686
    ],
    "robertson, james s.": [
        1688
    ],
    "walsenburg": [
        1688
    ],
    "rix, james patrick": [
        1689
    ],
    "robertson, james k.": [
        1690
    ],
    "hartford": [
        1690
    ],
    "troop 247": [
        1691,
        1945
    ],
    "roback, roger": [
        1691,
        1693
    ],
    "pack 3323": [
        1692
    ],
    "robins, randi": [
        1692
    ],
    "walled lake": [
        1692
    ],
    "pack 247": [
        1693
    ],
    "robinson, richard l.": [
        1694
    ],
    "robinson, david w.": [
        1695
    ],
    "murray": [
        1695,
        1828
    ],
    "robb, charles william": [
        1696,
        1699
    ],
    "davis": [
        1696,
        1699
    ],
    "troop 3120": [
        1697
    ],
    "robinson, harlow d.": [
        1697
    ],
    "rice": [
        1697
    ],
    "roche, rick": [
        1698
    ],
    "pack 193": [
        1699
    ],
    "pack 55": [
        1700
    ],
    "rocker, clifford w.": [
        1700
    ],
    "robbins, eric d.": [
        1701
    ],
    "rodgers, james s.": [
        1702
    ],
    "explorer 1507": [
        1703
    ],
    "roberts, john w.": [
        1703
    ],
    "rogers, david a.": [
        1704
    ],
    "pack 3051": [
        1705
    ],
    "rogers, christina j.": [
        1705
    ],
    "ocean city": [
        1705
    ],
    "rohm, william w.": [
        1706
    ],
    "rotz, ronald charles": [
        1707
    ],
    "rollins, louis b.": [
        1708
    ],
    "troop 192": [
        1709,
        2057
    ],
    "rowe, laverne b.": [
        1709
    ],
    "hilliard": [
        1709
    ],
    "rosbozom, john anthony": [
        1710
    ],
    "troop 672": [
        1711
    ],
    "rosen, donald": [
        1711
    ],
    "pasadena": [
        1711
    ],
    "rowles, reid a.": [
        1712
    ],
    "troop 1513": [
        1713
    ],
    "rosenberg, irvin i.": [
        1713
    ],
    "troop 687": [
        1714
    ],
    "rosenquist, john": [
        1714
    ],
    "pack 3858": [
        1715
    ],
    "ruble, calvin b.": [
        1715
    ],
    "balch springs": [
        1715
    ],
    "roseveare, melvin f.": [
        1716
    ],
    "ross, james g.": [
        1717
    ],
    "marietta": [
        1717,
        1791
    ],
    "troop 106": [
        1718,
        2522
    ],
    "ruch, kerry s.": [
        1718
    ],
    "rossnick, gerald h.": [
        1719
    ],
    "roswell, arvid": [
        1720
    ],
    "eau claire": [
        1720
    ],
    "rudolph, mark h.": [
        1721
    ],
    "westwood": [
        1721,
        2262
    ],
    "explorer 81": [
        1722
    ],
    "roth, ben j.": [
        1722
    ],
    "dubuque": [
        1722,
        1966,
        2632
    ],
    "ruggeri, anthony f.": [
        1723
    ],
    "seabrook": [
        1723
    ],
    "rothmeyer, jody a.": [
        1724
    ],
    "sasebo": [
        1724
    ],
    "pack 28": [
        1725,
        2610
    ],
    "sanchez, paul": [
        1725
    ],
    "santa fe": [
        1725
    ],
    "rumpff, terry": [
        1726,
        1729
    ],
    "snyder": [
        1726,
        1729
    ],
    "sandage, terry l.": [
        1727
    ],
    "pack 83": [
        1728,
        2543
    ],
    "sanders, troy l.": [
        1728
    ],
    "gary": [
        1728,
        2526
    ],
    "pack 224": [
        1730
    ],
    "sanders, mell j.": [
        1730
    ],
    "corona": [
        1730
    ],
    "pack 3354": [
        1731
    ],
    "sanford, john lloyd": [
        1731
    ],
    "santini, anthony michael": [
        1732
    ],
    "riverdale": [
        1732
    ],
    "troop 748": [
        1733
    ],
    "sartor, thomas h.": [
        1733
    ],
    "saunders, john g.": [
        1734
    ],
    "collingdale": [
        1734
    ],
    "explorer 2317": [
        1735
    ],
    "sahrmann, david louis": [
        1735
    ],
    "pack 3602": [
        1736
    ],
    "savard, james a.": [
        1736,
        1737
    ],
    "pack 34": [
        1738
    ],
    "sample, robert c.": [
        1738,
        1741
    ],
    "savastano, john": [
        1739
    ],
    "sawyer, jeffrey alan": [
        1740
    ],
    "troop 546": [
        1742
    ],
    "schaefer, orren glen": [
        1742,
        1743
    ],
    "hoquiam": [
        1743
    ],
    "pack 392": [
        1744
    ],
    "samples, doyle c.": [
        1744
    ],
    "quinlan": [
        1744
    ],
    "pack 8653": [
        1745
    ],
    "schaeffer, richard a.": [
        1745
    ],
    "venezuela": [
        1746
    ],
    "sams, jeffrey andrew": [
        1746
    ],
    "caracas": [
        1746
    ],
    "schauer, donald j.": [
        1747
    ],
    "petaluma": [
        1747
    ],
    "schultz, joshua j.": [
        1748
    ],
    "explorer 2875": [
        1749
    ],
    "schell, barton l.": [
        1749
    ],
    "whitehouse": [
        1749
    ],
    "schulz, kenneth e.": [
        1750
    ],
    "schilling, william s.": [
        1751
    ],
    "troop 235": [
        1752,
        2450
    ],
    "schliesser, george m.": [
        1752
    ],
    "vernal": [
        1752
    ],
    "schumann, robert james": [
        1753,
        1756
    ],
    "schmidt, james phillip": [
        1754,
        1755
    ],
    "caldwell": [
        1754
    ],
    "troop 200": [
        1755
    ],
    "wilder": [
        1755
    ],
    "troop 104": [
        1756,
        1992
    ],
    "pack 3673": [
        1757
    ],
    "schmidt, brian wayne": [
        1757
    ],
    "tecumseh": [
        1757
    ],
    "troop 370": [
        1758
    ],
    "schuyler, seth s.": [
        1758,
        1760
    ],
    "north miami beach": [
        1758,
        1760
    ],
    "schmutz, david j.": [
        1759
    ],
    "madeira": [
        1759
    ],
    "pack 3153": [
        1761
    ],
    "schoedinger, steven p.": [
        1761,
        1763
    ],
    "schwartz, louis": [
        1762
    ],
    "mount holly": [
        1762
    ],
    "pack 700": [
        1764
    ],
    "schoenfeld, irving": [
        1764,
        1765
    ],
    "miami beach": [
        1764,
        1765,
        2662
    ],
    "troop 873": [
        1766
    ],
    "scott, james larry": [
        1766
    ],
    "troop 634": [
        1767
    ],
    "scott, walter d.": [
        1767
    ],
    "el toro": [
        1767,
        2136
    ],
    "district 8610": [
        1768
    ],
    "schroeder, alan j.": [
        1768
    ],
    "frankfort": [
        1768
    ],
    "troop 535": [
        1769
    ],
    "schult, dennis walter": [
        1769
    ],
    "chatsworth": [
        1769
    ],
    "explorer 400": [
        1770
    ],
    "scott, ronald christopher": [
        1770
    ],
    "columbia": [
        1770,
        1871,
        2111
    ],
    "troop 264": [
        1771
    ],
    "sewell, jack e.": [
        1771
    ],
    "scott, randolph r.": [
        1772
    ],
    "explorer 2898": [
        1773
    ],
    "sexton, glen howard": [
        1773
    ],
    "scott, rex": [
        1774
    ],
    "lake elsinore": [
        1774
    ],
    "shadden, gary l.": [
        1775
    ],
    "crystal lake": [
        1775
    ],
    "shafer, ralph l.": [
        1776
    ],
    "kokomo": [
        1776,
        2257,
        2408
    ],
    "pack 252": [
        1777
    ],
    "shaffer, richard w.": [
        1777
    ],
    "indio": [
        1777
    ],
    "sells, wayne": [
        1778
    ],
    "woodruff": [
        1778
    ],
    "shanklin, earl j.": [
        1779
    ],
    "sharp, john l.": [
        1780
    ],
    "belle": [
        1780
    ],
    "pack 3013": [
        1781
    ],
    "serra, sergio": [
        1781
    ],
    "shaver, fremont": [
        1782
    ],
    "shaw, donald ray": [
        1783
    ],
    "sherman, robert john": [
        1784
    ],
    "framingham": [
        1784
    ],
    "sheely, alfred william": [
        1785
    ],
    "shedloskey, george": [
        1786,
        1787,
        1788
    ],
    "brooklyn ": [
        1788
    ],
    "shinn, lawrence e.": [
        1789
    ],
    "quincy": [
        1789,
        2629
    ],
    "pack 3244": [
        1790
    ],
    "sheedy, willie c.": [
        1790
    ],
    "uvalde": [
        1790
    ],
    "troop 713": [
        1791
    ],
    "shirley, robert l.": [
        1791
    ],
    "sheehan, william": [
        1792
    ],
    "troop 157": [
        1793,
        2488
    ],
    "shepard, larry william": [
        1793
    ],
    "short, mark thomas": [
        1794
    ],
    "silveria, jose m.": [
        1795
    ],
    "lawrence": [
        1795
    ],
    "simmons, david m.": [
        1796
    ],
    "pack 142": [
        1797
    ],
    "shrimplin, john e.": [
        1797
    ],
    "sublette": [
        1797
    ],
    "troop 1495": [
        1798
    ],
    "simon, steven v.": [
        1798
    ],
    "shumway, kenneth karl": [
        1799
    ],
    "cuyahoga falls": [
        1799,
        2194
    ],
    "sims, lonie": [
        1800
    ],
    "troop 173": [
        1801
    ],
    "shuty, jeffery": [
        1801
    ],
    "harbor creek": [
        1801
    ],
    "sinclair, duane j.": [
        1802
    ],
    "ketchikan": [
        1802
    ],
    "singley, bruce l.": [
        1803
    ],
    "tarboro": [
        1803
    ],
    "siefert, thomas g.": [
        1804
    ],
    "skeeler, frank w.": [
        1805
    ],
    "sieke, carl eric": [
        1806
    ],
    "harleysville": [
        1806
    ],
    "skelly, martin": [
        1807
    ],
    "pack 3695": [
        1808
    ],
    "skinner, larry d.": [
        1808
    ],
    "troop 143": [
        1809,
        2227,
        2503
    ],
    "sievers, h. neil": [
        1809
    ],
    "blair": [
        1809
    ],
    "troop 496": [
        1810
    ],
    "skol, albert h.": [
        1810,
        1812
    ],
    "sileo, john": [
        1811,
        1814
    ],
    "pack 496": [
        1812
    ],
    "1030": [
        1813
    ],
    "slattery, scott r.": [
        1813
    ],
    "pack 145": [
        1814
    ],
    "slemmer, robert henry": [
        1815
    ],
    "slocum, jesse james": [
        1816
    ],
    "branford": [
        1816,
        2198,
        2779
    ],
    "pack 3079": [
        1817
    ],
    "sill, lynn": [
        1817
    ],
    "cory": [
        1817
    ],
    "slusher, floyd david": [
        1818,
        1820
    ],
    "202": [
        1819
    ],
    "sills, milton harold": [
        1819
    ],
    "pooler": [
        1819
    ],
    "smeby, donald": [
        1821
    ],
    "crystal": [
        1821
    ],
    "smith, reginald": [
        1822
    ],
    "smedley, john joseph": [
        1823
    ],
    "aston": [
        1823
    ],
    "smith, michael diaz": [
        1824
    ],
    "smith, james warren": [
        1825
    ],
    "smith, john harold": [
        1826
    ],
    "pack 199": [
        1827
    ],
    "smith, michael finley": [
        1827,
        1828
    ],
    "lorimor": [
        1827
    ],
    "smith, brian lynn": [
        1829,
        1831
    ],
    "pack 3412": [
        1830
    ],
    "smith, david a.": [
        1830
    ],
    "hull": [
        1830
    ],
    "328": [
        1832
    ],
    "smith, james dale": [
        1832
    ],
    "frankston": [
        1832
    ],
    "smitty, floyd": [
        1833
    ],
    "middletown": [
        1833
    ],
    "troop 269": [
        1834,
        2200
    ],
    "smith, richard herbert": [
        1834
    ],
    "tillamook": [
        1834
    ],
    "smith, kenneth l.": [
        1835
    ],
    "birch run": [
        1835
    ],
    "snow, ronald e.": [
        1836
    ],
    "taylors": [
        1836
    ],
    "troop 199": [
        1837
    ],
    "smith, robert": [
        1837
    ],
    "redwood city": [
        1837
    ],
    "pack 3705": [
        1838
    ],
    "sollars, rickey lee": [
        1838
    ],
    "smith, paul gilbert": [
        1839
    ],
    "santa rosa": [
        1839,
        2516
    ],
    "smith, william o.": [
        1840
    ],
    "grandview": [
        1840
    ],
    "troop 588": [
        1841
    ],
    "somervell, gardiner stanton": [
        1841
    ],
    "key largo": [
        1841
    ],
    "smith, robert m.": [
        1842
    ],
    "shorewood": [
        1842
    ],
    "smith, dale r.": [
        1843
    ],
    "quonset point": [
        1843
    ],
    "sonju, william v.": [
        1844
    ],
    "sparks, guy k.": [
        1845,
        1847
    ],
    "sowder, gene judson": [
        1846
    ],
    "sparks, darrell c.": [
        1848
    ],
    "sparks, jerry l.": [
        1849
    ],
    "pack 3214": [
        1850
    ],
    "sowers, thomas": [
        1850
    ],
    "spauling, harry": [
        1851
    ],
    "spalding, carl p.": [
        1852,
        1854
    ],
    "vallejo": [
        1852,
        1854
    ],
    "spear, edward d.": [
        1853
    ],
    "goshen": [
        1853
    ],
    "explorer 205": [
        1854
    ],
    "speed, tommy": [
        1855,
        1856
    ],
    "explorer 2911": [
        1857
    ],
    "spangler, michael c.": [
        1857
    ],
    "rochester hills": [
        1857
    ],
    "troop 684": [
        1858
    ],
    "speer, bryan douglas": [
        1858
    ],
    "speice, howard w.": [
        1859
    ],
    "troop 1152": [
        1860
    ],
    "spencer, rex harrison": [
        1860,
        1862
    ],
    "anaheim": [
        1860,
        1862,
        1950
    ],
    "st. laurent, ronald a.": [
        1861,
        1863
    ],
    "spencer, sidney l.": [
        1864
    ],
    "district 5605": [
        1865
    ],
    "spreitler, michael r.": [
        1865
    ],
    "red bud": [
        1865
    ],
    "explorer 107": [
        1866
    ],
    "sprinkle, robert mason": [
        1866
    ],
    "sprott, rodney m.": [
        1867
    ],
    "sumter": [
        1867
    ],
    "stamps, thomas lynn": [
        1868
    ],
    "bryan ": [
        1868
    ],
    "troop 622": [
        1869
    ],
    "stark, rodney f.": [
        1869,
        1870
    ],
    "julian": [
        1870
    ],
    "staten, ralph randolph": [
        1871,
        1872
    ],
    "troop 822": [
        1873
    ],
    "steadman, warren e.": [
        1873,
        1874
    ],
    "south hero": [
        1873
    ],
    "shellbourne": [
        1874
    ],
    "explorer 2251": [
        1875
    ],
    "stearns, john michael": [
        1875,
        1876
    ],
    "district 8608": [
        1877
    ],
    "steele, james h.": [
        1877
    ],
    "steelmon, kenneth": [
        1878
    ],
    "steen, darryl f.": [
        1879
    ],
    "port henry": [
        1879
    ],
    "steffen, gary lee": [
        1880
    ],
    "stein, michael d.": [
        1881
    ],
    "explorer 2083": [
        1882
    ],
    "steinbach, robert wyne": [
        1882
    ],
    "explorer 142": [
        1883
    ],
    "stella, john": [
        1883,
        1884
    ],
    "northport": [
        1884,
        2380
    ],
    "sea scouts ": [
        1885
    ],
    "stenger, richard c.": [
        1885
    ],
    "troop 129": [
        1886
    ],
    "stettenbauer, jack henry": [
        1886
    ],
    "rohrstown": [
        1886
    ],
    "troop 613": [
        1887
    ],
    "stevens, bert h.": [
        1887
    ],
    "stevens, gary james": [
        1888
    ],
    "hemet": [
        1888
    ],
    "stewart, robert lee": [
        1889,
        1890
    ],
    "lincoln ": [
        1889
    ],
    "troop 1025": [
        1891
    ],
    "stewart, jack z.": [
        1891,
        1892
    ],
    "pack 8650": [
        1892
    ],
    "stidsen, larry d.": [
        1893
    ],
    "pack 234": [
        1894
    ],
    "stiefel, ronald k.": [
        1894
    ],
    "stillings, charles j.": [
        1895
    ],
    "kadena afb, okinawa": [
        1895,
        2508
    ],
    "troop 591": [
        1896,
        1912
    ],
    "stillman, michael": [
        1896
    ],
    "stillwell, robert david": [
        1897
    ],
    "south salt lake city": [
        1897
    ],
    "stockmeier, robert l.": [
        1898
    ],
    "stone, walter n.": [
        1899,
        1900
    ],
    "fitchburg": [
        1899
    ],
    "district 230": [
        1900
    ],
    "stonier, jess": [
        1901
    ],
    "kulpsville": [
        1901,
        2013
    ],
    "strain, larry w.": [
        1902
    ],
    "strain, gary l.": [
        1903
    ],
    "honolulu": [
        1903,
        2119,
        2245
    ],
    "straney, michael": [
        1904
    ],
    "spring": [
        1904,
        2095
    ],
    "strauch, otto": [
        1905
    ],
    "69": [
        1906
    ],
    "strausbach, william fred": [
        1906
    ],
    "xenia ": [
        1906
    ],
    "pack 267": [
        1907
    ],
    "strauss, ronald": [
        1907
    ],
    "north lauderdale": [
        1907
    ],
    "troop 1337": [
        1908
    ],
    "street, james d.": [
        1908
    ],
    "explorer 300": [
        1909
    ],
    "strickland, thomas jay": [
        1909
    ],
    "beatrice": [
        1909
    ],
    "strickland, bobby": [
        1910
    ],
    "munford": [
        1910
    ],
    "strobel, mark": [
        1911
    ],
    "strobel, ricky r.": [
        1912
    ],
    "phelps": [
        1912
    ],
    "pack 473": [
        1913
    ],
    "strong, randle g.": [
        1913,
        1914
    ],
    "stroup, gary a.": [
        1915
    ],
    "harrisburg": [
        1915,
        2650
    ],
    "pack 8608": [
        1916
    ],
    "stull, clifford allen": [
        1916
    ],
    "fremont": [
        1916,
        1992,
        2137
    ],
    "sufka, alfred j.": [
        1917
    ],
    "suggs, herman": [
        1918,
        1919
    ],
    "troop 572": [
        1919
    ],
    "sullivan, larry john": [
        1920
    ],
    "explorer 4": [
        1921
    ],
    "sullivan, herbert lewis": [
        1921
    ],
    "sullivan, michael edward": [
        1922
    ],
    "sumler, roger lee": [
        1923
    ],
    "summers, george": [
        1924
    ],
    "sundling, frederick l.": [
        1925
    ],
    "saline ": [
        1925
    ],
    "pack 192": [
        1926
    ],
    "swanson, mark a.": [
        1926
    ],
    "shelley": [
        1926
    ],
    "swarthout, arthur w.": [
        1927
    ],
    "swasey, james albert": [
        1928,
        1929
    ],
    "rio vista": [
        1928,
        1929
    ],
    "sea scouts 129": [
        1929
    ],
    "swearingen, clyde h.": [
        1930
    ],
    "troop 467": [
        1931
    ],
    "van swearingen, david": [
        1931,
        1932
    ],
    "alliance": [
        1931,
        1932
    ],
    "pack 394": [
        1933
    ],
    "sweeney, william j.": [
        1933
    ],
    "swentik, donald a.": [
        1934
    ],
    "ft. madison": [
        1934
    ],
    "3736": [
        1935
    ],
    "swift, robert e.": [
        1935
    ],
    "explorer 14": [
        1936
    ],
    "swindel, george w.": [
        1936,
        1937
    ],
    "tuscaloosa ": [
        1936
    ],
    "tuscaloosa": [
        1937
    ],
    "sykes, paul e.": [
        1938
    ],
    "north windham": [
        1938
    ],
    "explorer 2331": [
        1939
    ],
    "symonds, david michael": [
        1939
    ],
    "leominster": [
        1939
    ],
    "symons, william lee": [
        1940
    ],
    "new tripoli": [
        1940
    ],
    "troop 423": [
        1941
    ],
    "taff, james david": [
        1941
    ],
    "downey": [
        1941
    ],
    "troop 278": [
        1942,
        2433
    ],
    "talley, neal": [
        1942
    ],
    "tally, edgar s.": [
        1943
    ],
    "tampico, jonathan": [
        1944
    ],
    "tancabel, mark l.": [
        1945
    ],
    "chisholm": [
        1945
    ],
    "tardo, joseph p.": [
        1946
    ],
    "troop 122": [
        1947,
        2237
    ],
    "tate, mark christopher": [
        1947
    ],
    "tatu, robert j.": [
        1948
    ],
    "taus, richard": [
        1949
    ],
    "troop 581": [
        1950
    ],
    "taylor, robert": [
        1950
    ],
    "pack 148": [
        1951
    ],
    "taylor, christopher": [
        1951
    ],
    "dekalb": [
        1951
    ],
    "867": [
        1952
    ],
    "tebbs, albert h.": [
        1952
    ],
    "thorunbury": [
        1952
    ],
    "troop 518": [
        1953,
        2169,
        2371
    ],
    "teel, randall l.": [
        1953
    ],
    "wilton": [
        1953
    ],
    "teeling, robert h.": [
        1954
    ],
    "pack 279": [
        1955,
        1956
    ],
    "tercey, judith a.": [
        1955
    ],
    "tercey, franklin k.": [
        1956
    ],
    "terry, michael e.": [
        1957
    ],
    "theel, john richard": [
        1958
    ],
    "theis, farrell j.": [
        1959
    ],
    "westmont": [
        1959
    ],
    "thiel, joseph j.": [
        1960
    ],
    "east brunswick": [
        1960
    ],
    "pack 3037": [
        1961
    ],
    "thomas, james g.": [
        1961
    ],
    "san anselmo": [
        1961
    ],
    "thornton, peter b.": [
        1962
    ],
    "williamstown": [
        1962
    ],
    "troop 625": [
        1963,
        2769
    ],
    "thorpe, menica oliver": [
        1963
    ],
    "tibbetts, jack d.": [
        1964
    ],
    "pack 425": [
        1965
    ],
    "tiffany, harrison v.": [
        1965
    ],
    "edgewater": [
        1965
    ],
    "tillman, john e.": [
        1966
    ],
    "troop 167": [
        1967,
        2480
    ],
    "tinkham, ken": [
        1967
    ],
    "leavenworth": [
        1967
    ],
    "480": [
        1968
    ],
    "tisdale, edgar a.": [
        1968,
        1969
    ],
    "belfair": [
        1969
    ],
    "troop 1531": [
        1970
    ],
    "toland, ray b.": [
        1970
    ],
    "438": [
        1971
    ],
    "tolle, daniel": [
        1971
    ],
    "explorer 2044": [
        1972
    ],
    "toner, richard j.": [
        1972
    ],
    "newton square": [
        1972
    ],
    "explorer 961": [
        1973
    ],
    "townsend, robert clayton": [
        1973
    ],
    "lake city": [
        1973
    ],
    "tracy, gregory e.": [
        1974,
        1975,
        1976,
        1977
    ],
    "explorer 70": [
        1978
    ],
    "traver, benjamin": [
        1978
    ],
    "587": [
        1979
    ],
    "treder, john p.": [
        1979,
        1980
    ],
    "explorer 551": [
        1980
    ],
    "troop 586": [
        1981,
        2222
    ],
    "tripp, glendon e.": [
        1981
    ],
    "troop 631": [
        1982
    ],
    "tross, william haskins": [
        1982
    ],
    "troyer, duane dale": [
        1983
    ],
    "minier": [
        1983
    ],
    "truman, allon": [
        1984
    ],
    "venturing 244": [
        1985
    ],
    "tubbs, robert": [
        1985
    ],
    "slaterville": [
        1985
    ],
    "684": [
        1986
    ],
    "turk, michael a.": [
        1986
    ],
    "turley, richard j.": [
        1987,
        1988
    ],
    "fountain valley": [
        1988
    ],
    "turmenne, david j.": [
        1989
    ],
    "explorer 2811": [
        1990
    ],
    "turner, robert s.": [
        1990
    ],
    "walnut creek": [
        1990,
        2247
    ],
    "pack 3996": [
        1991
    ],
    "tusing, clifton russell": [
        1991
    ],
    "bushnell": [
        1991
    ],
    "tweedy, mark a.": [
        1992
    ],
    "tyler, william m.": [
        1993,
        1994
    ],
    "maui": [
        1994
    ],
    "tyree, william edward": [
        1995
    ],
    "pack 413": [
        1996
    ],
    "underwood, claude james": [
        1996
    ],
    "urig, charles j.": [
        1997,
        1998
    ],
    "van allen, john richard": [
        1999
    ],
    "van dyke, larry": [
        2000
    ],
    "gardena": [
        2000
    ],
    "vanfossen, ernest l.": [
        2001
    ],
    "east sparta": [
        2001
    ],
    "van luven, daniel w.": [
        2002
    ],
    "copenhagen": [
        2002
    ],
    "vannoy, roger d.": [
        2003,
        2004
    ],
    "troop 519": [
        2005
    ],
    "van tyle, thomas e.": [
        2005
    ],
    "vaughn, miles s.": [
        2006
    ],
    "vaughn, charles s.": [
        2007
    ],
    "vehaun, charles marion": [
        2008
    ],
    "vehoski, robert j.": [
        2009
    ],
    "vela, herlin e.": [
        2010
    ],
    "troop 367": [
        2011,
        2405
    ],
    "venett, ross a.": [
        2011
    ],
    "state college": [
        2011
    ],
    "troop 770": [
        2012
    ],
    "verheyen, philippe f.": [
        2012
    ],
    "verney, douglas geoffrey": [
        2013
    ],
    "troop 241": [
        2014,
        2443
    ],
    "vernon, rickey martin": [
        2014
    ],
    "troop 1622": [
        2015
    ],
    "vernon, russell h.": [
        2015
    ],
    "muncie": [
        2015,
        2078,
        2555
    ],
    "vetro, james": [
        2016
    ],
    "vick, earl w.": [
        2017
    ],
    "stachowski, michael jerome": [
        2018
    ],
    "vickers, william f.": [
        2019
    ],
    "vila, james edward": [
        2020
    ],
    "stafford, ernest allan": [
        2021
    ],
    "pompano beach": [
        2021
    ],
    "vincent, matthew d.c.": [
        2022
    ],
    "stahl, mark e.": [
        2023
    ],
    "jamestown": [
        2023
    ],
    "troop 536": [
        2024
    ],
    "vines, michael d.": [
        2024
    ],
    "voges, harold c.": [
        2025
    ],
    "pack 3057": [
        2026
    ],
    "volkmar, arnold a.": [
        2026
    ],
    "von aspern, dean ray": [
        2027
    ],
    "voll, gerhard u.": [
        2028
    ],
    "mounds view": [
        2028
    ],
    "pack 336": [
        2029
    ],
    "von hovatter, kurt e.": [
        2029
    ],
    "walker, john l.": [
        2030
    ],
    "vonneida, daryl e.": [
        2031
    ],
    "pack 419": [
        2032,
        2192
    ],
    "walker, jim r.": [
        2032
    ],
    "flagler beach": [
        2032
    ],
    "25": [
        2033
    ],
    "voorhees, david a.": [
        2033
    ],
    "walles, albert anthony": [
        2034
    ],
    "wadaga, paul j.": [
        2035
    ],
    "port huron": [
        2035,
        2648
    ],
    "walrath, rodney bruce": [
        2036,
        2037
    ],
    "watertown": [
        2036,
        2037
    ],
    "wade, jim": [
        2038
    ],
    "wagner, robert": [
        2039
    ],
    "pack 401": [
        2040
    ],
    "ward, vernon w.": [
        2040,
        2042
    ],
    "wakely, lawrence": [
        2041
    ],
    "spring city": [
        2041
    ],
    "fort worth": [
        2042
    ],
    "waldo, michael lynn": [
        2043
    ],
    "ward, steven j.": [
        2044
    ],
    "swansea": [
        2044
    ],
    "8603": [
        2045
    ],
    "walker, harry james": [
        2045
    ],
    "ward, timothy c.": [
        2046
    ],
    "troop 448": [
        2047
    ],
    "walker, exum d.": [
        2047
    ],
    "south daytona": [
        2047
    ],
    "district 35": [
        2048
    ],
    "ward, frank": [
        2048
    ],
    "coos bay": [
        2048
    ],
    "welander, james": [
        2049
    ],
    "wellhausen, william george": [
        2050
    ],
    "wargo, richard p.": [
        2051
    ],
    "troop 721": [
        2052
    ],
    "wells, fred": [
        2052
    ],
    "warner, tom": [
        2053
    ],
    "atwood": [
        2053
    ],
    "troop 889": [
        2054
    ],
    "welsh, taunton": [
        2054,
        2056
    ],
    "baltimore": [
        2054,
        2056,
        2158,
        2159,
        2389,
        2437,
        2500,
        2576
    ],
    "warner, philip l.": [
        2055
    ],
    "sea scouts 361": [
        2056
    ],
    "warren, thomas r.": [
        2057
    ],
    "west palm beach": [
        2057,
        2521
    ],
    "sea scouts 146": [
        2058
    ],
    "wentworth, ronald s.": [
        2058
    ],
    "warren, lowell dean": [
        2059
    ],
    "wentz, george whitfield": [
        2060
    ],
    "pack 3150": [
        2061
    ],
    "west, david raymond": [
        2061
    ],
    "broken arrow": [
        2061
    ],
    "troop 151": [
        2062
    ],
    "wheeler, daniel": [
        2062,
        2067
    ],
    "west, john henry": [
        2063
    ],
    "bellflower": [
        2063,
        2065
    ],
    "whipple, david": [
        2064
    ],
    "west bend": [
        2064
    ],
    "west, annabel": [
        2065
    ],
    "whitacre, alfred carl": [
        2066
    ],
    "white, douglas louis": [
        2068
    ],
    "angola ": [
        2068
    ],
    "white, robert e.": [
        2069
    ],
    "whitmer, henry j.": [
        2070,
        2072
    ],
    "centreville": [
        2070,
        2072
    ],
    "pack 208": [
        2071
    ],
    "white, russell r.": [
        2071
    ],
    "norwalk ": [
        2071
    ],
    "explorer 117": [
        2072
    ],
    "troop 162": [
        2073
    ],
    "white, charles e.": [
        2073
    ],
    "keowee": [
        2073
    ],
    "pack 157": [
        2074
    ],
    "whiton, curtis william": [
        2074,
        2076
    ],
    "elyria": [
        2074,
        2076,
        2276
    ],
    "white, david d.": [
        2075
    ],
    "baton rouge": [
        2075
    ],
    "troop 109": [
        2076
    ],
    "white, neil eldred": [
        2077
    ],
    "whittenburg, john casher": [
        2078
    ],
    "pack 129": [
        2079
    ],
    "whittington, douglas d.": [
        2079
    ],
    "troop 510": [
        2080,
        2780
    ],
    "white, donald lee": [
        2080,
        2082
    ],
    "fairview heights": [
        2080
    ],
    "wiggins, tommie d.": [
        2081
    ],
    "sault saint marie": [
        2081
    ],
    "pack 5606": [
        2082
    ],
    "camp 471": [
        2083
    ],
    "wilkins, ronald": [
        2083
    ],
    "troop 643": [
        2084,
        2160
    ],
    "white, bradford a.": [
        2084
    ],
    "waupaca": [
        2084
    ],
    "troop 291": [
        2085
    ],
    "willey, carleton benjamin": [
        2085
    ],
    "whitenight, ronnie d.": [
        2086
    ],
    "williams, keith g.": [
        2087
    ],
    "clearfield": [
        2087
    ],
    "williams, richard hugh": [
        2088
    ],
    "ukiah": [
        2088
    ],
    "williams, gregory charles": [
        2089,
        2091
    ],
    "troop 904": [
        2090
    ],
    "whitlock, robert n.": [
        2090
    ],
    "wayne": [
        2090
    ],
    "district 8694": [
        2092
    ],
    "williams, terry l.": [
        2092
    ],
    "williams, gary lee": [
        2093
    ],
    "troop 850": [
        2094
    ],
    "williams, arthur cornelius": [
        2094
    ],
    "williams, don": [
        2095
    ],
    "williams, dane allen": [
        2096
    ],
    "explorer 715": [
        2097
    ],
    "williams, russ": [
        2097
    ],
    "troop 53": [
        2098,
        2582
    ],
    "wilson, willie lewis": [
        2098
    ],
    "valley": [
        2098
    ],
    "williams, james h.": [
        2099
    ],
    "wilson, gary dean": [
        2100
    ],
    "sommersville": [
        2100
    ],
    "pack 375": [
        2101
    ],
    "wilson, billy wayne": [
        2101
    ],
    "wirtz": [
        2101
    ],
    "pack 3001": [
        2102
    ],
    "williamson, kenneth e.": [
        2102,
        2105
    ],
    "wingard, stephen edward": [
        2103,
        2104
    ],
    "limestone": [
        2103,
        2104
    ],
    "pack 49": [
        2104
    ],
    "winklepleck, robert harold": [
        2106
    ],
    "winner, scott m.": [
        2107
    ],
    "north syracuse": [
        2107
    ],
    "willie, fred l.": [
        2108
    ],
    "winters, eugene j.": [
        2109
    ],
    "cranford": [
        2109
    ],
    "winters, william h.": [
        2110
    ],
    "troop 601": [
        2111
    ],
    "willock, lloyd l.": [
        2111
    ],
    "wojtan, thomas j.": [
        2112
    ],
    "hobart": [
        2112
    ],
    "wilson, roy s.": [
        2113
    ],
    "corvallis": [
        2113
    ],
    "pack 77": [
        2114
    ],
    "wolfersteig, h. richard": [
        2114
    ],
    "santa maria": [
        2114
    ],
    "wilson, david p.": [
        2115
    ],
    "ashford": [
        2115
    ],
    "wolfe, edwin d.": [
        2116
    ],
    "wilson, royce l.": [
        2117
    ],
    "wong, robert": [
        2118
    ],
    "wilson, dale edmund": [
        2119
    ],
    "wood, thomas r.": [
        2120
    ],
    "wood, richard edward": [
        2121
    ],
    "van nuys": [
        2121,
        2523
    ],
    "wood, robert f.": [
        2122
    ],
    "youngblood, russell d.": [
        2123
    ],
    "blue ridge": [
        2123
    ],
    "woodall, raymond": [
        2124
    ],
    "zak, john": [
        2125,
        2127
    ],
    "woodford, michael grant": [
        2126
    ],
    "zeart, dewey j.": [
        2128
    ],
    "worcester, jerry l.": [
        2129
    ],
    "gillette": [
        2129
    ],
    "zegel, john c.": [
        2130
    ],
    "wray, ronald russell": [
        2131
    ],
    "zemanick, carl": [
        2132
    ],
    "spencer": [
        2132
    ],
    "pack 146": [
        2133,
        2500
    ],
    "zenzen, arthur e.": [
        2133
    ],
    "avery": [
        2133
    ],
    "troop 768": [
        2134
    ],
    "wright, donald l.": [
        2134,
        2136
    ],
    "explorer 1615": [
        2135
    ],
    "ziegler, herbert a.": [
        2135
    ],
    "troop 628": [
        2136
    ],
    "pack 302": [
        2137
    ],
    "zimmerman, mark s.": [
        2137
    ],
    "wright, clifford w.": [
        2138
    ],
    "pack 92": [
        2139
    ],
    "zimmerman, william d.": [
        2139
    ],
    "new castle": [
        2139
    ],
    "venturing 6129": [
        2140
    ],
    "wright, harvey dee": [
        2140
    ],
    "zottoli, joseph": [
        2141
    ],
    "troop 71": [
        2142,
        2231,
        2558
    ],
    "yates, john a.": [
        2142
    ],
    "pack 210": [
        2143
    ],
    "yoder, thomas m.": [
        2143
    ],
    "burton": [
        2143
    ],
    "young, john philip": [
        2144
    ],
    "del rio": [
        2144
    ],
    "young, gene r.": [
        2145
    ],
    "troop 156": [
        2146
    ],
    "hodges, thomas daniel": [
        2146,
        2148
    ],
    "winder": [
        2146,
        2148
    ],
    "young, jimmy d.": [
        2147
    ],
    "troop 3156": [
        2148
    ],
    "pack 271": [
        2149
    ],
    "young, anthony l.": [
        2149
    ],
    "leesburg": [
        2149
    ],
    "troop 820": [
        2150
    ],
    "sheffel, joel h.": [
        2150,
        2151
    ],
    "troop 3094": [
        2151
    ],
    "pack 3550": [
        2152
    ],
    "focaccio, frank robert": [
        2152,
        2153
    ],
    "pack 2146": [
        2153
    ],
    "leard, raymond d.": [
        2154,
        2156
    ],
    "port angeles": [
        2154,
        2156
    ],
    "troop 666": [
        2155
    ],
    "hamblin, bruce w.": [
        2155,
        2157
    ],
    "troop 1185": [
        2157
    ],
    "lawrence, t. franklin": [
        2158,
        2159
    ],
    "troop 989": [
        2159
    ],
    "walker sr., chalma lee": [
        2160
    ],
    "white, theodore w.": [
        2161,
        2162
    ],
    "leonardtown": [
        2161,
        2162,
        2330
    ],
    "troop 913": [
        2162,
        2330
    ],
    "explorer 604": [
        2163
    ],
    "albrecht, gene": [
        2163
    ],
    "mccalment, charles s.": [
        2164,
        2165
    ],
    "troop 859": [
        2165
    ],
    "culbertson, raymond w.": [
        2166,
        2167
    ],
    "rimersberg": [
        2166
    ],
    "garris, charles wayne": [
        2168,
        2169
    ],
    "troop 402": [
        2170,
        2376,
        2385,
        2779
    ],
    "reynolds, charles e.": [
        2170,
        2193
    ],
    "beck, bruce william": [
        2171
    ],
    "thurman, ray e.": [
        2172,
        2174
    ],
    "tyler": [
        2172
    ],
    "explorer 125": [
        2173
    ],
    "davis, d. leroy": [
        2173
    ],
    "pack 385": [
        2174
    ],
    "mineola": [
        2174,
        2390
    ],
    "thompson, charles philip": [
        2175,
        2176
    ],
    "silver spring": [
        2175,
        2176
    ],
    "linder, alva d.": [
        2177,
        2179
    ],
    "plattsmouth": [
        2177,
        2179
    ],
    "troop 495": [
        2178,
        2360
    ],
    "schlieper, ronald d.": [
        2178,
        2180
    ],
    "concord": [
        2178,
        2180
    ],
    "koncz, tibor j.": [
        2181,
        2183
    ],
    "woodbridge": [
        2181
    ],
    "troop 224": [
        2182
    ],
    "wood, larry w.": [
        2182,
        2184
    ],
    "wiesbaden": [
        2182,
        2184
    ],
    "explorer 356": [
        2183
    ],
    "perth amboy": [
        2183
    ],
    "troop 459": [
        2184
    ],
    "troop 257": [
        2185,
        2797
    ],
    "martin, richard earl": [
        2185,
        2187
    ],
    "grand island": [
        2185
    ],
    "brinkman, charles": [
        2186,
        2188
    ],
    "troop 335": [
        2187,
        2413
    ],
    "troop 426": [
        2188
    ],
    "troop 644": [
        2189
    ],
    "smith, bruce c.": [
        2189,
        2191
    ],
    "alta loma": [
        2189
    ],
    "fried, rienhold john": [
        2190,
        2192
    ],
    "minot": [
        2190,
        2192,
        2383
    ],
    "troop 325": [
        2191
    ],
    "ontario": [
        2191
    ],
    "troop 401": [
        2193,
        2377
    ],
    "explorer 2184": [
        2194
    ],
    "daves, donald bert": [
        2194,
        2195
    ],
    "pack 317": [
        2195
    ],
    "choate, craig dexter": [
        2196,
        2201
    ],
    "gebes, ralph edwin": [
        2197,
        2199
    ],
    "edwards, robert p.": [
        2198,
        2200
    ],
    "explorer 312": [
        2199
    ],
    "troop 281": [
        2202
    ],
    "wertman, donald": [
        2202,
        2204
    ],
    "norristown": [
        2202,
        2204
    ],
    "schneider, leland ward": [
        2203,
        2218
    ],
    "troop 483": [
        2205
    ],
    "smith, edward troy": [
        2205,
        2206
    ],
    "fitch, robert d.": [
        2207,
        2209
    ],
    "ballard, joseph c.": [
        2208,
        2210
    ],
    "explorer 264": [
        2209
    ],
    "norwood ": [
        2210
    ],
    "webb, eric bruce": [
        2211,
        2212
    ],
    "rowden, gary gene": [
        2213,
        2214
    ],
    "jarvill, larry": [
        2215,
        2217
    ],
    "breyette, edwin e.": [
        2216
    ],
    "troop 207": [
        2217
    ],
    "coralville": [
        2217
    ],
    "troop 219": [
        2218
    ],
    "lydon, robert": [
        2219,
        2220
    ],
    "boston": [
        2219,
        2220,
        2572
    ],
    "pack 187": [
        2220
    ],
    "hillard, robert l.": [
        2221,
        2223
    ],
    "newton": [
        2221,
        2223
    ],
    "smith, carl d.": [
        2222,
        2224
    ],
    "troop 389": [
        2225
    ],
    "pederson, hans": [
        2225,
        2227
    ],
    "carson, robert j.": [
        2226,
        2228
    ],
    "troop 166": [
        2228
    ],
    "diva, cal": [
        2229,
        2230
    ],
    "bradford, thomas wayne": [
        2231,
        2233
    ],
    "islip": [
        2231
    ],
    "troop 134": [
        2232,
        2499
    ],
    "nathan, paul marshall": [
        2232
    ],
    "natick": [
        2232
    ],
    "okinawa": [
        2233
    ],
    "miller, howard frank": [
        2234,
        2236
    ],
    "troop 68": [
        2235
    ],
    "putnam, thomas e.": [
        2235,
        2237
    ],
    "schwaback": [
        2235
    ],
    "troop 131": [
        2236
    ],
    "zirndorft": [
        2237
    ],
    "myers, elmer earl": [
        2238,
        2239
    ],
    "fox, alan l.": [
        2240
    ],
    "galesburg": [
        2240
    ],
    "explorer 95": [
        2241
    ],
    "fenton, david c.": [
        2241,
        2251
    ],
    "zahn, frank roy": [
        2242,
        2243
    ],
    "estes, alonzo e.": [
        2244,
        2246
    ],
    "green, william l.": [
        2245,
        2247
    ],
    "haynesworth, josiah edgar": [
        2248,
        2249
    ],
    "lynberg": [
        2248
    ],
    "stuarts draft": [
        2249
    ],
    "griffin, raymond g.": [
        2250
    ],
    "tipton": [
        2251
    ],
    "mosher, david eugene": [
        2252,
        2253
    ],
    "layfayette": [
        2253
    ],
    "harris, bobby l.": [
        2254,
        2258
    ],
    "nelsonville": [
        2254,
        2258
    ],
    "baton, jay a.": [
        2255,
        2257
    ],
    "gaither, robert carroll": [
        2256
    ],
    "chrisman, thomas lorenz": [
        2259,
        2260,
        2261,
        2262
    ],
    "beverly hills": [
        2261
    ],
    "zabek, robert": [
        2263
    ],
    "beargeon, andrew f.": [
        2264,
        2266
    ],
    "central falls": [
        2264
    ],
    "curtis, robert": [
        2265,
        2267
    ],
    "darien": [
        2265,
        2267
    ],
    "pawtucket": [
        2266
    ],
    "pack 52": [
        2267
    ],
    "clark, kenneth leon": [
        2268,
        2269
    ],
    "frankfurt": [
        2268,
        2269
    ],
    "explorer 51": [
        2269
    ],
    "barrett, gordon r.": [
        2270,
        2271
    ],
    "lock haven": [
        2271
    ],
    "burch, barney": [
        2272,
        2274
    ],
    "longville": [
        2272
    ],
    "follett, david": [
        2273,
        2275
    ],
    "new london": [
        2273,
        2275
    ],
    "explorer 44": [
        2274
    ],
    "moscoutah": [
        2274
    ],
    "explorer 136": [
        2276
    ],
    "glick, joseph d.": [
        2276,
        2277
    ],
    "west, james francis": [
        2278,
        2279
    ],
    "pound, harold": [
        2280,
        2281
    ],
    "cascade": [
        2280,
        2281
    ],
    "rhodes, joel danzler": [
        2282,
        2283
    ],
    "centerville": [
        2283
    ],
    "forsterling, benjamin c.": [
        2284,
        2286
    ],
    "dyson, david s.": [
        2285,
        2287
    ],
    "mt. laurel": [
        2285
    ],
    "rancocas woods": [
        2287
    ],
    "chavez, joaquin": [
        2288,
        2290
    ],
    "jackson, donald lee": [
        2289,
        2291
    ],
    "pack 15": [
        2290
    ],
    "veline, dale d.": [
        2292,
        2293
    ],
    "anderson, juan": [
        2294
    ],
    "pack 8": [
        2295,
        2665
    ],
    "bailey, ralph": [
        2295,
        2296
    ],
    "alton": [
        2295
    ],
    "benld": [
        2296
    ],
    "troop 3412": [
        2297
    ],
    "denton, john k.": [
        2297
    ],
    "coshocton": [
        2297
    ],
    "wall, charles vernon": [
        2298,
        2300
    ],
    "hyde park": [
        2298
    ],
    "troop 3135": [
        2299
    ],
    "carpenter, julian delroy": [
        2299
    ],
    "upper darby": [
        2299
    ],
    "clearmont": [
        2300
    ],
    "troop 3121": [
        2301
    ],
    "whitchurch, melvin james": [
        2301
    ],
    "comstock, john": [
        2302,
        2303
    ],
    "st. albans": [
        2302,
        2303
    ],
    "troop g-1": [
        2304
    ],
    "olson, edward b.": [
        2304
    ],
    "stamford": [
        2304
    ],
    "explorer 2337": [
        2305
    ],
    "musser, john william": [
        2305
    ],
    "pack 2227": [
        2306
    ],
    "mougakos, milton peter": [
        2306
    ],
    "pack 2211": [
        2307
    ],
    "tietz, fredric a.": [
        2307
    ],
    "bethesda": [
        2307
    ],
    "explorer 2173": [
        2308
    ],
    "wilber, robert w.": [
        2308
    ],
    "pack 2088": [
        2309
    ],
    "orr, lindley": [
        2309
    ],
    "sea scouts 5052": [
        2310
    ],
    "l'heureux, cleophus b.": [
        2310
    ],
    "pack 1781": [
        2311
    ],
    "ciotti, henry italo": [
        2311
    ],
    "southgate": [
        2311
    ],
    "troop 1611": [
        2312
    ],
    "feindt, c. fred": [
        2312
    ],
    "pack 909": [
        2313
    ],
    "pyhala, verner": [
        2313
    ],
    "troop 1555": [
        2314
    ],
    "baker, gilbert": [
        2314
    ],
    "troop 1273": [
        2315
    ],
    "simons, harvey w.": [
        2315
    ],
    "troop 897": [
        2316
    ],
    "adams, william dale": [
        2316
    ],
    "troop 892": [
        2317
    ],
    "stephenson, steve": [
        2317
    ],
    "troop 877": [
        2318
    ],
    "barkley, owen herbert": [
        2318
    ],
    "troop 1111": [
        2319
    ],
    "weeks, j. w.": [
        2319
    ],
    "dearborn": [
        2319
    ],
    "troop 874": [
        2320
    ],
    "silva, julio h.": [
        2320
    ],
    "troop 801": [
        2321
    ],
    "barrett, robert loius": [
        2321
    ],
    "coronado": [
        2321
    ],
    "troop 1094": [
        2322
    ],
    "skrutskie, john j.": [
        2322
    ],
    "darrenstown": [
        2322
    ],
    "troop 783": [
        2323
    ],
    "gupton, howard": [
        2323
    ],
    "ballwin": [
        2323
    ],
    "troop 1083": [
        2324
    ],
    "sawyer, david a.": [
        2324
    ],
    "berkley": [
        2324
    ],
    "troop 1034": [
        2325
    ],
    "eubank, james harrell": [
        2325
    ],
    "bloomfield hills": [
        2325
    ],
    "sea scouts 776": [
        2326
    ],
    "peterson, victor james": [
        2326
    ],
    "encinitas": [
        2326
    ],
    "troop 925": [
        2327
    ],
    "adams, oatley f.": [
        2327
    ],
    "troop 774": [
        2328
    ],
    "bruskin, robert h.": [
        2328
    ],
    "ft. bragg": [
        2328,
        2334
    ],
    "troop 765": [
        2329
    ],
    "noonan, john e.": [
        2329
    ],
    "knowlton, james": [
        2330
    ],
    "troop 739": [
        2331
    ],
    "rodriguez, edward a.": [
        2331
    ],
    "pack 719": [
        2332
    ],
    "simmons, john wesley": [
        2332
    ],
    "kent village": [
        2332
    ],
    "troop 708": [
        2333
    ],
    "fricke, donald leroy": [
        2333
    ],
    "milford": [
        2333
    ],
    "troop 704": [
        2334
    ],
    "steinwinder, harry l.": [
        2334
    ],
    "troop 633": [
        2335
    ],
    "houck, joel elisla": [
        2335
    ],
    "compton": [
        2335
    ],
    "sea scouts 684": [
        2336
    ],
    "gagnier, brian m.": [
        2336
    ],
    "santinelli, arthur": [
        2337
    ],
    "troop 676": [
        2338
    ],
    "cook, john charles": [
        2338
    ],
    "amend": [
        2338
    ],
    "ungles, warren k.": [
        2339
    ],
    "santa susana": [
        2339
    ],
    "poorman, john paul": [
        2340
    ],
    "troop 605": [
        2341,
        2343
    ],
    "ashworth, gerald": [
        2341
    ],
    "troop 662": [
        2342
    ],
    "henn, david w.": [
        2342
    ],
    "morris, ernest": [
        2343
    ],
    "o'bryon, jon clifford": [
        2344
    ],
    "troop 596": [
        2345
    ],
    "boewe, robert a.": [
        2345
    ],
    "troop 649": [
        2346
    ],
    "adams, joel ray": [
        2346
    ],
    "troop 585": [
        2347
    ],
    "bains, edward": [
        2347
    ],
    "winch, daniel h.": [
        2348
    ],
    "ware": [
        2348
    ],
    "troop 636": [
        2349
    ],
    "schafer, john c.": [
        2349
    ],
    "troop 575": [
        2350
    ],
    "hardiman, martin c.": [
        2350
    ],
    "troop 571": [
        2351
    ],
    "aycoth, richard j.": [
        2351
    ],
    "troop 512": [
        2352
    ],
    "menius, e. flynn": [
        2352
    ],
    "hartsville": [
        2352,
        2371
    ],
    "iafelice, frank": [
        2353
    ],
    "schwieg, daniel j.": [
        2354
    ],
    "explorer 550": [
        2355
    ],
    "guillet, john shelton": [
        2355
    ],
    "watson, thomas burton": [
        2356
    ],
    "los gatos": [
        2356
    ],
    "troop 543": [
        2357
    ],
    "lissanski, james": [
        2357
    ],
    "papermill": [
        2357
    ],
    "huntsinger, richard paul": [
        2358
    ],
    "saratoga": [
        2358
    ],
    "troop 542": [
        2359
    ],
    "wellborn, james a.": [
        2359
    ],
    "gibson, w. gideon": [
        2360
    ],
    "armona": [
        2360
    ],
    "sea scouts 541": [
        2361
    ],
    "couvillion, william robert": [
        2361
    ],
    "green bay": [
        2361,
        2499,
        2605
    ],
    "knoer, robert harold": [
        2362
    ],
    "troop 488": [
        2363
    ],
    "carr, richard b.": [
        2363
    ],
    "oreland": [
        2363
    ],
    "pack 531": [
        2364
    ],
    "van orden, john": [
        2364
    ],
    "troop 473": [
        2365
    ],
    "caron, gerard n.": [
        2365
    ],
    "explorer 522": [
        2366
    ],
    "frederick, lee": [
        2366
    ],
    "coffee, tommie e.": [
        2367
    ],
    "duncan, david paul": [
        2368
    ],
    "cortez": [
        2368
    ],
    "giacalone, michael": [
        2369
    ],
    "murphy, austin d.": [
        2370
    ],
    "wynnewood": [
        2370
    ],
    "garland, john duckworth": [
        2371
    ],
    "explorer 516": [
        2372
    ],
    "stewart, eddie joe": [
        2372
    ],
    "ft. walton beach": [
        2372
    ],
    "troop 443": [
        2373
    ],
    "wells, james": [
        2373
    ],
    "parrott, rowland m.": [
        2374
    ],
    "highland springs": [
        2374
    ],
    "livingston, ruben": [
        2375
    ],
    "fidler, raymond f.": [
        2376
    ],
    "edison": [
        2376,
        2406
    ],
    "waller, donald kay": [
        2377
    ],
    "hawley": [
        2377
    ],
    "troop 398": [
        2378
    ],
    "miner, gordon p.": [
        2378
    ],
    "jackson": [
        2378
    ],
    "steere, thomas h.": [
        2379
    ],
    "troop 413": [
        2380
    ],
    "van baalen, henry": [
        2380
    ],
    "rogers, paul denton": [
        2381
    ],
    "avila beach": [
        2381
    ],
    "monahan, john joseph": [
        2382
    ],
    "troop 407": [
        2383
    ],
    "knippel, lawrence arthur": [
        2383
    ],
    "troop 405": [
        2384
    ],
    "hauswirth, norman e.": [
        2384
    ],
    "burnett, eugene fred": [
        2385
    ],
    "pack 398": [
        2386
    ],
    "smith, ted allen": [
        2386
    ],
    "eldora": [
        2386
    ],
    "troop 363": [
        2387
    ],
    "davis, carl w.": [
        2387
    ],
    "shaw afb": [
        2387
    ],
    "troop 392": [
        2388
    ],
    "brady, john l.": [
        2388
    ],
    "vandenberg afb": [
        2388
    ],
    "shusta, leo j.": [
        2389
    ],
    "explorer 389": [
        2390
    ],
    "butler, cary": [
        2390
    ],
    "kalasky, james e.": [
        2391
    ],
    "beakes, john w.": [
        2392
    ],
    "pack 352": [
        2393
    ],
    "haynes , alexander g.": [
        2393
    ],
    "explorer 377": [
        2394
    ],
    "o'donnell, cornelius j.": [
        2394
    ],
    "troop 351": [
        2395
    ],
    "o'rourke, william": [
        2395
    ],
    "pack 376": [
        2396
    ],
    "phillips, roy e": [
        2396
    ],
    "beckman, carl henry": [
        2397
    ],
    "dessert, william p.": [
        2398
    ],
    "el cajon": [
        2398
    ],
    "philpott, james": [
        2399
    ],
    "pack 373": [
        2400
    ],
    "jones, george": [
        2400
    ],
    "explorer 347": [
        2401
    ],
    "barton, lynwood s.": [
        2401
    ],
    "newport news": [
        2401
    ],
    "newbery, william h.": [
        2402
    ],
    "sierra madre": [
        2402
    ],
    "troop 346": [
        2403
    ],
    "gallagher, michael p.": [
        2403
    ],
    "drake, donald clarence": [
        2404
    ],
    "culpepper, linwood m.": [
        2405
    ],
    "troop 344": [
        2406
    ],
    "bakelaar, john philip": [
        2406
    ],
    "mcgowan, lawrence": [
        2407
    ],
    "hunsberger, craig c.": [
        2408
    ],
    "densker, donald": [
        2409
    ],
    "smith, william d.": [
        2410
    ],
    "melbourne": [
        2410
    ],
    "explorer 340": [
        2411
    ],
    "hicks, kenneth lamar": [
        2411
    ],
    "pleasant garden": [
        2411
    ],
    "patton, william john": [
        2412
    ],
    "blankenship, wyman c.": [
        2413
    ],
    "palmdale": [
        2413
    ],
    "votava, thomas": [
        2414
    ],
    "cicero": [
        2414
    ],
    "goatley, william h.": [
        2415
    ],
    "arkansas city": [
        2415
    ],
    "gaston, william m.": [
        2416
    ],
    "clinton-sherman afb": [
        2416,
        2418
    ],
    "martin, c. paul": [
        2417
    ],
    "stuttgart": [
        2417
    ],
    "dobson, david neal": [
        2418
    ],
    "enoch, wesley g.": [
        2419
    ],
    "ewalt, allen clarence": [
        2420
    ],
    "green mountain": [
        2420
    ],
    "wysong, clifford gerald": [
        2421
    ],
    "troop 321": [
        2422
    ],
    "clark, frederick eugene": [
        2422
    ],
    "band hersfeld": [
        2422
    ],
    "connell, donald": [
        2423
    ],
    "troop 297": [
        2424
    ],
    "kelly, curtis a.": [
        2424
    ],
    "taylor, wylie jonathan": [
        2425
    ],
    "tully, jerry lee": [
        2426
    ],
    "troop 318": [
        2427
    ],
    "ricker, william g.": [
        2427
    ],
    "claremont": [
        2427
    ],
    "troop 313": [
        2428,
        2430
    ],
    "cromer, leonard a.": [
        2428
    ],
    "troop 279": [
        2429
    ],
    "burnett, robert benjamin": [
        2429,
        2431
    ],
    "white, emmett": [
        2430
    ],
    "rockledge": [
        2430
    ],
    "troop 277": [
        2432,
        2434
    ],
    "helmlinger, lawrence c.": [
        2432
    ],
    "mcclure, john allen": [
        2433
    ],
    "burger, freddy": [
        2434
    ],
    "thorpe, william james": [
        2435
    ],
    "upland": [
        2435
    ],
    "jackson, james juan": [
        2436
    ],
    "dibartolomeo, joseph a.": [
        2437
    ],
    "troop 246": [
        2438
    ],
    "tanzer, francis j.": [
        2438
    ],
    "troop 272": [
        2439
    ],
    "deputy, edward e.": [
        2439
    ],
    "reading": [
        2439,
        2618
    ],
    "troop 271": [
        2440
    ],
    "blakeslee, james l.": [
        2440
    ],
    "troop 245": [
        2441
    ],
    "ciminskie, theodore j.": [
        2441
    ],
    "jones, james a.": [
        2442
    ],
    "stout, james e.": [
        2443
    ],
    "ypsilanti": [
        2443
    ],
    "donovan, francis b.": [
        2444
    ],
    "shamokin": [
        2444
    ],
    "farrell, patrick c.": [
        2445
    ],
    "pack 251": [
        2446
    ],
    "newville, charles": [
        2446
    ],
    "pack 249": [
        2447,
        2793
    ],
    "clark, charles r.": [
        2447
    ],
    "gruber, richard j.": [
        2448
    ],
    "ewell, william burton": [
        2449
    ],
    "kennedy, david l.": [
        2450
    ],
    "brock, clyde a": [
        2451
    ],
    "troop 218": [
        2452
    ],
    "layman, terrence daniel": [
        2452
    ],
    "west los angeles": [
        2452
    ],
    "swendiman, richard fred": [
        2453
    ],
    "maclardie, john alfred": [
        2454
    ],
    "fleenor, ernest howard": [
        2455
    ],
    "briston": [
        2455
    ],
    "pack 233": [
        2456
    ],
    "cowling, billie richard": [
        2456
    ],
    "rio linda": [
        2456
    ],
    "luckie, richard m.": [
        2457
    ],
    "explorer 214": [
        2458
    ],
    "morocco": [
        2458
    ],
    "coppedge, errol l.": [
        2458
    ],
    "port lyautey": [
        2458
    ],
    "levang, paul e.": [
        2459
    ],
    "vadnais heights": [
        2459
    ],
    "schneyer, ronald w.": [
        2460
    ],
    "harding, robert l.": [
        2461
    ],
    "cormana, william r.": [
        2462
    ],
    "parrish, monroe": [
        2463
    ],
    "andrews, zachary d.": [
        2464
    ],
    "goff, bill": [
        2465
    ],
    "kelly, robert j.": [
        2466
    ],
    "haskamp, dennis j.": [
        2467
    ],
    "cheviot": [
        2467
    ],
    "wilbur, frank": [
        2468
    ],
    "gagnon, patrick": [
        2469
    ],
    "fairfield": [
        2469
    ],
    "dohm, charles bert": [
        2470
    ],
    "coleman, ralph l.": [
        2471
    ],
    "urbana": [
        2471
    ],
    "pack 190": [
        2472
    ],
    "pepper, floyd milton": [
        2472
    ],
    "graves, gene vincent": [
        2473
    ],
    "mars hill": [
        2473
    ],
    "explorer 188": [
        2474
    ],
    "sullivan, don m.": [
        2474
    ],
    "bogalusa": [
        2474
    ],
    "fetzer, alvie hobert": [
        2475
    ],
    "van kleeck, glen a": [
        2476
    ],
    "juno beach": [
        2476
    ],
    "riner, richard l.": [
        2477
    ],
    "norwood": [
        2477
    ],
    "holtzman, harry patrick": [
        2478
    ],
    "johnstown": [
        2478,
        2481
    ],
    "bush, james": [
        2479
    ],
    "ulvilden, john rainhardt": [
        2480
    ],
    "updyke, robert b.": [
        2481
    ],
    "pack 167": [
        2482
    ],
    "sargent, thomas george": [
        2482
    ],
    "smith, howell a.": [
        2483
    ],
    "mossburg, philip l.": [
        2484
    ],
    "sykes, robert james": [
        2485,
        2486
    ],
    "kincheloe afb": [
        2485
    ],
    "burnsville": [
        2486
    ],
    "christy, vaughn mckay": [
        2487
    ],
    "lewiston": [
        2487
    ],
    "hurt, richard arlie": [
        2488
    ],
    "sea scouts 180": [
        2489
    ],
    "weeter, john c.": [
        2489
    ],
    "cormier, leo adrien": [
        2490
    ],
    "pack 154": [
        2491
    ],
    "schon, fred eugene": [
        2491
    ],
    "troop 175": [
        2492
    ],
    "parker, william charles": [
        2492
    ],
    "barre center": [
        2492
    ],
    "winslow, william h.": [
        2493
    ],
    "explorer 138": [
        2494
    ],
    "findlan, glen": [
        2494
    ],
    "cordes, kenneth m.": [
        2495
    ],
    "mariemont": [
        2495
    ],
    "miller, eugene d.": [
        2496
    ],
    "bailey, jerry": [
        2497
    ],
    "derby, donald ray": [
        2498
    ],
    "wicke, william": [
        2499
    ],
    "carrigan, harry b.": [
        2500
    ],
    "troop 144": [
        2501
    ],
    "skoglund, royce arlen": [
        2501
    ],
    "legare, william r.": [
        2502
    ],
    "enriquez, nelson": [
        2503
    ],
    "south lake tahoe": [
        2503
    ],
    "troop 139": [
        2504
    ],
    "boudreaux, joseph d.": [
        2504
    ],
    "varner, gerald w.": [
        2505
    ],
    "coal valley": [
        2505
    ],
    "west germany": [
        2506
    ],
    "iveans, gerald d.": [
        2506
    ],
    "nelligen base": [
        2506
    ],
    "jackson, jon s.": [
        2507
    ],
    "clegg, wayne d.": [
        2508
    ],
    "fousse, george": [
        2509
    ],
    "beck, irving w.": [
        2510
    ],
    "o'hara, michael j.": [
        2511
    ],
    "hewlett": [
        2511
    ],
    "lockhart, john": [
        2512
    ],
    "campbell, lee a.": [
        2513
    ],
    "hughes, tyrone charles": [
        2514
    ],
    "suffern": [
        2514
    ],
    "stokes, paul kenneth": [
        2515
    ],
    "verloop, mark": [
        2516
    ],
    "verneuy, melvin g.": [
        2517
    ],
    "naha afb": [
        2517
    ],
    "pendleton, jon douglas": [
        2518
    ],
    "wiccopee": [
        2518
    ],
    "kubic, jerome w.": [
        2519
    ],
    "barber's point": [
        2519
    ],
    "explorer 109": [
        2520
    ],
    "schindler, robert andrew": [
        2520
    ],
    "watson, harry r.": [
        2521
    ],
    "heflin, robert c.": [
        2522
    ],
    "noblesville": [
        2522
    ],
    "brown, stephen i.": [
        2523
    ],
    "goodwin, elmer": [
        2524
    ],
    "kotzebue": [
        2524
    ],
    "doiron, wilfred d.": [
        2525,
        2527
    ],
    "mckinlay, lavon": [
        2526
    ],
    "igling, harry cecil": [
        2528
    ],
    "lindenmuth, richard f.": [
        2529
    ],
    "allentown": [
        2529
    ],
    "jones, dean": [
        2530
    ],
    "anderson, harry james": [
        2531
    ],
    "cresson": [
        2531
    ],
    "willis, terrell": [
        2532
    ],
    "blakely": [
        2532
    ],
    "france": [
        2533,
        2775
    ],
    "malone, james e.": [
        2533
    ],
    "fountaineblau": [
        2533
    ],
    "frie, george o.": [
        2534
    ],
    "farrell, william j.": [
        2535
    ],
    "dorchester": [
        2535
    ],
    "duvan, james": [
        2536
    ],
    "north las vegas": [
        2536
    ],
    "explorer 88": [
        2537
    ],
    "henricksen, david o.": [
        2537
    ],
    "south haven": [
        2537
    ],
    "busher, gary edison": [
        2538
    ],
    "explorer 87": [
        2539
    ],
    "kennedy, george ivor": [
        2539
    ],
    "tomczak, william alfred": [
        2540
    ],
    "hodge, donald a.": [
        2541
    ],
    "jones, carl robert": [
        2542
    ],
    "branham, robert jason": [
        2543
    ],
    "scanlan, robert h.": [
        2544
    ],
    "downers grove": [
        2544
    ],
    "gregory, don j.": [
        2545
    ],
    "hester, ferol curtis": [
        2546
    ],
    "pack 78": [
        2547
    ],
    "harris, j. d.": [
        2547
    ],
    "maywood": [
        2547
    ],
    "gray, eugene w.": [
        2548
    ],
    "miles, donald e.": [
        2549
    ],
    "bero, lewis john alexander": [
        2550
    ],
    "hogansburg": [
        2550
    ],
    "mudge, raymond": [
        2551
    ],
    "italy": [
        2552
    ],
    "atkins, lawrence m": [
        2552
    ],
    "leghorn": [
        2552
    ],
    "spain": [
        2553
    ],
    "bartlett, benhamin t.": [
        2553
    ],
    "rota naval station": [
        2553
    ],
    "logan, dale lyle": [
        2554
    ],
    "artesia": [
        2554
    ],
    "smith, lafayette b.": [
        2555
    ],
    "tobias, glenn richard": [
        2556
    ],
    "gonzales, gilbert m.": [
        2557
    ],
    "tempe": [
        2557
    ],
    "dierker, george a.": [
        2558
    ],
    "pack 71": [
        2559
    ],
    "montiel, valente r.": [
        2559
    ],
    "graf, peter d.": [
        2560
    ],
    "stone, william e.": [
        2561
    ],
    "port arthur": [
        2561
    ],
    "midgette, benjamin franklin": [
        2562
    ],
    "mobile": [
        2562
    ],
    "richard, larry": [
        2563
    ],
    "gonzales": [
        2563
    ],
    "gallagher, leonard s.": [
        2564
    ],
    "atkinson, bernard colie": [
        2565
    ],
    "alexander city": [
        2565
    ],
    "eddy, james r.": [
        2566
    ],
    "clark, arthur f.": [
        2567
    ],
    "peterson, james": [
        2568
    ],
    "o'tuell, alton louis": [
        2569
    ],
    "trombley, dale virgil": [
        2570
    ],
    "nabors, gary mitchell": [
        2571
    ],
    "paso robles": [
        2571
    ],
    "thomson, lincoln t.": [
        2572
    ],
    "dalton, lee": [
        2573
    ],
    "hiram": [
        2573
    ],
    "stabler, lemmie clyde": [
        2574
    ],
    "morin, alfred": [
        2575
    ],
    "worchester": [
        2575
    ],
    "freeman, robert walker": [
        2576
    ],
    "hamm, richard g.": [
        2577
    ],
    "hollidaysburg": [
        2577
    ],
    "doner, paul a.": [
        2578
    ],
    "carpenteria": [
        2578
    ],
    "sea scouts 58": [
        2579
    ],
    "keen, william c.": [
        2579
    ],
    "newport ": [
        2579
    ],
    "tinker, frank e.": [
        2580
    ],
    "springhill": [
        2580
    ],
    "shilling, joseph randolph": [
        2581
    ],
    "montgomery, john e.": [
        2582
    ],
    "wilson, harry w.": [
        2583
    ],
    "runnemede": [
        2583
    ],
    "pack 53": [
        2584
    ],
    "o'neal, carlisle j.": [
        2584
    ],
    "atascadero": [
        2584
    ],
    "elton, william francis": [
        2585
    ],
    "philo": [
        2585
    ],
    "potter, kenneth leroy": [
        2586
    ],
    "lawson, george p.": [
        2587
    ],
    "deckerville": [
        2587
    ],
    "pratt, william r.": [
        2588
    ],
    "toms river": [
        2588
    ],
    "faubion, joseph t.": [
        2589
    ],
    "espenlaub, william eugene": [
        2590
    ],
    "grover, charles j.": [
        2591
    ],
    "yardville": [
        2591
    ],
    "kise, walter": [
        2592
    ],
    "moorhead": [
        2592
    ],
    "explorer 36": [
        2593
    ],
    "michael, raymond porter": [
        2593
    ],
    "mccullough, marion edward": [
        2594
    ],
    "golding, ronald f.": [
        2595
    ],
    "north judson": [
        2595
    ],
    "lien, howard cameron": [
        2596
    ],
    "graves, gary lester": [
        2597
    ],
    "oroville": [
        2597
    ],
    "caldwell, william": [
        2598
    ],
    "east liverpool": [
        2598
    ],
    "joynes, arthur": [
        2599
    ],
    "dyson, ralph porter": [
        2600
    ],
    "wabash": [
        2600
    ],
    "normandie, lee f.": [
        2601
    ],
    "willimantic": [
        2601
    ],
    "sea scouts 31": [
        2602
    ],
    "hawks, claude b.": [
        2602
    ],
    "abney, herbert taylor": [
        2603
    ],
    "tillman, john adcox": [
        2604
    ],
    "highland park": [
        2604
    ],
    "roenfanz, calvin": [
        2605
    ],
    "shope, gary c.": [
        2606
    ],
    "boresch, michael": [
        2607
    ],
    "queens ": [
        2607
    ],
    "santy, donald l.": [
        2608,
        2610
    ],
    "hall, victor john": [
        2609
    ],
    "armstrong, james elmer": [
        2611
    ],
    "greentown": [
        2611
    ],
    "stewart, john leroy": [
        2612
    ],
    "parker, james gilbert": [
        2613
    ],
    "smith, orttis lloyd": [
        2614
    ],
    "mcconahy, malcolm willis": [
        2615
    ],
    "tinker, ralph irving": [
        2616
    ],
    "uxbridge": [
        2616
    ],
    "solis, alex": [
        2617
    ],
    "butte ": [
        2617
    ],
    "brendel, david": [
        2618
    ],
    "hough, david john": [
        2619
    ],
    "atsugi nas": [
        2619
    ],
    "dailey, david hutchings": [
        2620
    ],
    "new milford": [
        2620,
        2637
    ],
    "rhoads, marvin leroy": [
        2621
    ],
    "watson, clarence e.": [
        2622
    ],
    "camp drum": [
        2622
    ],
    "mero, john a.": [
        2623
    ],
    "agee, walter franklin": [
        2624
    ],
    "dedell, r. gary jerome": [
        2625
    ],
    "collins, guy": [
        2626
    ],
    "hamilton": [
        2626
    ],
    "kamp, michael william": [
        2627
    ],
    "schofield brooks": [
        2627
    ],
    "donahue, edward thomas": [
        2628
    ],
    "lewis, robert p.": [
        2629
    ],
    "patnode, joseph lloyd": [
        2630
    ],
    "tupper lake": [
        2630
    ],
    "armstrong, gerald t.": [
        2631
    ],
    "juneau": [
        2631
    ],
    "krakow, kenneth j.": [
        2632
    ],
    "keys, donald leonard": [
        2633
    ],
    "falcone, eugene s.": [
        2634
    ],
    "oman, william woodrow": [
        2635
    ],
    "boonville": [
        2635
    ],
    "thurman, garrett n.": [
        2636
    ],
    "baxter, jack l.": [
        2637
    ],
    "marshall, gregory": [
        2638
    ],
    "tujunga": [
        2638
    ],
    "jackson, david marvin": [
        2639
    ],
    "lawrence, neil e.": [
        2640
    ],
    "schultz, curtis f.": [
        2641
    ],
    "wallingford": [
        2641
    ],
    "stewart, ronnie van": [
        2642
    ],
    "clarksburg": [
        2642
    ],
    "hepburn, william d.": [
        2643
    ],
    "mcdonald, charles jerris": [
        2644
    ],
    "weaver": [
        2644
    ],
    "knutson, kenneth n.": [
        2645
    ],
    "jones, linford david": [
        2646
    ],
    "boyd, john c.": [
        2647
    ],
    "keltz, leo s.": [
        2648
    ],
    "rupert, robert": [
        2649
    ],
    "seaman, john wilber": [
        2650
    ],
    "young, tommy fletcher": [
        2651
    ],
    "knight, richard armour": [
        2652
    ],
    "wolfe, larry henry": [
        2653
    ],
    "meridian": [
        2653
    ],
    "benoit, ronald": [
        2654
    ],
    "herzogenauraek": [
        2654
    ],
    "blevins, john irvin baynard": [
        2655
    ],
    "provost, mitchell peter": [
        2656
    ],
    "ft. bliss": [
        2656
    ],
    "miller, david alden": [
        2657
    ],
    "chico": [
        2657
    ],
    "zook, michael": [
        2658
    ],
    "mcclintock, benny leroy": [
        2659
    ],
    "ellsworth, dennis": [
        2660
    ],
    "west haven": [
        2660,
        2664
    ],
    "schuerman, joseph ray": [
        2661
    ],
    "allen, david p.": [
        2662
    ],
    "harford, matthew joseph": [
        2663
    ],
    "schaefer, paul j.": [
        2664
    ],
    "moore, ralph eugene": [
        2665
    ],
    "everett": [
        2665,
        2794
    ],
    "garrison, paul eugene": [
        2666
    ],
    "moreau, william j.": [
        2667
    ],
    "kise, william s.": [
        2668
    ],
    "johnson, trois e.": [
        2669
    ],
    "o'callaghan, robert": [
        2670
    ],
    "clark afb": [
        2670
    ],
    "kirksey, george jackson": [
        2671
    ],
    "nolte, burdette": [
        2672
    ],
    "middleburgh": [
        2672
    ],
    "ettwein, joseph s.": [
        2673
    ],
    "willaimsport": [
        2673
    ],
    "ady, aard bruce": [
        2674
    ],
    "fowler, richard": [
        2675
    ],
    "wintz, jerry d.": [
        2676
    ],
    ",": [
        2677
    ],
    "trenton": [
        2677
    ],
    "hulett, james d.": [
        2678
    ],
    "sea scouts 511": [
        2679
    ],
    "pfanstiehl, alfred": [
        2679
    ],
    "tenney, edwin lren": [
        2680
    ],
    "bartlett, james w.": [
        2681
    ],
    "peterson, robert martin": [
        2682
    ],
    "tompkins, john r.": [
        2683
    ],
    "wrobel, donald e.": [
        2684
    ],
    "ramer, duane leigh": [
        2685
    ],
    "thouin, robert h.": [
        2686
    ],
    "stone, william s.": [
        2687
    ],
    "morton, robert joseph": [
        2688
    ],
    "boggess, michael kendrick": [
        2689
    ],
    "halpern, samuel barry": [
        2690
    ],
    "hohl, robert p.": [
        2691
    ],
    "henne, david john": [
        2692
    ],
    "perry, merle g.": [
        2693
    ],
    "bischoff, jimmie j.": [
        2694
    ],
    "story, james harold": [
        2695
    ],
    "orr, george frederick": [
        2696
    ],
    "huelsman, robert elmer": [
        2697
    ],
    "kidd, ronald": [
        2698
    ],
    "hicks, charles henry": [
        2699
    ],
    "sorenson, donald lee": [
        2700
    ],
    "funkhouser, jerry": [
        2701
    ],
    "mates, robert": [
        2702
    ],
    "steuart, william k.": [
        2703
    ],
    "weniger, robert l.": [
        2704
    ],
    "harvey, john": [
        2705
    ],
    "brown, douglas lee": [
        2706
    ],
    "cunningham, carl": [
        2707
    ],
    "collier, robert e.": [
        2708
    ],
    "mccadam, paul": [
        2709
    ],
    "donovan, william edward": [
        2710
    ],
    "leavis, john c.": [
        2711
    ],
    "jordan, robert lester": [
        2712
    ],
    "olson, frederick daniel": [
        2713
    ],
    "kirkpatrick, richard b.": [
        2714
    ],
    "stensland, neil francis": [
        2715
    ],
    "gorton, william": [
        2716
    ],
    "goddard, jack o.": [
        2717
    ],
    "klecher, anthony charles": [
        2718
    ],
    "pearson, kenneth edwin": [
        2719
    ],
    "martin, earl dean": [
        2720
    ],
    "gleston, franklin": [
        2721
    ],
    "hoffmeyer, william a.": [
        2722
    ],
    "privette, ernest t.": [
        2723
    ],
    "dudley, shearon": [
        2724
    ],
    "parthun, wayne earle": [
        2725
    ],
    "overturf, james e.": [
        2726
    ],
    "miller, alfred l.": [
        2727
    ],
    "talamo, jack j.": [
        2728
    ],
    "roach, jon kent": [
        2729
    ],
    "strine, r. patterson": [
        2730
    ],
    "anglin, clifford h.": [
        2731
    ],
    "lomax, william g.": [
        2732
    ],
    "smith, raymond h.": [
        2733
    ],
    "silvius, edward george": [
        2734
    ],
    "patterson, dwight reed": [
        2735
    ],
    "jones, charles frank": [
        2736
    ],
    "hill, alfred": [
        2737
    ],
    "rolloff, gary frederick": [
        2738
    ],
    "gladden, a. lee": [
        2739
    ],
    "iannuzzi, william": [
        2740
    ],
    "hiltner, richard montgomery": [
        2741
    ],
    "reid, samuel lynn": [
        2742
    ],
    "vance, donald w.": [
        2743
    ],
    "temple, robert eugene": [
        2744
    ],
    "damon, farnham w.": [
        2745
    ],
    "clayton, w. dean": [
        2746
    ],
    "jones, herbert f.h.": [
        2747
    ],
    "foley, warren r.": [
        2748
    ],
    "ward, james delma": [
        2749
    ],
    "talt, richard william": [
        2750
    ],
    "boyle, martin": [
        2751
    ],
    "newman, robert e.": [
        2752
    ],
    "brown, ronald lee": [
        2753
    ],
    "harris, henry t.": [
        2754
    ],
    "tomlin, paul c.": [
        2755
    ],
    "salyers, r. glenn": [
        2756
    ],
    "aziz, joel": [
        2757
    ],
    "bradley, marlowe william": [
        2758
    ],
    "donelson, floyd dwight": [
        2759
    ],
    "leight, edward": [
        2760
    ],
    "napier, frank": [
        2761
    ],
    "winthrow, samuel joseph": [
        2762
    ],
    "austin, david k.": [
        2763
    ],
    "gourley, william e.": [
        2764
    ],
    "gold, thomas hall": [
        2765
    ],
    "shadle, james l.": [
        2766
    ],
    "kissel, gary lee": [
        2767
    ],
    "dover": [
        2767
    ],
    "berry, frank": [
        2768
    ],
    "hansen, jene albert": [
        2769,
        2771
    ],
    "san lorenzo": [
        2769,
        2771
    ],
    "gray, brandon p.": [
        2770
    ],
    "mcdonald, leo patrick": [
        2772
    ],
    "explorer 143": [
        2773
    ],
    "landis, richard m.": [
        2773
    ],
    "bushy park ab": [
        2773
    ],
    "hartley, james c.": [
        2774
    ],
    "hampton": [
        2774
    ],
    "troop 275": [
        2775
    ],
    "witherspoon, charles c.": [
        2775
    ],
    "ingrandes": [
        2775
    ],
    "merritt, lowell l.": [
        2776
    ],
    "corfu": [
        2776
    ],
    "perdue, barney a.": [
        2777
    ],
    "lake havasu city": [
        2777
    ],
    "pack 492": [
        2778
    ],
    "hunnicutt, troy wayne": [
        2778
    ],
    "jennings, edward oscar": [
        2779
    ],
    "james, kenneth w.": [
        2780,
        2782
    ],
    "daniels, robert": [
        2781
    ],
    "south dartmouth": [
        2781
    ],
    "troop 677": [
        2782,
        2785
    ],
    "troop 639": [
        2783
    ],
    "sigmund, frederick": [
        2783,
        2785
    ],
    "explorer 327": [
        2784
    ],
    "evans, stanley thomas": [
        2784
    ],
    "fulbright, ricky": [
        2786
    ],
    "troop 819": [
        2787
    ],
    "burns, robert w.": [
        2787,
        2788
    ],
    "troop 813": [
        2788
    ],
    "pack 276": [
        2789
    ],
    "unklesbay, paul j.": [
        2789
    ],
    "moffitt heights": [
        2789
    ],
    "foley, warren raymond": [
        2790,
        2792
    ],
    "clark, richard": [
        2791,
        2793
    ],
    "ewalt, allen": [
        2794
    ],
    "troop 475": [
        2795
    ],
    "dozier, john ben": [
        2795
    ],
    "troop 864": [
        2796
    ],
    "whalen, thomas m.": [
        2796
    ],
    "fish, lloyd w.": [
        2797
    ],
    "plymouth": [
        2797
    ],
    "al": [
        272,
        395,
        853,
        854,
        910,
        993,
        1243,
        1244,
        1281,
        1533,
        1535,
        1545,
        1910,
        1936,
        1937,
        1947,
        2098,
        2464,
        2562,
        2565,
        2574,
        2644
    ],
    "ak": [
        428,
        476,
        550,
        654,
        749,
        750,
        1230,
        1231,
        1255,
        1291,
        1617,
        1802,
        2524,
        2631
    ],
    "az": [
        147,
        148,
        149,
        294,
        348,
        366,
        479,
        570,
        709,
        712,
        740,
        1179,
        1257,
        1329,
        1336,
        1338,
        1340,
        1532,
        1539,
        1778,
        1863,
        2006,
        2140,
        2211,
        2212,
        2366,
        2425,
        2468,
        2482,
        2509,
        2557,
        2777
    ],
    "ar": [
        34,
        35,
        212,
        414,
        446,
        537,
        1019,
        1304,
        2138
    ],
    "ca": [
        3,
        4,
        5,
        11,
        12,
        13,
        98,
        99,
        100,
        128,
        129,
        238,
        250,
        251,
        253,
        279,
        286,
        305,
        307,
        326,
        327,
        338,
        363,
        378,
        415,
        438,
        440,
        460,
        464,
        473,
        497,
        518,
        541,
        555,
        582,
        597,
        598,
        644,
        690,
        691,
        693,
        733,
        734,
        741,
        759,
        760,
        762,
        770,
        771,
        816,
        829,
        830,
        835,
        869,
        877,
        901,
        902,
        923,
        934,
        935,
        957,
        959,
        964,
        966,
        967,
        991,
        999,
        1001,
        1013,
        1027,
        1029,
        1045,
        1058,
        1060,
        1063,
        1070,
        1095,
        1103,
        1109,
        1118,
        1119,
        1120,
        1121,
        1134,
        1153,
        1170,
        1171,
        1173,
        1175,
        1187,
        1199,
        1203,
        1205,
        1265,
        1296,
        1298,
        1323,
        1324,
        1325,
        1327,
        1346,
        1352,
        1357,
        1368,
        1372,
        1389,
        1390,
        1403,
        1405,
        1418,
        1421,
        1430,
        1431,
        1433,
        1442,
        1446,
        1484,
        1493,
        1512,
        1538,
        1540,
        1542,
        1557,
        1564,
        1571,
        1578,
        1597,
        1599,
        1622,
        1623,
        1642,
        1672,
        1679,
        1681,
        1696,
        1699,
        1711,
        1716,
        1745,
        1747,
        1767,
        1769,
        1774,
        1777,
        1781,
        1813,
        1837,
        1839,
        1850,
        1852,
        1854,
        1860,
        1862,
        1869,
        1870,
        1878,
        1885,
        1888,
        1921,
        1928,
        1929,
        1941,
        1950,
        1955,
        1956,
        1961,
        1987,
        1988,
        1990,
        2000,
        2007,
        2023,
        2029,
        2058,
        2062,
        2063,
        2065,
        2066,
        2067,
        2088,
        2114,
        2118,
        2120,
        2121,
        2134,
        2136,
        2178,
        2180,
        2189,
        2191,
        2205,
        2206,
        2222,
        2224,
        2247,
        2259,
        2260,
        2261,
        2262,
        2313,
        2316,
        2318,
        2321,
        2326,
        2335,
        2339,
        2344,
        2356,
        2358,
        2360,
        2367,
        2381,
        2388,
        2398,
        2402,
        2404,
        2413,
        2433,
        2446,
        2452,
        2456,
        2461,
        2470,
        2480,
        2488,
        2490,
        2503,
        2516,
        2523,
        2528,
        2538,
        2548,
        2559,
        2578,
        2584,
        2585,
        2597,
        2638,
        2645,
        2647,
        2657,
        2679,
        2769,
        2771
    ],
    "co": [
        324,
        362,
        554,
        585,
        626,
        799,
        836,
        837,
        925,
        1010,
        1028,
        1056,
        1163,
        1232,
        1258,
        1310,
        1378,
        1434,
        1472,
        1498,
        1575,
        1664,
        1675,
        1688,
        1712,
        1818,
        1820,
        1851,
        1881,
        1882,
        2022,
        2077,
        2079,
        2252,
        2253,
        2308,
        2368,
        2373,
        2566,
        2621
    ],
    "ct": [
        104,
        105,
        106,
        226,
        258,
        329,
        463,
        568,
        587,
        669,
        674,
        777,
        985,
        987,
        1064,
        1114,
        1181,
        1200,
        1292,
        1319,
        1350,
        1458,
        1460,
        1690,
        2115,
        2265,
        2267,
        2273,
        2275,
        2304,
        2333,
        2469,
        2571,
        2599,
        2601,
        2620,
        2641,
        2660,
        2664,
        2675,
        2779
    ],
    "de": [
        302,
        441,
        442,
        1506,
        1701,
        2139,
        2385,
        2614,
        2767
    ],
    "fl": [
        144,
        145,
        146,
        177,
        178,
        179,
        214,
        223,
        283,
        284,
        301,
        306,
        311,
        312,
        386,
        407,
        411,
        412,
        413,
        462,
        470,
        480,
        484,
        498,
        513,
        526,
        532,
        542,
        556,
        573,
        632,
        661,
        745,
        773,
        811,
        812,
        832,
        833,
        842,
        849,
        850,
        864,
        887,
        971,
        980,
        981,
        982,
        983,
        1046,
        1062,
        1068,
        1073,
        1075,
        1081,
        1085,
        1087,
        1112,
        1113,
        1145,
        1147,
        1155,
        1157,
        1198,
        1235,
        1245,
        1274,
        1278,
        1285,
        1334,
        1366,
        1401,
        1406,
        1408,
        1426,
        1463,
        1465,
        1466,
        1483,
        1488,
        1514,
        1517,
        1541,
        1556,
        1582,
        1625,
        1683,
        1709,
        1758,
        1760,
        1764,
        1765,
        1816,
        1841,
        1894,
        1907,
        1965,
        1973,
        1991,
        1997,
        2019,
        2021,
        2032,
        2039,
        2047,
        2057,
        2149,
        2198,
        2200,
        2300,
        2372,
        2400,
        2410,
        2423,
        2424,
        2430,
        2442,
        2449,
        2476,
        2479,
        2497,
        2512,
        2521,
        2570,
        2624,
        2652,
        2662,
        2663,
        2666,
        2668,
        2790,
        2792
    ],
    "ga": [
        0,
        1,
        2,
        43,
        53,
        54,
        55,
        56,
        92,
        93,
        113,
        118,
        267,
        282,
        339,
        340,
        408,
        424,
        450,
        503,
        519,
        588,
        599,
        676,
        697,
        703,
        871,
        899,
        976,
        1314,
        1394,
        1396,
        1464,
        1489,
        1495,
        1519,
        1663,
        1686,
        1702,
        1713,
        1717,
        1791,
        1819,
        1859,
        1866,
        1923,
        2094,
        2123,
        2146,
        2148,
        2532,
        2540,
        2542,
        2671
    ],
    "hi": [
        185,
        687,
        688,
        1903,
        1994,
        2119,
        2245,
        2519,
        2627
    ],
    "id": [
        243,
        313,
        633,
        713,
        805,
        806,
        909,
        1089,
        1156,
        1579,
        1594,
        1754,
        1755,
        1926,
        2016,
        2487
    ],
    "il": [
        51,
        81,
        191,
        192,
        193,
        194,
        195,
        196,
        197,
        198,
        199,
        200,
        201,
        229,
        275,
        358,
        359,
        401,
        423,
        452,
        571,
        586,
        593,
        605,
        647,
        652,
        716,
        719,
        736,
        742,
        748,
        764,
        781,
        782,
        794,
        795,
        813,
        857,
        858,
        879,
        898,
        900,
        903,
        914,
        931,
        933,
        949,
        955,
        956,
        1018,
        1040,
        1049,
        1051,
        1054,
        1055,
        1117,
        1139,
        1172,
        1186,
        1209,
        1224,
        1226,
        1294,
        1307,
        1308,
        1358,
        1362,
        1369,
        1370,
        1383,
        1395,
        1409,
        1410,
        1485,
        1547,
        1549,
        1550,
        1551,
        1584,
        1586,
        1608,
        1635,
        1639,
        1665,
        1718,
        1731,
        1738,
        1741,
        1750,
        1768,
        1775,
        1789,
        1829,
        1831,
        1865,
        1951,
        1959,
        1983,
        2026,
        2053,
        2080,
        2082,
        2125,
        2127,
        2150,
        2151,
        2197,
        2199,
        2234,
        2236,
        2240,
        2256,
        2274,
        2289,
        2291,
        2292,
        2293,
        2295,
        2296,
        2314,
        2317,
        2396,
        2414,
        2445,
        2491,
        2494,
        2505,
        2544,
        2547,
        2604,
        2637,
        2707,
        2787,
        2788,
        2796
    ],
    "in": [
        14,
        15,
        16,
        154,
        227,
        344,
        374,
        394,
        522,
        685,
        715,
        735,
        881,
        884,
        926,
        930,
        940,
        1007,
        1104,
        1176,
        1229,
        1355,
        1356,
        1400,
        1501,
        1524,
        1526,
        1637,
        1662,
        1728,
        1776,
        1913,
        1914,
        2005,
        2015,
        2059,
        2078,
        2112,
        2168,
        2169,
        2186,
        2188,
        2238,
        2239,
        2244,
        2246,
        2257,
        2283,
        2349,
        2408,
        2522,
        2526,
        2549,
        2555,
        2556,
        2558,
        2595,
        2600,
        2611,
        2797
    ],
    "ia": [
        114,
        241,
        434,
        465,
        466,
        892,
        973,
        1042,
        1268,
        1452,
        1474,
        1563,
        1576,
        1577,
        1580,
        1618,
        1624,
        1643,
        1722,
        1827,
        1828,
        1934,
        1966,
        2215,
        2217,
        2242,
        2243,
        2386,
        2420,
        2545,
        2632,
        2766
    ],
    "ks": [
        87,
        90,
        342,
        431,
        432,
        458,
        579,
        580,
        581,
        602,
        666,
        667,
        1077,
        1129,
        1131,
        1174,
        1360,
        1386,
        1631,
        1646,
        1797,
        1808,
        1861,
        1967,
        2017,
        2221,
        2223,
        2341,
        2345,
        2415,
        2778
    ],
    "ky": [
        393,
        453,
        681,
        778,
        838,
        1005,
        1127,
        1445,
        1695,
        2399,
        2463,
        2489,
        2579,
        2643,
        2676
    ],
    "la": [
        208,
        334,
        387,
        547,
        562,
        603,
        604,
        743,
        828,
        919,
        1050,
        1053,
        1061,
        1082,
        1107,
        1122,
        1124,
        1125,
        1136,
        1204,
        1207,
        1286,
        1288,
        1513,
        1515,
        1531,
        1591,
        1592,
        1708,
        1735,
        1946,
        2075,
        2124,
        2255,
        2272,
        2355,
        2394,
        2474,
        2502,
        2563,
        2580,
        2651,
        2669
    ],
    "me": [
        47,
        48,
        49,
        50,
        263,
        402,
        429,
        521,
        529,
        560,
        783,
        1088,
        1264,
        1454,
        1523,
        1938,
        1953,
        1981,
        2473
    ],
    "md": [
        70,
        71,
        91,
        112,
        123,
        124,
        125,
        126,
        292,
        365,
        409,
        642,
        705,
        890,
        917,
        918,
        1076,
        1084,
        1247,
        1266,
        1287,
        1289,
        1413,
        1414,
        1447,
        1449,
        1612,
        1694,
        1723,
        1732,
        1982,
        2054,
        2056,
        2126,
        2158,
        2159,
        2161,
        2162,
        2175,
        2176,
        2307,
        2322,
        2330,
        2332,
        2389,
        2437,
        2500,
        2576,
        2581
    ],
    "ma": [
        73,
        74,
        75,
        82,
        83,
        84,
        85,
        166,
        167,
        174,
        175,
        176,
        224,
        252,
        331,
        382,
        384,
        403,
        437,
        451,
        485,
        486,
        530,
        534,
        548,
        620,
        621,
        653,
        729,
        732,
        744,
        747,
        823,
        826,
        856,
        875,
        932,
        970,
        972,
        1093,
        1160,
        1182,
        1184,
        1254,
        1256,
        1259,
        1261,
        1276,
        1293,
        1309,
        1326,
        1330,
        1347,
        1397,
        1404,
        1412,
        1605,
        1668,
        1677,
        1721,
        1739,
        1779,
        1784,
        1795,
        1830,
        1893,
        1899,
        1900,
        1939,
        2044,
        2170,
        2193,
        2219,
        2220,
        2232,
        2263,
        2310,
        2348,
        2535,
        2572,
        2575,
        2616,
        2629,
        2781
    ],
    "mi": [
        57,
        58,
        59,
        101,
        102,
        103,
        159,
        160,
        161,
        232,
        256,
        260,
        345,
        367,
        375,
        376,
        435,
        488,
        516,
        527,
        538,
        565,
        617,
        629,
        765,
        766,
        793,
        845,
        861,
        862,
        865,
        868,
        870,
        894,
        896,
        904,
        938,
        954,
        960,
        1003,
        1098,
        1100,
        1105,
        1106,
        1108,
        1130,
        1149,
        1227,
        1374,
        1443,
        1502,
        1528,
        1568,
        1601,
        1609,
        1634,
        1691,
        1692,
        1693,
        1757,
        1780,
        1793,
        1835,
        1857,
        1925,
        1930,
        2009,
        2035,
        2043,
        2069,
        2081,
        2090,
        2093,
        2143,
        2311,
        2312,
        2315,
        2319,
        2324,
        2325,
        2369,
        2378,
        2431,
        2441,
        2443,
        2485,
        2537,
        2568,
        2613,
        2648
    ],
    "mn": [
        44,
        142,
        143,
        296,
        355,
        449,
        457,
        575,
        855,
        990,
        1135,
        1150,
        1162,
        1349,
        1361,
        1478,
        1494,
        1518,
        1558,
        1569,
        1585,
        1647,
        1697,
        1821,
        1917,
        1945,
        2028,
        2453,
        2459,
        2486,
        2510,
        2592,
        2615,
        2633
    ],
    "ms": [
        209,
        578,
        614,
        615,
        616,
        958,
        977,
        978,
        997
    ],
    "mo": [
        88,
        89,
        107,
        108,
        109,
        139,
        140,
        141,
        186,
        187,
        188,
        309,
        326,
        327,
        543,
        589,
        698,
        723,
        724,
        738,
        761,
        775,
        776,
        937,
        944,
        945,
        961,
        1000,
        1044,
        1072,
        1074,
        1097,
        1111,
        1133,
        1137,
        1138,
        1151,
        1223,
        1242,
        1252,
        1371,
        1432,
        1441,
        1470,
        1475,
        1500,
        1521,
        1555,
        1565,
        1570,
        1573,
        1587,
        1589,
        1616,
        1641,
        1644,
        1645,
        1652,
        1682,
        1684,
        1685,
        1687,
        1783,
        1798,
        1840,
        1875,
        1876,
        1891,
        1892,
        1902,
        1908,
        2092,
        2100,
        2173,
        2241,
        2251,
        2305,
        2323,
        2397,
        2409,
        2419,
        2428,
        2429,
        2498,
        2587
    ],
    "mt": [
        563,
        606,
        1267,
        1313,
        2280,
        2281,
        2617
    ],
    "ne": [
        39,
        40,
        41,
        758,
        767,
        1037,
        1197,
        1272,
        1300,
        1610,
        1809,
        1889,
        1890,
        1909,
        1920,
        1992,
        2177,
        2179,
        2250,
        2384,
        2391
    ],
    "nv": [
        180,
        181,
        182,
        183,
        233,
        726,
        880,
        1035,
        1133,
        1246,
        1333,
        1497,
        1751,
        1898,
        2536,
        2551
    ],
    "nh": [
        79,
        80,
        330,
        577,
        622,
        657,
        675,
        754,
        936,
        1241,
        1508,
        1510,
        1993,
        2427,
        2525,
        2527
    ],
    "nj": [
        76,
        77,
        78,
        117,
        207,
        257,
        287,
        303,
        328,
        343,
        385,
        439,
        474,
        475,
        478,
        491,
        505,
        506,
        510,
        511,
        539,
        561,
        613,
        623,
        641,
        727,
        746,
        772,
        780,
        796,
        817,
        818,
        839,
        860,
        867,
        873,
        895,
        996,
        1011,
        1092,
        1094,
        1128,
        1202,
        1284,
        1359,
        1560,
        1671,
        1705,
        1753,
        1756,
        1762,
        1811,
        1814,
        1960,
        2109,
        2181,
        2183,
        2285,
        2287,
        2376,
        2406,
        2515,
        2583,
        2588,
        2589,
        2591,
        2677
    ],
    "nm": [
        611,
        612,
        790,
        929,
        1348,
        1725,
        1771,
        2270,
        2426,
        2539,
        2554
    ],
    "ny": [
        20,
        21,
        22,
        23,
        67,
        68,
        69,
        115,
        116,
        133,
        134,
        135,
        136,
        137,
        138,
        168,
        169,
        170,
        215,
        216,
        255,
        271,
        273,
        281,
        318,
        357,
        369,
        383,
        399,
        404,
        461,
        492,
        507,
        523,
        524,
        549,
        552,
        553,
        584,
        592,
        600,
        601,
        630,
        635,
        636,
        637,
        640,
        645,
        648,
        682,
        686,
        695,
        696,
        752,
        753,
        803,
        804,
        807,
        808,
        844,
        846,
        874,
        878,
        948,
        950,
        989,
        992,
        994,
        1002,
        1004,
        1031,
        1065,
        1079,
        1090,
        1165,
        1177,
        1189,
        1196,
        1236,
        1238,
        1280,
        1282,
        1283,
        1365,
        1388,
        1392,
        1402,
        1415,
        1416,
        1417,
        1420,
        1471,
        1479,
        1480,
        1481,
        1492,
        1596,
        1598,
        1600,
        1604,
        1650,
        1653,
        1667,
        1700,
        1710,
        1714,
        1730,
        1788,
        1825,
        1845,
        1847,
        1853,
        1879,
        1880,
        1883,
        1884,
        1954,
        1963,
        1978,
        1998,
        2002,
        2031,
        2036,
        2037,
        2046,
        2068,
        2107,
        2167,
        2185,
        2187,
        2207,
        2209,
        2216,
        2225,
        2226,
        2227,
        2228,
        2229,
        2230,
        2231,
        2294,
        2298,
        2331,
        2337,
        2343,
        2347,
        2351,
        2354,
        2362,
        2364,
        2365,
        2380,
        2395,
        2432,
        2440,
        2447,
        2460,
        2466,
        2492,
        2496,
        2511,
        2514,
        2518,
        2541,
        2550,
        2560,
        2582,
        2606,
        2607,
        2622,
        2623,
        2625,
        2630,
        2635,
        2672,
        2776,
        2783,
        2785,
        2791,
        2793
    ],
    "nc": [
        28,
        29,
        30,
        60,
        61,
        62,
        63,
        289,
        400,
        418,
        430,
        471,
        496,
        590,
        618,
        619,
        720,
        721,
        769,
        814,
        815,
        821,
        946,
        965,
        975,
        1008,
        1025,
        1233,
        1234,
        1353,
        1602,
        1615,
        1676,
        1803,
        2008,
        2012,
        2014,
        2328,
        2334,
        2411,
        2786
    ],
    "nd": [
        831,
        1520,
        2190,
        2192,
        2383
    ],
    "oh": [
        36,
        37,
        38,
        94,
        95,
        96,
        97,
        189,
        228,
        246,
        268,
        269,
        270,
        280,
        302,
        310,
        321,
        322,
        346,
        349,
        356,
        380,
        381,
        389,
        390,
        391,
        405,
        456,
        501,
        533,
        559,
        574,
        607,
        650,
        651,
        677,
        678,
        679,
        680,
        725,
        848,
        863,
        924,
        939,
        984,
        1012,
        1066,
        1096,
        1143,
        1158,
        1167,
        1169,
        1201,
        1273,
        1279,
        1302,
        1335,
        1376,
        1381,
        1387,
        1398,
        1411,
        1486,
        1611,
        1613,
        1614,
        1619,
        1628,
        1689,
        1704,
        1748,
        1749,
        1759,
        1761,
        1763,
        1799,
        1810,
        1812,
        1815,
        1826,
        1833,
        1858,
        1906,
        1915,
        1916,
        1931,
        1932,
        1999,
        2001,
        2003,
        2004,
        2033,
        2071,
        2074,
        2076,
        2131,
        2132,
        2137,
        2194,
        2195,
        2208,
        2210,
        2254,
        2258,
        2276,
        2277,
        2297,
        2340,
        2359,
        2392,
        2407,
        2448,
        2454,
        2467,
        2471,
        2495,
        2564,
        2573,
        2593,
        2598,
        2602,
        2626,
        2789
    ],
    "ok": [
        234,
        249,
        262,
        341,
        789,
        928,
        969,
        1277,
        1439,
        1450,
        1543,
        1657,
        1924,
        2061,
        2282,
        2416,
        2418,
        2659
    ],
    "or": [
        110,
        259,
        264,
        316,
        515,
        583,
        662,
        670,
        699,
        739,
        779,
        792,
        809,
        810,
        834,
        907,
        908,
        911,
        947,
        988,
        1043,
        1311,
        1312,
        1380,
        1393,
        1451,
        1537,
        1544,
        1655,
        1656,
        1674,
        1736,
        1737,
        1834,
        2048,
        2097,
        2113,
        2336,
        2438,
        2450,
        2451,
        2534,
        2546,
        2596,
        2608,
        2610,
        2646,
        2661,
        2674
    ],
    "pa": [
        52,
        65,
        66,
        86,
        130,
        131,
        132,
        235,
        236,
        237,
        261,
        265,
        290,
        300,
        353,
        472,
        489,
        546,
        567,
        627,
        628,
        665,
        668,
        673,
        683,
        710,
        714,
        718,
        730,
        755,
        757,
        785,
        786,
        840,
        841,
        843,
        847,
        851,
        852,
        962,
        1024,
        1034,
        1144,
        1152,
        1164,
        1168,
        1219,
        1295,
        1351,
        1407,
        1436,
        1456,
        1469,
        1476,
        1509,
        1511,
        1572,
        1603,
        1620,
        1649,
        1660,
        1734,
        1786,
        1787,
        1801,
        1806,
        1817,
        1823,
        1886,
        1901,
        1918,
        1919,
        1933,
        1940,
        1952,
        1972,
        1974,
        1975,
        1976,
        1977,
        2011,
        2013,
        2041,
        2086,
        2103,
        2104,
        2128,
        2152,
        2153,
        2166,
        2168,
        2169,
        2196,
        2201,
        2202,
        2204,
        2271,
        2299,
        2306,
        2338,
        2350,
        2357,
        2363,
        2370,
        2377,
        2403,
        2435,
        2436,
        2439,
        2444,
        2475,
        2477,
        2478,
        2481,
        2484,
        2493,
        2529,
        2531,
        2567,
        2577,
        2590,
        2618,
        2650,
        2653,
        2673
    ],
    "ri": [
        8,
        9,
        10,
        288,
        528,
        1047,
        1078,
        1110,
        1214,
        1216,
        1218,
        1221,
        1315,
        1363,
        1377,
        1843,
        2142,
        2157,
        2264,
        2266
    ],
    "sc": [
        203,
        204,
        205,
        206,
        468,
        469,
        483,
        701,
        702,
        1384,
        1499,
        1546,
        1770,
        1836,
        1867,
        1871,
        2073,
        2111,
        2352,
        2371,
        2387,
        2569,
        2594,
        2795
    ],
    "sd": [
        493,
        1208
    ],
    "tn": [
        297,
        298,
        320,
        370,
        371,
        443,
        487,
        535,
        536,
        557,
        913,
        1071,
        1161,
        1240,
        1448,
        1666,
        1673,
        1942,
        2099,
        2434,
        2530,
        2784
    ],
    "tx": [
        119,
        120,
        121,
        122,
        158,
        171,
        172,
        173,
        213,
        217,
        220,
        240,
        245,
        335,
        352,
        354,
        361,
        420,
        427,
        436,
        444,
        445,
        481,
        482,
        494,
        499,
        508,
        566,
        591,
        609,
        610,
        624,
        638,
        684,
        692,
        731,
        787,
        791,
        797,
        819,
        820,
        876,
        886,
        888,
        889,
        905,
        941,
        943,
        963,
        1006,
        1009,
        1017,
        1022,
        1048,
        1080,
        1115,
        1140,
        1142,
        1180,
        1183,
        1195,
        1250,
        1260,
        1263,
        1270,
        1299,
        1328,
        1337,
        1339,
        1341,
        1354,
        1367,
        1373,
        1399,
        1424,
        1435,
        1459,
        1461,
        1467,
        1554,
        1559,
        1567,
        1583,
        1607,
        1636,
        1640,
        1669,
        1706,
        1715,
        1726,
        1729,
        1733,
        1740,
        1744,
        1766,
        1773,
        1790,
        1805,
        1832,
        1838,
        1855,
        1856,
        1868,
        1872,
        1904,
        1905,
        1986,
        2010,
        2024,
        2040,
        2042,
        2045,
        2095,
        2116,
        2135,
        2144,
        2147,
        2163,
        2172,
        2174,
        2203,
        2213,
        2214,
        2218,
        2278,
        2279,
        2288,
        2290,
        2320,
        2327,
        2346,
        2375,
        2390,
        2457,
        2465,
        2483,
        2507,
        2561,
        2603,
        2612,
        2628,
        2634,
        2639,
        2656,
        2780,
        2782
    ],
    "ut": [
        244,
        274,
        700,
        722,
        906,
        920,
        1192,
        1290,
        1320,
        1496,
        1548,
        1658,
        1670,
        1752,
        1896,
        1897,
        1985,
        2087
    ],
    "vt": [
        347,
        512,
        594,
        631,
        658,
        915,
        1453,
        1457,
        1651,
        1873,
        1874,
        1887,
        2083,
        2302,
        2303,
        2667
    ],
    "va": [
        127,
        163,
        164,
        165,
        254,
        295,
        333,
        336,
        396,
        397,
        419,
        514,
        649,
        706,
        707,
        708,
        822,
        825,
        922,
        998,
        1014,
        1032,
        1033,
        1099,
        1101,
        1185,
        1191,
        1206,
        1375,
        1507,
        1516,
        1574,
        1957,
        1970,
        1995,
        1996,
        2060,
        2070,
        2072,
        2089,
        2091,
        2101,
        2164,
        2165,
        2248,
        2249,
        2374,
        2401,
        2405,
        2455,
        2649,
        2655,
        2774
    ],
    "wa": [
        31,
        32,
        33,
        222,
        239,
        291,
        325,
        377,
        388,
        392,
        421,
        520,
        660,
        664,
        671,
        672,
        689,
        737,
        768,
        885,
        916,
        979,
        1052,
        1067,
        1069,
        1222,
        1225,
        1228,
        1253,
        1318,
        1322,
        1331,
        1437,
        1553,
        1561,
        1595,
        1742,
        1743,
        1958,
        1968,
        1969,
        2154,
        2155,
        2156,
        2171,
        2301,
        2329,
        2421,
        2462,
        2501,
        2549,
        2586,
        2609,
        2658,
        2665,
        2794
    ],
    "wv": [
        17,
        18,
        19,
        72,
        211,
        230,
        231,
        299,
        379,
        495,
        801,
        802,
        891,
        1116,
        1427,
        1962,
        2642
    ],
    "wi": [
        24,
        25,
        26,
        27,
        45,
        46,
        64,
        111,
        150,
        151,
        152,
        155,
        156,
        157,
        247,
        285,
        350,
        351,
        364,
        368,
        551,
        576,
        625,
        717,
        912,
        927,
        952,
        1041,
        1083,
        1102,
        1141,
        1159,
        1166,
        1193,
        1194,
        1237,
        1239,
        1429,
        1455,
        1504,
        1552,
        1581,
        1593,
        1621,
        1720,
        1842,
        1912,
        1979,
        1980,
        2064,
        2084,
        2102,
        2105,
        2133,
        2284,
        2286,
        2361,
        2393,
        2499,
        2513,
        2605,
        2640
    ],
    "wy": [
        7,
        865,
        1301,
        1303,
        1691,
        1693,
        2129
    ],
    "dc": [
        1322,
        2155
    ],
    "gu": [
        711
    ],
    "pr": [
        751,
        866
    ]
}