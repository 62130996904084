<template>
  <div>
    <ag-grid-vue
      id="grid"
      style="width: 100%; height: 540px;"
      class="ag-theme-balham"
      :columnDefs="columnDefs"
      :rowData="selectedPerps"
    ></ag-grid-vue>
    <p id="disclaimer">
      This table contains information from and links to download publicly available BSA IV files.
      It is updated as new information becomes available and should not be considered comprehensive.
    </p>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
export default {
  name: "PerpTable",
  props: ["selectedPerps"],
  data() {
    return {
      columnDefs: null,
      rowData: null,
      frameworkComponents: null
    };
  },
  components: {
    AgGridVue
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: "Name",
        field: "NameOrID",
        sortable: true,
        resizable: true,
        comparator: stringComparator
      },
      {
        headerName: "Troop",
        field: "TroopNumber",
        sortable: true,
        resizable: true,
        width: 120,
        comparator: stringComparator
      },
      {
        headerName: "IV File",
        field: "DocumentUri",
        sortable: false,
        resizable: true,
        width: 70,
        cellRenderer: params => {
          const link = document.createElement("a");
          link.href = params.value;
          const icon = document.createElement("i");
          icon.className = "fa fa-file-pdf-o";
          link.appendChild(icon);
          link.setAttribute("target", "_blank");
          return link;
        }
      },
      {
        headerName: "City",
        field: "City",
        sortable: true,
        resizable: true,
        width: 150,
        comparator: stringComparator
      },
      {
        headerName: "State",
        field: "State",
        sortable: true,
        resizable: true,
        width: 120,
        comparator: stringComparator
      },
      {
        headerName: "Year",
        field: "Year",
        sortable: true,
        resizable: true,
        width: 100
      }
    ];
  }
};

function stringComparator(val1, val2, node1, node2, isInverted) {
  var result = 0;
  var multi = isInverted ? -1 : 1;

  if (val1 === "" && val2 === "") {
    result = 0;
  }
  if (val1 == val2) {
    result = 0;
  } else if (val1 === "") {
    result = 1 * multi;
  } else if (val2 === "") {
    result = -1 * multi;
  } else {
    result = val1 < val2 ? 1 : -1;
  }

  return result;
}
</script>

<style>
</style>
