<template>
  <div id="app">
    <!-- <div><p>From the 1920s to present day, the Boy Scouts of America (BSA) has maintained an internal filing system of scout leaders accused of misconduct, 
    including sexual abuse. As named by the BSA, "Ineligible Volunteer Files" (IV Files) or "Perversion Files" constitute five categories, including sexual misconduct. 
    These Perversion Files illustrate the knowledge that the BSA has about the history and context of sexual abuse of children within scouting. Significantly, 
    Courts have ordered the release of these files as part of numerous court cases throughout the country. In fall 2012, the Perversion Files from the years 1965 
    to 1985 were made public. </p></div> -->
    <div id="colorBar">
    </div>
    <div>
      <HeaderComponent />
      <SearchBar />
      <MainContainerNoForm :searchText="searchInput"/>
      <FooterComponent />
    </div>  
    <Footer />
  </div>
</template>

<script>
import SearchBar from './components/SearchBar.vue'
import MainContainerNoForm from './components/MainContainerNoForm.vue'
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import { EventBus } from '@/eventBus/Bus';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  name: 'app',
  components: {
    SearchBar,
    MainContainerNoForm,
    HeaderComponent,
    FooterComponent
  },
  mounted(){
    EventBus.$on('update:searchInput', this.handleSearchInputChange)
  },
  methods: {
    handleSearchInputChange(value){
      this.searchInput = value;
    }
  },
  data(){
    return {
      searchInput: 'All'
    }
  }
}


</script>

<style lang="scss">
  @import "../node_modules/ag-grid-community/styles/ag-grid.css";
  @import "../node_modules/ag-grid-community/styles/ag-theme-balham.css";
</style>

<style>
  #app {
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: black;
    width: 94%;
    margin-left: 1rem;
  }

  @media screen and (min-width: 600px) {
      #app {
        margin-left: 1.8rem;
      }
  }

  @media screen and (min-width: 1200px) {
      #app {
        margin-left: 2.6rem;
      }
  }

  @media screen and (min-width: 1500px) {
      #app {
        margin-left: 3.4rem;
      }
  }

  #colorBar {
    min-height: 4rem;
    background-color: #23365c;
  }

</style>
