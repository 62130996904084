<template>
  <div id="map">
    <GmapMap
      :center="centerCoordinates"
      :zoom="4"
      map-type-id="terrain"
      ref="mapRef"
      style="width: 100%; height: 500px;"
    >
      <GoogleMapCluster>
        <GmapMarker
          :key="index"
          v-for="(m, index) in selectedPerps"
          :position="m.Position"
          :clickable="true"
          :draggable="false"
        />
      </GoogleMapCluster>
    </GmapMap>
    <p id="disclaimer">
      Map markers indicate locations of troops impacted by individuals within the BSA IV files.
      Zoom in to view locations in greater detail.
    </p>
  </div>
</template>

<script>
import GoogleMapCluster from "vue2-google-maps/dist/components/cluster";
import { EventBus } from "@/eventBus/Bus";

export default {
  name: "GoogleMaps",
  components: {
    GoogleMapCluster
  },
  props: ["selectedPerps", "centerCoordinates"],
  mounted() {
    this.$refs.mapRef.$mapPromise.then(map => {
      map.addListener("center_changed", function() {
        let bounds = map.getBounds();
        EventBus.$emit("update:boundsChange", bounds);
      });
      map.addListener("zoom_changed", function() {
        let bounds = map.getBounds();
        EventBus.$emit("update:boundsChange", bounds);
      });
    });
  }
};
</script>

<style>
</style>
